import { get, set, map, uniqWith, isEqual } from 'lodash';
import * as d3 from 'd3';
import intl from 'react-intl-universal';

import { moneyFormat } from '../../../../utils/formatter';
import { AvailableCategoryModel } from '../../../../models/tour-details/tourAvailableCategory.model';
import { PricingOptionsModel } from './pricingOptions.model';

const OPTION_KEY_DELIMITER = '_';
export const OPTION_FIND_FIELDS = [
  'Id',
  'PaymentDueBy',
  'RatePlan.Id',
  'RatePlan.Name',
  'ServiceTime',
];

export const createSelectOptionKey = (category: AvailableCategoryModel) =>
  OPTION_FIND_FIELDS.map((field) => get(category, field, '')).join(
    OPTION_KEY_DELIMITER,
  );

export const getOptionObjectByKey = (key = '') => {
  const result = {};

  key.split(OPTION_KEY_DELIMITER).forEach((value, index) => {
    set(result, OPTION_FIND_FIELDS[index], value);
  });

  return result;
};

export const createPricingOptions = (
  availabilityCategories: AvailableCategoryModel[] = [],
): PricingOptionsModel[] => {
  const getColor = d3.scaleOrdinal(getD3ColorSchemes());

  return unifyAvailabilityCategories(availabilityCategories).map(
    (availabilityCategory, index) => {
      const { Guests, RatePlan, SellPrice } = availabilityCategory;
      const color = getColor(String(index));
      const key = createSelectOptionKey(availabilityCategory);
      const categoryName = intl.get('tourDetails.seatMap.pricingCategory', {
        name: RatePlan.Name,
      });
      const name = `${moneyFormat(SellPrice)} ${categoryName}`;
      const availabilityCategorySeats = map(Guests, 'Seat');
      const perUnitPrices = map(Guests, 'SellPrice');
      const seats = mapPricingCategorySeats(
        availabilityCategorySeats,
        categoryName,
        perUnitPrices,
        color,
      );

      return {
        key,
        name,
        seats,
        color,
        availabilityCategory,
      };
    },
  );
};

const unifyAvailabilityCategories = (
  availabilityCategories: AvailableCategoryModel[],
) =>
  uniqWith(availabilityCategories, (prevCategory, category) => {
    const prevSeats = map(prevCategory.Guests, 'Seat');
    const currentSeats = map(category.Guests, 'Seat');

    return isEqual(prevSeats, currentSeats);
  });

const mapPricingCategorySeats = (
  categorySeats: string[],
  categoryName: string,
  perUnitPrices: number[],
  color: string,
) =>
  categorySeats.map((categorySeat, index) => {
    const [section, row, seat] = categorySeat.split('-');

    return {
      id: categorySeat,
      price: perUnitPrices[index],
      categoryName,
      section,
      row,
      seat,
      color,
    };
  });

const getD3ColorSchemes = () => [
  ...d3.schemeCategory10,
  ...d3.schemeAccent,
  ...d3.schemeDark2,
  ...d3.schemePaired,
  ...d3.schemeSet1,
  ...d3.schemeSet2,
  ...d3.schemeTableau10,
];
