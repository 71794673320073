import { uniq } from 'lodash';

import { TableEditableColumnProps } from 'models/table/tableEditableColumn.props';

export const getFilterListData = <T>(
  data: T[],
  selector: (e: T) => string,
  renderText?: (value) => string,
) => {
  const uniqData = uniq(data.flatMap(selector).filter(Boolean));

  return uniqData.map((value) => ({
    text: renderText ? renderText(value) : value,
    value,
  }));
};

export const getOnCellEditableProps = <T>(
  record: T,
  column: TableEditableColumnProps<T>,
) => {
  const editConfig = column.edit?.(column.key.toString(), record);

  return {
    record,
    dataIndex: column.dataIndex,
    title: column.title,
    isEditable: editConfig?.isEnabled ?? false,
    isEditing: editConfig?.isActive ?? false,
    inputProps: editConfig?.inputProps,
  };
};
