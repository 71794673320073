import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import intl from 'react-intl-universal';
import { Button } from 'antd';
import { Formik, Form, Field, FormikHelpers } from 'formik';

import { AuthStore } from 'stores/auth.store';
import { CommonStore } from 'stores/common.store';
import { LoginFormModel } from 'models/form-models/login-form.model';
import { supportEmail } from 'utils/constants';
import { UserStore } from 'stores/user.store';
import { isSupplierUser } from 'utils/userRolesUtils';
import { InputContainer } from 'components/FormContainers';
import SupplierRegistrationModal from 'components/SharedComponents/SupplierRegistrationModal';
import { useQueryParamModal } from 'hooks/useQueryParamModal';
import { withStore } from 'hocs';

type Props = {
  userStore?: UserStore;
  authStore?: AuthStore;
  commonStore?: CommonStore;
};

function Login(props: Props) {
  const { authStore, userStore, commonStore } = props;
  const { brandingSettings, agentRegistration } = commonStore.siteSettings;
  const {
    portalLogo,
    portalLogoCaption,
    splashImage,
    splashImageCaption,
    portalName,
  } = brandingSettings;

  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(null);
  const queryParamModalHelper = useQueryParamModal(agentRegistration?.LinkUrl);

  useEffect(() => {
    if (authStore.isLoggedIn) {
      authStore.logOut();
    }
  }, []);

  const handleSubmit = async (
    values: LoginFormModel,
    actions: FormikHelpers<LoginFormModel>,
  ) => {
    if (loginError) {
      setLoginError(null);
    }

    try {
      await authStore.login(values);

      const path = isSupplierUser(userStore.userProperties)
        ? '/inventory'
        : '/marketplace';

      navigate(path);
    } catch (error) {
      setLoginError(error);
    }

    actions.setSubmitting(false);
  };

  const renderLoginError = () => {
    if (loginError) {
      let errorMessage = 'globalErrors.loginError';

      switch (loginError.message) {
        case 'invalid role':
          errorMessage = 'globalErrors.loginRoleError';
          break;
        case 'no user data':
          errorMessage = 'globalErrors.loginDataError';
          break;
      }

      if (loginError.response) {
        const { data } = loginError.response;

        if (data.ModelState) {
          const message: string = data.ModelState.Login[0];
          if (message.includes('The account has not been confirmed')) {
            errorMessage = 'globalErrors.unverifiedAccountError';
          }
        }
      }

      return (
        <div className="login-incorrect">
          <span>{intl.get(errorMessage)}</span>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="login-page">
      <Formik
        validateOnChange
        initialValues={new LoginFormModel()}
        validationSchema={Yup.object().shape({
          userName: Yup.string().required(intl.get('required')),
          password: Yup.string()
            .min(6, intl.get('minValidator', { size: 6 }))
            .required(intl.get('required')),
        })}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className="login-form">
            <div className="form-wrapper">
              <div className="form-logo-container">
                <img
                  className="form-logo"
                  src={portalLogo}
                  alt={portalLogoCaption}
                />
              </div>
              <h2
                className="login-header"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: portalName }}
              />
              <div className="fields-wrapper">
                <Field
                  name="userName"
                  component={InputContainer}
                  placeholder={intl.get('labels.username')}
                  prefix={<i className="tc-user input-prefix" />}
                />
                <Field
                  name="password"
                  component={InputContainer}
                  placeholder={intl.get('labels.password')}
                  type="password"
                  prefix={<i className="tc-lock input-prefix" />}
                />
                {renderLoginError()}
              </div>
              <div className="login-page-bottom-container">
                <div className="login-page-bottom">
                  <Button
                    className="login-button"
                    type="primary"
                    htmlType="submit"
                    size="large"
                  >
                    {intl.get('login')} <i className="tc-login login-icon" />
                  </Button>
                  <div className="forgot-password">
                    <Link to="/forgot-password">
                      {intl.get('buttons.forgotPassword')}
                    </Link>
                  </div>
                </div>

                {agentRegistration?.LinkUrl && (
                  <Button
                    block
                    type="link"
                    onClick={queryParamModalHelper.open}
                  >
                    {intl.get('noAccount')}
                  </Button>
                )}
              </div>
              <div className="support-message">
                {intl.get('supportMessage')}
              </div>
              <div className="support-message">
                {intl.get('contactUs')}{' '}
                <a className="support-email" href={`mailto:${supportEmail}`}>
                  {supportEmail}
                </a>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <aside className="right-container">
        <img className="logo" src={splashImage} alt={splashImageCaption} />
      </aside>

      {agentRegistration?.FormUrl && (
        <SupplierRegistrationModal
          url={agentRegistration.FormUrl}
          open={queryParamModalHelper.isOpen()}
          onCancel={queryParamModalHelper.close}
        />
      )}
    </div>
  );
}

export default withStore(({ rootStore }) => ({
  userStore: rootStore.userStore,
  authStore: rootStore.authStore,
  commonStore: rootStore.commonStore,
}))(Login);
