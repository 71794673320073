import { makeObservable, action } from 'mobx';

import { GlobalMessagesStore } from './globalMessages.store';
import { UserStore } from './user.store';
import { UserApi } from '../api/user.api';
import { AuthStore } from './auth.store';
import { AuthApi } from '../api/auth.api';
import { CommonStore } from './common.store';
import { CommonApi } from '../api/common.api';
import { OrdersStore } from './orders.store';
import { OrdersApi } from '../api/orders.api';
import { TourStore } from './tour.store';
import { TourApi } from '../api/tour.api';
import { MarketplaceStore } from './marketplace.store';
import { MarketplaceApi } from '../api/marketplace.api';
import { CheckoutStore } from './checkout.store';
import { CheckoutApi } from '../api/checkout.api';
import { CartStore } from './cart.store';
import { AgenciesStore } from './agencies.store';
import { AgenciesApi } from '../api/agencies.api';
import { SuppliersStore } from './suppliers.store';
import { SuppliersApi } from '../api/suppliers.api';
import { InventoryStore } from './inventory.store';
import { InventoryApi } from '../api/inventory.api';
import { BulkInventoryStore } from './bulkInventory.store';
import { MessageTemplatesStore } from './messageTemplates.store';
import { NotificationTemplatesApi } from '../api/notificationTemplates.api';
import { NotificationsApi } from '../api/notifications.api';
import { UserFiltersStore } from './userFilters.store';
import { UserFiltersApi } from '../api/userFilters.api';
import { MessagesApi } from '../api/messages.api';
import { MessagesStore } from './messages.store';

export class RootStore {
  globalMessagesStore: GlobalMessagesStore;
  userStore: UserStore;
  authStore: AuthStore;
  commonStore: CommonStore;
  ordersStore: OrdersStore;
  userFiltersStore: UserFiltersStore;
  tourStore: TourStore;
  marketplaceStore: MarketplaceStore;
  cartStore: CartStore;
  checkoutStore: CheckoutStore;
  agenciesStore: AgenciesStore;
  suppliersStore: SuppliersStore;
  inventoryStore: InventoryStore;
  bulkInventoryStore: BulkInventoryStore;
  messageTemplatesStore: MessageTemplatesStore;
  messagesStore: MessagesStore;

  constructor() {
    makeObservable(this);

    this.userStore = new UserStore(this, new UserApi());
    this.authStore = new AuthStore(this, new AuthApi(), new UserApi());
    this.commonStore = new CommonStore(this, new CommonApi());
    this.ordersStore = new OrdersStore(
      this,
      new OrdersApi(),
      new CheckoutApi(),
      new NotificationsApi(),
      new NotificationTemplatesApi(),
    );
    this.userFiltersStore = new UserFiltersStore(this, new UserFiltersApi());
    this.tourStore = new TourStore(this, new TourApi());
    this.marketplaceStore = new MarketplaceStore(this, new MarketplaceApi());
    this.cartStore = new CartStore(this);
    this.checkoutStore = new CheckoutStore(this, new CheckoutApi());
    this.agenciesStore = new AgenciesStore(this, new AgenciesApi());
    this.suppliersStore = new SuppliersStore(this, new SuppliersApi());
    this.inventoryStore = new InventoryStore(
      this,
      new InventoryApi(),
      new AgenciesApi(),
    );
    this.bulkInventoryStore = new BulkInventoryStore(this, new InventoryApi());
    this.globalMessagesStore = new GlobalMessagesStore(this);
    this.messageTemplatesStore = new MessageTemplatesStore(
      this,
      new NotificationTemplatesApi(),
    );
    this.messagesStore = new MessagesStore(this, new MessagesApi());
  }

  @action
  public cleanEntireStorage() {
    this.userStore.clearStore();
    this.authStore.clearStore();
    this.commonStore.clearStore();
    this.ordersStore.clearStore();
    this.tourStore.cleanStore();
    this.marketplaceStore.clearStore();
    this.cartStore.clearStore();
    this.checkoutStore.clearStore();
    this.agenciesStore.clearStore();
    this.suppliersStore.clearStore();
    this.inventoryStore.clearStore();
    this.bulkInventoryStore.clearStore();
    this.messageTemplatesStore.clearStore();
  }
}
