import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { reaction } from 'mobx';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import { CheckoutStore } from '../../../../stores/checkout.store';
import { UserStore } from '../../../../stores/user.store';
import { moneyFormat } from '../../../../utils/formatter';
import { PaymentMethodsEnum } from '../../../../models/enums/paymentMethods.enum';
import { PaymentMethodProfileModel } from '../../../../models/paymentMethodProfile.model';
import NewCreditCard from './NewCreditCard';
import AcceptedCreditCardsIcons from './AcceptedCreditCardsIcons';
import { withStore } from '../../../../hocs';

const RadioGroup = Radio.Group;
const newCardPaymentMethod = 'New Card';
const paymentValidName = 'checkoutPaymentMethod';
@withStore(({ rootStore }) => ({
  checkoutStore: rootStore.checkoutStore,
  userStore: rootStore.userStore,
}))
export default class PaymentOptions extends Component<{
  checkoutStore?: CheckoutStore;
  userStore?: UserStore;
  isPrefilled: boolean;
}> {
  disposer = null;

  state = {
    selectedPaymentOption: undefined,
    isErrorMessageVisible: false,
  };

  componentDidMount() {
    const { checkoutStore } = this.props;
    // there we should check if pay later in ane available option then select it by default
    // for Tourschain White Label customization there are two options: payLater, balance
    const isOptionValid = false;

    checkoutStore.updateValid(paymentValidName, isOptionValid);

    // there we should check if pay later in ane available option then select it by default
    // for Tourschain White Label customization there are two options: payLater, balance
    this.checkAndSetDefaultPaymentOption();

    this.disposer = reaction(
      () => checkoutStore.shouldValidate,
      (shouldValidate) => shouldValidate > 0 && this.validate(),
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  get balanceAfterOrder() {
    const { checkoutStore } = this.props;

    return this.fundsBalance - checkoutStore.total;
  }

  get fundsBalance() {
    const { userStore } = this.props;

    if (userStore && userStore.financeInfo) {
      return userStore.financeInfo.FundsBalance;
    }

    return 0;
  }

  get availablePaymentMethods() {
    const { checkoutStore, isPrefilled } = this.props;
    const isPaymentBalanceUnavailable =
      !this.isDataLoaded ||
      this.fundsBalance === 0 ||
      this.balanceAfterOrder < 0;
    const isPayLaterAvailable =
      !isPrefilled && !checkoutStore.isPaymentRequired;

    return [
      PaymentMethodsEnum.creditCard,
      !isPaymentBalanceUnavailable && PaymentMethodsEnum.balance,
      isPayLaterAvailable && PaymentMethodsEnum.payLater,
    ].filter(Boolean);
  }

  get isDataLoaded() {
    const { userStore } = this.props;

    return (
      userStore &&
      userStore.financeInfo &&
      userStore.financeInfo.FundsBalance !== undefined
    );
  }

  validate = () => {
    const { checkoutStore } = this.props;
    const isPaymentOptionsValid =
      checkoutStore.isItemsInCheckoutValid(paymentValidName);

    this.setState({
      isErrorMessageVisible: !isPaymentOptionsValid,
    });
  };

  handlePaymentMethodChange = (event: RadioChangeEvent) => {
    const { checkoutStore } = this.props;
    const isOptionValid = !!event.target.value;

    checkoutStore.changePaymentMethod(event.target.value);
    checkoutStore.updateValid(paymentValidName, isOptionValid);
    this.setState({ selectedPaymentOption: undefined });
  };

  checkAndSetDefaultPaymentOption = () => {
    const { userStore, checkoutStore } = this.props;
    const isDataLoaded =
      userStore &&
      userStore.financeInfo &&
      userStore.financeInfo.FundsBalance !== undefined;
    const isPaymentBalanceUnavailable =
      !isDataLoaded || this.fundsBalance === 0 || this.balanceAfterOrder < 0;

    if (this.availablePaymentMethods.length === 1) {
      const isOptionValid = true;
      const paymentMethod = this.availablePaymentMethods[0];

      checkoutStore.changePaymentMethod(paymentMethod);
      checkoutStore.updateValid(paymentValidName, isOptionValid);
      this.setState({ selectedPaymentOption: paymentMethod });

      return;
    }

    if (isPaymentBalanceUnavailable) {
      const isOptionValid = true;

      checkoutStore.changePaymentMethod(PaymentMethodsEnum.payLater);
      checkoutStore.updateValid(paymentValidName, isOptionValid);
      this.setState({ selectedPaymentOption: undefined });
    }
  };

  changeCardInStore = (selectedPaymentOption: string) => {
    const { checkoutStore, userStore } = this.props;
    let selectedCard: PaymentMethodProfileModel;

    if (selectedPaymentOption !== newCardPaymentMethod) {
      selectedCard = userStore.financeInfo.Profiles.find(
        (card: PaymentMethodProfileModel) =>
          card.ProfileID === selectedPaymentOption,
      );
    }

    checkoutStore.changeSelectedCard(selectedCard);
  };

  renderCreditCardSections = () => {
    const { selectedPaymentOption } = this.state;

    const defaultValue = newCardPaymentMethod;
    const selectedValue = selectedPaymentOption || defaultValue;

    this.changeCardInStore(selectedValue);

    return (
      <div>
        <NewCreditCard />
      </div>
    );
  };

  render() {
    const { checkoutStore } = this.props;
    const { isErrorMessageVisible } = this.state;

    if (
      this.fundsBalance === 0 &&
      checkoutStore.paymentMethod.paymentMethodType ===
        PaymentMethodsEnum.balance
    ) {
      checkoutStore.changePaymentMethod(PaymentMethodsEnum.creditCard);
      this.setState({ selectedPaymentOption: undefined });
    }

    return (
      <div className="payment-options">
        <h3 className="payment-options-header">
          {intl.get('checkout.paymentOptions')} &nbsp;
        </h3>
        <RadioGroup
          onChange={this.handlePaymentMethodChange}
          value={checkoutStore.paymentMethod.paymentMethodType}
        >
          {this.availablePaymentMethods.includes(
            PaymentMethodsEnum.balance,
          ) && (
            <Radio
              value={PaymentMethodsEnum.balance}
              className="payment-option"
            >
              {intl.get('checkout.useMyCurrentBalance')}(
              {moneyFormat(this.fundsBalance)})
              {this.balanceAfterOrder < 0 && (
                <span className="danger-text">
                  {intl.get('checkout.insufficientFunds')}
                </span>
              )}
            </Radio>
          )}

          {checkoutStore.paymentMethod.paymentMethodType ===
            PaymentMethodsEnum.balance && (
            <div className="balance-after-order">
              {`${intl.get('checkout.balanceAfterOrder')}: ${moneyFormat(
                this.balanceAfterOrder,
              )}`}
            </div>
          )}

          <Radio
            value={PaymentMethodsEnum.creditCard}
            className="payment-option"
          >
            <div className="d-if">
              {intl.get('checkout.payByCreditCard')}

              <AcceptedCreditCardsIcons className="m-l-20" />
            </div>
          </Radio>

          {checkoutStore.paymentMethod.paymentMethodType ===
            PaymentMethodsEnum.creditCard && this.renderCreditCardSections()}

          {this.availablePaymentMethods.includes(
            PaymentMethodsEnum.payLater,
          ) && (
            <Radio
              value={PaymentMethodsEnum.payLater}
              className="payment-option"
              disabled={!this.isDataLoaded}
            >
              {intl.get('checkout.payLater')}
            </Radio>
          )}
        </RadioGroup>

        {isErrorMessageVisible && (
          <div className="payment-option-error-message">
            <span>{intl.get('checkout.paymentRequired')}</span>
          </div>
        )}
      </div>
    );
  }
}
