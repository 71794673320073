/* eslint-disable react/no-danger */
import React from 'react';

export default function Footer() {
  const sections = [
    "<a href='/privacy-policy' target='_blank'>Privacy policy</a>",
    "<a href='/terms' target='_blank'>Terms & Conditions</a>",
    "<a href='/terms?isHelpAndSupportOpened=true' target='_blank'>Contact us</a>",
  ];

  const social = [
    "<a href='https://www.instagram.com/travelusaexpress/' target='_blank'><img src='https://chd.azureedge.net/images/instagram.svg' /></a>",
    "<a href='https://facebook.com/travelusaexpress' target='_blank'><img src='https://chd.azureedge.net/images/facebook-white.svg' /></a>",
    "<a href='https://twitter.com/travelusaexp' target='_blank'><img src='https://chd.azureedge.net/images/twitter.svg' /></a>",
  ];

  const copyright =
    'Copyright &copy; 2022 Travel USA Express - all rights reserved';

  const separator = '|';

  return (
    <footer className="footer">
      <div className="container-huge footer__container">
        <div className="footer__sections">
          {sections.map((section, index) => (
            <React.Fragment key={section}>
              <div
                key={section}
                dangerouslySetInnerHTML={{
                  __html: section,
                }}
              />

              {sections.length - 1 !== index && (
                <span className="footer__sections-separator">{separator}</span>
              )}
            </React.Fragment>
          ))}
        </div>

        <div className="footer__social">
          {social.map((section) => (
            <div
              className="footer__social-element"
              key={section}
              dangerouslySetInnerHTML={{
                __html: section,
              }}
            />
          ))}
        </div>

        <p
          className="footer__copyright"
          dangerouslySetInnerHTML={{
            __html: copyright,
          }}
        />
      </div>
    </footer>
  );
}
