import { observable, action, makeObservable } from 'mobx';
import intl from 'react-intl-universal';

import { AgenciesApi } from '../api/agencies.api';

import { RootStore } from './root.store';

import { addGlobalMessage } from '../components/SharedComponents/GlobalMessages';
import { MessageTypeEnum } from '../models/global-messages/message.model';
import { AgencyModel } from '../models/contacts/agency.model';

export class AgenciesStore {
  private rootStore: RootStore;
  private apiService: AgenciesApi;

  @observable public agencies: Array<AgencyModel> = [];
  @observable public isAgenciesLoading = false;
  @observable public isAgencyLoading = false;

  constructor(rootStore: RootStore, apiService: AgenciesApi) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.apiService = apiService;
  }

  @action
  public async getAgencies() {
    try {
      this.isAgenciesLoading = true;
      const response = await this.apiService.getAgencies();
      const { data } = response;
      const agencies = data.value;

      this.agencies = observable(agencies);

      this.isAgenciesLoading = false;

      return Promise.resolve();
    } catch (error) {
      this.isAgenciesLoading = false;

      addGlobalMessage({
        message: intl.get('contacts.agencies.failedToLoadAgencies'),
        type: MessageTypeEnum.error,
      });

      return Promise.reject();
    }
  }

  @action
  public async createAgency(agency) {
    try {
      this.isAgencyLoading = true;
      const response = await this.apiService.createAgency(agency);

      this.isAgencyLoading = false;

      addGlobalMessage({
        message: intl.get('contacts.agencies.agencyHasBeenSuccessfullyCreated'),
        type: MessageTypeEnum.success,
      });

      this.getAgencies();

      return Promise.resolve(response);
    } catch (error) {
      this.isAgencyLoading = false;

      addGlobalMessage({
        message: intl.get('contacts.agencies.failedToCreateAgency'),
        type: MessageTypeEnum.error,
      });

      return Promise.reject();
    }
  }

  @action
  public async editAgency(agencyId, agency) {
    try {
      this.isAgencyLoading = true;
      const response = await this.apiService.editAgency(agencyId, agency);

      this.isAgencyLoading = false;

      addGlobalMessage({
        message: intl.get('contacts.agencies.agencyHasBeenSuccessfullyEdited'),
        type: MessageTypeEnum.success,
      });

      this.getAgencies();

      return Promise.resolve(response);
    } catch (error) {
      this.isAgencyLoading = false;

      addGlobalMessage({
        message: intl.get('contacts.agencies.failedToEditAgency'),
        type: MessageTypeEnum.error,
      });

      return Promise.reject();
    }
  }

  // @action
  // public async deleteAgency(agencyId) {
  //     try {
  //         this.isAgencyLoading = true;
  //         await this.apiService.deleteAgency(agencyId);

  //         this.isAgencyLoading = false;

  //         this.getAgencies();

  //         addGlobalMessage({
  //             message: intl.get('contacts.agencies.agencyHasBeenSuccessfullyDeleted'),
  //             type: MessageTypeEnum.success
  //         });

  //         return Promise.resolve();
  //     } catch (error) {
  //         this.isAgencyLoading = false;

  //         addGlobalMessage({
  //             message: intl.get('contacts.agencies.failedToDeleteAgency'),
  //             type: MessageTypeEnum.error
  //         });

  //         return Promise.reject();
  //     }
  // }

  @action
  public clearStore() {
    this.agencies = [];
    this.isAgenciesLoading = false;
    this.isAgencyLoading = false;
  }
}
