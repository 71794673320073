import intl from 'react-intl-universal';

import { ProductTypeEnum } from '../models/enums/productType.enum';

export const getProductTypeLabel = (productType: ProductTypeEnum) => {
  switch (productType) {
    case ProductTypeEnum.Service:
      return intl.get('productType.standardProduct');
    case ProductTypeEnum.Hotel:
      return intl.get('productType.hotel');
    case ProductTypeEnum.Package:
      return intl.get('productType.packagedProduct');
  }
};
