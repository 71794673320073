import Handlebars from 'handlebars/dist/cjs/handlebars';
import helperMoment from 'helper-moment';

import { helperHtmlToText } from './helperHtmlToText';

export function registerHandlebarsHelpers() {
  Handlebars.registerHelper({
    moment: helperMoment,
    html_to_text: helperHtmlToText,
  });
}
