import { compact, isArray, isEmpty } from 'lodash';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { TablePaginationConfig } from 'antd';

import { stringifyQueryString } from './urlUtils';

export class TableQueryBuilder<T> {
  public static SORTS_DELIMITER = ',';
  public static FILTERS_DELIMITER = ';';
  private readonly queryObject = {};

  constructor(queryObject: Record<string, unknown> = {}) {
    this.queryObject = queryObject;
  }

  private sorter: SorterResult<T> | SorterResult<T>[];

  private filters: Record<string, FilterValue>;

  private pagination: TablePaginationConfig;

  private buildSorterQuery(sorter: SorterResult<T>) {
    if (!sorter.order) return '';

    const prefix = sorter.order === 'descend' ? '-' : null;

    return compact([prefix, sorter.column.key]).join('');
  }

  private getSorterQuery() {
    if (this.isEmptySorter()) return undefined;

    if (isArray(this.sorter)) {
      return this.sorter
        .map((sorter) => this.buildSorterQuery(sorter))
        .join(TableQueryBuilder.SORTS_DELIMITER);
    }

    return this.buildSorterQuery(this.sorter);
  }

  private buildFilterQuery(key: string, filterValue: FilterValue) {
    return [key, '=', filterValue].join('');
  }

  private getFiltersQuery() {
    if (this.isEmptyFilters()) return undefined;

    return Object.entries(this.filters)
      .filter(([, value]) => value)
      .map(([key, value]) => this.buildFilterQuery(key, value))
      .join(TableQueryBuilder.FILTERS_DELIMITER);
  }

  private getPaginationQuery() {
    if (isEmpty(this.pagination)) return null;

    return {
      page: this.pagination.current,
      pageSize: this.pagination.pageSize,
    };
  }

  private isEmptySorterResult(sorter: SorterResult<T>) {
    return !sorter?.order;
  }

  private isEmptySorter() {
    return isArray(this.sorter)
      ? this.sorter.every(this.isEmptySorterResult)
      : this.isEmptySorterResult(this.sorter);
  }

  private isEmptyFilters() {
    return Object.values(this.filters).every((value) => isEmpty(value));
  }

  public setSorter(sorter: SorterResult<T> | SorterResult<T>[]) {
    this.sorter = sorter;

    return this;
  }

  public setFilters(filters: Record<string, FilterValue>) {
    this.filters = filters;

    return this;
  }

  public setPagination(pagination: TablePaginationConfig) {
    this.pagination = pagination;

    return this;
  }

  public build() {
    return stringifyQueryString({
      ...this.queryObject,
      ...this.getPaginationQuery(),
      sorts: this.getSorterQuery(),
      filters: this.getFiltersQuery(),
    });
  }
}
