import React from 'react';
import { Collapse } from 'antd';
import intl from 'react-intl-universal';

function LandingFaq() {
  const panels = [
    {
      key: 1,
      caption: intl.get('landingFaq.1.caption'),
      description: intl.get('landingFaq.1.description'),
    },
    {
      key: 2,
      caption: intl.get('landingFaq.2.caption'),
      description: intl.get('landingFaq.2.description'),
    },
    {
      key: 3,
      caption: intl.get('landingFaq.3.caption'),
      description: intl.get('landingFaq.3.description'),
    },
    {
      key: 4,
      caption: intl.get('landingFaq.4.caption'),
      description: intl.get('landingFaq.4.description'),
    },
    {
      key: 5,
      caption: intl.get('landingFaq.5.caption'),
      description: intl.get('landingFaq.5.description'),
    },
  ];

  return (
    <section className="landing-faq">
      <div className="landing-faq__container">
        <div className="landing-faq__inner">
          <h2 className="landing-faq__caption">
            {intl.get('landingFaq.caption')}
          </h2>

          <div className="landing-faq-collapse">
            <Collapse
              ghost
              accordion
              defaultActiveKey={['1']}
              bordered={false}
              className="landing-faq-collapse__inner"
            >
              {panels.map((panel) => (
                <Collapse.Panel
                  key={panel.key}
                  showArrow={false}
                  header={panel.caption}
                >
                  {panel.description}
                </Collapse.Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LandingFaq;
