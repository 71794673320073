import intl from 'react-intl-universal';

import { OrderTableDataModel } from 'models/orders/orderTableData.model';
import { isAdult, isChild } from '../../../../utils/guestsUtils';

export const getCustomerUnnamedLabel = (record: OrderTableDataModel) => {
  const { guest } = record;

  if (!guest) return '';

  if (isChild(guest.Age)) {
    return intl.get('orders.table.unnamedChild', { age: guest.Age });
  }

  if (isAdult(guest.Age)) {
    return intl.get('orders.table.unnamedAdult');
  }

  return '';
};
