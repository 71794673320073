import React from 'react';
import { Provider } from 'mobx-react';
import { RouterProvider } from 'react-router-dom';

import { RootStore } from './stores/root.store';
import { router } from './router';

export const rootStore = new RootStore();

export default () => (
  <Provider rootStore={rootStore}>
    <RouterProvider router={router} />
  </Provider>
);
