export const isValidJSONString = (jsonString: string) => {
  try {
    JSON.parse(jsonString);

    return true;
  } catch (e) {
    return false;
  }
};

export const safeJSONStringify = (
  ...args: Parameters<typeof JSON.stringify>
) => {
  try {
    return JSON.stringify(...args);
  } catch (e) {
    return '';
  }
};

export const safeJSONParse = (...args: Parameters<typeof JSON.parse>) => {
  try {
    return JSON.parse(...args);
  } catch (e) {
    return {};
  }
};
