import React, { Component } from 'react';
import { Link, Location, NavLink } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Dropdown, Menu, Select, Modal, Popover } from 'antd';
import classNames from 'classnames';
import { CalendarOutlined } from '@ant-design/icons';

import { router } from 'router';
import Accessor from 'components/SharedComponents/Accessor';
import { AuthStore } from '../../../stores/auth.store';
import { UserStore } from '../../../stores/user.store';
import { CartStore } from '../../../stores/cart.store';
import { CommonStore } from '../../../stores/common.store';
import {
  availableLanguages,
  languageNames,
} from '../../../models/enums/language.enums';
import HelpAndSupport from '../HelpAndSupport';
import CartMinimized from '../../Pages/Cart/CartMinimized';
import { USER_ROLE_GROUPS } from '../../../utils/constants';
import { isSupplierUser } from '../../../utils/userRolesUtils';
import ProductsHoldTimer from '../ProductsHoldTimer';
import { RouterProps, withRouter, withStore } from '../../../hocs';

type Props = {
  authStore?: AuthStore;
  userStore?: UserStore;
  cartStore?: CartStore;
  commonStore?: CommonStore;
} & RouterProps;

@withRouter
@withStore(({ rootStore }) => ({
  authStore: rootStore.authStore,
  userStore: rootStore.userStore,
  cartStore: rootStore.cartStore,
  commonStore: rootStore.commonStore,
}))
export default class Header extends Component<Props> {
  state = {
    isHelpAndSupportVisible: false,
    helpAndSupportCategory: null,
    isCartHoverVisible: false,
  };

  componentDidMount() {
    const { location } = this.props;

    this.handleHistory(location);

    router.subscribe((state) => {
      this.handleHistory(state.location);
    });
  }

  handleHistory = (location: Location) => {
    const params = new URLSearchParams(location.search);

    if (params.has('isHelpAndSupportOpened')) {
      let category;
      if (params.has('helpCategory')) {
        category = params.get('helpCategory');
      }

      this.setState({
        isHelpAndSupportVisible: true,
        helpAndSupportCategory: category,
      });
    } else {
      this.setState({
        isHelpAndSupportVisible: false,
        helpAndSupportCategory: null,
      });
    }
  };

  handleLogout = () => {
    const { authStore } = this.props;

    authStore.logOut();
  };

  handleLanguageChange = (value: string) => {
    const { userStore } = this.props;

    userStore.changeLanguage(value);
  };

  handleHelpAndSupportVisible = (isVisible) => () => {
    const { location, navigate } = this.props;

    const params = new URLSearchParams(location.search);

    if (isVisible) {
      params.set('isHelpAndSupportOpened', 'true');
    } else {
      params.delete('isHelpAndSupportOpened');
      params.delete('helpCategory');
    }

    navigate({ search: params.toString() });
  };

  getLogoRedirectURL = () => {
    const { userStore } = this.props;
    const clearParams = new URLSearchParams();
    const path = isSupplierUser(userStore.userProperties)
      ? '/inventory'
      : '/marketplace';

    clearParams.set('page', '1');

    return path;
  };

  handleHoverCart = (isCartHoverVisible) =>
    this.setState({ isCartHoverVisible });

  renderDropdown = () => (
    <Menu className="menu-dropdown">
      <Menu.Item key="0" className="menu-item menu-item-profile">
        <Link to="/profile">
          <i className="tc-account" />
          <span>{intl.get('header.profile')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={this.handleHelpAndSupportVisible(true)}
        className="menu-item"
      >
        <i className="tc-comments" />
        <span>{intl.get('header.helpAndSupport')}</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" className="menu-item" onClick={this.handleLogout}>
        <span>{intl.get('buttons.logout')}</span>
      </Menu.Item>
    </Menu>
  );

  render() {
    const {
      userStore,
      commonStore,
      cartStore: { countOfCardsInCart, cardsInCart },
    } = this.props;
    const { portalLogo, portalLogoCaption } =
      commonStore.siteSettings.brandingSettings;
    const {
      isHelpAndSupportVisible,
      helpAndSupportCategory,
      isCartHoverVisible,
    } = this.state;
    const cartPopoverContent = (
      <CartMinimized closePopover={() => this.handleHoverCart(false)} />
    );

    return (
      <div className="header-page">
        <Link to={this.getLogoRedirectURL()}>
          <img className="logo" src={portalLogo} alt={portalLogoCaption} />
        </Link>
        <div className="right-menu">
          <div className="activity-buttons">
            <Select
              defaultValue={userStore.language}
              onChange={this.handleLanguageChange}
              className="language-select"
              dropdownClassName="language-select-dropdown"
              suffixIcon={<i className="tc-dropdown" />}
            >
              <Select.Option value={availableLanguages.ENG}>
                {languageNames[availableLanguages.ENG]}
              </Select.Option>
              <Select.Option value={availableLanguages.CHI}>
                {languageNames[availableLanguages.CHI]}
              </Select.Option>
            </Select>

            <Accessor roles={USER_ROLE_GROUPS.MARKETPLACE}>
              <div className="d-f">
                <ProductsHoldTimer
                  cardsInCart={cardsInCart}
                  timerWrapperClassName="m-r-15"
                />

                <Popover
                  trigger="hover"
                  visible={isCartHoverVisible}
                  onVisibleChange={this.handleHoverCart}
                  content={cartPopoverContent}
                  overlayClassName={classNames('cart-minimized-popover', {
                    'cart-minimized-popover-empty-card': !countOfCardsInCart,
                  })}
                >
                  <NavLink
                    to="/cart"
                    onMouseEnter={() => this.handleHoverCart(true)}
                    className={({ isActive }) =>
                      classNames('shopping-cart', { 'active-link': isActive })
                    }
                  >
                    <i className="tc-cart cart-icon" />
                    {countOfCardsInCart > 0 && (
                      <div className="count-card">{countOfCardsInCart}</div>
                    )}
                  </NavLink>
                </Popover>
              </div>
            </Accessor>

            <Accessor roles={USER_ROLE_GROUPS.SUPPLIER}>
              <NavLink to="/calendar">
                <div className="top-menu-calendar">
                  <CalendarOutlined className="f-s-20" />
                </div>
              </NavLink>
            </Accessor>
          </div>
          {userStore.userProperties && (
            <Dropdown trigger={['click']} overlay={this.renderDropdown()}>
              <div className="user-controls">
                <i className="user-icon tc-user11" />
                <span className="bold">
                  {userStore.userProperties.UserName}
                </span>
              </div>
            </Dropdown>
          )}
        </div>
        <Modal
          destroyOnClose
          visible={isHelpAndSupportVisible}
          footer={null}
          onCancel={this.handleHelpAndSupportVisible(false)}
          cancelButtonProps={{ size: 'large' }}
        >
          <HelpAndSupport defaultCategory={helpAndSupportCategory} />
        </Modal>
      </div>
    );
  }
}
