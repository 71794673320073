import { InputNumber, Slider, Space } from 'antd';
import React from 'react';

type Props = {
  label?: string;
  min: number;
  max: number;
  value: [number, number];
  onFromChange: (value: number) => void;
  onSliderChange: (value: [number, number]) => void;
  onToChange: (value: number) => void;
};

export default function InventoryBulkViewQuantityFilter(props: Props) {
  const { label, min, max, value, onFromChange, onSliderChange, onToChange } =
    props;

  return (
    <>
      {label && <label>{label}</label>}

      <Space>
        <InputNumber
          min={min}
          max={max}
          value={value[0] ?? min}
          onChange={onFromChange}
        />

        <Slider
          range
          min={min}
          max={max}
          value={value}
          marks={{
            [min]: min,
            [max]: max,
          }}
          defaultValue={[min, max]}
          onChange={onSliderChange}
          style={{ display: 'block', minWidth: 200 }}
        />

        <InputNumber
          min={min}
          max={max}
          value={value[1] ?? max}
          onChange={onToChange}
        />
      </Space>
    </>
  );
}
