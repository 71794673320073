import React from 'react';

import { InputNumberContainer } from './index';
import { InputNumberContainerProps } from './inputnumber.container';
import { CommissionTypeEnum } from '../../models/enums/commissionType.enum';

type Props = InputNumberContainerProps & {
  type: CommissionTypeEnum;
};

function CommissionContainer(props: Props) {
  const { type, ...restProps } = props;

  return (
    <InputNumberContainer
      {...restProps}
      min={type === CommissionTypeEnum.Percentage ? 0 : undefined}
      max={type === CommissionTypeEnum.Percentage ? 100 : undefined}
    />
  );
}

export default CommissionContainer;
