export enum NotificationStatusEnum {
  Queued = 'Queued',
  Sent = 'Sent',
  Delivered = 'Delivered',
  Received = 'Received',
  Canceled = 'Delayed',
  Click = 'Clicked',
  Read = 'Read',
  Failed = 'Failed',
  Undelivered = 'Undelivered',
  SpamReport = 'SpamReport',
  Unsubscribe = 'Unsubscribed',
}
