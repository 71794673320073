/* eslint-disable no-console */
import { action, computed, makeObservable, observable } from 'mobx';
import intl from 'react-intl-universal';
import { isEmpty, isNil, orderBy } from 'lodash';
import moment from 'moment';
import filterBuilder from 'odata-filter-builder';
import { matchPath } from 'react-router-dom';
import axios from 'axios';

import { router } from 'router';
import { ProductAvailabilityFiltersFormModel } from 'models/inventory/productAvailabilityFiltersForm.model';
import { parseQueryString } from 'utils/urlUtils';
import { InventoryApi } from '../api/inventory.api';
import { RootStore } from './root.store';
import { addGlobalMessage } from '../components/SharedComponents/GlobalMessages';
import { MessageTypeEnum } from '../models/global-messages/message.model';
import { ProductDetailsModel } from '../models/inventory/productDetails.model';
import { ProductTypeModel } from '../models/inventory/productType.model';
import { CategoryModel } from '../models/inventory/category.model';
import { InventoryModel } from '../models/inventory/inventory.model';
import { LocationModel } from '../models/inventory/locations.model';
import {
  DEFAULT_AGENCY,
  PACKAGE_AGE_TYPES,
  SERVICE_AGE_TYPES,
} from '../utils/constants';
import { getInitialTimes } from '../components/Pages/Inventory/components/ProductDetailsPopup/ProductPopup';
import { ProductTypeEnum } from '../models/enums/productType.enum';
import { ProductCardModel } from '../models/inventory/productCard.model';
import { VisibilityEnum } from '../models/enums/visibility.enum';
import { FormRateModel } from '../models/inventory/formRate.model';
import {
  getVisibilityOptionLabel,
  getVisibilityValue,
} from '../utils/rateUtils';
import { AgenciesApi } from '../api/agencies.api';
import FormRatesMapper from '../utils/formRatesMapper';
import { HelpInstructionKeyEnum } from '../models/enums/helpInstructionKeyEnum';
import { VisibilityCopyEnum } from '../models/enums/visibilityCopy.enum';
import { InventoryTabEnum } from '../models/enums/inventoryTab.enum';

export class InventoryStore {
  private rootStore: RootStore;
  private apiService: InventoryApi;
  private agenciesApiService: AgenciesApi;
  @observable public agenciesOptions = [];
  @observable public products: Array<ProductCardModel> = [];
  @observable public inventories: Array<InventoryModel> = [];
  @observable public productDetails: ProductDetailsModel = undefined;
  @observable public copiedRateValue: FormRateModel = null;
  @observable public productTypes: Array<ProductTypeModel> = [];
  @observable public categories: Array<CategoryModel> = [];
  @observable public destinations: Array<LocationModel> = [];
  @observable public isProductTypesLoading = false;
  @observable public isCategoriesLoading = false;
  @observable public isDestinationsLoading = false;

  @observable public isAvailabilitySubmitLoading = false;
  @observable public isProductSubmitLoading = false;
  @observable public isCurrentProductLoading = false;
  @observable public isInventoriesLoading = false;
  @observable public isProductAvailabilityOptionsLoading = false;
  @observable public idsOfDeletedRates: number[] = [];
  @observable public idsOfDeletedTimes: number[] = [];
  @observable public currentPagination = 1;
  @observable public pageSize = 15;
  @observable public total = 0;
  @observable public activeHelpInstructionKey =
    HelpInstructionKeyEnum.ProductName;

  public preventProductRequest: Function = null;
  public productsRequestsControllers: AbortController[] = [];
  public preventCategoriesRequest: Function = null;
  public preventDestinationsRequest: Function = null;
  public preventInventoriesRequest: Function = null;

  private lastProductsUpdate = undefined;
  private lastProductUpdate = undefined;
  private lastInventoriesUpdate = undefined;

  constructor(
    rootStore: RootStore,
    apiService: InventoryApi,
    agenciesApiService: AgenciesApi,
  ) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.apiService = apiService;
    this.agenciesApiService = agenciesApiService;
  }

  public getProductSupplier(id: string) {
    const { suppliersStore } = this.rootStore;

    return suppliersStore.getSupplierById(id) || suppliersStore.userSupplier;
  }

  @action async getAgenciesList() {
    try {
      const response = await this.agenciesApiService.getAgencies();

      this.agenciesOptions = (response.data.value || []).map((agency) => ({
        label: agency.Name,
        value: agency.Id,
      }));
    } catch (e) {
      return Promise.reject();
    }
  }

  private getActiveTab() {
    const match = matchPath(
      { path: '/inventory/:tab?' },
      router.state.location.pathname,
    );

    return match.params.tab;
  }

  private buildGetProductsFilter() {
    const activeTab = this.getActiveTab();

    const getInactiveFilter = () =>
      activeTab === InventoryTabEnum.All
        ? null
        : filterBuilder().eq(
            'Inactive',
            activeTab === InventoryTabEnum.Inactive,
          );

    return filterBuilder().and(getInactiveFilter()).toString();
  }

  @action
  public async getProducts(): Promise<{ isCancelled?: boolean }> {
    const currentTimeStamp = new Date().valueOf();
    this.lastProductsUpdate = currentTimeStamp;

    try {
      const params = {
        $count: true,
        $filter: this.buildGetProductsFilter() || undefined,
      };

      const entries = this.apiService.getProducts(params);

      this.productsRequestsControllers = entries.map(
        ({ controller }) => controller,
      );

      const responses = await Promise.all(
        entries.map(({ request }) => request),
      );

      if (this.lastProductsUpdate !== currentTimeStamp) {
        return Promise.resolve({ isCancelled: true });
      }

      const products = responses.flatMap((response) => response.data.value);
      this.products = observable(products);
      this.total = products.length;
      this.productsRequestsControllers = [];
      return Promise.resolve({ isCancelled: false });
    } catch (error) {
      if (this.lastProductsUpdate === currentTimeStamp) {
        this.currentPagination = 1;
      }

      this.total = 0;
      this.productsRequestsControllers = [];

      if (error.message === 'canceled') return;

      addGlobalMessage({
        message: intl.get('inventory.products.failedToLoadProducts'),
        type: MessageTypeEnum.error,
      });
    }
  }

  public get productDetailsType() {
    const queryParams = parseQueryString(router.state.location.search);

    return (
      (queryParams.productType as ProductTypeEnum) || ProductTypeEnum.Service
    );
  }

  @computed
  public get productsList() {
    return orderBy(this.products, ['Id']);
  }

  public preventProductsRequest() {
    if (isEmpty(this.productsRequestsControllers)) return;

    this.productsRequestsControllers.forEach((productsRequestsController) =>
      productsRequestsController.abort(),
    );
  }

  @action
  public async createProduct(product) {
    try {
      await this.apiService.createProduct(product, this.productDetailsType);

      addGlobalMessage({
        message: intl.get(
          'inventory.products.productHasBeenSuccessfullyCreated',
        ),
        type: MessageTypeEnum.success,
      });

      this.getProducts();

      return Promise.resolve();
    } catch (error) {
      const message = error.response
        ? error.response.data.error.message
        : intl.get('inventory.products.failedToCreateProduct');
      addGlobalMessage({
        message,
        type: MessageTypeEnum.error,
      });

      return Promise.reject();
    }
  }

  @action
  public async editProduct(product) {
    try {
      await this.apiService.editProduct(product, this.productDetailsType);

      addGlobalMessage({
        message: intl.get(
          'inventory.products.productHasBeenSuccessfullyEdited',
        ),
        type: MessageTypeEnum.success,
      });

      this.getProducts();

      return Promise.resolve();
    } catch (error) {
      addGlobalMessage({
        message: intl.get('inventory.products.failedToEditProduct'),
        type: MessageTypeEnum.error,
      });

      return Promise.reject();
    }
  }

  @action
  public async editPolicies(policies) {
    try {
      await this.apiService.editPolicies(policies);

      addGlobalMessage({
        message: intl.get(
          'inventory.products.policiesHasBeenSuccessfullyEdited',
        ),
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      addGlobalMessage({
        message: intl.get('inventory.products.failedToEditPolicies'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async getProductTypes() {
    try {
      this.isProductTypesLoading = true;

      const { data } = await this.apiService.getProductTypes(
        this.productDetailsType,
      );

      const productTypes = data.value;
      this.productTypes = observable(productTypes);

      this.isProductTypesLoading = false;

      return Promise.resolve();
    } catch (error) {
      this.isProductTypesLoading = false;

      addGlobalMessage({
        message: intl.get('inventory.products.failedToLoadProductTypes'),
        type: MessageTypeEnum.error,
      });

      return Promise.reject();
    }
  }

  @action
  public async createPolicies(policies) {
    try {
      await this.apiService.createPolicies(policies);

      addGlobalMessage({
        message: 'Policies have been successfully created',
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      addGlobalMessage({
        message: 'Failed to create policies',
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async createMedia(media) {
    try {
      const {
        data: { value },
      } = await this.apiService.createMedia(media);

      addGlobalMessage({
        message: 'Media have been successfully created',
        type: MessageTypeEnum.success,
      });

      return Promise.resolve(value);
    } catch (error) {
      addGlobalMessage({
        message: 'Failed to create media',
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async updateMedia(idsToDelete, media) {
    try {
      if (!isEmpty(idsToDelete)) {
        await this.apiService.deleteMedia(idsToDelete);
      }

      const {
        data: { value },
      } = await this.apiService.createMedia(media);

      addGlobalMessage({
        message: 'Media have been successfully updated',
        type: MessageTypeEnum.success,
      });

      return Promise.resolve(value);
    } catch (error) {
      addGlobalMessage({
        message: 'Failed to update media',
        type: MessageTypeEnum.error,
      });
    }
  }

  public get visibilityList() {
    const { userStore, commonStore } = this.rootStore;
    const { userProperties } = userStore;
    const { siteSettings } = commonStore;

    return [
      VisibilityEnum.Public,
      !isNil(userProperties.Agy_No) &&
        userProperties.Agy_No !== siteSettings.agencyId &&
        VisibilityEnum.Private,
      VisibilityEnum.Negotiated,
    ].filter((visibility) => !isNil(visibility));
  }

  @action
  public async getCategories() {
    try {
      this.isCategoriesLoading = true;

      const { cancel, request } = this.apiService.getCategories();

      this.preventCategoriesRequest = cancel;

      const response = await request;
      const { data } = response;

      const categories = data.value.map((item) => ({
        ...item,
        IsExistsCategory: true,
      }));
      this.categories = observable(categories);

      this.isCategoriesLoading = false;
      this.preventCategoriesRequest = null;
      return categories;
    } catch (error) {
      this.isCategoriesLoading = false;
      this.preventCategoriesRequest = null;

      if (error.message === 'canceled') return;

      addGlobalMessage({
        message: intl.get('inventory.products.failedToLoadCategories'),
        type: MessageTypeEnum.error,
      });
    }
  }

  public async searchCategories(input: string) {
    try {
      const search = input.trim().toLowerCase();

      const filter = filterBuilder
        .or()
        .startsWith((x) => x.toLower('Name'), search)
        .startsWith((x) => x.toLower('Id'), search)
        .toString();

      const { request } = this.apiService.getCategories({
        params: {
          $filter: filter,
        },
      });

      const response = await request;

      return this.mapCategoriesToOptions(response.data.value);
    } catch (error) {
      this.isCategoriesLoading = false;
      this.preventCategoriesRequest = null;

      if (error.message === 'canceled') return;

      addGlobalMessage({
        message: intl.get('inventory.products.failedToLoadCategories'),
        type: MessageTypeEnum.error,
      });
    }
  }

  private mapCategoriesToOptions(categories) {
    return categories
      .filter((category) => category.Id)
      .map((category) => ({ label: category.Name, value: category.Id }));
  }

  private getInventoryDateFilter(startDate: string, endDate: string) {
    if (!startDate && !endDate) return null;

    return filterBuilder()
      .gt('Date', moment(startDate).startOf('day').toDate())
      .le('Date', moment(endDate).endOf('day').toDate());
  }

  private getInventoryTimesFilter(times: string[]) {
    if (isEmpty(times)) return null;

    return times.reduce(
      (acc, time) =>
        acc.or((x) =>
          x.and((y) => {
            const timeMoment = moment(time);

            return y
              .eq('hour(Time)', timeMoment.utc().hours(), false)
              .eq('minute(Time)', timeMoment.utc().minutes(), false);
          }),
        ),
      filterBuilder(),
    );
  }

  private getInventoryCategoriesFilter(categories: string[]) {
    if (isEmpty(categories)) return null;

    return filterBuilder().in('CategoryId', categories);
  }

  @action
  public async getInventories(
    startDate,
    endDate,
    productId,
    filters: ProductAvailabilityFiltersFormModel = {},
  ): Promise<Array<InventoryModel>> {
    const currentTimeStamp = new Date().valueOf();
    this.lastInventoriesUpdate = currentTimeStamp;

    if (this.preventInventoriesRequest) {
      this.preventInventoriesRequest('cancel');
    }

    try {
      this.isInventoriesLoading = true;

      const params = {
        $orderby: 'Date, Time',
        $filter: filterBuilder()
          .eq('ProductType', this.productDetailsType || 'OPT')
          .eq('ProductId', productId)
          .and(this.getInventoryDateFilter(startDate, endDate))
          .and(this.getInventoryCategoriesFilter(filters.categories))
          .and(this.getInventoryTimesFilter(filters.times))
          .toString(),
      };

      const { cancel, request } = this.apiService.getInventories(params);

      this.preventInventoriesRequest = cancel;

      const response = await request;
      const { data } = response;

      if (this.lastInventoriesUpdate !== currentTimeStamp) {
        return Promise.resolve(this.inventories);
      }

      const inventories = data.value;
      this.inventories = observable(inventories);
      this.isInventoriesLoading = false;

      this.preventInventoriesRequest = null;

      return Promise.resolve(inventories);
    } catch (error) {
      if (axios.isCancel(error)) return;

      if (this.lastInventoriesUpdate === currentTimeStamp) {
        this.isInventoriesLoading = false;
      }

      addGlobalMessage({
        message: intl.get('inventory.products.failedToLoadInventories'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async deleteInventories(inventoryIds: number[]) {
    try {
      await this.apiService.deleteInventories(inventoryIds);

      addGlobalMessage({
        message: intl.get(
          'inventory.products.InventoriesHasBeenSuccessfullyDeleted',
        ),
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      addGlobalMessage({
        message: intl.get('inventory.products.failedToDeleteInventories'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async createRates(rates, productType: ProductTypeEnum) {
    try {
      await this.apiService.createRates(rates, productType);

      addGlobalMessage({
        message: intl.get('inventory.products.RatesHasBeenSuccessfullyCreated'),
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      console.dir(error);
      addGlobalMessage({
        message: intl.get('inventory.products.failedToCreateRates'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async createCategory(category: CategoryModel) {
    try {
      await this.apiService.createCategory(category);

      addGlobalMessage({
        message: intl.get(
          'inventory.products.CategoryHasBeenSuccessfullyCreated',
        ),
        type: MessageTypeEnum.success,
      });
      await this.getCategories();
    } catch (error) {
      console.dir(error);
      addGlobalMessage({
        message: intl.get('inventory.products.failedToCreateCategory'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public setActiveHelpInstructionKey(key: HelpInstructionKeyEnum) {
    this.activeHelpInstructionKey = key;
  }

  @action
  public setCopiedRateValue(copiedRateValue: FormRateModel) {
    this.copiedRateValue = copiedRateValue;
  }

  @action
  public setIdOfDeletedRate(id) {
    this.idsOfDeletedRates.push(id);
  }

  @action
  public clearDeletedRatesIds() {
    this.idsOfDeletedRates = [];
  }

  @action
  public setIdOfDeletedTimes(id) {
    this.idsOfDeletedTimes.push(id);
  }

  @action
  public clearDeletedTimesIds() {
    this.idsOfDeletedTimes = [];
  }

  @action
  public async deleteRates(rateIds: number[], productType: ProductTypeEnum) {
    try {
      await this.apiService.deleteRates(rateIds, productType);

      addGlobalMessage({
        message: intl.get('inventory.products.RatesHasBeenSuccessfullyDeleted'),
        type: MessageTypeEnum.success,
      });
      this.clearDeletedRatesIds();
    } catch (error) {
      this.clearDeletedRatesIds();
      addGlobalMessage({
        message: intl.get('inventory.products.failedToCreateRates'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async deleteTimes(timeIds: number[]) {
    try {
      await this.apiService.deleteTimes(timeIds);

      addGlobalMessage({
        message: intl.get('inventory.products.TimesHasBeenSuccessfullyDeleted'),
        type: MessageTypeEnum.success,
      });
      this.clearDeletedTimesIds();
    } catch (error) {
      this.clearDeletedTimesIds();
      addGlobalMessage({
        message: intl.get('inventory.products.failedToCreateTimes'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async createInventoryRange(inventoryRange) {
    try {
      await this.apiService.createInventoryRange(inventoryRange);

      addGlobalMessage({
        message: intl.get(
          'inventory.products.availabilityHasBeenSuccessfullyCreated',
        ),
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      console.dir(error);

      addGlobalMessage({
        message: intl.get('inventory.products.failedToCreateAvailability'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async createOrUpdateMultipleInventories(inventories) {
    try {
      await this.apiService.createOrUpdateMultipleInventories(inventories);

      addGlobalMessage({
        message: intl.get(
          'inventory.products.availabilityHasBeenSuccessfullyCreated',
        ),
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      console.dir(error);

      addGlobalMessage({
        message: intl.get('inventory.products.failedToCreateAvailability'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async editInventories(inventories) {
    try {
      this.isAvailabilitySubmitLoading = true;

      await this.apiService.createOrUpdateMultipleInventories(inventories);

      this.isAvailabilitySubmitLoading = false;

      addGlobalMessage({
        message: intl.get(
          'inventory.products.availabilityHasBeenSuccessfullyEdited',
        ),
        type: MessageTypeEnum.success,
      });

      // this.getProducts();

      return Promise.resolve();
    } catch (error) {
      this.isAvailabilitySubmitLoading = false;

      addGlobalMessage({
        message: intl.get('inventory.products.failedToEditAvailability'),
        type: MessageTypeEnum.error,
      });

      return Promise.reject();
    }
  }

  @action
  public async uploadImages(images: any[]) {
    try {
      await this.apiService.uploadImages(images);
      addGlobalMessage({
        message: intl.get('inventory.products.productImagesSuccessfullySaved'),
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      addGlobalMessage({
        message: intl.get('inventory.products.failedToCreateProductImages'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async deleteImages(images: any[]) {
    try {
      await Promise.all(
        images.map((image) => this.apiService.deleteImage(image)),
      );
    } catch (error) {
      addGlobalMessage({
        message: intl.get('inventory.products.failedToDeleteImages'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public clearStore() {
    this.products = [];
    this.inventories = [];
    this.productDetails = undefined;
    this.productTypes = [];
    this.categories = [];
    this.isProductTypesLoading = false;
    this.isCategoriesLoading = false;
    this.isAvailabilitySubmitLoading = false;
    this.isProductSubmitLoading = false;
    this.isCurrentProductLoading = false;
    this.isInventoriesLoading = false;
    this.currentPagination = 1;
    this.total = 0;
  }

  @action.bound
  public setPagination(pageNumber: number, pageSize: number) {
    this.currentPagination = pageNumber;
    this.pageSize = pageSize;
  }

  @action
  public clearProduct() {
    this.productDetails = undefined;
    this.copiedRateValue = null;
  }

  @action
  public stopGettingProduct() {
    if (this.preventProductRequest) {
      this.preventProductRequest();
      this.preventProductRequest = null;
    }
  }

  @computed
  public get ageTypesByProductType() {
    switch (this.productDetailsType) {
      case ProductTypeEnum.Package:
        return PACKAGE_AGE_TYPES;
      case ProductTypeEnum.Service:
      default:
        return SERVICE_AGE_TYPES;
    }
  }

  @action.bound
  public async getProductDetails(
    productId: string,
    productType: ProductTypeEnum,
  ) {
    this.isCurrentProductLoading = true;

    const currentTimeStamp = new Date().valueOf();
    this.lastProductUpdate = currentTimeStamp;

    this.stopGettingProduct();

    try {
      const { cancel, request } = this.apiService.getProduct(
        productId,
        productType,
      );

      this.preventProductRequest = cancel;

      if (this.lastProductUpdate !== currentTimeStamp) {
        return Promise.resolve({ isCancelled: true });
      }

      const response = await request;
      const productDetails = response.data;

      if (productDetails.Policies) {
        productDetails.Policies = productDetails.Policies.map((policy) => ({
          Id: policy.Id,
          Type: policy.Percentage ? 'percentage' : 'flat',
          Days: policy.UnitsOffset,
          Penalty: policy.Percentage || policy.Flat,
          TimeUnits: policy.TimeUnits,
          NonRefundable: policy.NonRefundable,
        }));
      }
      if (productDetails.Times) {
        productDetails.Times = getInitialTimes(productDetails.Times);
      }
      if (productDetails.Rates) {
        productDetails.Rates = new FormRatesMapper(
          productDetails.Rates,
          this.productDetails?.Name,
          this.categories,
        ).map();
      }

      if (productDetails.Media.length) {
        productDetails.Media = productDetails.Media.map((media) => ({
          ...media,
          key: media.Id.toString(),
          Visibility:
            media.Visibility === VisibilityCopyEnum.Negotiated &&
            (!media.AgencyId || media.AgencyId === DEFAULT_AGENCY)
              ? getVisibilityValue(VisibilityCopyEnum.Public)
              : getVisibilityValue(media.Visibility),
        }));
      }

      this.productDetails = productDetails;

      this.isCurrentProductLoading = false;
      return Promise.resolve(productDetails);
    } catch (error) {
      if (this.lastProductUpdate === currentTimeStamp) {
        this.isCurrentProductLoading = false;
      }

      console.dir(error);

      return Promise.reject();
    }
  }

  @action
  public async getDestinations() {
    try {
      this.isDestinationsLoading = true;

      const { cancel, request } = await this.apiService.getDestinations();

      this.preventDestinationsRequest = cancel;

      const response = await request;

      const { data } = response;

      const destinations = data;
      this.destinations = observable(destinations);

      this.isDestinationsLoading = false;
      this.preventDestinationsRequest = null;
    } catch (error) {
      this.isDestinationsLoading = false;
      this.preventDestinationsRequest = null;
      if (error.message === 'canceled') return;

      addGlobalMessage({
        message: intl.get('inventory.products.failToLoadDestinations'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  setIsProductSubmitLoading(value: boolean) {
    this.isProductSubmitLoading = value;
  }

  @action
  public async updateTimes(data: any) {
    try {
      await this.apiService.updateTimes(data);
      addGlobalMessage({
        message: intl.get('inventory.products.TimesHasBeenSuccessfullyCreated'),
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      addGlobalMessage({
        message: intl.get('inventory.products.failedToCreateTimes'),
        type: MessageTypeEnum.error,
      });
    }
  }
}
