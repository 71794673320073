// example: paymentAmountDue = 3, paymentDueBy = "2019-01-17T14:37:26.707Z"
import React from 'react';
import intl from 'react-intl-universal';
import pluralize from 'pluralize';
import moment, { Moment } from 'moment';
import { isNil } from 'lodash';

import switchCase, { normalizeDate } from './formatter';
import { AvailabilityLevelEnum } from '../models/enums/availabilityLevel.enum';
import { OrderDetailsModel } from '../models/orderDetails.model';
import { PaymentMethodsEnum } from '../models/enums/paymentMethods.enum';
import { PaymentStatusEnum } from '../models/enums/paymentStatus.enum';
import { CancelOrderModel } from '../models/cancelOrder.model';
import { IS_DEV } from './envUtils';
import { OrderCancellationFormModel } from '../models/orders/orderCancellationForm.model';
import { FinanceInfoModel } from '../models/financeInfo.model';
import { OrderCancellationFormValuesEnum } from '../models/enums/OrderCancellationFormValues.enum';
import { OrderTableDataModel } from '../models/orders/orderTableData.model';

export const getPaymentStatus = (
  order: OrderDetailsModel,
): PaymentStatusEnum => {
  const { PaymentAmountDue, PaymentDueBy } = order;

  if (PaymentAmountDue === 0) {
    return PaymentStatusEnum.paid;
  }

  const dueDate: Moment = normalizeDate(moment(PaymentDueBy));
  const now: Moment = normalizeDate(moment());
  const daysPassed: number = dueDate.diff(now, 'days');

  if (daysPassed < 0) {
    return PaymentStatusEnum.pastDue;
  }

  if (daysPassed <= 7) {
    return PaymentStatusEnum.dueIn;
  }

  return PaymentStatusEnum.dueOn;
};

export const getPaymentStatusLabel = (
  order: OrderDetailsModel,
  orderDetailsFinance?: any,
) => {
  const { PaymentDueBy } = order;
  const PaymentStatus = getPaymentStatus(order);

  const dueDate: Moment = normalizeDate(moment(PaymentDueBy));
  const now: Moment = normalizeDate(moment());
  const daysPassed: number = dueDate.diff(now, 'days');

  return switchCase(
    {
      [PaymentStatusEnum.cancelled]: () => (
        <div className="bold">{intl.get('orders.table.cancelled')}</div>
      ),
      [PaymentStatusEnum.paid]: () => {
        let cardType = null;
        let lastDigits = null;

        if (orderDetailsFinance && orderDetailsFinance.length) {
          const { CardType, LastDigits } = orderDetailsFinance[0];

          cardType = CardType;
          lastDigits = LastDigits;
        }
        return (
          <div className="bold">
            {`${intl.get('orders.table.paid')}${
              cardType && lastDigits
                ? ` – ${intl.get('orders.popup.cardDescription', {
                    cardType,
                    lastDigits,
                  })}`
                : ''
            }`}
          </div>
        );
      },
      [PaymentStatusEnum.pastDue]: () => (
        <div className="bold">{intl.get('orders.table.pastDue')}</div>
      ),
      [PaymentStatusEnum.dueIn]: () => (
        <div className="bold">
          {`${intl.get('orders.table.dueIn')} ${
            PaymentDueBy
              ? pluralize(intl.get('orders.table.day'), daysPassed, true)
              : ''
          }`}
        </div>
      ),
      [PaymentStatusEnum.dueOn]: () => (
        <div className="bold">
          {`${intl.get('orders.table.dueOn')} ${
            PaymentDueBy ? dueDate.format('MM/D') : ''
          }`}
        </div>
      ),
    },
    () => null,
    true,
  )(PaymentStatus);
};

export const getStatusIcon = (status: AvailabilityLevelEnum) => {
  switch (status) {
    case AvailabilityLevelEnum.Confirmed: {
      return '✔';
    }
    case AvailabilityLevelEnum.OnRequest:
    case AvailabilityLevelEnum.PartlyConfirmed: {
      return '⮃';
    }
    case AvailabilityLevelEnum.Cancelled:
    case AvailabilityLevelEnum.Administrative: {
      return '✖';
    }
  }
};

export const getPaymentStatusIcon = (status: AvailabilityLevelEnum) => {
  switch (status) {
    case AvailabilityLevelEnum.Confirmed: {
      return <i className="tc-check-circle confirmed" />;
    }
    case AvailabilityLevelEnum.OnRequest:
    case AvailabilityLevelEnum.PartlyConfirmed: {
      return (
        <i className="on-request tc-ic-pending">
          <i className="tc-custom-dots" />
        </i>
      );
    }
    case AvailabilityLevelEnum.Cancelled:
    case AvailabilityLevelEnum.Administrative: {
      return <i className="tc-remove-circle cancelled" />;
    }
  }
};

export const getSelectedOrderCancelBody = (
  order: OrderTableDataModel,
  tripItemIds: number[],
): CancelOrderModel => ({
  TripId: order.TripId,
  TripItemIds: tripItemIds,
  PaymentInfo: {
    TestMode: IS_DEV,
  },
  PrimaryGuestName: order.PrimaryGuestLastName,
});

export const mapRequestCancelBodyAccordingToOrderDetails = (
  orderDetails: OrderDetailsModel,
  values: OrderCancellationFormModel,
  financeInfo: FinanceInfoModel,
  options: { tripItemId?: number } = {},
): CancelOrderModel => {
  const { tripItemId } = options;

  return {
    TripId: orderDetails.TripId,
    PrimaryGuestName: orderDetails.PrimaryGuestName,
    TripItemIds: tripItemId ? [tripItemId] : null, // null cancels all travel items
    BypassCancellationPenalties: values.isCancelWithoutPenalty ?? false,
    PaymentInfo: getRequestCancelPaymentInfo(orderDetails, values, financeInfo),
  };
};

const getRequestCancelPaymentInfo = (
  orderDetails: OrderDetailsModel,
  values: OrderCancellationFormModel,
  financeInfo: FinanceInfoModel,
): CancelOrderModel['PaymentInfo'] => {
  if (isNil(values.creditCard) && !orderDetails.PaymentAmountDue) {
    return {
      PaymentMethod: PaymentMethodsEnum.creditCard,
      TestMode: IS_DEV,
    };
  }

  if (values.creditCard === OrderCancellationFormValuesEnum.NewCreditCard) {
    const [expirationMonth, expirationYear] = values.expirationDate.split('/');

    return {
      TestMode: IS_DEV,
      CreditCardInfo: {
        ExpirationMonth: expirationMonth,
        ExpirationYear: expirationYear,
        CardNumber: values.cardNumber,
        CardCode: values.cvv,
      },
    };
  }

  const financeProfile = financeInfo.Profiles.find(
    (profile) => profile.ProfileID === values.creditCard,
  );

  return {
    TestMode: IS_DEV,
    ProfileID: values.creditCard,
    PaymentMethod: financeProfile?.PaymentMethod,
  };
};
