import * as Yup from 'yup';
import { isEmpty } from 'lodash';

import { ProductAvailabilityFiltersFormModel } from 'models/inventory/productAvailabilityFiltersForm.model';

interface TestContextExtended {
  options: Yup.TestOptions & {
    context: {
      filtersFormValues: ProductAvailabilityFiltersFormModel;
    };
  };
}

export const ProductAvailabilityCreateFormSchema = Yup.object().shape({
  isCreateAvailability: Yup.boolean().test(
    'is-valid-create-availability',
    'Please select a valid option',
    function validate(value) {
      const { options } = this as Yup.TestContext & TestContextExtended;

      return !value || !isEmpty(options.context.filtersFormValues?.categories);
    },
  ),
  availableQuantity: Yup.number().when('isCreateAvailability', {
    is: true,
    then: Yup.number().typeError(
      'Please enter the quantity for new availability',
    ),
    otherwise: Yup.number().nullable(),
  }),
});
