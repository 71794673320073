import React from 'react';
import classNames from 'classnames';
import { Outlet } from 'react-router-dom';

import Header from '../SharedComponents/Header';
import SidebarMenu from '../SharedComponents/SidebarMenu';
import Footer from '../SharedComponents/Footer';

type Props = {
  withPadding?: boolean;
  withBackground?: boolean;
};

export default function WithGeneralLayout(props: Props) {
  const { withPadding = true, withBackground = true } = props;

  return (
    <main className="main-page">
      <Header />
      <section className="main-page-container">
        <SidebarMenu />
        <div
          className={classNames('main-page-content', {
            'with-padding': withPadding,
            'with-background': withBackground,
          })}
        >
          <Outlet />
        </div>
      </section>

      <Footer />
    </main>
  );
}
