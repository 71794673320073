import React, { Component } from 'react';
import { Col, Row } from 'antd';

import Accessor from 'components/SharedComponents/Accessor';
import MyProfile from './components/MyProfile';
import RoleManagement from './components/RoleManagement';
import { ROLES, USER_ROLE_GROUPS } from '../../../utils/constants';
import ProfilePlatformFees from './components/ProfilePlatformFees';
import { SuppliersStore } from '../../../stores/suppliers.store';
import { withStore } from '../../../hocs';

@withStore(({ rootStore }) => ({
  suppliersStore: rootStore.suppliersStore,
}))
export default class Profile extends Component<{
  suppliersStore?: SuppliersStore;
}> {
  render() {
    const { suppliersStore } = this.props;
    const { userSupplier } = suppliersStore;

    return (
      <div className="profile">
        <Row gutter={[0, 40]}>
          <Col span={24}>
            <MyProfile />
          </Col>

          <Accessor roles={[ROLES.SUPPLIER_ADMIN.value]}>
            {userSupplier?.CommissionFieldsVisible ? (
              <Col span={24}>
                <ProfilePlatformFees />
              </Col>
            ) : null}
          </Accessor>

          <Accessor roles={USER_ROLE_GROUPS.SUPPLIER}>
            <Col span={24}>
              <RoleManagement />
            </Col>
          </Accessor>
        </Row>
      </div>
    );
  }
}
