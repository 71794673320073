import moment, { Moment } from 'moment';

export const formatTimeCell = (time: string) =>
  time && moment(time).isValid() ? moment(time).format('hh:mm A') : '';

export const formatDateColumnTitle = (date: string | Moment) =>
  date ? moment(date).format('ddd') : '';

export const formatDateColumnSubTitle = (date: string | Moment) =>
  date ? moment(date).format('DD MMM') : '';
