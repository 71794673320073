import React from 'react';
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  Item,
} from 'devextreme-react/html-editor';
import { getIn } from 'formik';
import classnames from 'classnames';

const fontValues = [
  'Arial',
  'Courier New',
  'Georgia',
  'Impact',
  'Lucida Console',
  'Tahoma',
  'Times New Roman',
  'Verdana',
  'CircularStd',
  'Raleway',
  'Avenir',
];
const sizeValues = [
  '8px',
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '22px',
  '24px',
];
const headerValues = [false, 1, 2, 3, 4, 5];

interface Props {
  field: any;
  form: any;
  label: string;
  className?: string;
  maxWords?: number;
}
export default class HtmlEditorContainer extends React.Component<
  Props,
  {
    wordCount: number;
    value: string;
  }
> {
  state = {
    wordCount: 0,
    value: this.props.field.value,
  };

  componentDidMount() {
    const {
      field: { value },
    } = this.props;

    this.getWordCounter(value);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.field.value && !this.props.field.value) {
      this.handleResetValue();
    }
  }

  handleResetValue = () => {
    this.setState({ value: this.props.field.value });
  };

  handleChange = (value: string) => {
    const {
      field: { name },
      form: { setFieldValue },
    } = this.props;

    if (this.getWordCounter(value)) {
      setFieldValue(name, value);
    }
  };

  getWordCounter = (value: string) => {
    const { maxWords } = this.props;
    const element = document.createElement('div');
    element.innerHTML = value;

    const wordCount = element.innerText
      .replace(/\r?\n/g, '')
      .trim()
      .split(' ')
      .filter(Boolean).length;

    element.remove();

    if (wordCount <= maxWords) {
      this.setState(() => ({ wordCount, value }));
      return true;
    }

    return false;
  };

  render() {
    const {
      field,
      form: { touched, errors },
      label,
      className,
      maxWords,
    } = this.props;
    const { name } = field;
    const { wordCount, value } = this.state;

    const fieldError: string = getIn(errors, name);
    const isTouched: boolean = getIn(touched, name);
    const hasError: boolean = fieldError && isTouched;

    return (
      <div
        className={classnames('field', { 'has-error': hasError }, className)}
      >
        <h4 className="field-label">
          <div className="d-f jc-sb ai-c">
            <div>{label}</div>
            <div>{`${wordCount} / ${maxWords} Words`}</div>
          </div>
        </h4>
        <HtmlEditor
          height="300px"
          value={value}
          onValueChange={this.handleChange}
        >
          <MediaResizing enabled />
          <Toolbar multiline={false}>
            <Item name="undo" />
            <Item name="redo" />
            <Item name="separator" />
            <Item name="font" acceptedValues={fontValues} />
            <Item name="clear" />
            <Item name="size" acceptedValues={sizeValues} />
            <Item name="separator" />
            <Item name="bold" />
            <Item name="italic" />
            <Item name="strike" />
            <Item name="underline" />
            <Item name="separator" />
            <Item name="alignLeft" />
            <Item name="alignCenter" />
            <Item name="alignRight" />
            <Item name="alignJustify" />
            <Item name="separator" />
            <Item name="orderedList" />
            <Item name="bulletList" />
            <Item name="separator" />
            <Item name="header" acceptedValues={headerValues} />
            <Item name="separator" />
            <Item name="color" />
            <Item name="background" />
            <Item name="separator" />
            <Item name="link" />
            <Item name="separator" />
            <Item name="codeBlock" />
            <Item name="blockquote" />
            <Item name="separator" />
          </Toolbar>
        </HtmlEditor>

        {hasError && <div className="field-error visible">{fieldError}</div>}
      </div>
    );
  }
}
