import React from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Button, Col, Row } from 'antd';
import * as Yup from 'yup';
import intl from 'react-intl-universal';
import { isEmpty } from 'lodash';

import { CheckboxContainer, InputContainer } from 'components/FormContainers';
import { UserFiltersFormValuesModel } from 'models/user-filters/userFiltersFormValues.model';
import { USER_ROLE_GROUPS } from 'utils/constants';
import Accessor from './Accessor';

type Props = {
  isLoading: boolean;
  isAccessSettingsVisible?: boolean;
  initialValues: UserFiltersFormValuesModel;
  onSave: (values: UserFiltersFormValuesModel) => Promise<{ error: string }>;
};

const validationSchema = Yup.object().shape({
  allNames: Yup.array().of(Yup.string()),
  name: Yup.string()
    .required('Required')
    .max(60, 'Maximum length is 60')
    .test({
      name: 'unique-name',
      message: 'View name should be unique',
      test: function testUniqueName(value) {
        return (
          isEmpty(this.parent.allNames) || !this.parent.allNames.includes(value)
        );
      },
    }),
});

function UserFiltersManageForm(props: Props) {
  const {
    isLoading,
    initialValues,
    isAccessSettingsVisible = false,
    onSave,
  } = props;

  const onSubmit = async (
    values: UserFiltersFormValuesModel,
    formikHelpers: FormikHelpers<UserFiltersFormValuesModel>,
  ) => {
    const { error } = await onSave(values);

    if (error) {
      formikHelpers.resetForm({
        touched: { name: true },
        values: {
          name: '',
          allNames: initialValues.allNames,
          isAvailableForAgents: false,
          isAvailableForSuppliers: false,
        },
      });

      formikHelpers.setFieldError('name', error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form style={{ width: 400 }}>
        <Row gutter={[10, 5]}>
          {isAccessSettingsVisible && (
            <>
              <Accessor roles={USER_ROLE_GROUPS.AGENCY}>
                <Col span={24}>
                  <Field
                    name="isAvailableForAgents"
                    component={CheckboxContainer}
                    label={intl.get('userFilters.agencyAvailability')}
                  />
                </Col>
              </Accessor>

              <Accessor roles={USER_ROLE_GROUPS.SUPPLIER}>
                <Col span={24}>
                  <Field
                    name="isAvailableForSuppliers"
                    component={CheckboxContainer}
                    label={intl.get('userFilters.supplierAvailability')}
                  />
                </Col>
              </Accessor>
            </>
          )}

          <Col span={17}>
            <Field
              name="name"
              component={InputContainer}
              placeholder={intl.get('userFilters.viewName')}
            />
          </Col>

          <Col span={7}>
            <Button block loading={isLoading} htmlType="submit">
              {intl.get('submit')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Formik>
  );
}

export default UserFiltersManageForm;
