import React, { Component } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import { Button, Checkbox, Col, Row } from 'antd';
import { stringOrDate, View, Views } from 'react-big-calendar';
import { FormikHelpers, FormikProps } from 'formik';

import { withStore } from 'hocs';
import { ProductAvailabilityFiltersFormModel } from 'models/inventory/productAvailabilityFiltersForm.model';
import { ProductAvailabilityCreateFormModel } from 'models/inventory/productAvailabilityCreateForm.model';
import { EventModel } from 'models/inventory/event.model';
import { InventoryStore } from 'stores/inventory.store';
import { getAvailabilityQuantityByEditType } from 'utils/inventoryHelper';
import { isSameBy } from 'utils/arrayUtils';
import { getRateCategoryTimes } from 'utils/rateUtils';
import ProductAvailabilityCalendar, {
  CalendarRange,
} from './ProductAvailabilityCalendar';
import ProductDetailsCalendarFilters from './ProductDetailsCalendarFilters';
import ProductAvailabilityCreate from './ProductAvailabilityCreate';

type CreateFormSyncModel = Omit<
  ProductAvailabilityCreateFormModel,
  'isCreateAvailability' | 'availabilityEditType'
>;

type Props = {
  inventoryStore?: InventoryStore;
};

type State = {
  selectedDays: string[];
  events: EventModel[];
  dateFrom: stringOrDate;
  dateTo: stringOrDate;
  view: View;
  isShowOptionsInline: boolean;
};

@withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
}))
export default class AvailabilityTab extends Component<Props, State> {
  filtersFormRef =
    React.createRef<FormikProps<ProductAvailabilityFiltersFormModel>>();

  createFormRef =
    React.createRef<FormikProps<ProductAvailabilityCreateFormModel>>();

  constructor(props: Props) {
    super(props);

    const dateFrom = moment().startOf('month').startOf('week').toDate();
    const dateTo = moment().endOf('month').endOf('week').toDate();

    this.state = {
      selectedDays: [],
      events: [],
      dateFrom,
      dateTo,
      view: Views.MONTH,
      isShowOptionsInline: false,
    };
  }

  componentDidMount() {
    this.getEvents(this.filtersFormRef.current.values);
  }

  componentDidUpdate(_: Readonly<Props>, prevState: Readonly<State>) {
    if (!Object.is(this.state.events, prevState.events)) {
      this.handleSyncFieldsWithEventsBySelection();
    }
  }

  handleSyncFieldsWithEventsBySelection() {
    const { selectedDays } = this.state;

    if (isEmpty(selectedDays)) return;

    this.handleSelectAvailabilities(selectedDays);
  }

  get selectedDaysEvents() {
    const { selectedDays } = this.state;

    return selectedDays.map((day) => ({
      day,
      event: this.getEventByDate(day),
    }));
  }

  getEvents = async (filters: ProductAvailabilityFiltersFormModel) => {
    const { inventoryStore } = this.props;
    const { view, dateFrom, dateTo } = this.state;
    const { productDetails } = inventoryStore;

    if (!productDetails) return;

    this.setState({ events: [] });

    if (isEmpty(filters.categories)) return;

    const inventories = await inventoryStore.getInventories(
      dateFrom,
      dateTo,
      productDetails.Id,
      filters,
    );

    if (isEmpty(inventories)) return;

    const events: EventModel[] = [];

    inventories.forEach((inventory) => {
      const existingEvent = events.find(({ options }) =>
        options.some((option) =>
          moment(option.Date).isSame(moment(inventory.Date)),
        ),
      );

      if (existingEvent && (view === Views.MONTH || !inventory.Time)) {
        existingEvent.options.push(inventory);

        return;
      }

      events.push(new EventModel([inventory]));
    });

    this.setState({ events });
  };

  clearSelection = () => this.setState({ selectedDays: [] });

  handleViewChange = (view: View) => this.setState({ view });

  getCreateFormDaySyncData = (day: string): CreateFormSyncModel => {
    const selectedEvent = this.getEventByDate(day);

    return {
      notes: selectedEvent?.options[0]?.Notes ?? '',
      isStopSell: selectedEvent?.options[0]?.StopSell ?? false,
      availableQuantity: selectedEvent?.options[0]?.AvailableQuantity ?? null,
      bookingCutoffHours:
        selectedEvent?.options[0]?.BookingCutoffHours ?? undefined,
      hold: selectedEvent?.options[0]?.Hold ?? undefined,
    };
  };

  getCreateFormDaysSyncData = (days: string[]): CreateFormSyncModel => {
    const { events } = this.state;

    const selectedEvents = events.filter(({ start }) =>
      days.some((selectedDay) =>
        moment(start).isSame(moment(selectedDay), 'd'),
      ),
    );

    const isAvailableQuantitySame = isSameBy(selectedEvents, (event) =>
      event.options.map((option) => option.AvailableQuantity),
    );
    const isCutoffHoursSame = isSameBy(selectedEvents, (event) =>
      event.options.map((option) => option.BookingCutoffHours),
    );
    const availableQuantity = isAvailableQuantitySame
      ? get(selectedEvents, '0.options.0.AvailableQuantity')
      : null;
    const bookingCutoffHours = isCutoffHoursSame
      ? get(selectedEvents, '0.options.0.BookingCutoffHours')
      : undefined;

    return {
      notes: '',
      isStopSell: false,
      availableQuantity,
      bookingCutoffHours,
      hold: undefined,
    };
  };

  getCategoryTimes = (categoryId: string, times: string[]) => {
    const { inventoryStore } = this.props;

    const categoryTimes = getRateCategoryTimes(
      categoryId,
      inventoryStore.productDetails.Rates,
    );

    const relevantTimes = categoryTimes.filter((categoryTime) =>
      times.some((time) => categoryTime.isSame(time)),
    );

    return isEmpty(relevantTimes) ? [null] : relevantTimes;
  };

  getInventoriesToCreate = (values: ProductAvailabilityCreateFormModel) => {
    const { inventoryStore } = this.props;
    const { categories, times } = this.filtersFormRef.current?.values ?? {};

    const {
      notes,
      isStopSell,
      hold,
      availableQuantity,
      isCreateAvailability,
      bookingCutoffHours,
    } = values ?? {};
    const { productDetails, productDetailsType } = inventoryStore;

    if (!isCreateAvailability) return [];

    return this.selectedDaysEvents.flatMap(({ day, event }) => {
      if (event) return [];

      return categories.flatMap((category) =>
        this.getCategoryTimes(category, times).map((time) => ({
          ProductType: productDetailsType,
          ProductId: productDetails.Id,
          CategoryId: category,
          Time: time?.toISOString() || null,
          Date: moment(day).format('YYYY-MM-DD'),
          OriginalQuantity: availableQuantity,
          AvailableQuantity: availableQuantity,
          BookingCutoffHours: bookingCutoffHours,
          Notes: notes,
          StopSell: isStopSell,
          Hold: hold ?? 0,
        })),
      );
    });
  };

  getInventoriesToEdit = (values: ProductAvailabilityCreateFormModel) => {
    const {
      notes,
      isStopSell,
      hold,
      availableQuantity,
      availabilityEditType,
      bookingCutoffHours,
    } = values ?? {};

    return this.selectedDaysEvents.flatMap(({ event }) => {
      if (isEmpty(event?.options)) return [];

      return event.options.map((option) => {
        const editedAvailabilityAmount = getAvailabilityQuantityByEditType(
          availableQuantity,
          option,
          availabilityEditType,
        );

        return {
          ...option,
          Time: moment(option.Time).toISOString(),
          OriginalQuantity: editedAvailabilityAmount ?? option.OriginalQuantity,
          AvailableQuantity:
            editedAvailabilityAmount ?? option.AvailableQuantity,
          BookingCutoffHours: bookingCutoffHours || option.BookingCutoffHours,
          Notes: notes,
          StopSell: isStopSell,
          Hold: hold || option.Hold,
        };
      });
    });
  };

  getNumberOfEntriesToCreate = (values: ProductAvailabilityCreateFormModel) =>
    this.getInventoriesToCreate(values).length;

  handleSelectAvailabilities = (days: string[]) => {
    const { setFieldValue } = this.createFormRef.current;

    const syncData =
      days.length === 1
        ? this.getCreateFormDaySyncData(days[0])
        : this.getCreateFormDaysSyncData(days);

    setFieldValue('notes', syncData.notes);
    setFieldValue('isStopSell', syncData.isStopSell);
    setFieldValue('availableQuantity', syncData.availableQuantity);
    setFieldValue('bookingCutoffHours', syncData.bookingCutoffHours);
    setFieldValue('hold', syncData.hold);

    this.setState({ selectedDays: days });
  };

  handleSaveAvailabilities = async (
    values: ProductAvailabilityCreateFormModel,
    formikHelpers: FormikHelpers<ProductAvailabilityCreateFormModel>,
  ) => {
    const { inventoryStore } = this.props;

    const inventories = [
      ...this.getInventoriesToCreate(values),
      ...this.getInventoriesToEdit(values),
    ];

    await inventoryStore.editInventories(inventories);

    this.clearSelection();
    formikHelpers.resetForm();

    await this.getEvents(this.filtersFormRef.current.values);
  };

  handleRangeChange = (range: CalendarRange) => {
    const dateFrom = Array.isArray(range) ? range[0] : range.start;

    const dateTo = Array.isArray(range) ? range[range.length - 1] : range.end;

    this.setState({ dateFrom, dateTo }, () =>
      this.getEvents(this.filtersFormRef.current.values),
    );

    this.clearSelection();
  };

  handleChangeOptionsInline = (isShowOptionsInline: boolean) =>
    this.setState({ isShowOptionsInline });

  getEventByDate = (day: string) =>
    this.state.events.find(({ start }) =>
      moment(start).isSame(moment(day), 'd'),
    );

  render() {
    const { events, selectedDays, view, isShowOptionsInline } = this.state;

    return (
      <div className="product-body">
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Row justify="space-between" align="bottom">
              <Col>
                <ProductDetailsCalendarFilters
                  innerRef={this.filtersFormRef}
                  onChange={this.getEvents}
                />
              </Col>

              <Col>
                <Row gutter={10} align="middle">
                  <Col>
                    <Checkbox
                      checked={isShowOptionsInline}
                      onChange={(event) =>
                        this.handleChangeOptionsInline(event.target.checked)
                      }
                    >
                      {intl.get('productPage.showOptionsInline')}
                    </Checkbox>
                  </Col>

                  <Col>
                    <Button onClick={this.clearSelection}>
                      {intl.get('productPage.clearSelection')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <ProductAvailabilityCalendar
              events={events}
              view={view}
              selectedDays={selectedDays}
              className="product-availability-calendar"
              isShowOptionsInline={isShowOptionsInline}
              onEventsSelect={this.handleSelectAvailabilities}
              onRangeChange={this.handleRangeChange}
              onView={this.handleViewChange}
            />
          </Col>

          <Col span={24}>
            <ProductAvailabilityCreate
              innerRef={this.createFormRef}
              filtersFormValues={this.filtersFormRef.current?.values}
              getNumberOfEntriesToCreate={this.getNumberOfEntriesToCreate}
              selectedDaysEvents={this.selectedDaysEvents}
              onSubmit={this.handleSaveAvailabilities}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
