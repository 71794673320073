import React from 'react';
import { Radio, RadioProps } from 'antd';
import intl from 'react-intl-universal';
import { FilterDropdownProps } from 'antd/es/table/interface';

import { StatusEnum } from 'models/enums/status.enum';
import TableOrdersFilterActions from './TableOrdersFilterActions';

type Props = {
  inputProps?: RadioProps;
} & FilterDropdownProps;

function TableOrdersStatusFilter(props: Props) {
  const { inputProps, ...dropdownProps } = props;
  const { selectedKeys, setSelectedKeys } = dropdownProps;

  const options = [
    {
      label: intl.get('orders.table.cancelled'),
      value: StatusEnum.cancelled,
    },
    {
      label: intl.get('orders.table.active'),
      value: StatusEnum.active,
    },
  ];

  return (
    <div className="ant-table-filter-dropdown">
      <div className="ant-table-filter-dropdown-search">
        <Radio.Group
          {...inputProps}
          options={options}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys([e.target.value])}
        />
      </div>

      <div className="ant-table-filter-dropdown-btns">
        <TableOrdersFilterActions {...dropdownProps} />
      </div>
    </div>
  );
}

export default TableOrdersStatusFilter;
