import React from 'react';
import intl from 'react-intl-universal';
import { Button, Collapse, Space } from 'antd';
import { Element } from 'react-scroll';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { ErrorMessage, FormikProps } from 'formik';

import { ProductFormValuesModel } from 'models/inventory/productFormValues.model';
import { FormTimeModel } from 'models/inventory/formTime.model';
import { ProductSectionKeyEnum } from 'models/enums/productSectionKey.enum';
import { InventoryStore } from 'stores/inventory.store';
import { EMPTY_VALUE } from 'utils/constants';
import { withStore } from 'hocs';
import TimePanel from './TimePanel';

type Props = {
  inventoryStore?: InventoryStore;
  activeTimeKey: string[];
  formikProps: FormikProps<ProductFormValuesModel>;
  onActiveTimeChange: (key: string | string[]) => void;
  onToggleAll: () => void;
};

function ProductTimesInfo(props: Props) {
  const {
    inventoryStore,
    activeTimeKey,
    formikProps,
    onActiveTimeChange,
    onToggleAll,
  } = props;
  const { values, setFieldValue } = formikProps;

  const isAllExpanded = activeTimeKey.length === values.Times.length;

  const handleNewTime = () => {
    const allFormTimes = [...values.Times];

    allFormTimes.push({
      isExistsTime: false,
      position: allFormTimes.length,
      RecursEvery: undefined,
      Id: undefined,
      Time: undefined,
      EndTime: undefined,
      DaysOfWeek: [],
      CategoryId: EMPTY_VALUE,
      ServiceStartEnd: [],
      CreateAvailability: false,
    });

    setFieldValue('Times', allFormTimes);

    if (allFormTimes.length >= 1) {
      onActiveTimeChange(`${values.Times.length}`);
    }
  };

  const handleDeleteTime =
    (time: FormTimeModel) =>
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation();

      const newTimes = [...values.Times].filter(
        (item) => item.position !== time.position,
      );

      if (time.isExistsTime && time.Id) {
        inventoryStore.setIdOfDeletedTimes(time.Id);
      }

      setFieldValue('Times', newTimes);
    };

  const getPanelHeader = (time: FormTimeModel, index: number) => {
    const label =
      time.CategoryId && time.CategoryId !== EMPTY_VALUE
        ? `${time.CategoryId}`
        : [intl.get('inventory.products.popup.time'), index + 1].join(' ');

    return (
      <Space>
        <ErrorMessage
          name={`Times.${time.position}`}
          render={() => <WarningOutlined style={{ color: 'red' }} />}
        />

        <span>{label}</span>
      </Space>
    );
  };

  return (
    <Element name={ProductSectionKeyEnum.Times} className="row">
      <div className="column title">
        {intl.get('inventory.products.popup.times')}
      </div>
      <div className="column content">
        <div className="d-f f-d-column flx-1">
          <Button
            type="link"
            size="small"
            className="m-b-10 as-fe"
            onClick={onToggleAll}
          >
            {isAllExpanded
              ? intl.get('inventory.products.popup.collapseAll')
              : intl.get('inventory.products.popup.expandAll')}
          </Button>

          <div className="d-f f-d-column flx-1">
            <Collapse
              activeKey={activeTimeKey}
              defaultActiveKey={['0']}
              onChange={onActiveTimeChange}
            >
              {values.Times.map((time, index) => (
                <Collapse.Panel
                  header={getPanelHeader(time, index)}
                  key={String(index)}
                  className="new-product-panel"
                  extra={
                    <div className="new-product-extra-controls d-f ai-center">
                      <div>
                        <DeleteOutlined onClick={handleDeleteTime(time)} />
                      </div>
                    </div>
                  }
                >
                  <TimePanel position={index} />
                </Collapse.Panel>
              ))}
            </Collapse>
          </div>
          <div className="m-20">
            <div className="d-f jc-c">
              <Button type="primary" onClick={handleNewTime}>
                {intl.get('inventory.products.popup.addTime')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
}

export default withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
}))(ProductTimesInfo);
