import React, { Component, Fragment } from 'react';
import { reaction } from 'mobx';
import intl from 'react-intl-universal';
import moment from 'moment';
import pluralize from 'pluralize';
import { Select, Checkbox, Tooltip } from 'antd';
import classNames from 'classnames';
import { ClockCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { CheckoutStore } from '../../../../stores/checkout.store';
import { CardInCartModel } from '../../../../models/cardInCart.model';
import { GuestTypesEnum } from '../../../../utils/constants';
import { moneyFormat } from '../../../../utils/formatter';
import { AvailableCategoryModel } from '../../../../models/tour-details/tourAvailableCategory.model';
import { getPolicies } from '../../../../utils/policyUtils';
import ImageComponent from '../../../SharedComponents/Image';
import { TravelerFormModel } from '../../../../models/form-models/travelerForm.model';
import PickUpPoints from './PickUpPoints';
import BookingQuestionsForTraveler from './BookingQuestionsForTraveler';
import BookingQuestionsForProduct from './BookingQuestionsForProduct';
import SpecialRequest from './SpecialRequest';
import ProductHoldInfoPopover from '../../../SharedComponents/ProductHoldInfoPopover';
import { withStore } from '../../../../hocs';

const dateFormat = 'MMM D (ddd)';
const uniqueFilter = (card: CardInCartModel) => (traveler: TravelerFormModel) =>
  !card.product.Guests.find((guest) => guest.travelerId === traveler.id) &&
  traveler.fullName.trim() !== '';

@withStore(({ rootStore }) => ({
  checkoutStore: rootStore.checkoutStore,
}))
export default class CardInCheckout extends Component<{
  card: CardInCartModel;
  isPrefilled: boolean;
  checkoutStore?: CheckoutStore;
}> {
  disposer = null;

  state = {
    isSpecialRequestChecked: false,
    isErrorLabelNeeded: false,
  };

  componentDidMount() {
    const { checkoutStore, card, isPrefilled } = this.props;

    if (!isPrefilled) {
      const isSelectValid = false; // by default
      // register fields in store
      card.product.Guests.forEach((guest) => {
        checkoutStore.updateValid(
          `selectTravelers:${card.id}:${guest.GuestId}`,
          isSelectValid,
        );
      });

      this.initializeTravelers();
    }

    this.disposer = reaction(
      () => checkoutStore.shouldValidate,
      (shouldValidate) => shouldValidate > 0 && this.validate(),
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  validate = () => {
    const { checkoutStore, card } = this.props;

    const isSelectsValid = checkoutStore.isItemsInCheckoutValid(
      `selectTravelers:${card.id}`,
    );

    this.setState({
      isErrorLabelNeeded: !isSelectsValid,
    });
  };

  initializeTravelers = () => {
    const {
      card: {
        product: { Guests },
        id: cardId,
      },
      checkoutStore: { travelers, numberOfTravelers },
    } = this.props;

    const firstAdult = Guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.ADULT,
    )[0];
    const leadTraveler = travelers.find((traveler) => traveler.isLead);
    const isPrimaryGuest = true;

    this.handleSelectChange(
      cardId,
      firstAdult.GuestId,
      isPrimaryGuest,
    )(leadTraveler.id);

    if (numberOfTravelers === Guests.length) {
      const adults = Guests.filter(
        (guest: any) => guest.Type === GuestTypesEnum.ADULT,
      );
      const children = Guests.filter(
        (guest: any) => guest.Type === GuestTypesEnum.CHD,
      );
      const juniors = Guests.filter(
        (guest: any) => guest.Type === GuestTypesEnum.JUNIOR,
      );
      const infants = Guests.filter(
        (guest: any) => guest.Type === GuestTypesEnum.INFANT,
      );
      const isPrimary = false;

      travelers.forEach((traveler, index) => {
        if (index < adults.length) {
          if (!adults[index].travelerId) {
            this.handleSelectChange(
              cardId,
              adults[index].GuestId,
              isPrimary,
            )(traveler.id);
          }

          return;
        }

        const juniorIndex = index - adults.length;
        if (juniors.length && juniorIndex < juniors.length) {
          if (!juniors[juniorIndex].travelerId) {
            this.handleSelectChange(
              cardId,
              juniors[juniorIndex].GuestId,
              isPrimary,
            )(traveler.id);
          }

          return;
        }

        const childrenIndex = index - (adults.length + juniors.length);
        if (children.length && childrenIndex < children.length) {
          if (!children[childrenIndex].travelerId) {
            this.handleSelectChange(
              cardId,
              children[childrenIndex].GuestId,
              isPrimary,
            )(traveler.id);
          }

          return;
        }

        const infantIndex =
          index - (adults.length + juniors.length + children.length);
        if (infants.length && infantIndex < infants.length) {
          if (!infants[infantIndex].travelerId) {
            this.handleSelectChange(
              cardId,
              infants[infantIndex].GuestId,
              isPrimary,
            )(traveler.id);
          }
        }
      });
    }
  };

  handleSpecialRequestChange = (event) => {
    const isChecked = event.target.checked;

    if (isChecked === undefined) {
      const isSpecialRequestChecked = this.state.isSpecialRequestChecked;

      this.setState({
        isSpecialRequestChecked: !isSpecialRequestChecked,
      });
      return;
    }

    this.setState({
      isSpecialRequestChecked: isChecked,
    });
  };

  handleSelectChange =
    (cardId: string, guestId: number, isPrimaryGuest: boolean) =>
    (travelerId: number | string) => {
      const { checkoutStore } = this.props;
      const isSelectValid = travelerId !== undefined;

      checkoutStore.selectTraveler(
        cardId,
        guestId,
        isPrimaryGuest,
        +travelerId,
      );
      checkoutStore.updateValid(
        `selectTravelers:${cardId}:${guestId}`,
        isSelectValid,
      );
    };

  renderSelect = (guestId: number, isPrimaryGuest: boolean) => {
    const {
      checkoutStore: { travelers },
      card,
      isPrefilled,
    } = this.props;
    const validTravelers = travelers.filter(uniqueFilter(card));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let defaultGuest;

    if (isPrefilled) {
      const defaultTraveler = travelers.find(
        (traveler) => traveler.id === guestId,
      );

      defaultTraveler && (defaultGuest = defaultTraveler.fullName);
    }
    const { travelerId } = card.product.Guests.find(
      (guest) => guest.GuestId === guestId,
    );
    const traveler = travelers.find((item) => item.id === travelerId);

    return (
      <Select
        allowClear
        placeholder={intl.get('select')}
        disabled={isPrefilled}
        value={traveler ? traveler.fullName : undefined}
        onChange={this.handleSelectChange(card.id, guestId, isPrimaryGuest)}
      >
        {validTravelers.map((validTraveler) => (
          <Select.Option key={`${validTraveler.id}`} value={validTraveler.id}>
            {validTraveler.fullName}
          </Select.Option>
        ))}
      </Select>
    );
  };

  renderGuestFragment = (
    productName: string,
    price: number,
    countOfGuests: number,
    guestLabel: string,
    guests: any[],
    isFirstRow?: boolean,
  ) => {
    const { card, isPrefilled } = this.props;

    return (
      <div className="ticket-wrapper">
        <div className="ticket-item">
          <div className="ticket-name">{`${productName} (${guestLabel})`}</div>
          <div className="ticket-total">
            <div className="price">{moneyFormat(price)}</div>
            <div className="guest-count-and-type">
              {pluralize(guestLabel, countOfGuests, true)}
            </div>
          </div>
        </div>
        <div className="card-traveler-data-container">
          {guests.map((guest, index) => {
            const isPrimaryGuest = isFirstRow && index === 0;

            return (
              <div key={guest.GuestId} className="card-traveler-data">
                {!isPrefilled && (
                  <div className="questions">
                    <BookingQuestionsForTraveler
                      cardId={card.id}
                      guestId={guest.GuestId}
                      bookingQuestions={card.bookingQuestions}
                      isWeightRequired={card.passengerWeightRequired}
                    />
                  </div>
                )}
                <div
                  className="checkout-field select-container"
                  key={guest.GuestId}
                >
                  {this.renderSelect(guest.GuestId, isPrimaryGuest)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  renderProductByGuests = (product: AvailableCategoryModel) => {
    const adults = product.Guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.ADULT,
    );
    const adultsPrice = adults.reduce((sum, current) => sum + current.Price, 0);
    const adultsFirstRow = true;

    const children = product.Guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.CHD,
    );
    const childrenPrice = children.reduce(
      (sum, current) => sum + current.Price,
      0,
    );

    const juniors = product.Guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.JUNIOR,
    );
    const juniorsPrice = juniors.reduce(
      (sum, current) => sum + current.Price,
      0,
    );

    const infants = product.Guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.INFANT,
    );
    const infantsPrice = infants.reduce(
      (sum, current) => sum + current.Price,
      0,
    );

    return (
      <Fragment>
        {adults.length > 0 &&
          this.renderGuestFragment(
            product.Name,
            adultsPrice,
            adults.length,
            intl.get('adult'),
            adults,
            adultsFirstRow,
          )}
        {juniors.length > 0 &&
          this.renderGuestFragment(
            product.Name,
            juniorsPrice,
            juniors.length,
            intl.get('junior'),
            juniors,
          )}
        {children.length > 0 &&
          this.renderGuestFragment(
            product.Name,
            childrenPrice,
            children.length,
            intl.get('child'),
            children,
          )}
        {infants.length > 0 &&
          this.renderGuestFragment(
            product.Name,
            infantsPrice,
            infants.length,
            intl.get('infant'),
            infants,
          )}
      </Fragment>
    );
  };

  render() {
    const {
      card,
      card: {
        tourName,
        productId,
        imageUrl,
        date,
        product,
        id,
        pickupPoints,
        dropoffPoints,
        bookingQuestions,
      },
      isPrefilled,
    } = this.props;
    const { isErrorLabelNeeded, isSpecialRequestChecked } = this.state;

    return (
      <div className="card-in-checkout">
        <div className="card-top">
          <ProductHoldInfoPopover
            cardsInCart={[card]}
            text={intl.get('holdInfo.singleTimerText')}
            getPopupContainer={() => document.body}
          >
            <div className="card-hold-icon">
              <ClockCircleOutlined />
            </div>
          </ProductHoldInfoPopover>

          <ImageComponent className="card-image" src={imageUrl} />

          <div className="card-info">
            <h3 className="tour-name">{tourName}</h3>
            <div className="product-number">#{productId}</div>
            {!isPrefilled && (
              <div className="field checkout-field special-request-info">
                <span
                  className={classNames({ checked: isSpecialRequestChecked })}
                >
                  <Checkbox
                    checked={isSpecialRequestChecked}
                    onChange={this.handleSpecialRequestChange}
                  />
                </span>
                <span
                  className="field-label"
                  onClick={this.handleSpecialRequestChange}
                >
                  {intl.get('specialRequestsLabel')}
                </span>
                <span className="question-mark">
                  <Tooltip
                    placement="topLeft"
                    title={intl.get('questionMarks.checkout.specialRequest')}
                    trigger="hover"
                    overlayClassName="checkout-tooltip"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="card-bottom">
          <div className="tour-date">{moment.utc(date).format(dateFormat)}</div>
          <div className="products-by-guests">
            {this.renderProductByGuests(product)}
            {isErrorLabelNeeded && (
              <div className="selects-error field-error">
                <span>{intl.get('checkout.travelersShouldBeSelected')}</span>
              </div>
            )}
            <div className="booking-question-for-product-divider" />
            <div className="questions-for-all-product">
              {!isPrefilled && (
                <>
                  <div className="pickup-points-container">
                    <PickUpPoints
                      cardId={id}
                      pickupPoints={pickupPoints}
                      dropoffPoints={dropoffPoints}
                    />
                  </div>
                  <div className="booking-questions">
                    <BookingQuestionsForProduct
                      cardId={id}
                      bookingQuestions={bookingQuestions}
                    />
                  </div>
                  {isSpecialRequestChecked && (
                    <div className="special-request">
                      <SpecialRequest cardId={id} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div />
        </div>
        <div className="card-policies">
          <h5 className="card-policies-header">
            {intl.get('checkout.policyAndTerms')} &nbsp;
          </h5>
          <div className="policies-body">
            {getPolicies(product.CancelInfo.Policies, date)}
          </div>
        </div>
      </div>
    );
  }
}
