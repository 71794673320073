// map checkout store to trips/booking/save
import { CheckoutStore } from '../stores/checkout.store';
import { TravelerFormModel } from '../models/form-models/travelerForm.model';
import { CheckoutPaymentModel } from '../models/checkoutPayment.model';
import { CardInCartModel } from '../models/cardInCart.model';
import { PaymentMethodsEnum } from '../models/enums/paymentMethods.enum';
import { IS_DEV } from '../utils/envUtils';
import PickupPointForm from '../models/tour-details/pickupPointForm.model';
import { hotelTypes } from '../components/Pages/Checkout/components/PickUpPoints';
import { BookingQuestionEnum } from '../models/tour-details/bookingQuestion.enum';
import { BookingQuestionTravelerForm } from '../models/tour-details/bookingQuestionTravelerForm.model';
import { BookingQuestionProductForm } from '../models/tour-details/bookingQuestionProductForm.mode';
import { checkIfProductHoldActive } from '../utils/cartUtils';
import { OrderRequestModel } from '../models/orderRequest.model';

const confirmationNumbers = {
  confirmed: 'confirmed',
};

const defaultBody = {
  TripId: 0,
  Reference: '',
  PrimaryGuestName: '',
  Guests: [],
  PaymentInfo: {
    ProfileID: '',
    PaymentMethod: 'CreditCard',
    CreditCardInfo: {
      CardNumber: '',
      ExpirationMonth: 0,
      ExpirationYear: 0,
      CardCode: '',
    },
    BankAccountInfo: {
      BankAccountNumber: '',
      NameOnAccount: '',
      RoutingNumber: '',
    },
    SaveInProfile: true,
  },
  CurrencyId: '',
  Services: [],
};

export const mapCheckoutStoreToBodyRequest = (
  checkoutStore: CheckoutStore,
  isPrefilled: boolean,
) => {
  const requestBody: OrderRequestModel = {
    ...defaultBody,
  };

  requestBody.TripId = checkoutStore.tripId;

  if (checkoutStore.referenceNumber) {
    requestBody.Reference = checkoutStore.referenceNumber;
  }

  requestBody.PrimaryGuestName = checkoutStore.primaryGuestName
    ? checkoutStore.primaryGuestName
    : checkoutStore.travelers.find((traveler) => traveler.isLead).lastName;

  if (!isPrefilled) {
    requestBody.Guests = mapCheckoutTravelersToRequestGuests(
      checkoutStore.cardsToCheckout,
      checkoutStore.travelers,
      checkoutStore.bookingQuestionsForTravelers,
    );
  } else {
    requestBody.Guests = null;
  }

  requestBody.PaymentInfo = mapCheckoutPaymentToRequestPayment(
    checkoutStore.paymentMethod,
  ) as any;

  requestBody.CurrencyId = ''; // ???

  if (!isPrefilled) {
    requestBody.Services = mapCardInCheckoutToRequestServices(
      checkoutStore,
    ) as any;
  } else {
    requestBody.Services = null;
  }

  return requestBody;
};

const mapCheckoutTravelersToRequestGuests = (
  cards: CardInCartModel[],
  travelers: TravelerFormModel[],
  bookingQuestionsTraveler: BookingQuestionTravelerForm[],
) =>
  travelers.map((traveler) => {
    let guestInCard;
    // we should find Age
    const card = cards.find((cardItem) => {
      const result = cardItem.product.Guests.find(
        (guest) => guest.travelerId === traveler.id,
      );

      if (result) {
        guestInCard = result;
        return true;
      }

      return false;
    });

    const questionForTravelerWeight = bookingQuestionsTraveler.find(
      (question) =>
        question.guestId === guestInCard.GuestId &&
        !!question[BookingQuestionEnum.passengerWeights],
    );
    let email;
    if (!!traveler.isLead && !!traveler.email && !!traveler.email.trim()) {
      email = traveler.email.trim();
    }

    return {
      GuestId: traveler.id,
      FirstName: traveler.getFirstName,
      LastName: traveler.getLastName,
      Title: '', // what 'title' means?
      Phone: traveler.phoneNumberWithCountryCode,
      Age: guestInCard.Age,
      PrimaryGuest: !!traveler.isLead,
      Email: email,
      Weight: questionForTravelerWeight
        ? +questionForTravelerWeight[BookingQuestionEnum.passengerWeights]
        : undefined,
      TimedHoldId: card?.holdInfo?.timeHoldId,
    };
  });

const mapCheckoutPaymentToRequestPayment = (payment: CheckoutPaymentModel) => {
  const initialPaymentInfo = {
    TestMode: IS_DEV,
  };

  // there should be a switch case for every payment method type
  if (payment.paymentMethodType === PaymentMethodsEnum.payLater) {
    // when pay later there shouldn't be any payInfo object
    return initialPaymentInfo;
  }

  if (payment.paymentMethodType === PaymentMethodsEnum.balance) {
    return {
      ...initialPaymentInfo,
      PaymentMethod: PaymentMethodsEnum.balance,
    };
  }

  if (payment.paymentMethodType === PaymentMethodsEnum.alipay) {
    // handle this payment type
  }

  if (payment.paymentMethodType === PaymentMethodsEnum.creditCard) {
    const body = {
      ...initialPaymentInfo,
      PaymentMethod: payment.paymentMethodType,
    };

    if (payment.newCardInfo && payment.newCardInfo.cardNumber) {
      return {
        ...body,
        SaveInProfile: payment.saveInProfile,
        CreditCardInfo: {
          CardNumber: payment.newCardInfo.cardNumber,
          ExpirationMonth: payment.newCardInfo.expirationMonth,
          ExpirationYear: payment.newCardInfo.expirationYear,
          CardCode: payment.newCardInfo.cardCode,
        },
      };
    }
    return {
      ...defaultBody,
      ProfileID: payment.selectedCard.ProfileID,
    };
  }
};

const mapCardInCheckoutToRequestServices = (checkoutStore: CheckoutStore) => {
  const {
    cardsToCheckout: cards,
    pickupPoints,
    bookingQuestionsForTravelers,
    bookingQuestionsForProducts,
    specialRequests,
  } = checkoutStore;

  return cards.map((card) => {
    const pickupPoint = pickupPoints.find(
      (point) => point.cardId === card.id && point.type === hotelTypes.pickup,
    );
    const dropOffPoint = pickupPoints.find(
      (point) => point.cardId === card.id && point.type === hotelTypes.dropOff,
    );

    const pickup = mapPickupPointFormToPickupPoint(pickupPoint);
    const dropOff = mapPickupPointFormToPickupPoint(dropOffPoint);

    const questionsForTravelers = bookingQuestionsForTravelers.filter(
      (item) => item.cardId === card.id,
    );
    const questionsForProduct = bookingQuestionsForProducts.find(
      (item) => item.cardId === card.id,
    );

    const bookingQuestionsResult = mapBookingQuestionsFormsToBookingQuestions(
      questionsForProduct,
      questionsForTravelers,
    );

    const specialRequest = specialRequests.find(
      (req) => req.cardId === card.id,
    );
    const specialRequestData = specialRequest
      ? specialRequest.text.trim()
      : undefined;

    const timedHoldId = checkIfProductHoldActive(card)
      ? card.holdInfo.timeHoldId
      : undefined;

    return {
      ServiceTime: card.product.ServiceTime,
      Guests: {
        Guests: card.product.Guests.map((guest) => ({
          GuestId: guest.travelerId,
          Seat: guest.Seat,
        })),
        PrimaryGuestId: card.product.Guests.find(
          (guest) => guest.isPrimaryGuest,
        ).travelerId,
      },
      PickupPoint: pickup,
      DropoffPoint: dropOff,
      SupplierData: card.product.SupplierData,
      TimedHoldId: timedHoldId,
      TripItemId: 0,
      ProductType: card.productType,
      ProductId: card.productId,
      CategoryId: card.product.Id,
      RatePlanId: card.product.RatePlan.Id,
      StartDate: card.date,
      AvailabilityLevel: card.product.AvailabilityLevel,
      Price: card.product.Price,
      ConnectionId: card.product.ConnectionId,
      ProductMappingId: card.product.ProductMappingId,
      UnderReview: true,
      BookingQuestions: bookingQuestionsResult.length
        ? bookingQuestionsResult
        : undefined,
      Note: specialRequestData,
      ConfirmationNumber: confirmationNumbers.confirmed,
    };
  });
};

const mapPickupPointFormToPickupPoint = (form: PickupPointForm) => {
  if (!form) {
    return;
  }

  if (form.isCustom) {
    return {
      Address: form.Address,
    };
  }

  return {
    LocationType: form.LocationType,
    LocationId: form.LocationId,
    Time: form.Time,
  };
};

const mapBookingQuestionsFormsToBookingQuestions = (
  productForm: BookingQuestionProductForm,
  forms: BookingQuestionTravelerForm[],
) => {
  const resultQuestions = [];

  // added to result question booking questions related only to entire product
  Object.entries(productForm)
    .filter(([property]) => property !== 'cardId')
    .forEach(([Id, Value]) => resultQuestions.push({ Id, Value }));

  const summaryQuestions = {};
  forms.forEach((form) => {
    Object.entries(form)
      .filter(([property]) => property !== 'cardId' && property !== 'guestId')
      .forEach(([questionId, Value]) => {
        if (questionId === BookingQuestionEnum.passengerWeights) {
          summaryQuestions[questionId]
            ? (summaryQuestions[questionId] += +Value)
            : (summaryQuestions[questionId] = +Value);
          return;
        }

        summaryQuestions[questionId]
          ? (summaryQuestions[questionId] += `, ${Value}`)
          : (summaryQuestions[questionId] = Value);
      });
  });

  Object.entries(summaryQuestions).forEach(([Id, Value]) => {
    if (Id === BookingQuestionEnum.passengerWeights) {
      // eslint-disable-next-line no-param-reassign
      Value = `${Value} kilos`;
    }

    resultQuestions.push({ Id, Value });
  });

  return resultQuestions;
};
