import { action, makeObservable, observable } from 'mobx';
import intl from 'react-intl-universal';
import { AxiosError } from 'axios';
import { get, uniqBy } from 'lodash';

import { router } from 'router';
import { UserFiltersFormValuesModel } from 'models/user-filters/userFiltersFormValues.model';
import { UserFiltersApi } from 'api/userFilters.api';
import { UserFilterModel } from 'models/user-filters/userFilter.model';
import { MessageTypeEnum } from 'models/global-messages/message.model';
import { addGlobalMessage } from 'components/SharedComponents/GlobalMessages';
import { UserFilterDataModel } from 'models/user-filters/userFilterData.model';
import { UserFilterCustomOptionsType } from 'models/user-filters/userFilterCustomOptions.type';
import { OrdersFilterSuggestionModel } from 'models/orders/ordersFilterSuggestion.model';
import { safeJSONParse, safeJSONStringify } from 'utils/jsonUtils';
import {
  FORBIDDEN_ERROR_CODE,
  TABLE_ORDERS_BLANK_FILTER_LABEL,
  TABLE_ORDERS_BLANK_FILTER_VALUE,
} from 'utils/constants';
import { RootStore } from './root.store';

export class UserFiltersStore {
  @observable public filters: UserFilterModel[] = [];
  @observable public customFilterOptions: UserFilterCustomOptionsType = {};
  @observable public selectedFilterId: string | null = null;

  constructor(
    private rootStore: RootStore,
    private apiService: UserFiltersApi,
  ) {
    makeObservable(this);
  }

  public getIsUserOwnedFilter(id: string) {
    const { userProperties } = this.rootStore.userStore;

    return this.getFilterById(id)?.UserName === userProperties.UserName;
  }

  public getFilterById(id: string) {
    return this.filters.find((filter) => filter.Uuid === id);
  }

  public getSelectedFilter() {
    return this.getFilterById(this.selectedFilterId);
  }

  public parseFilterData(filterData: string | null): UserFilterDataModel {
    return safeJSONParse(filterData) ?? {};
  }

  public stringifyFilterData(filterData: UserFilterDataModel) {
    return safeJSONStringify(filterData);
  }

  @action public setSelectedFilterId(id: string | null) {
    this.selectedFilterId = id;

    localStorage.setItem('orders-user-filter-id', id);
  }

  @action public resetSelectedFilterId() {
    this.selectedFilterId = null;

    localStorage.removeItem('orders-user-filter-id');
  }

  @action public setFilter(id: string | null) {
    const { ordersStore, commonStore } = this.rootStore;

    const filter = this.getFilterById(id);

    if (filter) {
      this.setSelectedFilterId(id);

      const userFilters = this.parseFilterData(filter.FilterData);

      this.setCustomFilterOptions(userFilters.customFilterOptions);
      ordersStore.setUserFilters(userFilters);
      router.navigate({ search: userFilters.searchParams }, { replace: true });

      return filter;
    }

    this.resetSelectedFilterId();
    this.setCustomFilterOptions({});

    ordersStore.setVisibleColumnsIds(commonStore.ordersListColumnsIds);
    ordersStore.setColumnWidths({});

    router.navigate({ search: '' }, { replace: true });

    return null;
  }

  @action.bound public async getOrdersFilter() {
    try {
      const response = await this.apiService.getOrdersFilters();

      this.filters = response.data;
    } catch (e) {
      this.filters = [];
    }
  }

  @action public setCustomFilterOptions(
    customFilterOptions: UserFilterCustomOptionsType,
  ) {
    if (customFilterOptions) {
      this.customFilterOptions = customFilterOptions;
    }
  }

  @action public addCustomFilterOptions(field: string, optionKeys: string[]) {
    const fieldOptions = this.customFilterOptions[field];
    const newOptions: OrdersFilterSuggestionModel[] = optionKeys.map((key) => ({
      Key: key,
      Value:
        key === TABLE_ORDERS_BLANK_FILTER_VALUE
          ? TABLE_ORDERS_BLANK_FILTER_LABEL
          : key,
    }));

    this.customFilterOptions[field] = fieldOptions
      ? uniqBy([...fieldOptions, ...newOptions], (option) => option.Key)
      : [...newOptions];
  }

  @action public removeCustomFilterOption(field: string, optionKey: string) {
    const fieldOptions = this.getFieldCustomFilterOptions(field);
    const index = fieldOptions.findIndex((option) => option.Key === optionKey);

    this.customFilterOptions[field].splice(index, 1);
  }

  public getFieldCustomFilterOptions(field: string) {
    return this.customFilterOptions[field] ?? [];
  }

  private mapFormValuesToBody(
    formValues: UserFiltersFormValuesModel,
  ): UserFilterModel {
    const { ordersStore } = this.rootStore;

    const isAvailableForSuppliers = formValues.isAvailableForSuppliers ?? false;
    const isAvailableForAgents = formValues.isAvailableForAgents ?? false;

    return {
      IsSharedForSuppliers: isAvailableForSuppliers,
      IsSharedForAgency: isAvailableForAgents,
      IsPrivateForCurrentUser: true,
      FilterData: this.stringifyFilterData({
        name: formValues.name,
        searchParams: router.state.location.search,
        visibleColumnIds: ordersStore.visibleColumnIds,
        columnWidths: ordersStore.columnWidths,
        customFilterOptions: this.customFilterOptions,
      }),
    };
  }

  @action.bound public async createOrderFilter(
    formValues: UserFiltersFormValuesModel,
  ): Promise<{ error: string }> {
    const { userStore } = this.rootStore;
    const { userProperties } = userStore;

    try {
      const body: UserFilterModel = {
        Agency: userProperties.Agy_No,
        OperatorCode: userProperties.Operator_Code,
        UserName: userProperties.UserName,
        ...this.mapFormValuesToBody(formValues),
      };

      const { data } = await this.apiService.createOrderFilter(body);
      await this.getOrdersFilter();

      this.setFilter(data.Uuid);

      addGlobalMessage({
        message: intl.get('userFilters.saveSuccess'),
        type: MessageTypeEnum.success,
      });

      return { error: null };
    } catch (error) {
      const filterError = error.response.data?.ModelState.Filter;
      const message = filterError?.ShowToUser
        ? filterError.Message
        : intl.get('userFilters.saveFail');

      addGlobalMessage({ message, type: MessageTypeEnum.error });

      return { error: this.handleSaveError(error) };
    }
  }

  @action.bound public async updateOrderFilter(
    formValues: UserFiltersFormValuesModel,
  ): Promise<{ error: string }> {
    try {
      const selectedFilter = this.getSelectedFilter();

      const body: UserFilterModel = {
        ...selectedFilter,
        ...this.mapFormValuesToBody(formValues),
      };

      await this.apiService.updateOrderFilter(body);
      await this.getOrdersFilter();

      addGlobalMessage({
        message: intl.get('userFilters.updateSuccess'),
        type: MessageTypeEnum.success,
      });

      return { error: null };
    } catch (error) {
      const filterError = error.response.data?.ModelState.Filter;
      const message = filterError?.ShowToUser
        ? filterError.Message
        : intl.get('userFilters.updateFail');

      addGlobalMessage({ message, type: MessageTypeEnum.error });

      return { error: this.handleSaveError(error) };
    }
  }

  private handleSaveError(error: AxiosError) {
    const errorCode = get(error, 'response.data.ModelState.Filter.Code');

    return errorCode === FORBIDDEN_ERROR_CODE
      ? intl.get('userFilters.permissionsError')
      : '';
  }

  @action public async deleteOrderFilter() {
    try {
      await this.apiService.deleteOrderFilterById(this.selectedFilterId);
      await this.getOrdersFilter();

      this.setFilter(null);

      addGlobalMessage({
        message: intl.get('userFilters.deleteSuccess'),
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      const filterError = error.response.data?.ModelState.Filter;
      const message = filterError?.ShowToUser
        ? filterError.Message
        : intl.get('userFilters.deleteFail');

      addGlobalMessage({ message, type: MessageTypeEnum.error });
    }
  }

  @action public clearStore() {
    this.filters = [];
    this.selectedFilterId = null;
    this.customFilterOptions = {};
  }
}
