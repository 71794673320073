import React from 'react';

import { withStore } from 'hocs';
import { AuthStore } from 'stores/auth.store';
import { Navigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { checkUserGroup } from 'utils/userRolesUtils';
import { UserStore } from 'stores/user.store';

type Props = {
  authStore?: AuthStore;
  userStore?: UserStore;
  roles?: string[];
  children: React.ReactElement;
  redirectTo?: string;
};

function PrivateRoute(props: Props) {
  const {
    authStore,
    userStore,
    roles,
    children,
    redirectTo = '/login',
  } = props;
  const { isLoggedIn } = authStore;
  const { userProperties } = userStore;

  const hasRoleAccess = isEmpty(roles) || checkUserGroup(userProperties, roles);
  const hasAccess = isLoggedIn && hasRoleAccess;

  return hasAccess ? children : <Navigate to={redirectTo} />;
}

export default withStore(({ rootStore }) => ({
  authStore: rootStore.authStore,
  userStore: rootStore.userStore,
}))(PrivateRoute);
