import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import mapboxgl, { MarkerOptions, Marker, Map } from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { mapBoxToken } from '../../utils/constants';

mapboxgl.accessToken = mapBoxToken;

export type MapBoxMarker = {
  options?: MarkerOptions;
  onInit?: (marker: Marker, map: Map) => void;
};

type Props = {
  markers?: MapBoxMarker[];
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

function MapBox(props: Props) {
  const { markers = [], ...restProps } = props;

  const mapContainer = useRef(null);
  const [lng, setLng] = useState(-96.6);
  const [lat, setLat] = useState(40.2);
  const [zoom, setZoom] = useState(3);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      testMode: true,
      zoom,
    });

    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    markers.forEach((marker) => {
      const instance = new mapboxgl.Marker(marker.options);

      marker.onInit(instance, map);
    });

    map.on('move', () => {
      setLng(map.getCenter().lng);
      setLat(map.getCenter().lat);
      setZoom(map.getZoom());
    });

    return () => map.remove();
  }, []);

  return <div ref={mapContainer} {...restProps} />;
}

export default MapBox;
