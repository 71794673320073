import React, { Component } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import { GlobalMessagesStore } from '../../../stores/globalMessages.store';
import { withStore } from '../../../hocs';

@withStore(({ rootStore }) => ({
  globalMessagesStore: rootStore.globalMessagesStore,
}))
export default class GlobalSpinner extends Component<{
  globalMessagesStore?: GlobalMessagesStore;
}> {
  render() {
    const {
      globalMessagesStore: { isSpinnerVisible },
    } = this.props;

    return (
      isSpinnerVisible && (
        <div className="global-spinner">
          <div className="global-background">
            <LoadingOutlined className="spin" />
          </div>
        </div>
      )
    );
  }
}
