import { Component, ReactNode } from 'react';

import { UserStore } from 'stores/user.store';
import { AuthStore } from 'stores/auth.store';
import { withStore } from 'hocs';
import { checkUserGroup } from 'utils/userRolesUtils';
import { UserPropertiesModel } from 'models/userProperties.model';

@withStore(({ rootStore }) => ({
  userStore: rootStore.userStore,
  authStore: rootStore.authStore,
}))
export default class Accessor extends Component<{
  children: ReactNode;
  roles?: Array<string>;
  checkUser?: (userProperties: UserPropertiesModel) => boolean;
  userStore?: UserStore;
  authStore?: AuthStore;
}> {
  render() {
    const {
      userStore: { userProperties },
      authStore: { isLoggedIn },
      roles,
      checkUser,
      children,
    } = this.props;
    let hasAccess = false;

    if (isLoggedIn) {
      if (checkUser) {
        hasAccess = checkUser(userProperties);
      } else if (!roles || !roles.length) {
        hasAccess = true;
      } else {
        hasAccess = checkUserGroup(userProperties, roles);
      }
    }

    return hasAccess ? children : null;
  }
}
