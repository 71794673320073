import intl from 'react-intl-universal';
import moment from 'moment';
import { compact } from 'lodash';

import { parseServiceTime } from 'utils/datesUtils';
import { ExcelReportModel } from 'models/reports/excelReport.model';
import { StatusEnum } from 'models/enums/status.enum';
import { OrderKeysEnum } from 'models/enums/orderKeys.enum';
import { getNotificationStatusLabel } from 'components/Pages/Orders/utils/orderUtils';
import { OrderTableDataModel } from 'models/orders/orderTableData.model';
import { ExcelReportGenerator } from './excelReportGenerator';

class OrderExcelReportGeneratorClass extends ExcelReportGenerator {
  public convertData(
    orders: OrderTableDataModel[],
    visibleColumnIds: string[],
  ) {
    const columns = {
      [OrderKeysEnum.TripId]: {
        title: intl.get('orders.table.transactionNumber'),
        getValue: (order: OrderTableDataModel) =>
          `${order.TripId}-${order.TripItemId}`,
      },
      [OrderKeysEnum.TripItemId]: {
        title: intl.get('orders.table.itemId'),
        getValue: (order: OrderTableDataModel) => order.TripItemId,
      },
      [OrderKeysEnum.Reference]: {
        title: intl.get('orders.table.reference'),
        getValue: (order: OrderTableDataModel) => order.Reference,
      },
      [OrderKeysEnum.Name]: {
        title: intl.get('orders.table.productName'),
        getValue: (order: OrderTableDataModel) => order.Name,
      },
      [OrderKeysEnum.ProductType]: {
        title: intl.get('orders.table.productType'),
        getValue: (order: OrderTableDataModel) => order.ProductType,
      },
      [OrderKeysEnum.ProductId]: {
        title: intl.get('orders.table.productId'),
        getValue: (order: OrderTableDataModel) => order.ProductId,
      },
      [OrderKeysEnum.CategoryName]: {
        title: intl.get('orders.table.categoryName'),
        getValue: (order: OrderTableDataModel) => order.CategoryName,
      },
      [OrderKeysEnum.CategoryId]: {
        title: intl.get('orders.table.categoryId'),
        getValue: (order: OrderTableDataModel) => order.CategoryId,
      },
      [OrderKeysEnum.PrimaryEmail]: {
        title: intl.get('orders.table.email'),
        getValue: (order: OrderTableDataModel) => order.PrimaryEmail,
      },
      [OrderKeysEnum.PrimaryPhone]: {
        title: intl.get('orders.table.phone'),
        getValue: (order: OrderTableDataModel) => order.PrimaryPhone,
      },
      [OrderKeysEnum.PrimaryGuestName]: {
        title: intl.get('orders.table.customer'),
        getValue: (order: OrderTableDataModel) => order.PrimaryGuestName,
      },
      [OrderKeysEnum.StartDate]: {
        title: intl.get('orders.table.serviceDate'),
        getValue: (order: OrderTableDataModel) =>
          moment(order.StartDate).format('MMM D'),
      },
      [OrderKeysEnum.PassengerCount]: {
        title: intl.get('orders.table.guests'),
        getValue: (order: OrderTableDataModel) => order.PassengerCount,
      },
      [OrderKeysEnum.Nights]: {
        title: intl.get('orders.table.nights'),
        getValue: (order: OrderTableDataModel) => order.Nights,
      },
      [OrderKeysEnum.AgencyId]: {
        title: intl.get('orders.table.agencyId'),
        getValue: (order: OrderTableDataModel) => order.AgencyId,
      },
      [OrderKeysEnum.AgencyName]: {
        title: intl.get('orders.table.agencyName'),
        getValue: (order: OrderTableDataModel) => order.AgencyName,
      },
      [OrderKeysEnum.ServiceTime]: {
        title: intl.get('orders.table.serviceTime'),
        getValue: (order: OrderTableDataModel) =>
          parseServiceTime(order.ServiceTime)?.format('h:mm a') ?? '-',
      },
      [OrderKeysEnum.OperatorId]: {
        title: intl.get('orders.table.operatorId'),
        getValue: (order: OrderTableDataModel) => order.OperatorId,
      },
      [OrderKeysEnum.OperatorName]: {
        title: intl.get('orders.table.operatorName'),
        getValue: (order: OrderTableDataModel) => order.OperatorName,
      },
      [OrderKeysEnum.Price]: {
        title: intl.get('orders.table.price'),
        getValue: (order: OrderTableDataModel) => order.Price,
      },
      [OrderKeysEnum.Cost]: {
        title: intl.get('orders.table.cost'),
        getValue: (order: OrderTableDataModel) => order.Cost,
      },
      [OrderKeysEnum.Confirmation]: {
        title: intl.get('orders.table.confirmation'),
        getValue: (order: OrderTableDataModel) => order.Confirmation,
      },
      [OrderKeysEnum.Inactive]: {
        title: intl.get('orders.table.paymentStatus'),
        getValue: (order: OrderTableDataModel) =>
          !order.Inactive ? StatusEnum.active : StatusEnum.cancelled,
      },
      [OrderKeysEnum.CreatedOn]: {
        title: intl.get('orders.table.createdOn'),
        getValue: (order: OrderTableDataModel) =>
          moment(order.CreatedOn).format('YYYY-MM-DD'),
      },
      [OrderKeysEnum.TestMode]: {
        title: intl.get('orders.table.testOrder'),
        getValue: (order: OrderTableDataModel) =>
          order.TestMode
            ? intl.get('orders.table.testOrder')
            : intl.get('orders.table.liveOrder'),
      },
      [OrderKeysEnum.CommunicationStatus]: {
        title: intl.get('orders.table.communicationStatus'),
        getValue: (order: OrderTableDataModel) =>
          getNotificationStatusLabel(
            order.Communications.NotificationCurrentStatusEnumerable[0]?.Status,
          ),
      },
    };

    const titles = compact(visibleColumnIds.map((id) => columns[id]?.title));
    const reportData = orders.map((order) =>
      compact(visibleColumnIds.map((id) => columns[id]?.getValue(order))),
    );

    return [titles, ...reportData];
  }

  public downloadReport(
    orders: OrderTableDataModel[],
    visibleColumnIds: string[],
    onDownloadEnd?: () => void,
  ) {
    const reportModel: ExcelReportModel = {
      fileName: intl.get('sidebarMenu.orders'),
      workSheetData: this.convertData(orders, visibleColumnIds),
    };

    super.generateReport(reportModel);

    if (onDownloadEnd) {
      onDownloadEnd();
    }
  }
}

export const OrderExcelReportGenerator: OrderExcelReportGeneratorClass =
  new OrderExcelReportGeneratorClass();
