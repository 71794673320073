import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { CommonStore } from '../../../../stores/common.store';
import { MarketplaceStore } from '../../../../stores/marketplace.store';
import { UserStore } from '../../../../stores/user.store';
import { SuggestedProductModel } from '../../../../models/marketplace/suggestedProduct.model';
import { LocationTypesEnum } from '../../../../models/enums/locationTypes.enum';
import { moneyFormat } from '../../../../utils/formatter';
import ImageComponent from '../../../SharedComponents/Image';
import { availableLanguages } from '../../../../models/enums/language.enums';
import { textToDisplayFromSuggestion } from '../utils/searchUtils';
import { ProductSuggestTypeEnum } from '../../../../models/enums/productSuggestType.enum';
import { RouterProps, withRouter, withStore } from '../../../../hocs';

type Props = {
  onSuggestProductClick: Function;
  handleNewSearch: Function;
  marketplaceStore?: MarketplaceStore;
  commonStore?: CommonStore;
  userStore?: UserStore;
} & RouterProps;

@withRouter
@withStore(({ rootStore }) => ({
  marketplaceStore: rootStore.marketplaceStore,
  commonStore: rootStore.commonStore,
  userStore: rootStore.userStore,
}))
export default class TourSearchModule extends Component<Props> {
  inputRef = null;

  state = {
    searchText: '',
    textToDisplay: '',
    isFocused: false,
  };

  searchTimeout: NodeJS.Timeout;

  componentDidMount() {
    this.initialHistoryChanges();
  }

  componentWillUnmount() {
    clearTimeout(this.searchTimeout);
  }

  initialHistoryChanges = () => {
    const {
      marketplaceStore: { isSearched, searchText, selectedLocation },
      userStore: { language },
      marketplaceStore,
      location,
      navigate,
    } = this.props;

    const params = new URLSearchParams(location.search);

    if (
      params.has('isContinueShopping') &&
      params.get('isContinueShopping') === 'true'
    ) {
      if (isSearched) {
        const textToDisplay =
          searchText || textToDisplayFromSuggestion(language, selectedLocation);
        this.setState({
          searchText,
          textToDisplay,
        });
      }

      params.delete('isContinueShopping');
      navigate({ search: params.toString() });
    } else {
      marketplaceStore.setIsSearched(false);
    }

    if (params.has('searchText') && params.get('searchText').length > 0) {
      const searchQuery = params.get('searchText');

      marketplaceStore.setIsSearched(true);
      marketplaceStore.setSearchText(searchQuery);
      this.setState({
        searchText: searchQuery,
        textToDisplay: searchQuery,
      });
    } else if (params.has('suggestion')) {
      let suggestion;
      try {
        suggestion = JSON.parse(params.get('suggestion'));

        marketplaceStore.setLocation(suggestion);
        marketplaceStore.setIsLocation(true);
        marketplaceStore.setIsSearched(true);
        marketplaceStore.setServiceCodeAndLocation(
          suggestion.Code,
          suggestion.LocationType,
        );
        marketplaceStore.setSearchText('');

        this.setState({
          textToDisplay: textToDisplayFromSuggestion(language, suggestion),
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    marketplaceStore.getNecessaryProducts();
  };

  handleSearchInputFocus = (isFocused: boolean) => {
    setTimeout(() => {
      this.setState({ isFocused });
    }, 100);

    if (!isFocused) this.inputRef.blur();
  };

  handleSeeAllResults = () => {
    const { searchText } = this.state;
    const { marketplaceStore, location, navigate, handleNewSearch } =
      this.props;

    const params = new URLSearchParams(location.search);
    // we should set page = 1
    const currentPage = +params.get('page');
    let needHistoryPush = false;

    if (searchText) {
      needHistoryPush = true;
      params.set('searchText', searchText);
      params.delete('suggestion');
    }

    if (currentPage && currentPage !== 1) {
      needHistoryPush = true;
      params.set('page', '1');
    }

    if (needHistoryPush) {
      navigate({ search: params.toString() });
    }

    marketplaceStore.setIsSearched(true);
    marketplaceStore.setIsLocation(false);
    marketplaceStore.setSearchText(searchText);
    marketplaceStore.getNecessaryProducts();
    handleNewSearch();

    this.handleSearchInputFocus(false);
  };

  handleSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText: string = event.target.value;
    const { marketplaceStore } = this.props;

    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      marketplaceStore.getSuggestions(searchText).then((suggestions) => {
        if (suggestions && suggestions.length === 0) {
          marketplaceStore.getProductsAndSetSuggestions(searchText);
        }
      });
      this.setState({ searchText });
    }, 300);

    this.setState({
      textToDisplay: searchText,
      searchText,
    });
  };

  // adding ability to search by press "Enter" key
  handlePressKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      this.handleSeeAllResults();
    }
  };

  render() {
    const { isFocused, textToDisplay, searchText } = this.state;
    const {
      marketplaceStore: { suggestedProducts },
      onSuggestProductClick,
      commonStore: { settings },
      userStore: { language },
    } = this.props;

    const products: SuggestedProductModel[] = suggestedProducts
      .filter(
        (suggestion: SuggestedProductModel) =>
          suggestion.LocationType === LocationTypesEnum.service,
      )
      .filter((suggestion: SuggestedProductModel) =>
        suggestion.ProductType
          ? suggestion.ProductType === ProductSuggestTypeEnum.service
          : true,
      )
      .slice(0, 5);
    const shouldDisplayTranslatedName = language === availableLanguages.CHI; // requirement from Matt

    return (
      <div className="search-module">
        <div className="search-form">
          <div className="search-input">
            <Input
              prefix={<SearchOutlined className="search-icon" />}
              placeholder={intl.get('marketplacePage.productNameOrCode')}
              onChange={this.handleSearchChanged}
              onFocus={() => this.handleSearchInputFocus(true)}
              onBlur={() => this.handleSearchInputFocus(false)}
              onKeyPress={this.handlePressKey}
              ref={(element) => (this.inputRef = element)}
              value={textToDisplay}
            />
          </div>
          <div className="submit-btn">
            <Button
              type="primary"
              htmlType="button"
              size="large"
              onClick={() => this.handleSeeAllResults()}
            >
              {intl.get('buttons.search')}
            </Button>
          </div>
        </div>
        {isFocused && suggestedProducts.length > 0 && (
          <div className="suggestions">
            <div className="products">
              {products.map((suggestion: SuggestedProductModel) => (
                <div
                  className="suggestion-item"
                  key={suggestion.Code}
                  onMouseDown={() =>
                    onSuggestProductClick(
                      suggestion.Code,
                      suggestion.Language_Code,
                    )
                  }
                >
                  <ImageComponent
                    className="image"
                    src={
                      suggestion.ThumbnailImage
                        ? `${settings ? settings.ImagesRoot : null}/${encodeURI(
                            suggestion.ThumbnailImage.replace(/\\/g, '/'),
                          )}`
                        : null
                    }
                  />
                  <div>
                    <div
                      className="label"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: shouldDisplayTranslatedName
                          ? suggestion.TranslatedName
                          : suggestion['@search.text'],
                      }}
                    />
                    {!!suggestion.StartingPrice && (
                      <div className="price">
                        {`${intl.get('from')} ${moneyFormat(
                          Number(suggestion.StartingPrice),
                        )}`}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div
              className="more-results"
              onClick={() => this.handleSeeAllResults()}
            >
              <i className="tc-glass icon" />
              {intl.get('marketplacePage.findMoreResults')}
              &nbsp;
              <span className="searched-text">{searchText}</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
