import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Select } from 'antd';

import { CheckoutStore } from '../../../../stores/checkout.store';
import TravelerForm from './TravelerForm';
import TravelerLeadForm from './TravelerLeadForm';
import { withStore } from '../../../../hocs';

@withStore(({ rootStore }) => ({
  checkoutStore: rootStore.checkoutStore,
}))
export default class NumberOfTravelersAndForms extends Component<{
  checkoutStore?: CheckoutStore;
  isPrefilled: boolean;
}> {
  handleSelectChange = (value) => {
    const { checkoutStore } = this.props;

    checkoutStore.setNumberOfTravelers(value);
  };

  getSelectOptions = () => {
    const {
      checkoutStore: { minNumberOfTravelers, maxNumberOfTravelers },
    } = this.props;
    const numberOfTravelers = [];

    // eslint-disable-next-line no-plusplus
    for (let i = minNumberOfTravelers; i <= maxNumberOfTravelers; i++) {
      numberOfTravelers.push(i);
    }

    return numberOfTravelers.map((item) => (
      <Select.Option key={item} value={item}>
        {item}
      </Select.Option>
    ));
  };

  renderNumberOfTravelers = (
    numberOfTravelers: number,
    isDisabled: boolean,
  ) => {
    const options = this.getSelectOptions();

    return (
      <div className="number-of-travelers">
        <div className="number-of-travelers-text">
          {intl.get('checkout.numberOfTravelers')}
        </div>
        <div className="number-of-travelers-input">
          <Select
            className="checkout-field"
            disabled={isDisabled}
            defaultValue={numberOfTravelers}
            onChange={this.handleSelectChange}
          >
            {options}
          </Select>
        </div>
      </div>
    );
  };

  renderForms = () => {
    const {
      checkoutStore: {
        numberOfTravelers,
        travelers,
        isAllPassengerNamesRequired,
      },
      isPrefilled,
    } = this.props;
    const result = !isPrefilled
      ? new Array(numberOfTravelers)
          .fill(undefined)
          .map((value, index) =>
            index !== 0 ? (
              <TravelerForm
                formId={index + 1}
                isPrefilled={isPrefilled}
                isFieldsRequired={isAllPassengerNamesRequired}
                key={index + 1}
              />
            ) : (
              <TravelerLeadForm
                formId={index + 1}
                isPrefilled={isPrefilled}
                key={index + 1}
              />
            ),
          )
      : travelers.map((traveler) =>
          !traveler.isLead ? (
            <TravelerForm
              formId={traveler.id}
              isPrefilled={isPrefilled}
              isFieldsRequired={isAllPassengerNamesRequired}
              key={traveler.id}
            />
          ) : (
            <TravelerLeadForm
              formId={traveler.id}
              isPrefilled={isPrefilled}
              key={traveler.id}
            />
          ),
        );

    return result;
  };

  render() {
    const {
      checkoutStore: { numberOfTravelers },
      isPrefilled,
    } = this.props;

    return (
      <div className="number-of-travelers-and-forms">
        <div>
          {this.renderNumberOfTravelers(numberOfTravelers, isPrefilled)}
        </div>
        <div>{this.renderForms()}</div>
      </div>
    );
  }
}
