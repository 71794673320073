import React from 'react';
import { Button, Col, DatePicker, Row, Select } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Navigate, NavigateAction, View } from 'react-big-calendar';
import { Moment } from 'moment';

import { momentRange } from 'utils/dateRangeUtils';

type Props = {
  date: Date;
  view: View;
  onNavigate: (navigate: NavigateAction, date?: Date) => void;
};

function ProductDetailsCalendarNav(props: Props) {
  const { date, view, onNavigate } = props;

  const dateMoment = momentRange(date);
  const minDate = momentRange().startOf('year').subtract(1, 'year');
  const maxDate = momentRange().endOf('year').add(2, 'years');

  const getMonthOptions = () =>
    momentRange.months().map((month, index) => ({
      label: month,
      value: index,
    }));

  const getYearOptions = () => {
    const range = momentRange.range([minDate, maxDate]);

    return Array.from(range.by('year')).map((value) => ({
      label: value.format('YYYY'),
      value: value.year(),
    }));
  };

  const getPickerDisabledDate = (value: Moment) =>
    value.isBefore(minDate) || value.isAfter(maxDate);

  const formatDayPicker = (value: Moment) =>
    momentRange(value).format('dddd MMM DD');

  const formatWeekPicker = (value: Moment) =>
    [
      momentRange(value).startOf('week').format('MMMM DD'),
      momentRange(value).endOf('week').format('MMMM DD'),
    ].join(' - ');

  const onDateChange = (value: Moment) => {
    onNavigate(Navigate.DATE, value.toDate());
  };

  const onMonthChange = (value: number) => {
    onNavigate(Navigate.DATE, dateMoment.month(value).toDate());
  };

  const onYearChange = (value: number) => {
    onNavigate(Navigate.DATE, dateMoment.year(value).toDate());
  };

  return (
    <Row gutter={10} align="middle">
      <Col>
        <Button type="link" onClick={() => onNavigate(Navigate.PREVIOUS)}>
          <LeftOutlined />
        </Button>
      </Col>

      {view === 'day' && (
        <DatePicker
          allowClear={false}
          value={dateMoment}
          format={formatDayPicker}
          disabledDate={getPickerDisabledDate}
          onChange={onDateChange}
        />
      )}

      {view === 'week' && (
        <DatePicker
          picker="week"
          allowClear={false}
          value={dateMoment}
          format={formatWeekPicker}
          disabledDate={getPickerDisabledDate}
          onChange={onDateChange}
        />
      )}

      {view === 'month' && (
        <>
          <Col>
            <Select
              style={{ width: 200 }}
              value={dateMoment.month()}
              options={getMonthOptions()}
              onChange={onMonthChange}
            />
          </Col>

          <Col>
            <Select
              style={{ width: 80 }}
              value={dateMoment.year()}
              options={getYearOptions()}
              onChange={onYearChange}
            />
          </Col>
        </>
      )}

      <Col>
        <Button type="link" onClick={() => onNavigate(Navigate.NEXT)}>
          <RightOutlined />
        </Button>
      </Col>
    </Row>
  );
}

export default ProductDetailsCalendarNav;
