import { Key } from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import intl from 'react-intl-universal';
import {
  groupBy,
  isEmpty,
  max,
  min,
  get,
  set,
  omit,
  isArray,
  uniqueId,
} from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import filterBuilder from 'odata-filter-builder';

import {
  FilterValue,
  SorterResult,
  SortOrder,
  TableCurrentDataSource,
} from 'antd/lib/table/interface';

import { UserFiltersFormValuesModel } from 'models/user-filters/userFiltersFormValues.model';
import { InventoryApi } from '../api/inventory.api';
import { RootStore } from './root.store';
import { addGlobalMessage } from '../components/SharedComponents/GlobalMessages';
import { MessageTypeEnum } from '../models/global-messages/message.model';
import { InventoryModel } from '../models/inventory/inventory.model';
import { InventoryEditTypeEnum } from '../models/enums/inventoryEditType.enum';
import { InventoryBulkViewTableModel } from '../models/inventory/inventoryBulkViewTable.model';
import { getAvailabilityQuantityByEditType } from '../utils/inventoryHelper';
import { getDatesFromRange, momentRange } from '../utils/dateRangeUtils';
import { flattenBy, isSameBy } from '../utils/arrayUtils';
import {
  getColumns,
  mapInventoryToViewTableData,
} from '../utils/bulkInventoryUtils';
import { ProductTypeEnum } from '../models/enums/productType.enum';
import { ViewCriteriaModel } from '../models/inventory/viewCriteria.model';
import { ViewCriteriaItemModel } from '../models/inventory/viewCriteriaItem.model';
import { DateRangeTemplateEnum } from '../models/enums/dateRangeTemplate.enum';
import {
  getDateRangeByTemplate,
  getIsDateRangeTemplateValid,
} from '../utils/datesUtils';
import { MomentRangeModel } from '../models/momentRange.model';

export class BulkInventoryStore {
  private rootStore: RootStore;
  private apiService: InventoryApi;

  private minAvailabilityQuantity = 1;

  private readonly columns = [];

  @observable public datesRange: DateRangeTemplateEnum | string[] =
    DateRangeTemplateEnum.Today;

  @observable public filters: Record<string, FilterValue> = {};

  @observable public sorts: Record<string, SortOrder> = {};

  @observable public groupedBy: string[] = [];

  @observable public selectedViewCriteriaId = undefined;

  @observable public savedViewCriteria: ViewCriteriaModel = null;

  @observable public inventory: InventoryModel[] = [];

  @observable public productType: ProductTypeEnum = null;

  @observable public selectedRowKeys: Key[] = [];

  @observable public selectedRows: InventoryBulkViewTableModel[] = [];

  @observable public availabilityQuantity = 0;

  @observable public stopSell = false;

  @observable public editType: InventoryEditTypeEnum =
    InventoryEditTypeEnum.SetTo;

  constructor(rootStore: RootStore, apiService: InventoryApi) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.apiService = apiService;

    this.columns = getColumns(this);
  }

  @action
  public changeTableSortsAndFilters(
    _,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<InventoryBulkViewTableModel>
      | SorterResult<InventoryBulkViewTableModel>[],
    extra: TableCurrentDataSource<InventoryBulkViewTableModel>,
  ) {
    if (extra.action === 'filter') {
      this.filters = filters;
    }

    if (extra.action === 'sort' && !isArray(sorter)) {
      this.sorts = { [sorter.columnKey as string]: sorter.order };
    }
  }

  @computed public get savedViewCriteriaList() {
    return this.savedViewCriteria?.[this.productType] ?? [];
  }

  @action public onSelectedViewCriteriaChange(id: string) {
    const selectedViewCriteriaItem = this.savedViewCriteriaList.find(
      (viewCriteriaItem) => viewCriteriaItem.id === id,
    );

    this.selectedViewCriteriaId = id;
    this.sorts = selectedViewCriteriaItem?.sorts ?? {};
    this.filters = selectedViewCriteriaItem?.filters ?? {};
    this.groupedBy = selectedViewCriteriaItem?.groupedBy ?? [];

    if (selectedViewCriteriaItem?.datesRange) {
      this.datesRange = selectedViewCriteriaItem.datesRange;
    }
  }

  @action public async getBulkViewCriteria() {
    const response = await this.apiService.getBulkViewCriteria();

    this.savedViewCriteria = response.data;
  }

  private getUpdatedViewCriteriaByList(
    viewCriteriaList: ViewCriteriaItemModel[],
  ): ViewCriteriaModel {
    return {
      ...this.savedViewCriteria,
      [this.productType]: viewCriteriaList,
    };
  }

  @action public async loadBulkViewCriteria() {
    try {
      await this.getBulkViewCriteria();
    } catch (e) {
      addGlobalMessage({
        message: intl.get('inventory.bulkView.failedToLoadViewCriteria'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action public async createBulkViewCriteria(
    formValues: UserFiltersFormValuesModel,
  ) {
    try {
      const newViewItem = new ViewCriteriaItemModel({
        id: uuid(),
        name: formValues.name,
        sorts: this.sorts,
        filters: this.filters,
        groupedBy: this.groupedBy,
        datesRange: this.datesRange,
      });

      const newViewCriteria = this.getUpdatedViewCriteriaByList([
        ...this.savedViewCriteriaList,
        newViewItem,
      ]);

      await this.apiService.createOrUpdateBulkViewCriteria(newViewCriteria);

      await this.getBulkViewCriteria();

      this.selectedViewCriteriaId = newViewItem.id;

      addGlobalMessage({
        message: intl.get('inventory.bulkView.viewCriteriaHasBeenSaved'),
        type: MessageTypeEnum.success,
      });
    } catch (e) {
      addGlobalMessage({
        message: intl.get('inventory.bulkView.failedToSaveViewCriteria'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action public async updateSelectedBulkViewCriteria() {
    try {
      const newViewCriteriaList = this.savedViewCriteriaList.map(
        (savedViewCriteria) =>
          savedViewCriteria.id === this.selectedViewCriteriaId
            ? new ViewCriteriaItemModel({
                id: savedViewCriteria.id,
                name: savedViewCriteria.name,
                sorts: this.sorts,
                filters: this.filters,
                groupedBy: this.groupedBy,
                datesRange: this.datesRange,
              })
            : savedViewCriteria,
      );

      const newViewCriteria =
        this.getUpdatedViewCriteriaByList(newViewCriteriaList);

      await this.apiService.createOrUpdateBulkViewCriteria(newViewCriteria);

      await this.getBulkViewCriteria();

      addGlobalMessage({
        message: intl.get('inventory.bulkView.viewCriteriaHasBeenUpdated'),
        type: MessageTypeEnum.success,
      });
    } catch (e) {
      addGlobalMessage({
        message: intl.get('inventory.bulkView.failedToUpdateViewCriteria'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action public async deleteSelectedBulkViewCriteria() {
    try {
      const newViewCriteriaList = this.savedViewCriteriaList.filter(
        (savedViewCriteria) =>
          savedViewCriteria.id !== this.selectedViewCriteriaId,
      );

      const newViewCriteria =
        this.getUpdatedViewCriteriaByList(newViewCriteriaList);

      await this.apiService.createOrUpdateBulkViewCriteria(newViewCriteria);

      await this.getBulkViewCriteria();

      this.resetViewCriteria();

      addGlobalMessage({
        message: intl.get('inventory.bulkView.viewCriteriaHasBeenDeleted'),
        type: MessageTypeEnum.success,
      });
    } catch (e) {
      addGlobalMessage({
        message: intl.get('inventory.bulkView.failedToDeleteViewCriteria'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action public resetViewCriteria() {
    this.selectedViewCriteriaId = undefined;
    this.filters = {};
    this.groupedBy = [];
    this.sorts = {};
    this.datesRange = DateRangeTemplateEnum.Today;
  }

  @action
  public changeDatesRange(dates: DateRangeTemplateEnum | string[]) {
    this.datesRange = dates;
  }

  @action.bound
  public changeAvailabilityQuantity(quantity: number) {
    this.availabilityQuantity = quantity;
  }

  @action.bound
  public changeStopSell(isStopSell: boolean) {
    this.stopSell = isStopSell;
  }

  @action.bound
  public changeEditType(type: InventoryEditTypeEnum) {
    if (
      type !== InventoryEditTypeEnum.SetTo &&
      this.availabilityQuantity < this.minAvailabilityQuantity
    ) {
      this.availabilityQuantity = this.minAvailabilityQuantity;
    }

    this.editType = type;
  }

  @action
  public setSelectedRows(
    selectedRowKeys: Key[],
    selectedRows: InventoryBulkViewTableModel[],
  ) {
    this.selectedRowKeys = selectedRowKeys;

    this.selectedRows = selectedRows;
  }

  @action.bound
  public resetSelectedRows() {
    this.selectedRowKeys = [];

    this.selectedRows = [];
  }

  @action
  public changeEditFieldsBasedOnRowSelection() {
    if (isEmpty(this.selectedRowsInventory)) return;

    const isSameQuantity = isSameBy(
      this.selectedRowsInventory,
      (inventory) => inventory.AvailableQuantity,
    );

    const isSameStopSell = isSameBy(
      this.selectedRowsInventory,
      (inventory) => inventory.StopSell,
    );

    this.changeAvailabilityQuantity(
      isSameQuantity
        ? this.selectedRowsInventory[0]?.AvailableQuantity
        : undefined,
    );

    this.changeStopSell(
      isSameStopSell ? this.selectedRowsInventory[0]?.StopSell : undefined,
    );
  }

  @action.bound
  public async saveEditedInventoryCell(inventory) {
    try {
      await this.apiService.createOrUpdateMultipleInventories([inventory]);

      await this.getBulkInventory();

      addGlobalMessage({
        message: intl.get(
          'inventory.products.availabilityHasBeenSuccessfullyEdited',
        ),
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      addGlobalMessage({
        message: intl.get('inventory.products.failedToEditAvailability'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async saveEditedInventories() {
    try {
      const inventories = this.mapSelectedRowsToRequest();

      await this.apiService.createOrUpdateMultipleInventories(inventories);

      await this.getBulkInventory();

      this.resetSelectedRows();

      addGlobalMessage({
        message: intl.get(
          'inventory.products.availabilityHasBeenSuccessfullyEdited',
        ),
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      addGlobalMessage({
        message: intl.get('inventory.products.failedToEditAvailability'),
        type: MessageTypeEnum.error,
      });
    }
  }

  private mapSelectedRowsToRequest = () =>
    this.selectedRowsInventory.map((inventory) => ({
      ...inventory,
      AvailableQuantity:
        getAvailabilityQuantityByEditType(
          this.availabilityQuantity,
          inventory,
          this.editType,
        ) ?? inventory.AvailableQuantity,
      StopSell: this.stopSell ?? inventory.StopSell,
    }));

  public getDatesMomentRange() {
    if (isArray(this.datesRange)) {
      return this.datesRange.map((date) => moment(date)) as MomentRangeModel;
    }

    return getIsDateRangeTemplateValid(this.datesRange)
      ? getDateRangeByTemplate(this.datesRange)
      : null;
  }

  @action
  public async getBulkInventory(): Promise<void> {
    try {
      const [startDate, endDate] = this.getDatesMomentRange();

      const params = {
        $orderby: 'ProductId',
        $filter: filterBuilder()
          .eq('ProductType', this.productType || 'OPT')
          .and((x) =>
            x
              .gt('Date', startDate.startOf('day').toDate())
              .le('Date', endDate.endOf('day').toDate()),
          )
          .toString(),
      };

      const { request } = this.apiService.getInventories(params);

      const inventoryResponse = await request;

      this.inventory = inventoryResponse.data.value;
    } catch (error) {
      addGlobalMessage({
        message: intl.get('inventory.products.failedToLoadProducts'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public setProductType(productType: ProductTypeEnum) {
    this.productType = productType;
  }

  @action public setGroupedBy(groupedBy: string[]) {
    this.groupedBy = groupedBy;
  }

  @computed
  public get inventoryTableData() {
    return this.groupInventoryTableData(
      mapInventoryToViewTableData(this.inventory),
    );
  }

  private groupInventoryTableData(data: InventoryBulkViewTableModel[]) {
    const getAggregatedValues = <T>(
      leafRows: T[],
    ): Partial<InventoryBulkViewTableModel> => {
      const flattenColumns = flattenBy(this.columns, 'children');

      const result = {};

      flattenColumns.forEach((flattenColumn) => {
        const leafValues = leafRows.map((row) =>
          get(row, flattenColumn.dataIndex),
        );

        if (flattenColumn.aggregate) {
          set(
            result,
            flattenColumn.dataIndex,
            flattenColumn.aggregate(leafValues),
          );
        }
      });

      return result;
    };

    const groupUpRecursively = (
      rows: InventoryBulkViewTableModel[],
      depth = 0,
    ) => {
      if (depth === this.groupedBy.length) {
        return rows.map((row) => omit(row, this.groupedBy));
      }

      const groupByDataIndex = this.groupedBy[depth];

      return Object.entries(groupBy(rows, groupByDataIndex)).map(
        ([groupedByKey, groupedRows]) => {
          const children = groupUpRecursively(groupedRows, depth + 1);

          const leafRows = depth
            ? flattenBy(groupedRows, 'children')
            : groupedRows;

          return {
            key: uniqueId(),
            isEditable: false,
            [groupByDataIndex]: groupedByKey,
            ...getAggregatedValues(leafRows),
            children,
          };
        },
      );
    };

    return groupUpRecursively(data) as InventoryBulkViewTableModel[];
  }

  @computed
  public get datesRangeDates() {
    const [from, to] = this.getDatesMomentRange();

    const range = momentRange.range(from, to);

    return getDatesFromRange(range);
  }

  @computed
  public get allAvailableQuantity() {
    return this.inventoryTableData.flatMap((data) =>
      Object.values(data.inventoryByDate).map(
        (inventory) => inventory?.AvailableQuantity,
      ),
    );
  }

  @computed
  public get allBookedQuantity() {
    return this.inventoryTableData.flatMap((data) =>
      Object.values(data.inventoryByDate).map(
        (inventory) => inventory?.BookedCount,
      ),
    );
  }

  @computed
  public get minBookedQuantity() {
    return min(this.allBookedQuantity) ?? 0;
  }

  @computed
  public get maxBookedQuantity() {
    return max(this.allBookedQuantity) ?? 0;
  }

  @computed
  public get minAvailableQuantity() {
    return min(this.allAvailableQuantity) ?? 0;
  }

  @computed
  public get maxAvailableQuantity() {
    return max(this.allAvailableQuantity) ?? 0;
  }

  @computed
  public get isAbleEdit() {
    return !isEmpty(this.selectedRows);
  }

  @computed
  public get selectedRowsInventory() {
    return this.selectedRows.flatMap((row) =>
      Object.values(row.inventoryByDate),
    );
  }

  @computed
  public get selectedRowsCount() {
    return this.selectedRows.length;
  }

  @computed
  public get isDisplaySelectionWarning() {
    return !isEmpty(this.selectedRows);
  }

  @action
  public clearStore() {
    this.resetViewCriteria();

    this.datesRange = DateRangeTemplateEnum.Today;

    this.savedViewCriteria = null;

    this.inventory = [];

    this.selectedRowKeys = [];

    this.selectedRows = [];

    this.availabilityQuantity = 0;

    this.stopSell = false;

    this.editType = InventoryEditTypeEnum.SetTo;
  }
}
