import React, { Component } from 'react';
import { Checkbox, Space } from 'antd';
import intl from 'react-intl-universal';

import { BulkInventoryStore } from '../../../../../stores/bulkInventory.store';
import { InventoryBulkViewColumn } from '../../../../../models/inventory/inventoryBulkViewColumn.model';
import { withStore } from '../../../../../hocs';

type Props = {
  columns: InventoryBulkViewColumn[];
  bulkInventoryStore?: BulkInventoryStore;
};

@withStore(({ rootStore }) => ({
  bulkInventoryStore: rootStore.bulkInventoryStore,
}))
class InventoryBulkViewGroupBy extends Component<Props> {
  render() {
    const { bulkInventoryStore, columns } = this.props;

    return (
      <Space>
        <span>{intl.get('inventory.bulkView.groupBy')}:</span>

        <Checkbox.Group
          value={bulkInventoryStore.groupedBy}
          onChange={(value) =>
            bulkInventoryStore.setGroupedBy(value as string[])
          }
        >
          {columns.map((column) => (
            <Checkbox key={column.key} value={column.dataIndex}>
              {column.groupName}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Space>
    );
  }
}

export default InventoryBulkViewGroupBy;
