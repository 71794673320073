import React, { Component, ReactNode } from 'react';
import intl from 'react-intl-universal';
import { Button, Popover } from 'antd';
import { isFunction } from 'lodash';

import { ClockCircleOutlined } from '@ant-design/icons';
import { CardInCartModel } from '../../models/cardInCart.model';
import { isObjectsEqualBy } from '../../utils/formatter';
import CountDownTimer from './CountDownTimer';
import { getSortedActiveHoldCardsInCart } from '../../utils/cartUtils';

type Props = {
  text: string;
  cardsInCart: CardInCartModel[];
  children: ((activeCards) => ReactNode) | ReactNode;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
};

export default class ProductHoldInfoPopover extends Component<Props> {
  state = {
    isVisible: false,
    activeCards: [] as CardInCartModel[],
  };

  componentDidMount() {
    this.checkActiveHoldCardsInCart();
  }

  componentDidUpdate(prevProps) {
    if (!isObjectsEqualBy(this.props, prevProps, ['cardsInCart'])) {
      this.checkActiveHoldCardsInCart();
    }
  }

  handleVisibleChange = (isVisible) => this.setState({ isVisible });

  getContent = () => {
    const { text } = this.props;
    const { activeCards } = this.state;

    return (
      <div onClick={(e) => e.stopPropagation()}>
        <h4 className="d-f ai-c f-s-14 m-b-5">
          <ClockCircleOutlined />

          <span className="m-l-7">{intl.get('holdInfo.title')}</span>
        </h4>

        <p className="f-s-12 m-b-5">{text}</p>

        <div>
          {activeCards.map(({ id, tourName, holdInfo }) => (
            <div key={id} className="m-b-5">
              <span>{tourName} - </span>

              <CountDownTimer
                startDate={holdInfo.holdStartDate}
                endDate={holdInfo.holdEndDate}
                onTimerEnd={this.checkActiveHoldCardsInCart}
              />
            </div>
          ))}
        </div>

        <Button
          block
          type="primary"
          size="small"
          onClick={() => this.handleVisibleChange(false)}
        >
          {intl.get('buttons.gotIt')}
        </Button>
      </div>
    );
  };

  checkActiveHoldCardsInCart = () => {
    const { cardsInCart } = this.props;

    const activeCards = getSortedActiveHoldCardsInCart(cardsInCart);

    this.setState({ activeCards });
  };

  render() {
    const { children, getPopupContainer } = this.props;
    const { isVisible, activeCards } = this.state;

    if (!activeCards.length) return null;

    return (
      <Popover
        // @ts-ignore
        forceRender
        visible={isVisible}
        overlayStyle={{ width: 225 }}
        content={this.getContent()}
        getPopupContainer={getPopupContainer}
        onVisibleChange={this.handleVisibleChange}
      >
        {isFunction(children) ? children(activeCards) : children}
      </Popover>
    );
  }
}
