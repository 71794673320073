import './styles/app.scss';
import 'antd/dist/antd.min.css';
import smoothscroll from 'smoothscroll-polyfill';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { withStore } from 'hocs';
import { GlobalMessagesStore } from 'stores/globalMessages.store';
import { UserStore } from 'stores/user.store';
import { AuthStore } from 'stores/auth.store';
import { CommonStore } from 'stores/common.store';
import { SuppliersStore } from 'stores/suppliers.store';
import { registerHandlebarsHelpers } from 'utils/handlebars-helpers';
import ErrorBoundaryFallback from './components/SharedComponents/ErrorBoundaryFallback';
import GlobalMessages from './components/SharedComponents/GlobalMessages/GlobalMessages';
import GlobalSpinner from './components/SharedComponents/GlobalMessages/GlobalSpinner';
import AppRoutes from './AppRoutes';

registerHandlebarsHelpers();

if (navigator.userAgent.toLowerCase().includes('firefox')) {
  // @ts-ignore
  window.__forceSmoothScrollPolyfill__ = true;
}
smoothscroll.polyfill();

type Props = {
  userStore?: UserStore;
  authStore?: AuthStore;
  suppliersStore?: SuppliersStore;
  commonStore?: CommonStore;
  globalMessagesStore?: GlobalMessagesStore;
};

function App(props: Props) {
  const {
    userStore,
    authStore,
    commonStore,
    suppliersStore,
    globalMessagesStore,
  } = props;

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const init = async () => {
      globalMessagesStore.addGlobalSpinner();

      await userStore.loadLocales();
      await commonStore.loadSiteSettings();

      if (authStore.isLoggedIn) {
        await userStore.getUserProperties();
        await suppliersStore.checkAndLoadSuppliers();

        await Promise.all([
          commonStore.loadSettings(),
          commonStore.loadHelpInstructions(),
        ]);
      }

      setIsInitialized(true);

      globalMessagesStore.removeGlobalSpinner();
    };

    init();
  }, []);

  const onResetError = () => {
    window.location.href = '/';
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onReset={onResetError}
    >
      <div className="app">
        <GlobalSpinner />
        <GlobalMessages />
        {isInitialized && <AppRoutes />}
      </div>
    </ErrorBoundary>
  );
}

export default withStore(({ rootStore }) => ({
  userStore: rootStore.userStore,
  authStore: rootStore.authStore,
  suppliersStore: rootStore.suppliersStore,
  commonStore: rootStore.commonStore,
  globalMessagesStore: rootStore.globalMessagesStore,
}))(App);
