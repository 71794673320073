import React, { Component } from 'react';
import { Moment } from 'moment';
import classnames from 'classnames';

import { TimePicker } from 'antd';
import { getIn } from 'formik';
import { getCorrectDate } from '../../utils/formatter';

export default class TimepickerContainer extends Component<{
  children?: Component;
  field: any;
  form: any;
  label?: string;
  format?: string;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: string | Moment;
  getPopupContainer?: any;
  stringAsResult?: boolean;
  use12Hours?: boolean;
  className?: string;
}> {
  dateFormat = 'HH:mm';

  getPopupContainerDefault = () => document.body;

  handleChange = (date: Moment) => {
    const {
      form: { setFieldValue },
      field: { name },
      format,
      stringAsResult,
    } = this.props;

    if (!date) {
      setFieldValue(name, undefined);
      return;
    }

    setFieldValue(
      name,
      (stringAsResult ? date.format(format || this.dateFormat) : date) || '',
    );
  };

  render() {
    const {
      field,
      form: { touched, errors },
      label,
      placeholder,
      format,
      use12Hours,
      disabled,
      getPopupContainer,
      className,
      defaultValue,
      ...rest
    } = this.props;
    const fieldError: string = getIn(errors, field.name);
    const isTouched: boolean = getIn(touched, field.name);
    const hasError: boolean = fieldError && isTouched;

    return (
      <div
        className={classnames('field', { 'has-error': hasError }, className)}
      >
        {label && <h4 className="field-label">{label}</h4>}
        <TimePicker
          {...field}
          {...rest}
          value={getCorrectDate(field.value)}
          disabled={disabled}
          use12Hours={use12Hours || false}
          defaultValue={getCorrectDate(defaultValue ?? field.value)}
          onSelect={this.handleChange}
          onChange={this.handleChange}
          placeholder={placeholder}
          format={format || this.dateFormat}
          className="container-field"
          getPopupContainer={getPopupContainer || this.getPopupContainerDefault}
        />
        {hasError && <div className="field-error visible">{fieldError}</div>}
      </div>
    );
  }
}
