import React from 'react';
import { Modal, ModalProps } from 'antd';

type Props = {
  url: string;
} & ModalProps;

export default function SupplierRegistrationModal(props: Props) {
  const { url, ...modalProps } = props;

  return (
    <Modal
      destroyOnClose
      width={800}
      footer={null}
      wrapClassName="modal-tourschain"
      {...modalProps}
    >
      <iframe
        title="supplier registration"
        src={url}
        className="supplier-registration-iframe"
      />
    </Modal>
  );
}
