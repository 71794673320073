import {
  observable,
  action,
  computed,
  extendObservable,
  makeObservable,
} from 'mobx';
import moment, { Moment } from 'moment';
import intl from 'react-intl-universal';

import { RootStore } from './root.store';
import { CheckoutApi } from '../api/checkout.api';
import { CardInCartModel } from '../models/cardInCart.model';
import { PaymentMethodProfileModel } from '../models/paymentMethodProfile.model';
import { CheckoutPaymentModel } from '../models/checkoutPayment.model';
import { CreditCardModel } from '../models/creditCard.model';
import { TravelerFormModel } from '../models/form-models/travelerForm.model';
import {
  computeMinNumberOfTravelers,
  computeMaxNumberOfTravelers,
} from '../utils/storeUtils';
import { PaymentMethodsEnum } from '../models/enums/paymentMethods.enum';
import { mapCheckoutStoreToBodyRequest } from '../mappers/checkout.mapper';
import {
  addGlobalMessage,
  addGlobalSpinner,
  removeGlobalSpinner,
} from '../components/SharedComponents/GlobalMessages';
import { MessageTypeEnum } from '../models/global-messages/message.model';
import { OrderDetailsModel } from '../models/orderDetails.model';
import { AvailableCategoryModel } from '../models/tour-details/tourAvailableCategory.model';
import PickupPointForm from '../models/tour-details/pickupPointForm.model';
import { BookingQuestionTravelerForm } from '../models/tour-details/bookingQuestionTravelerForm.model';
import { BookingQuestionProductForm } from '../models/tour-details/bookingQuestionProductForm.mode';

export class CheckoutStore {
  private rootStore: RootStore;
  private apiService: CheckoutApi;

  @observable public paymentMethod: CheckoutPaymentModel = {
    ...new CheckoutPaymentModel(),
  };
  @observable public referenceNumber: string = undefined;
  @observable public tripId = 0;
  @observable public primaryGuestName: string = undefined;

  @observable public minNumberOfTravelers = null;
  @observable public maxNumberOfTravelers = null;
  @observable public numberOfTravelers = null;

  @observable public travelers: TravelerFormModel[] = [];
  @observable public cardsToCheckout: CardInCartModel[] = [];
  @observable
  public bookingQuestionsForTravelers: BookingQuestionTravelerForm[] = [];
  @observable public bookingQuestionsForProducts: BookingQuestionProductForm[] =
    [];
  @observable public pickupPoints: PickupPointForm[] = [];
  @observable public languageChoices: string[] = [];
  @observable public specialRequests: { cardId: string; text: string }[] = [];

  @observable public policyAndTermsFlag = false;
  @observable public isLoading = false;

  // if should validate > 0 there will turns validation function
  @observable public shouldValidate = 0;
  @observable private isValidStore: any = {};

  constructor(rootStore: RootStore, apiService: CheckoutApi) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.apiService = apiService;
  }

  // initializing
  @action public initializeNewCheckout(cards: CardInCartModel[]) {
    const cardsAndNewGuestIds = cards.map((card) => ({
      ...card,
      date: moment(card.date),
      product: {
        ...card.product,
        Guests: card.product.Guests.map((guest, index) => ({
          ...guest,
          GuestId: index + 1,
        })),
        PaymentDueBy: moment(card.product.PaymentDueBy),
      },
    }));
    this.tripId = 0;
    this.cardsToCheckout = observable(cardsAndNewGuestIds);
    this.minNumberOfTravelers =
      computeMinNumberOfTravelers(cardsAndNewGuestIds);
    this.numberOfTravelers = this.minNumberOfTravelers;
    this.maxNumberOfTravelers =
      computeMaxNumberOfTravelers(cardsAndNewGuestIds);
  }

  @action public initializeExistingCheckoutFromOrder(order: OrderDetailsModel) {
    this.tripId = order.TripId;
    this.primaryGuestName = order.PrimaryGuestName;
    this.numberOfTravelers = order.PassengerCount;
    this.referenceNumber = order.Reference ? order.Reference : '';
    this.travelers = order.Passengers.map((passenger) => {
      const travelerInfo = {
        id: passenger.GuestId,
        firstName: passenger.FirstName,
        lastName: passenger.LastName,
        phoneNumber: passenger.Phone,
        email: passenger.Email,
      };

      return new TravelerFormModel(travelerInfo, passenger.PrimaryGuest);
    });
    this.cardsToCheckout = order.Services.map((service) => {
      const product = {
        Price: service.Price,
        SellPrice: service.SellPrice,
        Name: service.Category.Name,
        Id: service.Category.Id,
        Guests: service.Guests.map((guest) => ({
          ...guest,
          travelerId: guest.GuestId,
          isPrimaryGuest: order.Passengers.find(
            (passenger) => passenger.GuestId === guest.GuestId,
          ).PrimaryGuest,
        })),
        PaymentDueBy: moment(order.PaymentDueBy),
        PaymentRequired: true,
        SupplierData: '', // api don't return this field
        ServiceTime: '', // api don't return this field
        RatePlan: service.RatePlan,
        AvailabilityLevel: service.AvailabilityLevel,
        ConnectionId: service.ConnectionId,
        ProductMappingId: 0, // api don't return this field
        CancelInfo: service.CancelInfo,
        Description: service.MainInformation
          ? service.MainInformation.Title
          : '', // added new field but there is unused
      } as AvailableCategoryModel;

      const card = {
        id: `${service.TripItemId}`,
        tourName: service.Name,
        productId: service.ProductId,
        imageUrl: service.MainInformation
          ? service.MainInformation.ThumbnailImageURI
          : '',
        date: moment(service.StartDate),
        product,
        productType: service.ProductType,
      } as CardInCartModel;

      return card;
    });
  }
  // initializing

  @action public clearStore() {
    this.paymentMethod = { ...new CheckoutPaymentModel() };
    this.referenceNumber = undefined;

    this.minNumberOfTravelers = null;
    this.maxNumberOfTravelers = null;
    this.numberOfTravelers = null;

    this.travelers = [];
    this.cardsToCheckout = [];
    this.bookingQuestionsForTravelers = [];
    this.bookingQuestionsForProducts = [];
    this.pickupPoints = [];
    this.languageChoices = [];
    this.specialRequests = [];

    this.policyAndTermsFlag = false;

    // if should validate > 0 there will turns validation function
    this.shouldValidate = 0;
    this.isValidStore = {};
  }

  @action public createOrUpdateTraveler(model: TravelerFormModel) {
    const index = this.travelers.findIndex(
      (traveler) => traveler.id === model.id,
    );
    if (index > -1) {
      this.travelers[index] = model;
    } else {
      this.travelers.push(model);
    }
  }

  @action public createOrUpdateBookingQuestionForTraveler(
    model: BookingQuestionTravelerForm,
  ) {
    const index = this.bookingQuestionsForTravelers.findIndex(
      (question) =>
        question.cardId === model.cardId && question.guestId === model.guestId,
    );

    if (index > -1) {
      this.bookingQuestionsForTravelers[index] = model;
    } else {
      this.bookingQuestionsForTravelers.push(model);
    }
  }

  @action public createOrUpdateBookingQuestionForProduct(
    model: BookingQuestionProductForm,
  ) {
    const index = this.bookingQuestionsForProducts.findIndex(
      (question) => question.cardId === model.cardId,
    );

    if (index > -1) {
      this.bookingQuestionsForProducts[index] = model;
    } else {
      this.bookingQuestionsForProducts.push(model);
    }
  }

  @action public createOrUpdatePickupPoint(model: PickupPointForm) {
    const index = this.pickupPoints.findIndex(
      (point) => point.cardId === model.cardId && point.type === model.type,
    );

    if (index > -1) {
      this.pickupPoints[index] = model;
    } else {
      this.pickupPoints.push(model);
    }
  }

  @action public createOrUpdateSpecialRequests(model: {
    cardId: string;
    text: string;
  }) {
    const index = this.specialRequests.findIndex(
      (point) => point.cardId === model.cardId,
    );

    if (index > -1) {
      this.specialRequests[index] = model;
    } else {
      this.specialRequests.push(model);
    }
  }

  @action public setNumberOfTravelers(count: number) {
    const numberOfTravelersOld = this.numberOfTravelers;
    this.numberOfTravelers = count;
    this.travelers = this.travelers.slice(0, count);

    if (numberOfTravelersOld > count) {
      this.resetSelects();
    }
  }

  @action public selectTraveler(
    cardId: string,
    guestId: number,
    isPrimaryGuest: boolean,
    travelerId: number,
  ) {
    this.cardsToCheckout
      .find((card) => card.id === cardId)
      .product.Guests.find((guest) => guest.GuestId === guestId).travelerId =
      travelerId;

    if (isPrimaryGuest) {
      this.cardsToCheckout
        .find((card) => card.id === cardId)
        .product.Guests.find(
          (guest) => guest.GuestId === guestId,
        ).isPrimaryGuest = true;
    }
  }

  @action public changePaymentMethod(paymentMethodType: PaymentMethodsEnum) {
    this.paymentMethod.paymentMethodType = paymentMethodType;
  }

  @action public changeSelectedCard(selectedCard?: PaymentMethodProfileModel) {
    this.paymentMethod.selectedCard = selectedCard;
    this.paymentMethod.newCardInfo = selectedCard
      ? undefined
      : new CreditCardModel();
    this.paymentMethod.saveInProfile = false;
  }

  @action public updateNewCreditCardInfo(
    newCardInfo: CreditCardModel,
    saveInProfile: boolean,
  ) {
    this.paymentMethod.newCardInfo = newCardInfo;
    this.paymentMethod.saveInProfile = saveInProfile;
  }

  @action public changeReferenceNumber(referenceNumber: string) {
    this.referenceNumber = referenceNumber;
  }

  @action public updatePolicyAndTermsFlag(value) {
    this.policyAndTermsFlag = value;
  }

  @action public updateValid(uniqueName: string, isValid: boolean) {
    if (this.isValidStore.hasOwnProperty(uniqueName)) {
      this.isValidStore[uniqueName] = isValid;
    } else {
      extendObservable(this.isValidStore, {
        [uniqueName]: isValid,
      });
    }
  }

  @action public unregisterFieldInValidStore(uniqueName: string) {
    if (this.isValidStore.hasOwnProperty(uniqueName)) {
      delete this.isValidStore[uniqueName];
    }
  }

  @action public validateForm() {
    this.shouldValidate += 1;
  }

  @action public resetSelects() {
    this.cardsToCheckout.forEach((item) => {
      item.product.Guests.forEach((guest) => {
        // eslint-disable-next-line no-param-reassign
        guest.travelerId = undefined;
        this.isValidStore[`selectTravelers:${item.id}:${guest.GuestId}`] =
          false;
      });
    });
  }

  @computed public get total(): number {
    return this.cardsToCheckout.reduce(
      (sum: number, currentValue: CardInCartModel) =>
        // eslint-disable-next-line no-param-reassign
        (sum += currentValue.product.Price),
      0,
    );
  }

  @computed public get isPaymentRequired(): boolean {
    return this.cardsToCheckout.some(
      (card: CardInCartModel) => card.product.PaymentRequired,
    );
  }

  @computed public get isAllPassengerNamesRequired(): boolean {
    return this.cardsToCheckout.some(
      (card: CardInCartModel) => card.allPassengerNamesRequired,
    );
  }

  @computed public get dueDate(): Moment {
    return moment(
      Math.min.apply(
        null,
        this.cardsToCheckout.map((card: CardInCartModel) =>
          card.product.PaymentDueBy.valueOf(),
        ),
      ),
    );
  }

  public isCheckoutValid(): boolean {
    const keys = Object.keys(this.isValidStore);

    return keys.every((key) => this.isValidStore[key]);
  }

  public isItemsInCheckoutValid(includedString: string): boolean {
    return Object.keys(this.isValidStore)
      .filter((key) => key.includes(includedString))
      .every((key) => this.isValidStore[key]);
  }

  // submit
  @action public async submitCheckout(isPrefilled: boolean) {
    try {
      addGlobalSpinner();
      const requestBody = mapCheckoutStoreToBodyRequest(this, isPrefilled);

      const response = await this.apiService.saveOrder(requestBody);
      removeGlobalSpinner();

      return Promise.resolve(response.data);
    } catch (err) {
      removeGlobalSpinner();
      addGlobalMessage({
        message: intl.get('checkout.errors.incorrectData'),
        type: MessageTypeEnum.error,
      });
      return Promise.reject(err);
    }
  }
  // submit
}
