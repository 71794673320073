export enum SliderImagePurposeEnum {
  Seatmap = 'SEATMAP',
  None = '',
}

export interface SliderImageModel {
  ImagePath: string;
  Caption: string;
  Purpose: SliderImagePurposeEnum;
}
