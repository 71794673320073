import React from 'react';
import { Col, InputNumber, Row } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import { FilterDropdownProps } from 'antd/es/table/interface';
import intl from 'react-intl-universal';

import TableOrdersFilterActions from './TableOrdersFilterActions';

type Props = {
  inputProps?: InputNumberProps;
} & FilterDropdownProps;

function TableOrdersTripNumberFilter(props: Props) {
  const { inputProps, ...dropdownProps } = props;
  const { selectedKeys, setSelectedKeys } = dropdownProps;

  const [tripId, tripItemId] = selectedKeys;

  const onTripIdChange = (value: string | number) => {
    setSelectedKeys([value, tripItemId]);
  };

  const onTripItemIdChange = (value: string | number) => {
    setSelectedKeys([tripId, value]);
  };

  return (
    <div className="ant-table-filter-dropdown" style={{ width: 280 }}>
      <div className="ant-table-filter-dropdown-search">
        <Row gutter={[15, 0]}>
          <Col span={14}>
            <InputNumber
              {...inputProps}
              value={tripId as number}
              min={1}
              style={{ width: '100%' }}
              placeholder={intl.get('orders.table.tripId')}
              onChange={onTripIdChange}
            />
          </Col>

          <Col span={10}>
            <InputNumber
              {...inputProps}
              disabled={!tripId}
              value={tripItemId as number}
              min={1}
              style={{ width: '100%' }}
              placeholder={intl.get('orders.table.tripItemId')}
              onChange={onTripItemIdChange}
            />
          </Col>
        </Row>
      </div>

      <div className="ant-table-filter-dropdown-btns">
        <TableOrdersFilterActions {...dropdownProps} />
      </div>
    </div>
  );
}

export default TableOrdersTripNumberFilter;
