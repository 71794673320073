import moment from 'moment';

import { InventoryModel } from './inventory.model';
import { createInventoryDateColumnKey } from '../../utils/bulkInventoryUtils';
import { InventoryComponentModel } from './inventoryComponent.model';

export class InventoryBulkViewByDateModel {
  constructor(inventory: InventoryModel | InventoryComponentModel) {
    this[createInventoryDateColumnKey(moment(inventory.Date))] = inventory;
  }
}
