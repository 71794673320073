import { observable, action, makeObservable } from 'mobx';
import moment from 'moment';
import intl from 'react-intl-universal';

import { RootStore } from './root.store';
import { TourApi } from '../api/tour.api';
import { TourDetailsModel } from '../models/tour-details/tourDetails.model';

import { addGlobalMessage } from '../components/SharedComponents/GlobalMessages';
import { MessageTypeEnum } from '../models/global-messages/message.model';
import { AvailableCategoryModel } from '../models/tour-details/tourAvailableCategory.model';
import { PolicyModel } from '../models/policy.model';
import CartCardHolInfo from '../models/cartCardHolInfo.model';
import { tourToPerformHoldMapper } from '../mappers/availabilityToPerformHold.mapper';
import { CardInCartModel } from '../models/cardInCart.model';

export class TourStore {
  private rootStore: RootStore;
  private apiService: TourApi;

  @observable public tourInfo: TourDetailsModel = undefined;
  @observable public departureDate: any = undefined;
  @observable public cancellationPolicies: PolicyModel[] = [];
  @observable public isAvailabilitiesLoading = false;

  @observable public unavailableDates: string[] = [];

  constructor(rootStore: RootStore, apiService: TourApi) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.apiService = apiService;
  }

  @action
  public cleanStore() {
    this.tourInfo = undefined;
    this.cancellationPolicies = [];
  }

  @action
  public async getTourDetails(tourId: string, languageCode?: string) {
    try {
      const { data: response }: any = await this.apiService.getTourDetails(
        tourId,
        languageCode || this.rootStore.userStore.language,
      );

      this.tourInfo = response;
      return Promise.resolve();
    } catch (error) {
      addGlobalMessage({
        message: `${intl.get('tourDetails.loadError')} #${tourId}`,
        type: MessageTypeEnum.error,
      });
      return Promise.reject(error);
    }
  }

  @action
  public async getTourAvailability(
    date: string,
    adult?: number,
    minorAges?: number[],
  ) {
    const guests: any = {};
    if (adult > 0) {
      guests.Adults = adult;
    }

    guests.MinorAges = minorAges;

    const formData = {
      Guests: guests,
      ProductId: this.tourInfo.ProductId,
      StartDate: date,
      ServiceType: 'SHOW, ATTAD',
    };

    try {
      this.isAvailabilitiesLoading = true;
      const { data: response }: any = await this.apiService.getTourAvailability(
        formData,
      );

      if (response && response[0]) {
        const res = response[0];

        this.tourInfo.AvailabilityCategories = res.AvailabilityCategories;
        this.tourInfo.StartDate = res.StartDate
          ? moment.utc(res.StartDate)
          : undefined;
        this.tourInfo.EndDate = res.EndDate
          ? moment.utc(res.EndDate)
          : undefined;
        this.tourInfo.PickupPoints = res.PickupPoints || [];
        this.tourInfo.Holdable = res.Holdable;
        this.tourInfo.DropoffPoints = res.DropoffPoints || [];
        this.tourInfo.PassengerWeightRequired = res.PassengerWeightRequired;
        this.tourInfo.AllPassengerNamesRequired = res.AllPassengerNamesRequired;
        this.tourInfo.BookingQuestions = res.BookingQuestions;
      } else {
        this.tourInfo.AvailabilityCategories = null;
      }

      this.isAvailabilitiesLoading = false;
      return Promise.resolve(response);
    } catch (error) {
      this.isAvailabilitiesLoading = false;
      return Promise.reject(error);
    }
  }

  @action
  public async getProductCancellation(productId: string) {
    try {
      const { data: cancellationPolicies } =
        await this.apiService.getCancellationPolicy(productId);

      this.cancellationPolicies = cancellationPolicies;
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  @action
  public async getServiceInventory(
    productId: string,
    startDate: any,
    endDate: any,
  ) {
    try {
      const { data } = await this.apiService.getServiceInventory(
        productId,
        startDate,
        endDate,
      );
      const dates = data.map(({ Date }) => Date);

      this.unavailableDates = [...dates];
      return Promise.resolve(dates);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async addProductToHold(availability: AvailableCategoryModel) {
    try {
      const formData = tourToPerformHoldMapper(this.tourInfo, availability);

      const { data: productHoldInfo } =
        await this.apiService.performHoldProduct(formData);

      const holdInfo = new CartCardHolInfo(productHoldInfo);

      return Promise.resolve(holdInfo);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async cancelProductHold(cardInCart: CardInCartModel) {
    try {
      const formData = {
        TimedHoldId: cardInCart.holdInfo.timeHoldId,
        SupplierConnectionId: cardInCart.product.ConnectionId,
      };

      await this.apiService.performCancelAvailabilityHold(formData);

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  @action
  public setDepartureDate(date: any) {
    this.departureDate = date;
  }
}
