import React, { Component, ReactNode } from 'react';

export const defaultImage = `${process.env.PUBLIC_URL}/default-thumbnail.png`;

export default class ImageComponent extends Component<{
  src: string;
  onClick?: any;
  isBackground?: boolean;
  alt?: string;
  className?: string;
  children?: ReactNode;
}> {
  image = null;

  state = {
    imageSrc: defaultImage,
  };

  componentDidMount() {
    this.createAnImage();
  }

  componentDidUpdate(prevProps) {
    const { src } = this.props;

    // eslint-disable-next-line eqeqeq
    if (src != prevProps.src) {
      this.createAnImage();
    }
  }

  componentWillUnmount() {
    this.image.onload = undefined;
  }

  createAnImage = () => {
    const { src } = this.props;
    const img = new Image();

    img.src = src;
    img.onload = () => {
      this.setState({
        imageSrc: src,
      });
    };

    this.image = img;
  };

  render() {
    const { imageSrc } = this.state;
    const { alt, className, isBackground, onClick } = this.props;

    return isBackground ? (
      <div
        className={className}
        style={{
          backgroundImage: `url(${imageSrc})`,
        }}
        onClick={onClick}
      >
        {this.props.children}
      </div>
    ) : (
      <img src={imageSrc} alt={alt} className={className} />
    );
  }
}
