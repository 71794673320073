import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Modal, Button } from 'antd';

import { UserStore } from '../../../../stores/user.store';
import { AuthStore } from '../../../../stores/auth.store';
import { addGlobalMessage } from '../../../SharedComponents/GlobalMessages';
import { MessageTypeEnum } from '../../../../models/global-messages/message.model';
import { secretCode } from '../../../../utils/constants';
import { withStore } from '../../../../hocs';

@withStore(({ rootStore }) => ({
  userStore: rootStore.userStore,
  authStore: rootStore.authStore,
}))
export default class MyProfile extends Component<{
  userStore?: UserStore;
  authStore?: AuthStore;
}> {
  state = {
    isPopupOpened: false,
  };

  handlePopupOpen = (isPopupOpened) => this.setState({ isPopupOpened });

  handleChangePasswordClick = () => {
    const {
      authStore,
      userStore: { userProperties },
    } = this.props;
    const data = {
      UserName: userProperties.UserName,
      SecretCode: secretCode,
      UrlToResetPage: `${document.location.origin}/reset-password`,
    };

    authStore
      .forgotPassword(data)
      .then(() => this.handlePopupOpen(true))
      .catch(() => {
        addGlobalMessage({
          message: intl.get('globalErrors.smthWentWrong'),
          type: MessageTypeEnum.error,
        });
      });
  };

  renderPopup = () => (
    <div className="change-password-popup">
      <div className="header">
        <span>{intl.get('profile.changePasswordPopup.header')}</span>
      </div>
      <div className="popup-body">
        <p>{intl.get('profile.changePasswordPopup.body.0')}</p>
        <p>{intl.get('profile.changePasswordPopup.body.1')}</p>
      </div>
      <div className="btn-container">
        <Button onClick={() => this.handlePopupOpen(false)}>
          {intl.get('buttons.gotIt')}
        </Button>
      </div>
    </div>
  );

  render() {
    const {
      userStore: { userProperties },
    } = this.props;
    const { isPopupOpened } = this.state;

    return (
      <div className="my-profile-section">
        <h2 className="my-profile-head">
          <i className="icon tc-profile" />
          {intl.get('profile.myProfile')}
        </h2>
        {userProperties && (
          <div className="my-profile-body">
            <div className="profile-info-container">
              <div className="profile-info-block">
                <i className="icon tc-user" />
                <div>
                  <div className="label">{intl.get('labels.username')}</div>
                  <div className="content">{userProperties.UserName}</div>
                </div>
              </div>
              <div className="profile-info-block">
                <i className="icon tc-email" />
                <div>
                  <div className="label">{intl.get('labels.email')}</div>
                  <div className="content">{userProperties.Email}</div>
                </div>
              </div>
            </div>

            <div className="change-password-container">
              <Button
                className="change-password-button"
                type="primary"
                htmlType="button"
                size="large"
                onClick={this.handleChangePasswordClick}
              >
                {intl.get('buttons.changePassword')}
              </Button>
            </div>
          </div>
        )}
        <Modal
          destroyOnClose
          visible={isPopupOpened}
          footer={null}
          closable={false}
          className="my-profile-change-password-popup"
        >
          {this.renderPopup()}
        </Modal>
      </div>
    );
  }
}
