/* eslint-disable react/no-danger */
import React, { useMemo } from 'react';
import { Divider, Space, Typography } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import intl from 'react-intl-universal';
import cx from 'classnames';

import { withStore } from '../../../../../../hocs';
import { InventoryStore } from '../../../../../../stores/inventory.store';
import { CommonStore } from '../../../../../../stores/common.store';

type Props = {
  className?: string;
  commonStore?: CommonStore;
  inventoryStore?: InventoryStore;
};

function ProductDetailsHelp(props: Props) {
  const { className, commonStore, inventoryStore } = props;
  const { activeHelpInstructionKey } = inventoryStore;
  const { helpInstructions } = commonStore;

  const activeHelpInstruction = useMemo(
    () =>
      helpInstructions.find((instruction) =>
        instruction.Keys.includes(activeHelpInstructionKey),
      ),
    [helpInstructions, activeHelpInstructionKey],
  );

  return (
    <div className={cx('help-instructions', className)}>
      <Space>
        <QuestionCircleTwoTone className="help-instructions__icon" />

        <Typography.Title level={3} className="m-0">
          {intl.get('help')}
        </Typography.Title>
      </Space>

      <Divider orientationMargin={5} />

      {activeHelpInstruction?.Title && (
        <Typography.Title level={4}>
          {activeHelpInstruction.Title}
        </Typography.Title>
      )}

      {activeHelpInstruction?.Content && (
        <div
          className="help-instructions__text"
          dangerouslySetInnerHTML={{
            __html: activeHelpInstruction.Content,
          }}
        />
      )}
    </div>
  );
}

export default withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
  commonStore: rootStore.commonStore,
}))(ProductDetailsHelp);
