import React, { Component } from 'react';
import { reaction } from 'mobx';
import { Table, Row, Col } from 'antd';

import { BulkInventoryStore } from '../../../../../stores/bulkInventory.store';
import { InventoryBulkViewTableModel } from '../../../../../models/inventory/inventoryBulkViewTable.model';
import InventoryBulkViewRangePicker from './InventoryBulkViewRangePicker';
import InventoryBulkViewEdit from './InventoryBulkViewEdit';
import InventoryBulkViewGroupBy from './InventoryBulkViewGroupBy';
import InventoryBulkViewCriteria from './InventoryBulkViewCriteria';
import { getColumns } from '../../../../../utils/bulkInventoryUtils';
import { withStore } from '../../../../../hocs';

type Props = {
  bulkInventoryStore?: BulkInventoryStore;
};

@withStore(({ rootStore }) => ({
  bulkInventoryStore: rootStore.bulkInventoryStore,
}))
export default class InventoryBulkView extends Component<Props> {
  containerRef: React.RefObject<HTMLDivElement> = React.createRef();

  inventoryDisposer = null;

  state = {
    isLoading: false,
  };

  componentDidMount() {
    const { bulkInventoryStore } = this.props;

    this.handleLoadInventory();

    this.inventoryDisposer = reaction(
      () => bulkInventoryStore.datesRange,
      () => this.handleLoadInventory(),
    );
  }

  componentWillUnmount() {
    const { bulkInventoryStore } = this.props;

    this.inventoryDisposer();

    bulkInventoryStore.clearStore();
  }

  get columns() {
    return getColumns(this.props.bulkInventoryStore);
  }

  get visibleColumns() {
    const {
      bulkInventoryStore: { groupedBy },
    } = this.props;

    const groupedColumns = groupedBy.map((groupDataIndex) =>
      this.columns.find((column) => column.dataIndex === groupDataIndex),
    );

    const nonGroupedColumns = this.columns.filter(
      (column) => !groupedBy.includes(column.dataIndex as string),
    );

    return [...groupedColumns, ...nonGroupedColumns];
  }

  get groupableColumns() {
    return this.columns.filter((column) => column.groupable);
  }

  handleLoadInventory = async () => {
    try {
      this.setState({ isLoading: true });

      await this.props.bulkInventoryStore.getBulkInventory();
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleRowSelect = (
    selectedRowKeys: React.Key[],
    selectedRows: InventoryBulkViewTableModel[],
  ) => {
    const { bulkInventoryStore } = this.props;

    bulkInventoryStore.setSelectedRows(selectedRowKeys, selectedRows);
  };

  renderSelectCell = (
    _$1,
    record: InventoryBulkViewTableModel,
    _$2,
    originNode,
  ) => (record.isEditable ? originNode : null);

  render() {
    const { bulkInventoryStore } = this.props;

    const { isLoading } = this.state;

    return (
      <div
        ref={this.containerRef as React.LegacyRef<HTMLDivElement>}
        className="inventory-bulk-view"
      >
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Row gutter={0} justify="space-between">
              <Col span={6}>
                <InventoryBulkViewRangePicker
                  getPopupContainer={() => this.containerRef.current}
                />
              </Col>

              <InventoryBulkViewCriteria />
            </Row>
          </Col>

          <Col span={24}>
            <InventoryBulkViewEdit />
          </Col>

          <Col span={24}>
            <InventoryBulkViewGroupBy columns={this.groupableColumns} />
          </Col>

          <Col span={24}>
            <Table
              bordered
              className="inventory-bulk-table"
              loading={isLoading}
              getPopupContainer={() => this.containerRef.current}
              columns={this.visibleColumns}
              rowKey={(record) => record.key}
              scroll={{ x: true }}
              dataSource={bulkInventoryStore.inventoryTableData}
              rowClassName={() => 'editable-row'}
              rowSelection={{
                hideSelectAll: true,
                renderCell: this.renderSelectCell,
                selectedRowKeys: bulkInventoryStore.selectedRowKeys,
                onChange: this.handleRowSelect,
              }}
              pagination={{ showSizeChanger: true }}
              onChange={(...args) =>
                bulkInventoryStore.changeTableSortsAndFilters(...args)
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
}
