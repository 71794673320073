import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Button, Modal, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { withStore } from 'hocs';
import { useQuery } from 'hooks';
import { OrdersStore } from 'stores/orders.store';
import { CheckoutStore } from 'stores/checkout.store';
import { UserFiltersStore } from 'stores/userFilters.store';
import { OrderTabsEnum } from 'models/enums/orderTabs.enum';
import { addToQueryString, omitFromQueryString } from 'utils/urlUtils';
import Checkout from '../Checkout/Checkout';
import OrderDetails from './components/OrderDetails';
import TableOrders from './components/TableOrders';

type Props = {
  ordersStore?: OrdersStore;
  userFiltersStore?: UserFiltersStore;
  checkoutStore?: CheckoutStore;
};

function Orders(props: Props) {
  const { ordersStore, userFiltersStore, checkoutStore } = props;

  const orderTab = ordersStore.currentTab;

  const location = useLocation();
  const navigate = useNavigate();
  const { orderId, isCheckoutOpened } = useQuery({
    parseBooleans: true,
    parseNumbers: true,
  });

  const DEFAULT_TAB = OrderTabsEnum.Upcoming;

  const TABS = [OrderTabsEnum.Upcoming, OrderTabsEnum.Past, OrderTabsEnum.All];

  const TAB_ICONS = {
    [OrderTabsEnum.Upcoming]: 'tc-calendar',
    [OrderTabsEnum.Past]: 'tc-clock-past',
    [OrderTabsEnum.All]: '',
  };
  const TAB_LABELS = {
    [OrderTabsEnum.Upcoming]: intl.get('orders.upcoming'),
    [OrderTabsEnum.Past]: intl.get('orders.past'),
    [OrderTabsEnum.All]: intl.get('orders.all'),
  };

  useEffect(() => {
    ordersStore.initialize();

    return () => {
      ordersStore.clearStore();
      userFiltersStore.clearStore();
    };
  }, []);

  useEffect(() => {
    const isTabValid = validateOrderTab(orderTab as OrderTabsEnum);

    if (!isTabValid) {
      setDefaultTab();
    }
  }, [orderTab]);

  const validateOrderTab = (tab: OrderTabsEnum) =>
    Object.values(OrderTabsEnum).includes(tab);

  const setDefaultTab = () => {
    navigate(
      {
        search: addToQueryString(location.search, { orderTab: DEFAULT_TAB }),
      },
      { replace: true },
    );
  };

  const onSwitchTab = (tab: OrderTabsEnum) => {
    navigate({
      search: addToQueryString(location.search, { orderTab: tab, page: 1 }),
    });
  };

  const onPopupClose = () => {
    navigate(
      {
        search: omitFromQueryString(location.search, [
          'orderId',
          'isCheckoutOpened',
        ]),
      },
      { replace: true },
    );
  };

  const onOrderClick = (id: number) => {
    navigate({ search: addToQueryString(location.search, { orderId: id }) });
  };

  const onChangeToCheckout = () => {
    navigate({
      search: addToQueryString(location.search, { isCheckoutOpened: true }),
    });
  };

  const onDownloadReportClick = async () => {
    await ordersStore.downloadReport();
  };

  const onAfterSubmit = async () => {
    ordersStore.clearOrderDetails();

    await ordersStore.loadOrders();
  };

  return (
    <div className="orders-page">
      <div className="orders-head">
        <div className="head-tabs">
          {TABS.map((tab) => (
            <div
              onClick={() => onSwitchTab(tab)}
              className={classnames('tab-title', { active: orderTab === tab })}
            >
              <i className={TAB_ICONS[tab]} /> {TAB_LABELS[tab]}
            </div>
          ))}
        </div>
        <div className="orders-actions">
          <div className="search-field-wrapper" />
          <Button
            loading={ordersStore.isOrderInProcessing}
            className="header-tab-button m-r-10"
            onClick={onDownloadReportClick}
            disabled={ordersStore.isLoading}
          >
            <span>
              <i className="tc-download" /> {intl.get('buttons.downloadReport')}
            </span>
          </Button>
        </div>
      </div>
      <div className="orders-body">
        {ordersStore.isInitialized ? (
          <TableOrders onOrderClick={onOrderClick} />
        ) : (
          <Spin size="large" className="orders-loader" />
        )}
      </div>
      <Modal
        destroyOnClose
        visible={Boolean(orderId)}
        maskClosable={false}
        footer={null}
        closable={false}
        onCancel={onPopupClose}
        wrapClassName="popup full-modal"
      >
        {checkoutStore.cardsToCheckout.length === 0 && isCheckoutOpened ? (
          <Checkout
            isPrefilled
            closePopup={onPopupClose}
            afterSubmit={onAfterSubmit}
          />
        ) : (
          <OrderDetails
            tripId={orderId as unknown as number}
            closePopup={onPopupClose}
            changeToCheckout={onChangeToCheckout}
          />
        )}
      </Modal>
    </div>
  );
}

export default withStore(({ rootStore }) => ({
  ordersStore: rootStore.ordersStore,
  userFiltersStore: rootStore.userFiltersStore,
  checkoutStore: rootStore.checkoutStore,
}))(Orders);
