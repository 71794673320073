/* eslint-disable no-param-reassign */
import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError,
} from 'axios';
import { rootStore } from '../provider';

export class BaseApi {
  public axios: AxiosInstance;

  constructor() {
    this.axios = axios.create();

    this.axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        config.baseURL = config.baseURL || rootStore.commonStore.apiUrl;

        if (!config.headers.Authorization) {
          config.headers.Authorization =
            rootStore.authStore.token || rootStore.commonStore.generalToken;
        }

        return config;
      },
      (error: AxiosError) => Promise.reject(error),
    );

    this.axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        const { response } = error;

        if (!response) {
          // rootStore.authStore.logOut();
          return Promise.reject(error);
        }

        switch (response.status) {
          case 401: {
            rootStore.authStore.logOut();
          }
        }

        return Promise.reject(error);
      },
    );
  }
}
