import React from 'react';
import intl from 'react-intl-universal';
import { Upload } from 'antd';

import { UploadFile } from 'antd/lib/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import ImageCropper from '../../../../../SharedComponents/ImageCropper';
import { PreviewFile } from './ProductUpload';

type Props = {
  fileList: PreviewFile[];
  onRemove: (file: UploadFile) => void;
  onPreview: (file: UploadFile) => void;
  beforeCrop: (file: File, fileList: File[]) => boolean;
  onApplyCrop: (file: File, fileList: File[]) => boolean;
};

function AdditionalImageUpload(props: Props) {
  const { fileList, beforeCrop, onRemove, onPreview, onApplyCrop } = props;

  return (
    <div>
      <h3>
        {intl.get('SUPPLIER.PRODUCT_DETAILS.IMAGES_SECTION.ADDITIONAL_IMAGES')}
      </h3>

      <div>
        <ImageCropper showGrid rotationSlider beforeCrop={beforeCrop}>
          <Upload
            listType="picture-card"
            fileList={fileList}
            customRequest={() => undefined}
            onRemove={onRemove}
            onPreview={onPreview}
            beforeUpload={onApplyCrop}
          >
            <UploadOutlined />
          </Upload>
        </ImageCropper>
      </div>
    </div>
  );
}

export default React.memo(AdditionalImageUpload);
