export const EMAIL_TEMPLATE_PREVIEW_ORDER_ITEM = {
  TripId: 447206,
  TripItemId: 1,
  Reference: 'CHDUA1AY5',
  ProductType: 'OPT',
  ProductId: 'BEGSRNPE',
  Name: 'Grand Canyon National Park Bus Tour',
  StartDate: '2023-06-27T00:00:00',
  Price: 244.0,
  BundledPrice: 244.0,
  Cost: 0.0,
  CreatedOn: '2023-02-03T00:00:00',
  PassengerCount: 4,
  CategoryId: 'GS1',
  CategoryName: 'Tour - 6:00 AM',
  RatePlanId: null,
  RatePlanName: null,
  Confirmation: 'CHDUA1AY5',
  PrimaryGuestName: 'Doe, John',
  Inactive: false,
  PrimaryEmail: 'johndoe@email.com',
  PrimaryPhone: '(123) 456-7890',
  AdditionalGuestDetails: [
    {
      Email: 'janedoe@email.com',
      Name: 'Doe, Jane',
      Phone: '(123) 456-7890',
    },
    {
      Email: '',
      Name: '',
      Phone: '',
    },
    {
      Email: '',
      Name: '',
      Phone: '',
    },
  ],
  Nights: 0,
  Rooms: 0,
  LinkedCanceledItem: null,
  CouponIds: [],
  AgencyId: '8572040812',
  AgencyName: 'R-GO City',
  LanguageCode: 'ENG',
  ServiceTime: '0700',
  ServiceDateTime: '2023-06-27T07:00:00',
  PickupPoint: {
    Address:
      'Excalibur Hotel & Casino, South Las Vegas Boulevard, Las Vegas, NV, USA',
    LocationType: 'WAY',
    LocationId: 'LASEXCLB',
    Name: 'Excalibur Rear Rotunda',
    Latitude: 36.098026,
    Longitude: -115.165215,
    StartTime: '0530',
    PickupDateTime: '2023-06-27T05:30:00',
    EndTime: null,
    Directions:
      'Meet outside&nbsp;the Rear Rotunda Tour Pickup Area (Back Entrance) at the exit door behind the Starbucks.<br /><br /><a href="https://nationalparkexpress.com/excalibur-pickup/">https://nationalparkexpress.com/excalibur-pickup/</a><br />',
    ThumbnailImage:
      'https://cdn.travelusaexpress.com/images/WAY%2FLASEXCLB%2Ft.jpg',
    ImageCaption: 'Rear Rotunda Tour Pickup Area',
  },
  DropoffPoint: {
    Address:
      'Excalibur Hotel & Casino, South Las Vegas Boulevard, Las Vegas, NV, USA',
    LocationType: 'WAY',
    LocationId: 'LASEXCLB',
    Name: 'Excalibur Rear Rotunda',
    Directions:
      'Meet outside&nbsp;the Rear Rotunda Tour Pickup Area (Back Entrance) at the exit door behind the Starbucks.<br /><br /><a href="https://nationalparkexpress.com/excalibur-pickup/">https://nationalparkexpress.com/excalibur-pickup/</a><br />',
    ThumbnailImage:
      'https://cdn.travelusaexpress.com/images/WAY%2FLASEXCLB%2Ft.jpg',
    ImageCaption: 'Excalibur Las Vegas',
    LanguageId: 'ENG',
    Latitude: 36.098026,
    Longitude: -115.165215,
  },
};
