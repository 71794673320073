export enum AvailabilityLevelEnum {
  Available = 'Available',
  Requestable = 'Requestable',
  NotAvailable = 'NotAvailable',
  Cancelled = 'Cancelled',
  OnRequest = 'OnRequest',
  PartlyConfirmed = 'PartlyConfirmed',
  Confirmed = 'Confirmed',
  RequestDenied = 'RequestDenied',
  Administrative = 'Administrative',
  NotApplicable = 'NotApplicable',
}
