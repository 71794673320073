import React from 'react';
import intl from 'react-intl-universal';

import { Button } from 'antd';

export default function AfterSignup(props: any) {
  const { onButtonClick } = props;

  return (
    <div className="my-profile-change-password-popup">
      <div className="change-password-popup">
        <div className="header">
          <span>{intl.get('emailSentTitle')}</span>
        </div>
        <div className="popup-body">
          <p>{intl.get('authPages.confirmPasswordEmailSent.0')}</p>
          <p>{intl.get('authPages.confirmPasswordEmailSent.1')}</p>
        </div>
        <div className="btn-container">
          <Button onClick={() => onButtonClick()}>
            {intl.get('buttons.gotIt')}
          </Button>
        </div>
      </div>
    </div>
  );
}
