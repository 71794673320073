import { RateModel } from './rate.model';

export class CreateRateModel extends RateModel {
  Product?: {
    Id: string;
    Name: string;
  };
  Category?: {
    Id: string;
    Name: string;
  };
}
