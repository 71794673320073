import React, { useEffect } from 'react';
import { Field, Form, useFormikContext } from 'formik';
import intl from 'react-intl-universal';
import { Button, Col, RadioChangeEvent, Row, Space, Tooltip } from 'antd';
import { isEmpty } from 'lodash';

import {
  CheckboxContainer,
  NumberStepperContainer,
  RadioGroupContainer,
  TextareaContainer,
} from 'components/FormContainers';
import Accessor from 'components/SharedComponents/Accessor';
import { ProductAvailabilityCreateFormModel } from 'models/inventory/productAvailabilityCreateForm.model';
import { InventoryEditTypeEnum } from 'models/enums/inventoryEditType.enum';
import { EventModel } from 'models/inventory/event.model';
import { USER_ROLE_GROUPS } from 'utils/constants';

type Props = {
  selectedDaysEvents: { day: string; event: EventModel }[];
  getNumberOfEntriesToCreate?: (
    values: ProductAvailabilityCreateFormModel,
  ) => number;
};

function ProductAvailabilityCreateForm(props: Props) {
  const { selectedDaysEvents, getNumberOfEntriesToCreate } = props;

  const { values, isValid, isSubmitting, submitCount, setFieldValue } =
    useFormikContext<ProductAvailabilityCreateFormModel>();

  const MIN_AVAILABILITY_AMOUNT = 1;

  const isAvailabilitySetOnly =
    isEmpty(selectedDaysEvents) ||
    (values.isCreateAvailability
      ? !selectedDaysEvents.every((dayEvent) => dayEvent.event)
      : !selectedDaysEvents.some((dayEvent) => dayEvent.event));

  useEffect(() => {
    if (isAvailabilitySetOnly) {
      setFieldValue('availabilityEditType', InventoryEditTypeEnum.SetTo);
    }
  }, [isAvailabilitySetOnly]);

  const minAvailabilityQuantity =
    values.availabilityEditType === InventoryEditTypeEnum.SetTo ? null : 1;

  const isSubmitDisabled =
    isEmpty(selectedDaysEvents) ||
    (!values.isCreateAvailability &&
      selectedDaysEvents.every(({ event }) => !event));

  const getSubmitTooltipTitle = () => {
    if (isSubmitDisabled) {
      return intl.get('productPage.availabilityNoChanges');
    }

    if (submitCount > 0 && !isValid) {
      return intl.get('productPage.createAvailabilitySubmitDisclaimer');
    }

    return '';
  };

  const onAvailabilityEditTypeChange = (event: RadioChangeEvent) => {
    if (
      event.target.value !== InventoryEditTypeEnum.SetTo &&
      values.availableQuantity < MIN_AVAILABILITY_AMOUNT
    ) {
      setFieldValue('availableQuantity', MIN_AVAILABILITY_AMOUNT);
    }
  };

  return (
    <Form>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <strong>{intl.get('productPage.updateSelectedOptions')}</strong>
            </Col>

            <Col>
              <Tooltip title={getSubmitTooltipTitle()}>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={isSubmitting}
                  disabled={isSubmitDisabled}
                >
                  {intl.get('buttons.apply')}
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={15}>
            <Col span={6}>
              <Field
                name="notes"
                rows={4}
                label={intl.get('productPage.notes')}
                component={TextareaContainer}
              />
            </Col>

            <Col span={13}>
              <Row gutter={15}>
                <Col span={9}>
                  <Field
                    name="availableQuantity"
                    min={minAvailabilityQuantity}
                    label={intl.get('productPage.availabilityQuantity')}
                    component={NumberStepperContainer}
                  />
                </Col>

                <Col span={15}>
                  <Field
                    name="availabilityEditType"
                    label="&nbsp;"
                    options={[
                      {
                        label: [
                          intl.get('inventory.bulkView.increaseBy'),
                          values.availableQuantity,
                        ].join(' '),
                        value: InventoryEditTypeEnum.Increase,
                        disabled: isAvailabilitySetOnly,
                      },
                      {
                        label: [
                          intl.get('inventory.bulkView.decreaseBy'),
                          values.availableQuantity,
                        ].join(' '),
                        value: InventoryEditTypeEnum.Decrease,
                        disabled: isAvailabilitySetOnly,
                      },
                      {
                        label: [
                          intl.get('inventory.bulkView.setTo'),
                          values.availableQuantity,
                        ].join(' '),
                        value: InventoryEditTypeEnum.SetTo,
                      },
                    ]}
                    component={RadioGroupContainer}
                    onChange={onAvailabilityEditTypeChange}
                  />
                </Col>

                <Col span={24}>
                  <Space>
                    <Field
                      name="isCreateAvailability"
                      label={intl.get('productPage.createAvailability', {
                        createEntriesNumber: getNumberOfEntriesToCreate(values),
                      })}
                      component={CheckboxContainer}
                    />

                    <Field
                      name="isStopSell"
                      label={intl.get('inventory.bulkView.stopSell')}
                      component={CheckboxContainer}
                    />
                  </Space>
                </Col>
              </Row>
            </Col>

            <Col span={5}>
              <Row>
                <Accessor roles={USER_ROLE_GROUPS.STAFF}>
                  <Col span={24}>
                    <Field
                      name="hold"
                      label={intl.get('productPage.hold')}
                      component={NumberStepperContainer}
                    />
                  </Col>
                </Accessor>

                <Col span={24}>
                  <Field
                    name="bookingCutoffHours"
                    label={intl.get('productPage.cutoffHours')}
                    component={NumberStepperContainer}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

export default ProductAvailabilityCreateForm;
