import { AxiosResponse } from 'axios';

import { BaseApi } from './base.api';
import { UserFilterModel } from '../models/user-filters/userFilter.model';

export class UserFiltersApi extends BaseApi {
  public getOrdersFilters(): Promise<AxiosResponse<UserFilterModel[]>> {
    return this.axios.get('/filters/orders');
  }

  public getOrderFilterById(
    id: string,
  ): Promise<AxiosResponse<UserFilterModel>> {
    return this.axios.get(`/filters/orders/${id}`);
  }

  public createOrderFilter(
    body: UserFilterModel,
  ): Promise<AxiosResponse<UserFilterModel>> {
    return this.axios.post('/filters/orders', body);
  }

  public updateOrderFilter(
    body: UserFilterModel,
  ): Promise<AxiosResponse<unknown>> {
    return this.axios.put('/filters/orders', body);
  }

  public deleteOrderFilterById(id: string): Promise<AxiosResponse<unknown>> {
    return this.axios.delete(`/filters/orders/${id}`);
  }
}
