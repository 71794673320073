import { observable, action, toJS, makeObservable } from 'mobx';

import { RootStore } from './root.store';

import { MarketplaceApi } from '../api/marketplace.api';

import { TourCardModel } from '../models/marketplace/tourCard.model';
import { FilterDuration } from '../utils/constants';
import { SuggestedProductModel } from '../models/marketplace/suggestedProduct.model';
import { LocationTypesEnum } from '../models/enums/locationTypes.enum';
import { tourToSuggestion } from '../mappers/tour.mapper';
import { availableLanguages } from '../models/enums/language.enums';
import {
  getOrderByStringBySortType,
  getFilterForLocation,
  getFilterFromTourFilters,
} from '../utils/storeUtils';
import { SiteSettingsModel } from '../models/site-settings/siteSettings.model';

export const sortToursItems = ['default', 'priceHighToLow', 'priceLowToHigh']; // , 'durationShortToLong', 'durationLongToShort', 'rating', 'ratingCount',];

export const countPerMarketplacePage = 16;

export const minPriceDefault = 0;
export const maxPriceDefault = 500;

export class MarketplaceStore {
  private rootStore: RootStore;
  private apiService: MarketplaceApi;
  private searchTimer;

  @observable public productsForOnePage: TourCardModel[] = [];

  @observable public suggestedProducts: SuggestedProductModel[] = [];

  // filters
  @observable public minPrice: number = minPriceDefault;
  @observable public maxPrice: number = maxPriceDefault;
  @observable public durations: FilterDuration[] = undefined;
  // filters end

  // sorting
  @observable public sortType = 'default';
  // sorting end

  // searching
  @observable public isLoading = false;
  @observable public searchText = '';
  @observable public selectedLocation: SuggestedProductModel = undefined;
  @observable public serviceCode: string = undefined; // code for service 'TCV645'
  @observable public locationType: LocationTypesEnum = undefined; // Product_Code, Region_Code etc
  @observable public totalOfExistedCards: number = undefined;
  @observable public currentPagination = 1;
  @observable public isLocation = false;
  // searching end
  @observable public isServiceInfoNeeded = false;
  @observable public isSearched = false;

  constructor(rootStore: RootStore, apiService: MarketplaceApi) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.apiService = apiService;
  }

  @action async getSuggestions(text: string) {
    if (text.trim() === '') {
      this.suggestedProducts = [];
      return;
    }

    const siteSettings: SiteSettingsModel = toJS(
      this.rootStore.commonStore.siteSettings,
    );

    try {
      const res: any = await this.apiService.getSuggestions(text, siteSettings);
      const suggestedProducts = res.data.value;
      const withCurrentLanguage = suggestedProducts.filter(
        (item) => item.Language_Code === this.rootStore.userStore.language,
      );
      const anotherLanguage = suggestedProducts.filter(
        (item) => item.Language_Code !== this.rootStore.userStore.language,
      );
      const correctProducts = [...withCurrentLanguage, ...anotherLanguage];

      this.suggestedProducts = correctProducts;
      return Promise.resolve(correctProducts);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public getNecessaryProducts(options?: {
    code?: string;
    type?: LocationTypesEnum;
    text?: string;
  }) {
    let code;
    let type;
    let text;

    if (!this.isSearched) {
      this.getRecommendedProducts();

      return;
    }

    if (options) {
      code = options.code;
      type = options.type;
      text = options.text;
    }
    // this function will call getServiceInfo or getProducts with needed params
    if (this.isLocation) {
      this.getServiceInfo(code || this.serviceCode, type || this.locationType);
    } else {
      this.getProducts(text || this.searchText);
    }
  }

  public shouldUpdateSearch() {
    clearTimeout(this.searchTimer);
    // for preventing a few updating at the one time we should set timer
    this.searchTimer = setTimeout(() => this.getNecessaryProducts(), 20);
  }

  @action async getServiceInfo(code: string, type: LocationTypesEnum) {
    try {
      this.isLoading = true;

      const siteSettings: SiteSettingsModel = toJS(
        this.rootStore.commonStore.siteSettings,
      );
      const locationFilter = getFilterForLocation(code, type);
      const tourFilters = getFilterFromTourFilters({
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
      });
      let languageFilter = `Language_Code eq '${this.rootStore.userStore.language}'`;
      let orderByLanguage;
      if (this.rootStore.userStore.language === availableLanguages.CHI) {
        languageFilter = `(Language_Code eq '${this.rootStore.userStore.language}' or Language_Code eq '${availableLanguages.ENG}')`;
        orderByLanguage = 'Language_Code'; // we always will be get english after other language (Chinese for now therefore asc)
      }

      const filterConditions = [languageFilter, locationFilter, ...tourFilters];

      const orderBySortType = getOrderByStringBySortType(this.sortType);
      const orderByConditions = ['Featured desc']; // Dean and Matt want by default

      // unshift because the first will be the first order
      if (orderBySortType) {
        if (this.sortType !== 'default') {
          orderByConditions.unshift(orderBySortType);
        } else {
          // in case default featured should go first, then default sort
          orderByConditions.push(orderBySortType);
        }
      }

      !!orderByLanguage && orderByConditions.unshift(orderByLanguage);
      const params = {
        topCount: countPerMarketplacePage,
        language: this.rootStore.userStore.language,
        skip: countPerMarketplacePage * (this.currentPagination - 1),
        count: true,
        orderBy: orderByConditions,
        filterConditions,
        siteSettings,
      };
      const { products, productsCount } = await this.apiService.getProducts(
        params,
      );

      this.totalOfExistedCards = productsCount;
      this.productsForOnePage = products;
      this.isLoading = false;
      return Promise.resolve();
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  @action async getProducts(text?: string) {
    if (text.trim() === '') {
      this.productsForOnePage = [];
      return;
    }

    const siteSettings: SiteSettingsModel = toJS(
      this.rootStore.commonStore.siteSettings,
    );

    try {
      this.isLoading = true;

      const tourFilters = getFilterFromTourFilters({
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
      });
      let languageFilter = `Language_Code eq '${this.rootStore.userStore.language}'`;
      let orderByLanguage;
      if (this.rootStore.userStore.language === availableLanguages.CHI) {
        languageFilter = `(Language_Code eq '${this.rootStore.userStore.language}' or Language_Code eq '${availableLanguages.ENG}')`;
        orderByLanguage = 'Language_Code'; // we always will be get english after other language (Chinese for now)
      }
      const filterConditions = [languageFilter, ...tourFilters];

      const orderBySortType = getOrderByStringBySortType(this.sortType);
      const orderByConditions = [];
      !!orderBySortType && orderByConditions.unshift(orderBySortType);
      !!orderByLanguage && orderByConditions.unshift(orderByLanguage);
      const params = {
        text,
        topCount: countPerMarketplacePage,
        skip: countPerMarketplacePage * (this.currentPagination - 1),
        count: true,
        orderBy: orderByConditions,
        filterConditions,
        siteSettings,
      };

      const { products, productsCount } = await this.apiService.getProducts(
        params,
      );

      this.totalOfExistedCards = productsCount;
      this.productsForOnePage = products;
      this.isLoading = false;
      return Promise.resolve();
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  @action
  public async setCurrentPagination(pageNumber: number) {
    this.currentPagination = pageNumber;
  }

  @action
  public async setIsLocation(isLocation: boolean) {
    this.isLocation = isLocation;
  }

  @action
  public async setServiceCodeAndLocation(
    code: string,
    locationType: LocationTypesEnum,
  ) {
    this.serviceCode = code;
    this.locationType = locationType;
  }

  @action async getProductsAndSetSuggestions(text: string) {
    try {
      const siteSettings: SiteSettingsModel = toJS(
        this.rootStore.commonStore.siteSettings,
      );

      const { products }: any = await this.apiService.getProducts({
        text,
        topCount: 5,
        siteSettings,
      });

      let correctProducts;
      if (this.rootStore.userStore.language === availableLanguages.CHI) {
        const withCurrentLanguage = products.filter(
          (item) => item.Language_Code === this.rootStore.userStore.language,
        );
        const anotherLanguage = products.filter(
          (item) => item.Language_Code !== this.rootStore.userStore.language,
        );
        correctProducts = [...withCurrentLanguage, ...anotherLanguage];
      } else {
        correctProducts = products.filter(
          (product: TourCardModel) =>
            product.Language_Code === this.rootStore.userStore.language,
        );
      }

      const correctSuggestions = correctProducts
        // .filter((product: TourCardModel) => product.Language_Code === this.rootStore.userStore.language)
        .map((tour) => tourToSuggestion(tour));

      this.suggestedProducts = correctSuggestions;
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  @action async getRecommendedProducts() {
    // Default products shown (before any searches done), show USA featured products
    // it should have 250 ish products

    const siteSettings: SiteSettingsModel = toJS(
      this.rootStore.commonStore.siteSettings,
    );

    try {
      this.isLoading = true;
      const filterConditionsConst = ["Country_Code eq 'US'"];
      const orderBy = ['Featured desc', 'UserReviews desc'];

      const tourFilters = getFilterFromTourFilters({
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
      });
      let languageFilter = `Language_Code eq '${this.rootStore.userStore.language}'`;
      let orderByLanguage;
      if (this.rootStore.userStore.language === availableLanguages.CHI) {
        languageFilter = `(Language_Code eq '${this.rootStore.userStore.language}' or Language_Code eq '${availableLanguages.ENG}')`;
        orderByLanguage = 'Language_Code'; // we always will be get english after other language (Chinese for now)
      }
      const filterConditions = [
        ...filterConditionsConst,
        languageFilter,
        ...tourFilters,
      ];

      const orderBySortType = getOrderByStringBySortType(this.sortType);

      !!orderBySortType &&
        !orderBy.find((item) => item === orderBySortType) &&
        orderBy.unshift(orderBySortType);
      !!orderByLanguage && orderBy.unshift(orderByLanguage);

      const params = {
        topCount: countPerMarketplacePage,
        skip: countPerMarketplacePage * (this.currentPagination - 1),
        filterConditions,
        siteSettings,
        count: true,
        orderBy,
      };

      const { products, productsCount } = await this.apiService.getProducts(
        params,
      );

      this.totalOfExistedCards = productsCount > 250 ? 250 : productsCount;
      this.productsForOnePage = products;
      this.isLoading = false;
      return Promise.resolve();
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  @action
  public setIsSearched(isSearched: boolean) {
    this.isSearched = isSearched;
  }

  @action
  public setSearchText(text: string) {
    this.searchText = text;
  }

  @action
  public setLocation(suggestion: SuggestedProductModel) {
    this.selectedLocation = suggestion;
  }

  // filters
  @action
  public clearFilters() {
    this.minPrice = 0;
    this.maxPrice = 500;
    this.durations = undefined;
  }

  @action
  public setPriceBoundaries(boundaries: number[]) {
    this.minPrice = boundaries[0];
    this.maxPrice = boundaries[1];
  }

  @action
  public setMinPrice(minPrice: number) {
    if (minPrice > this.maxPrice) {
      this.minPrice = this.maxPrice;
    } else {
      this.minPrice = minPrice;
    }
  }

  @action
  public setMaxPrice(maxPrice: number) {
    if (maxPrice < this.minPrice) {
      this.maxPrice = this.minPrice;
    } else {
      this.maxPrice = maxPrice;
    }
  }

  @action
  public setDurations(durations: any[]) {
    this.durations = durations;
  }

  // sorting
  @action
  public setSortType(sortType: string) {
    this.sortType = sortType;
  }

  @action
  public clearStore() {
    this.productsForOnePage = [];

    this.suggestedProducts = [];

    // filters
    this.minPrice = minPriceDefault;
    this.maxPrice = maxPriceDefault;
    this.durations = undefined;
    // filters end

    // sorting
    this.sortType = 'default';
    // sorting end

    // searching
    this.isLoading = false;
    this.searchText = '';
    this.selectedLocation = undefined;
    this.serviceCode = undefined; // code for service 'TCV645'
    this.locationType = undefined; // Product_Code, Region_Code etc
    this.totalOfExistedCards = undefined;
    this.currentPagination = 1;
    this.isLocation = false;
    // searching end
    this.isServiceInfoNeeded = false;
    this.isSearched = false;
  }
}
