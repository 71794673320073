import React from 'react';

import { supportEmail } from '../../../utils/constants';

export default function Terms() {
  return (
    <div className="terms-page">
      <h1 className="terms-page-title">
        <p className="m-0">CHD. Inc / Travel USA Express</p>
        <p>Terms and Conditions</p>
      </h1>
      <p className="terms-description">
        This website, located at www.travelusaexpress.com (“Website”), is owned
        and operated by CHD. Inc doing business as (d.b.a.) Travel USA Express,
        a Nevada, USA LLC having its principal place of business at 3525 West
        Hacienda Avenue, Las Vegas, NV 89118, USA (hereinafter <b>“TUE“</b>,
        “us” or “our”). TUE offers member travel services exclusively for Travel
        Agencies/Tour Operator and for their customers. These terms of use
        (“Terms of Use”), the commercial contract, Booking Policy, along with
        any other required terms and policies, shall govern the relationship
        between TUE and the Travel Agency/Tour Operator (sometimes referred to
        as <b>“CLIENT”</b>
        ). Reference may be made to TUE and Travel Agency/Tour Operator as a
        Party or collectively the Parties. The Website contains text, pictures,
        graphics, logos, images, works of authorship, and other content
        (collectively with all information and material about TUE and its
        services, “Content”). The Website is intended for use only by users who
        are at least 18 years of age and who have a customer account with TUE.
      </p>

      <div className="terms-definitions">
        <p className="terms-small-title">
          <b>Definitions</b>
        </p>
        <ul className="definitions-list">
          <li>
            1. CLIENT is an online/offline agent/operator and acts as a “B2B” in
            provision of certain travel services, including accommodation
            reservation services, excursion services, transfer services,
            representative services, ticket services, special offers
            (collectively, the <b>“Services”</b>
            ).
          </li>
          <li>
            1. CLIENT desires to access TUE’s Online Reservation System “TCRS”
            (as defined hereunder) or contact TUE offline for the purpose of
            booking and/or purchasing the Services on behalf of its customers
            (the <b>“Customers”</b> and individually a <b>“Customer”</b>
            );
          </li>
          <li>
            1. TUE desires to grant such access to CLIENT and/or accept CLIENT’s
            offline reservation upon the terms and conditions herein.
          </li>
        </ul>
        <p>
          NOW, THEREFORES, in consideration of the promises and of the mutual
          covenants and agreements hereinafter contained, and the
          representations and warranties of the Parties hereinafter set forth
          and for other good and valuable consideration, the receipt and
          sufficiency of which are hereby acknowledged, the Parties agree as
          follows:
        </p>
      </div>

      <div>
        <p className="terms-small-title">
          <b>1. CLIENT INFORMATION</b>
        </p>
        <span>
          CLIENT is required to provide the following information to Customers
          and intermediaries:
        </span>
        <ul className="terms-client-information">
          <li>1.1 The Services that the Customer will receive;</li>
          <li>
            1.2 The obligations and fees associated with No Shows, as defined
            below, cancellations and modifications;
          </li>
          <li>
            1.3 Anticipated name changes of the commercial name of the hotel
            and/or service provider;
          </li>
          <li>1.4 Applicable taxes;</li>
          <li>
            1.5 That all the Customers, without exception (children included)
            must have current and up to date personal and family documentation,
            as required by the country of destination, evidenced by either
            passport or a national identifying document, as required by
            applicable country or countries visited;
          </li>
          <li>
            1.6 All extra services (special meals, extra beds, cots/cribs, etc.)
            and costs to the Customer or applicable intermediary.
          </li>
        </ul>
      </div>

      <div>
        <p className="terms-small-title">
          <b>2. VOUCHERS</b>
        </p>
        <ul>
          <li>
            2.1 CLIENT will provide the voucher(s) and/or E-ticket(s) to
            Customer and advise the Customer to present the voucher(s) and/or
            E-ticket(s) to the Supplier is a must in order to redeem the
            confirmed service(s). CLIENT will not present its’ own vouchers or
            E-Tickets in lieu of TUE’s voucher(s) or E-Ticket(s).
          </li>
          <li>
            2.2 It is CLIENT’s responsibility to remind Customers to read
            through the Voucher Instruction Page and follow the instructions
            provided.
          </li>
        </ul>
      </div>

      <p className="terms-small-title">
        <b>3. SPECIAL TERMS FOR DESTINATION SERVICES</b>
      </p>

      <div>
        <p className="terms-small-title">
          <b>3.1 Theater/Entertainment & Sporting Events</b>
        </p>
        <p>
          3.11 Tickets are all confirmed instantly with live availabilities. In
          the event of tickets are not available at the time of reservation,
          tickets are on request. When we receive your instruction to book we
          treat this as our guarantee that you will pay for them. If, in the
          end, we cannot secure the ticket requested we will advise you and
          there will not be any charges.
        </p>
        <p>3.12 All ticket requests must state the followings:</p>
        <ul className="terms-nasted-list">
          <li>
            Date of performance/show/event and alternative dates to try, if any.
          </li>
          <li>
            Whether matinee, evening performance desired or exact performance
            time.
          </li>
          <li>Name of Customer.</li>
        </ul>

        <p>
          3.13 Unless otherwise advised, all theater tickets are in the form of
          e-tickets and it is CLIENT’s responsibility to deliver the
          e-ticket/TUE’s voucher to the CUSTOMER.
        </p>

        <p>
          3.14 In the event of e-ticket is not available, theater tickets are to
          be collected at the box office and the Customer must be provided with
          TUE’s voucher to redeem the physical ticket(s) and Customer needs to
          provide US government issued photo identification or passport together
          with the TUE’s voucher to redeem the physical ticket(s).
        </p>
      </div>

      <div>
        <p className="terms-small-title">
          <b>3.2 PRIVATE TRANSFERS AND TAILOR-MADE SIGHTSEEING </b>
        </p>
        <ul>
          <li>
            3.21 All vehicles provided will be based on 1 piece of luggage per
            person. We must be advised if any travelers will have more than 1
            piece each, as it may be necessary to arrange for a larger vehicle.
            If we are not so advised, you will be responsible for all additional
            charges should a larger vehicle be required.
          </li>
          <li>
            3.22 If there is a delay (other than that caused by the supplier)
            which results in the service taking place in a supplement period,
            CLIENT will be liable to pay such additional supplement.
          </li>
          <li>
            3.23 Transfers are planned taking into account normal local traffic
            conditions. In the event that conditions during the course of the
            transfer mean that a flight, train or other connection is missed,
            TUE will accept no liability or responsibility for any additional
            costs the Customers may incur.
          </li>
          <li>
            3.24 For bus charter service starting at any port of entry in any
            countries, any delay caused by immigration procedures / baggage
            claim, it is the Customer’s responsibility to pay for the extra
            hours incurred.
          </li>
          <li>
            3.25 For services starting at an airport, station or port the
            waiting time for the driver is 30 minutes from the actual passenger
            arrival, unless otherwise stated. For services starting from other
            points there is no waiting time for the driver. Passengers must
            ensure they are waiting at the specified meeting point, as shown on
            their voucher, at least 10 minutes prior to the confirmed pick-up
            time. TUE is not responsible to refund of the tour fares, or
            compensate in any form.
          </li>
          <li>
            3.26 If travelling with babies and infants it is the responsibility
            of travelers to ensure that they bring with them, as well as fit,
            the correct child /infant seat for use in the booked vehicle.
            Failure to do this could result in the service not taking place with
            no refund possible.
          </li>
        </ul>
      </div>

      <div>
        <p className="terms-small-title">
          <b>3.3 Shared Transfers</b>
        </p>
        <ul>
          <li>
            3.31 When joining a transfer, Customer must produce a valid voucher.
            Without this voucher, service provider may refuse to allow the
            passenger to join the transfer.
          </li>
          <li>
            3.32 For services beginning at airports, stations or ports, it is
            the Customer’s responsibility to check their voucher and follow the
            procedure set out in order to join the transfer.
          </li>
          <li>
            3.33 Transfers are provided on the basis of one average sized
            suitcase and one piece of hand luggage per person. Should the
            Customer bring more luggage than this, they may be liable to a
            supplement to be paid directly to the transfer provider.
          </li>
          <li>
            3.34 Should the Customer not be able to join the transfer at the
            start of a service, it is the passenger’s responsibility to call the
            relevant telephone number as shown on the voucher before making
            alternative arrangements. In case of the alternative arrangements
            cannot be arranged or agreed, there will be no refund or
            compensation in any form provided by TUE.
          </li>
          <li>
            3.35 For services beginning from accommodation, please ensure that
            the Customer(s) are waiting at the appointed meeting point, as shown
            on the voucher, at least 10 minutes prior to the confirmed pick up
            time.
          </li>
          <li>
            3.36 When advising details for the service CLIENT wish to book, it
            is CLIENT’s responsibility to ensure that all details sent to TUE
            are correct.
          </li>
          <li>
            3.37 For services ending at airports, stations or ports, it is
            CLIENT’s responsibility to ensure that enough time has been left to
            complete the transfer, leaving enough time to complete all check-in
            and customs procedures.
          </li>
          <li>
            3.38 The durations of all transfers are representative of the
            driving time in average driving conditions. TUE takes no
            responsibility for a flight, train or other connection being missed
            should the duration of the service exceed that which displayed.
          </li>
          <li>
            3.39 Service providers of shared transfers reserve the right to ask
            any person to withdraw from any transfer if they deem their acts or
            conduct offensive or a nuisance to other passengers and there shall
            be no further liability.
          </li>

          <li>
            3.390 Service providers of shared transfers decline any
            responsibility for articles forgotten or lost in their vehicles.
          </li>
          <li>
            3.391 Pickup times from hotels and duration of all transfers are
            approximate and may be subject to traffic conditions.
          </li>
          <li>
            3.392 Where hotel pick-up is offered, service providers will pick up
            from the majority of major hotels in the city, but not necessarily
            from all hotels.
          </li>
          <li>
            3.393 If the service provider is unable to pick the Customer(s) up
            from the requested hotel, an alternative pick-up point will be
            suggested.
          </li>
          <li>
            3.394 Service providers of shared transfers reserve the right to
            alter or to cancel services at short or no notice for any reason.
          </li>
          <li>
            3.395 Where it is necessary to cancel the transfer by service
            provider, the Customer(s) will be offered a full refund.
          </li>
          <li>
            3.396 Tips are not included. These are at Customers’ discretion.
          </li>
          <li>3.397 Porterage is not included.</li>
        </ul>
      </div>

      <div>
        <p className="terms-small-title">
          <b>3.4 SCHEDULED SIGHTSEEING TOURS & EXTENDED TOURS</b>
        </p>
        <ul>
          <li>
            3.41 If the tour offers a hotel pick-up the Customer must call the
            service provider at least 24 hours in advance to request and confirm
            such a pick-up.
          </li>
          <li>
            3.43 Scheduled sightseeing or extended tours may comprise visits to
            more than one country. Neither TUE nor the service provider accepts
            any responsibility should a Customer not be able to proceed with
            such a tour because the Customer does not have the required travel
            documentation, e.g. a valid passport or visa. Failure to have such
            documentation may result in the Customer being refused entry into a
            country on the tour itinerary. In this event, no refunds will be
            given.
          </li>
          <li>
            3.44 Some scheduled sightseeing or extended tours require a minimum
            number of passengers before proceeding. In the event that the
            minimum requirement has not been met the service provider may cancel
            any bookings. We will endeavor to advise CLIENT of any cancellations
            as soon as possible.
          </li>
          <p>
            3.45 Service providers reserve the right to alter itineraries or to
            cancel tours at short or no notice for any reason. Occasionally
            scheduled sightseeing and extended tours do not proceed for local
            reasons. In the event that it becomes necessary to cancel a tour, at
            least one of the following alternatives will be offered:
          </p>
          <ul className="terms-nasted-list">
            <li>
              To continue with the requested tour but at an alternative time or
              date.
            </li>
            <li>To change to a different tour.</li>
            <li>To receive a full refund.</li>
          </ul>

          <li>
            3.46 TUE is not liable and will not offer compensation in the event
            that a scheduled sightseeing or extended tour does not proceed.
          </li>
          <li>
            3.47 Tips are not included in any of the prices unless stated
            otherwise. They are at the discretion of the Customer.
          </li>
          <li>
            3.48 Children travelling free may not be entitled to meals and must
            be carried on the lap of an accompanying adult if no seats are
            available.
          </li>
          <li>
            3.49 Service providers reserve the right to ask any person to
            withdraw from any tour if they deem their behavior or conduct
            offensive or a nuisance to other passengers. Any refund will be made
            at the discretion of the service provider.
          </li>
          <li>
            3.490 Neither TUE nor the service providers are responsible for
            articles left in their vehicles.
          </li>
          <li>
            3.491 Pickup times from hotels and duration of tours is approximate
            and may be subject to local traffic conditions.
          </li>
          <li>
            3.492 Where a hotel pick up is offered, service providers will pick
            up passengers from the majority of the major hotels in the city, but
            not necessarily from all hotels. If the service provider is unable
            to pick up from a requested hotel, an alternative pick up point will
            be suggested.
          </li>
        </ul>
      </div>

      <div>
        <p className="terms-small-title">
          <b>
            3.5 CANCELLATION OF ALL DESTINATION SERVICES EXCEPT ACCOMMODATION{' '}
          </b>
        </p>
        <ul>
          <li>
            3.51 If CLIENT to cancel a service which was not booked online
            CLIENT will receive an email from TUE to confirm your cancellation.
            If you do not receive such an email from TUE please resend your
            cancellation request. Cancelled bookings can be checked online only
            if they were booked online, unless otherwise advised.
          </li>
          <li>
            7.52 In the event that charges are incurred for the cancellation,
            you will be advised in a separate message.
          </li>
          <li>
            7.53 Unless otherwise stated, cancellation of Bookings must be
            received no later than the due date indicated on TCRS and any
            cancellations received after this deadline will be charged in full.
            Other cancellation deadlines may be advised online or in our tariff
            for specific tours.
          </li>
          <li>
            7.54 No refund will be made for any unused portions of the Booking.
          </li>
        </ul>
      </div>

      <div>
        <p className="terms-small-title">
          <b>4. SPECIAL TERMS FOR HOTEL BOOKINGS</b>
        </p>
        <ul>
          <li>
            4.1 Hotels, Other Accommodation Providers. All Services are
            specified in the reservation confirmation, with the exception of
            those accommodation providers for which the inclusion of other
            services is specifically indicated.
          </li>
          <li>
            4.2 Customers must be 21 to check in to hotels in North America. In
            some countries the legal adult age may differ depending on the
            relevant local legislation. It will be the sole responsibility of
            the final Customer to ensure that he/she is at least of legal age in
            order to check in to the accommodation establishment.
          </li>
          <li>
            4.3 Room type for all passengers will be based on availability.
          </li>
          <li>
            4.4 In USA and Canada twins/doubles are defined by the number of
            occupants and not the type of bed i.e. if a twin is confirmed hotels
            may provide a double (with ONE bed) instead as both sleep 2 people.
            Customers may request separate beds upon reservation, however,
            separate beds are not guaranteed unless otherwise expressed. Triples
            and quads are not made up of single beds; they will generally
            contain two double beds with no guarantee. Most hotels cannot add
            additional beds to make triple or quad but rollaway beds may be
            available for an additional charge.
          </li>
          <li>
            4.5 Generally, hotel check-in times begin after 15:00 hours, and the
            latest check-out time on the date of departure is 11:00 hours. The
            price of the Services do not include extra service, minibar,
            parking, etc. which shall be paid directly by the guests.
          </li>
          <li>
            4.6 The categories of the hotels have been provided by the
            accommodation providers themselves and in accordance with specific
            regulations applicable in each country. A hotel in one country,
            therefore, may not be similar in terms of services and quality to a
            hotel in another, despite belonging to the same category.
          </li>
          <li>
            4.7 TUE guarantees only the first night of a booking (unless
            otherwise advised at time of confirmation); hotels may release rooms
            if the traveler has not arrived by 0000hrs (local time) following
            the booked arrival date.
          </li>
          <li>
            4.8 If an additional person is not mentioned during reservation,
            hotel has all the rights to collect extra person cost (if
            applicable) directly from the Customer(s).
          </li>
          <li>
            4.9 Most accommodation establishments may request a holding deposit
            on credit or debit card from end customers upon arrival at the
            accommodation for incidental charges incurred during the end
            customer’s stay, including but not limited to, long distance
            telephone charges, room service, resort fees, in-room movies, damage
            or theft of hotel property, minibar usage and other such hotel
            amenities. You are obliged to inform about this requirements to the
            customer.
          </li>
          <li>
            4.90 Bookings must not be made with fictitious names to hold space.
            If you do not book with the correct name you may receive non-arrival
            charges.
          </li>
          <p>
            4.91 Block booking and holding room blocks is prohibited. The
            blocking of multiple rooms with unconfirmed names or changing
            customer names are prohibited. Should any suspected room blocks
            detected, TUE reserves the right to:
          </p>
          <ul className="terms-nasted-list">
            <li>Deny and cancel such bookings</li>
            <li>
              Change the bookings to a group booking, which may increase fees
              and require different payment terms
            </li>
            <li>
              Charge an administrative fee for handling the block bookings
            </li>
          </ul>
          <li>
            4.92 Transfers and excursions, transfers and excursions are not
            complementary, and as such, require Customers to pay extra for these
            types of services.
          </li>
        </ul>
      </div>
      <div>
        <p className="terms-small-title">
          <b>5. CHILDREN</b>
        </p>
        <p>
          CLIENT must make all special requests with respect to Services for
          children, including crib service, at the time of making the
          reservation. Accommodation Services’ discounts only apply when
          children share a room with accompanying adults.
        </p>
      </div>
      <div>
        <p className="terms-small-title">
          <b>6. NO SHOWS</b>
        </p>
        <p>
          A “No Show” means when a Customer fails to show up for a Service that
          has been booked under the terms of the Agreement and fails to cancel
          such Service per the terms of the Agreement prior to the reserved date
          and time or arrival. A No Show is deemed a cancellation. CLIENT is
          liable for all fees associated with a No Show, which may include the
          cost of a single night stay up to the entire amount of the
          accommodation reservation, unless otherwise agreed to in writing
          between TUE and CLIENT.
        </p>
      </div>
      <div>
        <p className="terms-small-title">
          <b>7. MODIFICATIONS</b>
        </p>
        <p>
          Unless expressly agreed to in writing by TUE, CLIENT may not reduce
          the reserved period of stay or requested Service, nor change the names
          of the Customer once the reservation has been completed. Any
          Customer’s name change not expressly agreed in writing by TUE shall be
          deemed to be a cancellation and rebook. Modifications to extend the
          reserved period shall be subject to availability and increased pricing
          for such Services. In case of reduction of the stay reserved period,
          this could be subject to cancellation fees, depending on each
          accommodation establishment policies.
        </p>
      </div>
      <div>
        <p className="terms-small-title">
          <b>8. CANCELLATIONS</b>
        </p>
        <ul>
          <li>
            8.1 CLIENT shall have the right to a refund for any Services
            cancelled per the terms of the Agreement, minus any accrued
            cancellation charges and non-refundable amounts. The refund amount
            regardless of the payment method of how the payment was previously
            made, will be returned by default to the Account Balance in CLIENT’s
            TCRS account for future booking payment(s).
          </li>
          <li>
            8.2 CLIENT may request a refund to the credit card that payment was
            made for prepayment or refilling Account Balance in writing by email
            mentioning not limited to TUE’s transaction number, invoice number,
            your reference number (if applicable) and the refund amount. The
            process upon receiving CLIENT’s request may take up to 45 days. A 4%
            (four percent) bank fee will be charged by TUE.
          </li>
          <li>
            8.3 CLIENT may request a refund by check or wire transfer that
            payment was made for the booking(s) in writing by email mentioning
            not limited to TUE’s transaction number, invoice number, your
            reference number (if applicable) and the refund amount. The process
            upon receiving CLIENT’s request may take up to 14 business days. A
            $25 bank fee will be charged by TUE in case of wire transfer is
            requested.
          </li>
          <li>
            8.4 Accommodation services. Cancellation may be subject to accrued
            cancellation charges which vary depending on the policy of each
            accommodation establishment and may oscillate from the price of one
            night or accommodation or the full price of the stay.
            Notwithstanding, the aforementioned, cancellation terms and periods
            may vary and shall be indicated at the time of booking. The
            cancellation charges vary according to destination, dates and
            accommodation establishment, and are determined at the time of
            booking.
          </li>
          <li>
            8.5 Except for cancellations made via www.travelusaexpress.com, the
            cancellation request must be made in writing, directed to TUE and
            include destination and reservation number(s). Upon receipt, TUE
            will send an acknowledgement of the requested cancellation and
            inform CLIENT of all applicable charges, if any. A cancellation is
            not complete until CLIENT receives written confirmation by TUE.
            CLIENT is liable for and shall pay TUE for any charges for
            cancellations made by the Customer which are charged to TUE.
          </li>
          <li>
            8.6 Claims for reimbursement by CLIENT or a Customer for leaving the
            accommodation establishment before the reserved departure date, must
            be sent to TUE within twenty (20) calendar days of the effective
            date of departure, and must include written confirmation from the
            accommodation provider of time and date of departure. Such claims
            are subject to the terms and conditions of the Agreement.
          </li>
          <li>
            8.7 During certain periods, including peak occupation season,
            accommodation providers may charge the full amount of the original
            reservation to the Customer for any cancellation.
          </li>
          <li>
            8.8 Services other than accommodation Services have various booking
            and cancellation policies, which CLIENT may be informed of by the
            applicable service provider.
          </li>
        </ul>
      </div>

      <div>
        <p className="terms-small-title">
          <b>9. ADDITIONAL CANCELLATION AND ADMINISTRATIVE FEES</b>
        </p>
        <p>
          Multiple Customer name changes may be subject to a USD$25 fee per
          modification. The administrative fee and cancellation fees may change
          from time to time, at TUE sole discretion, without further notice to
          CLIENT. Such administrative and cancellation policies shall be
          provided to CLIENT upon reasonable request or on the Website.
        </p>
      </div>

      <div>
        <p className="terms-small-title">
          <b>10. MISCELLANEOUS</b>
        </p>
        <ul>
          <li>
            10.1 An accommodation provider may change its business name or trade
            name, which shall not be construed as a change of hotel or
            modification or the reservation.
          </li>
          <li>
            10.2 In some countries, there is a local tax known as “visitors’
            tax”, “city tax”, “tourist tax” (or similar) and other fees
            including (but not limited to) resort fees or service charges, which
            shall be paid directly by Customer at the establishment and/or at
            the airport. TUE shall use reasonable endeavors to provide, at the
            time of booking, an estimation of the applicable fees and/or local
            tax(es) attributable to each individual booking and payable at the
            time of booking and/or locally on arrival (“Local Tax/Fee
            Estimation”), however please note that TUE does not warrant that the
            Local Tax/Fee Estimations shall be accurate and the CLIENT
            acknowledges and agrees that the Local Tax/Fee Estimations are
            provided as estimates only. The CLIENT further acknowledges that
            Local Taxe/Fee Estimations may change from time to time. As a
            result, TUE cannot be held liable for any loss, costs or damages
            incurred as a result of the provision of such Local Tax/Fee
            Estimations. Confirmation of and the accuracy of the Local Tax/Fee
            Estimations is ultimately the responsibility of the CLIENT.
          </li>
          <li>
            10.3 To confirm a booking, the user must be authorized to make the
            booking by of the Service by all persons named on the booking and
            their parent or guardian for all party members who are under 18 when
            the booking is made.
          </li>
        </ul>
      </div>

      <div>
        <p className="terms-small-title">
          <b>11. RESPONSIBILITY</b>
        </p>
        <p>
          TUE and its affiliates are not responsible for any acts and/or
          omissions of provider or suppliers of Services, their employees,
          agents or representatives. The air carriers, accommodations, and other
          suppliers (including but not limited to trains, cruises, ferries,
          motor coaches, hotels, and restaurants) providing services are
          independent contractors and are not agents, employees, servants, or
          joint ventures of TUE or its affiliates. All certificates and other
          travel documents for services issued by TUE are subject to the terms
          and conditions specified by the supplier, which are available upon
          request, and to the laws of the countries in which the services are
          supplied. TUE, its agents, officers and/or suppliers of services
          pursuant to, or in connection with these itineraries, does not assume
          any liability whatsoever for any injury, damage, death, loss, accident
          or delay to person or property due to an act of negligence or default
          of others, including any hotel, carrier, restaurant, company or person
          rendering any of the services included in the tour, or by act of God.
          Further, no responsibilities are accepted for any damage or delay due
          to sickness, pilferage, labor disputes, machinery breakdown,
          quarantine, government restraints, weather or other causes beyond TUE
          control. No responsibility is accepted for any additional expense,
          omissions, delays, re-routing or acts of any government or authority.
        </p>
        <p>
          TUE, acting as intermediary only, will reasonably assist the CLIENT
          with respect to Customer related incidents and complaints handling.
          Such assistance will consist of informing the Service supplier about
          the complaint and using its commercially reasonable best efforts to
          ensure that the Service suppliers accept liability and indemnify
          CLIENT from and against any claims of the CLIENT’s Customers arising
          from the provision of the travel Services.
        </p>
      </div>

      <div>
        <p className="terms-small-title">
          <b>
            12. WARRANTY, DISCLAIMER OF WARRANTY, LIMITATION OF LIABILITY,
            INDEMNIFICATION:
          </b>
        </p>
        <ul>
          <li>
            <p>12.1 WARRANTY</p>
            <p>
              Each Party represents, warrants and covenants to the other Party
              that: (i) it will comply with all applicable laws in the
              performance of its obligations hereunder; (ii) as of the date
              hereof, it is duly organized or incorporated, validly existing and
              in good standing under the laws of its state or country of
              organization or incorporation; (iii) it has full power and
              authority, and all other rights, licenses and authorizations
              required by the state and country of its incorporation, to execute
              and deliver this Agreement and to perform its obligations set
              forth, and grant all rights granted, in the Agreement; (iv) the
              execution and delivery of this Agreement and the performance of
              all obligations in this Agreement have been duly and validly
              authorized by all necessary action on its part; (v) it will
              satisfy all obligations owed to third parties to be undertaken by
              it pursuant to this Agreement; and (vi) its performance of its
              obligations set forth in this Agreement will not: conflict with or
              breach any provision of its organizational or constitutional
              documents; breach any applicable laws, rules, regulations or
              ordinances in the state and country of its incorporation; or
              breach any provision of any agreement to which it is a Party or by
              which it is bound.
            </p>
          </li>
          <li>
            <p>12.2 DISCLAIMER OF WARRANTY</p>
            <p>
              EXCEPT AS STATED IN THIS SECTION 6, TUE MAKES NO REPRESENTATIONS
              ABOUT THE RESULTS TO BE OBTAINED FROM USING THE WEBSITE, THE
              SERVICES, OR THE INFORMATION OR THE CONTENT OF THE WEBSITE. THE
              USE OF SAME IS AT COMPANY’S AND CUSTOMER’S OWN RISK.
            </p>
            <p>
              THE WEBSITE, THE SERVICES, AND THE CONTENT OF THE WEBSITE ARE
              PROVIDED TO CLIENT ON AN “AS IS” BASIS. EXCEPT AS STATED IN THIS
              SECTION 6, TUE, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS
              ALL WARRANTIES, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
              INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
              MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES’ RIGHTS, AND
              FITNESS FOR A PARTICULAR PURPOSE. TUE MAKES NO REPRESENTATIONS OR
              WARRANTIES ABOUT THE ACCURACY, COMPLETENESS, SECURITY OR
              TIMELINESS OF THE CONTENT, INFORMATION OR SERVICES PROVIDED ON OR
              THROUGH THE USE OF THE WEBSITE. NO INFORMATION OBTAINED BY COMPANY
              FROM THE WEBSITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED BY
              TUE IN THIS AGREEMENT.
            </p>
            <p>
              SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES,
              SO THE LIMITATIONS AND EXCLUSIONS IN THIS SECTION MAY NOT APPLY TO
              COMPANY. COMPANY AGREES AND ACKNOWLEDGES THAT THE LIMITATIONS AND
              EXCLUSIONS OF LIABILITY AND WARRANTY PROVIDED IN THIS AGREEMENT
              ARE FAIR AND REASONABLE.
            </p>
          </li>
          <li>
            <p>12.3 INDEMNIFICATION</p>
            <p>
              Subject to the limitations in Section 5 and Section 6.4 of this
              Agreement, as applicable, each party (the “Indemnifying Party”)
              shall indemnify, hold harmless from, and defend the other party,
              its Affiliates and their respective officers, directors,
              employees, agents, successors and assigns, against any claim,
              damage, loss, liability, cost or expense (including reasonable
              fees and disbursements of counsel) (“Claims”) resulting or arising
              from (i) the distribution or sale of the Services (but only to the
              extent that the Claim does not result, arise from or is
              attributable to any act of the Indemnifying Party), (ii)
              Indemnifying Party’s fraud, gross negligence or bad faith, or
              (iii) any breach of a representation, warranty or covenant
              contained in this Agreement by the Indemnifying Party.
            </p>
          </li>
          <li>
            <p>12.4 LIMITATION OF LIABILITY</p>
            <p>
              IN NO EVENT SHALL EITHER PARTY, ITS DIRECTORS, OFFICERS,
              EMPLOYEES, AGENTS OR AFFILIATES, BE LIABLE TO THE OTHER PARTY FOR
              ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR
              CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOST PROFITS,
              ARISING OUT OF THIS AGREEMENT, WHETHER BASED ON CONTRACT, TORT
              (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHER LEGAL THEORY,
              EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBLITY OF SUCH
              DAMAGES.
            </p>
            <p>
              The information on the TUE Website is posted in good faith but TUE
              cannot guarantee that it is completely free from inaccuracies and
              typographical errors and does not accept liability for any error
              or omission on the TUE’s Website. Information on the various
              services is as accurate as possible given that the information is
              provided by the service supplier.
            </p>
            <p>
              Obvious errors and mistakes (including misprints, typographical
              errors and errors in calculating currency conversion) are not
              binding. In the event of an error or mistake being made, TUE or
              the service provider reserves the right to cancel any booking and
              refund monies paid by the CLIENT to TUE in relation to such
              booking (if applicable), this will be without any liability to the
              CLIENT and or the customer.
            </p>
          </li>
        </ul>
      </div>

      <div>
        <p className="terms-small-title">
          <b>13. CONFIDENTIALITY AND PROTECTION OF PERSONAL DATA</b>
        </p>
        <p>13.1 CONFIDENTIALITY</p>
        <p>
          Each Party (the “Disclosing Party”) may, from time to time, reveal to
          the other Party (the “Receiving Party”) such confidential data and
          information regarding the business and operations of the Disclosing
          Party which the Disclosing Party may determine to be necessary for the
          performance this Agreement. The Receiving Party acknowledges and
          agrees that such information is valuable to the Disclosing Party and
          that a part of the value of such information derives from the fact
          that the information is confidential. The Receiving party covenants
          that it shall not, without Disclosing Party’s prior written consent,
          disclose, divulge, or publish to others, or use to its own advantage,
          any knowledge, data, or information obtained pursuant to this
          Agreement. The Receiving Party further covenants that it shall reveal
          such confidential data and information only to those individual
          persons acting solely in the Receiving Party’s or Disclosing Party’s
          behalf who require it for the authorized purposes of this Agreement.
          The Receiving shall not, by virtue of this Agreement, obtain any title
          to, or any interest or license in, any confidential data and
          information divulged by the Disclosing Party hereunder. In the event
          of the termination of this Agreement, the Receiving Party shall,
          within thirty (30) calendar days, return all papers, data, drawings,
          and materials of any kind supplied to it by the Disclosing Party
          hereunder and any copies thereof. The covenant contained in this
          Section 6 shall survive the termination of the Agreement.
          Notwithstanding the provisions of this clause, the Parties agree that
          the obligation of confidentiality shall not apply in the following
          cases:
        </p>
        <ul className="terms-alphabetical-item">
          <li>
            a) In the case of information that is in the public domain or once
            supplied the information becomes public knowledge, as long as is not
            resulting from a breach of this Clause.
          </li>
          <li>
            b) Any information that has been disclosed by a third party and is
            not considered confidential, provided that the third party was
            authorized to disclose that information.
          </li>
          <li>
            c) When the disclosure is required by law, by order of a Court or
            Tribunal of a relevant jurisdiction or administrative mandate.
          </li>
        </ul>
        <p>
          In the case referred to in paragraph c), the content of the disclosure
          will be limited to what is necessary to comply with the legal or
          administrative requirements, but not exempt from the duty to comply
          with the confidentiality obligation on disclosing the information to
          third parties, nor does such information come into the public domain
          for the purposes of the provisions of paragraph a). Additionally, it
          is mandatory to notify the other party of such a request, prior to
          releasing confidential information, committing to do everything
          possible to ensure that confidential treatment of the information is
          given.
        </p>

        <p>13.2 PROTECTION OF DATA</p>
        <p>
          TUE uses various organizational and technical measures to protect
          CLIENT’s privacy and avoid unauthorized use of disclosure of CLIENT’s
          personal data. However, CLIENT should note that no transfer of data
          over the internet is completely secure. While we use reasonable
          efforts to protect CLIENT’s data on our systems, when the data is
          transferred over the internet, it may be accessed and used by
          unauthorized third parties. TUE has no control over the performance,
          reliability, availability or security of the internet and does not
          warrant that any information transfer via the internet or any
          communication through internet is secure. TUE shall not be liable for
          any disclosure, unauthorized use, loss, damage, expense, harm or
          inconvenience resulting from the loss, delay, interception,
          corruption, intervention, misuse or alteration of any personal
          information due to any action beyond TUE reasonable control. TUE SHALL
          NOT BE RESPONSIBLE FOR ANY BREACH OF SECURITY OF THE WEBSITE, ANY
          DAMAGE, LOSS OR CORRUPTION OF CLENT’S DATA, INFORMATION OR MATERIAL.
          ANY VIRUSES OR OTHER MALICIOUS PROGRAMS THAT MAY AFFECT CLIENT’S
          COMPUTER EQUIPMENT OR OTHER PORPERTY.
        </p>
      </div>

      <div>
        <p className="terms-small-title">
          <b>14. OWNERSHIP AND OTHER RIGHTS</b>
        </p>
        <p>
          Any and all intellectual property rights (“Intellectual Property”)
          associated with the Website and its contents (the “Content”) are the
          sole property of TUE, its affiliates or third parties. The Content is
          protected by copyright and other laws in both the United States and
          other countries. Elements of the Website are also protected by trade
          dress, trade secret, unfair competition, and other laws and may not be
          copied or imitated in whole or in part. All custom graphics, icons,
          and other items that appear on the Website, including, but not limited
          to the TUE logo and name, are trademarks, service marks or trade dress
          (“Marks”) of TUE, its affiliates or other entities that have granted
          TUE the right and license to use such Marks and may not be used or
          interfered with in any manner without the express written consent of
          TUE. Except as otherwise expressly authorized by this Agreement,
          CLIENT may not copy, reproduce, modify, lease, loan, sell, create
          derivative works from, upload, transmit, or distribute the
          Intellectual Property of the Website in any way without TUE or the
          appropriate third party’s prior written permission. Except as
          expressly provided herein, TUE does not grant to CLIENT any express or
          implied rights to TUE or any third part’s Intellectual Property.
        </p>
      </div>

      <div>
        <p className="terms-small-title">
          <b>15. CHANGES TO TUE’S WEBSITE</b>
        </p>
        <p>
          TUE may make improvements or changes to the information, elements,
          data, services, and other materials on the Website. TUE, in its sole
          discretion, may also modify this Agreement at any time, and such
          modification shall be effective immediately upon posting of the
          modified terms and conditions on the Website. Accordingly, the
          continued access or use of the Website by the CLIENT or its agent,
          representative or affiliate is deemed to be acceptance of the modified
          terms and conditions of this Agreement. TUE may suspend access to the
          program for maintenance, security reasons or due to a Force majeure
          Event (as defined in Section 19), with no obligation to reimburse or
          compensate CLIENT for the time for which access has been suspended.
        </p>
      </div>

      <div>
        <p className="terms-small-title">
          <b>16. CONTACTING US</b>
        </p>
        <p>
          If you have any questions or concerns about these Terms of Use, please
          contact us through our Contact Us web page located at
          www.travelusaexpress.com or at the mailing address provided below. We
          will attempt to respond to your questions or concerns promptly after
          we receive them.{' '}
        </p>
      </div>

      <div>
        <p>Travel USA Express</p>
        <p>3525 West Hacienda Avenue,</p>
        <p>Las Vegas, NV 89118,</p>
        <p>USA</p>
        <div className="m-t-20">
          <p>{supportEmail}</p>
          <p>Or by phone at (702) 853-6619</p>
          <p>Latest update: 7/15/2019</p>
        </div>
      </div>
    </div>
  );
}
