import React, { useMemo, useRef } from 'react';
import moment, { Moment } from 'moment';
import { Button, Col, Row } from 'antd';
import { Field, useFormikContext } from 'formik';
import { get } from 'lodash';
import intl from 'react-intl-universal';

import {
  CheckboxContainer,
  TimePickerContainer,
  RangeDatepickerContainer,
  InputNumberContainer,
  SelectContainer,
  CheckboxGroupContainer,
} from 'components/FormContainers';
import { withStore } from 'hocs';
import { InventoryStore } from 'stores/inventory.store';
import { checkIsRateDatesExtended } from 'utils/inventoryHelper';
import { VisibilityEnum } from 'models/enums/visibility.enum';
import { FormRateModel } from 'models/inventory/formRate.model';
import { ProductFormValuesModel } from 'models/inventory/productFormValues.model';
import { getDaysOptions, getVisibilityOptionLabel } from 'utils/rateUtils';
import CategoriesSelect from './CategoriesSelect';
import ProductRates from './ProductRates';
import RatePanelCopyPaste from './RatePanelCopyPaste';

type Props = {
  inventoryStore?: InventoryStore;
  rate: FormRateModel;
  initialValues: ProductFormValuesModel;
  onCancel: (position: number) => void;
};

function RatePanel(props: Props) {
  const { inventoryStore, rate, initialValues, onCancel } = props;
  const { agenciesOptions, categories, visibilityList } = inventoryStore;

  const formikProps = useFormikContext<ProductFormValuesModel>();
  const { values } = formikProps;

  const defaultAvailabilityRef = useRef(null);

  const visibilityOptions = visibilityList.map((visibility) => ({
    value: visibility,
    label: getVisibilityOptionLabel(visibility),
  }));

  const operatorData = inventoryStore.getProductSupplier(values.OperatorId);

  const isDatesExtended = useMemo(() => {
    const datesFieldName = `Rates.${rate.position}.InventoryDate`;

    const [, prevEndDate] = get(initialValues, datesFieldName) || [];

    const [, currentEndDate] = get(values, datesFieldName) || [];

    return checkIsRateDatesExtended(prevEndDate, currentEndDate);
  }, [rate, initialValues, values]);

  const isDefaultAvailabilityVisible = useMemo(
    () => !rate.isExistRate || rate.isCategoryChanged || isDatesExtended,
    [rate, isDatesExtended],
  );

  const onInventoryDateOpenChange = (isOpen: boolean) => {
    if (!isOpen && isDatesExtended) {
      setTimeout(() => {
        defaultAvailabilityRef.current?.focus();
      }, 100);
    }
  };

  return (
    <Row gutter={[30, 10]}>
      <Col span={12}>
        <Field
          label={intl.get('rateAvailability.rateVisibility')}
          placeholder={intl.get('rateAvailability.selectRateVisibility')}
          options={visibilityOptions}
          className="w-100"
          name={`Rates.${rate.position}.Visibility`}
          component={SelectContainer}
        />
      </Col>

      {values.Rates[rate.position].Visibility === VisibilityEnum.Negotiated && (
        <Col span={12}>
          <Field
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            label={intl.get('inventory.products.popup.negotiatedWithAgency')}
            placeholder={intl.get('inventory.products.popup.selectAgency')}
            className="w-100"
            options={agenciesOptions}
            name={`Rates.${rate.position}.AgencyId`}
            component={SelectContainer}
          />
        </Col>
      )}

      <Col span={24}>
        <CategoriesSelect
          ratePosition={rate.position}
          categories={categories}
          formikProps={formikProps}
          productCode={values.Id}
          MultipleCategories={values.MultipleCategories}
          initialValues={initialValues}
        />
      </Col>

      <Col span={24}>
        <Row gutter={30}>
          <Col span={12}>
            <Field
              key={values.DefaultTime?.toString()}
              use12Hours
              format="h:mm a"
              label={intl.get('productPage.time')}
              placeholder={intl.get('inventory.products.popup.serviceTime')}
              defaultValue={values.DefaultTime}
              name={`Rates.${rate.position}.ServiceTime`}
              component={TimePickerContainer}
            />
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <Field
          format="MM/DD/YYYY"
          label={intl.get('inventory.products.popup.serviceDates')}
          disabledDate={(currentDate: Moment) =>
            currentDate && currentDate < moment().subtract(1, 'd').endOf('day')
          }
          name={`Rates.${rate.position}.InventoryDate`}
          style={{ width: '100%' }}
          component={RangeDatepickerContainer}
          onOpenChange={onInventoryDateOpenChange}
        />
      </Col>

      {isDefaultAvailabilityVisible && (
        <Col span={12}>
          <Row gutter={30}>
            <Col span={14}>
              <Field
                inputRef={defaultAvailabilityRef}
                name={`Rates.${rate.position}.DefaultAvailability`}
                min={0}
                type="number"
                label={intl.get('inventory.products.popup.defaultAvailability')}
                component={InputNumberContainer}
              />
            </Col>

            <Col span={10}>
              <Field
                name={`Rates.${rate.position}.StopSell`}
                fieldLabel="&nbsp;"
                label={intl.get('productPage.stopSell')}
                component={CheckboxContainer}
              />
            </Col>
          </Row>
        </Col>
      )}

      <Col span={24}>
        <Field
          id={`Rates.${rate.position}.DaysOfWeek`}
          name={`Rates.${rate.position}.DaysOfWeek`}
          label={intl.get('inventory.products.popup.serviceDaysOfWeek')}
          options={getDaysOptions()}
          component={CheckboxGroupContainer}
        />
      </Col>

      <Col span={24}>
        <div className="new-product-title-divider">
          <h4>
            <span>
              {intl.get('inventory.products.popup.ratesTable.RateDivider')}
            </span>
          </h4>
        </div>

        <RatePanelCopyPaste
          rate={rate}
          className="m-b-10"
          formikProps={formikProps}
        />

        <ProductRates
          rates={values.Rates}
          ratePosition={rate.position}
          formikProps={formikProps}
          operatorData={operatorData}
        />
      </Col>

      <Col span={24}>
        <Row justify="end">
          <Col>
            <Button onClick={() => onCancel(rate.position)}>
              {intl.get('buttons.ok')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
}))(RatePanel);
