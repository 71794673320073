import numeral from 'numeral';
import moment, { Moment } from 'moment';
import { isEqual, pick, toNumber } from 'lodash';

export const getCorrectDate = (date): Moment => {
  if (!date) {
    return undefined;
  }

  const correctDate = moment(date);

  return correctDate.isValid() ? correctDate : undefined;
};

export const normalizeDate = (date: Moment): Moment =>
  date.hour(0).minute(0).second(0).millisecond(0);

export function moneyFormat(value: number) {
  return `$${numeral(value).format('0,0.00')}`;
}

export const stringSort = (property) => (a, b) =>
  a[property] < b[property] ? 1 : -1;

export const numberSort = (property) => (a, b) => a[property] - b[property];

export const dateSort = (property) => (a, b) =>
  +new Date(a[property]) - +new Date(b[property]);

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getImageElementBySrc = (src: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);

    img.src = src;
  });

export function bytesToSize(bytes: number) {
  if (bytes === 0) return 'n/a';

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(3000000) / Math.log(1024));
  const value = i === 0 ? bytes : Math.ceil(bytes / 1024 ** i).toFixed(1);

  return `${value} ${sizes[i]}`;
}

// eslint-disable-next-line no-control-regex
export const englishLettesAndSymbolsRegex = /[^\u0000-\u007f'‎]/;
export const englishLetterRegex = /^[a-zA-Z0-9\-\s]+$/;
// eslint-disable-next-line no-useless-escape
export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // E-mail validation according to https://www.w3resource.com/javascript/form/email-validation.php

/* eslint-disable no-prototype-builtins */
/**
 * SwitchCase function inspired by
 * https://hackernoon.com/rethinking-javascript-eliminate-the-switch-statement-for-better-code-5c81c044716d
 */

const executeIfFunction = (f) => (typeof f === 'function' ? f() : f);

export const chooseByKey = (cases, defaultCase) => (key) =>
  cases.hasOwnProperty(key) ? cases[key] : defaultCase;

export const chooseByValue = (cases, defaultCase) => {
  const [choosenKey] = Object.entries(cases).find(([, value]) => !!value) || [];

  return choosenKey || defaultCase;
};

/**
 * Functional analogue of if-else-if statement
 *
 * examples:
 * ```
 * const gender = 'm';
 * const genders = {
 *   Male: gender === 'm',
 *   Female: gender === 'f',
 * };
 *
 * switchIf(genders, 'Other'); // 'Male'
 * ```
 *
 * @param  {object} cases object with cases
 * @param  {any} [defaultCase=null] default case
 * @param  {boolean} [executable=false] option that says to execute option if it was choosen
 */
export function switchIf(cases, defaultCase = null, executable = false) {
  const value = chooseByValue(cases, defaultCase);

  return executable ? executeIfFunction(value) : value;
}

/**
 * Functional analogue of switch statement
 *
 * examples:
 * ```
 * const val = 10;
 * const default = 100;
 * const actions = {
 *   'RESET': 0,
 *   'INCREMENT': () => val + 1,
 *   'DECREMENT': () => val -1
 * };
 *
 * switchCase(actions, default, true)('INCREMENT'); // 11
 * switchCase(actions, default, true)('RESET'); // 0
 * switchCase(actions, default)('INCREMENT');  // () => { val + 1 }
 * switchCase(actions, default, true)('NOT_MATCHED'); // 100
 * ```
 *
 * @param  {object} cases object with cases
 * @param  {any} [defaultCase=null] default case
 * @param  {boolean} [executable=false] option that says to execute option if it was choosen
 */
export default function switchCase(
  cases,
  defaultCase = null,
  executable = false,
) {
  return (key) => {
    const value = chooseByKey(cases, defaultCase)(key);

    return executable ? executeIfFunction(value) : value;
  };
}

export function isObjectsEqualBy(obj1: any, obj2: any, props: string[] = []) {
  let o1 = obj1;
  let o2 = obj2;

  if (props.length) {
    o1 = pick(obj1, props);
    o2 = pick(obj2, props);
  }

  return isEqual(o1, o2);
}

export const isObject = (obj: any): boolean =>
  obj !== null && typeof obj === 'object';

export function setNestedObjectValues<T>(
  object: any,
  value: any,
  visited: any = new WeakMap(),
  response: any = {},
): T {
  // eslint-disable-next-line no-restricted-syntax
  for (const k of Object.keys(object)) {
    const val = object[k];
    if (isObject(val)) {
      if (!visited.get(val)) {
        visited.set(val, true);
        // In order to keep array values consistent for both dot path  and
        // bracket syntax, we need to check if this is an array so that
        // this will output  { friends: [true] } and not { friends: { "0": true } }
        response[k] = Array.isArray(val) ? [] : {};
        setNestedObjectValues(val, value, visited, response[k]);
      }
    } else {
      response[k] = value;
    }
  }

  return response;
}

export const getDurationHoursAndMins = (duration: any = 0) => {
  let value = duration;

  if (typeof duration === 'string') {
    value = toNumber(duration);
  }

  if (!value) return [0, 0];

  const min = value % 60;
  const hours = (value - min) / 60;
  return [hours, min];
};

export const getDefaultCutoffMins = (hours: number, min = 0) =>
  hours * 60 + min;

export const sortDateArray = (array: string[]) =>
  array.sort((a, b) => {
    if (moment(a).isAfter(moment(b))) {
      return 1;
    }
    if (moment(a).isBefore(moment(b))) {
      return -1;
    }
    return 0;
  });

export const getIsSelected = (selectedDays: string[], date: string | Date) =>
  selectedDays.some((day) => moment(date).isSame(day, 'day'));

export const correctFormatTime = (date: Moment) => {
  if (!date) return null;

  let result = date;

  if (!date.isValid) {
    result = moment(date);
  }

  return moment()
    .set({
      year: 1900,
      month: 0,
      date: 1,
      hour: result.get('hour'),
      minute: result.get('minute'),
      second: 0,
      millisecond: 0,
    })
    .format();
};

export const getBearerToken = (token: string) => `bearer ${token}`;

export const formatCardLastDigits = (digits: string) => `xxxx-${digits}`;

export const formatDozen = (input: number) =>
  [input < 9 ? '0' : '', input].join('');

export const formatOrderPrimaryGuest = (firstName: string, lastName: string) =>
  [firstName, lastName].filter(Boolean).join(' ');

export const parseOrderPrimaryGuest = (primaryGuest: string) =>
  primaryGuest.split(' ').filter(Boolean);
