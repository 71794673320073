import React, { Component } from 'react';
import { Button, Popconfirm, Space, Table } from 'antd';
import intl from 'react-intl-universal';
import { Form, Formik, FormikProps } from 'formik';
import { identity } from 'lodash';

import {
  TableEditableColumnProps,
  TableEditableConfig,
} from 'models/table/tableEditableColumn.props';
import { getOnCellEditableProps } from 'utils/tableUtils';
import { stringSort } from '../../../../../utils/formatter';
import { UserStore } from '../../../../../stores/user.store';
import { UserModel } from '../../../../../models/account/user.model';
import { EditableCellInputEnum } from '../../../../../models/enums/editableCellInput.enum';
import TableEditableCell from '../../../../SharedComponents/TableEditableCell';
import { ROLES } from '../../../../../utils/constants';
import {
  isManagerUser,
  isStaffOrStaffAdmin,
  isSupplierAdmin,
} from '../../../../../utils/userRolesUtils';
import { withStore } from '../../../../../hocs';

type Props = {
  userStore?: UserStore;
  onActivateClick: (userName: string, inactive: boolean) => void;
};

type State = {
  editingRowKey: string;
  isEditLoading: boolean;
};

type RoleManagementCol = TableEditableColumnProps<UserModel>;

type FormValues = {
  name: string;
  email: string;
  roles: string[];
};

@withStore(({ rootStore }) => ({
  userStore: rootStore.userStore,
}))
class RoleManagementTable extends Component<Props, State> {
  private formRef = React.createRef<FormikProps<FormValues>>();

  state = {
    editingRowKey: null,
    isEditLoading: false,
  };

  handleEditClick = (record: UserModel) => {
    this.setState({ editingRowKey: record.UserName });

    this.formRef.current.setValues({
      name: record.UserName,
      email: record.Email,
      roles: record.Roles.map((role) => role.RoleId),
    });
  };

  handleCancelEdit = () => {
    this.setState({ editingRowKey: null });

    this.formRef.current.setValues(this.initialValues);
  };

  get initialValues(): FormValues {
    return {
      name: '',
      email: '',
      roles: [],
    };
  }

  isRowEditing = (record: UserModel) =>
    this.state.editingRowKey === record.UserName;

  getRoleOptions = () => {
    const { userStore } = this.props;
    const { userProperties } = userStore;

    const isRoleEditable = (role) => {
      let userGroup = null;

      if (isManagerUser(userProperties)) {
        userGroup = [ROLES.MANAGER.value, ROLES.AGENT.value];
      }

      if (isSupplierAdmin(userProperties)) {
        userGroup = [ROLES.SUPPLIER_CLERK.value, ROLES.SUPPLIER_ADMIN.value];
      }

      return userGroup
        ? userGroup.some((key) => key === role)
        : isStaffOrStaffAdmin(userProperties);
    };

    return Object.values(ROLES).map(({ name, value }) => ({
      name,
      value,
      disabled: !isRoleEditable(value),
    }));
  };

  getColumnEditableProps =
    (config: Partial<TableEditableConfig>) =>
    (_, record: UserModel): TableEditableConfig => ({
      isEnabled: config.isEnabled ?? true,
      isActive: config.isActive ?? this.isRowEditing(record),
      getValue: config.getValue ?? identity,
      ...config,
    });

  getColumns = () => {
    const { onActivateClick } = this.props;
    const { isEditLoading } = this.state;

    const columns: RoleManagementCol[] = [
      {
        key: 'user-name',
        title: intl.get('profile.roleManagement.name'),
        dataIndex: 'UserName',
        render: (userName) => <span className="f-w-6 f-s-14">{userName}</span>,
        sorter: stringSort('UserName'),
        align: 'left',
        edit: this.getColumnEditableProps({
          inputProps: {
            inputType: EditableCellInputEnum.Text,
            name: 'name',
          },
        }),
      },
      {
        key: 'email',
        title: intl.get('profile.roleManagement.accountEmail'),
        dataIndex: 'Email',
        render: (email) => <span className="f-w-5 f-s-13">{email}</span>,
        sorter: stringSort('Email'),
        align: 'left',
        edit: this.getColumnEditableProps({
          inputProps: {
            inputType: EditableCellInputEnum.Text,
            name: 'email',
          },
        }),
      },
      {
        key: 'inactive',
        title: intl.get('profile.roleManagement.accountStatus'),
        dataIndex: 'Inactive',
        render: (inactive: boolean) => (
          <span className="f-w-6 f-s-13">
            {inactive
              ? intl.get('profile.roleManagement.inactive')
              : intl.get('profile.roleManagement.active')}
          </span>
        ),
        align: 'left',
      },
      {
        key: 'roles',
        title: intl.get('profile.roleManagement.userRole'),
        dataIndex: 'Roles',
        render: (roles) => (
          <span className="f-w-6 f-s-13">{roles[0].RoleId}</span>
        ),
        align: 'left',
        width: '200px',
        edit: this.getColumnEditableProps({
          inputProps: {
            inputType: EditableCellInputEnum.Select,
            name: 'roles',
            mode: 'multiple',
            options: this.getRoleOptions(),
          },
        }),
      },
      {
        key: 'inactive',
        title: '',
        dataIndex: 'Inactive',
        render: (inactive: boolean, record) => (
          <Button
            type="primary"
            onClick={() => onActivateClick(record.UserName, record.Inactive)}
          >
            {inactive
              ? intl.get('profile.roleManagement.activate')
              : intl.get('profile.roleManagement.deactivate')}
          </Button>
        ),
        align: 'left',
        width: '135px',
      },
      {
        key: 'operations',
        title: '',
        dataIndex: '',
        width: '180px',
        render: (_, record) =>
          this.isRowEditing(record) ? (
            <Space>
              <Button
                type="link"
                htmlType="submit"
                loading={isEditLoading}
                disabled={isEditLoading}
              >
                {intl.get('buttons.save')}
              </Button>

              <Popconfirm
                placement="topRight"
                title={intl.get('labels.sureToCancel')}
                onConfirm={this.handleCancelEdit}
                okText={intl.get('buttons.yes')}
                cancelText={intl.get('buttons.no')}
              >
                <Button type="link" disabled={isEditLoading}>
                  {intl.get('buttons.cancel')}
                </Button>
              </Popconfirm>
            </Space>
          ) : (
            <Button type="link" onClick={() => this.handleEditClick(record)}>
              {intl.get('profile.roleManagement.edit')}
            </Button>
          ),
      },
    ];

    return columns.map((col) => ({
      ...col,
      onCell: (record) => getOnCellEditableProps(record, col),
    })) as RoleManagementCol[];
  };

  onSubmit = async (values: FormValues) => {
    const { userStore } = this.props;
    const { editingRowKey } = this.state;

    const user = userStore.userList.find(
      ({ UserName }) => UserName === editingRowKey,
    );

    const payload = {
      ...user,
      UserName: values.name,
      Email: values.email,
      Roles: values.roles.map((RoleId) => ({ RoleId })),
    };

    try {
      this.setState({ isEditLoading: true });

      await userStore.updateUserProperties(payload);

      this.setState({ isEditLoading: false, editingRowKey: null });
    } catch (e) {
      this.setState({ isEditLoading: false });
    }
  };

  render() {
    const { userStore } = this.props;
    const { userList, isUserListLoading } = userStore;

    return (
      <Formik
        innerRef={this.formRef}
        initialValues={this.initialValues}
        onSubmit={this.onSubmit}
      >
        <Form>
          <Table
            columns={this.getColumns()}
            dataSource={userList}
            rowKey={(user) => user.UserName}
            className="users-table"
            loading={isUserListLoading}
            components={{ body: { cell: TableEditableCell } }}
          />
        </Form>
      </Formik>
    );
  }
}

export default RoleManagementTable;
