import { BookingQuestionEnum } from './bookingQuestion.enum';

export class BookingQuestionTravelerForm {
  // dateOfBirth: string; // 15 September 2015
  // passengerHeight: string; // 158 cm
  // passportExpiryDate: string; // 15 September 2015
  // passportNationality: string; // Australia
  // passportNumber: string; // 9876543210
  // passengerWeights: number; // 145
  cardId: string;
  guestId: string;

  constructor(values, cardId, guestId) {
    this.cardId = cardId;
    this.guestId = guestId;

    // object values contain keys listed above
    Object.entries(values).forEach(([questionId, value]) => {
      if (questionId === BookingQuestionEnum.passengerHeights) {
        // eslint-disable-next-line no-param-reassign
        value = `${value}cm`;
      }

      this[questionId] = value;
    });
  }
}
