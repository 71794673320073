import React from 'react';
import { InputNumber, Button } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import {
  CloseOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
} from '@ant-design/icons';
import { isNil } from 'lodash';

type OwnProps = {
  className?: string;
  label?: string;
  value: number;
  isCleanable?: boolean;
};

type Props = OwnProps & InputNumberProps<number>;

export type InputNumberStepperProps = Props;

export default function InputNumberStepper(props: Props) {
  const {
    className,
    label,
    value,
    min = 0,
    isCleanable = true,
    onChange,
    onFocus,
    ...restProps
  } = props;

  const getNumberValue = (inputValue?: number) => Number(inputValue ?? 0);

  const parseValue = (inputValue: string) =>
    inputValue.includes('e') ? 1 : +inputValue;

  const handleIncrease = () => {
    const newValue = getNumberValue(value) + 1;

    onChange?.(newValue);
  };

  const handleDecrease = () => {
    const numberValue = getNumberValue(value) - 1;

    const newValue = !isNil(min) && numberValue < min ? min : numberValue;

    onChange?.(newValue);
  };

  const handleChange = (inputValue: number) => {
    const newValue = getNumberValue(inputValue);

    if (!isNil(min) && newValue < min) return;

    onChange?.(newValue);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();

    onFocus?.(e);
  };

  const handleClear = () => onChange?.(undefined);

  return (
    <div className={className}>
      {label && <h4>{label}</h4>}

      <div className="number-stepper d-f js-c ai-c">
        <Button type="link" className="p-h-5" onClick={handleDecrease}>
          <MinusCircleTwoTone className="f-s-24" />
        </Button>

        <InputNumber
          parser={parseValue}
          onChange={handleChange}
          value={value}
          type="number"
          addonAfter={
            isCleanable && (
              <Button
                type="link"
                size="small"
                icon={<CloseOutlined />}
                onClick={handleClear}
              />
            )
          }
          onFocus={handleFocus}
          {...restProps}
        />

        <Button type="link" className="p-h-5" onClick={handleIncrease}>
          <PlusCircleTwoTone className="f-s-24" />
        </Button>
      </div>
    </div>
  );
}
