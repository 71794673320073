import React, { Fragment, useMemo } from 'react';
import { FormikProps, useFormikContext } from 'formik';
import { get, isEmpty, isNumber } from 'lodash';
import classnames from 'classnames';
import { Resizable, ResizableProps } from 'react-resizable';
import 'react-resizable/css/styles.css';

import TableEditableCell from 'components/SharedComponents/TableEditableCell';
import { BulkCommunicationFormValuesModel } from 'models/bulkCommunicationFormValues.model';
import { TableEditableCellProps } from 'models/table/tableEditableCell.props';

type OwnProps = {
  className?: string;
  formikProps: FormikProps<BulkCommunicationFormValuesModel>;
} & ResizableProps;

type Props = TableEditableCellProps & OwnProps;

function TableOrdersCell(props: Props) {
  const { record, dataIndex, children, width, onResize, ...restProps } = props;

  const { values, errors } =
    useFormikContext<BulkCommunicationFormValuesModel>();

  const hasError = useMemo(() => {
    if (isEmpty(values.selectedOrders)) return false;

    const index = values.selectedOrders.findIndex(
      (selectedOrder) => record?.key === selectedOrder.key,
    );

    if (index === -1) return false;

    return get(errors.selectedOrders, [index, dataIndex]);
  }, [values, errors]);

  const ResizableContainer = isNumber(width) ? Resizable : Fragment;

  return (
    <ResizableContainer
      width={width}
      height={0}
      maxConstraints={[600, 600]}
      onResize={onResize}
    >
      <TableEditableCell record={record} dataIndex={dataIndex} {...restProps}>
        <div
          className={classnames('d-f ai-c', {
            'error-bordered': hasError,
          })}
        >
          {children}
        </div>
      </TableEditableCell>
    </ResizableContainer>
  );
}

export default TableOrdersCell;
