import React from 'react';
import Handlebars from 'handlebars/dist/cjs/handlebars';

import { OrderTableDataModel } from '../../../../models/orders/orderTableData.model';

type Props = {
  input: string;
  model: OrderTableDataModel;
};

const OrdersCommunicationEmailTemplate = (props: Props) => {
  const { input, model } = props;

  const render = Handlebars.compile(input);

  const html = render(model);

  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default OrdersCommunicationEmailTemplate;
