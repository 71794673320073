import React from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Col, Row } from 'antd';
import intl from 'react-intl-universal';
import { noop, uniqWith } from 'lodash';
import moment from 'moment';

import { withStore } from 'hocs';
import { SelectContainer } from 'components/FormContainers';
import { InventoryStore } from 'stores/inventory.store';
import FormikAutoSummit from 'components/SharedComponents/FormikAutoSummit';
import { ProductAvailabilityFiltersFormModel } from 'models/inventory/productAvailabilityFiltersForm.model';
import { getRateCategoryTimes, getUniqRates } from 'utils/rateUtils';
import { ProductCalendarFiltersFormSchema } from '../../../schemas';

type Props = {
  innerRef?: React.Ref<FormikProps<ProductAvailabilityFiltersFormModel>>;
  inventoryStore?: InventoryStore;
  onChange: (values: ProductAvailabilityFiltersFormModel) => void;
};

function ProductDetailsCalendarFilters(props: Props) {
  const { innerRef, inventoryStore, onChange } = props;

  const productRates = inventoryStore.productDetails.Rates;
  const uniqProductRates = getUniqRates(productRates);
  const categoriesOptions = uniqProductRates.map((rate) => ({
    label: rate.CategoryName,
    value: rate.CategoryId,
  }));

  const getTimesOptions = (selectedCategories?: string[]) => {
    const rates = productRates
      .filter(
        (rate) =>
          !selectedCategories ||
          selectedCategories.some(
            (selectedCategory) =>
              !selectedCategory || rate.CategoryId === selectedCategory,
          ),
      )
      .map((rate) => rate.ServiceTime)
      .filter(Boolean);

    const times = uniqWith(rates, (currentValue, otherValue) =>
      moment(currentValue).isSame(otherValue),
    );

    return times.map((time) => ({
      label: moment(time).format('h:mm A'),
      value: moment(time).toISOString(),
    }));
  };

  function appendAllOption<T>(options: T[]) {
    return [
      {
        label: intl.get('labels.all'),
        value: '',
      },
      ...options,
    ];
  }

  const onCategoriesChange =
    (formikProps: FormikProps<ProductAvailabilityFiltersFormModel>) =>
    async (input: string[]) => {
      const { values, setFieldValue } = formikProps;
      const newCategories = getDirevedSelectValue(input, allCategories);

      await setFieldValue('categories', newCategories);

      const categoriesTimes = newCategories.flatMap((category) =>
        getRateCategoryTimes(category, productRates),
      );
      const relevantTimes = values.times.filter((time) =>
        categoriesTimes.some((categoriesTime) => categoriesTime.isSame(time)),
      );

      await setFieldValue('times', relevantTimes);
    };

  const onTimesChange =
    (formikProps: FormikProps<ProductAvailabilityFiltersFormModel>) =>
    async (input: string[]) => {
      const { setFieldValue } = formikProps;

      await setFieldValue('times', getDirevedSelectValue(input, allTimes));
    };

  const getDirevedSelectValue = (input: string[], options: string[]) =>
    input.indexOf('') === -1 ? input : options;

  const allTimesOptions = getTimesOptions();
  const allCategories = categoriesOptions.map((option) => option.value);
  const allTimes = allTimesOptions.map((option) => option.value);

  const initialValues: ProductAvailabilityFiltersFormModel = {
    categories: allCategories,
    times: allTimes,
  };

  return (
    <Formik
      validateOnChange
      innerRef={innerRef}
      validationSchema={ProductCalendarFiltersFormSchema}
      initialValues={initialValues}
      onSubmit={noop}
    >
      {(formikProps) => (
        <Form>
          <FormikAutoSummit onSubmit={onChange} />

          <Row gutter={10}>
            <Col>
              <Field
                allowClear
                mode="multiple"
                name="categories"
                style={{ width: 250 }}
                className="m-0"
                maxTagCount="responsive"
                label={intl.get('inventory.products.popup.category')}
                options={appendAllOption(categoriesOptions)}
                component={SelectContainer}
                onChange={onCategoriesChange(formikProps)}
              />
            </Col>

            <Col>
              <Field
                allowClear
                mode="multiple"
                name="times"
                style={{ width: 250 }}
                className="m-0"
                maxTagCount="responsive"
                label={intl.get('inventory.products.popup.time')}
                options={appendAllOption(
                  getTimesOptions(formikProps.values.categories),
                )}
                component={SelectContainer}
                onChange={onTimesChange(formikProps)}
              />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
}))(ProductDetailsCalendarFilters);
