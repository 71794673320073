import React from 'react';
import { Button, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Field, FormikValues, useFormikContext } from 'formik';
import intl from 'react-intl-universal';

import { getVisibilityIcon } from 'utils/rateUtils';
import { CheckboxContainer } from 'components/FormContainers';

type Props = {
  index: number;
  onDelete: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

function ProductDescriptionPanelActions(props: Props) {
  const { index, onDelete } = props;

  const { values } = useFormikContext<FormikValues>();

  return (
    <Space size={10}>
      {getVisibilityIcon(values.Media[index].Visibility)}

      <div onClick={(e) => e.stopPropagation()}>
        <Field
          invertValue
          label={intl.get('inventory.products.popup.active')}
          className="m-0"
          name={`Media.${index}.Inactive`}
          component={CheckboxContainer}
        />
      </div>

      <Button icon={<DeleteOutlined />} onClick={onDelete} />
    </Space>
  );
}

export default ProductDescriptionPanelActions;
