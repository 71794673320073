import React, { useMemo, useRef } from 'react';
import { Button, Modal } from 'antd';
import { Formik, Form, Field, FormikProps } from 'formik';
import intl from 'react-intl-universal';
import * as Yup from 'yup';
import { CheckOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';

import { InputContainer } from '../../../../../FormContainers';
import { PreviewFile } from './ProductUpload';
import EditProductUploadDetails from './EditProductUploadDetails';
import { bytesToSize } from '../../../../../../utils/formatter';

interface FormValues {
  caption: string;
}

const validationSchema = Yup.object().shape({
  caption: Yup.string().max(250).required('required'),
});

type Props = {
  previewFile: PreviewFile;
  maxSize: number;
  onCancel?: () => void;
  onSubmit?: (values: FormValues) => void;
  beforeCrop: (file: File, fileList: File[]) => boolean;
  onEditImage: (file: UploadFile) => void;
};

function ProductUploadDetails(props: Props) {
  const { previewFile, maxSize, beforeCrop, onCancel, onSubmit, onEditImage } =
    props;
  const formRef = useRef<FormikProps<FormValues>>();

  const maxSizeLabel = bytesToSize(maxSize);
  const initialValues: FormValues = useMemo(
    () => ({
      caption: previewFile?.caption,
    }),
    [previewFile],
  );

  const CAPTION_LABEL = intl.get(
    'SUPPLIER.PRODUCT_DETAILS.IMAGES_SECTION.FORM.CAPTION_LABEL',
  );

  const error =
    previewFile.size > maxSize
      ? intl.get('SUPPLIER.PRODUCT_DETAILS.IMAGES_SECTION.FILE_TOO_LARGE', {
          maxSize: maxSizeLabel,
        })
      : null;

  const handleCheckClick = () => formRef?.current?.submitForm();

  const handleSubmit = (values: FormValues) => {
    onSubmit?.(values);
  };

  return (
    <Modal
      visible
      title={
        <div className="d-f jc-sb ai-c p-r-30">
          <div>{previewFile?.name}</div>

          <Button
            icon={<CheckOutlined />}
            size="small"
            type="link"
            disabled={Boolean(error)}
            onClick={handleCheckClick}
          />
        </div>
      }
      footer={null}
      wrapClassName="full-modal p-40"
      onCancel={onCancel}
    >
      <div className="w-100 product-uploader-modal p-40">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <Field
                name="caption"
                component={InputContainer}
                label={CAPTION_LABEL}
              />
            </Form>
          )}
        </Formik>

        <div className="field">
          <h4 className="field-label">
            {intl.get('SUPPLIER.PRODUCT_DETAILS.IMAGES_SECTION.IMAGE', {
              maxSize: maxSizeLabel,
            })}
          </h4>

          <EditProductUploadDetails
            previewFile={previewFile}
            beforeCrop={beforeCrop}
            onEditImage={onEditImage}
          />

          {error && <div className="field-error visible">{error}</div>}
        </div>

        {previewFile && !error && (
          <img
            alt={previewFile.name}
            style={{ width: '100%' }}
            src={previewFile.thumbUrl}
          />
        )}
      </div>
    </Modal>
  );
}

export default React.memo(ProductUploadDetails);
