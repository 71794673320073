import React, { useCallback, useState } from 'react';
import intl from 'react-intl-universal';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Collapse, Button } from 'antd';
import { isNil } from 'lodash';
import { Element } from 'react-scroll';
import { v4 as uuid } from 'uuid';

import { FormMediaModel } from 'models/inventory/formMedia.model';
import { ProductFormValuesModel } from '../../../../../../models/inventory/productFormValues.model';
import ProductRateAvailabilityFilters from './ProductRateAvailabilityFilters';
import { VisibilityEnum } from '../../../../../../models/enums/visibility.enum';
import ProductDescriptionPanel from './ProductDescriptionPanel';
import { withStore } from '../../../../../../hocs';
import { CommonStore } from '../../../../../../stores/common.store';
import { ProductSectionKeyEnum } from '../../../../../../models/enums/productSectionKey.enum';

type Props = {
  extraData: any;
  commonStore?: CommonStore;
};

function ProductDescriptionInfo(props: Props) {
  const { extraData, commonStore } = props;

  const { values } = useFormikContext<ProductFormValuesModel>();

  const { Media: media, mediaFilters } = values;
  const mainMediaSection = commonStore.settings.MainMediaSection;

  const [activePanel, setActivePanel] = useState([media.at(-1)?.key]);

  const isAllExpanded = activePanel.length === media.length;

  const TOGGLE_BUTTON_NAME = isAllExpanded
    ? intl.get('inventory.products.popup.collapseAll')
    : intl.get('inventory.products.popup.expandAll');

  const onAddDescription = (arrayHelpers) => () => {
    const newMedia: FormMediaModel = {
      CategoryId: '',
      FormattedText: '',
      Section: undefined,
      Visibility: mediaFilters.visibility ?? null,
      LanguageId: null,
      AgencyId: mediaFilters.agencyId ?? null,
      key: uuid(),
    };

    copyMainSectionImages(newMedia.key);

    arrayHelpers.push(newMedia);

    setActivePanel([newMedia.key.toString()]);
  };

  const copyMainSectionImages = (targetMediaKey: string) => {
    const section = media.find(({ Section }) => Section === mainMediaSection);

    if (!section) return;

    extraData.copySectionImages(section.key, targetMediaKey);
  };

  const onRemove =
    (key: string, arrayHelpers: FieldArrayRenderProps) => (index: number) => {
      arrayHelpers.remove(index);

      extraData.removeSectionImages(key);
    };

  const onToggleAllPanels = () => {
    setActivePanel(isAllExpanded ? [] : media.map(({ key }) => key));
  };

  const getCategoriesOptions = () =>
    [
      {
        label: intl.get('inventory.products.popup.allOptions'),
        value: '',
      },
    ].concat(
      values.Rates.filter((rate) => rate.CategoryId).map((rate) => ({
        label: rate.CategoryName,
        value: rate.CategoryId,
      })),
    );

  const getSections = useCallback(() => {
    const { agencyId, visibility } = mediaFilters;

    return media.filter(
      (section) =>
        (isNil(visibility) || visibility === section.Visibility) &&
        (!agencyId ||
          visibility !== VisibilityEnum.Negotiated ||
          agencyId === section.AgencyId),
    );
  }, [mediaFilters, media]);

  return (
    <Element name={ProductSectionKeyEnum.Information} className="row">
      <div className="column title">
        {intl.get('inventory.products.popup.information')}
      </div>

      <div className="column content">
        <div className="d-f f-d-column w-100">
          <ProductRateAvailabilityFilters
            name="mediaFilters"
            filterAgencyOptions={(option) =>
              values.Media.some(({ AgencyId }) => AgencyId === option.value)
            }
          />

          <Button
            type="link"
            size="small"
            className="m-b-10 as-fe"
            onClick={onToggleAllPanels}
          >
            {TOGGLE_BUTTON_NAME}
          </Button>

          <FieldArray name="Media">
            {(arrayHelpers) => (
              <>
                <Collapse
                  activeKey={activePanel}
                  className="w-100 m-b-15"
                  onChange={(key) => setActivePanel(key as string[])}
                >
                  {getSections().map((section, index) => (
                    <ProductDescriptionPanel
                      key={section.key}
                      index={index}
                      extraData={extraData}
                      categoriesOptions={getCategoriesOptions()}
                      onRemove={onRemove(section.key, arrayHelpers)}
                    />
                  ))}
                </Collapse>

                {isNil(mediaFilters.visibility) && (
                  <Button
                    type="primary"
                    onClick={onAddDescription(arrayHelpers)}
                  >
                    {intl.get('inventory.products.popup.addInformation')}
                  </Button>
                )}
              </>
            )}
          </FieldArray>
        </div>
      </div>
    </Element>
  );
}

export default withStore(({ rootStore }) => ({
  commonStore: rootStore.commonStore,
}))(ProductDescriptionInfo);
