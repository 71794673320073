import React from 'react';
import { Popconfirm, Space, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';

type Props = {
  isEditing: boolean;
  isLoading: boolean;
  isDisabled?: boolean;
  onDetails: () => void;
  onEdit: () => void;
  onCancel: () => void;
  onSubmit: () => void;
};

function TableOrdersOperations(props: Props) {
  const {
    isEditing,
    isLoading,
    isDisabled,
    onDetails,
    onEdit,
    onCancel,
    onSubmit,
  } = props;

  return (
    <div style={{ whiteSpace: 'nowrap' }} onClick={(e) => e.stopPropagation()}>
      {isEditing ? (
        <Space>
          <Typography.Link onClick={onSubmit}>
            {isLoading ? <LoadingOutlined /> : intl.get('buttons.save')}
          </Typography.Link>

          <Popconfirm
            placement="topRight"
            disabled={isLoading}
            title={intl.get('labels.sureToCancel')}
            onConfirm={onCancel}
            okText={intl.get('buttons.yes')}
            cancelText={intl.get('buttons.no')}
          >
            <Typography.Link>{intl.get('buttons.cancel')}</Typography.Link>
          </Popconfirm>
        </Space>
      ) : (
        <Space>
          <Typography.Link onClick={onDetails}>
            {intl.get('buttons.details')}
          </Typography.Link>

          <Typography.Link disabled={isDisabled} onClick={onEdit}>
            {intl.get('buttons.edit')}
          </Typography.Link>
        </Space>
      )}
    </div>
  );
}

export default TableOrdersOperations;
