/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';

import { InputContainer } from 'components/FormContainers';
import { TableOrdersListFilterSchema } from '../../schemas';
import { TableOrdersListFilterPropsModel } from '../../models';
import TableOrdersGenericListFilter from './TableOrdersBaseListFilter';

function TableOrdersListFilter(props: TableOrdersListFilterPropsModel) {
  const { inputProps, ...restProps } = props;

  const getIsSearchValueMatched = (
    value: string,
    label: string,
    searchValue: string,
  ) =>
    !searchValue ||
    label?.toLowerCase().includes(searchValue.trim().toLowerCase());

  return (
    <TableOrdersGenericListFilter
      getIsSearchValueMatched={getIsSearchValueMatched}
      validationSchema={TableOrdersListFilterSchema}
      getSearchContainer={(fieldProps) => (
        <InputContainer
          prefix={<SearchOutlined />}
          placeholder={intl.get('orders.table.searchInFilters')}
          {...inputProps}
          {...fieldProps}
        />
      )}
      {...restProps}
    />
  );
}

export default TableOrdersListFilter;
