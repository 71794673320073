import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Slider, InputNumber, Popover } from 'antd';

import { TourStore } from '../../../../stores/tour.store';
import {
  MarketplaceStore,
  minPriceDefault,
  maxPriceDefault,
} from '../../../../stores/marketplace.store';
import { withStore } from '../../../../hocs';

@withStore(({ rootStore }) => ({
  tourStore: rootStore.tourStore,
  marketplaceStore: rootStore.marketplaceStore,
}))
export default class TourFiltersModule extends Component<{
  tourStore?: TourStore;
  marketplaceStore?: MarketplaceStore;
}> {
  state = {
    minPrice: minPriceDefault,
    maxPrice: maxPriceDefault,
  };

  componentDidMount() {
    const {
      marketplaceStore: { minPrice, maxPrice },
    } = this.props;

    this.setState({
      minPrice,
      maxPrice,
    });
  }

  handleSliderChange = (values: any) => {
    this.setState({
      minPrice: values[0],
      maxPrice: values[1],
    });
  };

  handleAfterSliderChange = (values: any) => {
    const { marketplaceStore } = this.props;

    marketplaceStore.setPriceBoundaries(values);
  };

  handleLocalMinPriceChange = (value) => {
    this.setState({
      minPrice: value,
    });
  };

  handleGlobalMinPriceChange = () => {
    const { marketplaceStore } = this.props;
    const { minPrice } = this.state;

    marketplaceStore.setMinPrice(minPrice);
  };

  handleLocalMaxPriceChange = (value) => {
    this.setState({
      maxPrice: value,
    });
  };

  handleGlobalMaxPriceChange = () => {
    const { marketplaceStore } = this.props;
    const { maxPrice } = this.state;

    marketplaceStore.setMaxPrice(maxPrice);
  };

  handleClearClick = () => {
    const { marketplaceStore, tourStore } = this.props;

    marketplaceStore.clearFilters();
    tourStore.setDepartureDate(undefined);
    this.setState({
      minPrice: minPriceDefault,
      maxPrice: maxPriceDefault,
    });
  };

  renderPrice = () => {
    const { minPrice, maxPrice } = this.state;

    return (
      <div>
        <div>
          <Slider
            range
            min={minPriceDefault}
            max={maxPriceDefault}
            defaultValue={[0, 500]}
            onChange={this.handleSliderChange}
            onAfterChange={this.handleAfterSliderChange}
            value={[minPrice, maxPrice]}
            className="price-slider"
          />
        </div>
        <div className="tour-filter-price-inputs">
          <div>
            <InputNumber
              min={minPriceDefault}
              max={maxPriceDefault}
              value={minPrice}
              onChange={this.handleLocalMinPriceChange}
              onBlur={this.handleGlobalMinPriceChange}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => {
                const parsedValue = +value
                  .replace(/\$\s?|(,*)/g, '')
                  .replace(/\D/g, '');

                return parsedValue < maxPriceDefault
                  ? parsedValue
                  : maxPriceDefault;
              }}
            />
          </div>
          <div>
            <InputNumber
              min={minPriceDefault}
              max={maxPriceDefault}
              value={maxPrice}
              onChange={this.handleLocalMaxPriceChange}
              onBlur={this.handleGlobalMaxPriceChange}
              formatter={(value) =>
                +value !== +maxPriceDefault
                  ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : `$ ${value}+`
              }
              parser={(value) => {
                const parsedValue = +value
                  .replace(/\$\s?|(,*)/g, '')
                  .replace(/\D/g, '');

                return parsedValue < maxPriceDefault
                  ? parsedValue
                  : maxPriceDefault;
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { minPrice, maxPrice } = this.state;

    return (
      <div className="tours-filters">
        <Popover
          placement="bottomLeft"
          content={this.renderPrice()}
          trigger="click"
          overlayClassName="tour-filter-price"
        >
          <div className="general-input-container filter-price">
            <span>
              {`${intl.get('marketplacePage.price')}: $${minPrice}-${
                maxPrice === maxPriceDefault ? `${maxPrice}+` : maxPrice
              }`}
            </span>
            <i className="tc-dropdown" />
          </div>
        </Popover>
        <div className="filter-clear-button">
          <span onClick={this.handleClearClick}>
            {intl.get('marketplacePage.clearFilters')}
          </span>
        </div>
      </div>
    );
  }
}
