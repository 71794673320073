import React, { Component } from 'react';
import { Alert } from 'antd';

import { MessageModel } from '../../../models/global-messages/message.model';
import { GlobalMessagesStore } from '../../../stores/globalMessages.store';
import { withStore } from '../../../hocs';

@withStore(({ rootStore }) => ({
  globalMessagesStore: rootStore.globalMessagesStore,
}))
export default class GlobalMessages extends Component<{
  globalMessagesStore?: GlobalMessagesStore;
}> {
  render() {
    const {
      globalMessagesStore: { messages },
    } = this.props;

    return (
      <div className="global-messages">
        {messages.map((message: MessageModel, index: number) => (
          <Alert
            key={`${message.message}-${index}`}
            message={message.message}
            description={message.description}
            type={message.type}
            closable={
              message.hasOwnProperty('closable') ? message.closable : true
            }
            showIcon={
              message.hasOwnProperty('showIcon') ? message.showIcon : false
            }
            className="global-message"
          />
        ))}
      </div>
    );
  }
}
