import React from 'react';
import { Field, Form, Formik, FormikConfig } from 'formik';
import intl from 'react-intl-universal';
import { Button } from 'antd';
import IMask from 'imask';
import * as Yup from 'yup';

import Accessor from 'components/SharedComponents/Accessor';
import { USER_ROLE_GROUPS } from '../../../../utils/constants';
import {
  CheckboxContainer,
  InputContainer,
  SelectContainer,
} from '../../../FormContainers';
import { FinanceInfoModel } from '../../../../models/financeInfo.model';
import { OrderCancellationFormModel } from '../../../../models/orders/orderCancellationForm.model';
import { OrderCancellationFormValuesEnum } from '../../../../models/enums/OrderCancellationFormValues.enum';
import {
  creditCardValidator,
  expirationDateExpiredValidator,
  expirationDateFormatValidator,
} from '../../../../utils/validation/validators';

type Props = {
  financeInfo: FinanceInfoModel;
  isPenaltyVisible: boolean;
  isCardVisible: boolean;
  onSubmit: FormikConfig<OrderCancellationFormModel>['onSubmit'];
  onCancel: () => void;
};

function OrderCancellationForm(props: Props) {
  const { isPenaltyVisible, isCardVisible, financeInfo, onSubmit, onCancel } =
    props;

  const cardOptions = [
    {
      label: intl.get('newCreditCard'),
      value: OrderCancellationFormValuesEnum.NewCreditCard,
    },
    ...financeInfo.Profiles.map((profile) => ({
      label: profile.Description,
      value: profile.ProfileID,
    })),
  ];

  const initialValues: OrderCancellationFormModel = {
    isCardVisible,
    isCancelWithoutPenalty: false,
    creditCard:
      isCardVisible && cardOptions.length === 1
        ? OrderCancellationFormValuesEnum.NewCreditCard
        : undefined,
    cardNumber: '',
    expirationDate: '',
    cvv: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        isCancelWithoutPenalty: Yup.boolean(),
        creditCard: Yup.string().when('isCardVisible', {
          is: (visible) => visible,
          then: Yup.string().required(intl.get('required')),
        }),
        cardNumber: Yup.string().when('creditCard', {
          is: (creditCard) =>
            creditCard === OrderCancellationFormValuesEnum.NewCreditCard,
          then: Yup.string()
            .required(intl.get('required'))
            .test(creditCardValidator(intl.get('invalidCreditCardValidator'))),
        }),
        expirationDate: Yup.string().when('creditCard', {
          is: (creditCard) =>
            creditCard === OrderCancellationFormValuesEnum.NewCreditCard,
          then: Yup.string()
            .required(intl.get('required'))
            .test(expirationDateFormatValidator(intl.get('invalidFormat')))
            .test(
              expirationDateExpiredValidator(intl.get('creditCardExpired')),
            ),
        }),
        cvv: Yup.string().when('creditCard', {
          is: (creditCard) =>
            creditCard === OrderCancellationFormValuesEnum.NewCreditCard,
          then: Yup.string()
            .required(intl.get('required'))
            .min(3, intl.get('invalidFormat')),
        }),
      })}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <Form>
          {isPenaltyVisible && (
            <Accessor
              roles={[...USER_ROLE_GROUPS.SUPPLIER, ...USER_ROLE_GROUPS.STAFF]}
            >
              <Field
                name="isCancelWithoutPenalty"
                component={CheckboxContainer}
                label={intl.get('orders.cancellation.withoutPenalty')}
              />
            </Accessor>
          )}

          {isCardVisible && (
            <>
              <span className="cancellation-order-description">
                {intl.get('orders.cancellation.unableRefund')}
              </span>

              <Field
                name="creditCard"
                placeholder={intl.get('selectCreditCard')}
                component={SelectContainer}
                options={cardOptions}
              />

              {values.creditCard ===
                OrderCancellationFormValuesEnum.NewCreditCard && (
                <>
                  <Field
                    name="cardNumber"
                    component={InputContainer}
                    placeholder={intl.get('labels.cardNumber')}
                    mask="0000-0000-0000-0000-000"
                  />
                  <Field
                    name="expirationDate"
                    component={InputContainer}
                    placeholder={intl.get('labels.expirationDate')}
                    mask="MM/YY"
                    maskOptions={{
                      blocks: {
                        YY: {
                          mask: '00',
                        },
                        MM: {
                          mask: IMask.MaskedRange,
                          from: 1,
                          to: 12,
                        },
                      },
                    }}
                  />
                  <Field
                    name="cvv"
                    component={InputContainer}
                    placeholder={intl.get('labels.cvv')}
                    mask="0000"
                  />
                </>
              )}
            </>
          )}

          <div className="cancellation-order-buttons">
            <div>
              <Button size="large" htmlType="submit" className="cancel-button">
                {intl.get('buttons.yes')}
              </Button>

              <Button size="large" className="back-button" onClick={onCancel}>
                {intl.get('buttons.no')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default OrderCancellationForm;
