import * as Yup from 'yup';

import { MessageTemplateFormModel } from 'models/messageTemplateForm.model';
import { jsonValidator, validateHandlebar } from '../validators';

export const messageTemplateSchema = Yup.object().shape({
  description: Yup.string().required('Required'),
  subject: Yup.string(),
  smsTemplate: Yup.string().test(
    'is-sms-template-valid',
    '',
    function validateTemplate(value: string) {
      const model = JSON.parse(this.parent.previewModel);
      const validationError = validateHandlebar(value, model);
      const message = value ? validationError : 'Required';

      return message ? this.createError({ message }) : true;
    },
  ),
  previewModel: Yup.string().test(jsonValidator('Invalid JSON')),
  modelType: Yup.string(),
  orderId: Yup.string(),
  emailTemplate: Yup.object().test(
    'is-email-template-valid',
    '',
    function validateTemplate(
      value: MessageTemplateFormModel['emailTemplate'],
    ) {
      const { html } = value;
      const model = JSON.parse(this.parent.previewModel);
      const validationError = validateHandlebar(html, model);
      const message = html ? validationError : 'Required';

      return message ? this.createError({ message }) : true;
    },
  ),
});
