import { Location } from 'react-router-dom';
import { SortOrder } from 'antd/lib/table/interface';

import { TableQueryBuilder } from './tableQueryBuilder';
import { parseQueryString } from './urlUtils';

export class TableQueryParser {
  constructor(private location: Location) {}

  private parseSearch() {
    return parseQueryString(this.location.search);
  }

  private parseFilterQuery(query = '') {
    const [key, value] = query.split('=');

    return {
      key,
      value: value?.split(',').map((filter) => filter || undefined),
    };
  }

  private parseSortQuery(query = '') {
    const key = query.replace('-', '');
    const value = query.startsWith('-') ? 'descend' : 'ascend';

    return { key, value };
  }

  private parseQuery(
    query: string,
    delimiter: string,
    parser: (query?: string) => { key: string; value: string | string[] },
  ) {
    if (!query) return {};

    return query.split(delimiter).reduce((acc, filter) => {
      const { key, value } = parser(filter);

      return Object.assign(acc, { [key]: value });
    }, {});
  }

  public parseFilters() {
    const query = (this.parseSearch().filters as string) ?? '';
    const parser = this.parseFilterQuery;

    return this.parseQuery(query, TableQueryBuilder.FILTERS_DELIMITER, parser);
  }

  public parseSorts() {
    const query = (this.parseSearch().sorts as string) ?? '';
    const parser = this.parseSortQuery;

    return this.parseQuery(query, TableQueryBuilder.SORTS_DELIMITER, parser);
  }

  public parseFieldSort(field: string): SortOrder {
    return this.parseSorts()[field];
  }

  public parseFieldFilter(field: string) {
    return this.parseFilters()[field];
  }
}
