import React from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';

import { uniq } from 'lodash';
import { getIsSelected } from '../../../../../../utils/formatter';
import { EventModel } from '../../../../../../models/inventory/event.model';
import { OrderTabsEnum } from '../../../../../../models/enums/orderTabs.enum';
import { TableQueryBuilder } from '../../../../../../utils/tableQueryBuilder';
import { OrderTableDataModel } from '../../../../../../models/orders/orderTableData.model';
import { OrderKeysEnum } from '../../../../../../models/enums/orderKeys.enum';

type CalendarCellHeaderProps = {
  date: Date;
  label: string;
  events: EventModel[];
  selectedDays: string[];
};

const CalendarCellHeader: React.FC<CalendarCellHeaderProps> = React.memo(
  (props) => {
    const { events, selectedDays, date, label } = props;

    const event = events.find(({ start }) =>
      moment(date).isSame(moment(start), 'd'),
    );

    const eventQuantity = event?.options.reduce(
      (acc, eventOption) => acc + eventOption.BookedCount,
      0,
    );

    const isSelectedDate = getIsSelected(selectedDays, date);
    const isToday = moment(date).isSame(moment(), 'd');

    const getOrdersLink = () => {
      const formattedDate = moment(date).toISOString();
      const bookedOptions = event.options.filter(
        (option) => option.BookedCount,
      );

      const initialQuery = {
        page: 1,
        orderTab: OrderTabsEnum.All,
      };

      const queryBuilder = new TableQueryBuilder<OrderTableDataModel>(
        initialQuery,
      );

      const filters = {
        [OrderKeysEnum.CategoryId]: uniq(
          bookedOptions.map((option) => option.CategoryId),
        ),
        [OrderKeysEnum.ProductId]: uniq(
          bookedOptions.map((option) => option.ProductId),
        ),
        [OrderKeysEnum.StartDate]: [formattedDate, formattedDate],
      };

      return `/orders?${queryBuilder.setFilters(filters).build()}`;
    };

    return (
      <div className="d-f jc-sb">
        <div
          className="m-t-1 m-l-1 p-h-5 flx-1"
          style={{
            backgroundColor: isSelectedDate ? '#4a90e2' : 'transparent',
          }}
        >
          <span
            className="f-s-16 f-w-5"
            style={{ color: isSelectedDate ? 'white' : 'rgba(0,0,0,.85)' }}
          >
            {label}
          </span>
        </div>
        <div className="p-h-5 p-t-4 d-f text-left flx-4 f-s-13">
          {isToday && intl.get('productPage.today')}
        </div>
        <div className="p-r-5 p-t-5 flx-1">
          {eventQuantity > 0 && (
            <a
              href={getOrdersLink()}
              target="_blank"
              className="d-f jc-fe"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <UserOutlined className="m-r-5 f-s-17" />
              <span className="f-s-14 f-w-5">{eventQuantity}</span>
            </a>
          )}
        </div>
      </div>
    );
  },
);

export default CalendarCellHeader;
