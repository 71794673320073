import React, { Component } from 'react';
import classnames from 'classnames';
import { FieldProps, getIn } from 'formik';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';

type OwnProps = {
  inputRef?: React.Ref<HTMLInputElement> | undefined;
  label?: string;
  className?: string;
  labelClassName?: string;
  tooltip?: string;
  defaultValue?: number;
  onChange?: (value: number) => void;
};

export type InputNumberContainerProps = InputNumberProps &
  FieldProps &
  OwnProps;

export default class InputNumberContainer extends Component<InputNumberContainerProps> {
  componentDidMount() {
    const {
      form: { setFieldValue },
      field: { name },
      defaultValue,
    } = this.props;

    if (defaultValue !== undefined) {
      setFieldValue(name, defaultValue);
    }
  }

  handleChange = (value) => {
    const {
      form: { setFieldValue },
      field: { name },
      onChange,
    } = this.props;

    setFieldValue(name, value);

    onChange?.(value);
  };

  defaultParser = (value) => {
    const { min = 0 } = this.props;

    if (value.includes('e')) {
      return min;
    }

    return +value;
  };

  render() {
    const {
      field,
      form: { touched, errors },
      label,
      inputRef,
      className,
      tooltip,
      readOnly,
      parser,
      labelClassName,
      ...rest
    } = this.props;
    const fieldError: string = getIn(errors, field.name);
    const isTouched: boolean = getIn(touched, field.name);
    const hasError: boolean = fieldError && isTouched;

    return (
      <div
        className={classnames(
          'field',
          {
            'has-error': hasError,
            'ant-input-number__read-only': readOnly,
          },
          className,
        )}
      >
        {label && (
          <h4 className={classnames('field-label', labelClassName)}>
            {label} &nbsp;
            {tooltip && tooltip}
          </h4>
        )}
        <InputNumber
          {...field}
          {...rest}
          className={classnames('input-number-container-width')}
          type="number"
          readOnly={readOnly}
          onChange={this.handleChange}
          ref={inputRef}
          parser={parser || this.defaultParser}
        />
        {hasError && <div className="field-error visible">{fieldError}</div>}
      </div>
    );
  }
}
