import XLSX from 'xlsx';
import { ExcelReportModel } from '../../../models/reports/excelReport.model';

export class ExcelReportGenerator {
  public generateReport(report: ExcelReportModel) {
    const book: XLSX.WorkBook = XLSX.utils.book_new();
    const workSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
      report.workSheetData,
    );

    XLSX.utils.book_append_sheet(book, workSheet);

    XLSX.writeFile(book, `${report.fileName}.xlsx`);
  }
}
