import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  addToQueryString,
  omitFromQueryString,
  parseQueryString,
} from 'utils/urlUtils';

export function useQueryParamModal(param: string) {
  const { search } = useLocation();
  const navigate = useNavigate();

  const key = useMemo(() => ['modal', param].join('-'), [param]);

  const open = useCallback(
    () =>
      navigate({
        search: addToQueryString(search, { [key]: true }),
      }),
    [search, key],
  );

  const close = useCallback(
    () => navigate({ search: omitFromQueryString(search, [key]) }),
    [search, key],
  );

  const isOpen = useCallback(
    () => Boolean(parseQueryString(search)[key]),
    [search, key],
  );

  return { isOpen, open, close };
}
