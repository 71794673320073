import * as Yup from 'yup';
import { debounce } from 'lodash';

import { MessagesStore } from '../../../../stores/messages.store';

export const makeTestTableOrdersPhoneSchema = (
  messagesStore: MessagesStore,
) => {
  const DEBOUNCE_DELAY = 300;

  let cachedValid = false;
  let cachedValue = null;

  const validatePhoneNumber = async function test(
    value: string,
    ctx: Yup.TestContext,
  ) {
    if (!value) return true;

    if (value === cachedValue) return cachedValid;

    try {
      const result = await messagesStore.getPhoneResource(value);
      const [error] = result.validation_errors;
      const message = messagesStore.mapPhoneErrorToLabel(error);

      cachedValue = value;
      cachedValid = result.valid;

      return result.valid || ctx.createError({ message });
    } catch (e) {
      return false;
    }
  };

  const validatePhoneNumberDebounced = debounce(
    async (value, ctx, resolve) =>
      validatePhoneNumber(value, ctx).then(resolve),
    DEBOUNCE_DELAY,
  );

  return function testTableOrdersPhoneSchema(
    this: Yup.TestContext,
    value: string,
  ) {
    return new Promise<boolean | Yup.ValidationError>((resolve) => {
      validatePhoneNumberDebounced(value, this, resolve);
    });
  };
};
