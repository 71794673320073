import { TestOptions } from 'yup';
import moment, { Moment } from 'moment';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import cardValidator from 'card-validator';
import { get, isEqual, isNil, pick } from 'lodash';
import * as Yup from 'yup';

import { normalizeDate } from '../storeUtils';
import { AgeTypeEnum } from '../../models/enums/ageType.enum';
import { SupplierModel } from '../../models/contacts/supplier.model';

cardValidator.creditCardType.updateCard(
  cardValidator.creditCardType.types.VISA,
  {
    lengths: [13, 16, 18, 19],
  },
);

export const passwordPattern = /[!#$%&<=>_?]/; // /[!#$%&*+-<=>?@^_~]/;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const creditCardRegexp =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12})$/;

export function creditCardValidator(message: string): TestOptions {
  return {
    name: 'creditCardValidator',
    test: (value: string) => {
      if (!value) {
        return true;
      }
      const unformattedValue = value.replace(/-/g, '');
      const numberValidation = cardValidator.number(unformattedValue);

      return numberValidation.isValid;
    },
    message,
  };
}

export function expirationDateFormatValidator(message: string): TestOptions {
  return {
    name: 'expirationDateFormatValidator',
    test: (value: string) => {
      if (!value) {
        return true;
      }
      const [month, year] = value.split('/');

      if (!month || month.length !== 2 || !year || year.length !== 2) {
        return false;
      }

      return true;
    },
    message,
  };
}

export function expirationDateExpiredValidator(message: string): TestOptions {
  return {
    name: 'expirationDateExpiredValidator',
    test: (value: string) => {
      if (!value) {
        return true;
      }

      const [month, year] = value.split('/');
      const monthNumber = Number(month);
      const yearNumber = Number(year);

      if (!yearNumber || !monthNumber) {
        return true;
      }

      const expirationDate: Moment = normalizeDate(
        moment()
          .month(monthNumber - 1)
          .year(yearNumber + 2000)
          .date(1),
      );
      const currentDate: Moment = normalizeDate(moment().date(1));

      return currentDate <= expirationDate;
    },
    message,
  };
}

export const regularRateAgeMaxValidator = (message: string): TestOptions => ({
  name: 'regularRateAgeMaxValidator',
  message,
  test(value) {
    const {
      path,
      parent,
      options: { context },
    } = this;

    const ratePath = path.split('.').slice(0, -2).join('.');

    const regularRates = get(context, `values.${ratePath}.RegularRates`, []);
    const isRequiredValid = !isNil(value);

    if (
      parent.Type === AgeTypeEnum.Group ||
      parent.Type === AgeTypeEnum.Adult ||
      parent.Type === AgeTypeEnum.Single
    ) {
      return true;
    }

    if (parent.Type === AgeTypeEnum.Child) {
      return isNil(parent.AgeMin) || isRequiredValid;
    }

    if (parent.Type === AgeTypeEnum.Junior) {
      const childRegularRate = regularRates.find(
        (rate) => rate.Type === AgeTypeEnum.Child,
      );

      return isNil(childRegularRate?.AgeMax) || isRequiredValid;
    }

    return isRequiredValid;
  },
});

export const regularRateNetRateValidator = (message: string): TestOptions => ({
  name: 'regularRateNetRateValidator',
  message,
  test(value) {
    const {
      options: { context },
    } = this;
    const { operatorData } = context as { operatorData: SupplierModel };

    return !operatorData?.NetRateVisible || !isNil(value);
  },
});

export const validateHandlebar = (template: string, model: object) => {
  try {
    Handlebars.compile(template)(model);

    return '';
  } catch (error) {
    return error.message;
  }
};

export const testJSONValue = (value) => {
  try {
    JSON.parse(value);

    return true;
  } catch (e) {
    return false;
  }
};

export function jsonValidator(message: string): TestOptions {
  return {
    name: 'json-validator',
    test: (value) => testJSONValue(value),
    message,
  };
}

Yup.addMethod(
  Yup.object,
  'uniqueInArray',
  function getValidateUnique(
    path: string,
    fields: string[],
    message: Yup.TestOptionsMessage,
  ) {
    return this.test(
      'is-unique-in-array',
      message,
      function validateUnique(currentSection: object) {
        const pickFields = (section: object) =>
          pick(section, [path, ...fields]);

        const otherSections = (this.parent as object[]).filter(
          (section) => section !== currentSection,
        );

        const hasDuplicates = otherSections.some((section) =>
          isEqual(pickFields(section), pickFields(currentSection)),
        );

        return hasDuplicates
          ? this.createError({ path: [this.path, path].join('.') })
          : true;
      },
    );
  },
);

Yup.addMethod(
  Yup.string,
  'duplicatedIn',
  function getValidateDuplicateIn(
    field: string,
    message: Yup.TestOptionsMessage,
    compare: (thisValue: unknown, otherValue: unknown) => boolean,
  ) {
    return this.test(
      'is-duplicated-in',
      message,
      function validateUnique(thisValue: unknown) {
        return !(this.parent[field] as unknown[]).some((otherValue) =>
          compare(thisValue, otherValue),
        );
      },
    );
  },
);
