import { AxiosPromise } from 'axios';

import { SendNotificationModel } from '../models/notification/sendNotification.model';
import { NotificationHistoryModel } from '../models/notification/notificationHistory.model';
import { BaseApi } from './base.api';

export class NotificationsApi extends BaseApi {
  public sendNotifications(url: string, data: SendNotificationModel[]) {
    return this.axios.post(url, data);
  }

  public getTripNotificationHistory(
    tripId: number,
  ): AxiosPromise<NotificationHistoryModel> {
    return this.axios.get(`/notifications/history/${tripId}`);
  }
}
