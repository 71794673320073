import React from 'react';

import { withStore } from 'hocs';
import { AuthStore } from 'stores/auth.store';
import { Navigate } from 'react-router-dom';

type Props = {
  authStore?: AuthStore;
  children: React.ReactElement;
  redirectTo?: string;
};

function PublicRoute(props: Props) {
  const { authStore, redirectTo = '/', children } = props;

  return authStore.isLoggedIn ? <Navigate to={redirectTo} /> : children;
}

export default withStore(({ rootStore }) => ({
  authStore: rootStore.authStore,
}))(PublicRoute);
