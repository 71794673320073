import React, { Component } from 'react';
import { reaction } from 'mobx';
import intl from 'react-intl-universal';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import { CheckoutStore } from '../../../../stores/checkout.store';
import { TravelerFormModel } from '../../../../models/form-models/travelerForm.model';
import { InputContainer } from '../../../FormContainers';
import { isFormValid, englishLetterRegex } from '../utils/validationUtils';
import { withStore } from '../../../../hocs';

@withStore(({ rootStore }) => ({
  checkoutStore: rootStore.checkoutStore,
}))
export default class TravelerForm extends Component<{
  formId: number;
  isPrefilled: boolean;
  isFieldsRequired: boolean;
  checkoutStore?: CheckoutStore;
}> {
  isInitialized = false;
  validate = null;
  disposer = null;

  state = {
    initialValues: null,
  };

  UNSAFE_componentWillMount() {
    const {
      formId,
      isPrefilled,
      checkoutStore: { travelers, isAllPassengerNamesRequired },
    } = this.props;
    const baseInitialValues = !isPrefilled
      ? new TravelerFormModel({ id: formId, countryCodeAndPhoneCode: 'CN:86' })
      : travelers.find((traveler) => traveler.id === formId);
    const initialValues = { ...baseInitialValues, isAllPassengerNamesRequired };

    this.setState({
      initialValues,
    });
  }

  componentDidMount() {
    const { checkoutStore } = this.props;

    this.disposer = reaction(
      () => checkoutStore.shouldValidate,
      (shouldValidate) => shouldValidate > 0 && this.validate(),
    );
  }

  componentWillUnmount() {
    const { checkoutStore, formId } = this.props;

    checkoutStore.unregisterFieldInValidStore(`travelerForm:${formId}`);
    this.disposer();
  }

  initializeValidationFunction = (validateForm, setErrors, setTouched) => {
    this.isInitialized = true;
    this.validate = () => {
      validateForm().then((errors) => {
        setErrors(errors);
        const result = {};
        Object.keys(errors).forEach((k) => (result[k] = true));
        setTouched(result);
      });
    };
  };

  handleTravelerFormChange = (values, errors) => {
    const { checkoutStore } = this.props;

    checkoutStore.createOrUpdateTraveler(new TravelerFormModel(values));
    checkoutStore.updateValid(`travelerForm:${values.id}`, isFormValid(errors));
  };

  getFieldLabel = (name: string, isRequired: boolean) =>
    isRequired ? `${name} &` : name;

  render() {
    const { formId, isPrefilled } = this.props;
    const { initialValues } = this.state;

    return (
      <div className="traveler-form-container">
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .when('isAllPassengerNamesRequired', {
                is: true,
                then: Yup.string().required(intl.get('required')),
              })
              .test(
                'length',
                intl.get('checkout.mustBeAtLeastCharacters', {
                  minCount: 2,
                }),
                (value) => (value ? value?.trim().length > 1 : true),
              )
              .test(
                'englishLetters',
                intl.get('checkout.englishLetters'),
                (value) => (value ? englishLetterRegex.test(value) : true),
              ),
            lastName: Yup.string()
              .when('isAllPassengerNamesRequired', {
                is: true,
                then: Yup.string().required(intl.get('required')),
              })
              .test(
                'length',
                intl.get('checkout.mustBeAtLeastCharacters', { minCount: 2 }),
                (value) => (value ? value.trim().length > 1 : true),
              )
              .test(
                'englishLetters',
                intl.get('checkout.englishLetters'),
                (value) => (value ? englishLetterRegex.test(value) : true),
              ),
          })}
          onSubmit={() => undefined}
        >
          {({ values, validateForm, setErrors, setTouched, errors }) => (
            <>
              <>
                {!this.isInitialized &&
                  this.initializeValidationFunction(
                    validateForm,
                    setErrors,
                    setTouched,
                  )}
                {!isPrefilled && this.handleTravelerFormChange(values, errors)}
              </>

              <div className="traveler-header">
                <div className="title">{`${intl.get(
                  'checkout.traveler',
                )} ${formId}`}</div>
              </div>
              <div className="fields-container a-traveler">
                <Field
                  className="checkout-field first-name"
                  disabled={isPrefilled}
                  name="firstName"
                  component={InputContainer}
                  label={this.getFieldLabel(
                    intl.get('checkout.firstName'),
                    values.isAllPassengerNamesRequired,
                  )}
                />
                <Field
                  className="checkout-field last-name"
                  disabled={isPrefilled}
                  name="lastName"
                  component={InputContainer}
                  label={this.getFieldLabel(
                    intl.get('checkout.lastName'),
                    values.isAllPassengerNamesRequired,
                  )}
                />
              </div>
            </>
          )}
        </Formik>
      </div>
    );
  }
}
