import React, { Component } from 'react';
import intl from 'react-intl-universal';

import { ServiceModel } from '../../../../models/service.model';
import { OrdersStore } from '../../../../stores/orders.store';
import { UserStore } from '../../../../stores/user.store';
import { getCancellationInfoItem } from '../utils/cancellationUtils';
import { formatCardLastDigits, moneyFormat } from '../../../../utils/formatter';
import OrderCancellationForm from './OrderCancellationForm';
import { OrderCancellationFormModel } from '../../../../models/orders/orderCancellationForm.model';
import { withStore } from '../../../../hocs';

@withStore(({ rootStore }) => ({
  userStore: rootStore.userStore,
  ordersStore: rootStore.ordersStore,
}))
export default class OrderItemCancellation extends Component<{
  order: ServiceModel;
  closePopup: any;
  afterCancelTrip: Function;
  tripId: number;
  ordersStore?: OrdersStore;
  userStore?: UserStore;
}> {
  handleCancellationSubmit = (values: OrderCancellationFormModel) => {
    const {
      ordersStore,
      ordersStore: { orderDetails },
      order,
      closePopup,
      afterCancelTrip,
    } = this.props;
    closePopup();

    return ordersStore
      .cancelOrder(orderDetails, values, {
        tripItemId: order.TripItemId,
      })
      .then(() => {
        afterCancelTrip();

        return Promise.resolve();
      });
  };

  handleCancellationAndSendEmail = async (
    values: OrderCancellationFormModel,
  ) => {
    const { userStore, tripId } = this.props;

    await this.handleCancellationSubmit(values);
    await userStore.sendUserEmailCancellation(tripId);
  };

  get cancellationInfoItem() {
    const {
      order,
      ordersStore: {
        orderDetails: { PaymentAmountDue },
      },
    } = this.props;

    return getCancellationInfoItem(order, PaymentAmountDue);
  }

  renderCancellationMessage = () => {
    const {
      order,
      ordersStore: { orderDetailsFinance },
    } = this.props;
    const { LastDigits } = orderDetailsFinance[0] || {};
    const { isFreeCancellation, isPartlyRefunded, refund, penalty } =
      this.cancellationInfoItem;

    if (isPartlyRefunded) {
      const translationKey = order.PaymentInfoRequiredForRefund
        ? 'orders.popup.orderItemPartlyRefundedCancellation'
        : 'orders.popup.orderItemPartlyRefundedCardCancellation';

      return (
        <span>
          {intl.get(translationKey, {
            productName: order.Name,
            penalty: moneyFormat(penalty),
            refund: moneyFormat(refund),
            cardDigits: formatCardLastDigits(LastDigits),
          })}
        </span>
      );
    }

    if (isFreeCancellation) {
      const translationKey = order.PaymentInfoRequiredForRefund
        ? 'orders.popup.orderItemRefundedCancellation'
        : 'orders.popup.orderItemRefundedCardCancellation';

      return (
        <span>
          {intl.get(translationKey, {
            productName: order.Name,
            refund: moneyFormat(refund),
            cardDigits: formatCardLastDigits(LastDigits),
          })}
        </span>
      );
    }

    return (
      <span>
        {intl.get('orders.popup.orderItemNonRefundedCancellation', {
          productName: order.Name,
        })}
      </span>
    );
  };

  render() {
    const {
      order,
      closePopup,
      userStore: { financeInfo },
    } = this.props;
    const { isFreeCancellation, refund, penalty } = this.cancellationInfoItem;
    const isPenaltyVisible =
      !isFreeCancellation || (refund === 0 && penalty > 0);

    return (
      <div className="cancel-order-tooltip">
        <div className="cancel-header">
          <span>{intl.get('orders.popup.confirmCancellation')}</span>
        </div>
        <div>
          <div className="cancellation-order-text m-b-5">
            {this.renderCancellationMessage()}
          </div>

          <div className="p-h-30">
            <OrderCancellationForm
              isPenaltyVisible={isPenaltyVisible}
              isCardVisible={order.PaymentInfoRequiredForRefund}
              financeInfo={financeInfo}
              onCancel={closePopup}
              onSubmit={this.handleCancellationAndSendEmail}
            />
          </div>
        </div>
      </div>
    );
  }
}
