import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Select } from 'antd';

import {
  sortToursItems,
  MarketplaceStore,
} from '../../../../stores/marketplace.store';
import { withStore } from '../../../../hocs';

@withStore(({ rootStore }) => ({
  marketplaceStore: rootStore.marketplaceStore,
}))
export default class TourSortModule extends Component<{
  marketplaceStore?: MarketplaceStore;
}> {
  handleSelectChange = (sortType) => {
    const { marketplaceStore } = this.props;

    marketplaceStore.setSortType(sortType);
  };

  render() {
    const {
      marketplaceStore: { sortType },
    } = this.props;
    const { Option } = Select;
    const selectOptions = sortToursItems.map((item) => (
      <Option key={item} value={item}>
        {intl.get(`marketplacePage.sort.sortItems.${item}`)}
      </Option>
    ));

    return (
      <div className="tour-sort-module">
        <span>{intl.get('marketplacePage.sort.sortBy')}: </span>
        <Select
          value={sortType}
          onChange={this.handleSelectChange}
          className="select-sort general-input-container"
          dropdownClassName="sorting-select-dropdown"
          suffixIcon={<i className="tc-dropdown" />}
        >
          {selectOptions}
        </Select>
      </div>
    );
  }
}
