import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import Accessor from 'components/SharedComponents/Accessor';
import { getRouteTree } from './routing-tree';

export default function SidebarMenu() {
  const routeTree = getRouteTree();

  return (
    <div className="sidebar">
      {routeTree.map((link) => (
        <Accessor key={link.to} roles={link.roles}>
          <NavLink
            to={link.to}
            className={({ isActive }) =>
              cx('sidebar-nav-link', { 'active-sidebar-menu': isActive })
            }
          >
            <Fragment>
              <span className="icon">{link.icon}</span>
              {link.children}
            </Fragment>
          </NavLink>
        </Accessor>
      ))}
    </div>
  );
}
