import React, { Component } from 'react';
import { reaction } from 'mobx';
import intl from 'react-intl-universal';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import { CheckoutStore } from '../../../../stores/checkout.store';

import { CheckboxContainer } from '../../../FormContainers';
import { availableLanguages } from '../../../../models/enums/language.enums';
import { UserStore } from '../../../../stores/user.store';
import { withStore } from '../../../../hocs';

const policyAndTermsName = 'policyAndTermsCondition';
const termsLinks = {
  // [availableLanguages.ENG]: 'https://www.tourschain.com/en/terms-conditions/',
  // [availableLanguages.CHI]: 'https://www.tourschain.com/termsconditions/',
  [availableLanguages.ENG]: '/en/terms-conditions/',
  [availableLanguages.CHI]: '/en/terms-conditions/',
};

@withStore(({ rootStore }) => ({
  checkoutStore: rootStore.checkoutStore,
  userStore: rootStore.userStore,
}))
export default class PolicyAndTerms extends Component<{
  checkoutStore?: CheckoutStore;
  userStore?: UserStore;
}> {
  isInitialized = false;
  validate = null;
  disposer = null;

  componentDidMount() {
    const { checkoutStore } = this.props;
    const isFormValid = false;

    checkoutStore.updateValid(policyAndTermsName, isFormValid);

    this.disposer = reaction(
      () => checkoutStore.shouldValidate,
      (shouldValidate) => shouldValidate > 0 && this.validate(),
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  initializeValidationFunction = (validateForm, setErrors, setTouched) => {
    this.isInitialized = true;
    this.validate = () => {
      validateForm().then((errors) => {
        setErrors(errors);
        const result = {};
        Object.keys(errors).forEach((k) => (result[k] = true));
        setTouched(result);
      });
    };
  };

  getCheckboxLabel = (language: string) => (
    <span className="checkout-policy-and-terms">
      {intl.get('checkout.policyAndTermsCheckboxFirstPart')}
      <span>
        <a
          href={termsLinks[language]}
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.get('checkout.policyAndTermsCheckboxSecondPart')}
        </a>
      </span>
    </span>
  );

  handlePolicyAndTermsChange = (values) => {
    const { checkoutStore } = this.props;
    const { policyAndTermsCondition: flag } = values;

    checkoutStore.updatePolicyAndTermsFlag(flag);
    checkoutStore.updateValid(policyAndTermsName, flag);
  };

  render() {
    const {
      userStore: { language },
    } = this.props;

    return (
      <div className="policy-and-terms-container">
        <div className="policy-checkbox">
          <Formik
            initialValues={{ policyAndTermsCondition: false }}
            validationSchema={Yup.object().shape({
              policyAndTermsCondition: Yup.boolean()
                .oneOf([true], intl.get('checkout.policyAndTermsRequired'))
                .required(intl.get('required')),
            })}
            validateOnChange
            validateOnBlur
            onSubmit={() => undefined}
          >
            {({ values, validateForm, setErrors, setTouched }) => (
              <>
                <>
                  {!this.isInitialized &&
                    this.initializeValidationFunction(
                      validateForm,
                      setErrors,
                      setTouched,
                    )}
                  {this.handlePolicyAndTermsChange(values)}
                </>

                <Field
                  name="policyAndTermsCondition"
                  component={CheckboxContainer}
                  label={this.getCheckboxLabel(language)}
                />
              </>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
