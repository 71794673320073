import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { reaction } from 'mobx';
import { Input } from 'antd';

import { CheckoutStore } from '../../../../stores/checkout.store';
import { withStore } from '../../../../hocs';

@withStore(({ rootStore }) => ({
  checkoutStore: rootStore.checkoutStore,
}))
export default class SpecialRequest extends Component<{
  cardId: string;
  checkoutStore?: CheckoutStore;
}> {
  disposer = null;

  state = {
    text: '',
    isErrorLabelNeeded: false,
  };

  componentDidMount() {
    const { checkoutStore, cardId } = this.props;
    const isSelectValid = false;

    checkoutStore.updateValid(`specialRequest:${cardId}`, isSelectValid);

    this.disposer = reaction(
      () => checkoutStore.shouldValidate,
      (shouldValidate) => shouldValidate > 0 && this.validate(),
    );
  }

  componentWillUnmount() {
    const { checkoutStore, cardId } = this.props;

    checkoutStore.unregisterFieldInValidStore(`specialRequest:${cardId}`);
    this.disposer();
  }

  validate = () => {
    const { checkoutStore, cardId } = this.props;

    const isSelectsValid = checkoutStore.isItemsInCheckoutValid(
      `specialRequest:${cardId}`,
    );

    this.setState({
      isErrorLabelNeeded: !isSelectsValid,
    });
  };

  handleInputChange = (event) => {
    const { checkoutStore, cardId } = this.props;
    const isRequestValid = !!event.target.value;

    this.setState({ text: event.target.value });
    checkoutStore.updateValid(`specialRequest:${cardId}`, isRequestValid);
  };

  handleBlur = () => {
    const { checkoutStore, cardId } = this.props;
    const { text } = this.state;
    const request = {
      text,
      cardId,
    };

    checkoutStore.createOrUpdateSpecialRequests(request);
  };

  render() {
    const { isErrorLabelNeeded } = this.state;

    return (
      <div className="special-request field">
        <div>
          <div className="checkout-field">
            <div>
              <h4 className="field-label">
                {intl.get('specialRequestsLabel')}
              </h4>
            </div>
            <Input.TextArea
              placeholder={intl.get('specialRequestsPlaceholder')}
              autoSize={{ minRows: 6, maxRows: 6 }}
              onChange={this.handleInputChange}
              onBlur={this.handleBlur}
            />
          </div>
        </div>
        {isErrorLabelNeeded && (
          <div className="field-error visible">
            <span>{intl.get('checkout.fieldsMustBeFilled')}</span>
          </div>
        )}
      </div>
    );
  }
}
