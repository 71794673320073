import React from 'react';
import { Select } from 'antd';
import intl from 'react-intl-universal';

import { PricingOptionsModel } from './pricingOptions.model';

const { Option } = Select;

type Props = {
  value: string;
  options: PricingOptionsModel[];
  onChange: (key: string) => void;
};

export default function SeatCategorySelect(props: Props) {
  const { value, options, onChange } = props;

  return (
    <Select
      className="select-price-category"
      value={value}
      placeholder={intl.get('tourDetails.seatMap.selectPricingOptions')}
      getPopupContainer={(triggerNode) => triggerNode}
      onChange={onChange}
    >
      {options.map(({ key, name, color }) => (
        <Option key={key} value={key}>
          <div className="price-option">
            <span
              className="price-option-bullet"
              style={{ background: color }}
            />
            <span>{name}</span>
          </div>
        </Option>
      ))}
    </Select>
  );
}
