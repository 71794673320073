import React, { Component, Fragment } from 'react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import { CheckoutStore } from '../../../stores/checkout.store';
import { CartStore } from '../../../stores/cart.store';
import { CardInCartModel } from '../../../models/cardInCart.model';
import CardInCart from './CardInCart';
import { moneyFormat } from '../../../utils/formatter';
import { RouterProps, withRouter, withStore } from '../../../hocs';

type Props = {
  cartStore?: CartStore;
  checkoutStore?: CheckoutStore;
  closePopover: any;
} & RouterProps;

@withRouter
@withStore(({ rootStore }) => ({
  cartStore: rootStore.cartStore,
  checkoutStore: rootStore.checkoutStore,
}))
export default class CartMinimized extends Component<Props> {
  handleEditCard = (tourId: string, cardId: string) => {
    const { closePopover, location, navigate } = this.props;
    const params = new URLSearchParams(location.search);

    closePopover();
    params.set('isProductPopupOpened', 'true');
    params.set('popupTourId', `${tourId}`);
    params.set('cardId', `${cardId}`);
    navigate({ search: params.toString() });
  };

  handleClearCart = () => {
    const { cartStore } = this.props;

    cartStore.clearCart();
  };

  handleNewProductOpen = (tourId: string) => {
    const { closePopover, location, navigate } = this.props;
    const params = new URLSearchParams(location.search);

    closePopover();
    params.set('isProductPopupOpened', 'true');
    params.set('popupTourId', `${tourId}`);
    navigate({ search: params.toString() });
  };

  handleCheckoutClick = () => {
    const {
      cartStore: { cardsInCart },
      checkoutStore,
      closePopover,
      navigate,
    } = this.props;

    closePopover();
    checkoutStore.initializeNewCheckout(cardsInCart);
    navigate('/cart?isCheckoutOpened=true');
  };

  renderBottomControls = (cardsInCart: CardInCartModel[]) => {
    const { closePopover } = this.props;
    const total = cardsInCart.reduce(
      (sum, current) => sum + current.product.Price,
      0,
    );

    return (
      <div className="card-minimized-bottom-controls">
        <div className="go-to-cart">
          <Link to="/cart">
            <Button
              type="primary"
              htmlType="button"
              size="large"
              onClick={closePopover}
            >
              {intl.get('cartPage.goToCart')}
            </Button>
          </Link>
        </div>
        <div className="go-to-checkout">
          <Button
            onClick={this.handleCheckoutClick}
            type="primary"
            htmlType="button"
            size="large"
          >
            {`${intl.get('cartPage.checkout')} (${moneyFormat(total)})`}
          </Button>
        </div>
      </div>
    );
  };

  renderCartItems = (cards: CardInCartModel[]) => {
    const { cartStore } = this.props;

    return (
      <Fragment>
        {cards.map((card) => (
          <CardInCart
            key={`${card.id}`}
            card={card}
            editCard={this.handleEditCard}
            removeCard={() => cartStore.removeCardFromCart(card.id)}
            onCardClick={() => this.handleNewProductOpen(card.productId)}
            isProductIdHidden
          />
        ))}
      </Fragment>
    );
  };

  render() {
    const {
      cartStore: { cardsInCart },
      closePopover,
    } = this.props;

    return (
      <div onMouseLeave={closePopover} className="cart-minimized">
        {cardsInCart && cardsInCart.length > 0 ? (
          <div>
            <div className="d-f j-c-space-between header-container">
              <h2 className="header">{`${intl.get('cartPage.yourCart')} (${
                cardsInCart.length
              })`}</h2>
              <div>
                <span onClick={this.handleClearCart} className="clear-cart">
                  {intl.get('cartPage.clearCart')}
                </span>
              </div>
            </div>
            <div className="divider" />
            <div className="cart-minimized-cards-section">
              {this.renderCartItems(cardsInCart)}
            </div>
            <div>{this.renderBottomControls(cardsInCart)}</div>
          </div>
        ) : (
          <div className="empty-cart">
            <span>{intl.get('cartPage.cartHoverIsEmpty')}</span>
          </div>
        )}
      </div>
    );
  }
}
