import { BaseApi } from './base.api';
import { PhoneResourceModel } from '../models/phoneResource.model';

export class MessagesApi extends BaseApi {
  public sendSupportEmail(body) {
    return this.axios.post('/messages/support/send', body);
  }

  public getPhoneResource(phoneNumber: string) {
    return this.axios.get<PhoneResourceModel>('/messages/phone-resource', {
      params: {
        phoneNumber,
      },
    });
  }
}
