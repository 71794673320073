import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import { Field } from 'formik';
import * as Yup from 'yup';

import {
  englishLetterRegex,
  emailRegex,
  englishLettesAndSymbolsRegex,
} from '../../../../utils/formatter';
import { InputContainer, TextareaContainer } from '../../../FormContainers';

function renderSupplierInfo(extraData) {
  const { currentContact } = extraData;

  return (
    <div className="d-f f-d-column flx-1">
      <div className="d-f jc-sb ai-fs">
        <Field
          className="m-r-5"
          name="Name"
          label={intl.get('contacts.popup.supplierName')}
          component={InputContainer}
        />
        <Field
          className="m-l-5"
          name="Id"
          disabled={currentContact}
          label={intl.get('contacts.popup.uniqueCode')}
          component={InputContainer}
        />
      </div>
      <div className="d-f jc-sb ai-fs">
        <Field
          className="m-r-5"
          name="Address1"
          label={intl.get('contacts.popup.address')}
          component={InputContainer}
        />
        <Field
          className="m-l-5"
          name="Phone"
          label={intl.get('contacts.popup.phone')}
          component={InputContainer}
        />
      </div>
    </div>
  );
}

function renderAccountingPersonInfo() {
  return (
    <Fragment>
      <Field
        className="m-r-10"
        name="AccountingContact"
        label={intl.get('contacts.popup.accountantName')}
        component={InputContainer}
      />
      <Field
        name="AccountingEmail"
        label={intl.get('contacts.popup.email')}
        component={InputContainer}
      />
    </Fragment>
  );
}

function renderNotes() {
  return <Field name="Notes" component={TextareaContainer} />;
}

export function renderSupplierPopupBody(setFieldValue, values, extraData) {
  return (
    <div className="main-info">
      <div className="row">
        <div className="column title">
          {intl.get('contacts.popup.supplierInfo')}
        </div>
        <div className="column content">{renderSupplierInfo(extraData)}</div>
      </div>

      <div className="row">
        <div className="column title">
          {intl.get('contacts.popup.accounting')}
        </div>
        <div className="column content">{renderAccountingPersonInfo()}</div>
      </div>

      <div className="row">
        <div className="column title">{intl.get('contacts.popup.notes')}</div>
        <div className="column content">{renderNotes()}</div>
      </div>
    </div>
  );
}

export function getSupplierInitialValues(currentContact) {
  return currentContact
    ? {
        Id: currentContact.Id,
        Name: currentContact.Name,
        Address1: currentContact.Address1,
        Address2: currentContact.Address2,
        City: currentContact.City,
        State: currentContact.State,
        Zip: currentContact.Zip,
        AccountingEmail: currentContact.AccountingEmail,
        AccountingContact: currentContact.AccountingContact,
        Phone: currentContact.Phone,
        Notes: currentContact.Notes,
      }
    : {
        Id: undefined,
        Name: undefined,
        Address1: undefined,
        Address2: undefined,
        City: undefined,
        State: undefined,
        Zip: undefined,
        AccountingEmail: undefined,
        AccountingContact: undefined,
        Phone: undefined,
        Notes: undefined,
      };
}

export function getSupplierValidation() {
  return Yup.object().shape({
    Id: Yup.string()
      .required('required')
      .test(
        'length',
        'Must be 10 characters or less',
        (value) => value && value.trim().length <= 10,
      )
      .test(
        'englishLetters',
        "Can enter only latin letters, numbers and '-'",
        (value) => englishLetterRegex.test(value),
      ),
    Name: Yup.string()
      .required('required')
      .test(
        'length',
        'Must be 60 characters or less',
        (value) => value && value.trim().length <= 60,
      )
      .test(
        'englishLetters',
        'Can enter only latin letters and numbers',
        (value) => !englishLettesAndSymbolsRegex.test(value),
      ),
    Phone: Yup.string()
      .required('required')
      .matches(/^\d+$/, intl.get('checkout.phoneNumberError')),
    Address1: Yup.string().test(
      'length',
      'Must be 30 characters or less',
      (value) => value && value.trim().length <= 30,
    ),
    AccountingContact: Yup.string()
      .matches(
        englishLettesAndSymbolsRegex,
        'Can enter only latin letters and numbers',
      )
      .test(
        'length',
        'Must be 50 characters or less',
        (value) => value && value.trim().length <= 50,
      )
      .required('required'),
    AccountingEmail: Yup.string()
      .required('required')
      .matches(emailRegex, intl.get('emailValidator')),
  });
}
