import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Button, Popover, Select, Space } from 'antd';

import { withStore } from 'hocs';
import { UserFiltersFormValuesModel } from 'models/user-filters/userFiltersFormValues.model';
import { BulkInventoryStore } from 'stores/bulkInventory.store';
import { USER_ROLE_GROUPS } from 'utils/constants';
import Accessor from 'components/SharedComponents/Accessor';
import UserFiltersManageForm from 'components/SharedComponents/UserFiltersManageForm';

type Props = {
  bulkInventoryStore?: BulkInventoryStore;
};

function InventoryBulkViewCriteria(props: Props) {
  const { bulkInventoryStore } = props;

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isSavePopoverVisible, setIsSavePopoverVisible] = useState(false);

  useEffect(() => {
    bulkInventoryStore.loadBulkViewCriteria();
  }, []);

  const onCreate = async (values: UserFiltersFormValuesModel) => {
    try {
      setIsSaveLoading(true);

      await bulkInventoryStore.createBulkViewCriteria(values);
    } finally {
      setIsSaveLoading(false);
      setIsSavePopoverVisible(false);
    }
  };

  const onDelete = async () => {
    try {
      setIsDeleteLoading(true);

      await bulkInventoryStore.deleteSelectedBulkViewCriteria();
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const onUpdate = async () => {
    try {
      setIsUpdateLoading(true);

      await bulkInventoryStore.updateSelectedBulkViewCriteria();
    } finally {
      setIsUpdateLoading(false);
    }
  };

  const onVisibleChange = (isVisible: boolean) => {
    if (isSaveLoading) return;

    setIsSavePopoverVisible(isVisible);
  };

  const onChange = (id: string) => {
    bulkInventoryStore.onSelectedViewCriteriaChange(id);
  };

  const onSave = async (values: UserFiltersFormValuesModel) => {
    await onCreate(values);

    return { error: null };
  };

  const options = bulkInventoryStore.savedViewCriteriaList.map(
    (viewCriteria) => ({
      label: viewCriteria.name,
      value: viewCriteria.id,
    }),
  );

  return (
    <Space>
      <Select
        allowClear
        placeholder={intl.get('inventory.bulkView.selectViewCriteria')}
        style={{ width: 250 }}
        getPopupContainer={(triggerNode) => triggerNode}
        value={bulkInventoryStore.selectedViewCriteriaId}
        options={options}
        dropdownRender={(menu) => (
          <>
            {menu}

            <Button block type="link" onClick={() => onChange(null)}>
              {intl.get('buttons.clear')}
            </Button>
          </>
        )}
        onChange={onChange}
      />

      <Accessor roles={USER_ROLE_GROUPS.ADMINS}>
        <Space>
          {bulkInventoryStore.selectedViewCriteriaId && (
            <Button loading={isUpdateLoading} onClick={onUpdate}>
              {intl.get('buttons.update')}
            </Button>
          )}

          {!bulkInventoryStore.selectedViewCriteriaId && (
            <Popover
              destroyTooltipOnHide
              trigger={['click']}
              placement="bottomRight"
              visible={isSavePopoverVisible}
              onVisibleChange={onVisibleChange}
              content={() => (
                <UserFiltersManageForm
                  initialValues={{
                    name: '',
                    allNames: [],
                  }}
                  isLoading={isSaveLoading}
                  onSave={onSave}
                />
              )}
              getPopupContainer={(triggerNode) => triggerNode}
            >
              <div>
                <Button>{intl.get('buttons.save')}</Button>
              </div>
            </Popover>
          )}

          <Button
            loading={isDeleteLoading}
            disabled={!bulkInventoryStore.selectedViewCriteriaId}
            onClick={onDelete}
          >
            {intl.get('buttons.delete')}
          </Button>
        </Space>
      </Accessor>
    </Space>
  );
}

export default withStore(({ rootStore }) => ({
  bulkInventoryStore: rootStore.bulkInventoryStore,
}))(InventoryBulkViewCriteria);
