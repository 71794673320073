import { TourCardModel } from '../models/marketplace/tourCard.model';
import { SuggestedProductModel } from '../models/marketplace/suggestedProduct.model';
import { LocationTypesEnum } from '../models/enums/locationTypes.enum';

export const tourToSuggestion = (
  tour: TourCardModel,
): SuggestedProductModel => ({
  LocationType: LocationTypesEnum.service,
  Code: tour.Product_Code,
  TranslatedName: tour.Name,
  ThumbnailImage: tour.ThumbnailImage,
  StartingPrice: tour.StartingPrice,
  '@search.text': tour.Name, // html text with highlighted search text
  Language_Code: tour.Language_Code,
  StateName: tour.State_Code,
  RegionName: tour.Region_Code,
  CountryName: tour.Country_Code,

  ProductType: tour.Product_Type,
});
