import { Radio, RadioGroupProps, RadioChangeEvent } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { FieldProps, getIn } from 'formik';

interface Button {
  label: string;
  value: string;
}

type OwnProps = {
  buttons: Button[];
  label?: string;
};

type Props = OwnProps & RadioGroupProps & FieldProps;

const RadioGroupField: React.FC<Props> = React.memo(
  ({
    buttons = [],
    className,
    field,
    form,
    label,
    children,
    onChange,
    ...rest
  }) => {
    const { errors, touched } = form;
    const fieldError: string = getIn(errors, field.name);
    const isTouched: boolean = getIn(touched, field.name);
    const hasError: boolean = fieldError && isTouched;

    const handleChange = (e: RadioChangeEvent) => {
      const { value } = e.target;

      form.setFieldValue(field.name, value, true);

      onChange?.(e);
    };

    return (
      <div
        className={classNames('field', { 'has-error': hasError }, className)}
      >
        {label && <h4 className="field-label">{label}</h4>}
        <Radio.Group
          className={classNames(className, { error: hasError })}
          {...field}
          {...rest}
          onChange={handleChange}
        >
          {children}
          {buttons.map((button) => (
            <Radio.Button value={button.value} key={button.value}>
              {button.label}
            </Radio.Button>
          ))}
        </Radio.Group>
        {hasError && <div className="field-error visible">{fieldError}</div>}
      </div>
    );
  },
);

export default RadioGroupField;
