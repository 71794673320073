import { isEmpty, uniqueId } from 'lodash';
import { InventoryModel } from './inventory.model';
import { InventoryBulkViewByDateModel } from './inventoryBulkViewByDate.model';
import { ProductTypeEnum } from '../enums/productType.enum';
import { mapInventoryToViewTableData } from '../../utils/bulkInventoryUtils';
import { InventoryComponentModel } from './inventoryComponent.model';

export class InventoryBulkViewTableModel {
  key: string;
  productId: string;
  productName: string;
  productType: ProductTypeEnum;
  categoryId: string;
  categoryName: string;
  time: string;
  inventoryByDate: InventoryBulkViewByDateModel;
  children: InventoryBulkViewTableModel[];
  isEditable: boolean;

  constructor(inventory: InventoryModel | InventoryComponentModel) {
    const hasComponents = 'Components' in inventory;

    this.key = uniqueId();
    this.productId = inventory.ProductId;
    this.productName = inventory.ProductName;
    this.productType = inventory.ProductType;
    this.categoryId = inventory.CategoryId;
    this.categoryName = inventory.CategoryName;
    this.time = inventory.Time;
    this.inventoryByDate = new InventoryBulkViewByDateModel(inventory);
    this.isEditable = hasComponents;
    this.children =
      hasComponents && !isEmpty(inventory.Components)
        ? mapInventoryToViewTableData(inventory?.Components)
        : null;
  }
}
