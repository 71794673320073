import { Moment } from 'moment';
import { AvailableCategoryModel } from './tour-details/tourAvailableCategory.model';
import { TourDetailsModel } from './tour-details/tourDetails.model';
import { PolicyModel } from './policy.model';
import { BookingQuestionModel } from './tour-details/bookingQuestion.model';
import PickupPoint from './tour-details/pickupPoint.model';
import CartCardHolInfo from './cartCardHolInfo.model';

export class CardInCartModel {
  public id: string;
  public tourName: string;
  public productId: string;
  public imageUrl: string;
  public date: Moment;
  public product: AvailableCategoryModel;
  public productType: string;
  public passengerWeightRequired: boolean;
  public allPassengerNamesRequired: boolean;
  public bookingQuestions: BookingQuestionModel[];
  public pickupPoints: PickupPoint[];
  public dropoffPoints: PickupPoint[];
  public holdInfo: CartCardHolInfo;

  constructor(options: {
    tourInfo: TourDetailsModel;
    availability: AvailableCategoryModel;
    id?: any;
    cancellationPolicies: PolicyModel[];
    holdInfo?: CartCardHolInfo;
  }) {
    const { tourInfo, availability, id, holdInfo } = options;

    const cardId = !id ? `${Math.random()}` : id;
    this.id = `${cardId}`;
    this.tourName = tourInfo.Name;
    this.productId = tourInfo.ProductId;
    this.imageUrl = tourInfo.MainInformation.ThumbnailImageURI;
    this.date = tourInfo.StartDate;
    this.product = availability;
    this.productType = tourInfo.ProductType;
    this.passengerWeightRequired = tourInfo.PassengerWeightRequired;
    this.allPassengerNamesRequired = tourInfo.AllPassengerNamesRequired;
    this.bookingQuestions = tourInfo.BookingQuestions;
    this.pickupPoints = tourInfo.PickupPoints;
    this.dropoffPoints = tourInfo.DropoffPoints;
    this.holdInfo = holdInfo;
  }
}
