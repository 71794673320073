import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { observer } from 'mobx-react';
import { Select } from 'antd';

import { InventoryStore } from '../../../stores/inventory.store';

import { ALL_PRODUCTS } from './TopMenuCalendar';

const { Option } = Select;

@observer
export default class TopMenuCalendarProductDropdown extends Component<{
  onChangeProduct: Function;
  selectedId: string;
  inventoryStore?: InventoryStore;
}> {
  componentDidMount() {
    const { inventoryStore } = this.props;

    inventoryStore.getProducts();
  }

  handleChangeProduct = (value) => this.props.onChangeProduct(value);

  render() {
    const {
      selectedId,
      inventoryStore: { productsList },
    } = this.props;

    const productOptions = productsList.map((product, index) => (
      <Option key={`${product.Id}.${index}`} value={product.Id}>
        {product.Name}
      </Option>
    ));

    return (
      <Select
        className="flx-1"
        value={selectedId}
        onChange={this.handleChangeProduct}
      >
        {[
          <Option key={ALL_PRODUCTS} value={ALL_PRODUCTS}>
            {intl.get('topMenuCalendar.allProducts')}
          </Option>,
          ...productOptions,
        ]}
      </Select>
    );
  }
}
