import { PaymentMethodProfileModel } from './paymentMethodProfile.model';
import { CreditCardModel } from './creditCard.model';
import { PaymentMethodsEnum } from './enums/paymentMethods.enum';

export class CheckoutPaymentModel {
  paymentMethodType: PaymentMethodsEnum;
  selectedCard: PaymentMethodProfileModel;
  newCardInfo: CreditCardModel;
  saveInProfile: boolean;

  constructor() {
    this.paymentMethodType = undefined; // PaymentMethodsEnum.balance;
    this.selectedCard = new PaymentMethodProfileModel();
    this.newCardInfo = new CreditCardModel();
    this.saveInProfile = false;
  }
}
