import { isArray, toNumber } from 'lodash';

export default class RateAgeFormatter {
  private readonly ageRange: number[] = [];

  constructor(ageRange: [number, number] | string) {
    this.ageRange = isArray(ageRange)
      ? this.mapToNumber(ageRange)
      : this.parse(ageRange);
  }

  private mapToNumber(range: Array<string | number>) {
    return range.map(toNumber);
  }

  private parse(range: string) {
    return range ? this.mapToNumber(range.split('-')) : [null, null];
  }

  public format() {
    return this.ageRange.join('-');
  }

  public getMin() {
    return this.ageRange[0];
  }

  public getMax() {
    return this.ageRange[1];
  }
}
