import React, { Component } from 'react';
import { reaction } from 'mobx';
import { Formik, Field } from 'formik';

import { BookingQuestionModel } from '../../../../models/tour-details/bookingQuestion.model';
import { getBookingQuestionsInitialDataForProduct } from '../utils/bookingQuestions';
import { CheckoutStore } from '../../../../stores/checkout.store';

import { isFormValid } from '../utils/validationUtils';
import { BookingQuestionProductForm } from '../../../../models/tour-details/bookingQuestionProductForm.mode';
import { withStore } from '../../../../hocs';

@withStore(({ rootStore }) => ({
  checkoutStore: rootStore.checkoutStore,
}))
export default class BookingQuestionsForProduct extends Component<{
  cardId: string;
  bookingQuestions: BookingQuestionModel[];
  checkoutStore?: CheckoutStore;
}> {
  isInitialized = false;
  validate = null;

  state = {
    initialValues: {},
    validationSchema: {},
    components: {},
    labels: {},
    relevantQuestions: [],
    otherProps: {},
  };

  componentDidMount() {
    const { bookingQuestions, checkoutStore, cardId } = this.props;
    const {
      initialValues,
      validationSchema,
      components,
      labels,
      relevantQuestions,
      otherProps,
    } = getBookingQuestionsInitialDataForProduct(bookingQuestions);
    const isValidForm = false;

    checkoutStore.updateValid(
      `bookingQuestionForProduct:${cardId}`,
      isValidForm,
    );

    this.setState({
      initialValues,
      validationSchema,
      components,
      labels,
      relevantQuestions,
      otherProps,
    });

    reaction(
      () => checkoutStore.shouldValidate,
      (shouldValidate) => shouldValidate > 0 && this.validate(),
    );
  }

  initializeValidationFunction = (validateForm, setErrors, setTouched) => {
    this.isInitialized = true;
    this.validate = () => {
      validateForm().then((errors) => {
        setErrors(errors);
        const result = {};
        Object.keys(errors).forEach((k) => (result[k] = true));
        setTouched(result);
      });
    };
  };

  handleFormChange = (values, errors) => {
    const { checkoutStore, cardId } = this.props;

    checkoutStore.createOrUpdateBookingQuestionForProduct(
      new BookingQuestionProductForm(values, cardId),
    );
    checkoutStore.updateValid(
      `bookingQuestionForProduct:${cardId}`,
      isFormValid(errors),
    );
  };

  renderFormRegardingQuestions = () => {
    // className=`booking-question-${questionId}`
    const { components, labels, relevantQuestions, otherProps } = this.state;
    const sortedRelevantQuestions = [...relevantQuestions].sort(
      (a, b) => a.SortOrder - b.SortOrder,
    );

    return sortedRelevantQuestions.map(({ questionId }) => (
      <Field
        key={questionId}
        name={questionId}
        className={`checkout-field booking-question booking-question-${questionId}`}
        label={labels[questionId]}
        component={components[questionId]}
        {...otherProps[questionId]}
      />
    ));
  };

  render() {
    const { initialValues, validationSchema } = this.state;

    return (
      <div className="booking-question-container">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => undefined}
        >
          {({ values, validateForm, setErrors, setTouched, errors }) => (
            <>
              {!this.isInitialized &&
                this.initializeValidationFunction(
                  validateForm,
                  setErrors,
                  setTouched,
                )}
              {this.handleFormChange(values, errors)}
              <div className="fields-container booking-questions-fields-container">
                {this.renderFormRegardingQuestions()}
              </div>
            </>
          )}
        </Formik>
      </div>
    );
  }
}
