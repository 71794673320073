import { BaseApi } from './base.api';

export class AgenciesApi extends BaseApi {
  public getAgencies() {
    return this.axios.get('/odata/agencies');
  }

  public createAgency(agency) {
    return this.axios.post('/odata/agencies', agency);
  }

  public editAgency(agencyId, agency) {
    return this.axios.put(`/odata/agencies/${agencyId}`, agency);
  }

  // public deleteAgency(agencyId) {
  //     return this.axios.delete(`/odata/agencies/${agencyId}`);
  // }
}
