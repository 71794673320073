import React from 'react';
import intl from 'react-intl-universal';
import moment, { Moment } from 'moment';

import { PolicyModel } from '../models/policy.model';

export const getPolicies = (
  policies: PolicyModel[],
  serviceStart?: string | Date | Moment,
) => {
  if (!policies || !policies.length) {
    return (
      <li className="no-refund">
        {intl.get('cancellationAndRefund.policyNoRefundable')}
      </li>
    );
  }

  const sortedPolicies = [...policies].sort(
    (a, b) => b.NightsPrior - a.NightsPrior,
  );

  return sortedPolicies.map((policy, index) => {
    if (policy.NonRefundable && policy.NightsPrior >= 999) {
      // non-refundable = true and NightsPrior = 999 means non-refundable from the moment of purchase
      return (
        <li key={index} className="no-refund">
          {intl.get('cancellationAndRefund.policyNoRefundable')}
        </li>
      );
    }
    if (index === 0) {
      // free cancellation until the first record
      return (
        <li key={index}>
          <div>
            <p>
              {intl.get('cancellationAndRefund.policyFreeCancellationUntil', {
                until: policy.NightsPrior * 24,
              })}
            </p>
            {!!serviceStart && (
              <p>
                {intl.get('cancellationAndRefund.cancellationDeadline', {
                  deadlineTime: `${moment
                    .utc(serviceStart)
                    .subtract(policy.NightsPrior + 1, 'days')
                    .format('M/D')} 11:59pm`,
                })}
              </p>
            )}
          </div>
        </li>
      );
    }

    return null;
  });
};
