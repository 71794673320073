import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Col, Row } from 'antd';
import { Field } from 'formik';
import { get, isNil, findLast, take } from 'lodash';
import { CloseOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';

import { SupplierModel } from '../../../../../../models/contacts/supplier.model';
import { RegularRatesModel } from '../../../../../../models/inventory/regularRates.model';
import {
  InputNumberContainer,
  SelectContainer,
} from '../../../../../FormContainers';
import { AgeTypeEnum } from '../../../../../../models/enums/ageType.enum';
import { InventoryStore } from '../../../../../../stores/inventory.store';
import { getRateGuestTypeOptions } from '../../../../../../utils/rateUtils';
import { withStore } from '../../../../../../hocs';

type ProductRateProps = {
  regularRate: RegularRatesModel;
  ratePosition: number;
  setFieldValue: Function;
  operatorData: SupplierModel;
  regularRateIndex: number;
  regularRates: RegularRatesModel[];
  inventoryStore?: InventoryStore;
  onDelete: Function;
};

function ProductRate(props: ProductRateProps) {
  const {
    regularRate,
    ratePosition,
    setFieldValue,
    operatorData,
    regularRates,
    regularRateIndex,
    inventoryStore,
    onDelete,
  } = props;
  const NetRateVisible = get(operatorData, 'NetRateVisible', false);
  const NetRateEditable = get(operatorData, 'NetRateEditable', false);

  const childRegularRate = useMemo(
    () => regularRates.find((rate) => rate.Type === AgeTypeEnum.Child),
    [regularRates],
  );

  const prevFilledRateByGuests = useMemo(
    () =>
      findLast(
        take(regularRates, regularRateIndex),
        (rate) => !isNil(rate.MaxOccupancy),
      ),
    [regularRates, regularRateIndex],
  );

  const handleChangeOccupancy = useCallback(() => {
    if (!prevFilledRateByGuests) return;

    const newOccupancy = (prevFilledRateByGuests.MaxOccupancy ?? 0) + 1;
    const regularRatePath = `Rates.${ratePosition}.RegularRates.${regularRateIndex}`;

    setFieldValue(`${regularRatePath}.MinOccupancy`, newOccupancy);

    if (regularRate.MaxOccupancy < newOccupancy) {
      setFieldValue(`${regularRatePath}.MaxOccupancy`, newOccupancy);
    }
  }, [ratePosition, regularRateIndex, prevFilledRateByGuests]);

  useEffect(() => {
    handleChangeRate();
  }, [regularRate.Retail]);

  useEffect(() => {
    handleChangeOccupancy();
  }, [handleChangeOccupancy]);

  const handleChangeRate = () => {
    if (NetRateVisible && NetRateEditable) return;

    setFieldValue(
      `Rates.${ratePosition}.RegularRates.${regularRateIndex}.Rate`,
      regularRate.Retail,
    );
  };

  const handleNumberParser = (value: number) => {
    const [a, b] = value.toString().split('.');

    if (b && b.length > 2) {
      return `${a}.${b.slice(0, 2)}`;
    }
    return value;
  };

  const isAgeRangeVisible =
    regularRate.Type === AgeTypeEnum.Child ||
    regularRate.Type === AgeTypeEnum.Junior ||
    regularRate.Type === AgeTypeEnum.Senior;

  const isGuestRangeVisible =
    regularRate.Type === AgeTypeEnum.Adult ||
    regularRate.Type === AgeTypeEnum.Group;

  const isMaxAgeDisabled =
    regularRate.Type === AgeTypeEnum.Junior && isNil(childRegularRate?.AgeMax);

  const isMinGuestVisible =
    isGuestRangeVisible && prevFilledRateByGuests?.MaxOccupancy;

  return (
    <Row gutter={5}>
      <Col span={5}>
        <Field
          name={`Rates.${ratePosition}.RegularRates.${regularRateIndex}.Type`}
          component={SelectContainer}
          options={getRateGuestTypeOptions(
            inventoryStore.ageTypesByProductType,
            regularRate,
            regularRates,
          )}
        />
      </Col>

      <Col span={2}>
        <span className="regular-rate-col-label regular-rate-field-row">
          {isAgeRangeVisible &&
            intl.get('inventory.products.popup.ratesTable.Ages')}

          {isGuestRangeVisible &&
            intl.get('inventory.products.popup.ratesTable.Guests')}
        </span>
      </Col>

      <Col span={2}>
        {regularRate.Type === AgeTypeEnum.Child && (
          <Field
            type="number"
            min={0}
            step={1}
            precision={0}
            name={`Rates.${ratePosition}.RegularRates.${regularRateIndex}.AgeMin`}
            component={InputNumberContainer}
          />
        )}

        {isMinGuestVisible && (
          <Field
            disabled
            type="number"
            min={0}
            step={1}
            precision={0}
            name={`Rates.${ratePosition}.RegularRates.${regularRateIndex}.MinOccupancy`}
            component={InputNumberContainer}
          />
        )}
      </Col>

      <Col span={2}>
        {isAgeRangeVisible && (
          <Field
            type="number"
            min={0}
            step={1}
            precision={0}
            disabled={isMaxAgeDisabled}
            name={`Rates.${ratePosition}.RegularRates.${regularRateIndex}.AgeMax`}
            component={InputNumberContainer}
          />
        )}

        {isGuestRangeVisible && (
          <Field
            type="number"
            min={regularRate.MinOccupancy}
            step={1}
            precision={0}
            name={`Rates.${ratePosition}.RegularRates.${regularRateIndex}.MaxOccupancy`}
            component={InputNumberContainer}
          />
        )}
      </Col>

      <Col span={3}>
        <Field
          min={0}
          type="number"
          precision={2}
          step={0.01}
          parser={handleNumberParser}
          name={`Rates.${ratePosition}.RegularRates.${regularRateIndex}.Cost`}
          component={InputNumberContainer}
        />
      </Col>

      <Col span={3}>
        <Field
          min={0}
          type="number"
          precision={2}
          step={0.01}
          parser={handleNumberParser}
          name={`Rates.${ratePosition}.RegularRates.${regularRateIndex}.CostBeforeTax`}
          component={InputNumberContainer}
        />
      </Col>

      {NetRateVisible && (
        <Col span={3}>
          <Field
            min={0}
            disabled={!NetRateEditable}
            type="text"
            precision={2}
            step={0.01}
            parser={handleNumberParser}
            name={`Rates.${ratePosition}.RegularRates.${regularRateIndex}.Rate`}
            component={InputNumberContainer}
          />
        </Col>
      )}

      <Col span={3}>
        <Field
          min={0}
          type="text"
          precision={2}
          step={0.01}
          parser={handleNumberParser}
          name={`Rates.${ratePosition}.RegularRates.${regularRateIndex}.Retail`}
          component={InputNumberContainer}
        />
      </Col>

      {regularRates.length > 1 && (
        <Col className="text-center regular-rate-field-row">
          <Button
            size="small"
            shape="circle"
            icon={<CloseOutlined />}
            onClick={() => onDelete(regularRateIndex)}
          />
        </Col>
      )}
    </Row>
  );
}

export default withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
}))(ProductRate);
