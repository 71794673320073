import React, { useState } from 'react';
import { Button } from 'antd';
import intl from 'react-intl-universal';

type Props = {
  children: React.ReactElement[];
  limit: number;
  lessLabel?: string | (({ limit, restItemsCount }) => string);
  moreLabel?: string | (({ limit, restItemsCount }) => string);
  className?: string;
  onMore?: () => void;
};

export default function LessMoreList(props: Props) {
  const {
    children,
    limit,
    lessLabel = intl.get('less'),
    moreLabel = intl.get('more'),
    className,
    onMore,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const itemsToShow = isOpen ? children : children.slice(0, limit);

  const restItemsCount = children.length - limit;

  const formattedLessLabel =
    typeof lessLabel === 'function'
      ? lessLabel({ limit, restItemsCount })
      : lessLabel;

  const formattedMoreLabel =
    typeof moreLabel === 'function'
      ? moreLabel({ limit, restItemsCount })
      : moreLabel;

  const label = isOpen ? formattedLessLabel : formattedMoreLabel;

  const onClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
    ev.stopPropagation();

    if (onMore) {
      onMore();

      return;
    }

    setIsOpen((open) => !open);
  };

  return (
    <div className={className}>
      {itemsToShow}

      {children.length > limit && (
        <Button block size="small" type="link" onClick={onClick}>
          {label}
        </Button>
      )}
    </div>
  );
}
