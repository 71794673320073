import React from 'react';
import intl from 'react-intl-universal';
import { Field, useFormikContext } from 'formik';
import { Row, Col, Collapse, CollapsePanelProps, Space } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { compact, isEmpty } from 'lodash';

import {
  HtmlEditorContainer,
  SelectContainer,
  ErrorSectionMessageContainer,
} from 'components/FormContainers';
import { getVisibilityOptionLabel } from 'utils/rateUtils';
import { withStore } from 'hocs';
import { SelectOptionModel } from 'models/selectOption.model';
import { ProductFormValuesModel } from 'models/inventory/productFormValues.model';
import { CommonStore } from 'stores/common.store';
import { InventoryStore } from 'stores/inventory.store';
import { VisibilityEnum } from 'models/enums/visibility.enum';
import ProductUpload from './ProductUpload';
import ProductDescriptionPanelActions from './ProductDescriptionPanelActions';

const MAX_LONG_INPUT_WORDS = 20000;

type Props = {
  index: number;
  extraData: any;
  categoriesOptions: SelectOptionModel[];
  commonStore?: CommonStore;
  inventoryStore?: InventoryStore;
  onRemove: (key: number) => void;
} & Omit<CollapsePanelProps, 'header'>;

function ProductDescriptionPanel(props: Props) {
  const {
    index,
    extraData,
    categoriesOptions,
    commonStore,
    inventoryStore,
    onRemove: $onRemove,
    ...restProps
  } = props;

  const { values } = useFormikContext<ProductFormValuesModel>();

  const panelFormValues = values.Media[index];
  const panelSection = panelFormValues?.Section;
  const panelKey = panelFormValues?.key;
  const isNegotiatedVisibilitySelected =
    panelFormValues?.Visibility === VisibilityEnum.Negotiated;

  const fileList = extraData.fileList.filter(
    (file) => file.mediaKey === panelKey,
  );

  const languageOptions = commonStore.languages.map((language) => ({
    label: language.Name,
    value: language.Id,
  }));

  const mediaOptions = commonStore.mediaSections.map((mediaSection) => ({
    label: mediaSection.Name,
    value: mediaSection.Id,
  }));

  const visibilityOptions = inventoryStore.visibilityList.map((visibility) => ({
    value: visibility,
    label: getVisibilityOptionLabel(visibility),
  }));

  const headerName = commonStore.mediaSections.find(
    ({ Id }) => Id === panelSection,
  )?.Name;

  const selectedCategory = categoriesOptions.find(
    (option) => option.value === panelFormValues?.CategoryId,
  )?.label;

  const selectedLanguage = languageOptions.find(
    (option) => option.value === panelFormValues?.LanguageId,
  )?.label;

  const getHeader = () => {
    const result = [headerName];
    const selectedValues = compact([selectedCategory, selectedLanguage]);

    if (!isEmpty(selectedValues)) {
      result.push(`(${selectedValues.join(', ')})`);
    }

    return (
      <Space>
        <ErrorSectionMessageContainer
          name={`Media.${index}`}
          render={() => <WarningOutlined style={{ color: 'red' }} />}
        />

        {result.join(' ')}
      </Space>
    );
  };

  const onDelete = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();

    $onRemove(index);
  };

  return (
    <Collapse.Panel
      {...restProps}
      className="new-product-panel"
      extra={
        <ProductDescriptionPanelActions index={index} onDelete={onDelete} />
      }
      header={getHeader()}
    >
      <Row gutter={[15, 0]}>
        <Col span={24}>
          <Field
            label={intl.get('inventory.products.popup.category')}
            name={`Media[${index}].CategoryId`}
            component={SelectContainer}
            options={categoriesOptions}
          />
        </Col>

        <Col span={24}>
          <Field
            label={intl.get('labels.informationType')}
            placeholder={intl.get('labels.selectInformationType')}
            name={`Media[${index}].Section`}
            component={SelectContainer}
            options={mediaOptions}
          />
        </Col>

        <Col span={12}>
          <Field
            label={intl.get('labels.informationVisibility')}
            placeholder={intl.get('labels.selectInformationVisibility')}
            options={visibilityOptions}
            name={`Media[${index}].Visibility`}
            component={SelectContainer}
          />
        </Col>

        {isNegotiatedVisibilitySelected && (
          <Col span={12}>
            <Field
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              label={intl.get('inventory.products.popup.negotiatedWithAgency')}
              placeholder={intl.get('inventory.products.popup.selectAgency')}
              options={inventoryStore.agenciesOptions}
              name={`Media[${index}].AgencyId`}
              component={SelectContainer}
            />
          </Col>
        )}

        <Col span={isNegotiatedVisibilitySelected ? 24 : 12}>
          <Field
            label={intl.get('labels.language')}
            placeholder={intl.get('labels.selectLanguage')}
            name={`Media[${index}].LanguageId`}
            component={SelectContainer}
            options={languageOptions}
          />
        </Col>

        <Col span={24}>
          <Field
            className="m-b-40"
            name={`Media[${index}].FormattedText`}
            maxWords={MAX_LONG_INPUT_WORDS}
            component={HtmlEditorContainer}
          />
        </Col>

        {panelSection === commonStore.settings.MainMediaSection && (
          <Col span={24}>
            <ProductUpload
              mediaKey={panelKey}
              fileList={fileList}
              uploadImage={extraData.uploadImage}
              updateImage={extraData.updateImage}
              removeUploadedImage={extraData.removeUploadedImage}
            />
          </Col>
        )}
      </Row>
    </Collapse.Panel>
  );
}

export default withStore(({ rootStore }) => ({
  commonStore: rootStore.commonStore,
  inventoryStore: rootStore.inventoryStore,
}))(ProductDescriptionPanel);
