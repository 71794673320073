import intl from 'react-intl-universal';
import * as Yup from 'yup';

import { TABLE_ORDERS_BLANK_FILTER_VALUE } from 'utils/constants';

export const TableOrdersListFilterSchema = Yup.object().shape({
  search: Yup.string()
    .when('allowBlankEntry', {
      is: false,
      then: Yup.string().required(() => intl.get('required')),
    })
    .duplicatedIn(
      'filterOptions',
      () => intl.get('duplicatesAreNotAllowed'),
      (thisValue, otherValue) => {
        const value = thisValue as string;
        const other = otherValue as string;

        return (
          value?.toLowerCase() === other?.toLowerCase() ||
          (!value && other === TABLE_ORDERS_BLANK_FILTER_VALUE)
        );
      },
    ),
});
