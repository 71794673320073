/* eslint-disable no-param-reassign */
import React, { Component, LegacyRef } from 'react';
import classnames from 'classnames';
import { UploadFile } from 'antd/lib/upload/interface';
import transliterate from 'transliterate';

import {
  getBase64,
  getImageElementBySrc,
} from '../../../../../../utils/formatter';
import { ImageSizeEnum } from '../../../../../../utils/constants';
import { addGlobalMessage } from '../../../../../SharedComponents/GlobalMessages';
import { MessageTypeEnum } from '../../../../../../models/global-messages/message.model';
import { ImagesStatusEnum } from '../../../../../../models/enums/imagesStatus';
import { CommonStore } from '../../../../../../stores/common.store';
import ProductUploadDetails from './ProductUploadDetails';
import PrimaryImageUpload from './PrimaryImageUpload';
import AdditionalImageUpload from './AdditionalImageUpload';
import { withStore } from '../../../../../../hocs';

export type PreviewFile = UploadFile & {
  fileName?: string;
  thumbUrl: string;
  apiSize: ImageSizeEnum | ImageSizeEnum[];
  caption?: string;
  isPrimary?: boolean;
  status?: string;
  response?: string;

  apiId?: number;
  mediaKey?: string;
  apiImagePath?: string;
  isApply?: boolean;
  aspect?: number;
  width?: number;
  height?: number;
};

type ProductUploadProps = {
  fileList: PreviewFile[];
  uploadImage: Function;
  updateImage: Function;
  removeUploadedImage: Function;
  mediaKey: string;
  commonStore?: CommonStore;
};

type ProductUploadState = {
  previewFile?: PreviewFile;
};

@withStore(({ rootStore }) => ({
  commonStore: rootStore.commonStore,
}))
export default class ProductUpload extends Component<ProductUploadProps> {
  state: ProductUploadState = {
    previewFile: null,
  };

  get primaryFileList() {
    const { fileList } = this.props;

    return fileList.filter((file) => file.isPrimary);
  }

  get additionalFileList() {
    const { fileList } = this.props;

    return fileList.filter((file) => !file.isPrimary);
  }

  handlePreview = (previewFile) => {
    this.setState({
      previewFile,
    });
  };

  handlePreviewSubmit = (values) => {
    const { updateImage, mediaKey } = this.props;
    const { previewFile } = this.state;

    updateImage({
      ...previewFile,
      isApply: true,
      size: previewFile.size,
      caption: values.caption,
      mediaKey,
    });

    this.setState({ previewFile: null });
  };

  handleClosePreview = () => {
    const { previewFile } = this.state;

    if (!(previewFile.apiId || previewFile.isApply)) {
      this.handleRemoveFile(previewFile);
    }

    this.setState({
      previewFile: null,
    });
  };

  handleEditPreviewFile = (file: UploadFile) => {
    const { previewFile } = this.state;

    const editedFile = file.originFileObj as unknown as PreviewFile;

    editedFile.uid = previewFile.uid;
    editedFile.isPrimary = previewFile.isPrimary;
    editedFile.apiId = previewFile.apiId;
    editedFile.mediaKey = previewFile.mediaKey;
    editedFile.apiImagePath = previewFile.apiImagePath;
    editedFile.aspect = previewFile.aspect;
    editedFile.apiSize = previewFile.apiSize;

    this.handlePrepareFile(editedFile, (newFile) => {
      this.setState({ previewFile: newFile });
    });
  };

  handleBeforeCrop = (file: UploadFile | any) => {
    const {
      commonStore: { settings },
    } = this.props;

    return file.size <= settings.MaxImageSize;
  };

  handlePrepareFile = (file, cb?: (file: File) => void) => {
    getBase64(file).then(async (thumbUrl) => {
      const img = await getImageElementBySrc(thumbUrl as string);

      file.fileName = transliterate(file.name);
      file.thumbUrl = thumbUrl as string;
      file.apiSize = file.apiSize || ImageSizeEnum.LARGE;
      file.status = file.isPrimary
        ? ImagesStatusEnum.PRIMARY
        : ImagesStatusEnum.DONE;
      file.width = img.width;
      file.height = img.height;

      cb?.(file);
    });
  };

  handleApplyCrop = (file) => {
    const { uploadImage } = this.props;

    this.handlePrepareFile(file, (newFile) => {
      uploadImage(newFile);
      this.handlePreview(newFile);
    });

    return true;
  };

  handleRemoveFile = (file: PreviewFile) => {
    const { removeUploadedImage } = this.props;

    if (file.isPrimary && this.primaryFileList.length === 1) {
      addGlobalMessage({
        message: 'The product must have a Primary Image',
        type: MessageTypeEnum.error,
      });

      return;
    }

    removeUploadedImage(file);
  };

  render() {
    const { commonStore } = this.props;
    const { previewFile } = this.state;

    return (
      <div className="flx-1">
        <div className={classnames('product-uploader')}>
          <div className="m-b-20">
            <PrimaryImageUpload
              fileList={this.primaryFileList}
              siteSettings={commonStore.siteSettings}
              onRemove={(file) => {
                this.handleRemoveFile(file as PreviewFile);
              }}
              onPreview={this.handlePreview}
              beforeCrop={this.handleBeforeCrop}
              onApplyCrop={this.handleApplyCrop}
            />
          </div>

          <AdditionalImageUpload
            fileList={this.additionalFileList}
            onRemove={(file) => {
              this.handleRemoveFile(file as PreviewFile);
            }}
            onPreview={this.handlePreview}
            beforeCrop={this.handleBeforeCrop}
            onApplyCrop={this.handleApplyCrop}
          />
        </div>

        {!!previewFile && (
          <ProductUploadDetails
            previewFile={previewFile}
            maxSize={commonStore.settings.MaxImageSize}
            beforeCrop={this.handleBeforeCrop}
            onEditImage={this.handleEditPreviewFile}
            onCancel={this.handleClosePreview}
            onSubmit={this.handlePreviewSubmit}
          />
        )}
      </div>
    );
  }
}
