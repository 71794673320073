import { observable, action, makeObservable } from 'mobx';
import intl from 'react-intl-universal';
import filterBuilder from 'odata-filter-builder';

import { RootStore } from './root.store';
import { NotificationTemplatesApi } from '../api/notificationTemplates.api';
import { NotificationTemplate } from '../models/notification-template/notificationTemplate.model';
import { addGlobalMessage } from '../components/SharedComponents/GlobalMessages';
import { MessageTypeEnum } from '../models/global-messages/message.model';

export class MessageTemplatesStore {
  private rootStore: RootStore;
  private apiService: NotificationTemplatesApi;

  @observable public notificationTemplates: NotificationTemplate[] = [];

  @observable public searchQuery = '';

  @observable public pagination = {
    total: 0,
    page: 1,
    pageSize: 10,
  };

  constructor(rootStore: RootStore, apiService: NotificationTemplatesApi) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.apiService = apiService;
  }

  @action
  public changeSearchQuery(searchQuery: string) {
    this.searchQuery = searchQuery;

    this.changePagination(1);
  }

  @action
  public changePagination(page?: number, pageSize?: number, total?: number) {
    this.pagination = {
      total: total ?? this.pagination.total,
      page: page ?? this.pagination.page,
      pageSize: pageSize ?? this.pagination.pageSize,
    };
  }

  private buildTemplatesFilter() {
    if (!this.searchQuery) return null;

    return filterBuilder()
      .contains((x) => x.toLower('Description'), this.searchQuery.toLowerCase())
      .toString();
  }

  @action
  public async getNotificationTemplates() {
    try {
      const params = {
        $count: true,
        $filter: this.buildTemplatesFilter(),
        $skip:
          this.pagination.page * this.pagination.pageSize -
          this.pagination.pageSize,
        $top: this.pagination.pageSize,
      };

      const response = await this.apiService.getNotificationTemplates(params);

      this.changePagination(null, null, response.data['@odata.count']);

      this.notificationTemplates = response.data.value;
    } catch (error) {
      addGlobalMessage({
        message: intl.get('messageTemplates.loadTemplatesFail'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async getNotificationTemplate(id: number) {
    try {
      const response = await this.apiService.getNotificationTemplate(id);

      return response.data;
    } catch (error) {
      addGlobalMessage({
        message: intl.get('messageTemplates.loadTemplateFail'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async createNotificationTemplate(
    notificationTemplate: NotificationTemplate,
  ) {
    try {
      const response = await this.apiService.createNotificationTemplate(
        notificationTemplate,
      );

      await this.getNotificationTemplates();

      addGlobalMessage({
        message: intl.get('messageTemplates.createSuccess'),
        type: MessageTypeEnum.success,
      });

      return response.data;
    } catch (error) {
      addGlobalMessage({
        message: intl.get('messageTemplates.createFail'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async updateNotificationTemplate(
    id: number,
    notificationTemplate: NotificationTemplate,
  ) {
    try {
      await this.apiService.updateNotificationTemplate(
        id,
        notificationTemplate,
      );

      await this.getNotificationTemplates();

      addGlobalMessage({
        message: intl.get('messageTemplates.updateSuccess'),
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      addGlobalMessage({
        message: intl.get('messageTemplates.updateFail'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async deleteNotificationTemplate(id: number) {
    try {
      await this.apiService.deleteNotificationTemplate(id);

      await this.getNotificationTemplates();

      addGlobalMessage({
        message: intl.get('messageTemplates.deleteSuccess'),
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      addGlobalMessage({
        message: intl.get('messageTemplates.deleteFail'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action public clearStore() {
    this.notificationTemplates = [];
  }
}
