import React, { useState, useRef } from 'react';
import { Tooltip, Space, Popover } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import InventoryBulkViewEditAvailability from './InventoryBulkViewEditAvailability';
import { BulkInventoryEditAvailabilityModel } from '../../models/form-models/BulkInventoryEditAvailability.model';
import { InventoryModel } from '../../models/inventory/inventory.model';
import { formatTableCell } from '../../utils/bulkInventoryUtils';

type Props = {
  inventory: InventoryModel;
  isEditable: boolean;
  onSave: Function;
};

function InventoryBulkViewAvailabilityCell(props: Props) {
  const { inventory, isEditable, onSave } = props;

  const containerRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    availabilityQuantity: inventory?.AvailableQuantity ?? 1,
    stopSell: inventory?.StopSell ?? false,
  };

  const handleSave = async (values: BulkInventoryEditAvailabilityModel) => {
    try {
      const { availabilityQuantity, stopSell } = values;

      setIsLoading(true);

      const inventoryToSave = inventory
        ? {
            ...inventory,
            AvailableQuantity: availabilityQuantity,
            StopSell: stopSell,
          }
        : {
            ProductType: inventory.ProductType,
            ProductId: inventory.ProductId,
            CategoryId: inventory.CategoryId,
            Time: inventory.Time || undefined,
            Date: inventory.Date,
            AvailableQuantity: availabilityQuantity,
            StopSell: stopSell,
          };

      await onSave(inventoryToSave);
    } finally {
      setIsLoading(false);

      setIsVisible(false);
    }
  };

  const handlePopoverVisibleChange = (visible: boolean) => {
    if (isLoading) return;

    setIsVisible(visible);
  };

  const getCellContent = () => (
    <div
      ref={containerRef}
      className={classnames('inventory-bulk-view-availability-cell', {
        'editable-cell': isEditable,
      })}
    >
      <Space>
        {inventory.StopSell && (
          <span>
            <Tooltip placement="bottom" title="Stop Sell">
              <CloseOutlined className="stop-sell-icon stop-sell-icon_red" />
            </Tooltip>
          </span>
        )}

        <span className="inventory-bulk-view-availability-cell-quantity">
          {formatTableCell(inventory.AvailableQuantity, inventory.BookedCount)}
        </span>
      </Space>
    </div>
  );

  if (!inventory) return null;

  return isEditable ? (
    <Popover
      destroyTooltipOnHide
      trigger="click"
      visible={isVisible}
      content={
        <InventoryBulkViewEditAvailability
          isLoading={isLoading}
          initialValues={initialValues}
          onSave={handleSave}
        />
      }
      getPopupContainer={() => containerRef.current}
      onVisibleChange={handlePopoverVisibleChange}
    >
      {getCellContent()}
    </Popover>
  ) : (
    getCellContent()
  );
}

export default React.memo(InventoryBulkViewAvailabilityCell);
