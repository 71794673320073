import moment from 'moment';
import { xorWith } from 'lodash';

import { CardInCartModel } from '../models/cardInCart.model';

export const checkIfProductHoldActive = (cardInCart: CardInCartModel) => {
  if (!cardInCart.holdInfo) return false;

  return moment().isSameOrBefore(moment(cardInCart.holdInfo.holdEndDate));
};

export const getSortedActiveHoldCardsInCart = (
  cardsInCart: CardInCartModel[],
) =>
  cardsInCart.filter(checkIfProductHoldActive).sort((a, b) => {
    const momentEndDateA = moment(a?.holdInfo?.holdEndDate);
    const momentEndDateB = moment(b?.holdInfo?.holdEndDate);

    return (momentEndDateA as any) - (momentEndDateB as any);
  });

export const getProductExpirationInMsByEndDate = (endDate: string) =>
  (moment(endDate) as any) - (moment() as any);

export const getExpiredCardsInCard = (cardsInCart: CardInCartModel[]) =>
  cardsInCart.filter(
    (cardInCart) =>
      cardInCart.holdInfo && !checkIfProductHoldActive(cardInCart),
  );

export const getInitialCardsInCart = (cardsInCart: CardInCartModel[]) => {
  const expiredCardsInCart = getExpiredCardsInCard(cardsInCart);

  return xorWith(cardsInCart, expiredCardsInCart, (a, b) => a.id === b.id);
};
