import moment, { Moment } from 'moment';
import intl from 'react-intl-universal';

import { DateRangeTemplateEnum } from 'models/enums/dateRangeTemplate.enum';
import { MomentRangeModel } from 'models/momentRange.model';

export const isSameDatesTimes = (times: string[]) =>
  times.every((time) => {
    const firstTime = moment(times[0], 'HH:mm');

    const thisTime = moment(time, 'HH:mm');

    return firstTime.isSame(thisTime);
  });

export const parseServiceTime = (serviceTime: string) =>
  moment(serviceTime).isValid() ? moment(serviceTime, 'HHmm') : null;

export const toServiceTime = (time: string | Moment) =>
  moment(time).format('HHmm');

export const getDateRangeTemplateLabel = (
  dateRangeTemplate: DateRangeTemplateEnum,
) =>
  ({
    [DateRangeTemplateEnum.YearToDate]: intl.get('labels.yearToDate'),
    [DateRangeTemplateEnum.PrevYear]: intl.get('labels.prevYear'),
    [DateRangeTemplateEnum.Prev12Months]: intl.get('labels.prev12Months'),
    [DateRangeTemplateEnum.PrevQuarter]: intl.get('labels.prevQuarter'),
    [DateRangeTemplateEnum.Prev3Months]: intl.get('labels.prev3Months'),
    [DateRangeTemplateEnum.Prev30Days]: intl.get('labels.prev30Days'),
    [DateRangeTemplateEnum.Prev7Days]: intl.get('labels.prev7Days'),
    [DateRangeTemplateEnum.Yesterday]: intl.get('labels.yesterday'),
    [DateRangeTemplateEnum.Today]: intl.get('labels.today'),
    [DateRangeTemplateEnum.Tomorrow]: intl.get('labels.tomorrow'),
    [DateRangeTemplateEnum.ThisWeek]: intl.get('labels.thisWeek'),
    [DateRangeTemplateEnum.ThisQuarter]: intl.get('labels.thisQuarter'),
    [DateRangeTemplateEnum.ThisMonth]: intl.get('labels.thisMonth'),
    [DateRangeTemplateEnum.ThisYear]: intl.get('labels.thisYear'),
    [DateRangeTemplateEnum.Next7Days]: intl.get('labels.next7Days'),
    [DateRangeTemplateEnum.Next30Days]: intl.get('labels.next30Days'),
    [DateRangeTemplateEnum.Next3Months]: intl.get('labels.next3Months'),
    [DateRangeTemplateEnum.NextQuatter]: intl.get('labels.nextQuarter'),
    [DateRangeTemplateEnum.Next12Months]: intl.get('labels.next12Months'),
    [DateRangeTemplateEnum.NextYear]: intl.get('labels.nextYear'),
  }[dateRangeTemplate]);

const getDateRangeTemplatesMap = (): Record<
  DateRangeTemplateEnum,
  MomentRangeModel
> => ({
  [DateRangeTemplateEnum.YearToDate]: [
    moment().startOf('year'),
    moment().endOf('day'),
  ],
  [DateRangeTemplateEnum.PrevYear]: [
    moment().subtract(1, 'year').startOf('year'),
    moment().subtract(1, 'year').endOf('year'),
  ],
  [DateRangeTemplateEnum.Prev12Months]: [
    moment().subtract(12, 'month').startOf('day'),
    moment().endOf('day'),
  ],
  [DateRangeTemplateEnum.PrevQuarter]: [
    moment().subtract(1, 'quarter').startOf('quarter'),
    moment().subtract(1, 'quarter').endOf('quarter'),
  ],
  [DateRangeTemplateEnum.Prev3Months]: [
    moment().subtract(3, 'month').startOf('day'),
    moment().endOf('day'),
  ],
  [DateRangeTemplateEnum.Prev30Days]: [
    moment().subtract(30, 'days').startOf('day'),
    moment().endOf('day'),
  ],
  [DateRangeTemplateEnum.Prev7Days]: [
    moment().subtract(7, 'days').startOf('day'),
    moment().endOf('day'),
  ],
  [DateRangeTemplateEnum.Yesterday]: [
    moment().subtract(1, 'day').startOf('day'),
    moment().subtract(1, 'day').endOf('day'),
  ],
  [DateRangeTemplateEnum.Today]: [
    moment().startOf('day'),
    moment().endOf('day'),
  ],
  [DateRangeTemplateEnum.Tomorrow]: [
    moment().add(1, 'day').startOf('day'),
    moment().add(1, 'day').endOf('day'),
  ],
  [DateRangeTemplateEnum.ThisWeek]: [
    moment().startOf('week'),
    moment().endOf('week'),
  ],
  [DateRangeTemplateEnum.ThisQuarter]: [
    moment().startOf('quarter'),
    moment().endOf('quarter'),
  ],
  [DateRangeTemplateEnum.ThisMonth]: [
    moment().startOf('month'),
    moment().endOf('month'),
  ],
  [DateRangeTemplateEnum.ThisYear]: [
    moment().startOf('year'),
    moment().endOf('year'),
  ],
  [DateRangeTemplateEnum.Next7Days]: [
    moment().startOf('day'),
    moment().add(7, 'days').endOf('day'),
  ],
  [DateRangeTemplateEnum.Next30Days]: [
    moment().startOf('day'),
    moment().add(30, 'days').endOf('day'),
  ],
  [DateRangeTemplateEnum.Next3Months]: [
    moment().startOf('day'),
    moment().add(3, 'month').endOf('day'),
  ],
  [DateRangeTemplateEnum.NextQuatter]: [
    moment().add(1, 'quarter').startOf('quarter'),
    moment().add(1, 'quarter').endOf('quarter'),
  ],
  [DateRangeTemplateEnum.Next12Months]: [
    moment().startOf('day'),
    moment().add(12, 'month').endOf('day'),
  ],
  [DateRangeTemplateEnum.NextYear]: [
    moment().add(1, 'year').startOf('year'),
    moment().add(1, 'year').endOf('year'),
  ],
});

export const getIsDateRangeTemplateValid = (
  dateRangeTemplate: string,
): dateRangeTemplate is DateRangeTemplateEnum =>
  Object.keys(getDateRangeTemplatesMap()).includes(dateRangeTemplate);

export const getDateRangeByTemplate = (
  dateRangeTemplate: DateRangeTemplateEnum,
): MomentRangeModel => getDateRangeTemplatesMap()[dateRangeTemplate];

export const normalizeDateRange = (
  dateRange: MomentRangeModel,
  format = 'YYYY-MM-DD',
) => {
  const [from, to] = dateRange;

  return [
    from.utc().startOf('d').format(format),
    to.utc().endOf('d').format(format),
  ];
};
