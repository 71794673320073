import { AgeTypeEnum } from 'models/enums/ageType.enum';
import { getBearerToken } from './formatter';

export const serverUrl = process.env.REACT_APP_SERVER_URL;
export const generalToken = getBearerToken(
  process.env.REACT_APP_GENERAL_ACCESS_TOKEN,
);
export const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
export const supportTechEmail = process.env.REACT_APP_SUPPORT_TECH_EMAIL;
export const supportOrdersEmail = process.env.REACT_APP_SUPPORT_ORDERS_EMAIL;
export const supportAccountEmail = process.env.REACT_APP_SUPPORT_ACCOUNT_EMAIL;
export const secretCode = process.env.REACT_APP_SECRET_CODE;
export const searchApiKey = process.env.REACT_APP_SEARCH_API_KEY;
export const mapBoxToken = process.env.REACT_APP_MAPBOX_TOKEN;

export const ROLES = {
  STAFF: {
    name: 'Staff',
    value: 'Staff',
  },
  STAFF_ADMIN: {
    name: 'Staff Admin',
    value: 'StaffAdmin',
  },
  SUPPLIER_CLERK: {
    name: 'Supplier Clerk',
    value: 'SupplierClerk',
  },
  SUPPLIER_ADMIN: {
    name: 'Supplier Admin',
    value: 'SupplierAdmin',
  },
  MANAGER: {
    name: 'Manager',
    value: 'Manager',
  },
  AGENT: {
    name: 'Agent',
    value: 'Agent',
  },
};

export const USER_ROLE_GROUPS = {
  ADMINS: [ROLES.STAFF_ADMIN.value, ROLES.SUPPLIER_ADMIN.value],
  SUPPLIER: [ROLES.SUPPLIER_ADMIN.value, ROLES.SUPPLIER_CLERK.value],
  STAFF: [ROLES.STAFF.value, ROLES.STAFF_ADMIN.value],
  AGENCY: [ROLES.AGENT.value, ROLES.MANAGER.value],
  MARKETPLACE: [
    ROLES.AGENT.value,
    ROLES.MANAGER.value,
    ROLES.STAFF.value,
    ROLES.STAFF_ADMIN.value,
  ],
  ALL: [
    ROLES.SUPPLIER_ADMIN.value,
    ROLES.SUPPLIER_CLERK.value,
    ROLES.AGENT.value,
    ROLES.MANAGER.value,
    ROLES.STAFF.value,
    ROLES.STAFF_ADMIN.value,
  ],
};

export const MANAGE_ORDERS_ROLES = [
  ROLES.SUPPLIER_ADMIN.value,
  ROLES.SUPPLIER_CLERK.value,
  ROLES.STAFF_ADMIN.value,
];

export enum ImageSizeEnum {
  LARGE = 'Large',
  MEDIUM = 'Medium',
  SMALL = 'Small',
  THUMBNAIL = 'Thumbnail',
}

export const PACKAGE_AGE_TYPES = [
  AgeTypeEnum.Single,
  AgeTypeEnum.Child,
  AgeTypeEnum.Junior,
  AgeTypeEnum.Senior,
];

export const SERVICE_AGE_TYPES = [
  AgeTypeEnum.Adult,
  AgeTypeEnum.Group,
  AgeTypeEnum.Child,
  AgeTypeEnum.Junior,
  AgeTypeEnum.Senior,
];

export enum FilterDuration {
  upToOneHour = 'upToOneHour',
  oneHoursToFourHours = 'oneHoursToFourHours',
  fourHoursToOneDay = 'fourHoursToOneDay',
  multipleDays = 'multipleDays',
}

export enum GuestTypesEnum {
  INFANT = 'INF',
  ADULT = 'ADULT',
  CHD = 'CHD',
  JUNIOR = 'JR',
  SENIOR = 'SR',
}

export const CHILD_MAX_AGE = 17;

export const EMPTY_VALUE = 'EMPTY_VALUE';

export const DEFAULT_AGENCY = 'TARIFF';

export const FORBIDDEN_ERROR_CODE = 'Forbidden';

export const TABLE_ORDERS_BLANK_FILTER_LABEL = '<blank>';

export const TABLE_ORDERS_BLANK_FILTER_VALUE = 'null';
