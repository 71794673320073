import React from 'react';
import CodeEditor, {
  TextareaCodeEditorProps,
} from '@uiw/react-textarea-code-editor';
import { FieldProps, getIn } from 'formik';
import classnames from 'classnames';

type OwnProps = {
  label?: string;
  height?: number | string;
  className?: string;
};

type Props = OwnProps & FieldProps & TextareaCodeEditorProps;

function CodeEditorContainer(props: Props) {
  const {
    field,
    form,
    label,
    className,
    height = 'auto',
    ...restProps
  } = props;

  const hasError =
    getIn(form.errors, field.name) && getIn(form.touched, field.name);

  return (
    <div className={classnames('field', { 'has-error': hasError }, className)}>
      {label && <h4 className="field-label">{label}</h4>}

      <div style={{ height, overflow: 'auto' }}>
        <CodeEditor {...restProps} {...field} />
      </div>

      {hasError && (
        <div className="field-error visible">
          {getIn(form.errors, field.name)}
        </div>
      )}
    </div>
  );
}

export default React.memo(CodeEditorContainer);
