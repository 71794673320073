import React from 'react';
import intl from 'react-intl-universal';
import { ToolbarProps, Views, Navigate } from 'react-big-calendar';
import { Button, Radio, Space, Row, Col } from 'antd';

import { EventModel } from 'models/inventory/event.model';
import ProductDetailsCalendarNav from './ProductDetailsCalendarNav';

type Props = ToolbarProps<EventModel>;

export default function ProductAvailabilityCalendarToolbar(props: Props) {
  const { date, view, onView, onNavigate } = props;

  return (
    <Row align="bottom" justify="space-between" className="m-b-10">
      <Col>
        <Button onClick={() => onNavigate(Navigate.TODAY)}>
          {intl.get('productPage.today')}
        </Button>
      </Col>

      <Col>
        <ProductDetailsCalendarNav
          date={date}
          view={view}
          onNavigate={onNavigate}
        />
      </Col>

      <Col>
        <Space>
          <Radio.Group
            value={view}
            onChange={(event) => onView(event.target.value)}
          >
            <Radio.Button value={Views.DAY}>1</Radio.Button>
            <Radio.Button value={Views.WEEK}>7</Radio.Button>
            <Radio.Button value={Views.MONTH}>30</Radio.Button>
          </Radio.Group>
        </Space>
      </Col>
    </Row>
  );
}
