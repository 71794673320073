import { isEmpty, intersectionBy } from 'lodash';
import * as Yup from 'yup';
import { OrderCommunicationMethodEnum } from '../../../../models/enums/orderCommunicationMethod.enum';
import { OrderCommunicationType } from '../../../../models/enums/orderCommunicationType.enum';
import { OrderTableDataModel } from '../../../../models/orders/orderTableData.model';
import { BulkCommunicationFormValuesModel } from '../../../../models/bulkCommunicationFormValues.model';
import {
  isEmailFieldVisible,
  isMessageTextVisible,
} from '../utils/orderCommunicationUtils';
import { validateHandlebar } from '../../../../utils/validation/validators';

interface TestContextExtended {
  options: Yup.TestOptions & {
    context: {
      values: BulkCommunicationFormValuesModel;
      orders: OrderTableDataModel[];
      primaryMethod: string;
    };
  };
}

export const BulkCommunicationSchema = Yup.object().shape({
  messageType: Yup.number().required('required'),
  communicationMethod: Yup.number().required('required'),
  template: Yup.string().when('messageType', {
    is: (messageType) =>
      messageType === OrderCommunicationType.PredefinedTemplate,
    then: Yup.string().required('required'),
  }),
  emailTemplate: Yup.object().test(
    'is-email-template-valid',
    '',
    function validateTemplate(
      value: BulkCommunicationFormValuesModel['emailTemplate'],
    ) {
      const { options } = this as Yup.TestContext & TestContextExtended;
      const { html } = value;

      const model = this.parent.selectedOrders[0];
      const validationError = validateHandlebar(html, model);
      const validationMessage = html ? validationError : 'Required';
      const message = isEmailFieldVisible(
        options.context?.values,
        options.context?.primaryMethod,
      )
        ? validationMessage
        : '';

      return this.createError({ message });
    },
  ),
  messageText: Yup.string().test(
    'is-message-text-required',
    'Required',
    function (value: string) {
      const { options } = this as Yup.TestContext & TestContextExtended;

      return isMessageTextVisible(
        options.context?.values,
        options.context?.primaryMethod,
      )
        ? Boolean(value)
        : true;
    },
  ),
  emailSubject: Yup.string().test(
    'emailSubject',
    'Required',
    function (value: string) {
      const { options } = this as Yup.TestContext & TestContextExtended;

      return isEmailFieldVisible(
        options.context?.values,
        options.context?.primaryMethod,
      )
        ? Boolean(value)
        : true;
    },
  ),
  selectedOrders: Yup.array()
    .test(
      'is-selected-orders-visible',
      'Selected orders are not visible',
      function (value: OrderTableDataModel[]) {
        const { options } = this as Yup.TestContext & TestContextExtended;

        const orders = options.context?.orders || [];

        return !isEmpty(intersectionBy(value, orders, (order) => order.TripId));
      },
    )
    .of(
      Yup.object().shape({
        PrimaryPhone: Yup.string().test(
          'is-primary-phone-required',
          'required',
          function (value) {
            const { options } = this as Yup.TestContext & TestContextExtended;

            const { communicationMethod } = options.context?.values || {};

            switch (communicationMethod) {
              case OrderCommunicationMethodEnum.Email:
                return true;

              case OrderCommunicationMethodEnum.SMS:
                return Boolean(value);

              case OrderCommunicationMethodEnum.FirstAvailable:
                return Boolean(this.parent.PrimaryEmail || value);

              default: {
                return true;
              }
            }
          },
        ),
        PrimaryEmail: Yup.string().test(
          'is-primary-email-required',
          'required',
          function (value) {
            const { options } = this as Yup.TestContext & TestContextExtended;

            const { communicationMethod } = options.context?.values || {};

            switch (communicationMethod) {
              case OrderCommunicationMethodEnum.SMS:
                return true;

              case OrderCommunicationMethodEnum.Email:
                return Boolean(value);

              case OrderCommunicationMethodEnum.FirstAvailable:
                return Boolean(this.parent.PrimaryPhone || value);

              default: {
                return true;
              }
            }
          },
        ),
      }),
    )
    .required(),
});
