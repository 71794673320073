import React, { ReactElement, useState } from 'react';
import ImgCrop, { ImgCropProps } from 'antd-img-crop';

type OwnProps = {
  aspect?: number;
  children: ReactElement;
  beforeCrop: (file: File, fileList: File[]) => boolean;
};

type Props = ImgCropProps & OwnProps;

function ImageCropper(props: Props) {
  const {
    aspect: $aspect,
    beforeCrop: $beforeCrop,
    children,
    ...restProps
  } = props;
  const [aspect, setAspect] = useState($aspect);

  const setOriginalImageAspect = (file: File) => {
    const url = URL.createObjectURL(file);
    const img = new Image();

    img.src = url;
    img.onload = () => setAspect(img.width / img.height);
  };

  const beforeCrop = (file: File, fileList: File[]) => {
    if (!$aspect) {
      setOriginalImageAspect(file);
    }

    return $beforeCrop ? $beforeCrop?.(file, fileList) : true;
  };

  return (
    <ImgCrop {...restProps} aspect={aspect} beforeCrop={beforeCrop}>
      {children}
    </ImgCrop>
  );
}

export default React.memo(ImageCropper);
