import React, { PureComponent } from 'react';
import * as d3 from 'd3';
import { Alert } from 'antd';
import intl from 'react-intl-universal';

import SeatMapPopover from './SeatMapPopover';
import { SeatModel } from './pricingOptions.model';
import AvailabilitiesLoader from '../AvailabilitiesLoader';

type Props = {
  url: string;
  innerRef: React.RefObject<SVGSVGElement>;
  popoverInfo: {
    metaData: SeatModel;
    offsetX: number;
    offsetY: number;
  };
  isPopoverVisible: boolean;
  onInit: () => void;
};

class SeatMapArea extends PureComponent<Props> {
  state = {
    isLoading: false,
    hasLoadingError: false,
  };

  mapStore = {
    width: 0,
    height: 0,
  };

  containerRef: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount() {
    this.init();
  }

  handleMapZoom = () => {
    const { innerRef } = this.props;
    const { width, height } = this.mapStore;

    d3.select(innerRef.current)
      .select('svg')
      .call(
        // @ts-ignore
        d3
          .zoom()
          .scaleExtent([1, 5])
          .translateExtent([
            [0, 0],
            [width, height],
          ])
          .on('zoom', this.onMapZoom),
      );
  };

  init = async () => {
    try {
      const { url, innerRef, onInit } = this.props;

      this.setState({ isLoading: true });

      const data = await d3.xml(url);

      this.setState({ isLoading: false });

      const svgWidth = +data.documentElement.getAttribute('width');
      const svgHeight = +data.documentElement.getAttribute('height');
      const svgViewBox = `0 0 ${svgWidth} ${svgHeight}`;

      this.mapStore.width = svgWidth;
      this.mapStore.height = svgHeight;

      d3.select(innerRef.current)
        .attr('width', svgWidth)
        .attr('height', svgHeight)
        .attr('viewBox', svgViewBox);

      d3.select(innerRef.current)
        .select('g')
        .node()
        // @ts-ignore
        .append(data.documentElement as any);

      this.handleMapZoom();
      onInit();
    } catch (e) {
      this.setState({ hasLoadingError: true, isLoading: false });
    }
  };

  onMapZoom = ({ transform }) => {
    const { innerRef } = this.props;

    d3.select(innerRef.current).select('g').attr('transform', transform);
  };

  render() {
    const { innerRef, isPopoverVisible, popoverInfo } = this.props;
    const { isLoading, hasLoadingError } = this.state;

    if (hasLoadingError) {
      return (
        <Alert
          message={intl.get('tourDetails.seatMap.loadingError')}
          type="error"
        />
      );
    }

    return (
      <div ref={this.containerRef}>
        {isLoading ? (
          <AvailabilitiesLoader />
        ) : (
          <div className="seat-map">
            <svg ref={innerRef}>
              <g />
            </svg>

            <SeatMapPopover
              isPopoverVisible={isPopoverVisible}
              popoverInfo={popoverInfo}
              getPopupContainer={() => this.containerRef.current}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SeatMapArea;
