import React from 'react';
import { Button, Result } from 'antd';
import { FallbackProps } from 'react-error-boundary';
import intl from 'react-intl-universal';

function ErrorBoundaryFallback(props: FallbackProps) {
  const { resetErrorBoundary } = props;

  return (
    <Result
      status="error"
      title={intl.get('errorBoundary.title')}
      subTitle={intl.get('errorBoundary.description')}
      extra={[
        <Button type="primary" key="console" onClick={resetErrorBoundary}>
          {intl.get('buttons.goHome')}
        </Button>,
      ]}
    />
  );
}

export default ErrorBoundaryFallback;
