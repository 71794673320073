import React, { Component } from 'react';
import classnames from 'classnames';

import { Select, SelectProps } from 'antd';
import { FieldProps, getIn } from 'formik';

type OwnProps = {
  label?: string;
  className?: string;
};

export type SelectContainerProps = SelectProps & FieldProps & OwnProps;

export default class SelectContainer extends Component<SelectContainerProps> {
  private container = React.createRef<HTMLDivElement>();

  handleChange = (value) => {
    const {
      form: { setFieldValue },
      field: { name },
    } = this.props;

    setFieldValue(name, value ?? '');
  };

  render() {
    const {
      field,
      form: { touched, errors },
      label,
      children,
      className,
      onChange,
      ...rest
    } = this.props;
    const fieldError: string = getIn(errors, field.name);
    const isTouched: boolean = getIn(touched, field.name);
    const hasError: boolean = fieldError && isTouched;

    // in the first place select prop onChange were implememtned but in case not then use formik abilities
    const handleChange = onChange || this.handleChange;

    return (
      <div
        ref={this.container}
        className={classnames('field', { 'has-error': hasError }, className)}
      >
        {label && <h4 className="field-label">{label}</h4>}
        <Select
          {...field}
          {...rest}
          className="w-100"
          onChange={handleChange}
          onBlur={() =>
            field.onBlur({
              target: { name: field.name },
            })
          }
          getPopupContainer={() => this.container.current}
        >
          {children}
        </Select>
        {hasError && <div className="field-error visible">{fieldError}</div>}
      </div>
    );
  }
}
