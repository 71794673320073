import React, { Component, Fragment, RefObject, LegacyRef } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Popover, Spin, Popconfirm, Input } from 'antd';
import classNames from 'classnames';

import Accessor from 'components/SharedComponents/Accessor';
import { OrdersStore } from '../../../../stores/orders.store';
import { CheckoutStore } from '../../../../stores/checkout.store';
import { UserStore } from '../../../../stores/user.store';
import { AvailabilityLevelEnum } from '../../../../models/enums/availabilityLevel.enum';
import { ServiceModel } from '../../../../models/service.model';
import { OrderDetailsModel } from '../../../../models/orderDetails.model';
import { moneyFormat } from '../../../../utils/formatter';
import { getPaymentStatus } from '../utils/orderUtils';
import OrderEntireCancellation from './OrderEntireCancellation';
import CardInOrder from './CardInOrder';
import OrderDownloads from './OrderDownloads';
import SendDocuments from './SendDocuments';
import { getPaymentStatusLabel } from '../../../../utils/ordersHelper';
import { USER_ROLE_GROUPS } from '../../../../utils/constants';
import { isSupplierUser } from '../../../../utils/userRolesUtils';
import { PENALTY_PRODUCT_ID } from '../constants';
import OrderDetailsNotificationsHistory from './OrderDetailsNotificationsHistory';
import { withStore } from '../../../../hocs';

const dateFormat = 'MMM D, YYYY';

@withStore(({ rootStore }) => ({
  ordersStore: rootStore.ordersStore,
  checkoutStore: rootStore.checkoutStore,
  userStore: rootStore.userStore,
}))
export default class OrderDetails extends Component<{
  tripId: number;
  closePopup: any;
  ordersStore?: OrdersStore;
  checkoutStore?: CheckoutStore;
  userStore?: UserStore;
  changeToCheckout?: any;
}> {
  state = {
    reConfirmEmail: undefined,
    isCancelAvailable: true,
    isCancelPopupVisible: false,
    isDownloadPopoverVisible: false,
    isFileDownloading: false,

    isSendVoucherPopoverVisible: false,
    isSendVoucherSending: false,
  };

  modalRef: RefObject<HTMLElement> = React.createRef();

  componentDidMount() {
    this.initializeComponent();
  }

  componentWillUnmount() {
    const { ordersStore } = this.props;

    ordersStore.clearOrderDetails();
  }

  initializeComponent = async () => {
    const { ordersStore, userStore, tripId, closePopup } = this.props;

    try {
      const [orderDetails] = await Promise.all([
        ordersStore.getOrderDetailsByTripId(tripId),
        ordersStore.getOrderFinance(tripId),
      ]);

      await userStore.getFinanceInfo(orderDetails.Agency);

      this.checkCancelAvailability(orderDetails);
      this.setReConfirmEmailInitialValue(orderDetails);
    } catch (_) {
      closePopup();
    }
  };

  checkCancelAvailability = (orderDetails: OrderDetailsModel) => {
    const {
      userStore: { userProperties },
    } = this.props;

    const servicesWithoutPenalties: ServiceModel[] =
      orderDetails.Services.filter(
        (service) => service.ProductId !== PENALTY_PRODUCT_ID,
      );

    const isSomeServiceNotCancelled = servicesWithoutPenalties.some(
      (service) =>
        service.AvailabilityLevel !== AvailabilityLevelEnum.Cancelled,
    );

    const isCancelUnavailableForSupplier = !isSomeServiceNotCancelled;

    const isCancelUnavailableForUser =
      moment(orderDetails.ServicesBegin).diff(moment()) < 0 ||
      !isSomeServiceNotCancelled;

    const isCancelUnavailable = isSupplierUser(userProperties)
      ? isCancelUnavailableForSupplier
      : isCancelUnavailableForUser;

    if (isCancelUnavailable) {
      this.setState({
        isCancelAvailable: false,
      });
    }
  };

  setReConfirmEmailInitialValue = (orderDetails: OrderDetailsModel) => {
    const primaryPassenger = orderDetails.Passengers.find(
      (passenger) => passenger.PrimaryGuest,
    );

    this.setState({ reConfirmEmail: primaryPassenger?.Email });
  };

  getPopupContainer = () => this.modalRef.current;

  handleDownloadVisible = (isDownloadPopoverVisible) =>
    this.setState({ isDownloadPopoverVisible });

  handleSendDocumentsVisible = (isSendVoucherPopoverVisible) =>
    this.setState({ isSendVoucherPopoverVisible });

  handleIsSendVoucherSending = (isSendVoucherSending) =>
    this.setState({ isSendVoucherSending });

  handleCancelPopupVisible = (isCancelPopupVisible) =>
    this.setState({ isCancelPopupVisible });

  handleAfterCancelOrder = () => {
    const {
      ordersStore: { orderDetails },
      ordersStore,
      tripId,
    } = this.props;

    this.checkCancelAvailability(orderDetails);
    ordersStore.getOrderFinance(tripId);
    ordersStore.loadOrders();
  };

  handleFileDownloading = (isFileDownloading) => {
    this.setState({
      isFileDownloading,
    });
  };

  handleReConfirmOrder = async () => {
    const { ordersStore, tripId } = this.props;
    const { reConfirmEmail } = this.state;

    await ordersStore.sendOrderReConfirm(tripId, reConfirmEmail);
  };

  renderHeader = () => {
    const {
      closePopup,
      ordersStore: { orderDetails: order },
    } = this.props;

    return (
      <Fragment>
        <Button className="back-button" onClick={closePopup}>
          <i className="icon tc-arrow-left" />
          {intl.get('buttons.back')}
        </Button>
        <div className="title">
          <div>{`${intl.get('orders.popup.order')} ${order.TripId}`}</div>
          <div className="sub-title">{`${intl.get(
            'orders.popup.placedOn',
          )} ${moment(order.CreatedOn).format(dateFormat)}`}</div>
        </div>
        <div className="reference-number">
          {order.Reference && (
            <Fragment>
              <span className="label">
                {intl.get('orders.popup.reference')}
              </span>
              <span className="number">#{order.Reference}</span>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  };

  renderStatus = (
    status: AvailabilityLevelEnum,
    servicesWithoutPenalties: any[],
  ) => {
    // 1. If any item within the order is pending, the whole order shows pending.
    // 2. (Without any pending item), if any item within the order is confirmed, shows confirmed.
    // 3. Until all bookings are cancelled, it will then show cancelled.
    const statuses = [];

    const isPending = servicesWithoutPenalties.some(
      (service) =>
        service.AvailabilityLevel === AvailabilityLevelEnum.OnRequest,
    );

    if (isPending) {
      statuses.push(
        <div className="status on-request">
          <span className="label">{intl.get('orders.popup.pending')}</span>
          <i className="tc-ic-pending">
            <i className="tc-custom-dots" />
          </i>
        </div>,
      );
    }

    const isConfirmed = servicesWithoutPenalties.some(
      (service) =>
        service.AvailabilityLevel === AvailabilityLevelEnum.Confirmed,
    );

    if (isConfirmed) {
      statuses.push(
        <div className="status confirmed">
          <span className="label">{intl.get('orders.popup.confirmed')}</span>
          <i className="tc-check-circle confirmed" />
        </div>,
      );
    }

    const isCancelled = servicesWithoutPenalties.some(
      (service) =>
        service.AvailabilityLevel === AvailabilityLevelEnum.Cancelled,
    );

    if (isCancelled) {
      statuses.push(
        <div className="status cancelled">
          <span className="label">{intl.get('orders.popup.cancelled')}</span>
          <i className="tc-remove-circle" />
        </div>,
      );
    }

    return (
      <div className="status-row">
        {statuses.map((statusElement, index) => (
          <React.Fragment key={index}>{statusElement}</React.Fragment>
        ))}
      </div>
    );
  };

  renderCartItems = (servicesWithoutPenalties: any[]) => {
    const {
      ordersStore: { orderDetails, orderDetailsFinance },
      userStore: { userProperties },
    } = this.props;
    const { isCancelAvailable } = this.state;

    return (
      <Fragment>
        {servicesWithoutPenalties.map((card) => (
          <CardInOrder
            key={`${card.TripItemId}`}
            card={card}
            passengers={orderDetails.Passengers}
            afterCancelTrip={this.handleAfterCancelOrder}
            tripId={orderDetails.TripId}
            isEntireOrderCancellationAvailable={isCancelAvailable}
            orderDetailsFinance={orderDetailsFinance}
            userProperties={userProperties}
            modalContainer={this.modalRef.current}
          />
        ))}
      </Fragment>
    );
  };

  renderPaymentSection = () => {
    const {
      ordersStore: { orderDetails },
    } = this.props;
    const total = orderDetails.Price;
    const isPaid: boolean = orderDetails.PaymentAmountDue === 0;

    return (
      <div
        className={classNames('payment-fee', {
          'pay-now': !isPaid,
          paid: isPaid,
        })}
      >
        <div className="d-f f-d-row">
          <span className="m-r-10">{`${intl.get(
            'orders.popup.paymentStatus',
          )}:`}</span>
          {getPaymentStatusLabel(orderDetails)}
        </div>

        <div className="payment-info">
          <div className="count">{moneyFormat(total)}</div>
          <span className="payment-method">
            {getPaymentStatus(orderDetails.Services)}
          </span>
        </div>
      </div>
    );
  };

  renderCancel = (servicesWithoutPenalties) => {
    const {
      ordersStore: {
        orderDetailsFinance,
        orderDetails: { TripId },
      },
    } = this.props;
    const { isCancelAvailable, isCancelPopupVisible } = this.state;

    if (!isCancelAvailable || !orderDetailsFinance) {
      return null;
    }

    const canBeCancelledServices = servicesWithoutPenalties.filter(
      (service) =>
        service.AvailabilityLevel !== AvailabilityLevelEnum.Cancelled,
    );

    return (
      <Popover
        destroyTooltipOnHide
        content={
          <OrderEntireCancellation
            orders={canBeCancelledServices}
            afterCancelTrip={this.handleAfterCancelOrder}
            closePopup={() => this.handleCancelPopupVisible(false)}
            tripId={TripId}
          />
        }
        trigger="click"
        placement="bottomLeft"
        visible={isCancelPopupVisible}
        onVisibleChange={this.handleCancelPopupVisible}
        overlayClassName="cancel-order-tooltip-container"
        getPopupContainer={this.getPopupContainer}
      >
        <div className="cancel-order">
          <Button
            type="default"
            className="cancel-button"
            onClick={() => this.handleCancelPopupVisible(true)}
          >
            {intl.get('orders.popup.cancelOrder')}&nbsp;
            {canBeCancelledServices.length >= 1 &&
              `(${canBeCancelledServices.length} ${intl.get(
                'orders.popup.items',
                { amount: canBeCancelledServices.length },
              )})`}
          </Button>
        </div>
      </Popover>
    );
  };

  renderReConfirm = () => {
    const { isCancelAvailable, reConfirmEmail } = this.state;

    if (!isCancelAvailable) return null;

    return (
      <Popconfirm
        icon
        title={
          <>
            <h3>{intl.get('orders.cancellation.re-confirmTitle')}</h3>
            <Input
              value={reConfirmEmail}
              onChange={(value) =>
                this.setState({ reConfirmEmail: value.target.value })
              }
            />
          </>
        }
        getPopupContainer={this.getPopupContainer}
        onConfirm={this.handleReConfirmOrder}
        okText={intl.get('orders.popup.SEND')}
      >
        <Button type="default" className="accent-action-button m-r-10">
          {intl.get('orders.cancellation.re-confirm')}
        </Button>
      </Popconfirm>
    );
  };

  render() {
    const {
      ordersStore: { orderDetails },
    } = this.props;
    const {
      isDownloadPopoverVisible,
      isFileDownloading,

      isSendVoucherPopoverVisible,
      isSendVoucherSending,
    } = this.state;
    const servicesWithoutPenalties = orderDetails
      ? orderDetails.Services.filter(
          (service) => service.ProductId !== PENALTY_PRODUCT_ID,
        )
      : [];

    if (!orderDetails) {
      return <Spin className="spin" size="large" />;
    }
    const confirmationNumber = servicesWithoutPenalties[0]
      ? servicesWithoutPenalties[0].ConfirmationNumber
      : null;

    return (
      <div
        ref={this.modalRef as LegacyRef<HTMLDivElement>}
        className="main-modal order-details"
      >
        <div className="modal-header">{this.renderHeader()}</div>
        <div className="modal-body">
          <div className="availability-level">
            {this.renderStatus(
              orderDetails.AvailabilityLevel,
              servicesWithoutPenalties,
            )}
            {confirmationNumber && (
              <div className="confirmation-number">
                <span className="label">
                  {intl.get('orders.popup.confirmation')}
                </span>
                <span className="number">{confirmationNumber}</span>
              </div>
            )}
          </div>
          <div className="order-cards-container">
            {this.renderCartItems(servicesWithoutPenalties)}

            <OrderDetailsNotificationsHistory />
          </div>
          <div className="order-details-footer-container">
            <div className="order-details-footer">
              <div className="download-and-cancel">
                <Accessor roles={USER_ROLE_GROUPS.MARKETPLACE}>
                  <div className="download-label">
                    <Popover
                      trigger="click"
                      overlayClassName="order-download"
                      visible={isDownloadPopoverVisible}
                      onVisibleChange={this.handleDownloadVisible}
                      getPopupContainer={this.getPopupContainer}
                      content={
                        <OrderDownloads
                          docTypes={orderDetails.DocTypes}
                          tripId={orderDetails.TripId}
                          orderStore={this.props.ordersStore}
                          isDownloading={isFileDownloading}
                          closePopup={() => this.handleDownloadVisible(false)}
                          startFileDownloading={() =>
                            this.handleFileDownloading(true)
                          }
                          finishFileDownloading={() =>
                            this.handleFileDownloading(false)
                          }
                        />
                      }
                    >
                      <Button type="default" className="accent-action-button">
                        {!isFileDownloading ? (
                          <i className="tc-download icon" />
                        ) : (
                          <LoadingOutlined />
                        )}
                        {intl.get('orders.popup.downloads')}
                      </Button>
                    </Popover>
                  </div>
                </Accessor>

                <Accessor roles={USER_ROLE_GROUPS.MARKETPLACE}>
                  <div className="download-label">
                    <Popover
                      trigger="click"
                      overlayClassName="order-download"
                      visible={isSendVoucherPopoverVisible}
                      onVisibleChange={this.handleSendDocumentsVisible}
                      getPopupContainer={this.getPopupContainer}
                      content={
                        <SendDocuments
                          docTypes={orderDetails.DocTypes}
                          tripId={orderDetails.TripId}
                          orderStore={this.props.ordersStore}
                          isDownloading={isSendVoucherSending}
                          closePopup={() =>
                            this.handleSendDocumentsVisible(false)
                          }
                          startFileDownloading={() =>
                            this.handleIsSendVoucherSending(true)
                          }
                          finishFileDownloading={() =>
                            this.handleIsSendVoucherSending(false)
                          }
                        />
                      }
                    >
                      <Button type="default" className="accent-action-button">
                        {isSendVoucherSending && <LoadingOutlined />}
                        {intl.get('orders.popup.SEND')}
                      </Button>
                    </Popover>
                  </div>
                </Accessor>

                <Accessor roles={USER_ROLE_GROUPS.SUPPLIER}>
                  <div>{this.renderReConfirm()}</div>
                </Accessor>

                <div>{this.renderCancel(servicesWithoutPenalties)}</div>
              </div>
              {this.renderPaymentSection()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
