// message will be in that template: Age [age] is not within the service age
const firstBrace = '[';
const secondBrace = ']';

export const getAgeNumberFromAvailabilityMessage = (
  message: string,
): string[] => {
  const firstBraceIndex = message.indexOf(firstBrace);
  const secondBraceIndex = message.indexOf(secondBrace);

  return message.slice(firstBraceIndex + 1, secondBraceIndex).split(',');
};

export const isMoreLessButtonNeeded = (
  span: HTMLElement,
  wrapper: HTMLElement,
): boolean => {
  if (!span || !wrapper) {
    return true;
  }

  const minimalDifference = 10;
  const spanWidth = span.offsetWidth;
  const wrapperWidth = wrapper.offsetWidth;

  if (spanWidth - wrapperWidth > minimalDifference) {
    return true;
  }

  return false;
};
