import React, { Component, Fragment } from 'react';
import intl from 'react-intl-universal';
import * as Yup from 'yup';
import {
  Formik,
  Form,
  FormikValues,
  setNestedObjectValues,
  yupToFormErrors,
  validateYupSchema,
} from 'formik';
import { Button, Modal, Tooltip, Spin } from 'antd';
import classNames from 'classnames';
import { RcFile } from 'antd/lib/upload';
import { isEmpty, get } from 'lodash';
import { WarningFilled } from '@ant-design/icons';
import { Element } from 'react-scroll';

import { addToQueryString, parseQueryString } from 'utils/urlUtils';
import { ProductTypeEnum } from 'models/enums/productType.enum';
import { CommonStore } from '../../../../../stores/common.store';
import { InventoryStore } from '../../../../../stores/inventory.store';
import { SuppliersStore } from '../../../../../stores/suppliers.store';

import MainPopup from '../../../../SharedComponents/MainPopup';
import { getProductInitialValues, getProductValidation } from './ProductPopup';
import {
  getSupplierInitialValues,
  getSupplierValidation,
  renderSupplierPopupBody,
} from '../../../Contacts/components/SupplierPopup';
import mapProductToSubmit, {
  convertImagesToRequest,
  convertImageToDelete,
  generateUploadFileList,
  getImageUid,
  mapFileListToRequest,
  patchFileListMediaId,
} from '../../../../../utils/inventoryHelper';
import ProductInfoTab from './ProductInfoTab/ProductInfoTab';
import AvailabilityTab from './AvailabilityTab/AvailabilityTab';
import { addGlobalMessage } from '../../../../SharedComponents/GlobalMessages';
import { MessageTypeEnum } from '../../../../../models/global-messages/message.model';
import { ProductDetailsModel } from '../../../../../models/inventory/productDetails.model';
import { RouterProps, withRouter, withStore } from '../../../../../hocs';
import { ProductFormValuesModel } from '../../../../../models/inventory/productFormValues.model';
import { PRODUCT_DETAILS_NAV_CONTAINER_ID } from '../../constants';

export const PRODUCT_TABS = {
  INFO: 0,
  AVAILABILITY: 1,
};

const CORRECT_IMAGE_TYPE: string[] = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/webp',
];

enum CollapsedPanelTypeEnum {
  Rate,
  Times,
}

@withRouter
@withStore(({ rootStore }) => ({
  commonStore: rootStore.commonStore,
  inventoryStore: rootStore.inventoryStore,
  suppliersStore: rootStore.suppliersStore,
}))
export default class ProductDetails extends Component<
  {
    isProductPopupOpened: boolean;
    commonStore?: CommonStore;
    inventoryStore?: InventoryStore;
    suppliersStore?: SuppliersStore;
    handleProductPopupClose: Function;
  } & RouterProps
> {
  state = {
    currentTab: PRODUCT_TABS.INFO,
    isSupplierPopupOpened: false,
    isProductIdLoading: false,
    isProductLoading: true,
    fileList: [],
    initialValues: null as ProductFormValuesModel,
    validation: undefined,
    popupTitle: '',
    submitTitle: '',
    activeRateKey: ['0'],
    activeTimeKey: ['0'],
  };

  uploaderRef: { current: HTMLDivElement } = React.createRef();

  async componentDidMount() {
    const { inventoryStore, commonStore } = this.props;

    await Promise.all([
      inventoryStore.getProductTypes(),
      inventoryStore.getCategories(),
      inventoryStore.getDestinations(),
      commonStore.getMediaSections(),
      commonStore.loadLanguages(),
    ]);

    this.loadLocations();

    this.initializeComponent();
  }

  get initialTouched() {
    const { initialValues } = this.state;

    const touchedMedia = initialValues.Media.map(() => ({
      Section: true,
    }));

    return {
      Media: touchedMedia,
    };
  }

  initializeComponent = async () => {
    const { inventoryStore, commonStore, suppliersStore, location } =
      this.props;
    const { productDetailsType, getProductDetails } = inventoryStore;
    const { settings } = commonStore;
    const { currentTab } = this.state;

    try {
      let productDetails;
      const searchQuery = parseQueryString(location.search);
      const productId = searchQuery.productId as string;
      const productType = searchQuery.productType as ProductTypeEnum;

      if (productId && productType) {
        const [productResponse] = await Promise.all([
          getProductDetails(productId, productType),
          inventoryStore.getInventories(null, null, productId),
        ]);

        productDetails = productResponse;
      }

      if (!settings) return;
      const newValues = getProductInitialValues(productDetails, settings);

      let popupTitle = '';
      let submitTitle = '';

      if (currentTab === PRODUCT_TABS.INFO) {
        popupTitle = productDetails
          ? intl.get('inventory.products.popup.editProduct')
          : intl.get('inventory.products.popup.newProduct');
        submitTitle = productDetails
          ? intl.get('buttons.apply')
          : intl.get('buttons.createProduct');
      } else if (currentTab === PRODUCT_TABS.AVAILABILITY) {
        popupTitle = productDetails ? productDetails.Name : '';
        submitTitle = intl.get('buttons.save');
      }

      let validation = getProductValidation(newValues, productDetailsType);

      if (suppliersStore.isSupplierSelectable) {
        validation = Yup.object()
          .shape({
            OperatorId: Yup.string().required('required'),
          })
          .concat(validation);
      }

      this.initializeImages();
      this.setState({
        initialValues: newValues,
        isProductLoading: false,
        popupTitle,
        submitTitle,
        validation,
      });
    } catch {
      this.handleClose();
      addGlobalMessage({
        message: intl.get('inventory.products.failedToLoadProducts'),
        type: MessageTypeEnum.error,
      });
    } finally {
      this.setState({
        isProductLoading: false,
      });
    }
  };

  handleActiveRateChange = (activeRateKey) => this.setState({ activeRateKey });
  handleActiveTimeChange = (activeTimeKey) => this.setState({ activeTimeKey });

  handleTogglePanel =
    (values: FormikValues, panelType: CollapsedPanelTypeEnum) => () => {
      const { activeRateKey, activeTimeKey } = this.state;
      let key;
      let keyValue;
      let value;

      switch (panelType) {
        case CollapsedPanelTypeEnum.Rate: {
          key = 'activeRateKey';
          keyValue = activeRateKey;
          value = values.Rates;

          break;
        }

        case CollapsedPanelTypeEnum.Times: {
          key = 'activeTimeKey';
          keyValue = activeTimeKey;
          value = values.Times;

          break;
        }
      }

      const isAllExpanded = keyValue.length === value.length;
      const newActiveKey = isAllExpanded
        ? []
        : value.map((_, index) => String(index));

      this.setState({ [key]: newActiveKey });
    };

  removeUploadedImage = (file) => {
    this.setState((state: any) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();

      newFileList.splice(index, 1);

      return {
        fileList: newFileList,
      };
    });
  };

  // function to init images on edit mode
  initializeImages = () => {
    const {
      inventoryStore: { productDetails: currentProduct },
      commonStore: { settings },
    } = this.props;

    const fileList = generateUploadFileList(
      currentProduct ? currentProduct.Media : [],
      settings,
    );

    this.setState({
      fileList,
    });
  };

  uploadImage = (file) => {
    if (CORRECT_IMAGE_TYPE.includes(file.type)) {
      this.setState((state: any) => ({
        fileList: [...state.fileList, file],
      }));
    }
  };

  copySectionImages = (sourceMediaKey: string, targetMediaKey: string) => {
    const { fileList } = this.state;

    const images = fileList
      .filter(({ mediaKey }) => mediaKey === sourceMediaKey)
      .map((image, index) => ({
        ...image,
        uid: getImageUid(targetMediaKey, index),
        apiId: null,
        mediaKey: targetMediaKey,
      }));

    if (isEmpty(images)) return;

    this.setState({ fileList: [...fileList, ...images] });
  };

  removeSectionImages = (key: string) => {
    const { fileList } = this.state;

    this.setState({
      fileList: fileList.filter(({ mediaKey }) => mediaKey !== key),
    });
  };

  updateImage = (file: RcFile) => {
    const { fileList } = this.state;
    const fileIndex = fileList.findIndex((f: RcFile) => f.uid === file.uid);

    if (fileIndex > -1) {
      const updatedList = [...fileList];

      updatedList[fileIndex] = file;

      this.setState({
        fileList: updatedList.sort((image) => (image.isPrimary ? -1 : 0)),
      });
    }
  };

  handleSwitchTab = (tab) => {
    const { currentTab } = this.state;

    if (currentTab === tab) {
      return;
    }

    this.setState({ currentTab: tab });
  };

  handleChangeProductIdLoading = (isProductIdLoading) =>
    this.setState({ isProductIdLoading });

  handleSupplierPopupOpen = (e) => {
    e.preventDefault();

    this.setState({ isSupplierPopupOpened: true });
  };

  handleSupplierPopupClose = () =>
    this.setState({ isSupplierPopupOpened: false });

  handleClose = () => {
    const { handleProductPopupClose } = this.props;

    this.setState({
      currentTab: PRODUCT_TABS.INFO,
      isSupplierPopupOpened: false,
      fileList: [],
    });

    handleProductPopupClose();
  };

  handleSubmitSupplierPopup = (supplier) => {
    const { suppliersStore } = this.props;

    suppliersStore
      .createSupplier(supplier)
      .then(() => this.setState({ isSupplierPopupOpened: false }));
  };

  handleSubmitProductPopup = async (product: ProductDetailsModel) => {
    const {
      navigate,
      location,
      inventoryStore,
      inventoryStore: { productDetails: currentProduct, productDetailsType },
      commonStore: { settings },
    } = this.props;

    const { fileList } = this.state;

    const primaryFileList = fileList.filter((file) => file.isPrimary);

    if (fileList.length && !primaryFileList.length) {
      return addGlobalMessage({
        message: 'The product must have a Primary Image',
        type: MessageTypeEnum.error,
      });
    }

    if (currentProduct) {
      const oldFileList = generateUploadFileList(
        currentProduct.Media,
        settings,
      );

      const {
        updatedGeneralInfo,
        updatedPolicies,
        updatedRates,
        updatedMediaSections,
        deletedMediaSectionIds,
        updateTimes,
      } = await mapProductToSubmit(
        product,
        currentProduct,
        productDetailsType,
        settings,
        inventoryStore,
      );

      inventoryStore.setIsProductSubmitLoading(true);
      inventoryStore
        .editProduct(updatedGeneralInfo)
        .then(() =>
          Promise.all([
            inventoryStore.updateMedia(deletedMediaSectionIds, {
              entities: updatedMediaSections,
            }),
            inventoryStore.createRates(updatedRates, productDetailsType),
            inventoryStore.updateTimes(updateTimes),
            updatedPolicies.Id
              ? inventoryStore.editPolicies(updatedPolicies)
              : inventoryStore.createPolicies(updatedPolicies),
          ]),
        )

        // save images with MediaInfoId = product summary id
        .then(([mediaInfoIds]) => {
          if (!mediaInfoIds) return;

          const patchedFileList = patchFileListMediaId(
            fileList,
            product.Media,
            mediaInfoIds,
          );

          const { deletedImages, updatedImages, newImages } =
            mapFileListToRequest(patchedFileList, oldFileList);

          const correctDeletedImages = deletedImages.map(convertImageToDelete);

          const promises = Promise.all([
            newImages.length ? inventoryStore.uploadImages(newImages) : null,
            updatedImages.length
              ? inventoryStore.uploadImages(updatedImages)
              : null,
            correctDeletedImages.length
              ? inventoryStore.deleteImages(correctDeletedImages)
              : null,
          ]);

          return promises;
        })
        .then(() => this.initializeComponent())
        .then(() => this.initializeImages())
        .finally(() => inventoryStore.setIsProductSubmitLoading(false));
    } else {
      const {
        newGeneralInfo,
        newMediaSections,
        newProductRates,
        newPolicies,
        newTimes,
      } = await mapProductToSubmit(
        product,
        currentProduct,
        productDetailsType,
        settings,
        inventoryStore,
      );

      inventoryStore.setIsProductSubmitLoading(true);
      inventoryStore
        .createProduct(newGeneralInfo)
        .then(() =>
          Promise.all([
            inventoryStore.createMedia({
              entities: newMediaSections,
            }),
            inventoryStore.createRates(newProductRates, productDetailsType),
            inventoryStore.createPolicies(newPolicies),
            inventoryStore.updateTimes(newTimes),
          ]),
        )
        // save images with MediaInfoId = product summary id
        .then(([mediaInfoIds]) => {
          if (!mediaInfoIds) return;

          const patchedFileList = patchFileListMediaId(
            fileList,
            product.Media,
            mediaInfoIds,
          );

          return inventoryStore.uploadImages(
            convertImagesToRequest(patchedFileList),
          );
        })
        .then(() =>
          navigate({
            search: addToQueryString(location.search, {
              productId: product.Id,
            }),
          }),
        )
        .finally(() => {
          inventoryStore.setIsProductSubmitLoading(false);

          setTimeout(() => this.initializeComponent(), 0);
        });
    }
  };

  handleValidateProductPopup = async (
    formicActions: any,
    RestrictionCode: string,
  ) => {
    const { fileList } = this.state;
    const { validateForm, setTouched } = formicActions;
    const values = { ...formicActions.values, RestrictionCode };
    const errors = await validateForm(values);
    const isFilesValid = fileList.every((file) => file.caption || file.apiSize);

    if (isEmpty(errors) && isFilesValid) {
      this.handleSubmitProductPopup(values);
      return;
    }

    const touched = setNestedObjectValues(values, true);
    setTouched(touched);
    const ratesErrorIndex = get(errors, 'Rates', []).findIndex(Boolean);
    addGlobalMessage({
      message: intl.get('inventory.products.formError'),
      type: MessageTypeEnum.error,
    });

    if (ratesErrorIndex >= 0) {
      this.handleActiveRateChange(`${ratesErrorIndex}`);
    }

    if (!isFilesValid) {
      this.uploaderRef.current.scrollIntoView();
    }
  };

  validateForm = async (values) => {
    const { validation } = this.state;
    const { inventoryStore } = this.props;

    try {
      await validateYupSchema(values, validation, false, {
        values,
        operatorData: inventoryStore.getProductSupplier(values.OperatorId),
      });
    } catch (err) {
      return yupToFormErrors<ProductFormValuesModel>(err);
    }

    return {};
  };

  async loadLocations() {
    const { commonStore } = this.props;

    await Promise.all([
      commonStore.loadLocationStates(),
      commonStore.loadLocationCountries(),
    ]);
  }

  render() {
    const {
      isProductPopupOpened,
      inventoryStore: { isProductSubmitLoading, productDetails },
      suppliersStore: { isSupplierLoading },
    } = this.props;
    const {
      isSupplierPopupOpened,
      currentTab,
      fileList,
      isProductIdLoading,
      initialValues,
      isProductLoading,
      popupTitle,
      submitTitle,
      activeRateKey,
      activeTimeKey,
    } = this.state;

    const extraData = {
      initialValues,
      currentProduct: productDetails,
      currentTab,
      fileList,
      isProductIdLoading,
      uploaderRef: this.uploaderRef,
      uploadImage: this.uploadImage,
      updateImage: this.updateImage,
      copySectionImages: this.copySectionImages,
      removeSectionImages: this.removeSectionImages,
      removeUploadedImage: this.removeUploadedImage,
      onSwitchTab: this.handleSwitchTab,
      onSupplierPopupOpen: this.handleSupplierPopupOpen,
      onProductIdLoading: this.handleChangeProductIdLoading,
      initializeImages: this.initializeImages,
    };

    return (
      <Fragment>
        <Modal
          wrapClassName="popup full-modal product-modal"
          destroyOnClose
          visible={isProductPopupOpened}
          maskClosable={false}
          footer={null}
          closable={false}
          onCancel={this.handleClose}
        >
          <Element
            id={PRODUCT_DETAILS_NAV_CONTAINER_ID}
            className="content new-product"
          >
            {isProductLoading ? (
              <Spin className="spin" size="large" />
            ) : (
              <Formik
                enableReinitialize
                initialValues={initialValues}
                initialTouched={this.initialTouched}
                validate={this.validateForm}
                validateOnChange
                onSubmit={() => undefined}
              >
                {(formikProps) => {
                  const { setFieldValue, values } = formikProps;
                  return (
                    <>
                      <div className="header">
                        <div
                          className={classNames('d-f flx-1', {
                            'jc-c ai-fs': productDetails,
                          })}
                        >
                          <div className="d-f jc-fs ai-c flx-1">
                            <Button
                              className="back-button"
                              onClick={this.handleClose}
                              disabled={isProductSubmitLoading}
                            >
                              <i className="icon tc-arrow-left" />
                              {intl.get('buttons.cancel')}
                            </Button>
                          </div>
                          <div className="title d-f jc-c ai-c flx-1">
                            <div>{popupTitle}</div>
                          </div>
                          <div className="title d-f f-d-column flx-1">
                            <div className="d-f jc-fe">
                              {currentTab === PRODUCT_TABS.INFO && (
                                <Fragment>
                                  <Button
                                    loading={
                                      isProductSubmitLoading &&
                                      values.RestrictionCode === 'H'
                                    }
                                    htmlType="button"
                                    disabled={isProductSubmitLoading}
                                    className="action-button secondary m-r-15"
                                    onClick={() => {
                                      setFieldValue('RestrictionCode', 'H');
                                      this.handleValidateProductPopup(
                                        formikProps,
                                        'H',
                                      );
                                    }}
                                  >
                                    {intl.get('buttons.saveAsDraft')}
                                  </Button>
                                  <Button
                                    loading={
                                      isProductSubmitLoading &&
                                      values.RestrictionCode === 'O'
                                    }
                                    htmlType="button"
                                    className="action-button"
                                    disabled={isProductSubmitLoading}
                                    onClick={() => {
                                      setFieldValue('RestrictionCode', 'O');
                                      this.handleValidateProductPopup(
                                        formikProps,
                                        'O',
                                      );
                                    }}
                                  >
                                    {submitTitle}
                                  </Button>
                                </Fragment>
                              )}
                            </div>
                            {productDetails &&
                              productDetails.RestrictionCode &&
                              currentTab === PRODUCT_TABS.INFO && (
                                <div className="d-f jc-fe f-s-14 m-t-5">
                                  {`${intl.get(
                                    'inventory.products.popup.status',
                                  )}: ${
                                    productDetails.RestrictionCode === 'H'
                                      ? intl
                                          .get('inventory.products.popup.draft')
                                          .toLowerCase()
                                      : intl
                                          .get('inventory.products.popup.live')
                                          .toLowerCase()
                                  }`}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="body">
                        <div className="product-page">
                          <div className="product-head">
                            <div className="head-tabs">
                              <div
                                onClick={() =>
                                  this.handleSwitchTab(PRODUCT_TABS.INFO)
                                }
                                className={classNames('tab-title', {
                                  active: currentTab === PRODUCT_TABS.INFO,
                                })}
                              >
                                {intl.get('productPage.info')}
                              </div>
                              <Tooltip
                                placement="right"
                                title={
                                  productDetails ? null : (
                                    <Fragment>
                                      <WarningFilled
                                        style={{ color: '#f5a623' }}
                                      />
                                      {intl.get(
                                        'inventory.products.popup.warnings.youShouldCreateAProduct',
                                      )}
                                    </Fragment>
                                  )
                                }
                              >
                                <div>
                                  <div
                                    onClick={() =>
                                      this.handleSwitchTab(
                                        PRODUCT_TABS.AVAILABILITY,
                                      )
                                    }
                                    className={classNames('tab-title', {
                                      active:
                                        currentTab ===
                                        PRODUCT_TABS.AVAILABILITY,
                                      disabled: !productDetails,
                                    })}
                                  >
                                    {intl.get('productPage.availability')}
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          </div>

                          {currentTab === PRODUCT_TABS.INFO && (
                            <Form>
                              <ProductInfoTab
                                extraData={extraData}
                                formikProps={formikProps}
                                activeRateKey={activeRateKey}
                                activeTimeKey={activeTimeKey}
                                onToggleAllRates={this.handleTogglePanel(
                                  values,
                                  CollapsedPanelTypeEnum.Rate,
                                )}
                                onToggleAllTimes={this.handleTogglePanel(
                                  values,
                                  CollapsedPanelTypeEnum.Times,
                                )}
                                onActiveTimeChange={this.handleActiveTimeChange}
                                onActiveRateChange={this.handleActiveRateChange}
                              />
                            </Form>
                          )}
                          {currentTab === PRODUCT_TABS.AVAILABILITY && (
                            <AvailabilityTab />
                          )}
                        </div>
                      </div>
                    </>
                  );
                }}
              </Formik>
            )}
          </Element>
        </Modal>
        <MainPopup
          mask={false}
          contentClassName="new-contact"
          closePopup={this.handleSupplierPopupClose}
          visible={isSupplierPopupOpened}
          onCancel={this.handleSupplierPopupClose}
          title={intl.get('contacts.popup.newSupplier')}
          submitTitle={intl.get('buttons.createSupplier')}
          onSubmit={this.handleSubmitSupplierPopup}
          initialValues={getSupplierInitialValues(null)}
          validation={getSupplierValidation()}
          renderBody={renderSupplierPopupBody}
          submitLoading={isSupplierLoading}
          disabledCancelBtn={isSupplierLoading}
          disabledSubmitBtn={isSupplierLoading}
          extraData={{ currentContact: null }}
        />
      </Fragment>
    );
  }
}
