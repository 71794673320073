import React from 'react';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import moment, { Moment } from 'moment';
import { isArray, isEmpty } from 'lodash';

import { useStore } from 'hooks';
import { DateRangeTemplateEnum } from 'models/enums/dateRangeTemplate.enum';
import { MomentRangeModel } from 'models/momentRange.model';
import {
  getDateRangeByTemplate,
  getIsDateRangeTemplateValid,
  normalizeDateRange,
} from 'utils/datesUtils';
import RangePickerTemplates from 'components/SharedComponents/RangePickerTemplates';

type Props = RangePickerProps<Moment>;

export default function InventoryBulkViewRangePicker(props: Props) {
  const bulkInventoryStore = useStore((state) => state.bulkInventoryStore);
  const { datesRange } = bulkInventoryStore;

  const parseSelectedTemplate = (): DateRangeTemplateEnum | null => {
    if (isEmpty(datesRange) || isArray(datesRange)) return null;

    return getIsDateRangeTemplateValid(datesRange) ? datesRange : null;
  };

  const parseDateRange = (): MomentRangeModel | null => {
    if (!isArray(datesRange)) return null;

    const [from, to] = datesRange;

    return [moment(from), moment(to)];
  };

  const parseSelectedDateRange = (): MomentRangeModel => {
    if (isEmpty(datesRange)) return null;

    return getDateRangeByTemplate(selectedTemplate) ?? parseDateRange();
  };

  const selectedTemplate = parseSelectedTemplate();
  const selectedDateRange = parseSelectedDateRange();

  const onTemplateChange = (value: DateRangeTemplateEnum) => {
    bulkInventoryStore.changeDatesRange(value);
  };

  const onDateChange = (dates: MomentRangeModel) => {
    bulkInventoryStore.changeDatesRange(normalizeDateRange(dates, ''));
  };

  return (
    <DatePicker.RangePicker
      {...props}
      allowClear={false}
      renderExtraFooter={() => (
        <RangePickerTemplates
          value={selectedTemplate}
          onChange={onTemplateChange}
        />
      )}
      value={selectedDateRange}
      onChange={onDateChange}
    />
  );
}
