import { observable, action, computed, makeObservable } from 'mobx';
import intl from 'react-intl-universal';
import { isEmpty } from 'lodash';

import { HelpInstructionsSettingModel } from 'models/site-settings/helpInstructionsSetting.model';
import { checkUserGroup } from 'utils/userRolesUtils';
import { RootStore } from './root.store';
import { CommonApi } from '../api/common.api';
import siteSettingsUtils from '../utils/siteSettingsUtils';
import { SiteSettingsModel } from '../models/site-settings/siteSettings.model';
import { generalToken, mapBoxToken, serverUrl } from '../utils/constants';
import { trimUrl } from '../utils/urlUtils';
import { getBearerToken } from '../utils/formatter';
import { LocationStateModel } from '../models/locations/locationState.model';
import { LocationCountryModel } from '../models/locations/locationCountry.model';
import { MediaSectionModel } from '../models/media/mediaSection.model';
import { LanguageModel } from '../models/language.model';
import { SettingsModel } from '../models/site-settings/settings.model';

export class CommonStore {
  private rootStore: RootStore;
  private apiService: CommonApi;

  public currentDomain = window.location.host;

  @observable public languages: LanguageModel[] = [];
  @observable public settings: SettingsModel = null;
  @observable public helpInstructions: HelpInstructionsSettingModel[] = [];
  @observable public siteSettings: SiteSettingsModel = undefined;
  @observable public locationStates: LocationStateModel[] = [];
  @observable public locationCountries: LocationCountryModel[] = [];
  @observable public mediaSections: MediaSectionModel[] = [];

  constructor(rootStore: RootStore, apiService: CommonApi) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.apiService = apiService;
  }

  @action
  public async loadLanguages() {
    try {
      const response = await this.apiService.getLanguages();
      this.languages = response.data;

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  @action
  public async loadSettings() {
    try {
      const { data: settings } = await this.apiService.getSettings();

      this.settings = settings;
    } catch (e) {
      this.settings = null;
    }
  }

  @action
  public async loadHelpInstructions() {
    try {
      const response = await this.apiService.getHelpInstructions();

      this.helpInstructions = response.data;
    } catch (e) {
      this.helpInstructions = [];
    }
  }

  private async loadCurrentDomainSiteSettings(
    token?: string,
    baseUrl?: string,
  ) {
    const result = await this.apiService.getSiteSettings(token, baseUrl);

    return siteSettingsUtils.getDomainSettings(this.currentDomain, result.data);
  }

  @action
  public async loadSiteSettings() {
    try {
      this.siteSettings = await this.loadCurrentDomainSiteSettings(
        generalToken,
        serverUrl,
      );

      if (trimUrl(this.siteSettings.apiUrl) !== trimUrl(serverUrl)) {
        this.siteSettings = await this.loadCurrentDomainSiteSettings(
          getBearerToken(this.siteSettings.token),
          this.siteSettings.apiUrl,
        );
      }
    } catch (e) {
      this.siteSettings = siteSettingsUtils.defaultSiteSettings;
    }
  }

  @action
  public async getMediaSections() {
    try {
      const response = await this.apiService.getMediaSections();

      this.mediaSections = [
        {
          Id: this.settings.MainMediaSection,
          Name: intl.get('inventory.products.popup.productSummary'),
        },
        {
          Id: this.settings.FullDescriptionMediaSection,
          Name: intl.get('inventory.products.popup.longDescription'),
        },
        {
          Id: this.settings.TermsMediaSection,
          Name: 'Terms & Conditions',
        },
      ].concat(response.data.value);
    } catch (e) {
      return Promise.reject();
    }
  }

  @action
  public async loadLocationStates() {
    if (!isEmpty(this.locationStates)) return;

    try {
      const response = await this.apiService.getLocationsStates();

      this.locationStates = response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  @action
  public async loadLocationCountries() {
    if (!isEmpty(this.locationCountries)) return;

    try {
      const response = await this.apiService.getLocationsCountries();

      this.locationCountries = response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  @action.bound
  public async getLocationLatLng(query: string, country?: string) {
    try {
      const params = {
        ...(country && { country }),
        proximity: 'ip',
        types: 'address',
        limit: '1',
        access_token: mapBoxToken,
      };

      const response = await this.apiService.getLocationLatLng(query, params);

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  @computed public get ordersListColumns() {
    const { userStore } = this.rootStore;
    const { userProperties } = userStore;

    return this.siteSettings.ordersListColumns.filter((column) =>
      isEmpty(column.VisibleRoles)
        ? column.Visible
        : checkUserGroup(userProperties, column.VisibleRoles),
    );
  }

  @computed public get ordersListColumnsIds() {
    return this.ordersListColumns.map((column) => column.Id);
  }

  public getOrdersListColumnById(id: string) {
    return this.ordersListColumns.find(
      (ordersListColumn) => ordersListColumn.Id === id,
    );
  }

  public getIsOrdersListColumnEditable(id: string) {
    const { userStore } = this.rootStore;
    const { userProperties } = userStore;

    const column = this.getOrdersListColumnById(id);

    if (!column) return false;

    return checkUserGroup(userProperties, column.EditableRoles ?? []);
  }

  @computed public get apiUrl() {
    return this.siteSettings?.apiUrl || serverUrl;
  }

  @computed public get generalToken() {
    return this.siteSettings?.token || generalToken;
  }

  @action
  public clearStore() {
    this.settings = undefined;
  }
}
