import intl from 'react-intl-universal';

export class TravelerFormModel {
  id: number;
  firstName: string;
  lastName: string;
  // countryCodeAndPhoneCode: 'CN:86'
  countryCodeAndPhoneCode: string;
  phoneNumber: string;
  email?: string;

  isLead: boolean;

  constructor(values: any, isLead?: boolean) {
    const {
      id,
      firstName,
      lastName,
      countryCodeAndPhoneCode,
      phoneNumber,
      email,
    } = values;
    this.id = id;
    this.firstName = firstName || '';
    this.lastName = lastName || '';
    this.countryCodeAndPhoneCode = countryCodeAndPhoneCode || '';
    this.phoneNumber = phoneNumber || '';
    this.email = email || '';

    this.isLead = isLead;
  }

  public get fullName() {
    return `${this.getFirstName} ${this.getLastName}`;
  }

  public get getFirstName() {
    return this.firstName;
  }

  public get getLastName() {
    if (!this.isLead && this.lastName.trim() === '') {
      return `${intl.get('checkout.traveler')} ${this.id}`;
    }
    return this.lastName;
  }

  public get phoneNumberWithCountryCode() {
    // only lead traveler has the phone field
    if (!this.isLead) {
      return null;
    }

    const code = this.countryCodeAndPhoneCode
      ? this.countryCodeAndPhoneCode.split(':')[1]
      : '';

    return `${code}${this.phoneNumber}`;
  }
}
