import React from 'react';
import { Space, Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { Formik, Form, Field } from 'formik';
import intl from 'react-intl-universal';

import { CheckboxContainer, NumberStepperContainer } from '../FormContainers';
import { BulkInventoryEditAvailabilityModel } from '../../models/form-models/BulkInventoryEditAvailability.model';

type Props = {
  initialValues: BulkInventoryEditAvailabilityModel;
  isLoading: boolean;
  onSave: (values: BulkInventoryEditAvailabilityModel) => void;
};

export default function InventoryBulkViewEditAvailability(props: Props) {
  const { initialValues, isLoading, onSave } = props;

  const onSubmit = (values: BulkInventoryEditAvailabilityModel) => {
    onSave(values);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <Space className="inventory-bulk-view-edit-availability">
          <Field
            min={null}
            className="d-f ai-c"
            label={`${intl.get('inventory.bulkView.available')}:`}
            name="availabilityQuantity"
            isCleanable={false}
            style={{ width: 90 }}
            component={NumberStepperContainer}
          />

          <Field
            name="stopSell"
            label={intl.get('inventory.bulkView.stopSell')}
            component={CheckboxContainer}
          />

          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            icon={<SaveOutlined />}
          />
        </Space>
      </Form>
    </Formik>
  );
}
