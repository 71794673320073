import React from 'react';
import intl from 'react-intl-universal';
import { Col, Row } from 'antd';
import { Field, useFormikContext } from 'formik';
import moment, { Moment } from 'moment';

import {
  CheckboxContainer,
  CheckboxGroupContainer,
  InputNumberContainer,
  RangeDatepickerContainer,
  SelectContainer,
  TimePickerContainer,
} from 'components/FormContainers';
import { getDaysOptions } from 'utils/rateUtils';
import { ProductFormValuesModel } from 'models/inventory/productFormValues.model';
import { EMPTY_VALUE } from 'utils/constants';

type Props = {
  position: number;
};

export default function TimePanel(props: Props) {
  const { position } = props;

  const { values } = useFormikContext<ProductFormValuesModel>();

  const getCategoryOptions = () => {
    const rates = (values.Rates || []).filter(
      (rate) => rate.CategoryName && rate.CategoryId,
    );

    return [
      {
        value: EMPTY_VALUE,
        label: intl.get('inventory.products.popup.noCategory'),
      },
      ...rates.map((rate) => ({
        value: rate.CategoryId,
        label: rate.CategoryName,
      })),
    ];
  };

  return (
    <div className="w-100">
      <Row gutter={10} align="middle">
        <Col span={14}>
          <Field
            allowClear
            showSearch
            id={`Times.${position}.CategoryId`}
            name={`Times.${position}.CategoryId`}
            label={intl.get('inventory.products.popup.category')}
            options={getCategoryOptions()}
            component={SelectContainer}
          />
        </Col>

        <Col span={10}>
          <Field
            label={intl.get(
              'inventory.products.popup.createAvailabilityOptions',
            )}
            fieldLabel="&nbsp;"
            id={`Times.${position}.CreateAvailability`}
            name={`Times.${position}.CreateAvailability`}
            component={CheckboxContainer}
          />
        </Col>
      </Row>

      <Row gutter={[10, 15]}>
        <Col span={14}>
          <Field
            label={intl.get('inventory.products.popup.startEndDate')}
            format="MM/DD/YYYY"
            disabledDate={(currentDate: Moment) =>
              currentDate &&
              currentDate < moment().subtract(1, 'd').endOf('day')
            }
            id={`Times.${position}.ServiceStartEnd`}
            name={`Times.${position}.ServiceStartEnd`}
            component={RangeDatepickerContainer}
          />
        </Col>

        <Col span={14}>
          <Row gutter={5}>
            <Col span={12}>
              <Field
                use12Hours
                format="h:mm a"
                id={`Times.${position}.Time`}
                name={`Times.${position}.Time`}
                label={intl.get('inventory.products.popup.startTime')}
                component={TimePickerContainer}
              />
            </Col>

            <Col span={12}>
              <Field
                use12Hours
                format="h:mm a"
                id={`Times.${position}.EndTime`}
                name={`Times.${position}.EndTime`}
                label={intl.get('inventory.products.popup.endTime')}
                component={TimePickerContainer}
              />
            </Col>
          </Row>
        </Col>

        <Col span={10}>
          <Field
            id={`Times.${position}.RecursEvery`}
            name={`Times.${position}.RecursEvery`}
            label={intl.get('inventory.products.popup.recursEvery')}
            component={InputNumberContainer}
          />
        </Col>
      </Row>

      <Field
        id={`Times.${position}.DaysOfWeek`}
        name={`Times.${position}.DaysOfWeek`}
        className="day-of-week"
        options={getDaysOptions()}
        label={intl.get('inventory.products.popup.daysOfWeek')}
        component={CheckboxGroupContainer}
      />
    </div>
  );
}
