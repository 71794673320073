import intl from 'react-intl-universal';
import { differenceBy, isEmpty, isEqual, partial } from 'lodash';
import { Moment } from 'moment';

import { PreviewFile } from '../components/Pages/Inventory/components/ProductDetailsPopup/ProductInfoTab/ProductUpload';
import { InventoryApi } from '../api/inventory.api';
import { EMPTY_VALUE, ImageSizeEnum } from './constants';
import { correctFormatTime, getDefaultCutoffMins } from './formatter';
import { ImagesStatusEnum } from '../models/enums/imagesStatus';
import { getDatesFromRange, momentRange } from './dateRangeUtils';
import { mapDaysOfWeekValuesToKeys } from '../components/Pages/Inventory/components/ProductDetailsPopup/ProductPopup';
import { findShortestArray } from './arrayUtils';
import { InventoryModel } from '../models/inventory/inventory.model';
import { InventoryEditTypeEnum } from '../models/enums/inventoryEditType.enum';
import { ProductTypeEnum } from '../models/enums/productType.enum';
import { ProductDetailsModel } from '../models/inventory/productDetails.model';
import { InventoryStore } from '../stores/inventory.store';
import CreateRatesMapper from './createRatesMapper';
import { toServiceTime } from './datesUtils';
import { getDaysOfWeekInt } from './rateUtils';
import { ProductFormValuesModel } from '../models/inventory/productFormValues.model';
import { FormMediaModel } from '../models/inventory/formMedia.model';

export const ProductType = 'OPT';
// Draft
// RestrictionCode should be 'H' for a House (internal only) product. This effectively puts a product into "draft" state where it can only be seen internally.

// ProductType for MediaBundles will always be "OPT"

// Cancel policy and Cutoff
// UnitsOffset would be the number of nights(or days) prior when the cancellation penalty takes effect.
// Flat is if it's a flat fee.
// Percentage is if it's a percentage.
// The CutoffDate in the Policies api is not required for this project.It refers to the cancellation penalty taking effect on a specific date rather than a number of days prior.
// The Id number is the primary key, so you would need it for editing or deleting, but it is not specified by the user(nor does it need to be shown to them)
// The "Cut-off time" refers to the time prior to operation when bookings can no longer be taken for the product, ie 1 day means bookings will not be able to be made on the day of operation

// Descriptions
// Question: Do I understand correctly that in order for me to get a Summary Product and a Long Description I should:
// 1. Call /settings and get values for MainMediaSection and  FullDescriptionMediaSection http://prntscr.com/q51ws9
// 2. In the Product under MediaBundles -> Media create/get two same objects, but with different values for Section (value from MainMediaSection or FullDescriptionMediaSection) and FormattedText
// Answer - Yes. Each block of text is a different MediaInfo.  You don't need to use MediaBundles though, because in the case of See Canyon, there is only one bundle.  You can create/retrieve the info with the /media endpoint (providing the product id of the product

export default async function mapProductToSubmit(
  product,
  currentProduct,
  productDetailsType,
  settings,
  inventoryStore: InventoryStore,
) {
  const operatorId = inventoryStore.getProductSupplier(product.OperatorId)?.Id;

  // Update
  if (currentProduct) {
    if (inventoryStore.idsOfDeletedTimes.length) {
      inventoryStore.deleteTimes(inventoryStore.idsOfDeletedTimes);
    }
    const updateTimes = product.Times.map((time) => ({
      ...time.timeData,
      ProductType: productDetailsType,
      Time: correctFormatTime(time.Time) ?? undefined,
      EndTime: correctFormatTime(time.EndTime) ?? undefined,
      ServiceStart: time.ServiceStartEnd?.[0]?.format('YYYY-MM-DD'),
      ServiceEnd: time.ServiceStartEnd?.[1]?.format('YYYY-MM-DD'),
      ProductId: currentProduct.Id,
      RecursEvery: time.RecursEvery,
      CategoryId: time.CategoryId === EMPTY_VALUE ? null : time.CategoryId,
      Id: time.Id,
      DaysOfWeek: getDaysOfWeekInt(time.DaysOfWeek),
    }));

    const updatedGeneralInfo = {
      Id: product.Id,
      Name: product.Name,
      Inactive: product.Inactive,
      RestrictionCode: product.RestrictionCode,
      OperatorId: operatorId,
      ServiceTypeId: product.ServiceTypeId,
      PackageTypeId: product.PackageTypeId,
      DestinationId: product.DestinationId,
      DefaultCutoffMins:
        productDetailsType === ProductTypeEnum.Package
          ? undefined
          : getDefaultCutoffMins(product.CutoffHours),
      MinDuration: product.MinDuration,
      MaxDuration: product.MaxDuration,
      DefaultTime: product.DefaultTime
        ? toServiceTime(product.DefaultTime)
        : undefined,
      StateId: product.StateId,
      CountryId: product.CountryId,
      City: product.City,
      Street: product.Street,
      Zip: product.Zip,
      Latitude: product.Latitude,
      Longitude: product.Longitude,
    };

    const updatedPolicies = {
      Id: product.Policies[0].Id,
      ProductType: productDetailsType,
      ProductId: product.Id,
      UnitsOffset: product.Policies[0].Days,
      TimeUnits: product.Policies[0].TimeUnits,
      NonRefundable: product.Policies[0].NonRefundable,
      [product.Policies[0].Type === 'percentage' ? 'Percentage' : 'Flat']:
        product.Policies[0].Penalty,
    };

    const updatedRates = new CreateRatesMapper(product.Id, product.Rates).map();

    product.Rates.forEach((currentRate) => {
      const prevRate = currentProduct.Rates.find(
        (rate) => rate.Id === currentRate.Id,
      );

      if (currentRate.CategoryId && !currentRate.IsExistsCategory) {
        inventoryStore.createCategory({
          Id: currentRate.CategoryId,
          Name: currentRate.CategoryName,
        });
      }

      const [, prevEndDate] = prevRate?.InventoryDate || [];

      const [startDate, endDate] = currentRate.InventoryDate;

      const isRateDatesExtended = checkIsRateDatesExtended(
        prevEndDate,
        endDate,
      );

      const inventoryStartDate = isRateDatesExtended
        ? prevEndDate.add(1, 'd')
        : startDate;

      const isCreateRateInventory =
        !currentRate.isExistRate ||
        currentRate.isCategoryChanged ||
        isRateDatesExtended;

      if (isCreateRateInventory) {
        const inventoryDate = [inventoryStartDate, endDate];

        const inventoryRanges = mapProductRateToInventoryRequest(
          product,
          productDetailsType,
          currentRate,
          inventoryDate,
        );

        if (isEmpty(inventoryRanges)) {
          const inventory = createInventory(
            product,
            productDetailsType,
            currentRate,
            inventoryDate,
          );

          inventoryStore.createInventoryRange(inventory);
        } else {
          inventoryStore.createOrUpdateMultipleInventories(inventoryRanges);
        }
      }
    });

    if (inventoryStore.idsOfDeletedRates.length) {
      inventoryStore.deleteRates(
        inventoryStore.idsOfDeletedRates,
        productDetailsType,
      );
    }

    const deletedRates = (currentProduct?.Rates || []).filter((rate) =>
      inventoryStore.idsOfDeletedRates.includes(rate.Id),
    );
    const inventoriesToRemove = inventoryStore.inventories
      .filter((inventory) =>
        deletedRates.find((rate) => rate.CategoryId === inventory.CategoryId),
      )
      .map((inventory) => inventory.Id);

    if (inventoriesToRemove.length) {
      inventoryStore.deleteInventories(inventoriesToRemove);
    }

    const { mediaSectionsToSave, mediaIdsToDelete } = mapMediaValuesToRequest(
      product,
      currentProduct,
      inventoryStore,
    );

    return {
      updatedGeneralInfo,
      updatedRates,
      updatedPolicies,
      updatedMediaSections: mediaSectionsToSave,
      deletedMediaSectionIds: mediaIdsToDelete,
      updateTimes,
    };
  }

  // Create

  const newGeneralInfo = {
    Id: product.Id,
    Name: product.Name,
    Inactive: product.Inactive,
    RestrictionCode: product.RestrictionCode,
    OperatorId: operatorId,
    ServiceTypeId: product.ServiceTypeId,
    PackageTypeId: product.PackageTypeId,
    DestinationId: product.DestinationId,
    DefaultCutoffMins:
      productDetailsType === ProductTypeEnum.Package
        ? undefined
        : getDefaultCutoffMins(product.CutoffHours),
    MinDuration: product.MinDuration,
    MaxDuration: product.MaxDuration,
    DefaultTime: product.DefaultTime
      ? toServiceTime(product.DefaultTime)
      : undefined,
    StateId: product.StateId,
    CountryId: product.CountryId,
    City: product.City,
    Street: product.Street,
    Zip: product.Zip,
    Latitude: product.Latitude,
    Longitude: product.Longitude,
  };

  const { mediaSectionsToSave } = mapMediaValuesToRequest(
    product,
    currentProduct,
    inventoryStore,
  );

  const newPolicies = {
    ProductType: productDetailsType,
    ProductId: product.Id,
    UnitsOffset: product.Policies[0].Days,
    TimeUnits: product.Policies[0].TimeUnits,
    NonRefundable: product.Policies[0].NonRefundable,
    [product.Policies[0].Type === 'percentage' ? 'Percentage' : 'Flat']:
      product.Policies[0].Penalty,
  };

  const newProductRates = new CreateRatesMapper(
    product.Id,
    product.Rates,
  ).map();

  product.Rates.forEach((currentRate) => {
    if (currentRate.CategoryId && !currentRate.IsExistsCategory) {
      inventoryStore.createCategory({
        Id: currentRate.CategoryId,
        Name: currentRate.CategoryName,
      });
    }

    const inventoryDate = currentRate.InventoryDate;

    const inventoryRanges = mapProductRateToInventoryRequest(
      product,
      productDetailsType,
      currentRate,
      inventoryDate,
    );

    if (isEmpty(inventoryRanges)) {
      const inventory = createInventory(
        product,
        productDetailsType,
        currentRate,
        inventoryDate,
      );

      inventoryStore.createInventoryRange(inventory);
    } else {
      inventoryStore.createOrUpdateMultipleInventories(inventoryRanges);
    }
  });

  const newTimes = product.Times.map((time) => ({
    ProductType: productDetailsType,
    ProductId: product.Id,
    RecursEvery: time.RecursEvery,
    Time: correctFormatTime(time.Time) ?? undefined,
    EndTime: correctFormatTime(time.EndTime) ?? undefined,
    CategoryId: time.CategoryId === EMPTY_VALUE ? null : time.CategoryId,
    DaysOfWeek: getDaysOfWeekInt(time.DaysOfWeek),
  }));

  return {
    newGeneralInfo,
    newMediaSections: mediaSectionsToSave,
    newProductRates,
    newPolicies,
    newTimes,
  };
}

const mapMediaValuesToRequest = (
  product: ProductFormValuesModel,
  currentProduct: ProductFormValuesModel,
  inventoryStore: InventoryStore,
) => {
  const mediaSectionsToSave = product.Media.map((media) => ({
    ProductType: media.ProductType ?? inventoryStore.productDetailsType,
    ProductId: product.Id,
    FormattedText: media.FormattedText ?? '',
    Id: media.Id,
    LanguageId: media.LanguageId,
    CategoryId: media.CategoryId,
    InHouse: media.InHouse,
    Section: media.Section,
    Title: media.Title,
    SubTitle: media.SubTitle,
    LargeImageURI: media.LargeImageURI,
    MediumImageURI: media.MediumImageURI,
    SmallImageURI: media.SmallImageURI,
    ThumbnailImageURI: media.ThumbnailImageURI,
    ImageCaption: media.ImageCaption,
    AgencyId: media.AgencyId,
    Visibility: media.Visibility,
    Inactive: media.Inactive,
    Images: media.Images,
    ServiceTime: media.ServiceTime,
    Product: media.Product,
    Category: media.Category,
    ServiceStartDate: media.ServiceStartDate,
    ServiceEndDate: media.ServiceEndDate,
    BookingStartDate: media.BookingStartDate,
    BookingEndDate: media.BookingEndDate,
    Description: media.Description,
  }));

  const mediaIdsToDelete = differenceBy(
    currentProduct?.Media,
    product.Media,
    (media) => media.Id,
  ).map(({ Id }) => Id);

  return { mediaSectionsToSave, mediaIdsToDelete };
};

export const convertImagesToRequest = (images: PreviewFile[]) =>
  images.map((image) => convertImageToRequest(image)).flat();

const convertImageToRequest = (image: PreviewFile) => {
  const isNewImage = !image.apiId;
  const data = isNewImage ? image.thumbUrl : null;

  // image path
  return {
    Id: image.apiId,
    ImagePath: isNewImage ? image.fileName : image.apiImagePath,
    FilePath: image.apiImagePath,
    Data: image.apiImagePath ? undefined : data,
    Caption: image.caption,
    Size: image.apiSize,
    IsCoverImage: Boolean(image.isPrimary),
    MediaInfoId: image.mediaKey,
  };
};

export const convertImageToDelete = (image: any) =>
  // image path
  ({
    Id: image.Id,
    IsCoverImage: image.IsCoverImage,
    Size: image.Size,
    MediaInfoId: image.MediaInfoId,
  });

export const patchFileListMediaId = (
  fileList: PreviewFile[],
  media: FormMediaModel[],
  updatedMediaIds: number[],
) =>
  fileList.map((file) => {
    const index = media.findIndex(({ key }) => key === file.mediaKey);

    return { ...file, mediaKey: updatedMediaIds[index].toString() };
  });

export const isExistsProductId = async (
  productId: string,
  productType: ProductTypeEnum,
) => {
  const { data } = await new InventoryApi().isExistsProductId(
    productId,
    productType,
  );

  return data;
};

export const isExistsCategoryId = async (categoryId: string) => {
  const { data } = await new InventoryApi().isExistsCategoryId(categoryId);

  return data.value;
};

export const generateProductId = async (
  currentProduct: ProductDetailsModel,
  destinationId,
  name,
  productType: ProductTypeEnum,
  operatorCode = '',
) => {
  /*
  1) Replace unnecessary characters with spaces
  2) Remove duplicate spaces
  3) Get an array (of words) using a separator (space) that contain only letters and numbers
  4) Take the first character from each element of the array
  5) If the code is ununique, then add one character from the array (product name part) until the code becomes unique.
  -if there are no more characters in the current element of the array, then go to the next array element
  -If we walked through the all array elements, and the code is not yet unique, we can add numbers
  -If we have reached the maximum number of characters, and the code is not unique, then we can just offer the user to edit
  */

  if (currentProduct || !destinationId || !name) {
    return { code: '', isExistsProduct: false };
  }

  const productNameWords = name
    .toUpperCase()
    .replace(/[^a-zA-Z]/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim()
    .split(' '); // 1,2,3 steps
  let code = `${operatorCode}${productNameWords.reduce(
    (all, word) => `${all}${word.substring(0, 1)}`,
    destinationId,
  )}`; // 4 step
  let isExistsProduct = true;

  isExistsProduct = await isExistsProductId(code, productType);

  if (isExistsProduct) {
    // 5 step
    const maxCodeLength = 32;
    const codeWords = code.split(''); // necessary to accumulate addition characters into the correct place
    let productWordIndex = 0; // iteration by product name words
    let characterIndex = 1; // iteration by characters by product name words. Starts with the second character, since the first is already added at the 4 step
    let needAddNumbers = false;
    let additionNumber = 0;

    while (isExistsProduct && codeWords.join('').length < maxCodeLength) {
      if (!needAddNumbers) {
        const codeWordIndex = productWordIndex + destinationId.length; // current product word index in the code

        codeWords[
          codeWordIndex
        ] = `${codeWords[codeWordIndex]}${productNameWords[productWordIndex][characterIndex]}`; // adding addition character into the correct place
      } else {
        codeWords[
          operatorCode.length + destinationId.length + productNameWords.length
        ] = `${additionNumber}`; // adding addition number into the end
      }

      // eslint-disable-next-line no-await-in-loop
      isExistsProduct = await isExistsProductId(
        codeWords.join(''),
        productType,
      );

      if (isExistsProduct) {
        if (characterIndex < productNameWords[productWordIndex].length - 1) {
          // if not all characters passed
          characterIndex += 1; // go to the next character in the current word
        } else if (productWordIndex < productNameWords.length - 1) {
          // if not all words passed
          productWordIndex += 1; // go to the next word
          characterIndex = 1;
        } else {
          // when all words and all characters are passed - go to adding addition number
          needAddNumbers = true;
          additionNumber += 1;
        }
      }
    }

    code = codeWords.join('');
  }

  return { code, isExistsProduct };
};

export const getPrimaryImageApiSizes = (media: {
  LargeImageURI: string;
  MediumImageURI: string;
  SmallImageURI: string;
  ThumbnailImageURI: string;
}) => {
  const result = [];

  if (media.LargeImageURI) {
    result.push(ImageSizeEnum.LARGE);
  }

  if (media.MediumImageURI) {
    result.push(ImageSizeEnum.MEDIUM);
  }

  if (media.SmallImageURI) {
    result.push(ImageSizeEnum.SMALL);
  }

  if (media.ThumbnailImageURI) {
    result.push(ImageSizeEnum.THUMBNAIL);
  }

  return result;
};

export const getImageUid = (mediaKey: string, imageIndex: number) =>
  [mediaKey, imageIndex].join('-');

const getPrimaryImageURIs = (media: any, imagesRoot: string) =>
  [
    {
      size: ImageSizeEnum.LARGE,
      uri: media.LargeImageURI,
      path: getMediaImageUrl(media.LargeImageURI, imagesRoot),
    },
    {
      size: ImageSizeEnum.MEDIUM,
      uri: media.MediumImageURI,
      path: getMediaImageUrl(media.MediumImageURI, imagesRoot),
    },
    {
      size: ImageSizeEnum.SMALL,
      uri: media.SmallImageURI,
      path: getMediaImageUrl(media.SmallImageURI, imagesRoot),
    },
    {
      size: ImageSizeEnum.THUMBNAIL,
      uri: media.ThumbnailImageURI,
      path: getMediaImageUrl(media.ThumbnailImageURI, imagesRoot),
    },
  ].filter(({ uri }) => uri);

export const generateUploadFileList = (
  media: FormMediaModel[],
  settings: any,
) =>
  media
    .filter(({ Section }) => Section === settings.MainMediaSection)
    .flatMap((mediaElement) =>
      generateMediaUploadFileList(mediaElement, settings.ImagesRoot),
    )
    .filter(Boolean);

const getMediaImageUrl = (path = '', imagesRoot = '') =>
  [imagesRoot, path.replace(/\\/g, '/')].join('');

const getMediaImageName = (uri = '') => uri.split('\\').reverse()[0];

export const generateMediaUploadFileList = (
  media: FormMediaModel,
  imagesRoot: string,
): PreviewFile[] => {
  if (
    !media ||
    !(
      media.LargeImageURI ||
      media.MediumImageURI ||
      media.SmallImageURI ||
      media.ThumbnailImageURI
    )
  )
    return [];

  const primaryFileName = getMediaImageName(media.LargeImageURI);
  const primaryImageURIs = getPrimaryImageURIs(media, imagesRoot);

  const primaryImages = primaryImageURIs.map(({ size, path, uri }, index) => ({
    uid: getImageUid(media.key, index),
    name: primaryFileName,
    status: ImagesStatusEnum.PRIMARY,
    url: path,
    isPrimary: true,
    response: intl.get('SUPPLIER.PRODUCT_DETAILS.IMAGES_SECTION.IS_PRIMARY'),
    apiSize: size,
    caption: media.ImageCaption,
    thumbUrl: path,
    size: null,
    type: null,
    apiId: media.Id,
    mediaKey: media.key,
    apiImagePath: uri,
  }));

  const images = media.Images.filter((image) => image.ImagePath).map(
    (image, idx) => {
      const url = getMediaImageUrl(image.ImagePath, imagesRoot);

      return {
        uid: getImageUid(media.key, idx),
        name: getMediaImageName(image.ImagePath),
        status: ImagesStatusEnum.DONE,
        url,
        isPrimary: image.IsCoverImage,
        apiSize: image.Size,
        caption: image.Caption,
        thumbUrl: url,
        size: null,
        type: null,
        apiId: image.Id,
        mediaKey: media.key,
        apiImagePath: image.ImagePath,
      };
    },
  );

  return [...primaryImages, ...images];
};

export const mapFileListToRequest = (
  fileList: PreviewFile[],
  oldFileList: PreviewFile[],
) => {
  const fileListWithNewImages = fileList.filter((file) => !file.apiId);
  const alreadyUploadedInNewFileList = fileList.filter((file) => file.apiId);

  const deletedPreviewImages = oldFileList.filter(
    (oldFile) => !fileList.find((uploaded) => uploaded.uid === oldFile.uid),
  );

  const updatedPreviewImages = alreadyUploadedInNewFileList.filter(
    (alreadyUploaded) =>
      !isEqual(
        alreadyUploaded,
        oldFileList.find((old) => old.uid === alreadyUploaded.uid),
      ),
  );

  const newImages = convertImagesToRequest(fileListWithNewImages);
  const deletedImages = convertImagesToRequest(deletedPreviewImages);
  const updatedImages = convertImagesToRequest(updatedPreviewImages);

  return {
    newImages,
    deletedImages,
    updatedImages,
  };
};

export const generateCategoryId = async (name: string) => {
  const maxCodeLength = 32;
  if (!name) {
    return { code: '', IsExistsCategory: false };
  }

  const Name = name.trim();
  const { request } = new InventoryApi().getCategories({
    params: {
      $filter: `Name eq '${Name}'`,
    },
  });
  const response = await request;
  const [existingCategory] = response.data.value;

  if (existingCategory) {
    return { code: existingCategory.Id, IsExistsCategory: true };
  }
  /*
  1) Replace unnecessary characters with spaces
  2) Remove duplicate spaces
  3) Get an array (of words) using a separator (space) that contain only letters and numbers
  4) Take the first character from each element of the array
  5) If the code is ununique, then add one character from the array (product name part) until the code becomes unique.
  -if there are no more characters in the current element of the array, then go to the next array element
  -If we walked through the all array elements, and the code is not yet unique, we can add numbers
  -If we have reached the maximum number of characters, and the code is not unique, then we can just offer the user to edit
  */

  const productNameWords = name
    .toUpperCase()
    .replace(/[^a-zA-Z0-9]/g, ' ')
    .replace(/\s{1,}/g, ' ')
    .trim()
    .split(' '); // 1,2,3 steps
  let code =
    productNameWords.length === 1
      ? productNameWords[0].slice(0, maxCodeLength)
      : productNameWords.reduce(
          (all, word) => `${all}${+word ? word : word.substring(0, 1)}`,
          '',
        ); // 4 step
  let IsExistsCategory = true;

  IsExistsCategory = await isExistsCategoryId(code);

  if (IsExistsCategory) {
    // 5 step
    const codeWords = code.split(''); // necessary to accumulate addition characters into the correct place
    let productWordIndex = 0; // iteration by product name words
    let characterIndex = 1; // iteration by characters by product name words. Starts with the second character, since the first is already added at the 4 step
    let needAddNumbers = false;
    let additionNumber = 0;

    while (IsExistsCategory && codeWords.join('').length < maxCodeLength) {
      if (!needAddNumbers) {
        const codeWordIndex = productWordIndex; // current product word index in the code

        codeWords[
          codeWordIndex
        ] = `${codeWords[codeWordIndex]}${productNameWords[productWordIndex][characterIndex]}`; // adding addition character into the correct place
      } else {
        codeWords[productNameWords.length] = `${additionNumber}`; // adding addition number into the end
      }

      // eslint-disable-next-line no-await-in-loop
      IsExistsCategory = await isExistsCategoryId(codeWords.join(''));

      if (IsExistsCategory) {
        if (characterIndex < productNameWords[productWordIndex].length - 1) {
          // if not all characters passed
          characterIndex += 1; // go to the next character in the current word
        } else if (productWordIndex < productNameWords.length - 1) {
          // if not all words passed
          productWordIndex += 1; // go to the next word
          characterIndex = 1;
        } else {
          // when all words and all characters are passed - go to adding addition number
          needAddNumbers = true;
          additionNumber += 1;
        }
      }
    }

    code = codeWords.join('');
  }

  return { code, IsExistsCategory };
};

export const setFieldValuesLikeObject = (
  values: any,
  prevName: string,
  setFiledValue: Function,
) => {
  if (typeof values === 'object' && values !== null) {
    Object.keys(values).forEach((key) => {
      setFieldValuesLikeObject(
        values[key],
        `${prevName ? `${prevName}.` : ''}${key}`,
        setFiledValue,
      );
    });
  } else {
    setFiledValue(prevName, values);
  }
};

export const checkIsRateDatesExtended = (
  prevEndDate: Moment,
  currentEndDate: Moment,
) => {
  if (!prevEndDate || !currentEndDate) return false;

  return currentEndDate.isAfter(prevEndDate);
};

const isIncludeDayOfWeek = (daysOfWeek: string[], date: Moment) =>
  daysOfWeek.includes(date.format('dddd'));

const mapTimesToInventoryDates = (times, inventoryDate) =>
  times.map((time) => {
    const [rateInventoryFrom, rateInventoryTo] = inventoryDate;

    const [timeServiceStart, timeServiceEnd] = time.ServiceStartEnd;

    const daysOfWeek = mapDaysOfWeekValuesToKeys(time.DaysOfWeek);

    const isDaysOfWeekExist = !isEmpty(daysOfWeek);

    const isTimeServiceDatesExist = !isEmpty(time.ServiceStartEnd);

    const rateInventoryRange = momentRange.range(
      rateInventoryFrom,
      rateInventoryTo,
    );

    const timeServiceRange = momentRange.range(
      timeServiceStart,
      timeServiceEnd,
    );

    const intersectedRange = timeServiceRange.intersect(rateInventoryRange);

    const filterByDaysOfWeek = partial(isIncludeDayOfWeek, daysOfWeek);

    if (isTimeServiceDatesExist && isDaysOfWeekExist) {
      return getDatesFromRange(intersectedRange, filterByDaysOfWeek);
    }

    if (!isTimeServiceDatesExist && isDaysOfWeekExist) {
      return getDatesFromRange(rateInventoryRange, filterByDaysOfWeek);
    }

    if (isTimeServiceDatesExist && !isDaysOfWeekExist) {
      return getDatesFromRange(intersectedRange);
    }

    return [];
  });

const splitTimesByIntersection = (times) => {
  const intersectedTimes = [];
  const nonIntersectedTimes = [];

  times.forEach((time) => {
    const [firstTimeStart, firstTimeEnd] = times[0].ServiceStartEnd;

    const [currentTimeStart, currentTimeEnd] = time.ServiceStartEnd;

    const firstTimeRange = momentRange.range(firstTimeStart, firstTimeEnd);

    const currentTimeRange = momentRange.range(
      currentTimeStart,
      currentTimeEnd,
    );

    if (firstTimeRange.intersect(currentTimeRange)) {
      intersectedTimes.push(time);
    } else {
      nonIntersectedTimes.push(time);
    }
  });

  return [intersectedTimes, nonIntersectedTimes];
};

const mapProductRateToInventoryRequest = (
  product,
  productType,
  rate,
  inventoryDate,
) => {
  const rateTimesToCreateInventory = product.Times.filter(
    (time) => time.CategoryId === rate.CategoryId && time.CreateAvailability,
  );

  const [intersectedTimes, nonIntersectedTimes] = splitTimesByIntersection(
    rateTimesToCreateInventory,
  );

  const intersectedTimesDatesToCreateInventory = mapTimesToInventoryDates(
    intersectedTimes,
    inventoryDate,
  );

  const nonIntersectedTimesDatesToCreateInventory = mapTimesToInventoryDates(
    nonIntersectedTimes,
    inventoryDate,
  );

  const shortestIntersectedTimes = findShortestArray(
    intersectedTimesDatesToCreateInventory,
  );

  return [
    ...nonIntersectedTimesDatesToCreateInventory.flat(),
    ...shortestIntersectedTimes,
  ].map((date) => createInventory(product, productType, rate, [date]));
};

const createInventory = (product, productType, rate, range = []) => {
  const [startDate, endDate] = range;

  return {
    ProductType: productType,
    ProductId: product.Id,
    CategoryId: rate.CategoryId,
    OriginalQuantity: rate.DefaultAvailability,
    AvailableQuantity: rate.DefaultAvailability,
    StopSell: rate.StopSell,
    Time: rate.ServiceTime
      ? startDate?.clone()?.set({
          hour: rate.ServiceTime.get('h'),
          minute: rate.ServiceTime.get('m'),
          second: 0,
          millisecond: 0,
        })
      : null,
    Date: startDate?.format('YYYY-MM-DD'),
    EndDate: endDate?.format('YYYY-MM-DD'),
    BookingCutoffHours: product.CutoffHours,
  };
};

export const getAvailabilityQuantityByEditType = (
  value: number,
  inventory: InventoryModel,
  editType: InventoryEditTypeEnum,
) => {
  switch (editType) {
    case InventoryEditTypeEnum.Increase: {
      return value && inventory.AvailableQuantity + value;
    }

    case InventoryEditTypeEnum.Decrease: {
      return value && inventory.AvailableQuantity - value;
    }

    case InventoryEditTypeEnum.SetTo: {
      return value && value;
    }

    default: {
      return inventory.AvailableQuantity;
    }
  }
};
