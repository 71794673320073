import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

function AfterOrderSubmit(props: { orderId: number }) {
  const { orderId } = props;

  const navigate = useNavigate();

  return (
    <div className="checkout-page">
      <div className="header">
        <div className="title">{intl.get('checkout.popup.confirmation')}</div>
      </div>
      <div className="after-order-submit">
        <div className="confirmation-header">
          <CheckCircleOutlined className="header-icon" />
          <span>{intl.get('checkout.popup.header')}</span>
        </div>
        <div className="top-content">
          <div className="order-number">
            <span>
              {intl.get('checkout.popup.order')} #{orderId}
            </span>
          </div>
          <div className="confirmation-body">
            <p>{intl.get('checkout.popup.body')}</p>
            <p>{intl.get('checkout.popup.notice')}</p>
          </div>
          <div className="confirmation-btns">
            <Button
              className="continue"
              onClick={() => navigate('/marketplace', { replace: true })}
            >
              {intl.get('checkout.popup.continueShopping')}
            </Button>
            <Button onClick={() => navigate('/orders', { replace: true })}>
              {intl.get('checkout.popup.manageOrders')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AfterOrderSubmit;
