import * as Yup from 'yup';
import intl from 'react-intl-universal';
import moment, { Moment } from 'moment';

import { BookingQuestionModel } from '../../../../models/tour-details/bookingQuestion.model';
import { BookingQuestionEnum } from '../../../../models/tour-details/bookingQuestion.enum';
import {
  DatepickerContainer,
  InputContainer,
  TimePickerContainer,
} from '../../../FormContainers';

const defaultFormat = 'DD MMMM YYYY';

export const getBookingQuestionsInitialDataForTraveler = (
  questions: BookingQuestionModel[],
) => {
  const initialValues = {};
  const shape = {};
  const components = {};
  const labels = {};
  const relevantQuestions = [];
  const otherProps = {};

  questions.forEach((question) => {
    const { questionId, Required } = question;

    switch (questionId) {
      case BookingQuestionEnum.dateOfBirth: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = moment.utc().add(1, 'd');
        components[questionId] = DatepickerContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);
        otherProps[questionId] = {
          stringAsResult: true,
          format: defaultFormat,
          disabledDate: (current: Moment) =>
            current && current > moment().endOf('day'),
        };

        return;
      }
      case BookingQuestionEnum.passengerHeights: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.number()
              .typeError(intl.get('tourDetails.shouldBeNumber'))
              .required(intl.get('required'))
          : Yup.number().typeError(intl.get('tourDetails.shouldBeNumber'));
        initialValues[BookingQuestionEnum.passengerHeights] = 170;
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);
        otherProps[questionId] = {
          suffix: intl.get('suffixCm'),
        };

        return;
      }
      case BookingQuestionEnum.passportExpiryDate: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = moment.utc().add(1, 'd');
        components[questionId] = DatepickerContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);
        otherProps[questionId] = {
          stringAsResult: true,
          format: defaultFormat,
          disabledDate: (current: Moment) =>
            current && current < moment().endOf('day'),
        };

        return;
      }
      case BookingQuestionEnum.passportNationality: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = '';
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);

        return;
      }
      case BookingQuestionEnum.passportNumber: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = '';
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);

        return;
      }
      case BookingQuestionEnum.passengerWeights: {
        shape[questionId] = Required
          ? Yup.number()
              .typeError(intl.get('tourDetails.shouldBeNumber'))
              .required(intl.get('required'))
          : Yup.number();
        initialValues[questionId] = 65;
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);
        otherProps[questionId] = {
          suffix: intl.get('suffixKilos'),
        };
      }
    }
  });

  const validationSchema = Yup.object().shape(shape);

  return {
    initialValues,
    validationSchema,
    components,
    labels,
    relevantQuestions,
    otherProps,
  };
};

export const getBookingQuestionsInitialDataForProduct = (
  questions: BookingQuestionModel[],
) => {
  const initialValues = {};
  const shape = {};
  const components = {};
  const labels = {};
  const relevantQuestions = [];
  const otherProps = {};

  questions.forEach((question) => {
    const { questionId, Required } = question;

    switch (questionId) {
      case BookingQuestionEnum.arrivalAirline: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = '';
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);

        return;
      }
      case BookingQuestionEnum.arrivalFlightNumber: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = '';
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);

        return;
      }
      case BookingQuestionEnum.departureAirline: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = '';
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);

        return;
      }

      case BookingQuestionEnum.departureFlightNumber: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = '';
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);

        return;
      }

      case BookingQuestionEnum.dropOffLocation: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = '';
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);

        return;
      }

      case BookingQuestionEnum.arrivalTime: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = moment('10:00', 'HH:mm');
        components[questionId] = TimePickerContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);
        otherProps[questionId] = {
          stringAsResult: true,
        };

        return;
      }

      case BookingQuestionEnum.departureDate: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = moment.utc().add(1, 'd');
        components[questionId] = DatepickerContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);
        otherProps[questionId] = {
          disabledDate: (current: Moment) =>
            current && current < moment().endOf('day'),
          stringAsResult: true,
          format: defaultFormat,
        };

        return;
      }
      case BookingQuestionEnum.pickUpLocation: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = '';
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);

        return;
      }
      case BookingQuestionEnum.departureTime: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = moment('22:00', 'HH:mm');
        components[questionId] = TimePickerContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);
        otherProps[questionId] = {
          stringAsResult: true,
        };

        return;
      }

      case BookingQuestionEnum.disembarkationTime: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = moment('12:00', 'HH:mm');
        components[questionId] = TimePickerContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);
        otherProps[questionId] = {
          stringAsResult: true,
        };

        return;
      }

      case BookingQuestionEnum.cruiseShip: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = '';
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);

        return;
      }

      case BookingQuestionEnum.boardingTime: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = moment('12:00', 'HH:mm');
        components[questionId] = TimePickerContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);
        otherProps[questionId] = {
          stringAsResult: true,
        };

        return;
      }
      case BookingQuestionEnum.arrivalRailLine: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = '';
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);

        return;
      }
      case BookingQuestionEnum.arrivalRailStation: {
        // need info how it should be represented
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = '';
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);

        return;
      }
      case BookingQuestionEnum.departureRailLine: {
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = '';
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);

        return;
      }
      case BookingQuestionEnum.departureRailStation: {
        shape[questionId] = Required
          ? Yup.string().required(intl.get('required'))
          : Yup.string();
        initialValues[questionId] = '';
        components[questionId] = InputContainer;
        labels[questionId] = intl.get(`bookingQuestions.${questionId}`);
        relevantQuestions.push(question);
      }
    }
  });

  const validationSchema = Yup.object().shape(shape);

  return {
    initialValues,
    validationSchema,
    components,
    labels,
    relevantQuestions,
    otherProps,
  };
};
