import { useCallback, useEffect, useState } from 'react';

import { useStore } from 'hooks';
import { OrdersFilterSuggestionModel } from 'models/orders/ordersFilterSuggestion.model';

export default function useTableOrdersFilterLoader(
  field: string,
  visible: boolean,
) {
  const [isLoading, setIsLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState<
    OrdersFilterSuggestionModel[]
  >([]);

  const ordersStore = useStore((state) => state.ordersStore);

  const loadFilterOptions = useCallback(async () => {
    try {
      setIsLoading(true);

      const data = await ordersStore.loadFilterOptions(field);

      setFilterOptions(data);
      setIsLoading(false);
    } catch (_) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!visible) return;

    loadFilterOptions();
  }, [visible, loadFilterOptions]);

  return { isLoading, filterOptions };
}
