import React from 'react';
import classnames from 'classnames';

import { ReactComponent as AmericanExpress } from '../../../../assets/images/icons/american-express.svg';
import { ReactComponent as Discover } from '../../../../assets/images/icons/discover.svg';
import { ReactComponent as MasterCard } from '../../../../assets/images/icons/mastercard.svg';
import { ReactComponent as Visa } from '../../../../assets/images/icons/visa.svg';

type Props = {
  className?: string;
};

export default function AcceptedCreditCardsIcons(props: Props) {
  const { className } = props;

  return (
    <div className={classnames('accepted-credit-cards-icons', className)}>
      <span>
        <Visa />
      </span>

      <span>
        <MasterCard />
      </span>

      <span>
        <AmericanExpress />
      </span>

      <span>
        <Discover />
      </span>
    </div>
  );
}
