import React from 'react';
import intl from 'react-intl-universal';
import { Col, Row } from 'antd';

import LandingHowItWorksCard from './LandingHowItWorksCard';

function LandingHowItWorks() {
  const sections = [
    {
      key: 1,
      caption: intl.get('landingHowWorks.1.caption'),
      description: intl.get('landingHowWorks.1.description'),
      cards: [
        {
          key: 1,
          icon: 'user-add',
          caption: intl.get('landingHowWorks.1.cards.1.caption'),
          description: intl.get('landingHowWorks.1.cards.1.description'),
        },
        {
          key: 2,
          icon: 'tasks',
          caption: intl.get('landingHowWorks.1.cards.2.caption'),
          description: intl.get('landingHowWorks.1.cards.2.description'),
        },
      ],
    },
    {
      key: 2,
      caption: intl.get('landingHowWorks.2.caption'),
      description: intl.get('landingHowWorks.2.description'),
      cards: [
        {
          key: 1,
          icon: 'calendar',
          caption: intl.get('landingHowWorks.2.cards.1.caption'),
          description: intl.get('landingHowWorks.2.cards.1.description'),
        },
        {
          key: 2,
          icon: 'account-outlined',
          caption: intl.get('landingHowWorks.2.cards.2.caption'),
          description: intl.get('landingHowWorks.2.cards.2.description'),
        },
      ],
    },
    {
      key: 3,
      caption: intl.get('landingHowWorks.3.caption'),
      description: intl.get('landingHowWorks.3.description'),
      cards: [
        {
          key: 1,
          icon: 'payment',
          caption: intl.get('landingHowWorks.3.cards.1.caption'),
          description: intl.get('landingHowWorks.3.cards.1.description'),
        },
        {
          key: 2,
          icon: 'payment',
          caption: intl.get('landingHowWorks.3.cards.2.caption'),
          description: intl.get('landingHowWorks.3.cards.2.description'),
        },
      ],
    },
  ];

  return (
    <section className="landing-how-works">
      <div className="landing-how-works__container">
        <h2 className="landing-how-works__caption">
          {intl.get('landingHowWorks.caption')}
        </h2>

        <Row gutter={[0, 70]}>
          {sections.map((section) => (
            <Col
              key={section.key}
              span={24}
              className="landing-how-works-section"
            >
              <h3 className="landing-how-works-section__caption">
                {section.caption}
              </h3>

              <p className="landing-how-works-section__description">
                {section.description}
              </p>

              <Row gutter={[90, 30]}>
                {section.cards.map((card) => (
                  <Col key={card.key} xs={24} sm={10}>
                    <LandingHowItWorksCard
                      icon={card.icon}
                      caption={card.caption}
                      description={card.description}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
}

export default LandingHowItWorks;
