import { SiteSettingsModel } from 'models/site-settings/siteSettings.model';
import { SourceSiteSettingsModel } from 'models/site-settings/sourceSiteSettings.model';
import { generalToken, serverUrl } from './constants';

class SiteSettingsUtils {
  public defaultSiteSettings: SiteSettingsModel = {
    agencyId: null,
    domains: ['localhost'],
    apiUrl: serverUrl,
    token: generalToken,
    destinations: [],
    defaultDestinations: [],
    serviceTypes: null,
    productsIncluded: [],
    productsExcluded: [],
    specificProducts: [],
    brandingSettings: {
      portalName: 'Travel USA Express <span class="bold">Partner Login</span>',
      portalTitle: 'Travel USA Express',
      portalLogo: '/images/logo/travel_usa_logo.png',
      portalLogoCaption: 'Travel USA Express logo',
      splashImage: '/images/logo/travel_usa_logo.png',
      splashImageCaption: '',
      portalFavIcon: '/images/logo/travel_usa_favicon.ico',
      portalFavIconCaption: '',
    },
    ordersListColumns: null,
    supplierMergeTags: null,
    agencyMergeTags: null,
    agentRegistration: null,
  };

  public getDomainSettings(
    domain: string,
    siteSettings: SourceSiteSettingsModel[],
  ) {
    if (domain) {
      const domainSettings = this.mapSourceSiteSettings(siteSettings).find(
        (settings) => settings.domains.some((key) => key === domain),
      );

      if (domainSettings) {
        return domainSettings;
      }
    }

    return this.defaultSiteSettings;
  }

  private mapSourceSiteSettings(
    siteSettings: SourceSiteSettingsModel[],
  ): SiteSettingsModel[] {
    return siteSettings.map((siteSetting) => ({
      agencyId: siteSetting.AgencyId,
      domains: siteSetting.Domains,
      apiUrl: siteSetting.ApiUrl,
      token: siteSetting.Token,
      destinations: siteSetting.Destinations || [],
      defaultDestinations: siteSetting.DefaultDestinations || [],
      serviceTypes: siteSetting.ServiceTypes,
      productsIncluded: siteSetting.ProductsIncluded || [],
      productsExcluded: siteSetting.ProductsExcluded || [],
      specificProducts: siteSetting.SpecificProducts || [],
      brandingSettings: {
        portalName: siteSetting.BrandingSettings?.Name,
        portalTitle: siteSetting.BrandingSettings?.Title,
        portalLogo: siteSetting.BrandingSettings?.Logo?.ImagePath,
        portalLogoCaption: siteSetting.BrandingSettings?.Logo?.Caption,
        splashImage: siteSetting.BrandingSettings?.SplashImage?.ImagePath,
        splashImageCaption: siteSetting.BrandingSettings?.SplashImage?.Caption,
        portalFavIcon: siteSetting.BrandingSettings?.Favicon?.ImagePath,
        portalFavIconCaption: siteSetting.BrandingSettings?.Favicon?.Caption,
      },
      largeImageAspectRatio: siteSetting.LargeImageAspectRatio,
      mediumImageAspectRatio: siteSetting.MediumImageAspectRatio,
      smallImageAspectRatio: siteSetting.SmallImageAspectRatio,
      thumbnailImageAspectRatio: siteSetting.ThumbnailImageAspectRatio,
      ordersListColumns: siteSetting.OrdersListColumns ?? [],
      supplierMergeTags: siteSetting.SupplierMergeTags,
      agencyMergeTags: siteSetting.AgencyMergeTags,
      unlayerProjectId: siteSetting.UnlayerProjectId,
      agentRegistration: siteSetting.AgentRegistration,
    }));
  }
}

export default new SiteSettingsUtils();
