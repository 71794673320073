import { compact, isNil, isArray, toNumber } from 'lodash';
import moment from 'moment';

import { parseServiceTime } from 'utils/datesUtils';
import { RateModel } from '../models/inventory/rate.model';
import { CategoryModel } from '../models/inventory/category.model';
import { FormRateModel } from '../models/inventory/formRate.model';
import { CommissionTypeEnum } from '../models/enums/commissionType.enum';
import { RegularRatesModel } from '../models/inventory/regularRates.model';
import { AgeTypeEnum } from '../models/enums/ageType.enum';
import { VisibilityCopyEnum } from '../models/enums/visibilityCopy.enum';
import { getDaysOfWeekList, getVisibilityValue } from './rateUtils';
import RateAgeFormatter from './rateAgeFormatter';

export default class FormRatesMapper {
  constructor(
    private readonly rates: RateModel[],
    private readonly productName: string,
    private readonly categories: CategoryModel[],
  ) {}

  private filterRegularRate<T>(value: unknown, result: T): T | null {
    return value ? result : null;
  }

  private readonly rateFieldsProjection: Partial<
    Record<
      keyof RateModel,
      (
        rate: RateModel,
        value: unknown,
      ) => RegularRatesModel | RegularRatesModel[] | null
    >
  > = {
    AdultRetail: (rate, value) =>
      isArray(value)
        ? compact(
            value.map((price, index) =>
              this.filterRegularRate(price, {
                Type: rate.UnitRate[index]
                  ? AgeTypeEnum.Group
                  : AgeTypeEnum.Adult,
                Retail: price,
                Cost: rate.AdultCost[index],
                Rate: rate.AdultRate[index],
                CostBeforeTax: rate.AdultCostBeforeTax[index],
                MaxOccupancy: rate.MaxOccupancy[index],
              }),
            ),
          )
        : null,
    ChildRetail: (rate, value) =>
      this.filterRegularRate(value, {
        Type: AgeTypeEnum.Child,
        Retail: rate.ChildRetail,
        Rate: rate.ChildRate,
        AgeMin: new RateAgeFormatter(rate.ChildAgeLimit).getMin(),
        AgeMax: new RateAgeFormatter(rate.ChildAgeLimit).getMax(),
        Cost: rate.ChildCost,
        CostBeforeTax: rate.ChildCostBeforeTax,
      }),
    JuniorRetail: (rate, value) =>
      this.filterRegularRate(value, {
        Type: AgeTypeEnum.Junior,
        Retail: rate.JuniorRetail,
        Rate: rate.JuniorRate,
        AgeMin: new RateAgeFormatter(rate.JuniorAgeLimit).getMin(),
        AgeMax: new RateAgeFormatter(rate.JuniorAgeLimit).getMax(),
        Cost: rate.JuniorCost,
        CostBeforeTax: rate.JuniorCostBeforeTax,
      }),
    SeniorRetail: (rate, value) =>
      this.filterRegularRate(value, {
        Retail: rate.SeniorRetail,
        Rate: rate.SeniorRate,
        Type: AgeTypeEnum.Senior,
        AgeMin: null,
        AgeMax: toNumber(rate.SeniorAgeLimit),
        Cost: rate.SeniorCost,
        CostBeforeTax: rate.SeniorCostBeforeTax,
      }),
    SingleRetail: (rate, value) =>
      this.filterRegularRate(value, {
        Retail: rate.SingleRetail,
        Rate: rate.SingleRate,
        Type: AgeTypeEnum.Single,
        Cost: rate.SingleCost,
        CostBeforeTax: rate.SingleCostBeforeTax,
      }),
  };

  public map() {
    return compact(
      this.rates.map((rate, index) => {
        const rateCategory = this.getRateCategory(rate);

        if (!rateCategory) return null;

        return Object.assign(new FormRateModel(), rate, {
          position: index,
          isExistRate: true,
          isCategoryChanged: false,
          CategoryName: rateCategory.Name,
          CategoryId: rateCategory.Id,
          IsExistsCategory: rateCategory.IsExistsCategory,
          Visibility: getVisibilityValue(rate.Visibility as VisibilityCopyEnum),
          CommissionPct: rate.CommissionPct ?? 0,
          CommissionFlat: rate.CommissionFlat ?? 0,
          CommissionType:
            (!isNil(rate.CommissionFlat) && CommissionTypeEnum.Flat) ||
            (!isNil(rate.CommissionPct) && CommissionTypeEnum.Percentage) ||
            CommissionTypeEnum.Percentage,
          DaysOfWeek: getDaysOfWeekList(rate.DaysOfWeek as string),
          ServiceTime: this.getServiceTime(
            rate.ServiceStartDate,
            rate.ServiceTime,
          ),
          InventoryDate:
            rate.ServiceStartDate && rate.ServiceEndDate
              ? [
                  moment(rate.ServiceStartDate, 'YYYY-MM-DD'),
                  moment(rate.ServiceEndDate, 'YYYY-MM-DD'),
                ]
              : undefined,
          DefaultAvailability: undefined,
          StopSell: false,
          RegularRates: this.mapRateToRegularRates(rate),
        });
      }),
    );
  }

  private findCategoryById(id: string) {
    return this.categories.find((category) => category.Id === id);
  }

  private getServiceTime(date: string, time: string) {
    if (!time) return undefined;

    const momentTime = parseServiceTime(time);

    return moment(date).hours(momentTime.hours()).minutes(momentTime.minutes());
  }

  private getRateCategory(rate: RateModel) {
    const rateCategory = this.findCategoryById(rate.CategoryId);

    if (!rateCategory && rate.CategoryId === rate.ProductId) {
      return {
        Id: rate.ProductId,
        Name: this.productName,
        IsExistsCategory: true,
      };
    }

    return rateCategory;
  }

  private mapRateToRegularRates(rate: RateModel) {
    return Object.entries(rate)
      .reduce((acc, entry) => {
        const [key, value] = entry;

        const regularRate = this.rateFieldsProjection[key]?.(rate, value);

        return regularRate ? acc.concat(regularRate) : acc;
      }, [])
      .flat();
  }
}
