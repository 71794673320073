import { VisibilityCopyEnum } from '../enums/visibilityCopy.enum';
import { VisibilityEnum } from '../enums/visibility.enum';

export class RateModel {
  Id?: string;
  AgencyId?: string;
  ServiceTime?: string;
  Visibility?: VisibilityEnum | VisibilityCopyEnum;
  CommissionPct?: number;
  CommissionFlat?: number;
  CategoryId?: string;
  ServiceStartDate?: string;
  ServiceEndDate?: string;
  BookingStartDate?: string;
  BookingEndDate?: string;
  Description?: string;
  IsAgencyRate?: boolean;
  LastUpdate?: string;
  DaysOfWeek?: string | number[];
  MaxOccupancy?: number[];
  TransportationSegments?: number;
  TransportationDays?: number;
  UnitRate?: boolean[];
  ProductId?: string;
  Inactive?: boolean;
  AdultRate?: number[];
  AdultRetail?: number[];
  AdultNet?: number;
  AdultCost?: number[];
  AdultCostBeforeTax?: number[];
  ChildRate?: number;
  ChildRetail?: number;
  ChildNet?: number;
  ChildCost?: number;
  ChildCostBeforeTax?: number;
  JuniorRate?: number;
  JuniorRetail?: number;
  JuniorNet?: number;
  JuniorCost?: number;
  JuniorCostBeforeTax?: number;
  SeniorRate?: number;
  SeniorRetail?: number;
  SeniorNet?: number;
  SeniorCost?: number;
  SeniorCostBeforeTax?: number;
  ChildAgeLimit?: string;
  JuniorAgeLimit?: string;
  SeniorAgeLimit?: string;
  SingleRate?: number;
  SingleRetail?: number;
  SingleCost?: number;
  SingleCostBeforeTax?: number;
}
