import React, { Component, Ref } from 'react';
import classnames from 'classnames';
import { getIn, FieldProps } from 'formik';

import InputNumberStepper, {
  InputNumberStepperProps,
} from '../SharedComponents/InputNumberStepper';

type OwnProps = {
  inputRef: Ref<unknown>;
};

type Props = OwnProps & InputNumberStepperProps & FieldProps;

export default class NumberStepperContainer extends Component<Props> {
  handleChange = (value: number) => {
    const { form, field } = this.props;

    form.setFieldValue(field.name, value);
  };

  render() {
    const {
      field,
      form: { touched, errors },
      label,
      className,
      ...rest
    } = this.props;
    const fieldError: string = getIn(errors, field.name);
    const isTouched: boolean = getIn(touched, field.name);
    const hasError: boolean = fieldError && isTouched;

    return (
      <div
        className={classnames('field', { 'has-error': hasError }, className)}
      >
        {label && <h4 className="field-label">{label}</h4>}

        <InputNumberStepper {...field} {...rest} onChange={this.handleChange} />

        {hasError && <div className="field-error visible">{fieldError}</div>}
      </div>
    );
  }
}
