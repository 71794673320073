import React from 'react';
import { Upload, Button } from 'antd';
import intl from 'react-intl-universal';
import { UploadFile } from 'antd/lib/upload/interface';

import { EditOutlined } from '@ant-design/icons';
import { PreviewFile } from './ProductUpload';
import ImageCropper from '../../../../../SharedComponents/ImageCropper';

type Props = {
  previewFile: PreviewFile;
  beforeCrop: (file: File, fileList: File[]) => boolean;
  onEditImage: (file: UploadFile) => void;
};

export default function EditProductUploadDetails(props: Props) {
  const { previewFile, beforeCrop, onEditImage } = props;

  return (
    <ImageCropper
      showGrid
      rotationSlider
      aspect={previewFile?.aspect}
      beforeCrop={beforeCrop}
    >
      <Upload
        maxCount={1}
        accept="image/*"
        className="edit-product-upload-details"
        customRequest={() => undefined}
        fileList={[previewFile]}
        onChange={({ file }) => onEditImage(file)}
      >
        <Button>
          <EditOutlined /> <span>{intl.get('buttons.edit')}</span>
        </Button>
      </Upload>
    </ImageCropper>
  );
}
