import React from 'react';
import { Button, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Field, FormikValues, useFormikContext } from 'formik';
import intl from 'react-intl-universal';

import { CheckboxContainer } from '../../../../../FormContainers';
import { getVisibilityIcon } from '../../../../../../utils/rateUtils';
import { FormRateModel } from '../../../../../../models/inventory/formRate.model';

type Props = {
  rate: FormRateModel;
  onDelete: (rate: FormRateModel) => void;
};

function RatePanelActions(props: Props) {
  const { rate, onDelete } = props;

  const { values } = useFormikContext<FormikValues>();

  return (
    <Space size={10}>
      {getVisibilityIcon(values.Rates[rate.position].Visibility)}

      <div onClick={(e) => e.stopPropagation()}>
        <Field
          invertValue
          label={intl.get('inventory.products.popup.active')}
          className="m-0"
          name={`Rates.${rate.position}.Inactive`}
          component={CheckboxContainer}
        />
      </div>

      <Button icon={<DeleteOutlined />} onClick={() => onDelete(rate)} />
    </Space>
  );
}

export default RatePanelActions;
