import React, { Component } from 'react';
import classnames from 'classnames';
import { Checkbox, CheckboxProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FieldProps, getIn } from 'formik';

type OwnProps = {
  label?: string;
  fieldLabel?: string;
  invertValue?: boolean;
  onChange?: (event: CheckboxChangeEvent) => void;
};

type Props = FieldProps & CheckboxProps & OwnProps;

export default class CheckboxContainer extends Component<Props> {
  handleChange = (event: CheckboxChangeEvent) => {
    const {
      form: { setFieldValue },
      field: { name },
      invertValue,
      onChange,
    } = this.props;
    const { checked } = event.target;

    setFieldValue(name, invertValue ? !checked : checked);
    onChange?.(event);
  };

  render() {
    const {
      field,
      form: { touched, errors },
      label,
      fieldLabel,
      className,
      invertValue,
      ...rest
    } = this.props;
    const fieldError: string = getIn(errors, field.name);
    const isTouched: boolean = getIn(touched, field.name);
    const hasError: boolean = fieldError && isTouched;

    return (
      <div
        className={classnames('field', { 'has-error': hasError }, className)}
      >
        {fieldLabel && <h4 className="field-label">{fieldLabel}</h4>}

        <Checkbox
          {...field}
          {...rest}
          checked={invertValue ? !field.value : field.value}
          onChange={this.handleChange}
          className="field-label"
        >
          {label}
        </Checkbox>
        {hasError && <div className="field-error visible">{fieldError}</div>}
      </div>
    );
  }
}
