import React, { Component } from 'react';
import moment from 'moment';
import { uniq } from 'lodash';
import classnames from 'classnames';

import {
  Calendar,
  CalendarProps,
  momentLocalizer,
  stringOrDate,
} from 'react-big-calendar';
import { InventoryStore } from '../../../../../../stores/inventory.store';
import ProductAvailabilityCalendarToolbar from './ProductAvailabilityCalendarToolbar';
import {
  getIsSelected,
  sortDateArray,
} from '../../../../../../utils/formatter';
import EventComponent from './EventComponent';
import CalendarCellWrapper from './CalendarCellWrapper';
import CalendarCellHeader from './CalendarCellHeader';
import { EventModel } from '../../../../../../models/inventory/event.model';
import { withStore } from '../../../../../../hocs';
import InlineEventComponent from './InlineEventComponent';

const calendarLocalizer = momentLocalizer(moment);

// http://jquense.github.io/react-big-calendar/examples/index.html#api

export type CalendarRange = Date[] | { start: stringOrDate; end: stringOrDate };

type Props = {
  inventoryStore?: InventoryStore;
  selectedDays: string[];
  isShowOptionsInline: boolean;
  onEventsSelect: (events: string[]) => void;
} & Omit<CalendarProps<EventModel>, 'localizer'>;

@withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
}))
export default class ProductAvailabilityCalendar extends Component<Props> {
  componentWillUnmount() {
    const { inventoryStore } = this.props;

    if (inventoryStore.preventInventoriesRequest) {
      inventoryStore.preventInventoriesRequest('cancel');
    }
  }

  handleSelectSlot = (e) => {
    const { slots } = e;
    const { onEventsSelect, selectedDays } = this.props;

    const newSelectedSlots = slots
      .filter((slot) => !selectedDays.find((day) => day === slot))
      .map((i) => moment(i).format());

    const isEverySelect = slots.every((slot) =>
      getIsSelected(selectedDays, slot),
    );

    if (isEverySelect) {
      const newSelectedDays = selectedDays.filter(
        (day) => !getIsSelected(slots, day),
      );

      onEventsSelect(newSelectedDays);
    } else {
      const newSelectedDays = uniq(
        sortDateArray([...selectedDays, ...newSelectedSlots]),
      );

      onEventsSelect(newSelectedDays);
    }
  };

  handleSelectEvent = (event) => {
    const { onEventsSelect, selectedDays } = this.props;
    const isDateSelected = getIsSelected(selectedDays, event.start);

    if (isDateSelected) {
      onEventsSelect(
        selectedDays.filter(
          (day) => !moment(day).isSame(moment(event.start), 'd'),
        ),
      );
    } else {
      const newSelectedDays = uniq(
        sortDateArray([...selectedDays, moment(event.start).format()]),
      );

      onEventsSelect(newSelectedDays);
    }
  };

  dayStyleGetter = (date) => {
    const { selectedDays } = this.props;
    const isDateSelect = getIsSelected(selectedDays, date);

    if (!isDateSelect) {
      return undefined;
    }

    return {
      style: {
        outline: '2px solid #4a90e2',
        outlineOffset: '-3px',
      },
    };
  };

  render() {
    const {
      events,
      className,
      inventoryStore: { isInventoriesLoading, isAvailabilitySubmitLoading },
      isShowOptionsInline,
      selectedDays,
      ...restProps
    } = this.props;

    return (
      <Calendar
        selectable={!isInventoriesLoading && !isAvailabilitySubmitLoading}
        className={classnames(className, {
          'is-show-options-inline': isShowOptionsInline,
          'is-loading': isInventoriesLoading,
        })}
        localizer={calendarLocalizer}
        events={events}
        onSelectSlot={this.handleSelectSlot}
        onSelectEvent={this.handleSelectEvent}
        dayPropGetter={this.dayStyleGetter}
        formats={{
          eventTimeRangeFormat: ({ start }, culture, localizer) =>
            localizer.format(start, 'h:mm A', culture),
        }}
        components={{
          toolbar: ProductAvailabilityCalendarToolbar,
          event: isShowOptionsInline ? InlineEventComponent : EventComponent,
          month: {
            dateHeader: (props) => (
              <CalendarCellHeader
                {...props}
                selectedDays={selectedDays}
                events={events}
              />
            ),
          },
          dateCellWrapper: (props) => (
            <CalendarCellWrapper selectedDays={selectedDays} {...props} />
          ),
        }}
        {...restProps}
      />
    );
  }
}
