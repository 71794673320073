import { AxiosPromise, AxiosRequestConfig } from 'axios';

import { BaseApi } from './base.api';
import { NotificationTemplate } from '../models/notification-template/notificationTemplate.model';

export class NotificationTemplatesApi extends BaseApi {
  public getNotificationTemplates(
    params?: AxiosRequestConfig['params'],
  ): AxiosPromise<{
    value: NotificationTemplate[];
  }> {
    return this.axios.get('/odata/NotificationTemplates', {
      params,
    });
  }

  public getNotificationTemplate(
    id: number,
  ): AxiosPromise<NotificationTemplate> {
    return this.axios.get(`/odata/NotificationTemplates/${id}`);
  }

  public createNotificationTemplate(
    data: NotificationTemplate,
  ): AxiosPromise<NotificationTemplate> {
    return this.axios.post('/odata/NotificationTemplates', data);
  }

  public updateNotificationTemplate(
    id: number,
    data: NotificationTemplate,
  ): AxiosPromise<NotificationTemplate> {
    return this.axios.patch(`/odata/NotificationTemplates/${id}`, data);
  }

  public deleteNotificationTemplate(id: number) {
    return this.axios.delete(`/odata/NotificationTemplates/${id}`);
  }
}
