import moment from 'moment';
import { InventoryModel } from './inventory.model';

export class EventModel {
  id?: string;
  options: InventoryModel[];
  start: Date;
  end: Date;
  allDay: boolean;

  constructor(options: InventoryModel[]) {
    const [option] = options;

    const time = option.Time ? moment(option.Time) : null;
    const startMoment = moment(option.Date)
      .set({
        hours: time?.hours() ?? 0,
        minutes: time?.minutes() ?? 0,
        seconds: time?.seconds() ?? 0,
        milliseconds: time?.milliseconds() ?? 0,
      })
      .local();
    const endMoment =
      startMoment.hours() === 23
        ? moment(startMoment).endOf('day').local()
        : moment(startMoment).add(1, 'hour').local();

    this.options = options;
    this.id = String(option.Id);
    this.start = startMoment.toDate();
    this.end = endMoment.toDate();
    this.allDay = !time;
  }
}
