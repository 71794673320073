import React, { useMemo } from 'react';
import { ErrorMessageProps, ErrorMessage, useFormikContext } from 'formik';
import { flatten } from 'flat';
import { get } from 'lodash';

type Props = ErrorMessageProps;

function ErrorSectionMessageContainer(props: Props) {
  const { name } = props;
  const { touched, errors } = useFormikContext();

  const hasSectionError = useMemo(() => {
    const flattenTouched = flatten(get(touched, name, {}));
    const flattenErrors = flatten(get(errors, name, {}));

    return Object.entries(flattenTouched).some(
      ([key, value]) => value && flattenErrors[key],
    );
  }, [touched, errors, name]);

  return hasSectionError ? <ErrorMessage {...props} /> : null;
}

export default ErrorSectionMessageContainer;
