import { sortBy } from 'lodash';

import { StatusEnum } from '../models/enums/status.enum';
import { OrderFilterOperatorsEnum } from '../models/enums/orderFilterOperators.enum';
import { OrderKeysEnum } from '../models/enums/orderKeys.enum';
import {
  normalizeDateRange,
  getDateRangeByTemplate,
  getIsDateRangeTemplateValid,
} from '../utils/datesUtils';
import { TABLE_ORDERS_BLANK_FILTER_VALUE } from '../utils/constants';

export default class OrdersFilterMapper {
  constructor(private readonly key: OrderKeysEnum) {}

  private getDateTemplateRange(value: string[]) {
    const [template] = value;

    return getIsDateRangeTemplateValid(template)
      ? normalizeDateRange(getDateRangeByTemplate(template))
      : null;
  }

  public getSearch(
    operator: OrderFilterOperatorsEnum,
    value: string | boolean,
  ) {
    return [this.key, operator, value].join('');
  }

  public getList(operator: OrderFilterOperatorsEnum, values: string[]) {
    const getIsBlank = (value: string) =>
      value === TABLE_ORDERS_BLANK_FILTER_VALUE;

    const filter = sortBy(values, getIsBlank)
      .filter(Boolean)
      .flatMap((value, _, list) => {
        if (getIsBlank(value)) {
          return list.length > 1 ? value : ['', value];
        }

        return value;
      })
      .join(OrderFilterOperatorsEnum.Or);

    return this.getSearch(operator, filter);
  }

  public getDateRange(values: string[]) {
    const range = this.getDateTemplateRange(values) ?? values;

    return this.getRange(range);
  }

  public getRange(values: string[]) {
    const [from, to] = values;

    return [
      this.getSearch(OrderFilterOperatorsEnum.GreaterThan, from),
      this.getSearch(OrderFilterOperatorsEnum.LessThan, to),
    ].join(', ');
  }

  public getStatus(value: string) {
    return this.getSearch(
      OrderFilterOperatorsEnum.Equals,
      value !== StatusEnum.active,
    );
  }

  public getTripNumber(values: string[]) {
    const [tripId, tripItemId] = values;

    const filter = [
      tripId,
      ...(tripItemId ? [OrderFilterOperatorsEnum.Or, tripItemId] : []),
    ].join('');

    return this.getSearch(OrderFilterOperatorsEnum.Contains, filter);
  }
}
