import moment from 'moment';

import AvailabilityHoldModel from './availabilityHold.model';

export default class CartCardHolInfo {
  public timeHoldId: string;
  public timedHoldDuration: number;
  public holdStartDate: string;
  public holdEndDate: string;

  constructor(availabilityHold: AvailabilityHoldModel) {
    this.timeHoldId = availabilityHold.TimedHoldId;
    this.timedHoldDuration = availabilityHold.TimedHoldDuration;
    this.holdStartDate = moment().format();
    this.holdEndDate = moment()
      .add(availabilityHold.TimedHoldDuration, 'seconds')
      .format();
  }
}
