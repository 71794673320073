import React, { useCallback, useEffect, useState } from 'react';
import { withStore } from 'hocs';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import intl from 'react-intl-universal';

import { InventoryStore } from 'stores/inventory.store';
import { UserStore } from 'stores/user.store';
import { ProductCardModel } from 'models/inventory/productCard.model';
import { getProductTypeLabel } from 'utils/productUtils';
import { ProductTypeEnum } from 'models/enums/productType.enum';
import { getFilterListData } from 'utils/tableUtils';
import { checkUserGroup } from 'utils/userRolesUtils';
import { ROLES } from 'utils/constants';

type Props = {
  inventoryStore?: InventoryStore;
  userStore?: UserStore;
  onProductSelect: (product: ProductCardModel) => void;
};

function InventoryTable(props: Props) {
  const { inventoryStore, userStore, onProductSelect } = props;

  const [isLoading, setIsLoading] = useState(false);

  const isOperatorColumnVisible = checkUserGroup(userStore.userProperties, [
    ROLES.STAFF.value,
    ROLES.STAFF_ADMIN.value,
  ]);

  const loadProducts = useCallback(async () => {
    setIsLoading(true);

    await inventoryStore.getProducts();

    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const getProductTypeFilters = () =>
    [ProductTypeEnum.Service, ProductTypeEnum.Package].map((value) => ({
      text: getProductTypeLabel(value),
      value,
    }));

  const getColumns = () =>
    (
      [
        {
          key: 'product-id',
          title: intl.get('inventory.products.productId'),
          dataIndex: 'Id',
          filterSearch: true,
          filters: getFilterListData(
            inventoryStore.products,
            (product) => product.Id,
          ),
          onFilter: (value, record) => record.Id === value,
        },
        {
          key: 'product-name',
          title: intl.get('inventory.products.productName'),
          dataIndex: 'Name',
          filterSearch: true,
          filters: getFilterListData(
            inventoryStore.products,
            (product) => product.Name,
          ),
          onFilter: (value, record) => record.Name === value,
        },
        isOperatorColumnVisible && {
          key: 'product-operator',
          title: intl.get('inventory.products.productOperator'),
          dataIndex: 'OperatorId',
          filterSearch: true,
          filters: getFilterListData(
            inventoryStore.products,
            (product) => product.OperatorId,
          ),
          onFilter: (value, record) => record.OperatorId === value,
        },
        {
          key: 'product-type',
          title: intl.get('inventory.products.productType'),
          dataIndex: 'ProductType',
          render: (value: ProductTypeEnum) => getProductTypeLabel(value),
          filters: getProductTypeFilters(),
          onFilter: (value, record) => record.ProductType === value,
        },
      ] as ColumnsType<ProductCardModel>
    ).filter(Boolean);

  return (
    <Table
      loading={isLoading}
      columns={getColumns()}
      className="inventory-table"
      dataSource={inventoryStore.products}
      pagination={{
        total: inventoryStore.total,
        pageSize: inventoryStore.pageSize,
        current: inventoryStore.currentPagination,
        onChange: inventoryStore.setPagination,
      }}
      onRow={(record) => ({
        onClick: () => onProductSelect(record),
      })}
    />
  );
}

export default withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
  userStore: rootStore.userStore,
}))(InventoryTable);
