import React from 'react';
import {
  Location,
  NavigateFunction,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { ReactComponent } from 'models/reactComponent.type';

export interface RouterProps {
  navigate?: NavigateFunction;
  readonly params?: Readonly<Params<string>>;
  location?: Location;
}

export const withRouter: <T extends ReactComponent>(Component: T) => T = (
  Component,
) =>
  ((props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component
        location={location}
        navigate={navigate}
        params={params}
        {...props}
      />
    );
  }) as typeof Component;
