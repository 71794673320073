import React, { Component } from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';
import Dotdotdot from 'react-dotdotdot';
import intl from 'react-intl-universal';

import { Popover, Button, Spin } from 'antd';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { UserOutlined } from '@ant-design/icons';
import { InventoryStore } from '../../../stores/inventory.store';
import TopMenuCalendarToolbar from './TopMenuCalendarToolbar';
import ProductDetails from '../Inventory/components/ProductDetailsPopup/ProductDetails';
import { withStore } from '../../../hocs';

export const ALL_PRODUCTS = 'all';

// https://fullcalendar.io/docs

@withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
}))
export default class TopMenuCalendar extends Component<{
  inventoryStore?: InventoryStore;
}> {
  fullCalendar = null;

  state = {
    title: '',
    isProductPopupOpened: false,
    selectedId: ALL_PRODUCTS,
    events: [],
  };

  componentDidMount() {
    const { title } = this.fullCalendar.calendar.view;

    this.setState({ title });
  }

  componentWillUnmount() {
    const { inventoryStore } = this.props;

    if (inventoryStore.preventInventoriesRequest) {
      inventoryStore.preventInventoriesRequest('cancel');
    }

    inventoryStore.preventProductsRequest();

    inventoryStore.clearStore();
  }

  getEvents = (start, end, productId) => {
    const { inventoryStore } = this.props;

    inventoryStore.getInventories(start, end, productId).then(() => {
      const {
        inventoryStore: { inventories },
      } = this.props;
      const { selectedId } = this.state;

      const events = inventories.map((event) => {
        const mappedEvent: any = {
          id: `${event.Id}`,
          start: event.Date,
          end: event.Date,
          time: event.Time,
          productId: event.ProductId,
          productType: event.ProductType,
          total: event.OriginalQuantity - event.AvailableQuantity,
          left: event.AvailableQuantity,
        };

        if (selectedId === ALL_PRODUCTS) {
          mappedEvent.productName = event.ProductName;
        }

        return mappedEvent;
      });

      this.setState({ events });
    });
  };

  handleChangeProduct = (value) => {
    const { activeStart, activeEnd } = this.fullCalendar.calendar.view;

    this.setState({ selectedId: value, events: [] }, () => {
      const { selectedId } = this.state;

      this.getEvents(
        activeStart,
        moment(activeEnd).subtract(1, 'd'),
        selectedId === ALL_PRODUCTS ? null : selectedId,
      );
    });
  };

  handleGoToProductClick = (productId, productType) => {
    const { inventoryStore } = this.props;

    inventoryStore.getProductDetails(productId, productType);

    this.setState({ isProductPopupOpened: true });
  };

  handleProductPopupClose = () => {
    const { inventoryStore } = this.props;
    inventoryStore.clearProduct();

    this.setState({ isProductPopupOpened: false });
  };

  datesRender = (info: any) => {
    const { selectedId } = this.state;
    const { title, activeStart, activeEnd } = info.view;

    this.setState({ title, events: [] }, () => {
      this.getEvents(
        activeStart,
        moment(activeEnd).subtract(1, 'd'),
        selectedId === ALL_PRODUCTS ? null : selectedId,
      );
    });
  };

  eventRender = (info: any) => {
    const {
      event: {
        id,
        extendedProps: {
          productName,
          total,
          left,
          productId,
          productType,
          time,
        },
      },
    } = info;
    const { selectedId, events } = this.state;

    const popoverProductTitle = (
      <div className="min-max-w-200">{productName}</div>
    );
    const popoverAvailabilityTitle = (
      <div className="min-max-w-200">
        {time ? moment(time).format('h A') : ''}
      </div>
    );

    const popoverContent = (
      <div className="event-popover-content">
        <div className="d-f jc-sb ai-c m-b-10">
          <div>
            <UserOutlined className="m-h-5 f-s-17" />
            {total}
          </div>
          {left
            ? `${left} ${intl.get('topMenuCalendar.left')}`
            : intl.get('topMenuCalendar.soldOut')}
        </div>
        <div className="actions p-t-5">
          <Button
            type="link"
            onClick={() => this.handleGoToProductClick(productId, productType)}
          >
            {intl.get('topMenuCalendar.goToProduct')}
          </Button>
        </div>
      </div>
    );

    const currEventIndex = events.findIndex((evnt) => evnt.id === id);
    const prevEvent = events[currEventIndex - 1];
    const currEvent = events[currEventIndex];
    let isTimeShow = true;

    if (
      prevEvent &&
      moment(currEvent.start).isSame(moment(prevEvent.start)) &&
      ((currEvent.time === null && prevEvent.time === null) ||
        moment(currEvent.time).get('h') === moment(prevEvent.time).get('h'))
    ) {
      isTimeShow = false;
    }

    const eventProductComponent = (
      <div className="d-f f-d-column">
        {isTimeShow && time && (
          <div className="time-block">{moment(time).format('h A')}</div>
        )}
        <Popover
          content={popoverContent}
          title={popoverProductTitle}
          placement="right"
        >
          <div
            className="content-block"
            style={{ height: isTimeShow && time ? '70px' : '92px' }}
          >
            <div className="p-h-5 p-t-5 min-max-h-40">
              <Dotdotdot clamp={2}>{productName}</Dotdotdot>
            </div>
            <div className="d-f jc-sb ai-c p-h-5 p-b-5">
              <div>
                {total}
                <UserOutlined className="m-l-5 f-s-17" />
              </div>
              <div>
                {left
                  ? `${left} ${intl.get('topMenuCalendar.left')}`
                  : intl.get('topMenuCalendar.soldOut')}
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );

    const eventAvailabilityComponent = (
      <div className="d-f f-d-column">
        <Popover
          content={popoverContent}
          title={popoverAvailabilityTitle}
          placement="right"
        >
          <div className="content-block">
            <div className="p-5 m-b-10 min-max-h-20">
              {time ? moment(time).format('h A') : ''}
            </div>
            <div className="d-f jc-sb ai-c p-5">
              <div>
                {total}
                <UserOutlined className="m-l-5 f-s-17" />
              </div>
              <div>
                {left
                  ? `${left} ${intl.get('topMenuCalendar.left')}`
                  : intl.get('topMenuCalendar.soldOut')}
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );

    ReactDOM.render(
      selectedId === ALL_PRODUCTS
        ? eventProductComponent
        : eventAvailabilityComponent,
      info.el,
    );

    return info.el;
  };

  renderLoading = () => {
    const {
      inventoryStore: { isInventoriesLoading },
    } = this.props;

    return (
      <div
        className="inventories-update"
        style={{ visibility: isInventoriesLoading ? 'visible' : 'hidden' }}
      >
        <span>
          <Spin className="spin" size="small" />
          {intl.get('topMenuCalendar.calendarLoading')}
        </span>
      </div>
    );
  };

  render() {
    const { inventoryStore } = this.props;
    const { title, selectedId, events, isProductPopupOpened } = this.state;

    return (
      <div className="top-menu-calendar">
        {this.renderLoading()}
        <TopMenuCalendarToolbar
          title={title}
          calendar={this.fullCalendar}
          selectedId={selectedId}
          onChangeProduct={this.handleChangeProduct}
          inventoryStore={inventoryStore}
        />
        <FullCalendar
          height="auto"
          contentHeight="auto"
          ref={(ref) => (this.fullCalendar = ref)}
          defaultView="dayGridWeek"
          plugins={[dayGridPlugin, interactionPlugin]}
          header={false}
          columnHeaderFormat={{ weekday: 'short', day: 'numeric' }}
          events={events}
          datesRender={this.datesRender}
          eventRender={this.eventRender}
        />
        <ProductDetails
          isProductPopupOpened={isProductPopupOpened}
          handleProductPopupClose={this.handleProductPopupClose}
        />
      </div>
    );
  }
}
