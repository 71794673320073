import React, { Component } from 'react';
import { reaction } from 'mobx';
import intl from 'react-intl-universal';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import IMask from 'imask';

import { CheckoutStore } from '../../../../stores/checkout.store';
import { InputContainer } from '../../../FormContainers';

import {
  creditCardValidator,
  expirationDateFormatValidator,
  expirationDateExpiredValidator,
} from '../../../../utils/validation/validators';
import { CreditCardModel } from '../../../../models/creditCard.model';
import { withStore } from '../../../../hocs';

const newCardSectionName = 'newCardSection';

@withStore(({ rootStore }) => ({
  checkoutStore: rootStore.checkoutStore,
}))
export default class NewCreditCard extends Component<{
  checkoutStore?: CheckoutStore;
}> {
  isInitialized = false;
  validate = null;
  disposer = null;

  componentDidMount() {
    const { checkoutStore } = this.props;

    this.disposer = reaction(
      () => checkoutStore.shouldValidate,
      (shouldValidate) =>
        this.isInitialized && shouldValidate > 0 && this.validate(),
    );
  }

  componentWillUnmount() {
    const { checkoutStore } = this.props;

    checkoutStore.unregisterFieldInValidStore(newCardSectionName);
    this.disposer();
  }

  initializeValidationFunction = (validateForm, setErrors, setTouched) => {
    this.isInitialized = true;
    this.validate = () => {
      validateForm().then((errors) => {
        setErrors(errors);
        const result = {};
        Object.keys(errors).forEach((k) => (result[k] = true));
        setTouched(result);
      });
    };
  };

  handleNewCardInfoChange = (values: any, isValid: boolean) => {
    const { checkoutStore } = this.props;
    const { saveInProfile, ...creditCardInfo } = values;

    checkoutStore.updateNewCreditCardInfo(
      new CreditCardModel(creditCardInfo),
      saveInProfile,
    );
    checkoutStore.updateValid(newCardSectionName, isValid);
  };

  render() {
    return (
      <Formik
        initialValues={{
          cardNumber: '',
          expirationDate: '',
          cardCode: '',
          saveInProfile: false,
        }}
        validationSchema={Yup.object().shape({
          cardNumber: Yup.string()
            .required(intl.get('required'))
            .test(creditCardValidator(intl.get('invalidCreditCardValidator'))),
          expirationDate: Yup.string()
            .required(intl.get('required'))
            .test(expirationDateFormatValidator(intl.get('invalidFormat')))
            .test(
              expirationDateExpiredValidator(intl.get('creditCardExpired')),
            ),
          cardCode: Yup.string()
            .required(intl.get('required'))
            .min(3, intl.get('invalidFormat')),
          // saveInProfile: Yup.boolean()
        })}
        validateOnChange
        onSubmit={() => undefined}
      >
        {({ values, validateForm, setErrors, setTouched, isValid }) => (
          <Form className="new-card-form">
            <>
              {!this.isInitialized &&
                this.initializeValidationFunction(
                  validateForm,
                  setErrors,
                  setTouched,
                )}
              {this.handleNewCardInfoChange(values, isValid)}
            </>

            <div className="credit-card-fields">
              <Field
                name="cardNumber"
                component={InputContainer}
                label={intl.get('labels.cardNumber')}
                mask="0000-0000-0000-0000-000"
                className="card-number"
              />
              <Field
                name="expirationDate"
                component={InputContainer}
                label={intl.get('labels.expirationDate')}
                className="expiration-date"
                mask="MM/YY"
                maskOptions={{
                  blocks: {
                    YY: {
                      mask: '00',
                    },
                    MM: {
                      mask: IMask.MaskedRange,
                      from: 1,
                      to: 12,
                    },
                  },
                }}
              />
              <Field
                name="cardCode"
                component={InputContainer}
                label={intl.get('labels.cvv')}
                mask="0000"
                className="card-code"
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}
