import React, { Component } from 'react';
import intl from 'react-intl-universal';

import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { InventoryStore } from '../../../stores/inventory.store';

import TopMenuCalendarProductDropdown from './TopMenuCalendarProductDropdown';

export default class TopMenuCalendarToolbar extends Component<{
  calendar: any;
  selectedId: string;
  title: string;
  onChangeProduct: Function;
  inventoryStore?: InventoryStore;
}> {
  goToBack = () => {
    const { calendar } = this.props;
    const API = calendar.getApi();

    API.prev();
  };

  goToNext = () => {
    const { calendar } = this.props;
    const API = calendar.getApi();

    API.next();
  };

  goToCurrent = () => {
    const { calendar } = this.props;
    const API = calendar.getApi();

    API.today();
  };

  render() {
    const { title, selectedId, onChangeProduct, inventoryStore } = this.props;

    return (
      <div className="d-f ai-c jc-c p-v-10">
        <div className="flx-1 d-f ai-c jc-fs">
          <TopMenuCalendarProductDropdown
            onChangeProduct={onChangeProduct}
            inventoryStore={inventoryStore}
            selectedId={selectedId}
          />
        </div>
        <div className="flx-1 d-f ai-c jc-c">
          <Button onClick={this.goToBack} type="link">
            <LeftOutlined />
          </Button>
          <label>
            <span className="calendar-title">{title}</span>
          </label>
          <Button onClick={this.goToNext} type="link">
            <RightOutlined />
          </Button>
        </div>
        <div className="flx-1 d-f ai-c jc-fe">
          <Button onClick={this.goToCurrent}>
            {intl.get('productPage.today')}
          </Button>
        </div>
      </div>
    );
  }
}
