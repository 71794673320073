import React, { Component, Fragment } from 'react';
import { reaction } from 'mobx';
import intl from 'react-intl-universal';
import { Modal, Empty, Spin, Button } from 'antd';
import { Location } from 'react-router-dom';

import { router } from 'router';
import {
  MarketplaceStore,
  countPerMarketplacePage,
} from '../../../stores/marketplace.store';
import { CommonStore } from '../../../stores/common.store';

import TourCard from './components/TourCard';
import { TourCardModel } from '../../../models/marketplace/tourCard.model';
import TourPagination from './components/TourPagination';
import TourDetails from '../TourDetails';
import TourSearchModule from './components/TourSearchModule';
import TourSearchActionsModule from './components/TourSearchActionsModule';
import { helpCategories } from '../../SharedComponents/HelpAndSupport';
import { UserStore } from '../../../stores/user.store';
import { availableLanguages } from '../../../models/enums/language.enums';
import { textToDisplayInResults } from './utils/searchUtils';
import { RouterProps, withRouter, withStore } from '../../../hocs';

type Props = {
  marketplaceStore?: MarketplaceStore;
  commonStore?: CommonStore;
  userStore?: UserStore;
} & RouterProps;

@withRouter
@withStore(({ rootStore }) => ({
  marketplaceStore: rootStore.marketplaceStore,
  commonStore: rootStore.commonStore,
  userStore: rootStore.userStore,
}))
export default class ToursAndActivities extends Component<Props> {
  searchReaction = null;

  state = {
    isPopupVisible: false,
    popupTourId: null,
    productLanguageCode: null,
  };

  componentDidMount() {
    const { location, navigate, marketplaceStore } = this.props;

    this.handleHistoryChange(location);

    router.subscribe((state) => {
      if (state.location.pathname === '/marketplace') {
        this.handleHistoryChange(state.location);
      }
    });

    this.searchReaction = reaction(
      () => [
        marketplaceStore.minPrice,
        marketplaceStore.maxPrice,
        marketplaceStore.sortType,
      ],
      () => {
        const params = new URLSearchParams(location.search);
        // we should set page = 1
        params.set('page', '1');
        navigate({ search: params.toString() });
        // and we should update search
        marketplaceStore.shouldUpdateSearch();
      },
    );
  }

  componentWillUnmount() {
    this.searchReaction();
  }

  handleHistoryChange = (location: Location) => {
    const {
      navigate,
      marketplaceStore,
      marketplaceStore: { currentPagination },
    } = this.props;
    const params = new URLSearchParams(location.search);

    if (params.has('page') && typeof +params.get('page') === 'number') {
      if (currentPagination !== +params.get('page')) {
        marketplaceStore.setCurrentPagination(+params.get('page'));
        marketplaceStore.shouldUpdateSearch();
      }
    } else {
      params.set('page', `${currentPagination}`);
      navigate({ search: params.toString() });
    }

    if (!params.has('productId') && this.state.isPopupVisible) {
      this.setState({
        isPopupVisible: false,
      });
    }

    if (params.has('productId')) {
      const productLanguageCode = params.get('productLanguageCode');

      this.setState({
        isPopupVisible: true,
        popupTourId: params.get('productId'),
        productLanguageCode,
      });
    }
  };

  handlePaginationChange = (page) => {
    const {
      location,
      navigate,
      marketplaceStore: { currentPagination },
    } = this.props;

    if (page !== currentPagination) {
      const params = new URLSearchParams(location.search);

      window.scrollTo(0, 0);
      params.set('page', page);
      navigate({ search: params.toString() });
    }
  };

  handleTourClick = (tourId: string, languageCode?: string) => {
    const {
      location,
      navigate,
      userStore: { language: currentLanguage },
    } = this.props;
    const params = new URLSearchParams(location.search);

    // in case current version is ENG that language code should be English
    if (currentLanguage !== availableLanguages.ENG && languageCode) {
      params.set('productLanguageCode', languageCode);
    }

    params.set('productId', tourId);
    navigate({ search: params.toString() });
  };

  handlePopupClose = () => {
    const { location, navigate } = this.props;
    const params = new URLSearchParams(location.search);

    params.delete('productId');
    params.delete('productLanguageCode');
    navigate({ search: params.toString() });
  };

  handleSubmitProductRequest = () => {
    const { location, navigate } = this.props;
    const params = new URLSearchParams(location.search);

    params.set('isHelpAndSupportOpened', 'true');
    params.set('helpCategory', helpCategories.productInquiryOrRequest);
    navigate({ search: params.toString() });
  };

  handleNewSearch = () => {
    const { location, navigate } = this.props;

    const params = new URLSearchParams(location.search);

    params.set('page', `${1}`);
    navigate({ search: params.toString() });
  };

  renderTourCards = (cards: TourCardModel[]) => {
    const {
      commonStore: { settings },
      marketplaceStore: {
        isSearched,
        isLocation,
        selectedLocation,
        searchText,
      },
      userStore: { language },
    } = this.props;
    const header = isSearched
      ? `${intl.get('marketplacePage.results')}: "${
          isLocation
            ? textToDisplayInResults(language, selectedLocation)
            : searchText
        }"`
      : intl.get('marketplacePage.recommended');

    return (
      <div className="tours-and-activities-container">
        <h4 className="group-header">{header}</h4>
        <div className="tour-cards-container">
          {cards.map((card) => (
            <div
              key={card.Id}
              className="tour-card-container"
              onClick={() =>
                this.handleTourClick(card.Product_Code, card.Language_Code)
              }
            >
              <TourCard
                card={card}
                imagesRoot={settings ? settings.ImagesRoot : null}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  renderPopup = () => {
    const { isPopupVisible, popupTourId, productLanguageCode } = this.state;

    return (
      <Modal
        destroyOnClose
        visible={isPopupVisible}
        footer={null}
        onCancel={this.handlePopupClose}
        wrapClassName="modal-tourschain product-details-modal"
        cancelButtonProps={{ size: 'large' }}
      >
        <TourDetails
          id={popupTourId}
          languageCode={productLanguageCode}
          parentClassName="product-details-modal"
          closeDetails={this.handlePopupClose}
        />
      </Modal>
    );
  };

  renderLoaderOrNoResult = (cardsLength: number, isLoading: boolean) => {
    let content;

    if (isLoading) {
      content = (
        <div className="marketplace-loader">
          <Spin size="large" className="spin" />
        </div>
      );
    }

    if (!isLoading && cardsLength === 0) {
      content = (
        <div className="marketplace-no-results">
          <Empty description={intl.get('marketplacePage.noResult')} />
          <div className="no-result-submit-product-request">
            <span>{intl.get('marketplacePage.dontFindProduct')}</span>
            <Button onClick={this.handleSubmitProductRequest}>
              {intl.get('marketplacePage.submitProductRequest')}
            </Button>
          </div>
        </div>
      );
    }

    return content || null;
  };

  render() {
    const {
      marketplaceStore: {
        productsForOnePage,
        isLoading,
        totalOfExistedCards,
        currentPagination,
      },
    } = this.props;

    return (
      <div className="tours-and-activities">
        <TourSearchModule
          onSuggestProductClick={this.handleTourClick}
          handleNewSearch={this.handleNewSearch}
        />

        <TourSearchActionsModule className="m-t-30" />

        {!isLoading && productsForOnePage.length > 0 && (
          <Fragment>
            {this.renderTourCards(productsForOnePage)}
            <div className="pagination-container">
              <TourPagination
                current={currentPagination}
                onChange={this.handlePaginationChange}
                pageSize={countPerMarketplacePage}
                total={totalOfExistedCards}
              />
            </div>
          </Fragment>
        )}

        {this.renderLoaderOrNoResult(productsForOnePage.length, isLoading)}
        {this.renderPopup()}
      </div>
    );
  }
}
