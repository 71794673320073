import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { FieldProps, getIn } from 'formik';
import EmailEditor, { EmailEditorProps, EditorRef } from 'react-email-editor';
import { isNil } from 'lodash';
import Handlebars from 'handlebars/dist/cjs/handlebars';

type OwnProps = {
  className?: string;
  label?: string;
  previewModel?: object;
};

type Props = OwnProps & EmailEditorProps & FieldProps;

export default class EmailEditorContainer extends PureComponent<Props> {
  editorRef = React.createRef<EditorRef>();
  isInitialized = false;

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.previewModel !== prevProps.previewModel) {
      this.handlePreview();
    }
  }

  handleLoadDesign = () => {
    const { field } = this.props;

    if (!isNil(field.value.json)) {
      this.editorRef.current.editor?.loadDesign(field.value.json);
    }
  };

  handleEditorUpdate = () => {
    this.editorRef.current.editor?.addEventListener('design:updated', () => {
      this.editorRef.current.editor.exportHtml((data) => {
        const { field, form } = this.props;

        form.setFieldValue(`${field.name}.html`, data.html, true);

        form.setFieldValue(`${field.name}.json`, data.design, false);
      });
    });
  };

  handleCompileTemplate = (template: string, model: object) => {
    try {
      return Handlebars.compile(template)(model);
    } catch (error) {
      return `<pre style="color: red;"><code>${error.message}</code></pre>`;
    }
  };

  handlePreview = () => {
    const { previewModel } = this.props;

    if (!previewModel) return;

    this.editorRef.current.editor?.registerCallback(
      'previewHtml',
      (params, done) => {
        done({
          html: this.handleCompileTemplate(params.html, previewModel),
        });
      },
    );
  };

  onEditorLoad = () => {
    if (this.isInitialized) return;

    setTimeout(() => {
      this.handleLoadDesign();
      this.handleEditorUpdate();
      this.handlePreview();

      this.isInitialized = true;
    }, 0);
  };

  render() {
    const {
      field,
      form: { errors, touched },
      label,
      className,
      ...rest
    } = this.props;
    const fieldError: string = getIn(errors, field.name);
    const isTouched: boolean = getIn(touched, field.name);
    const hasError: boolean = fieldError && isTouched;

    return (
      <div
        className={classnames('field', { 'has-error': fieldError }, className)}
      >
        {label && <h4 className="field-label">{label}</h4>}

        <EmailEditor
          {...rest}
          ref={this.editorRef}
          onReady={this.onEditorLoad}
        />

        {hasError && <div className="field-error visible">{fieldError}</div>}
      </div>
    );
  }
}
