import React from 'react';
import intl from 'react-intl-universal';
import { Button, Checkbox, TimePicker } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { isEmpty, isNil, sumBy } from 'lodash';

import { InventoryBulkViewTableModel } from '../models/inventory/inventoryBulkViewTable.model';
import {
  formatDateColumnSubTitle,
  formatDateColumnTitle,
  formatTimeCell,
} from './bulkInventoryTableUtils';
import { dateSort, stringSort } from './formatter';
import { BulkInventoryStore } from '../stores/bulkInventory.store';
import InventoryBulkViewAvailabilityCell from '../components/SharedComponents/InventoryBulkViewAvailabilityCell';
import InventoryBulkViewQuantityFilter from '../components/SharedComponents/InventoryBulkViewQuantityFilter';
import { InventoryBulkViewColumn } from '../models/inventory/inventoryBulkViewColumn.model';
import { InventoryModel } from '../models/inventory/inventory.model';
import { isSameDatesTimes } from './datesUtils';
import { InventoryBulkViewByDateModel } from '../models/inventory/inventoryBulkViewByDate.model';
import { InventoryComponentModel } from '../models/inventory/inventoryComponent.model';
import { getFilterListData } from './tableUtils';

type AvailabilityFilters = [boolean, [number, number], [number, number]];

const getFilterActions = ({ selectedKeys, clearFilters, confirm }) => (
  <div className="ant-table-filter-dropdown-btns d-f jc-sb">
    <Button
      type="link"
      size="small"
      disabled={isEmpty(selectedKeys)}
      onClick={() => clearFilters()}
    >
      {intl.get('buttons.reset')}
    </Button>

    <Button type="primary" size="small" onClick={() => confirm()}>
      {intl.get('buttons.ok')}
    </Button>
  </div>
);

const getTimeFilterProps = (bulkInventoryStore: BulkInventoryStore) => ({
  filterDropdown: (props) => (
    <>
      <div className="p-v-9 p-h-12">
        <TimePicker.RangePicker
          use12Hours
          minuteStep={5}
          format="HH:mm"
          value={props.selectedKeys[0]}
          onChange={(fieldValue) => props.setSelectedKeys([fieldValue])}
        />
      </div>

      {getFilterActions(props)}
    </>
  ),
  filteredValue: bulkInventoryStore.filters.time,
  onFilter: (value, record: InventoryBulkViewTableModel) => {
    const [from, to] = value;

    const timeMoment = moment(record.time);

    return (
      timeMoment.isValid &&
      (isNil(from) || timeMoment.isSameOrAfter(from)) &&
      (isNil(to) || timeMoment.isSameOrBefore(to))
    );
  },
});

const getAvailabilityFilterAndSortProps = (
  bulkInventoryStore: BulkInventoryStore,
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => {
    const {
      minAvailableQuantity,
      maxAvailableQuantity,
      minBookedQuantity,
      maxBookedQuantity,
    } = bulkInventoryStore;

    const [
      selectedStopSell,
      selectedAvailableRange,
      selectedBookedRange,
    ]: AvailabilityFilters = selectedKeys[0] || [];

    const [selectedAvailableFrom, selectedAvailableTo] =
      selectedAvailableRange || [];

    const [selectedBookedFrom, selectedBookedTo] = selectedBookedRange || [];

    const availableRangeValue: [number, number] = [
      selectedAvailableFrom ?? minAvailableQuantity,
      selectedAvailableTo ?? maxAvailableQuantity,
    ];

    const bookedRangeValue: [number, number] = [
      selectedBookedFrom ?? minBookedQuantity,
      selectedBookedTo ?? maxBookedQuantity,
    ];

    const [availableFrom, availableTo] = availableRangeValue;

    const [bookedFrom, bookedTo] = bookedRangeValue;

    return (
      <>
        <div className="d-f f-d-column ai-fs p-v-9 p-h-12">
          <InventoryBulkViewQuantityFilter
            label={intl.get('inventory.bulkView.available')}
            min={minAvailableQuantity}
            max={maxAvailableQuantity}
            value={availableRangeValue}
            onFromChange={(value) =>
              setSelectedKeys([
                [selectedStopSell, [value, availableTo], bookedRangeValue],
              ])
            }
            onSliderChange={(value) =>
              setSelectedKeys([[selectedStopSell, value, bookedRangeValue]])
            }
            onToChange={(value) =>
              setSelectedKeys([
                [selectedStopSell, [availableFrom, value], bookedRangeValue],
              ])
            }
          />

          <InventoryBulkViewQuantityFilter
            label={intl.get('inventory.bulkView.booked')}
            min={minBookedQuantity}
            max={maxBookedQuantity}
            value={bookedRangeValue}
            onFromChange={(value) =>
              setSelectedKeys([
                [selectedStopSell, availableRangeValue, [value, bookedTo]],
              ])
            }
            onSliderChange={(value) =>
              setSelectedKeys([[selectedStopSell, availableRangeValue, value]])
            }
            onToChange={(value) =>
              setSelectedKeys([
                [selectedStopSell, availableRangeValue, [bookedFrom, value]],
              ])
            }
          />

          <Checkbox
            checked={selectedStopSell}
            onChange={(ev) =>
              setSelectedKeys([
                [ev.target.checked, availableRangeValue, bookedRangeValue],
              ])
            }
          >
            {intl.get('inventory.bulkView.stopSell')}
          </Checkbox>
        </div>

        {getFilterActions({ selectedKeys, clearFilters, confirm })}
      </>
    );
  },
  filteredValue: bulkInventoryStore.filters.avaliability,
  onFilter: (
    value: AvailabilityFilters,
    record: InventoryBulkViewTableModel,
  ) => {
    const [stopSell, availableRange, bookedRange] = value || [];

    const [availableFrom, availableTo] = availableRange || [];

    const [bookedFrom, bookedTo] = bookedRange || [];

    return Object.values(record.inventoryByDate).some((inventory) => {
      const isMatchAvailableRange =
        inventory?.AvailableQuantity >= availableFrom &&
        inventory?.AvailableQuantity <= availableTo;

      const isMatchBookedRange =
        inventory?.BookedCount >= bookedFrom &&
        inventory?.BookedCount <= bookedTo;

      const isMatchStopSell = inventory?.StopSell === stopSell;

      return (
        (isNil(availableFrom) || isNil(availableTo) || isMatchAvailableRange) &&
        (isNil(bookedFrom) || isNil(bookedTo) || isMatchBookedRange) &&
        (isNil(stopSell) || isMatchStopSell)
      );
    });
  },
  sortOrder: bulkInventoryStore.sorts.availability,
  sorter: (a: InventoryBulkViewTableModel, b: InventoryBulkViewTableModel) => {
    const inventoriesA = Object.values(a.inventoryByDate);
    const inventoriesB = Object.values(b.inventoryByDate);

    return (
      sumBy(inventoriesA, (inventory) => inventory?.AvailableQuantity) -
      sumBy(inventoriesB, (inventory) => inventory?.AvailableQuantity)
    );
  },
});

export const getColumns = (
  bulkInventoryStore: BulkInventoryStore,
): InventoryBulkViewColumn[] => {
  const {
    datesRangeDates,
    inventoryTableData,
    sorts,
    filters,
    saveEditedInventoryCell,
  } = bulkInventoryStore;

  return [
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      filterSearch: true,
      groupable: true,
      groupName: 'Product',
      filters: getFilterListData(
        inventoryTableData,
        (inventory) => inventory.productName,
      ),
      filteredValue: filters.productName,
      sortOrder: sorts.productName,
      sorter: stringSort('productName'),
      onFilter: (value, record) => record.productName === value,
    },
    {
      title: 'Option',
      dataIndex: 'categoryName',
      key: 'categoryName',
      filterSearch: true,
      groupable: true,
      groupName: 'Option',
      filters: getFilterListData(
        inventoryTableData,
        (inventory) => inventory.categoryName,
      ),
      filteredValue: filters.categoryName,
      sortOrder: sorts.categoryName,
      sorter: stringSort('categoryName'),
      onFilter: (value, record) => record.categoryName === value,
    },
    {
      title: () => <ClockCircleOutlined />,
      dataIndex: 'time',
      key: 'time',
      className: 'text-center',
      groupable: true,
      groupName: 'Time',
      render: (value) => formatTimeCell(value),
      sortOrder: sorts.time,
      sorter: dateSort('time'),
      ...getTimeFilterProps(bulkInventoryStore),
    },
    {
      key: 'availability',
      title: 'Available / Booked',
      groupable: false,
      // @ts-ignore
      children: datesRangeDates.map((date) => ({
        title: () => (
          <>
            <strong className="d-b">{formatDateColumnTitle(date)}</strong>
            <span className="d-b">{formatDateColumnSubTitle(date)}</span>
          </>
        ),
        className: 'text-center',
        key: date.format(),
        groupable: false,
        aggregate: (leafValues: InventoryModel[]) => {
          const availableQuantitySum = sumBy(leafValues, 'AvailableQuantity');
          const bookedCountSum = sumBy(leafValues, 'BookedCount');

          if (isNil(availableQuantitySum) && isNil(bookedCountSum)) return null;

          return {
            AvailableQuantity: availableQuantitySum,
            BookedCount: bookedCountSum,
          };
        },
        dataIndex: ['inventoryByDate', createInventoryDateColumnKey(date)],
        render: (
          inventory: InventoryModel,
          record: InventoryBulkViewTableModel,
        ) => (
          <InventoryBulkViewAvailabilityCell
            inventory={inventory}
            isEditable={record.isEditable}
            onSave={saveEditedInventoryCell}
          />
        ),
      })),
      ...getAvailabilityFilterAndSortProps(bulkInventoryStore),
    },
  ];
};

export const createInventoryDateColumnKey = (date: Moment) =>
  date.format('YYYY-MM-DD');

export const formatTableCell = (
  availableQuantity: number,
  bookedQuantity: number,
) =>
  isNil(availableQuantity) && isNil(bookedQuantity)
    ? undefined
    : `${availableQuantity ?? 0} / ${bookedQuantity ?? 0}`;

export const mapInventoryToViewTableData = (
  inventories: (InventoryModel | InventoryComponentModel)[],
) =>
  inventories.reduce((acc, inventory) => {
    const existingResult = acc.find(({ productId, categoryId, time }) => {
      const isSameId = productId === inventory.ProductId;

      const isSameCategory = categoryId === inventory.CategoryId;

      const isSameTime =
        !time || !inventory.Time || isSameDatesTimes([time, inventory.Time]);

      return isSameId && isSameCategory && isSameTime;
    });

    if (existingResult) {
      Object.assign(
        existingResult.inventoryByDate,
        new InventoryBulkViewByDateModel(inventory),
      );

      return acc;
    }

    return [...acc, new InventoryBulkViewTableModel(inventory)];
  }, [] as InventoryBulkViewTableModel[]);
