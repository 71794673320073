import React, { Component } from 'react';
import pluralize from 'pluralize';
import intl from 'react-intl-universal';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';

import { QuestionCircleFilled } from '@ant-design/icons';
import { AvailableCategoryModel } from '../../../models/tour-details/tourAvailableCategory.model';
import { GuestTypesEnum } from '../../../utils/constants';
import { AvailabilityLevelEnum } from '../../../models/enums/availabilityLevel.enum';
import { moneyFormat } from '../../../utils/formatter';
import { isMoreLessButtonNeeded } from './utils/availabilityUtils';

export default class Availability extends Component<{
  availability: AvailableCategoryModel;
  addToCard: any;
}> {
  descriptionSpanRef = null;
  descriptionWrapperRef = null;

  state = {
    isDescriptionOpened: false,
    isMoreLessNeeded: true,
  };

  handleDescriptionOpen = () => {
    this.setState((state: any) => ({
      isDescriptionOpened: !state.isDescriptionOpened,
    }));
  };

  initializeDescriptionSpanRef = (elem) => {
    if (this.descriptionSpanRef) {
      return;
    }

    const isMoreLessNeeded = isMoreLessButtonNeeded(
      elem,
      this.descriptionWrapperRef,
    );

    this.setState({
      isMoreLessNeeded,
    });

    this.descriptionSpanRef = elem;
  };

  initializeDescriptionWrapperRef = (elem) => {
    if (this.descriptionWrapperRef) {
      return;
    }

    const isMoreLessNeeded = isMoreLessButtonNeeded(
      this.descriptionSpanRef,
      elem,
    );

    this.setState({
      isMoreLessNeeded,
    });

    this.descriptionWrapperRef = elem;
  };

  renderGuest = (guests: any[], label: string) => (
    <div className="guest-type">
      <div>
        <span>{`x ${guests.length} ${pluralize(label, guests.length)}`}</span>
      </div>
      <div>
        <span>-</span>
      </div>
      <div>
        <span>
          USD {moneyFormat(guests.reduce((sum, current) => current.Price, 0))}
        </span>
      </div>
    </div>
  );

  renderGuests = (guests: any[]) => {
    const adults = guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.ADULT,
    );
    const juniors = guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.JUNIOR,
    );
    const children = guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.CHD,
    );
    const infants = guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.INFANT,
    );

    return (
      <div>
        {adults.length > 0 && this.renderGuest(adults, intl.get('adult'))}
        {juniors.length > 0 && this.renderGuest(juniors, intl.get('junior'))}
        {children.length > 0 && this.renderGuest(children, intl.get('child'))}
        {infants.length > 0 && this.renderGuest(infants, intl.get('infant'))}
      </div>
    );
  };

  render() {
    const { availability, addToCard } = this.props;
    const { isDescriptionOpened, isMoreLessNeeded } = this.state;
    const REQUESTABLE_TOOLTIP = intl.get('REQUESTABLE_TOOLTIP');

    return (
      <div
        className={classNames('option', {
          inactive:
            availability.AvailabilityLevel ===
            AvailabilityLevelEnum.NotAvailable,
        })}
      >
        <div className="option-first-part">
          <div>
            <div className="d-f">
              <h4 className="category-name m-r-10">{availability.Name}</h4>
              {availability.AvailabilityLevel ===
                AvailabilityLevelEnum.Requestable && (
                <span className="f-s-16 m-t-1">
                  <Tooltip title={REQUESTABLE_TOOLTIP}>
                    <QuestionCircleFilled className="color-yellow c-p" />
                  </Tooltip>
                </span>
              )}
            </div>
            <div className="guests">
              {this.renderGuests(availability.Guests)}
            </div>
          </div>
          {availability.AvailabilityLevel !==
          AvailabilityLevelEnum.NotAvailable ? (
            <div>
              <div className="money-box">
                <div className="price">
                  USD {moneyFormat(availability.Price)}
                </div>
              </div>
            </div>
          ) : (
            <div className="not-available">
              {intl.get('tourDetails.notAvailable')}
            </div>
          )}
        </div>
        {availability.AvailabilityLevel !==
          AvailabilityLevelEnum.NotAvailable && (
          <div className="option-second-part">
            <div className="description">
              {!!availability.Description && (
                <>
                  <p
                    className={classNames({
                      'description-opened': isDescriptionOpened,
                    })}
                    ref={this.initializeDescriptionWrapperRef}
                  >
                    <span ref={this.initializeDescriptionSpanRef}>
                      {availability.Description}
                    </span>
                  </p>
                  {isMoreLessNeeded && (
                    <div className="description-more-less-btn">
                      <span onClick={this.handleDescriptionOpen}>
                        {!isDescriptionOpened
                          ? intl.get('more')
                          : intl.get('less')}
                      </span>
                    </div>
                  )}
                </>
              )}
              {availability.RatePlan && (
                <div className="d-f jc-sb">
                  {availability.RatePlan.Id && (
                    <div>
                      <span className="f-w-5">{availability.RatePlan.Id}</span>
                    </div>
                  )}
                  {availability.RatePlan.Name && (
                    <div>
                      <span className="f-w-5">
                        {availability.RatePlan.Name}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="add-to-cart-btn">
              <Button type="default" htmlType="button" onClick={addToCard}>
                {intl.get('tourDetails.addToCart')}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
