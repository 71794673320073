import { isEmpty } from 'lodash';
import { Location } from 'react-router-dom';

import { OrderKeysEnum } from '../models/enums/orderKeys.enum';
import { OrderFilterOperatorsEnum } from '../models/enums/orderFilterOperators.enum';
import { TableQueryBuilder } from '../utils/tableQueryBuilder';
import OrdersFilterMapper from './ordersFilter.mapper';
import { parseQueryString } from '../utils/urlUtils';

export default class OrdersTableParamsMapper {
  private filters = '';
  private sorts = '';
  private pagination = {};
  private result = {};

  constructor(location: Location) {
    const parsed = parseQueryString(location.search);

    this.filters = parsed.filters as string;
    this.sorts = parsed.sorts as string;
    this.pagination = {
      page: parsed.page ?? 1,
      pageSize: parsed.pageSize ?? 10,
    };
  }

  private getFilter(key: OrderKeysEnum, values: string[]) {
    const filtersMapper = new OrdersFilterMapper(key);

    switch (key) {
      case OrderKeysEnum.TripId:
        return new OrdersFilterMapper(OrderKeysEnum.TripNumber).getTripNumber(
          values,
        );

      case OrderKeysEnum.PassengerCount:
      case OrderKeysEnum.Nights:
        return filtersMapper.getSearch(
          OrderFilterOperatorsEnum.Equals,
          values[0],
        );

      case OrderKeysEnum.Reference:
      case OrderKeysEnum.Confirmation:
      case OrderKeysEnum.PrimaryGuestName:
      case OrderKeysEnum.PrimaryEmail:
      case OrderKeysEnum.PrimaryPhone:
      case OrderKeysEnum.Name:
      case OrderKeysEnum.ProductId:
      case OrderKeysEnum.CategoryName:
      case OrderKeysEnum.CategoryId:
      case OrderKeysEnum.AgencyId:
      case OrderKeysEnum.AgencyName:
      case OrderKeysEnum.OperatorId:
      case OrderKeysEnum.OperatorName:
      case OrderKeysEnum.ServiceTime:
      case OrderKeysEnum.PickupPointLocationId:
      case OrderKeysEnum.PickupPointName:
      case OrderKeysEnum.PickupPointStartTime:
      case OrderKeysEnum.DropoffPointLocationId:
      case OrderKeysEnum.DropoffPointName:
        return filtersMapper.getList(OrderFilterOperatorsEnum.Contains, values);

      case OrderKeysEnum.ProductType:
      case OrderKeysEnum.PickupPointLocationType:
      case OrderKeysEnum.DropoffPointLocationType:
      case OrderKeysEnum.TestMode:
      case OrderKeysEnum.CommunicationStatus:
        return filtersMapper.getList(OrderFilterOperatorsEnum.Equals, values);

      case OrderKeysEnum.StartDate:
      case OrderKeysEnum.CreatedOn:
        return filtersMapper.getDateRange(values);

      case OrderKeysEnum.Price:
      case OrderKeysEnum.Cost:
        return filtersMapper.getRange(values);

      case OrderKeysEnum.Inactive:
        return filtersMapper.getStatus(values[0]);

      default:
        return '';
    }
  }

  private mapFilters() {
    if (isEmpty(this.filters)) return null;

    return this.filters
      .split(TableQueryBuilder.FILTERS_DELIMITER)
      .map((filter) => {
        const [key, value] = filter.split('=') as [OrderKeysEnum, string];

        return this.getFilter(key, value.split(','));
      })
      .join(', ');
  }

  public buildSorts() {
    const sorts = this.sorts;

    if (sorts) {
      Object.assign(this.result, { sorts });
    }

    return this;
  }

  public buildFilters() {
    const filters = this.mapFilters();

    if (filters) {
      Object.assign(this.result, { filters });
    }

    return this;
  }

  public buildPagination() {
    const pagination = this.pagination;

    if (pagination) {
      Object.assign(this.result, pagination);
    }

    return this;
  }

  public map() {
    return this.result;
  }
}
