import React, { Component } from 'react';
import { Spin } from 'antd';
import { FormikProps } from 'formik';
import { compact } from 'lodash';

import { InventoryStore } from '../../../../../../stores/inventory.store';
import { UserStore } from '../../../../../../stores/user.store';
import { SuppliersStore } from '../../../../../../stores/suppliers.store';
import { CommonStore } from '../../../../../../stores/common.store';
import { ProductTypeEnum } from '../../../../../../models/enums/productType.enum';
import ProductGeneralInfo from './ProductGeneralInfo';
import AdditionGeneralInfo from './AdditionGeneralInfo';
import ProductDescriptionInfo from './ProductDescriptionInfo';
import ProductCancellationPoliciesInfo from './ProductCancellationPoliciesInfo';
import ProductCategoriesAndRatesInfo from './ProductCategoriesAndRatesInfo';
import ProductTimesInfo from './ProductTimesInfo';
import { withStore } from '../../../../../../hocs';
import { ProductFormValuesModel } from '../../../../../../models/inventory/productFormValues.model';
import { ProductSectionKeyEnum } from '../../../../../../models/enums/productSectionKey.enum';
import ProductLocation from './ProductLocation';
import ProductDetailsHelp from './ProductDetailsHelp';
import ProductDetailsNav from './ProductDetailsNav';

@withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
  commonStore: rootStore.commonStore,
  userStore: rootStore.userStore,
  suppliersStore: rootStore.suppliersStore,
}))
export default class ProductInfoTab extends Component<{
  formikProps: FormikProps<ProductFormValuesModel>;
  extraData: any;
  inventoryStore?: InventoryStore;
  userStore?: UserStore;
  suppliersStore?: SuppliersStore;
  commonStore?: CommonStore;
  activeRateKey: string[];
  activeTimeKey: string[];
  onActiveTimeChange: (key: string | string[]) => void;
  onActiveRateChange: (key: string | string[]) => void;
  onToggleAllRates: () => void;
  onToggleAllTimes: () => void;
}> {
  componentDidMount() {
    this.props.inventoryStore.clearDeletedRatesIds();
    this.props.inventoryStore.clearDeletedTimesIds();
  }

  get visibleSectionsKeys() {
    const { inventoryStore } = this.props;
    const { productDetailsType } = inventoryStore;

    return compact([
      ProductSectionKeyEnum.General,
      ProductSectionKeyEnum.Rates,
      ProductSectionKeyEnum.Times,
      ProductSectionKeyEnum.Information,
      ProductSectionKeyEnum.Cancellation,
      productDetailsType === ProductTypeEnum.Service &&
        ProductSectionKeyEnum.Location,
    ]);
  }

  isSectionVisible = (key) => this.visibleSectionsKeys.includes(key);

  render() {
    const {
      extraData,
      formikProps,
      inventoryStore: { isCurrentProductLoading },
      activeRateKey,
      activeTimeKey,
      onToggleAllRates,
      onToggleAllTimes,
      onActiveTimeChange,
      onActiveRateChange,
    } = this.props;
    const { values, setFieldValue } = formikProps;

    if (isCurrentProductLoading) {
      return <Spin className="spin" size="large" />;
    }

    return (
      <div className="d-f">
        <div className="product-body main-info">
          <ProductDetailsNav sectionsKeys={this.visibleSectionsKeys} />

          {this.isSectionVisible(ProductSectionKeyEnum.General) && (
            <ProductGeneralInfo
              extraData={extraData}
              setFieldValue={setFieldValue}
              values={values}
            />
          )}

          <AdditionGeneralInfo />

          {this.isSectionVisible(ProductSectionKeyEnum.Rates) && (
            <ProductCategoriesAndRatesInfo
              extraData={extraData}
              formikProps={formikProps}
              activeRateKey={activeRateKey}
              onToggleAllRates={onToggleAllRates}
              onActiveRateChange={onActiveRateChange}
            />
          )}

          {this.isSectionVisible(ProductSectionKeyEnum.Times) && (
            <ProductTimesInfo
              activeTimeKey={activeTimeKey}
              formikProps={formikProps}
              onToggleAll={onToggleAllTimes}
              onActiveTimeChange={onActiveTimeChange}
            />
          )}

          {this.isSectionVisible(ProductSectionKeyEnum.Information) && (
            <ProductDescriptionInfo extraData={extraData} />
          )}

          {this.isSectionVisible(ProductSectionKeyEnum.Cancellation) && (
            <ProductCancellationPoliciesInfo values={values} />
          )}

          {this.isSectionVisible(ProductSectionKeyEnum.Location) && (
            <ProductLocation formikProps={formikProps} />
          )}
        </div>

        <div className="product-aside">
          <ProductDetailsHelp className="product-instructions" />
        </div>
      </div>
    );
  }
}
