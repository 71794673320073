import React, { Component } from 'react';
import { reaction } from 'mobx';
import intl from 'react-intl-universal';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Select, Divider, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { CheckoutStore } from '../../../../stores/checkout.store';
import { TravelerFormModel } from '../../../../models/form-models/travelerForm.model';
import { InputContainer, SelectContainer } from '../../../FormContainers';

import { phoneCodesPreferable, phoneCodes } from '../../../../utils/phoneCodes';
import { englishLetterRegex } from '../utils/validationUtils';
import { withStore } from '../../../../hocs';

@withStore(({ rootStore }) => ({
  checkoutStore: rootStore.checkoutStore,
}))
export default class TravelerLeadForm extends Component<{
  formId: number;
  isPrefilled: boolean;
  checkoutStore?: CheckoutStore;
}> {
  isInitialized = false;
  validate = null;
  disposer = null;

  state = {
    initialValues: null,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const {
      formId,
      isPrefilled,
      checkoutStore: { travelers },
    } = this.props;
    const initialValues = !isPrefilled
      ? new TravelerFormModel(
          { id: formId, countryCodeAndPhoneCode: 'US:1' },
          true,
        )
      : travelers.find((traveler) => traveler.id === formId);

    this.setState({
      initialValues,
    });
  }

  componentDidMount() {
    const { checkoutStore } = this.props;

    this.disposer = reaction(
      () => checkoutStore.shouldValidate,
      (shouldValidate) => shouldValidate > 0 && this.validate(),
    );
  }

  componentWillUnmount() {
    const { checkoutStore, formId } = this.props;

    checkoutStore.unregisterFieldInValidStore(`travelerForm:${formId}`);
    this.disposer();
  }

  handleTravelerFormChange(values, isFormValid) {
    const { checkoutStore } = this.props;

    checkoutStore.createOrUpdateTraveler(new TravelerFormModel(values, true));
    checkoutStore.updateValid(`travelerForm:${values.id}`, isFormValid);
  }

  initializeValidationFunction = (validateForm, setErrors, setTouched) => {
    this.isInitialized = true;
    this.validate = () => {
      validateForm().then((errors) => {
        setErrors(errors);
        const result = {};
        Object.keys(errors).forEach((k) => (result[k] = true));
        setTouched(result);
      });
    };
  };

  handleOnSelectChange = (setFieldValueFunc, fieldName) => (value) =>
    setFieldValueFunc(fieldName, value);

  renderSelectChildren = () => {
    const preferableCodes = phoneCodesPreferable.map((country) => {
      const identifier = `${country.countryCode}:${country.phoneCode}`;

      return (
        <Select.Option value={identifier} key={identifier}>
          {`${country.countryName} (+${country.phoneCode})`}
        </Select.Option>
      );
    });
    const ordinaryCodes = phoneCodes.map((country) => {
      const identifier = `${country.countryCode}:${country.phoneCode}`;

      return (
        <Select.Option value={identifier} key={identifier}>
          {`${country.countryName} (+${country.phoneCode})`}
        </Select.Option>
      );
    });
    const divider = (
      <Select.Option value="divider" key="divider" disabled>
        <Divider dashed className="phoneCode-divider" />
      </Select.Option>
    );

    return [...preferableCodes, divider, ...ordinaryCodes];
  };

  render() {
    const { isPrefilled } = this.props;
    const { initialValues } = this.state;
    const emailTooltip = (
      <Tooltip
        placement="topLeft"
        title={intl.get('questionMarks.checkout.email')}
        trigger="hover"
        overlayClassName="checkout-tooltip"
      >
        <QuestionCircleOutlined />
      </Tooltip>
    );

    return (
      <div className="traveler-form-container">
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            // Dean: should be always required
            firstName: Yup.string()
              .required(intl.get('required'))
              .test(
                'length',
                intl.get('checkout.mustBeAtLeastCharacters', { minCount: 2 }),
                (value) => value && value.trim().length > 1,
              )
              .test(
                'englishLetters',
                intl.get('checkout.englishLetters'),
                (value) => englishLetterRegex.test(value),
              ),
            lastName: Yup.string()
              .required(intl.get('required'))
              .test(
                'length',
                intl.get('checkout.mustBeAtLeastCharacters', { minCount: 2 }),
                (value) => value && value.trim().length > 1,
              )
              .test(
                'englishLetters',
                intl.get('checkout.englishLetters'),
                (value) => englishLetterRegex.test(value),
              ),
            countryCodeAndPhoneCode: Yup.string().required(
              intl.get('required'),
            ),
            phoneNumber: Yup.string()
              .matches(/^\d+$/, intl.get('checkout.phoneNumberError'))
              .when('countryCodeAndPhoneCode', (codeAndPhone, schema) => {
                switch (codeAndPhone) {
                  // china
                  case 'CN:86':
                  case 'TW:886':
                  case 'HK:852': {
                    return schema.min(
                      8,
                      intl.get('checkout.phoneNumberMinEight', { count: 8 }),
                    );
                  }
                  case 'US:1': {
                    return schema
                      .min(
                        10,
                        intl.get('checkout.phoneNumberForUSA', { count: 10 }),
                      )
                      .max(
                        10,
                        intl.get('checkout.phoneNumberForUSA', { count: 10 }),
                      );
                  }
                }
              })
              .required(intl.get('required')),
            email: Yup.string().email(),
          })}
          onSubmit={() => undefined}
        >
          {({
            values,
            setFieldValue,
            validateForm,
            setErrors,
            setTouched,
            isValid,
          }) => (
            <>
              <>
                {!this.isInitialized &&
                  this.initializeValidationFunction(
                    validateForm,
                    setErrors,
                    setTouched,
                  )}
                {!isPrefilled && this.handleTravelerFormChange(values, isValid)}
              </>

              <div className="traveler-header">
                <div className="title">{intl.get('checkout.leadTraveler')}</div>
              </div>
              <div className="fields-container">
                <Field
                  className="checkout-field first-name"
                  disabled={isPrefilled}
                  name="firstName"
                  component={InputContainer}
                  label={`${intl.get('checkout.firstName')} *`}
                />
                <Field
                  className="checkout-field last-name"
                  disabled={isPrefilled}
                  name="lastName"
                  component={InputContainer}
                  label={`${intl.get('checkout.lastName')} *`}
                />
                <div className="phone">
                  {!isPrefilled && (
                    <Field
                      showSearch
                      name="countryCodeAndPhoneCode"
                      // eslint-disable-next-line react/no-children-prop
                      children={this.renderSelectChildren()}
                      component={SelectContainer}
                      label={`${intl.get('checkout.phone')} *`}
                      dropdownMatchSelectWidth={false}
                      onChange={this.handleOnSelectChange(
                        setFieldValue,
                        'countryCodeAndPhoneCode',
                      )}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        if (typeof option.props.children === 'string') {
                          return (
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                      }}
                      className="checkout-field phone-code"
                    />
                  )}
                  {!isPrefilled ? (
                    <Field
                      className="checkout-field"
                      disabled={isPrefilled}
                      name="phoneNumber"
                      component={InputContainer}
                      label="&nbsp;"
                    />
                  ) : (
                    <Field
                      className="checkout-field"
                      disabled={isPrefilled}
                      name="phoneNumber"
                      component={InputContainer}
                      label={`${intl.get('checkout.phone')} *`}
                    />
                  )}
                </div>
                <Field
                  className="checkout-field email"
                  disabled={isPrefilled}
                  name="email"
                  component={InputContainer}
                  label={intl.get('checkout.emailOptional')}
                  tooltip={emailTooltip}
                />
              </div>
            </>
          )}
        </Formik>
      </div>
    );
  }
}
