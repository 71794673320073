import React, { Component } from 'react';
import { Moment } from 'moment';
import classnames from 'classnames';

import { DatePicker } from 'antd';
import { getIn } from 'formik';
import { normalizeDate } from '../../utils/formatter';

export default class DatepickerContainer extends Component<{
  children?: Component;
  field: any;
  form: any;
  label: string;
  placeholder?: string;
  format: string;
  disabledDate?: any;
  getCalendarContainer?: any;
  stringAsResult?: boolean;
  className?: string;
  allowClear?: boolean;
}> {
  dateFormat = 'MMM D (ddd)';

  getCalendarContainerDefault = () => document.body;

  handleChange = (date: Moment) => {
    const {
      form: { setFieldValue },
      field: { name },
      format,
      stringAsResult,
    } = this.props;

    setFieldValue(
      name,
      (stringAsResult
        ? date.format(format || this.dateFormat)
        : normalizeDate(date.utc())) || '',
    );
  };

  render() {
    const {
      field,
      form: { touched, errors },
      label,
      placeholder,
      format,
      disabledDate,
      getCalendarContainer,
      className,
      allowClear,
      ...rest
    } = this.props;
    const fieldError: string = getIn(errors, field.name);
    const isTouched: boolean = getIn(touched, field.name);
    const hasError: boolean = fieldError && isTouched;

    return (
      <div
        className={classnames('field', { 'has-error': hasError }, className)}
      >
        {label && <h4 className="field-label">{label}</h4>}
        <DatePicker
          allowClear={!!allowClear}
          defaultValue={field.value}
          onChange={this.handleChange}
          placeholder={placeholder}
          format={format || this.dateFormat}
          className="container-field"
          disabledDate={disabledDate}
          showToday={false}
          dropdownClassName="date-picker"
          getPopupContainer={
            getCalendarContainer || this.getCalendarContainerDefault
          }
          {...rest}
        />
        {hasError && <div className="field-error visible">{fieldError}</div>}
      </div>
    );
  }
}
