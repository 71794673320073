import React, { Component } from 'react';
import { Select, Button } from 'antd';
import intl from 'react-intl-universal';
import * as Yup from 'yup';

import { Formik, Form, Field } from 'formik';
import { CheckCircleOutlined } from '@ant-design/icons';

import SelectContainer from '../../FormContainers/select.container';
import { InputContainer, TextareaContainer } from '../../FormContainers';
import {
  supportEmail,
  supportTechEmail,
  supportOrdersEmail,
  supportAccountEmail,
} from '../../../utils/constants';
import { withStore } from '../../../hocs';
import { MessagesStore } from '../../../stores/messages.store';

export const helpCategories = {
  generalQuestions: 'generalQuestions',
  accountAndBilling: 'accountAndBilling',
  productInquiryOrRequest: 'productInquiryOrRequest',
  orders: 'orders',
  technicalIssue: 'technicalIssue',
  suggestionsAndFeedback: 'suggestionsAndFeedback',
};

@withStore(({ rootStore }) => ({
  messagesStore: rootStore.messagesStore,
}))
export default class HelpAndSupport extends Component<{
  messagesStore?: MessagesStore;
  defaultCategory?: string;
}> {
  state = {
    isSuccessVisible: false,
  };

  handleSubmit = (values) => {
    const { messagesStore } = this.props;
    let email;

    switch (values.category) {
      case 'orders':
        email = supportOrdersEmail;
        break;
      case 'technicalIssue':
        email = supportTechEmail;
        break;
      case 'accountAndBilling':
        email = supportAccountEmail;
        break;
      case 'generalQuestions':
      case 'productInquiryOrRequest':
      case 'suggestionsAndFeedback':
      default:
        email = supportEmail;
    }

    const body = {
      Request: {
        Email: email,
        Name: values.name,
        Category: values.category,
        Message: values.message,
      },
    };

    messagesStore.sendSupportEmail(body).then(() => {
      this.setState({
        isSuccessVisible: true,
      });
    });
  };

  handleOnSelectChange = (setFieldValueFunc, fieldName) => (value) =>
    setFieldValueFunc(fieldName, value);

  renderSelectOptions = () =>
    Object.values(helpCategories).map((category) => (
      <Select.Option value={category} key={category}>
        {intl.get(`helpAndSupport.categories.${category}`)}
      </Select.Option>
    ));

  renderForm = () => {
    const { defaultCategory } = this.props;

    return (
      <div>
        <div className="help-and-support-body">
          <div className="header">
            <h3>{intl.get('helpAndSupport.helpAndSupport')}</h3>
          </div>
          <div className="form">
            <Formik
              initialValues={{
                category: defaultCategory || helpCategories.generalQuestions,
                name: '',
                message: '',
              }}
              validationSchema={Yup.object().shape({
                category: Yup.string().required(intl.get('required')),
                name: Yup.string().required(intl.get('required')),
                message: Yup.string().required(intl.get('required')),
              })}
              validateOnChange
              onSubmit={this.handleSubmit}
            >
              {({ setFieldValue }) => (
                <Form className="login-form">
                  <div className="form-wrapper">
                    <div>
                      <Field
                        name="category"
                        component={SelectContainer}
                        label={intl.get('helpAndSupport.canHelpYou')}
                        onChange={this.handleOnSelectChange(
                          setFieldValue,
                          'category',
                        )}
                      >
                        {this.renderSelectOptions()}
                      </Field>
                    </div>
                    <div>
                      <Field
                        name="name"
                        component={InputContainer}
                        label={intl.get('labels.name')}
                      />
                    </div>
                    <div>
                      <Field
                        name="message"
                        component={TextareaContainer}
                        label={intl.get('labels.message')}
                      />
                    </div>
                    <div className="button-submit">
                      <Button type="primary" htmlType="submit" size="large">
                        {intl.get('submit')}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="help-and-support-footer">
          <p>
            <span>{intl.get('helpAndSupport.contactUsExplanation')}</span>
            <span className="email">
              &nbsp;
              {supportEmail}
            </span>
          </p>
        </div>
      </div>
    );
  };

  renderSuccess = () => (
    <div className="support-success">
      <div className="header">
        <span>{intl.get('helpAndSupport.emailSuccess')}</span>
      </div>
      <div className="icon">
        <CheckCircleOutlined />
      </div>
    </div>
  );

  render() {
    const { isSuccessVisible } = this.state;

    return (
      <div className="help-and-support-container">
        {isSuccessVisible ? this.renderSuccess() : this.renderForm()}
      </div>
    );
  }
}
