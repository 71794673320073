import React, { PureComponent } from 'react';
import { List, Button, Popconfirm, Tooltip } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import intl from 'react-intl-universal';

import { withStore } from 'hocs';
import { MessageTemplatesStore } from 'stores/messageTemplates.store';
import { NotificationTemplate } from 'models/notification-template/notificationTemplate.model';
import { messageTemplateSchema } from 'utils/validation/schemas/messageTemplate.schema';
import { getTemplateInitialValues } from 'utils/messageTemplateUtils';
import MessageTemplateModal from './MessageTemplateModal';

type Props = {
  messageTemplatesStore?: MessageTemplatesStore;
  messageTemplate: NotificationTemplate;
};

type State = {
  isEditModalVisible: boolean;
};

@withStore(({ rootStore }) => ({
  messageTemplatesStore: rootStore.messageTemplatesStore,
}))
export default class MessageTemplateCard extends PureComponent<Props, State> {
  state = {
    isEditModalVisible: false,
  };

  get hasError() {
    const { messageTemplate } = this.props;

    return !messageTemplateSchema.isValidSync(
      getTemplateInitialValues(messageTemplate),
    );
  }

  handleTemplateDelete = async () => {
    const { messageTemplate, messageTemplatesStore } = this.props;

    await messageTemplatesStore.deleteNotificationTemplate(messageTemplate.Id);
  };

  handleEditModalOpen = () => {
    this.setState({ isEditModalVisible: true });
  };

  handleEditModalClose = () => {
    this.setState({ isEditModalVisible: false });
  };

  render() {
    const { messageTemplate } = this.props;

    const { isEditModalVisible } = this.state;

    return (
      <>
        <List.Item
          actions={[
            this.hasError ? (
              <Tooltip title={intl.get('messageTemplates.validationError')}>
                <WarningOutlined style={{ color: 'red' }} />
              </Tooltip>
            ) : null,
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={this.handleEditModalOpen}
            />,
            <Popconfirm
              title={intl.get('messageTemplates.sureToDelete')}
              placement="topRight"
              okText={intl.get('buttons.yes')}
              cancelText={intl.get('buttons.no')}
              onConfirm={this.handleTemplateDelete}
            >
              <Button
                danger
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>,
          ]}
        >
          <List.Item.Meta title={messageTemplate.Description} />
        </List.Item>

        <MessageTemplateModal
          visible={isEditModalVisible}
          messageTemplateId={messageTemplate.Id}
          onClose={this.handleEditModalClose}
        />
      </>
    );
  }
}
