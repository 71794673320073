import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { reaction } from 'mobx';
import { Select, Input } from 'antd';

import { CheckoutStore } from '../../../../stores/checkout.store';
import PickupPoint from '../../../../models/tour-details/pickupPoint.model';
import PickupPointForm from '../../../../models/tour-details/pickupPointForm.model';
import { getOppositeHotelType } from '../utils/pickUpPoints';
import { withStore } from '../../../../hocs';

export enum hotelTypes {
  pickup = 'pickup',
  dropOff = 'dropOff',
}

export const customFields = {
  pickup: 'isCustomPickup',
  dropOff: 'isCustomDropoff',
};

export enum locationTypes {
  HOTEL = 'HTL',
  WITH_YOU_TICKET = 'WAY',
}

const customPointId = 'notListed';
const notDisplayId = 'notBooked';

@withStore(({ rootStore }) => ({
  checkoutStore: rootStore.checkoutStore,
}))
export default class PickUpPoints extends Component<{
  cardId: string;
  pickupPoints: PickupPoint[];
  dropoffPoints: PickupPoint[];
  checkoutStore?: CheckoutStore;
}> {
  disposer = null;

  state = {
    relevantPickupOptions: [],
    relevantDropoffOptions: [],
    isCustomPickup: false,
    isCustomDropoff: false,
    isErrorLabelNeeded: false,
  };

  componentDidMount() {
    const { checkoutStore, cardId, pickupPoints, dropoffPoints } = this.props;
    const isSelectValid = false;

    const relevantPickupOptions = pickupPoints.filter(
      (point) => point.LocationId !== notDisplayId,
    );
    const relevantDropoffOptions = dropoffPoints.filter(
      (point) => point.LocationId !== notDisplayId,
    );
    // relevantOptions.push({ LocationId: customPointId } as any);

    this.setState({
      relevantPickupOptions,
      relevantDropoffOptions,
    });

    if (relevantPickupOptions && relevantPickupOptions.length) {
      checkoutStore.updateValid(
        `pickupPoint:${cardId}:${hotelTypes.pickup}`,
        isSelectValid,
      );
    }

    if (relevantDropoffOptions && relevantDropoffOptions.length) {
      checkoutStore.updateValid(
        `pickupPoint:${cardId}:${hotelTypes.dropOff}`,
        isSelectValid,
      );
    }

    this.disposer = reaction(
      () => checkoutStore.shouldValidate,
      (shouldValidate) => shouldValidate > 0 && this.validate(),
    );
  }

  componentWillUnmount() {
    const { checkoutStore, cardId } = this.props;
    const { relevantPickupOptions, relevantDropoffOptions } = this.state;

    if (relevantPickupOptions && relevantPickupOptions.length) {
      checkoutStore.unregisterFieldInValidStore(
        `pickupPoint:${cardId}:${hotelTypes.pickup}`,
      );
    }

    if (relevantDropoffOptions && relevantDropoffOptions.length) {
      checkoutStore.unregisterFieldInValidStore(
        `pickupPoint:${cardId}:${hotelTypes.dropOff}`,
      );
    }

    this.disposer();
  }

  validate = () => {
    const { checkoutStore, cardId } = this.props;

    const isSelectsValid = checkoutStore.isItemsInCheckoutValid(
      `pickupPoint:${cardId}`,
    );

    this.setState({
      isErrorLabelNeeded: !isSelectsValid,
    });
  };

  handleCustomChange = (type: string) => (event) => {
    const { checkoutStore, cardId } = this.props;
    const Address = event.target.value.trim();
    const isSelectValid = !!Address;

    checkoutStore.createOrUpdatePickupPoint(
      new PickupPointForm({ cardId, type, isCustom: true, Address }),
    );
    checkoutStore.updateValid(`pickupPoint:${cardId}:${type}`, isSelectValid);
  };

  handleCustomVisibilityChange = (property: string, isVisible: boolean) => {
    this.setState({
      [property]: isVisible,
    });
  };

  handleSelectChange = (type: string) => (locationId) => {
    const { checkoutStore, cardId } = this.props;
    const { relevantPickupOptions } = this.state;
    const selectedPoint = relevantPickupOptions.find(
      (point) => point.LocationId === locationId,
    );
    const isSelectValid = !!selectedPoint;

    let isCustom;
    if (isSelectValid) {
      if (selectedPoint.LocationId === customPointId) {
        const isVisible = true;
        const isInputValid = false;

        isCustom = true;
        this.handleCustomVisibilityChange(customFields[type], isVisible);
        checkoutStore.updateValid(
          `pickupPoint:${cardId}:${type}`,
          isInputValid,
        );
        return;
      }

      if (
        selectedPoint.LocationId !== customPointId &&
        this.state[customFields[type]]
      ) {
        const isVisible = false;

        this.handleCustomVisibilityChange(customFields[type], isVisible);
      }
    }

    checkoutStore.createOrUpdatePickupPoint(
      new PickupPointForm({ ...selectedPoint, cardId, type, isCustom }),
    );
    checkoutStore.updateValid(`pickupPoint:${cardId}:${type}`, isSelectValid);

    const oppositeType = getOppositeHotelType(type);
    const isOppositeSelectFilled = checkoutStore.isItemsInCheckoutValid(
      `pickupPoint:${cardId}:${oppositeType}`,
    );

    if (!isOppositeSelectFilled) {
      const isOppositeSelectValid = true;
      // as pickup points and dropoff points is the same then selectedPoint has the same properties
      checkoutStore.createOrUpdatePickupPoint(
        new PickupPointForm({
          ...selectedPoint,
          cardId,
          type: oppositeType,
          isCustom,
        }),
      );
      checkoutStore.updateValid(
        `pickupPoint:${cardId}:${oppositeType}`,
        isOppositeSelectValid,
      );
    }
  };

  render() {
    const {
      checkoutStore: { pickupPoints },
      cardId,
    } = this.props;
    const {
      isCustomPickup,
      isCustomDropoff,
      isErrorLabelNeeded,
      relevantPickupOptions,
      relevantDropoffOptions,
    } = this.state;

    if (!relevantPickupOptions || !relevantPickupOptions.length) {
      return null;
    }

    // locationId = "notListed" means ability of Custom hotel
    // Matt: if there is an option with LocationId of "notBooked" you should exclude that option from being displayed

    const pickupOptions = relevantPickupOptions.map((point) =>
      point.LocationId !== customPointId ? (
        <Select.Option key={point.LocationId} value={point.LocationId}>
          {`${intl.get('pickup')}${
            point.LocationType === locationTypes.HOTEL
              ? `: ${intl.get('hotel')}`
              : ''
          } - ${point.Name}`}
        </Select.Option>
      ) : (
        <Select.Option key={point.LocationId} value={point.LocationId}>
          {`${intl.get('pickup')} - ${intl.get('customLocation')}`}
        </Select.Option>
      ),
    );
    const dropoffOptions = relevantDropoffOptions.map((point) =>
      point.LocationId !== customPointId ? (
        <Select.Option key={point.LocationId} value={point.LocationId}>
          {`${intl.get('dropoff')}${
            point.LocationType === locationTypes.HOTEL
              ? `: ${intl.get('hotel')}`
              : ''
          } - ${point.Name}`}
        </Select.Option>
      ) : (
        <Select.Option key={point.LocationId} value={point.LocationId}>
          {`${intl.get('dropoff')} - ${intl.get('customLocation')}`}
        </Select.Option>
      ),
    );

    const relevantPickupPoints = pickupPoints.filter(
      (point) => point.cardId === cardId,
    );
    const pickupForm = relevantPickupPoints.find(
      (point) => point.type === hotelTypes.pickup,
    );
    const dropoffForm = relevantPickupPoints.find(
      (point) => point.type === hotelTypes.dropOff,
    );

    const PICKUP_PLACEHOLDER = 'Select pickup'; // intl.get('selectPickupHotel')
    const DROPOFF_PLACEHOLDER = 'Select dropoff'; // intl.get('selectPickupHotel')

    return (
      <div className="pickup-points">
        <div className="pickup-hotel">
          <div>
            <Select
              onChange={this.handleSelectChange(hotelTypes.pickup)}
              placeholder={PICKUP_PLACEHOLDER}
              className="checkout-field pickup-point"
              value={pickupForm ? pickupForm.LocationId : undefined}
            >
              {pickupOptions}
            </Select>
          </div>
          {isCustomPickup && (
            <div className="checkout-field">
              <Input onChange={this.handleCustomChange(hotelTypes.pickup)} />
            </div>
          )}
        </div>
        {!!dropoffOptions && !!dropoffOptions.length && (
          <div className="drop-off-hotel">
            <div>
              <Select
                onChange={this.handleSelectChange(hotelTypes.dropOff)}
                placeholder={DROPOFF_PLACEHOLDER}
                className="checkout-field pickup-point"
                value={dropoffForm ? dropoffForm.LocationId : undefined}
              >
                {dropoffOptions}
              </Select>
            </div>
            {isCustomDropoff && (
              <div className="checkout-field">
                <Input onChange={this.handleCustomChange(hotelTypes.dropOff)} />
              </div>
            )}
          </div>
        )}
        {isErrorLabelNeeded && (
          <div className="field-error">
            <span>{intl.get('checkout.fieldsMustBeFilled')}</span>
          </div>
        )}
      </div>
    );
  }
}
