import intl from 'react-intl-universal';

import { TypeOfDocumentEnum } from '../../../../models/enums/typeOfDocument.enum';

export const getDocumentName = (docTypeName: TypeOfDocumentEnum) => {
  switch (docTypeName) {
    case TypeOfDocumentEnum.Invoice:
      return intl.get('orders.popup.invoice');

    case TypeOfDocumentEnum.Voucher:
    case TypeOfDocumentEnum.ServiceVouchers:
      return intl.get('orders.popup.voucher');

    case TypeOfDocumentEnum.PaymentReceipt:
      return intl.get('orders.popup.paymentReceipt');

    default:
      return '';
  }
};
