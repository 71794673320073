export enum DateRangeTemplateEnum {
  YearToDate = 'year-to-date',
  PrevYear = 'prev-year',
  PrevQuarter = 'prev-quarter',
  Prev12Months = 'prev-12-months',
  Prev3Months = 'prev-3-months',
  Prev30Days = 'prev-30-days',
  Prev7Days = 'prev-7-days',
  Yesterday = 'yesterday',
  Today = 'today',
  Tomorrow = 'tomorrow',
  ThisWeek = 'this-week',
  ThisQuarter = 'this-quarter',
  ThisMonth = 'this-month',
  ThisYear = 'this-year',
  Next7Days = 'next-7-days',
  Next30Days = 'next-30-days',
  Next3Months = 'next-3-months',
  Next12Months = 'next-12-months',
  NextQuatter = 'next-quarter',
  NextYear = 'next-year',
}
