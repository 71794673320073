import React from 'react';
import { Modal } from 'antd';
import intl from 'react-intl-universal';

import InventoryBulkView from './InventoryBulkView';

type Props = {
  visible: boolean;
  onCancel: () => void;
};

export default function InventoryBulkViewModal(props: Props) {
  const { visible, onCancel } = props;

  return (
    <Modal
      destroyOnClose
      title={intl.get('inventory.bulkView.modalTitle')}
      width="80%"
      style={{ minWidth: 960 }}
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <InventoryBulkView />
    </Modal>
  );
}
