import intl from 'react-intl-universal';
import moment from 'moment';
import * as Yup from 'yup';

import { parseServiceTime } from 'utils/datesUtils';
import { TABLE_ORDERS_BLANK_FILTER_VALUE } from '../../../../utils/constants';

export const TableOrdersTimeFilterSchema = Yup.object().shape({
  search: Yup.string()
    .when('allowBlankEntry', {
      is: false,
      then: Yup.string().required(() => intl.get('required')),
    })
    .duplicatedIn(
      'filterOptions',
      () => intl.get('duplicatesAreNotAllowed'),
      (thisValue, otherValue) =>
        moment(thisValue).isSame(
          parseServiceTime(otherValue as string),
          'minutes',
        ) ||
        (!thisValue && otherValue === TABLE_ORDERS_BLANK_FILTER_VALUE),
    ),
});
