import React, { Component } from 'react';
import classnames from 'classnames';
import { DatePicker } from 'antd';
import { getIn } from 'formik';

const { RangePicker } = DatePicker;

export default class RangeDatepickerContainer extends Component<{
  children?: Component;
  field: any;
  form: any;
  label: string;
  format: string;
  separator?: string;
  className?: string;
}> {
  dateFormat = 'MMM D (ddd)';

  handleChange = (dateRange) => {
    const {
      form: { setFieldValue },
      field: { name },
    } = this.props;

    setFieldValue(name, dateRange);
  };

  render() {
    const {
      field,
      form: { touched, errors },
      label,
      format,
      className,
      separator,
      ...rest
    } = this.props;
    const fieldError: string = getIn(errors, field.name);
    const isTouched: boolean = getIn(touched, field.name);
    const hasError: boolean = fieldError && isTouched;

    return (
      <div
        className={classnames(
          'field m-b-0',
          { 'has-error': hasError },
          className,
        )}
      >
        {label && <h4 className="field-label">{label}</h4>}
        <RangePicker
          {...field}
          {...rest}
          separator={separator || '-'}
          onChange={this.handleChange}
          format={format || this.dateFormat}
        />
        {hasError && <div className="field-error visible">{fieldError}</div>}
      </div>
    );
  }
}
