import React from 'react';
import classnames from 'classnames';

import TourFiltersModule from './TourFiltersModule';
import TourSortModule from './TourSortModule';

type Props = {
  className?: string;
};

export default function TourSearchActionsModule(props: Props) {
  const { className } = props;

  return (
    <div className={classnames('tour-search-actions', className)}>
      <TourFiltersModule />

      <TourSortModule />
    </div>
  );
}
