import React, { Component } from 'react';
import intl from 'react-intl-universal';

import { CardInCartModel } from '../../models/cardInCart.model';
import { CountDownTypeEnum } from '../../models/enums/countDownType.enum';
import CountDownTimer from './CountDownTimer';
import ProductHoldInfoPopover from './ProductHoldInfoPopover';

type Props = {
  cardsInCart: CardInCartModel[];
  timerWrapperClassName?: string;
};

export default class ProductsHoldTimer extends Component<Props> {
  state = {
    key: 0,
  };

  renderCountDownTimer = (activeActivities: CardInCartModel[]) => {
    const { timerWrapperClassName } = this.props;
    const { holdInfo } = activeActivities[0];

    return (
      <div className={timerWrapperClassName}>
        <CountDownTimer
          type={CountDownTypeEnum.Progress}
          width={45}
          startDate={holdInfo.holdStartDate}
          endDate={holdInfo.holdEndDate}
          onTimerEnd={() => this.setState({ key: activeActivities.length })}
        />
      </div>
    );
  };

  render() {
    const { cardsInCart } = this.props;

    return (
      <ProductHoldInfoPopover
        key={this.state.key}
        cardsInCart={cardsInCart}
        text={intl.get('holdInfo.multipleTimerText')}
      >
        {this.renderCountDownTimer}
      </ProductHoldInfoPopover>
    );
  }
}
