import React, { Component, LegacyRef, RefObject } from 'react';
import intl from 'react-intl-universal';
import { Modal, Spin } from 'antd';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import cloneDeep from 'lodash/cloneDeep';
import Carousel from 'nuka-carousel';
import uniqBy from 'lodash/uniqBy';

import { TourStore } from '../../../stores/tour.store';
import { CartStore } from '../../../stores/cart.store';
import AvailabilityCheck from './AvailabilityCheck';
import { AvailableCategoryModel } from '../../../models/tour-details/tourAvailableCategory.model';
import { SliderImagePurposeEnum } from '../../../models/tour-details/sliderImage.model';
import { AvailabilityLevelEnum } from '../../../models/enums/availabilityLevel.enum';
import { getPolicies } from '../../../utils/policyUtils';
import ImageComponent, { defaultImage } from '../../SharedComponents/Image';
import Availability from './Availability';
import SeatMap from './SeatMap';
import AvailabilitiesLoader from './AvailabilitiesLoader';
import { RouterProps, withRouter, withStore } from '../../../hocs';

const countAvailabilitiesDefault = 5;
const countPickupLocationDefault = 10;

type Props = {
  id: string;
  languageCode?: string;
  parentClassName: string;
  cardId?: string;
  tourStore?: TourStore;
  cartStore?: CartStore;
  closeDetails?: Function;
} & RouterProps;

@withRouter
@withStore(({ rootStore }) => ({
  tourStore: rootStore.tourStore,
  cartStore: rootStore.cartStore,
}))
export default class TourDetails extends Component<Props> {
  tripDetailsRef: HTMLElement = null;

  modalRef: RefObject<HTMLElement> = React.createRef();

  state = {
    isAvailabilityChecked: false,
    isGalleryOpen: false,
    galleryStartIndex: 0,
    isAllAvailabilitiesVisible: false,
    isAllPickupLocationVisible: false,
  };

  componentDidMount() {
    const { tourStore, id, closeDetails, languageCode } = this.props;

    tourStore.getTourDetails(id, languageCode).catch(() => {
      closeDetails && closeDetails();
    });
    tourStore.getProductCancellation(id);
  }

  componentWillUnmount() {
    const { tourStore } = this.props;

    tourStore.cleanStore();
  }

  get seatMapUrl() {
    const {
      tourStore: { tourInfo },
    } = this.props;

    const images = tourInfo?.SliderImages || [];
    const image = images.find(
      ({ Purpose }) => Purpose === SliderImagePurposeEnum.Seatmap,
    );

    return image?.ImagePath;
  }

  handleAvailabilityCheck = () => {
    this.setState({ isAvailabilityChecked: true });
    this.handleScrollToAvailabilities();
  };

  handleAddToCard = async (availability) => {
    const { cartStore, cardId, closeDetails, navigate } = this.props;

    try {
      await cartStore.addCardToCart(availability, cardId);

      cardId && closeDetails();

      navigate('/cart');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  handleAllPickupPointsVisible = () => {
    const { isAllPickupLocationVisible } = this.state;

    if (isAllPickupLocationVisible) {
      this.handleScrollToAvailabilities();
    }

    this.setState({ isAllPickupLocationVisible: !isAllPickupLocationVisible });
  };

  handleAllAvailabilitiesVisible = () => {
    const { isAllAvailabilitiesVisible } = this.state;

    if (isAllAvailabilitiesVisible) {
      this.handleScrollToAvailabilities();
    }

    this.setState({ isAllAvailabilitiesVisible: !isAllAvailabilitiesVisible });
  };

  handleScrollToAvailabilities = () => {
    const top = this.tripDetailsRef?.offsetTop ?? 0 - 20;

    this.modalRef.current
      ?.closest(`.${this.props.parentClassName}`)
      ?.scroll({ top, left: 0, behavior: 'smooth' }); // modal window with scroll
  };

  openGallery = (galleryStartIndex = 0) => {
    this.setState({ isGalleryOpen: true, galleryStartIndex });
  };

  closeGallery = () => {
    this.setState({ isGalleryOpen: false });
  };

  renderTripDetails = () => {
    const {
      tourStore: {
        tourInfo: { PickupPoints, Town },
      },
    } = this.props;
    const { isAvailabilityChecked, isAllPickupLocationVisible } = this.state;
    const shouldDisplaySection = Town || (PickupPoints && PickupPoints.length);

    if (!isAvailabilityChecked || !shouldDisplaySection) {
      return null;
    }

    const PickupPointsUniq = uniqBy(PickupPoints, 'Name');

    const pickupPoints = isAllPickupLocationVisible
      ? PickupPointsUniq
      : PickupPointsUniq.slice(0, countPickupLocationDefault);
    let phraseSeePoints;
    if (PickupPointsUniq.length > countAvailabilitiesDefault) {
      isAllPickupLocationVisible
        ? (phraseSeePoints = intl.get('seeLess'))
        : (phraseSeePoints = intl.get('seeMore'));
    }

    return (
      <div className="trip-details">
        <h3 className="trip-details-head">
          {intl.get('tourDetails.tripDetails')}
        </h3>
        <div className="details">
          <div className="details-line">
            <div className="line-head">
              {intl.get('tourDetails.departingAt')}
            </div>
            <div className="line-content">{Town}</div>
          </div>
          <div className="details-line">
            <div className="line-head">{intl.get('tourDetails.pickupLoc')}</div>
            <div className="line-content">
              {pickupPoints &&
                pickupPoints.map((point, index) => (
                  <div key={`${point.Name}-${index}`}>{point.Name}</div>
                ))}
            </div>
          </div>
          {PickupPointsUniq.length > countAvailabilitiesDefault && (
            <div
              className="see-more-less"
              onClick={this.handleAllPickupPointsVisible}
            >
              <span>{phraseSeePoints}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderAvailableCategories = () => {
    const {
      tourStore: {
        tourInfo: { AvailabilityCategories, StartDate },
        isAvailabilitiesLoading,
      },
    } = this.props;
    const { isAvailabilityChecked, isAllAvailabilitiesVisible } = this.state;
    const hasOnlyOneUnavailableProduct =
      AvailabilityCategories?.length === 1 &&
      AvailabilityCategories[0]?.AvailabilityLevel ===
        AvailabilityLevelEnum.NotAvailable;
    const hasNoAvailableProducts =
      !AvailabilityCategories || hasOnlyOneUnavailableProduct;

    if (isAvailabilitiesLoading) {
      return <AvailabilitiesLoader />;
    }

    if (!isAvailabilityChecked) {
      return null;
    }

    if (hasNoAvailableProducts) {
      return (
        <div className="category-options">
          <h3 className="product-options-head">
            {intl.get('tourDetails.productOptions')}
          </h3>
          <div className="no-available-products">
            <h4>{`${intl.get(
              'tourDetails.noAvailableProducts',
            )} ${StartDate.format('MMMM D')}`}</h4>
            <div>{intl.get('tourDetails.selectAnotherDate')}</div>
          </div>
        </div>
      );
    }

    if (this.seatMapUrl) {
      return (
        <div className="category-options">
          <SeatMap
            url={this.seatMapUrl}
            availabilityCategories={AvailabilityCategories}
            onScrollToAvailabilities={this.handleScrollToAvailabilities}
            onAddAvailabilityToCart={this.handleAddToCard}
          />
        </div>
      );
    }

    const availabilities = isAllAvailabilitiesVisible
      ? AvailabilityCategories
      : AvailabilityCategories.slice(0, countAvailabilitiesDefault);
    let phraseSeeAvailabilities;
    if (AvailabilityCategories.length > countAvailabilitiesDefault) {
      isAllAvailabilitiesVisible
        ? (phraseSeeAvailabilities = intl.get('seeLess'))
        : (phraseSeeAvailabilities = intl.get('seeMore'));
    }

    return (
      <div className="category-options">
        <h3 className="product-options-head">
          {intl.get('tourDetails.productOptions')}
        </h3>
        {availabilities.map((availability: AvailableCategoryModel, index) => (
          <Availability
            key={`${availability.Id}-${index}`}
            availability={availability}
            addToCard={() => this.handleAddToCard(availability)}
          />
        ))}
        {AvailabilityCategories.length > countAvailabilitiesDefault && (
          <div
            className="see-more-less"
            onClick={this.handleAllAvailabilitiesVisible}
          >
            <span>{phraseSeeAvailabilities}</span>
          </div>
        )}
      </div>
    );
  };

  renderCancellationPolicy = () => {
    const {
      tourStore: { cancellationPolicies },
    } = this.props;

    return (
      <div className="cancellation-policy">
        <div>
          <h3 className="policy-header">
            {intl.get('cancellationAndRefund.title')}
          </h3>
        </div>
        <ul className="policy-list">{getPolicies(cancellationPolicies)}</ul>
      </div>
    );
  };

  render() {
    const {
      tourStore: { tourInfo },
    } = this.props;
    const { isGalleryOpen, galleryStartIndex } = this.state;
    if (!tourInfo) {
      return <Spin className="spin" size="large" />;
    }

    const galleryImages: ReactImageGalleryItem[] = cloneDeep(
      tourInfo.SliderImages,
    ).map((slideImage) => ({
      original: slideImage.ImagePath,
      description: slideImage.Caption,
      thumbnail: slideImage.ImagePath,
    }));
    const miniGalleryItems = cloneDeep(tourInfo.SliderImages).splice(0, 4);

    return (
      <div
        ref={this.modalRef as LegacyRef<HTMLDivElement>}
        className="tour-details"
      >
        <div className="tour-header">
          <Carousel
            autoplay={false}
            enableKeyboardControls={false}
            withoutControls
          >
            {tourInfo.SliderImages.map((image, index) => (
              <ImageComponent
                key={`slider-image-${index}`}
                src={image.ImagePath}
                className="tour-image"
                isBackground
                onClick={() => this.openGallery(index)}
              />
            ))}
          </Carousel>
        </div>
        <div className="tour-body">
          <div className="tour-info">
            <div className="tour-uptitle">
              <div>
                {[tourInfo.Country, tourInfo.Continent, tourInfo.Location.Name]
                  .filter((v) => v)
                  .join(' / ')}
              </div>
              <div>{tourInfo.ServiceType.Name}</div>
            </div>
            <h2 className="tour-title">{tourInfo.Name}</h2>
            <div className="product-id">#{tourInfo.ProductId}</div>
            <div
              className="main-info"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: tourInfo.MainInformation.FormattedText,
              }}
            />
            <div
              className="category-options-container"
              ref={(elem) => (this.tripDetailsRef = elem)}
            >
              {this.renderTripDetails()}
            </div>
            {this.renderAvailableCategories()}
            {tourInfo.AdditionalInformation.map((ai) => (
              <div className="additional-info-section" key={ai.InformationId}>
                <h3 className="additional-info-head">{ai.Title}</h3>
                <p
                  className="additional-info-content"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: ai.FormattedText }}
                />
              </div>
            ))}
            {this.renderCancellationPolicy()}
          </div>

          <div className="tour-side">
            <AvailabilityCheck
              productId={tourInfo.ProductId}
              availabilityChecked={this.handleAvailabilityCheck}
              scrollToNeededElement={this.handleScrollToAvailabilities}
              modalContainer={this.modalRef.current}
            />
            <div className="tour-mini-gallery">
              {miniGalleryItems.map((si, index) => (
                <div
                  className="image-wrapper"
                  key={si.ImagePath}
                  onClick={() => this.openGallery(index)}
                >
                  <ImageComponent
                    className="image"
                    src={si.ImagePath}
                    alt={si.Caption}
                  />
                </div>
              ))}
              {tourInfo.SliderImages.length > 4 && (
                <div
                  className="all-photos-label"
                  onClick={() => this.openGallery()}
                >
                  {intl.get('tourDetails.allPhotos')} (
                  {tourInfo.SliderImages.length})
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          destroyOnClose
          visible={isGalleryOpen}
          footer={null}
          onCancel={this.closeGallery}
          wrapClassName="modal-tourschain gallery-modal"
        >
          <ImageGallery
            items={galleryImages}
            showPlayButton={false}
            showFullscreenButton={false}
            startIndex={galleryStartIndex}
            infinite={false}
            defaultImage={defaultImage}
          />
        </Modal>
      </div>
    );
  }
}
