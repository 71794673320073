import { ROLES, USER_ROLE_GROUPS } from './constants';
import { UserPropertiesModel } from '../models/userProperties.model';

export const isSupplierUser = (userProperties: UserPropertiesModel) =>
  checkUserGroup(userProperties, USER_ROLE_GROUPS.SUPPLIER);

export const isMarketplaceUser = (userProperties: UserPropertiesModel) =>
  checkUserGroup(userProperties, USER_ROLE_GROUPS.MARKETPLACE);

export const isManagerUser = (userProperties: UserPropertiesModel) =>
  userProperties?.IsManager;

export const isSupplierAdmin = (userProperties: UserPropertiesModel) =>
  checkUserGroup(userProperties, [ROLES.SUPPLIER_ADMIN.value]);

export const isStaffOrStaffAdmin = (userProperties: UserPropertiesModel) =>
  checkUserGroup(userProperties, USER_ROLE_GROUPS.STAFF);

export const checkUserGroup = (
  userProperties: UserPropertiesModel,
  userRoleGroups: string[],
) =>
  userProperties?.Roles.some((userRole) =>
    userRoleGroups.includes(userRole.RoleId),
  );
