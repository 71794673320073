import React, { PureComponent } from 'react';
import { Space, Spin, Table } from 'antd';
import moment from 'moment';
import intl from 'react-intl-universal';
import { compact, isEmpty } from 'lodash';

import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { OrdersStore } from '../../../../stores/orders.store';
import { NotificationStatusEnum } from '../../../../models/enums/notificationStatusEnum';
import { ORDER_NOTIFICATION_DATE_FORMAT } from '../constants';
import {
  getNotificationMethodLabel,
  getNotificationStatusLabel,
} from '../utils/orderUtils';
import { withStore } from '../../../../hocs';
import { NotificationHistoryMethodType } from '../../../../models/enums/notificationHistoryMethodType.enum';
import { NotificationHistoryTemplateModel } from '../../../../models/notification/notificationHistoryTemplate.model';

type Props = {
  ordersStore?: OrdersStore;
};

type State = {
  isLoading: boolean;
};

@withStore(({ rootStore }) => ({
  ordersStore: rootStore.ordersStore,
}))
class OrderDetailsNotificationsHistory extends PureComponent<Props, State> {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    this.handleLoadNotificationsHistory();
  }

  handleLoadNotificationsHistory = async () => {
    const { ordersStore } = this.props;

    this.setState({ isLoading: true });

    await ordersStore.loadOrderNotificationsHistory();

    this.setState({ isLoading: false });
  };

  get columns() {
    const {
      ordersStore: { detailsTableNotifications },
    } = this.props;

    const isStatusExist = (status: NotificationStatusEnum) =>
      detailsTableNotifications.some((item) => item[status]);

    const statusColumns = compact(
      Object.values(NotificationStatusEnum).map((status) =>
        isStatusExist(status)
          ? {
              dataIndex: status,
              key: status,
              title: (
                <Space>
                  {this.getColumnHeaderIcon(status)}

                  <span>{getNotificationStatusLabel(status)}</span>
                </Space>
              ),
              render: (date) =>
                date
                  ? moment(date).format(ORDER_NOTIFICATION_DATE_FORMAT)
                  : null,
            }
          : null,
      ),
    );

    return [
      {
        dataIndex: 'method',
        key: 'method',
        title: intl.get('orders.popup.notifications.method'),
        render: (method: NotificationHistoryMethodType) =>
          getNotificationMethodLabel(method),
      },
      {
        dataIndex: 'templateInfo',
        key: 'templateInfo',
        title: intl.get('orders.popup.notifications.template'),
        render: (template: NotificationHistoryTemplateModel) =>
          this.getTemplateInfo(template),
      },
      ...statusColumns,
    ];
  }

  getTemplateInfo = (template: NotificationHistoryTemplateModel) =>
    template?.RptTypeDesc ?? '';

  getColumnHeaderIcon = (status: NotificationStatusEnum) => {
    switch (status) {
      case NotificationStatusEnum.Sent:
      case NotificationStatusEnum.Delivered:
      case NotificationStatusEnum.Received:
      case NotificationStatusEnum.Read:
      case NotificationStatusEnum.Click:
        return (
          <CheckCircleOutlined className="order-notifications-table-icon__success" />
        );

      case NotificationStatusEnum.Failed:
      case NotificationStatusEnum.Undelivered:
      case NotificationStatusEnum.Canceled:
        return (
          <ExclamationCircleOutlined className="order-notifications-table-icon__fail" />
        );

      default:
        return null;
    }
  };

  render() {
    const { isLoading } = this.state;

    const { ordersStore } = this.props;

    const { detailsTableNotifications } = ordersStore;

    if (isLoading) return <Spin />;

    return (
      <div className="order-notifications">
        <h3 className="order-notifications-header">
          {intl.get('orders.popup.notifications.title')}
        </h3>

        {isEmpty(detailsTableNotifications) && (
          <p>{intl.get('orders.popup.notifications.emptyMessage')}</p>
        )}

        {!isEmpty(detailsTableNotifications) && (
          <Table
            bordered
            rowKey="NotificationId"
            pagination={false}
            columns={this.columns}
            dataSource={ordersStore.detailsTableNotifications}
          />
        )}
      </div>
    );
  }
}

export default OrderDetailsNotificationsHistory;
