export class RateGroup<T> {
  public static size = 10;

  constructor(
    private values: T[] = [],
    private readonly defaultValue: T = null,
  ) {
    if (values.length > RateGroup.size) {
      throw new Error('The max size of the rate group is exceeded');
    }

    this.setInitialValues();
  }

  private setInitialValues() {
    const elementsToAdd = RateGroup.size - this.values.length;

    if (elementsToAdd > 0) {
      this.values = this.values.concat(
        Array.from(Array(elementsToAdd).keys()).map(() => this.defaultValue),
      );
    }
  }

  public getValues() {
    return this.values;
  }

  public add(arg: T, index: number) {
    this.values[index] = arg;

    return this;
  }
}
