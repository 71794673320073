import React, { PureComponent } from 'react';
import {
  Alert,
  Button,
  Col,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
} from 'antd';
import intl from 'react-intl-universal';
import { Field, FormikProps } from 'formik';
import { toJS } from 'mobx';
import { ErrorBoundary } from 'react-error-boundary';

import { OrdersStore } from '../../../../stores/orders.store';
import {
  EmailEditorContainer,
  RadioGroupContainer,
  SelectContainer,
  TextareaContainer,
} from '../../../FormContainers';
import { OrderCommunicationType } from '../../../../models/enums/orderCommunicationType.enum';
import { NotificationTemplate } from '../../../../models/notification-template/notificationTemplate.model';
import { CommonStore } from '../../../../stores/common.store';
import {
  isEmailFieldVisible,
  isMessageTextVisible,
} from '../utils/orderCommunicationUtils';
import { withStore } from '../../../../hocs';
import { BulkCommunicationFormValuesModel } from '../../../../models/bulkCommunicationFormValues.model';
import InputContainer from '../../../FormContainers/input.container';
import OrdersCommunicationEmailTemplate from './OrdersCommunicationEmailTemplate';
import { messageTemplateSchema } from '../../../../utils/validation/schemas/messageTemplate.schema';
import { getTemplateInitialValues } from '../../../../utils/messageTemplateUtils';

type Props = {
  ordersStore?: OrdersStore;
  commonStore?: CommonStore;
  visible: boolean;
  isBulkCommunicationLoading: boolean;
  primaryMethod: string;
  formikProps: FormikProps<BulkCommunicationFormValuesModel>;
  onCancel: () => void;
};

type State = {
  selectedTemplate: NotificationTemplate;
};

@withStore(({ rootStore }) => ({
  ordersStore: rootStore.ordersStore,
  commonStore: rootStore.commonStore,
}))
export default class OrdersCommunicationModal extends PureComponent<
  Props,
  State
> {
  state = {
    selectedTemplate: undefined,
  };

  componentDidMount() {
    this.handleLoadNotificationTemplates();
  }

  async handleLoadNotificationTemplates() {
    const { ordersStore } = this.props;

    await ordersStore.getNotificationTemplates();
  }

  handleTemplateChange = (templateId: number) => {
    const { formikProps, ordersStore } = this.props;

    const { notificationTemplates } = ordersStore;

    const selectedTemplate = notificationTemplates.find(
      (notificationTemplate) => notificationTemplate.Id === templateId,
    );

    formikProps.setFieldValue('template', templateId);

    formikProps.setFieldValue(
      'messageText',
      selectedTemplate?.ReportTextMobile,
    );

    this.setState({ selectedTemplate });
  };

  handleMessageTypeChange = (e: RadioChangeEvent) => {
    const { value } = e.target;

    const { formikProps } = this.props;

    if (value === OrderCommunicationType.CustomMessage) {
      formikProps.setFieldValue('template', undefined, false);
      formikProps.setFieldValue('messageText', undefined, false);
      formikProps.setFieldValue('emailEditor.html', undefined, false);
      formikProps.setFieldValue('emailEditor.json', undefined, false);

      this.setState({ selectedTemplate: undefined });
    }

    if (value === OrderCommunicationType.PredefinedTemplate) {
      formikProps.setFieldValue('messageText', undefined, false);
      formikProps.setFieldValue('emailEditor.html', undefined, false);
      formikProps.setFieldValue('emailEditor.json', undefined, false);
      formikProps.setFieldValue('emailSubject', undefined, false);
    }
  };

  get templateOptions() {
    const { ordersStore } = this.props;
    const { notificationTemplates } = ordersStore;

    return notificationTemplates
      .filter((notificationTemplate) =>
        messageTemplateSchema.isValidSync(
          getTemplateInitialValues(notificationTemplate),
        ),
      )
      .map((notificationTemplate) => ({
        key: notificationTemplate.Id,
        value: notificationTemplate.Id,
        label: notificationTemplate.Description,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  isShowWarning = () => {
    const { formikProps } = this.props;

    const errors = formikProps.errors.selectedOrders;

    return Array.isArray(errors);
  };

  render() {
    const {
      visible,
      commonStore,
      formikProps,
      primaryMethod,
      isBulkCommunicationLoading,
      onCancel,
    } = this.props;
    const { selectedTemplate } = this.state;

    return (
      <Modal
        width="70%"
        style={{ maxWidth: 1400, minWidth: 990 }}
        visible={visible}
        footer={null}
        title={intl.get('orders.table.communicate')}
        onCancel={onCancel}
      >
        <Space direction="vertical" className="w-100">
          <Field
            name="messageType"
            className="m-b-0"
            label={intl.get('orders.table.communicationTypes.title')}
            component={RadioGroupContainer}
            onChange={this.handleMessageTypeChange}
          >
            <Radio value={OrderCommunicationType.PredefinedTemplate}>
              {intl.get('orders.table.communicationTypes.predefinedTemplate')}
            </Radio>

            <Radio value={OrderCommunicationType.CustomMessage}>
              {intl.get('orders.table.communicationTypes.customMessage')}
            </Radio>
          </Field>

          <Space direction="horizontal" className="w-100" align="baseline">
            <Field
              showSearch
              allowClear
              name="template"
              dropdownMatchSelectWidth={false}
              style={{ width: 200 }}
              optionFilterProp="label"
              className="m-b-0"
              disabled={
                formikProps.values.messageType ===
                OrderCommunicationType.CustomMessage
              }
              label={intl.get('orders.table.template')}
              placeholder={intl.get('orders.table.selectTemplate')}
              component={SelectContainer}
              options={this.templateOptions}
              onChange={this.handleTemplateChange}
            />

            {isEmailFieldVisible(formikProps.values, primaryMethod) && (
              <Field
                name="emailSubject"
                component={InputContainer}
                allowClear
                style={{ width: 200 }}
                disabled={
                  formikProps.values.messageType ===
                  OrderCommunicationType.PredefinedTemplate
                }
                className="m-b-0"
                label={intl.get('orders.table.emailSubject')}
                placeholder={intl.get('orders.table.emailSubject')}
              />
            )}
          </Space>

          {isMessageTextVisible(formikProps.values, primaryMethod) && (
            <Field
              name="messageText"
              className="m-b-0"
              component={TextareaContainer}
              disabled={
                formikProps.values.messageType ===
                OrderCommunicationType.PredefinedTemplate
              }
              autoSize={{ minRows: 3, maxRows: 5 }}
              label={intl.get('orders.table.message')}
            />
          )}

          {selectedTemplate &&
            !isMessageTextVisible(formikProps.values, primaryMethod) && (
              <div className="field">
                <h4 className="field-label">
                  {intl.get('orders.table.emailTemplate')}
                </h4>

                <ErrorBoundary
                  key={selectedTemplate.Id}
                  fallback={
                    <Alert
                      showIcon
                      type="error"
                      description={intl.get(
                        'orders.table.failedToDisplayEmailTemplate',
                      )}
                    />
                  }
                >
                  <OrdersCommunicationEmailTemplate
                    input={selectedTemplate.ReportText}
                    model={formikProps.values.selectedOrders[0]}
                  />
                </ErrorBoundary>
              </div>
            )}

          {isEmailFieldVisible(formikProps.values, primaryMethod) && (
            <Field
              name="emailTemplate"
              label={intl.get('messageTemplates.emailTemplate')}
              minHeight={700}
              options={{
                mergeTags: toJS(commonStore.siteSettings.supplierMergeTags),
                features: {
                  smartMergeTags: false,
                },
              }}
              previewModel={formikProps.values.selectedOrders[0]}
              projectId={commonStore.siteSettings.unlayerProjectId}
              component={EmailEditorContainer}
            />
          )}

          {this.isShowWarning() && (
            <Alert
              message={intl.get('orders.table.communicationWarning')}
              type="warning"
              showIcon
            />
          )}

          <Row justify="end">
            <Col>
              <Button
                type="primary"
                disabled={formikProps.isSubmitting}
                loading={isBulkCommunicationLoading}
                onClick={() => formikProps.submitForm()}
              >
                {intl.get('orders.table.send')}
              </Button>
            </Col>
          </Row>
        </Space>
      </Modal>
    );
  }
}
