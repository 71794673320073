import { FilterValue, SortOrder } from 'antd/lib/table/interface';
import { DateRangeTemplateEnum } from '../enums/dateRangeTemplate.enum';

export class ViewCriteriaItemModel {
  id: string;
  name: string;
  groupedBy: string[];
  filters: Record<string, FilterValue>;
  sorts: Record<string, SortOrder>;
  datesRange: DateRangeTemplateEnum | string[];

  constructor(params: {
    id: string;
    name: string;
    groupedBy?: string[];
    filters?: Record<string, FilterValue>;
    sorts?: Record<string, SortOrder>;
    datesRange?: DateRangeTemplateEnum | string[];
  }) {
    this.id = params.id;
    this.name = params.name;
    this.groupedBy = params.groupedBy;
    this.filters = params.filters;
    this.sorts = params.sorts;
    this.datesRange = params.datesRange;
  }
}
