import React, { Component, Fragment } from 'react';
import moment from 'moment';
import pluralize from 'pluralize';
import intl from 'react-intl-universal';
import classNames from 'classnames';
import { Popover } from 'antd';

import { ServiceModel } from '../../../../models/service.model';
import { GuestTypesEnum } from '../../../../utils/constants';
import { PassengerModel } from '../../../../models/orders/passenger.model';
import { AvailabilityLevelEnum } from '../../../../models/enums/availabilityLevel.enum';
import { UserPropertiesModel } from '../../../../models/userProperties.model';
import { moneyFormat } from '../../../../utils/formatter';
import { getPolicies } from '../../../../utils/policyUtils';
import ImageComponent from '../../../SharedComponents/Image';
import OrderItemCancellation from './OrderItemCancellation';
import { getAgebandLabel, getPaymentStatusIcon } from '../utils/orderUtils';
import { isSupplierUser } from '../../../../utils/userRolesUtils';

const dateFormat = 'MMM D (ddd)';

export default class CardInOrder extends Component<{
  card: ServiceModel;
  passengers: PassengerModel[];
  afterCancelTrip: Function;
  tripId: number;
  isEntireOrderCancellationAvailable: boolean;
  orderDetailsFinance?: any;
  modalContainer?: any;
  userProperties: UserPropertiesModel;
}> {
  state = {
    isCancelPopupVisible: false,
    isCancelAvailable: true,
  };

  componentDidMount() {
    const { card, userProperties, isEntireOrderCancellationAvailable } =
      this.props;

    const isCancelUnavailableForSupplier =
      card.AvailabilityLevel === AvailabilityLevelEnum.Cancelled ||
      !isEntireOrderCancellationAvailable;
    const isCancelUnavailableForUser =
      card.AvailabilityLevel === AvailabilityLevelEnum.Cancelled ||
      moment(card.StartDate).diff(moment()) < 0 ||
      !isEntireOrderCancellationAvailable;

    const isCancelUnavailable = isSupplierUser(userProperties)
      ? isCancelUnavailableForSupplier
      : isCancelUnavailableForUser;

    if (isCancelUnavailable) {
      this.setState({
        isCancelAvailable: false,
      });
    }
  }

  handleCancelPopupVisible = (isCancelPopupVisible) =>
    this.setState({ isCancelPopupVisible });

  getPopupContainer = () => this.props.modalContainer;

  renderGuestFragment = (
    productName: string,
    price: number,
    countOfGuests: number,
    guestLabel: string,
  ) => (
    <div className="ticket-wrapper">
      <div className="ticket-item">
        <div className="ticket-name">{`${productName} (${guestLabel})`}</div>
        <div className="ticket-total">
          <div className="price">{moneyFormat(price)}</div>
          <div className="guest-count-and-type">
            {pluralize(guestLabel, countOfGuests, true)}
          </div>
        </div>
      </div>
    </div>
  );

  renderProductByGuests = () => {
    const {
      card,
      card: { Category },
    } = this.props;

    const adults = card.Guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.ADULT,
    );
    const adultsPrice = adults.reduce((sum, current) => sum + current.Price, 0);

    const children = card.Guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.CHD,
    );
    const childrenPrice = children.reduce(
      (sum, current) => sum + current.Price,
      0,
    );

    const juniors = card.Guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.JUNIOR,
    );
    const juniorsPrice = juniors.reduce(
      (sum, current) => sum + current.Price,
      0,
    );

    const infants = card.Guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.INFANT,
    );
    const infantsPrice = infants.reduce(
      (sum, current) => sum + current.Price,
      0,
    );

    return (
      <Fragment>
        {adults.length > 0 &&
          this.renderGuestFragment(
            Category.Name,
            adultsPrice,
            adults.length,
            intl.get('adult'),
          )}
        {juniors.length > 0 &&
          this.renderGuestFragment(
            Category.Name,
            juniorsPrice,
            juniors.length,
            intl.get('junior'),
          )}
        {children.length > 0 &&
          this.renderGuestFragment(
            Category.Name,
            childrenPrice,
            children.length,
            intl.get('child'),
          )}
        {infants.length > 0 &&
          this.renderGuestFragment(
            Category.Name,
            infantsPrice,
            infants.length,
            intl.get('infant'),
          )}
      </Fragment>
    );
  };

  renderTooltip = () => {
    const { card, afterCancelTrip, tripId, orderDetailsFinance } = this.props;
    const { isCancelAvailable } = this.state;

    if (!isCancelAvailable || !orderDetailsFinance) {
      return null;
    }

    return (
      <div className="item-actions-tooltip">
        <div>
          <OrderItemCancellation
            order={card}
            closePopup={() => this.handleCancelPopupVisible(false)}
            afterCancelTrip={afterCancelTrip}
            tripId={tripId}
          />
        </div>
      </div>
    );
  };

  renderPolicyAndTerms = () => {
    const {
      card: { CancelInfo, StartDate },
    } = this.props;

    return (
      <div className="card-policies">
        <div className="card-policies-header">
          {intl.get('checkout.policyAndTerms')}
        </div>
        <div className="policies-body">
          {getPolicies(CancelInfo.Policies, StartDate)}
        </div>
      </div>
    );
  };

  renderTableRow = (guest: any, passenger: any) => (
    <div key={guest.GuestId} className="guests-row">
      <div
        className={classNames('guest-type', {
          adult: guest.Type === GuestTypesEnum.ADULT,
          child: guest.Type === GuestTypesEnum.CHD,
          junior: guest.Type === GuestTypesEnum.JUNIOR,
          infant: guest.Type === GuestTypesEnum.INFANT,
        })}
      >
        <i className="tc-user1 icon" />
        {getAgebandLabel(guest.Type)}
      </div>
      <div className="traveler-name">{passenger.FormattedName}</div>
      <div className="phone">{!!passenger.Phone && `+${passenger.Phone}`}</div>
      <div className="age">{passenger.Email}</div>
    </div>
  );

  renderTravelerTable = (guests: any[], passengers: PassengerModel[]) => {
    const primaryPassenger = passengers.find(
      (passenger) => passenger.PrimaryGuest,
    );
    const primaryGuest = guests.find(
      (guest) => guest.GuestId === primaryPassenger.GuestId,
    );
    const guestsWithoutPrimary = primaryGuest
      ? guests.filter((guest) => guest.GuestId !== primaryPassenger.GuestId)
      : guests;

    return (
      <div className="card-traveler-table">
        <div className="t-header">
          <div className="guest-type" />
          <div className="traveler-name">
            {intl.get('orders.popup.travelerName')}
          </div>
          <div className="phone">{intl.get('orders.popup.phone')}</div>
          <div className="age">{intl.get('orders.popup.email')}</div>
        </div>
        <div className="t-body">
          {!!primaryGuest &&
            this.renderTableRow(primaryGuest, primaryPassenger)}
          {guestsWithoutPrimary.map((guest) => {
            const passenger = passengers.find(
              (pass) => pass.GuestId === guest.GuestId,
            );

            return this.renderTableRow(guest, passenger);
          })}
        </div>
      </div>
    );
  };

  render() {
    const {
      card: {
        MainInformation,
        StartDate: date,
        ProductId: productId,
        Name: tourName,
        Guests: guests,
        AvailabilityLevel,
      },
      passengers,
    } = this.props;
    const { isCancelPopupVisible, isCancelAvailable } = this.state;
    const imageUrl =
      MainInformation && MainInformation.ThumbnailImageURI
        ? MainInformation.ThumbnailImageURI
        : null;

    return (
      <div className="card-in-order">
        <div className="card-top">
          <ImageComponent className="card-image" src={imageUrl} />
          <div className="card-info">
            <h3 className="tour-name">{tourName}</h3>
            <div className="product-number">#{productId}</div>
            <div className="status-icon">
              <span>{getPaymentStatusIcon(AvailabilityLevel)}</span>
            </div>
          </div>
          {isCancelAvailable &&
            AvailabilityLevel !== AvailabilityLevelEnum.Cancelled && (
              <Popover
                destroyTooltipOnHide
                content={this.renderTooltip()}
                trigger="click"
                placement="bottomRight"
                visible={isCancelPopupVisible}
                onVisibleChange={this.handleCancelPopupVisible}
                overlayClassName="cancel-order-tooltip-container"
                getPopupContainer={this.getPopupContainer}
              >
                <div className="item-actions">
                  <span>···</span>
                </div>
              </Popover>
            )}
        </div>
        <div className="card-bottom">
          <div className="tour-date">{moment(date).format(dateFormat)}</div>
          <div className="products-by-guests">
            {this.renderProductByGuests()}
          </div>
        </div>
        {this.renderTravelerTable(guests, passengers)}
        {this.renderPolicyAndTerms()}
      </div>
    );
  }
}
