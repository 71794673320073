import React, { Component, Fragment } from 'react';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';
import { Field } from 'formik';
import { Col, Divider, Row, Select, Spin } from 'antd';
import { Element } from 'react-scroll';

import { PlusOutlined } from '@ant-design/icons';
import {
  InputContainer,
  InputNumberContainer,
  SelectContainer,
  TimePickerContainer,
} from '../../../../../FormContainers';
import { InventoryStore } from '../../../../../../stores/inventory.store';
import { UserStore } from '../../../../../../stores/user.store';
import { SuppliersStore } from '../../../../../../stores/suppliers.store';
import {
  generateProductId,
  isExistsProductId,
} from '../../../../../../utils/inventoryHelper';
import { ProductTypeEnum } from '../../../../../../models/enums/productType.enum';
import { isSupplierUser } from '../../../../../../utils/userRolesUtils';
import { withStore } from '../../../../../../hocs';
import { HelpInstructionKeyEnum } from '../../../../../../models/enums/helpInstructionKeyEnum';
import { ProductSectionKeyEnum } from '../../../../../../models/enums/productSectionKey.enum';

const { Option } = Select;

@withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
  userStore: rootStore.userStore,
  suppliersStore: rootStore.suppliersStore,
}))
export default class ProductGeneralInfo extends Component<{
  setFieldValue: Function;
  extraData: any;
  values: any;
  inventoryStore?: InventoryStore;
  userStore?: UserStore;
  suppliersStore?: SuppliersStore;
}> {
  handleChangeProductId = debounce(
    (e, value) => this.handleChangeProductIdDebounced(e, value),
    1000,
  );

  handleChangeProductIdDebounced = async (e, value) => {
    const { extraData, inventoryStore, setFieldValue } = this.props;
    const { onProductIdLoading } = extraData;
    const { productDetailsType } = inventoryStore;

    onProductIdLoading(true);

    const isExistsId = await isExistsProductId(value, productDetailsType);

    setFieldValue('isExistsProductId', !!isExistsId);

    onProductIdLoading(false);
  };

  handleGenerateProductId = async (DestinationId, value) => {
    const {
      extraData,
      setFieldValue,
      inventoryStore: { productDetails: currentProduct, productDetailsType },
    } = this.props;
    const { onProductIdLoading } = extraData;

    onProductIdLoading(true);
    if (!currentProduct) {
      const { code, isExistsProduct } = await generateProductId(
        currentProduct,
        DestinationId,
        value,
        productDetailsType,
        this.operatorData?.NewProductPrefix || '',
      );

      setFieldValue('Id', code);
      setFieldValue('isExistsProductId', !!isExistsProduct);
    }

    onProductIdLoading(false);
  };

  getProductTypeFieldName = () => {
    const {
      inventoryStore: { productDetailsType },
    } = this.props;

    switch (productDetailsType) {
      case ProductTypeEnum.Package:
        return 'PackageTypeId';

      case ProductTypeEnum.Service:
      default:
        return 'ServiceTypeId';
    }
  };

  get operatorData() {
    const { inventoryStore, values } = this.props;

    return inventoryStore.getProductSupplier(values.OperatorId);
  }

  isProductCodeDisabled = () => {
    const { inventoryStore, userStore, values } = this.props;
    const { productDetails } = inventoryStore;
    const { userProperties } = userStore;

    if (productDetails) {
      return true;
    }

    return isSupplierUser(userProperties)
      ? !values.DestinationId || !values.isExistsProductId
      : !values.DestinationId;
  };

  onSetHelpInstructionKey = (key: HelpInstructionKeyEnum) => () => {
    const { inventoryStore } = this.props;

    inventoryStore.setActiveHelpInstructionKey(key);
  };

  render() {
    const {
      extraData,
      setFieldValue,
      inventoryStore: {
        productTypes,
        isProductTypesLoading,
        destinations,
        isDestinationsLoading,
        productDetailsType,
      },
      suppliersStore: { suppliers, isSupplierSelectable, isSuppliersLoading },
      values: { Name, DestinationId },
    } = this.props;
    const { onSupplierPopupOpen, isProductIdLoading } = extraData;

    const supplierOptions = suppliers.map((supplier) => (
      <Option key={supplier.Id} value={supplier.Id}>
        {supplier.Name}
      </Option>
    ));

    const productTypesOptions = productTypes.map((productType) => (
      <Option key={productType.Id} value={productType.Id}>
        {productType.Name}
      </Option>
    ));

    const destinationOptions = destinations.map((destination) => (
      <Option key={destination.Id} value={destination.Id}>
        {`${destination.Name}, ${destination.State}`}
      </Option>
    ));

    return (
      <Element name={ProductSectionKeyEnum.General} className="row">
        <div className="column title">
          {intl.get('inventory.products.popup.general')}
        </div>
        <div className="column content">
          <div className="d-f f-d-column flx-1">
            <div className="d-f jc-sb ai-fs">
              <Field
                className="m-r-5 w-65"
                name="Name"
                label={intl.get('inventory.products.popup.productName')}
                component={InputContainer}
                onMouseEnter={this.onSetHelpInstructionKey(
                  HelpInstructionKeyEnum.ProductName,
                )}
                onChange={(e, value) =>
                  this.handleGenerateProductId(DestinationId, value)
                }
              />
              <div className="d-f jc-c ai-c w-35">
                <Field
                  name="Id"
                  className="m-l-5"
                  disabled={this.isProductCodeDisabled()}
                  label={intl.get('inventory.products.popup.uniqueCode')}
                  component={InputContainer}
                  onChange={this.handleChangeProductId}
                />
                {isProductIdLoading && (
                  <Spin className="p-t-20 m-l-5" size="small" />
                )}
              </div>
            </div>
            {isSupplierSelectable && (
              <div className="d-f jс-fs ai-fs">
                <Field
                  loading={isSuppliersLoading}
                  className="p-r-5 w-65"
                  name="OperatorId"
                  label={intl.get('inventory.products.popup.supplier')}
                  component={SelectContainer}
                  dropdownRender={(menu) => (
                    <Fragment>
                      <div
                        className="p-v-4 p-h-8 cur-p"
                        onMouseDown={onSupplierPopupOpen}
                      >
                        <PlusOutlined />{' '}
                        {intl.get('inventory.products.popup.addSupplier')}
                      </div>
                      <Divider className="m-v-4" />
                      {menu}
                    </Fragment>
                  )}
                >
                  {supplierOptions}
                </Field>
              </div>
            )}
            <div className="d-f jс-fs ai-fs">
              <Field
                loading={isProductTypesLoading}
                className="p-r-5 w-65"
                name={this.getProductTypeFieldName()}
                label={intl.get('inventory.products.popup.productType')}
                component={SelectContainer}
                onMouseEnter={this.onSetHelpInstructionKey(
                  HelpInstructionKeyEnum.ProductType,
                )}
              >
                {productTypesOptions}
              </Field>
            </div>
            <div className="d-f jс-fs ai-fs">
              <Field
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                loading={isDestinationsLoading}
                className="p-r-5 w-65"
                name="DestinationId"
                label={intl.get('inventory.products.popup.destinations')}
                component={SelectContainer}
                onChange={async (value) => {
                  setFieldValue('DestinationId', value || '');

                  this.handleGenerateProductId(value, Name);
                }}
              >
                {destinationOptions}
              </Field>
            </div>

            {productDetailsType === ProductTypeEnum.Service && (
              <div className="w-65">
                <Row gutter={15}>
                  <Col span={12}>
                    <Field
                      min={0}
                      precision={0}
                      name="MinDuration"
                      label={intl.get('inventory.products.popup.minDuration')}
                      component={InputNumberContainer}
                    />
                  </Col>

                  <Col span={12}>
                    <Field
                      min={0}
                      precision={0}
                      name="MaxDuration"
                      label={intl.get('inventory.products.popup.maxDuration')}
                      component={InputNumberContainer}
                    />
                  </Col>
                </Row>
              </div>
            )}

            {productDetailsType === ProductTypeEnum.Service && (
              <div className="w-65">
                <Row gutter={15}>
                  <Col span={12}>
                    <Field
                      use12Hours
                      format="h:mm a"
                      name="DefaultTime"
                      label={intl.get('inventory.products.popup.defaultTime')}
                      component={TimePickerContainer}
                    />
                  </Col>
                </Row>
              </div>
            )}

            <div className="field d-f ai-fe cut-off-fields">
              <h4 className="field-label m-b-13 m-r-20">
                {intl.get('inventory.products.popup.defaultCutoffMins')}
              </h4>
              <div className="d-f flx-1">
                <Field
                  loading={isDestinationsLoading}
                  size="small"
                  className="p-r-5 w-20"
                  precision={0}
                  name="CutoffHours"
                  labelClassName="cut-off-label f-s-12"
                  label={intl.get('inventory.products.popup.hours')}
                  component={InputNumberContainer}
                />
              </div>
            </div>
          </div>
        </div>
      </Element>
    );
  }
}
