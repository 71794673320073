import { observable, action, computed, makeObservable } from 'mobx';
import intl from 'react-intl-universal';

import { RootStore } from './root.store';
import { CardInCartModel } from '../models/cardInCart.model';
import { AvailableCategoryModel } from '../models/tour-details/tourAvailableCategory.model';
import {
  addGlobalMessage,
  addGlobalSpinner,
  removeGlobalSpinner,
} from '../components/SharedComponents/GlobalMessages';
import {
  checkIfProductHoldActive,
  getInitialCardsInCart,
  getProductExpirationInMsByEndDate,
} from '../utils/cartUtils';
import { MessageTypeEnum } from '../models/global-messages/message.model';

const cardBasketName = 'CardBasketName';

export class CartStore {
  private rootStore: RootStore;

  @observable public cardsInCart: CardInCartModel[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
    // this.apiService = apiService;

    this.initializeCart();
  }

  @action public initializeCart() {
    try {
      const cardsInCartJson = localStorage.getItem(cardBasketName);
      const parsedCardsInCart = cardsInCartJson
        ? JSON.parse(cardsInCartJson)
        : [];

      const cardsInCart = getInitialCardsInCart(parsedCardsInCart);

      localStorage.setItem(cardBasketName, JSON.stringify(cardsInCart));

      this.cardsInCart = cardsInCart;
    } catch (err) {
      return err;
    }
  }

  @action public async addCardToCart(
    availability: AvailableCategoryModel,
    cardId?: string,
  ) {
    const { tourInfo, cancellationPolicies } = this.rootStore.tourStore;
    let holdInfo;

    try {
      const cardsInCartJson = localStorage.getItem(cardBasketName);
      const cardsInCart: CardInCartModel[] = cardsInCartJson
        ? JSON.parse(cardsInCartJson)
        : [];
      // possibility for editing
      const cardIndex = cardsInCart.findIndex((item) => item.id === cardId);

      if (tourInfo.Holdable) {
        addGlobalSpinner();

        if (
          cardIndex > -1 &&
          checkIfProductHoldActive(cardsInCart[cardIndex])
        ) {
          await this.rootStore.tourStore.cancelProductHold(
            cardsInCart[cardIndex],
          );
        }

        holdInfo = await this.rootStore.tourStore.addProductToHold(
          availability,
        );

        removeGlobalSpinner();
      }

      const card = new CardInCartModel({
        tourInfo,
        availability,
        id: cardId,
        cancellationPolicies,
        holdInfo,
      });

      this.handleExpireProductHold(card);

      if (cardIndex > -1) {
        cardsInCart[cardIndex] = card;
      } else {
        cardsInCart.push(card);
      }

      localStorage.setItem(cardBasketName, JSON.stringify(cardsInCart));
      this.cardsInCart = cardsInCart;
    } catch (err) {
      this.handleHoldError(err);

      throw err;
    }
  }

  @action public async removeCardFromCart(id: string) {
    try {
      const cardsInCartJson = localStorage.getItem(cardBasketName);
      const cardsInCart: CardInCartModel[] = cardsInCartJson
        ? JSON.parse(cardsInCartJson)
        : [];
      const cardsInCartWithoutRemoved = cardsInCart.filter(
        (card) => card.id !== id,
      );
      const removedCard = cardsInCart.find((card) => card.id === id);
      const shouldCancelHold =
        !!removedCard && checkIfProductHoldActive(removedCard);

      if (shouldCancelHold) {
        addGlobalSpinner();

        await this.rootStore.tourStore.cancelProductHold(removedCard);

        removeGlobalSpinner();
      }

      localStorage.setItem(
        cardBasketName,
        JSON.stringify(cardsInCartWithoutRemoved),
      );
      this.cardsInCart = cardsInCartWithoutRemoved;
    } catch (err) {
      this.handleHoldError(err);
    }
  }

  @action public async clearCart() {
    const { tourStore } = this.rootStore;
    const activeHeldProducts = this.cardsInCart.filter(
      checkIfProductHoldActive,
    );
    const hasActiveHeldProducts = !!activeHeldProducts.length;

    try {
      if (hasActiveHeldProducts) {
        addGlobalSpinner();

        await Promise.all(
          activeHeldProducts.map(tourStore.cancelProductHold.bind(tourStore)),
        );

        removeGlobalSpinner();
      }

      this.clearStore();
    } catch (err) {
      this.handleHoldError(err);
    }
  }

  @action public clearStore() {
    localStorage.removeItem(cardBasketName);
    this.cardsInCart = [];
  }

  @computed get countOfCardsInCart() {
    return this.cardsInCart.length;
  }

  private handleExpireProductHold(cardInCart: CardInCartModel) {
    if (!cardInCart.holdInfo) return;

    const duration = getProductExpirationInMsByEndDate(
      cardInCart.holdInfo.holdEndDate,
    );

    setTimeout(() => this.removeExpiredProduct(cardInCart), duration);
  }

  private removeExpiredProduct(cardInCart: CardInCartModel) {
    this.removeCardFromCart(cardInCart.id);

    addGlobalMessage({
      message: intl.get('globalErrors.reservationExpired', {
        product: cardInCart.tourName,
      }),
      type: MessageTypeEnum.error,
    });
  }

  private handleHoldError(err) {
    removeGlobalSpinner();

    const defaultMessage = intl.get('globalErrors.smthWentWrong');
    const apiMessage = err?.response?.data?.ModelState?.request?.Message;

    addGlobalMessage({
      message: apiMessage || defaultMessage,
      type: MessageTypeEnum.error,
    });
  }
}
