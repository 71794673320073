export class CreditCardModel {
  cardNumber: string;
  expirationMonth: number;
  expirationYear: number;
  cardCode: string;

  constructor(values?: any) {
    let cardNumber: string;
    let expirationMonth: number;
    let expirationYear: number;
    let cardCode: string;

    if (values) {
      cardNumber = values.cardNumber.split('-').join('');
      cardCode = values.cardCode;
      expirationMonth = +values.expirationDate.split('/')[0];
      expirationYear = +`20${values.expirationDate.split('/')[1]}`;
    }

    this.cardNumber = cardNumber;
    this.expirationMonth = expirationMonth;
    this.expirationYear = expirationYear;
    this.cardCode = cardCode;
  }
}
