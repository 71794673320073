import intl from 'react-intl-universal';

import { CombinationOperatorEnum } from '../models/enums/combinationOperator.enum';

export const getFeeCombinationLabel = (combination: CombinationOperatorEnum) =>
  ({
    [CombinationOperatorEnum.Both]: intl.get('commissionCombination.both'),
    [CombinationOperatorEnum.Greater]: intl.get(
      'commissionCombination.greater',
    ),
    [CombinationOperatorEnum.Less]: intl.get('commissionCombination.less'),
  }[combination] ?? '');
