import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'antd';
import { identity, isNil } from 'lodash';
import moment, { Moment } from 'moment';
import cx from 'classnames';
import * as Yup from 'yup';

import { useStore } from 'hooks';
import { OrderColumn } from 'models/orders/orderColumn.model';
import { OrderKeysEnum } from 'models/enums/orderKeys.enum';
import { OrderTableDataModel } from 'models/orders/orderTableData.model';
import { EditableCellInputEnum } from 'models/enums/editableCellInput.enum';
import { StatusEnum } from 'models/enums/status.enum';
import { OrderNotificationModel } from 'models/orderNotificationModel';
import { TABLE_ORDERS_BLANK_FILTER_LABEL } from 'utils/constants';
import { formatDozen, moneyFormat } from 'utils/formatter';
import { isAdult } from 'utils/guestsUtils';
import { parseServiceTime, toServiceTime } from 'utils/datesUtils';

import { getCustomerUnnamedLabel } from '../utils/orderTableUtils';
import { getNotificationStatusLabel } from '../utils/orderUtils';
import { makeTestTableOrdersPhoneSchema } from '../schemas';
import useTableOrdersFilteringProps from './useTableOrdersFilteringProps';

type Props = {
  hasManageOrdersAccess: boolean;
  getIsEditRecordActive: (record: OrderTableDataModel) => boolean;
  onOrderClick: (tripId: number) => void;
};

function useTableOrdersColumns(props: Props) {
  const { hasManageOrdersAccess, getIsEditRecordActive, onOrderClick } = props;

  const commonStore = useStore((store) => store.commonStore);
  const ordersStore = useStore((store) => store.ordersStore);
  const messagesStore = useStore((store) => store.messagesStore);

  const formatTime = (value: Moment) => value?.format('h:mm a');

  const formatTimeSearch = (value: Moment) =>
    value ? toServiceTime(value) : null;

  const formatTimeOption = (value: string) =>
    value === TABLE_ORDERS_BLANK_FILTER_LABEL
      ? value
      : formatTime(parseServiceTime(value));

  const getColumnName = (key: OrderKeysEnum) =>
    commonStore.getOrdersListColumnById(key.toString())?.Name;

  const getColumnAddBlankEntry = (key: OrderKeysEnum) =>
    commonStore.getOrdersListColumnById(key.toString())?.AddBlankEntry ?? false;

  const getColumnWidth = (key: OrderKeysEnum) =>
    ordersStore.getColumnWidth(key.toString());

  const getIsEditColumnEnabled = (key: string) =>
    commonStore.getIsOrdersListColumnEditable(key);

  const {
    getColumnSorterProps,
    getColumnPriceRangeProps,
    getColumnPaymentStatusProps,
    getColumnDateRangeProps,
    getColumnTimeProps,
    getColumnSearchProps,
    getColumnListProps,
    getTripIdColumnFilterProps,
  } = useTableOrdersFilteringProps();

  const columns: OrderColumn[] = [
    {
      key: OrderKeysEnum.TripId,
      title:
        getColumnName(OrderKeysEnum.TripId) ||
        intl.get('orders.table.transactionNumber'),
      dataIndex: 'TripId',
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.TripId) ?? 150,
      render: (tripId: number, record: OrderTableDataModel) => {
        const value = [tripId, formatDozen(record.TripItemId)].join('');

        return (
          <Button type="link" onClick={() => onOrderClick(tripId)}>
            {value}
          </Button>
        );
      },
      ...getColumnSorterProps(OrderKeysEnum.TripId),
      ...getTripIdColumnFilterProps(OrderKeysEnum.TripId),
    },
    {
      key: OrderKeysEnum.TripItemId,
      title:
        getColumnName(OrderKeysEnum.TripItemId) ||
        intl.get('orders.table.itemId'),
      dataIndex: 'TripItemId',
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.TripItemId) ?? 105,
      ...getColumnSorterProps(OrderKeysEnum.TripItemId),
    },
    {
      key: OrderKeysEnum.Reference,
      title:
        getColumnName(OrderKeysEnum.Reference) ||
        intl.get('orders.table.reference'),
      dataIndex: 'Reference',
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.Reference) ?? 135,
      ...getColumnSorterProps(OrderKeysEnum.Reference),
      ...getColumnSearchProps(OrderKeysEnum.Reference, {
        allowBlankEntry: getColumnAddBlankEntry(OrderKeysEnum.Reference),
      }),
    },
    {
      key: OrderKeysEnum.Name,
      title:
        getColumnName(OrderKeysEnum.Name) ||
        intl.get('orders.table.productName'),
      dataIndex: 'Name',
      align: 'left',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.Name) ?? 320,
      ...getColumnSorterProps(OrderKeysEnum.Name),
      ...getColumnListProps(OrderKeysEnum.Name, {
        allowCustomFilter: true,
      }),
    },
    {
      key: OrderKeysEnum.ProductType,
      title:
        getColumnName(OrderKeysEnum.ProductType) ||
        intl.get('orders.table.productType'),
      dataIndex: 'ProductType',
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.ProductType) ?? 135,
      ...getColumnSorterProps(OrderKeysEnum.ProductType),
      ...getColumnListProps(OrderKeysEnum.ProductType, {
        allowCustomFilter: false,
      }),
    },
    {
      key: OrderKeysEnum.ProductId,
      title:
        getColumnName(OrderKeysEnum.ProductId) ||
        intl.get('orders.table.productId'),
      dataIndex: 'ProductId',
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.ProductId) ?? 135,
      ...getColumnSorterProps(OrderKeysEnum.ProductId),
      ...getColumnListProps(OrderKeysEnum.ProductId, {
        allowCustomFilter: true,
      }),
    },
    {
      key: OrderKeysEnum.CategoryName,
      title:
        getColumnName(OrderKeysEnum.CategoryName) ||
        intl.get('orders.table.categoryName'),
      dataIndex: 'CategoryName',
      align: 'left',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.CategoryName) ?? 210,
      ...getColumnSorterProps(OrderKeysEnum.CategoryName),
      ...getColumnListProps(OrderKeysEnum.CategoryName, {
        allowCustomFilter: true,
        allowBlankEntry: getColumnAddBlankEntry(OrderKeysEnum.CategoryName),
      }),
    },
    {
      key: OrderKeysEnum.CategoryId,
      title:
        getColumnName(OrderKeysEnum.CategoryId) ||
        intl.get('orders.table.categoryId'),
      dataIndex: 'CategoryId',
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.CategoryId) ?? 135,
      ...getColumnSorterProps(OrderKeysEnum.CategoryId),
      ...getColumnListProps(OrderKeysEnum.CategoryId, {
        allowCustomFilter: true,
        allowBlankEntry: getColumnAddBlankEntry(OrderKeysEnum.CategoryId),
      }),
    },
    {
      key: OrderKeysEnum.PrimaryEmail,
      title:
        getColumnName(OrderKeysEnum.PrimaryEmail) ||
        intl.get('orders.table.email'),
      dataIndex: 'PrimaryEmail',
      render: (value: string) => value || <span>&nbsp;</span>,
      align: 'left',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.PrimaryEmail) ?? 270,
      edit: (key, record) => ({
        isActive: getIsEditRecordActive(record),
        isEnabled:
          getIsEditColumnEnabled(key) &&
          (!record.parent || isAdult(record.guest?.Age)),
        getValue: identity,
        validation: Yup.string().email(),
      }),
      ...getColumnSorterProps(OrderKeysEnum.PrimaryEmail),
      ...getColumnSearchProps(OrderKeysEnum.PrimaryEmail, {
        allowBlankEntry: getColumnAddBlankEntry(OrderKeysEnum.PrimaryEmail),
      }),
    },
    {
      key: OrderKeysEnum.PrimaryPhone,
      title:
        getColumnName(OrderKeysEnum.PrimaryPhone) ||
        intl.get('orders.table.phone'),
      dataIndex: 'PrimaryPhone',
      render: (value: string) => value || <span>&nbsp;</span>,
      align: 'left',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.PrimaryPhone) ?? 275,
      edit: (key, record) => ({
        isActive: getIsEditRecordActive(record),
        isEnabled:
          getIsEditColumnEnabled(key) &&
          (!record.parent || isAdult(record.guest?.Age)),
        getValue: identity,
        validation: Yup.string().test(
          'validatePhoneNumber',
          intl.get('invalidPhoneNumber'),
          makeTestTableOrdersPhoneSchema(messagesStore),
        ),
        inputProps: {
          inputType: EditableCellInputEnum.Phone,
        },
      }),
      ...getColumnSorterProps(OrderKeysEnum.PrimaryPhone),
      ...getColumnSearchProps(OrderKeysEnum.PrimaryPhone, {
        allowBlankEntry: getColumnAddBlankEntry(OrderKeysEnum.PrimaryPhone),
      }),
    },
    {
      key: OrderKeysEnum.PrimaryGuestName,
      title:
        getColumnName(OrderKeysEnum.PrimaryGuestName) ||
        intl.get('orders.table.customer'),
      dataIndex: 'PrimaryGuestName',
      align: 'center',
      draggable: true,
      resizable: true,
      render: (name: string, record: OrderTableDataModel) => (
        <span className={cx({ 'not-primary-text': !name })}>
          {name || getCustomerUnnamedLabel(record)}
        </span>
      ),
      width: getColumnWidth(OrderKeysEnum.PrimaryGuestName) ?? 210,
      edit: (key, record) => ({
        isActive: getIsEditRecordActive(record),
        isEnabled: getIsEditColumnEnabled(key),
        getValue: identity,
        validation: Yup.string()
          .matches(/^[\w'-]+ [\w'-]+$/, intl.get('invalidCustomerFormat'))
          .concat(record.parent ? null : Yup.string().required('required')),
        inputProps: {
          inputType: EditableCellInputEnum.Text,
          placeholder: getCustomerUnnamedLabel(record),
        },
      }),
      ...getColumnSorterProps(OrderKeysEnum.PrimaryGuestName),
      ...getColumnSearchProps(OrderKeysEnum.PrimaryGuestName, {
        allowBlankEntry: getColumnAddBlankEntry(OrderKeysEnum.PrimaryGuestName),
      }),
    },
    {
      key: OrderKeysEnum.StartDate,
      title:
        getColumnName(OrderKeysEnum.StartDate) ||
        intl.get('orders.table.serviceDate'),
      dataIndex: 'StartDate',
      render: (date: string) =>
        date ? moment(date).format('MMM D, YYYY') : null,
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.StartDate) ?? 150,
      ...getColumnSorterProps(OrderKeysEnum.StartDate),
      ...getColumnDateRangeProps(OrderKeysEnum.StartDate, {
        format: 'MMM DD, YYYY',
      }),
    },
    {
      key: OrderKeysEnum.PassengerCount,
      title:
        getColumnName(OrderKeysEnum.PassengerCount) ||
        intl.get('orders.table.guests'),
      dataIndex: 'PassengerCount',
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.PassengerCount) ?? 110,
      ...getColumnSorterProps(OrderKeysEnum.PassengerCount),
      ...getColumnSearchProps(OrderKeysEnum.PassengerCount, {
        inputProps: { type: 'number' },
      }),
    },
    {
      key: OrderKeysEnum.Nights,
      title:
        getColumnName(OrderKeysEnum.Nights) || intl.get('orders.table.nights'),
      dataIndex: 'Nights',
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.Nights) ?? 110,
      ...getColumnSorterProps(OrderKeysEnum.Nights),
      ...getColumnSearchProps(OrderKeysEnum.Nights, {
        inputProps: { type: 'number' },
      }),
    },
    {
      key: OrderKeysEnum.AgencyId,
      title:
        getColumnName(OrderKeysEnum.AgencyId) ||
        intl.get('orders.table.agencyId'),
      dataIndex: 'AgencyId',
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.AgencyId) ?? 135,
      ...getColumnSorterProps(OrderKeysEnum.AgencyId),
      ...getColumnListProps(OrderKeysEnum.AgencyId, {
        allowCustomFilter: true,
      }),
    },
    {
      key: OrderKeysEnum.AgencyName,
      title:
        getColumnName(OrderKeysEnum.AgencyName) ||
        intl.get('orders.table.agencyName'),
      dataIndex: 'AgencyName',
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.AgencyName) ?? 250,
      ...getColumnSorterProps(OrderKeysEnum.AgencyName),
      ...getColumnListProps(OrderKeysEnum.AgencyName, {
        allowCustomFilter: true,
      }),
    },
    {
      key: OrderKeysEnum.ServiceTime,
      title:
        getColumnName(OrderKeysEnum.ServiceTime) ||
        intl.get('orders.table.serviceTime'),
      dataIndex: 'ServiceTime',
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.ServiceTime) ?? 150,
      render: (value: string) =>
        value ? formatTime(parseServiceTime(value)) : null,
      ...getColumnTimeProps(OrderKeysEnum.ServiceTime, {
        formatOption: formatTimeOption,
        formatSearch: formatTimeSearch,
        inputProps: { use12Hours: true, format: 'h:mm a' },
        allowCustomFilter: true,
        allowBlankEntry: getColumnAddBlankEntry(OrderKeysEnum.ServiceTime),
      }),
      ...getColumnSorterProps(OrderKeysEnum.ServiceTime),
    },
    {
      key: OrderKeysEnum.OperatorId,
      title:
        getColumnName(OrderKeysEnum.OperatorId) ||
        intl.get('orders.table.operatorId'),
      dataIndex: 'OperatorId',
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.OperatorId) ?? 150,
      ...getColumnSorterProps(OrderKeysEnum.OperatorId),
      ...getColumnListProps(OrderKeysEnum.OperatorId, {
        allowCustomFilter: true,
        allowBlankEntry: getColumnAddBlankEntry(OrderKeysEnum.OperatorId),
      }),
    },
    {
      key: OrderKeysEnum.OperatorName,
      title:
        getColumnName(OrderKeysEnum.OperatorName) ||
        intl.get('orders.table.operatorName'),
      dataIndex: 'OperatorName',
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.OperatorName) ?? 300,
      ...getColumnSorterProps(OrderKeysEnum.OperatorName),
      ...getColumnListProps(OrderKeysEnum.OperatorName, {
        allowCustomFilter: true,
        allowBlankEntry: getColumnAddBlankEntry(OrderKeysEnum.OperatorName),
      }),
    },
    {
      key: OrderKeysEnum.Price,
      title:
        getColumnName(OrderKeysEnum.Price) || intl.get('orders.table.price'),
      dataIndex: 'Price',
      render: (price: number) => (isNil(price) ? null : moneyFormat(price)),
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.Price) ?? 110,
      ...getColumnSorterProps(OrderKeysEnum.Price),
      ...getColumnPriceRangeProps(OrderKeysEnum.Price),
    },
    {
      key: OrderKeysEnum.Cost,
      title: getColumnName(OrderKeysEnum.Cost) || intl.get('orders.table.cost'),
      dataIndex: 'Cost',
      render: (price: number) => (isNil(price) ? null : moneyFormat(price)),
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.Cost) ?? 110,
      ...getColumnSorterProps(OrderKeysEnum.Cost),
      ...getColumnPriceRangeProps(OrderKeysEnum.Cost),
    },
    {
      key: OrderKeysEnum.Confirmation,
      title:
        getColumnName(OrderKeysEnum.Confirmation) ||
        intl.get('orders.table.confirmation'),
      dataIndex: 'Confirmation',
      align: 'center',
      draggable: true,
      resizable: true,
      edit: (key, record) => ({
        isActive: getIsEditRecordActive(record),
        isEnabled: !record.parent && getIsEditColumnEnabled(key),
        getValue: identity,
        validation: Yup.string().max(15).required('required'),
      }),
      width: getColumnWidth(OrderKeysEnum.Confirmation) ?? 150,
      ...getColumnSorterProps(OrderKeysEnum.Confirmation),
      ...getColumnSearchProps(OrderKeysEnum.Confirmation, {
        allowBlankEntry: getColumnAddBlankEntry(OrderKeysEnum.Confirmation),
      }),
    },
    {
      key: OrderKeysEnum.Inactive,
      title:
        getColumnName(OrderKeysEnum.Inactive) ||
        intl.get('orders.table.paymentStatus'),
      dataIndex: 'Inactive',
      render: (inactive: boolean) =>
        isNil(inactive) ||
        (inactive ? StatusEnum.cancelled : StatusEnum.active),
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.Inactive) ?? 110,
      align: 'center',
      ...getColumnSorterProps(OrderKeysEnum.Inactive),
      ...getColumnPaymentStatusProps(OrderKeysEnum.Inactive),
    },
    {
      key: OrderKeysEnum.CreatedOn,
      title:
        getColumnName(OrderKeysEnum.CreatedOn) ||
        intl.get('orders.table.createdOn'),
      dataIndex: 'CreatedOn',
      render: (date: string) =>
        date ? moment(date).format('YYYY-MM-DD') : null,
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.CreatedOn) ?? 150,
      ...getColumnSorterProps(OrderKeysEnum.CreatedOn),
      ...getColumnDateRangeProps(OrderKeysEnum.CreatedOn, {
        format: 'YYYY-MM-DD',
      }),
    },
    {
      key: OrderKeysEnum.PickupPointLocationType,
      title:
        getColumnName(OrderKeysEnum.PickupPointLocationType) ||
        intl.get('orders.table.pickupPointLocationType'),
      dataIndex: ['PickupPoint', 'LocationType'],
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.PickupPointLocationType) ?? 150,
      ...getColumnSorterProps(OrderKeysEnum.PickupPointLocationType),
      ...getColumnListProps(OrderKeysEnum.PickupPointLocationType, {
        allowCustomFilter: false,
      }),
    },
    {
      key: OrderKeysEnum.PickupPointLocationId,
      title:
        getColumnName(OrderKeysEnum.PickupPointLocationId) ||
        intl.get('orders.table.pickupPointLocationId'),
      dataIndex: ['PickupPoint', 'LocationId'],
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.PickupPointLocationId) ?? 200,
      ...getColumnSorterProps(OrderKeysEnum.PickupPointLocationId),
      ...getColumnListProps(OrderKeysEnum.PickupPointLocationId, {
        allowCustomFilter: true,
        allowBlankEntry: getColumnAddBlankEntry(
          OrderKeysEnum.PickupPointLocationId,
        ),
      }),
    },
    {
      key: OrderKeysEnum.PickupPointName,
      title:
        getColumnName(OrderKeysEnum.PickupPointName) ||
        intl.get('orders.table.pickupPoint'),
      dataIndex: ['PickupPoint', 'Name'],
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.PickupPointName) ?? 200,
      ...getColumnSorterProps(OrderKeysEnum.PickupPointName),
      ...getColumnListProps(OrderKeysEnum.PickupPointName, {
        allowCustomFilter: true,
        allowBlankEntry: getColumnAddBlankEntry(OrderKeysEnum.PickupPointName),
      }),
    },
    {
      key: OrderKeysEnum.PickupPointStartTime,
      title:
        getColumnName(OrderKeysEnum.PickupPointStartTime) ||
        intl.get('orders.table.pickupPointStartTime'),
      dataIndex: ['PickupPoint', 'PickupDateTime'],
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.PickupPointStartTime) ?? 150,
      render: (value: string) => (value ? formatTime(moment(value)) : null),
      ...getColumnSorterProps(OrderKeysEnum.PickupPointStartTime),
      ...getColumnTimeProps(OrderKeysEnum.PickupPointStartTime, {
        formatOption: formatTimeOption,
        formatSearch: formatTimeSearch,
        inputProps: { use12Hours: true, format: 'h:mm a' },
        allowCustomFilter: true,
        allowBlankEntry: getColumnAddBlankEntry(
          OrderKeysEnum.PickupPointStartTime,
        ),
      }),
    },
    {
      key: OrderKeysEnum.DropoffPointLocationType,
      title:
        getColumnName(OrderKeysEnum.DropoffPointLocationType) ||
        intl.get('orders.table.dropoffPointLocationType'),
      dataIndex: ['DropoffPoint', 'LocationType'],
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.DropoffPointLocationType) ?? 150,
      ...getColumnSorterProps(OrderKeysEnum.DropoffPointLocationType),
      ...getColumnListProps(OrderKeysEnum.DropoffPointLocationType, {
        allowCustomFilter: false,
      }),
    },
    {
      key: OrderKeysEnum.DropoffPointLocationId,
      title:
        getColumnName(OrderKeysEnum.DropoffPointLocationId) ||
        intl.get('orders.table.dropoffPointLocationId'),
      dataIndex: ['DropoffPoint', 'LocationId'],
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.DropoffPointLocationId) ?? 150,
      ...getColumnSorterProps(OrderKeysEnum.DropoffPointLocationId),
      ...getColumnListProps(OrderKeysEnum.DropoffPointLocationId, {
        allowCustomFilter: true,
        allowBlankEntry: getColumnAddBlankEntry(
          OrderKeysEnum.DropoffPointLocationId,
        ),
      }),
    },
    {
      key: OrderKeysEnum.DropoffPointName,
      title:
        getColumnName(OrderKeysEnum.DropoffPointName) ||
        intl.get('orders.table.dropoffPointName'),
      dataIndex: ['DropoffPoint', 'Name'],
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.DropoffPointName) ?? 200,
      ...getColumnSorterProps(OrderKeysEnum.DropoffPointName),
      ...getColumnListProps(OrderKeysEnum.DropoffPointName, {
        allowCustomFilter: true,
        allowBlankEntry: getColumnAddBlankEntry(OrderKeysEnum.DropoffPointName),
      }),
    },
    {
      key: OrderKeysEnum.TestMode,
      title:
        getColumnName(OrderKeysEnum.TestMode) ||
        intl.get('orders.table.testOrder'),
      dataIndex: 'TestMode',
      render: (testMode: boolean) =>
        testMode
          ? intl.get('orders.table.testOrder')
          : intl.get('orders.table.liveOrder'),
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.TestMode) ?? 150,
      ...getColumnSorterProps(OrderKeysEnum.TestMode),
      ...getColumnListProps(OrderKeysEnum.TestMode, {
        allowCustomFilter: false,
      }),
    },
    hasManageOrdersAccess && {
      key: OrderKeysEnum.CommunicationStatus,
      title:
        getColumnName(OrderKeysEnum.CommunicationStatus) ||
        intl.get('orders.table.communicationStatus'),
      dataIndex: ['Communications', 'NotificationCurrentStatusEnumerable', '0'],
      render: (orderNotification: OrderNotificationModel) =>
        getNotificationStatusLabel(orderNotification?.Status),
      align: 'center',
      draggable: true,
      resizable: true,
      width: getColumnWidth(OrderKeysEnum.CommunicationStatus) ?? 200,
      ...getColumnSorterProps(OrderKeysEnum.CommunicationStatus),
      ...getColumnListProps(OrderKeysEnum.CommunicationStatus, {
        allowCustomFilter: false,
      }),
    },
  ];

  return columns;
}

export default useTableOrdersColumns;
