import React from 'react';
import intl from 'react-intl-universal';

import { ColumnProps } from 'antd/lib/table';
import { stringSort } from '../../../../utils/formatter';

export const getAgencyColumns = (): ColumnProps<any>[] => [
  {
    title: intl.get('contacts.table.name'),
    dataIndex: 'Name',
    render: (value: string) => <div className="bold">{value}</div>,
    align: 'left',
    sorter: stringSort('Name'),
  },
  {
    title: intl.get('contacts.table.contactPerson'),
    dataIndex: 'PrimaryContact',
    render: (value: string) => <div className="bold">{value}</div>,
    align: 'left',
    sorter: stringSort('PrimaryContact'),
  },
  {
    title: intl.get('contacts.table.email'),
    dataIndex: 'Email',
    render: (value: string) => <div className="bold">{value}</div>,
    align: 'left',
    sorter: stringSort('Email'),
  },
  {
    title: intl.get('contacts.table.phone'),
    dataIndex: 'Phone',
    render: (value: string) => <div className="bold">{value}</div>,
    align: 'left',
    sorter: stringSort('Phone'),
  },
];
