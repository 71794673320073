import React from 'react';
import { Spin } from 'antd';

export default function AvailabilitiesLoader() {
  return (
    <div className="category-options">
      <div className="no-available-products">
        <Spin className="availabilities-loading" />
      </div>
    </div>
  );
}
