import React, { Component } from 'react';
import { Input } from 'antd';
import intl from 'react-intl-universal';
import { debounce } from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import { reaction } from 'mobx';

import { MessageTemplatesStore } from '../../../../stores/messageTemplates.store';
import { withStore } from '../../../../hocs';

type Props = {
  messageTemplatesStore?: MessageTemplatesStore;
  onChange?: () => void;
};

@withStore(({ rootStore }) => ({
  messageTemplatesStore: rootStore.messageTemplatesStore,
}))
export default class MessageTemplatesSearch extends Component<Props> {
  searchQueryDisposer = null;

  state = {
    searchQuery: '',
  };

  componentDidMount() {
    const { messageTemplatesStore, onChange } = this.props;

    this.searchQueryDisposer = reaction(
      () => messageTemplatesStore.searchQuery,
      () => onChange?.(),
    );
  }

  componentWillUnmount() {
    this.searchQueryDisposer();
  }

  onSetSearchQuery = (value: string) => {
    const { messageTemplatesStore } = this.props;

    messageTemplatesStore.changeSearchQuery(value);
  };

  // eslint-disable-next-line react/sort-comp
  debounceSetSearchQuery = debounce(this.onSetSearchQuery, 500);

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value;

    this.setState({ searchQuery });

    this.debounceSetSearchQuery(searchQuery);
  };

  render() {
    const { searchQuery } = this.state;

    return (
      <Input
        allowClear
        prefix={<SearchOutlined className="search-icon" />}
        placeholder={intl.get('messageTemplates.searchPlaceholder')}
        value={searchQuery}
        onChange={this.handleChange}
      />
    );
  }
}
