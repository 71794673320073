/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Button, Select } from 'antd';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { SelectContainer } from '../../FormContainers';

const childOptions = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
];

export default class TravelersSelect extends Component<{ onSubmit: Function }> {
  handleAdultsChange = (
    setFieldValue: Function,
    values: any,
    plus: boolean,
  ) => {
    let value = values.adults;

    if (value === 1 && !plus) {
      return;
    }

    setFieldValue('adults', plus ? ++value : --value);
  };

  handleChildrenChange = (
    setFieldValue: Function,
    values: any,
    plus: boolean,
  ) => {
    let { childrenCount } = values;
    const { children } = values;

    if (childrenCount === 0 && !plus) {
      return;
    }

    if (plus) {
      childrenCount++;
      children.push(undefined);
    } else {
      childrenCount--;
      children.pop();
    }

    setFieldValue('childrenCount', childrenCount);
    setFieldValue('children', children);
  };

  handleSubmit = (values: any) => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  handleOnSelectChange = (setFieldValueFunc, fieldName) => (value) =>
    setFieldValueFunc(fieldName, value);

  renderChildOptions = () =>
    childOptions.map((option) => (
      <Select.Option key={option} value={option}>
        {option !== '0' ? option : '< 1'}
      </Select.Option>
    ));

  render() {
    return (
      <div className="travelers-selection">
        <Formik
          initialValues={{
            adults: 1,
            childrenCount: 0,
            children: [],
          }}
          validationSchema={Yup.object().shape({
            adults: Yup.number()
              .required(intl.get('required'))
              .min(1, intl.get('required')),
            childrenCount: Yup.number(),
            children: Yup.array().of(
              Yup.string().required(intl.get('required')),
            ),
          })}
          validateOnChange
          onSubmit={this.handleSubmit}
        >
          {({ values, setFieldValue, errors, submitForm }) => (
            <Form>
              <div className="travelers-line">
                <div className="label">{intl.get('adult')}</div>
                <div className="controls">
                  <button
                    className="count-button"
                    type="button"
                    onClick={() =>
                      this.handleAdultsChange(setFieldValue, values, false)
                    }
                  >
                    −
                  </button>
                  <div className="count-input">{values.adults}</div>
                  <button
                    className="count-button"
                    type="button"
                    onClick={() =>
                      this.handleAdultsChange(setFieldValue, values, true)
                    }
                  >
                    +
                  </button>
                </div>
              </div>
              {errors.adults && (
                <div className="field">
                  <div className="field-error">{errors.adults}</div>
                </div>
              )}
              <div className="travelers-line">
                <div className="label">{intl.get('child')}</div>
                <div className="controls">
                  <button
                    className="count-button"
                    type="button"
                    onClick={() =>
                      this.handleChildrenChange(setFieldValue, values, false)
                    }
                  >
                    −
                  </button>
                  <div className="count-input">{values.childrenCount}</div>
                  <button
                    className="count-button"
                    type="button"
                    onClick={() =>
                      this.handleChildrenChange(setFieldValue, values, true)
                    }
                  >
                    +
                  </button>
                </div>
              </div>
              <FieldArray
                name="children"
                render={() =>
                  values.children.map((age: number, index: number) => (
                    <div className="travelers-line" key={index}>
                      <div className="sub-label">{intl.get('age')}</div>
                      <div className="age-input">
                        <Field
                          name={`children.${index}`}
                          component={SelectContainer}
                          // eslint-disable-next-line react/no-children-prop
                          children={this.renderChildOptions()}
                          onChange={this.handleOnSelectChange(
                            setFieldValue,
                            `children.${index}`,
                          )}
                          suffixIcon={<i className="tc-dropdown" />}
                        />
                      </div>
                    </div>
                  ))
                }
              />
              <div className="apply-button">
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => submitForm()}
                >
                  {intl.get('buttons.apply')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
