import { Moment } from 'moment';
import { uniqBy } from 'lodash';
import { CardInCartModel } from '../models/cardInCart.model';
import { LocationTypesEnum } from '../models/enums/locationTypes.enum';
import { maxPriceDefault, minPriceDefault } from '../stores/marketplace.store';

// checkout store
export const computeMinNumberOfTravelers = (cards: CardInCartModel[]) => {
  // computing should be performed included for any types and ages
  // any types should create new traveler form
  // any different ages should create new traveler form
  let minDistinctTravelers = 0;
  const allGuests = [];

  cards.forEach((card) => {
    allGuests.push(...card.product.Guests);

    if (card.product.Guests.length > minDistinctTravelers) {
      minDistinctTravelers = card.product.Guests.length;
    }
  });

  const uniqGuestsByAge = uniqBy(allGuests, 'Age');

  if (uniqGuestsByAge.length > minDistinctTravelers) {
    minDistinctTravelers = uniqGuestsByAge.length;
  }

  return minDistinctTravelers;
};

export const computeMaxNumberOfTravelers = (cards: CardInCartModel[]) => {
  let maxDistinctTravelers = 0;
  cards.forEach((card) => {
    maxDistinctTravelers += card.product.Guests.length;
  });

  return maxDistinctTravelers;
};
//

export const normalizeDate = (date: Moment): Moment =>
  date.hour(0).minute(0).second(0).millisecond(0);

// created for marketplace apis
export const getOrderByStringBySortType = (sortType: string) => {
  switch (sortType) {
    case 'default': {
      // last Dean demand: Make “Travelers review count (high to low)” as default sort order.
      // But feature will still work like before, be at the front among all others.
      return 'UserReviews desc';
    }
    case 'rating': {
      return 'UserRating desc';
    }
    case 'ratingCount': {
      return 'UserReviews desc';
    }
    case 'priceHighToLow': {
      return 'StartingAgentNet desc';
    }
    case 'priceLowToHigh': {
      return 'StartingAgentNet';
    }
    default: {
      return null;
    }
  }
};

export const getFilterForLocation = (code: string, type: LocationTypesEnum) => {
  let filterType: string;

  switch (type) {
    case LocationTypesEnum.service: {
      filterType = 'Product_Code';
      break;
    }
    case LocationTypesEnum.region: {
      filterType = 'Region_Code';
      break;
    }
    case LocationTypesEnum.country: {
      filterType = 'Country_Code';
      break;
    }
    case LocationTypesEnum.state: {
      filterType = 'State_Code';
      break;
    }
    case LocationTypesEnum.city: {
      filterType = 'City_Code';
      break;
    }
  }

  return `${filterType} eq '${code}'`;
};

export const getFilterFromTourFilters = (options: {
  minPrice: number;
  maxPrice: number;
}) => {
  const { minPrice, maxPrice } = options;
  const filters = [];

  if (minPrice !== minPriceDefault || maxPrice !== maxPriceDefault) {
    const priceFilter =
      maxPrice !== maxPriceDefault
        ? `(StartingAgentNet ge ${minPrice} and StartingAgentNet le ${maxPrice})`
        : `(StartingAgentNet ge ${minPrice})`;
    filters.push(priceFilter);
  }

  return filters;
};
