import React, { Component, Fragment } from 'react';
import intl from 'react-intl-universal';
import pluralize from 'pluralize';
import { Button } from 'antd';

import { CheckoutStore } from '../../../stores/checkout.store';
import { UserStore } from '../../../stores/user.store';

import PaymentOptions from './components/PaymentOptions';
import TotalPayment from './components/TotalPayment';
import NumberOfTravelersAndForms from './components/NumberOfTravelersAndForms';
import CardInCheckout from './components/CardInCheckout';
import { CardInCartModel } from '../../../models/cardInCart.model';
import ReferenceNumber from './components/ReferenceNumber';
import PolicyAndTerms from './components/PolicyAndTerms';
import { CartStore } from '../../../stores/cart.store';
import AfterOrderSubmit from './components/AfterOrderSubmit';
import ProductsHoldTimer from '../../SharedComponents/ProductsHoldTimer';
import { withStore } from '../../../hocs';

@withStore(({ rootStore }) => ({
  userStore: rootStore.userStore,
  checkoutStore: rootStore.checkoutStore,
  cartStore: rootStore.cartStore,
}))
export default class Checkout extends Component<{
  checkoutStore?: CheckoutStore;
  userStore?: UserStore;
  cartStore?: CartStore;
  isPrefilled?: boolean;
  closePopup?: any;
  afterSubmit?: Function;
}> {
  state = {
    isSuccess: false,
    orderId: null,
  };

  componentDidMount() {
    this.loadFinanceInfo();
  }

  componentWillUnmount() {
    const { checkoutStore } = this.props;

    checkoutStore.clearStore();
  }

  loadFinanceInfo = async () => {
    const { userStore } = this.props;
    const { userProperties } = userStore;

    await userStore.getFinanceInfo(userProperties.Agy_No);
  };

  handleSubmitCheckout = () => {
    const { checkoutStore, cartStore, isPrefilled, afterSubmit, userStore } =
      this.props;

    checkoutStore.validateForm();
    if (checkoutStore.isCheckoutValid() && !userStore.userProperties.ReadOnly) {
      checkoutStore.submitCheckout(isPrefilled).then((data) => {
        const { Passengers } = data;
        const emails = Passengers.map(({ Email }) => Email).filter(
          (email) => Boolean(email) && Boolean(email.trim()),
        );

        if (emails && emails.length) {
          userStore.sendEmailInvoice(data.TripId, emails);
          userStore.sendEmailVoucher(data.TripId, emails);
        }

        cartStore.clearStore();
        checkoutStore.clearStore();
        afterSubmit && afterSubmit();
        this.setState({
          isSuccess: true,
          orderId: data.TripId,
        });
      });
    }
  };

  handleClosePopup = () => {
    this.props.closePopup();
  };

  renderHeader = (cardsLength) => (
    <Fragment>
      <Button className="back-button" onClick={this.handleClosePopup}>
        <i className="icon tc-arrow-left" />
        {intl.get('buttons.back')}
      </Button>
      <div className="title">
        {intl.get('checkout.checkout')}
        <span className="items-count">
          ({pluralize(intl.get('checkout.item'), cardsLength, true)})
        </span>
      </div>
    </Fragment>
  );

  renderCartItems = (cards: CardInCartModel[], isPrefilled: boolean) => (
    <div className="checkout-items">
      {cards.map((card) => (
        <CardInCheckout
          key={`${card.id}`}
          card={card}
          isPrefilled={isPrefilled}
        />
      ))}
    </div>
  );

  render() {
    const {
      checkoutStore: { cardsToCheckout },
      isPrefilled,
      userStore: { userProperties },
    } = this.props;
    const { isSuccess, orderId } = this.state;
    const isSubmitDisable = !userProperties || userProperties.ReadOnly;

    return !isSuccess ? (
      <div className="checkout-page">
        <div className="header">
          {this.renderHeader(cardsToCheckout.length)}
        </div>
        <div className="body">
          <div className="checkout-main-section">
            <NumberOfTravelersAndForms isPrefilled={isPrefilled} />
            {this.renderCartItems(cardsToCheckout, isPrefilled)}
            <PaymentOptions isPrefilled={isPrefilled} />
            <div className="policy-and-terms-and-reference">
              <PolicyAndTerms />
              <div className="reference-number-container">
                <ReferenceNumber />
              </div>
            </div>
          </div>
          <aside className="checkout-sidebar">
            <div>
              <TotalPayment />

              <ProductsHoldTimer
                cardsInCart={cardsToCheckout}
                timerWrapperClassName="m-t-15"
              />
            </div>

            <div className="checkout-submit-btn">
              <Button
                onClick={this.handleSubmitCheckout}
                disabled={isSubmitDisable}
              >
                {intl.get('checkout.submitCheckboxButton')}
              </Button>
            </div>
          </aside>
        </div>
      </div>
    ) : (
      <AfterOrderSubmit orderId={orderId} />
    );
  }
}
