import React from 'react';
import { Button, Col, Row, Space } from 'antd';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';

import { withStore } from '../../../hocs';
import { CommonStore } from '../../../stores/common.store';

type Props = {
  commonStore?: CommonStore;
};

function LandingHeader(props: Props) {
  const {
    commonStore: {
      siteSettings: { brandingSettings },
    },
  } = props;

  return (
    <header className="landing-header">
      <div className="container-huge">
        <Row align="middle" justify="space-between">
          {brandingSettings.portalLogo && brandingSettings.portalName && (
            <Col>
              <Link to="/" className="landing-logo">
                {brandingSettings.portalLogo && (
                  <img
                    className="landing-logo__img"
                    src={brandingSettings.portalLogo}
                    alt={brandingSettings.portalLogoCaption}
                  />
                )}

                {brandingSettings.portalName && (
                  <span className="landing-logo__name">
                    {brandingSettings.portalName}
                  </span>
                )}
              </Link>
            </Col>
          )}

          <Col>
            <Link to="/login">
              <Button type="primary" size="large" className="secondary-button">
                <Space size={18}>
                  <i className="tc-user-add f-s-18" />

                  {intl.get('login')}
                </Space>
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
    </header>
  );
}

export default withStore(({ rootStore }) => ({
  commonStore: rootStore.commonStore,
}))(LandingHeader);
