import React from 'react';

import { withStore } from 'hocs';
import { CommonStore } from 'stores/common.store';
import SupplierRegistrationModal from 'components/SharedComponents/SupplierRegistrationModal';
import { useQueryParamModal } from 'hooks/useQueryParamModal';
import LandingHeader from './LandingHeader';
import LandingTopHeader from './LandingTopHeader';
import LandingBanner from './LandingBanner';
import LandingAdvantages from './LandingAdvantages';
import LandingHowItWorks from './LandingHowItWorks';
import LandingFaq from './LandingFaq';
import Footer from '../../SharedComponents/Footer';

type Props = {
  commonStore?: CommonStore;
};

function Landing(props: Props) {
  const { commonStore } = props;
  const { agentRegistration } = commonStore.siteSettings;

  const queryParamModalHelper = useQueryParamModal(agentRegistration?.LinkUrl);

  const onSignUpClick = agentRegistration?.LinkUrl
    ? queryParamModalHelper.open
    : null;

  return (
    <>
      <LandingTopHeader onSignUpClick={onSignUpClick} />

      <LandingHeader />

      <LandingBanner onSignUpClick={onSignUpClick} />

      <LandingAdvantages />

      <LandingHowItWorks />

      <LandingFaq />

      <Footer />

      {agentRegistration?.FormUrl && (
        <SupplierRegistrationModal
          url={agentRegistration.FormUrl}
          open={queryParamModalHelper.isOpen()}
          onCancel={queryParamModalHelper.close}
        />
      )}
    </>
  );
}

export default withStore(({ rootStore }) => ({
  commonStore: rootStore.commonStore,
}))(Landing);
