import * as moment from 'moment';
import { DateRange, extendMoment } from 'moment-range';

export const momentRange = extendMoment(moment);

export const getDatesFromRange = (
  range: DateRange,
  predicate: (date: moment.Moment) => boolean = () => true,
) =>
  range
    ? Array.from(range.by('day')).filter(predicate)
    : ([] as moment.Moment[]);
