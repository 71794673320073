import React, { Component } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Spin, Table, Button } from 'antd';

import { AgenciesStore } from '../../../stores/agencies.store';
import { SuppliersStore } from '../../../stores/suppliers.store';
import MainPopup from '../../SharedComponents/MainPopup';
import { getAgencyColumns } from './components/agency-columns';
import { getSupplierColumns } from './components/supplier-columns';
import {
  renderAgencyPopupBody,
  getAgencyInitialValues,
  getAgencyValidation,
} from './components/AgencyPopup';
import {
  renderSupplierPopupBody,
  getSupplierInitialValues,
  getSupplierValidation,
} from './components/SupplierPopup';
import { withStore } from '../../../hocs';

const CONTACT_TYPES = {
  AGENCIES: 'agencies',
  SUPPLIERS: 'suppliers',
};

@withStore(({ rootStore }) => ({
  agenciesStore: rootStore.agenciesStore,
  suppliersStore: rootStore.suppliersStore,
}))
export default class Contacts extends Component<{
  agenciesStore?: AgenciesStore;
  suppliersStore?: SuppliersStore;
}> {
  state = {
    currentTab: CONTACT_TYPES.AGENCIES,
    currentPagination: 1,
    isContactPopupOpened: false,
    currentContact: undefined,
  };

  componentDidMount() {
    const { agenciesStore } = this.props;

    agenciesStore.getAgencies();
  }

  getAgencyPopupData = () => {
    const {
      agenciesStore: { agencies, isAgenciesLoading },
    } = this.props;
    const { currentContact } = this.state;

    return {
      columns: getAgencyColumns(),
      dataSource: agencies,
      loading: isAgenciesLoading && !agencies.length,
      newContactButtonLabel: intl.get('buttons.newAgency'),
      popupTitle: currentContact
        ? intl.get('contacts.popup.editAgency')
        : intl.get('contacts.popup.newAgency'),
      submitTitle: currentContact
        ? intl.get('buttons.apply')
        : intl.get('buttons.createAgency'),
      initialValues: getAgencyInitialValues(currentContact),
      validation: getAgencyValidation(),
    };
  };

  getSupplierPopupData = () => {
    const {
      suppliersStore: { suppliers, isSuppliersLoading },
    } = this.props;
    const { currentContact } = this.state;

    return {
      columns: getSupplierColumns(),
      dataSource: suppliers,
      loading: isSuppliersLoading && !suppliers.length,
      newContactButtonLabel: intl.get('buttons.newSupplier'),
      popupTitle: currentContact
        ? intl.get('contacts.popup.editSupplier')
        : intl.get('contacts.popup.newSupplier'),
      submitTitle: currentContact
        ? intl.get('buttons.apply')
        : intl.get('buttons.createSupplier'),
      initialValues: getSupplierInitialValues(currentContact),
      validation: getSupplierValidation(),
    };
  };

  handleSwitchTab = (tab) => {
    const { agenciesStore, suppliersStore } = this.props;
    const { currentTab } = this.state;

    if (currentTab === tab) {
      return;
    }

    switch (tab) {
      case CONTACT_TYPES.AGENCIES:
        agenciesStore.getAgencies();
        break;
      case CONTACT_TYPES.SUPPLIERS:
        suppliersStore.getSuppliers();
    }

    this.setState({ currentTab: tab });
  };

  handleRowSelect = (contact) => () =>
    this.setState({ isContactPopupOpened: true, currentContact: contact });

  handlePaginationChange = (number) =>
    this.setState({ currentPagination: number });

  handleContactPopupClose = () =>
    this.setState({ isContactPopupOpened: false, currentContact: undefined });

  handleNewContactClick = () => this.setState({ isContactPopupOpened: true });

  handleSubmitContactPopup = (contact) => {
    const { currentTab, currentContact } = this.state;

    if (currentTab === CONTACT_TYPES.AGENCIES) {
      const { agenciesStore } = this.props;

      if (currentContact) {
        agenciesStore.editAgency(currentContact.Id, contact).then(() => {
          this.setState({
            isContactPopupOpened: false,
            currentContact: undefined,
          });
        });
      } else {
        agenciesStore.createAgency(contact).then(() => {
          this.setState({ isContactPopupOpened: false });
        });
      }
    } else {
      const { suppliersStore } = this.props;

      if (currentContact) {
        suppliersStore.editSupplier(currentContact.Id, contact).then(() => {
          this.setState({
            isContactPopupOpened: false,
            currentContact: undefined,
          });
        });
      } else {
        suppliersStore.createSupplier(contact).then(() => {
          this.setState({ isContactPopupOpened: false });
        });
      }
    }
  };

  renderLoading() {
    const {
      agenciesStore,
      agenciesStore: { isAgenciesLoading },
      suppliersStore,
      suppliersStore: { isSuppliersLoading },
    } = this.props;
    const { currentTab } = this.state;

    if (
      (currentTab === CONTACT_TYPES.AGENCIES &&
        (!isAgenciesLoading || !agenciesStore.agencies.length)) ||
      (currentTab === CONTACT_TYPES.SUPPLIERS &&
        (!isSuppliersLoading || !suppliersStore.suppliers.length))
    ) {
      return null;
    }

    return (
      <div className="contacts-update">
        <span>
          <Spin className="spin" size="small" />
          {intl.get('contacts.updatingContacts')}
        </span>
      </div>
    );
  }

  renderContactPopupBody = (setFieldValue, values, extraData) => {
    const { currentTab } = this.state;

    if (currentTab === CONTACT_TYPES.AGENCIES) {
      return renderAgencyPopupBody(setFieldValue, values, extraData);
    }
    return renderSupplierPopupBody(setFieldValue, values, extraData);
  };

  render() {
    const {
      agenciesStore: { isAgencyLoading },
      suppliersStore: { isSupplierLoading },
    } = this.props;
    const {
      currentTab,
      currentPagination,
      isContactPopupOpened,
      currentContact,
    } = this.state;
    const {
      columns,
      dataSource,
      loading,
      newContactButtonLabel,
      popupTitle,
      submitTitle,
      initialValues,
      validation,
    } =
      currentTab === CONTACT_TYPES.AGENCIES
        ? this.getAgencyPopupData()
        : this.getSupplierPopupData();

    return (
      <div className="contacts">
        <div className="contacts-head">
          <div className="head-tabs">
            <div
              onClick={() => this.handleSwitchTab(CONTACT_TYPES.AGENCIES)}
              className={classnames('tab-title', {
                active: currentTab === CONTACT_TYPES.AGENCIES,
              })}
            >
              {intl.get('contactsPage.agencies')}
            </div>
            <div
              onClick={() => this.handleSwitchTab(CONTACT_TYPES.SUPPLIERS)}
              className={classnames('tab-title', {
                active: currentTab === CONTACT_TYPES.SUPPLIERS,
              })}
            >
              {intl.get('contactsPage.suppliers')}
            </div>
          </div>
          <Button
            className="header-tab-button m-b-20"
            onClick={this.handleNewContactClick}
          >
            {newContactButtonLabel}
          </Button>
        </div>

        <div className="contacts-body">
          {this.renderLoading()}
          <Table
            columns={columns}
            dataSource={dataSource}
            rowKey={(contact) => contact.Id.toString()}
            onRow={(contact) => ({
              onClick: this.handleRowSelect(contact),
            })}
            className="contacts-table"
            pagination={{
              current: currentPagination,
              onChange: this.handlePaginationChange,
            }}
            loading={loading}
          />
        </div>

        <MainPopup
          contentClassName="new-contact"
          closePopup={this.handleContactPopupClose}
          visible={isContactPopupOpened}
          onCancel={this.handleContactPopupClose}
          title={popupTitle}
          submitTitle={submitTitle}
          onSubmit={this.handleSubmitContactPopup}
          initialValues={initialValues}
          validation={validation}
          renderBody={this.renderContactPopupBody}
          submitLoading={isAgencyLoading || isSupplierLoading}
          extraData={{ currentContact }}
        />
      </div>
    );
  }
}
