import React from 'react';
import { RadioProps } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { SliderRangeProps } from 'antd/lib/slider';
import { SearchOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { Moment } from 'moment';

import { OrderKeysEnum } from 'models/enums/orderKeys.enum';
import { TableQueryParser } from 'utils/tableQueryParser';
import TableOrdersRangeFilter from '../components/TableOrders/TableOrdersRangeFilter';
import TableOrdersStatusFilter from '../components/TableOrders/TableOrdersStatusFilter';
import TableOrdersDateFilter from '../components/TableOrders/TableOrdersDateFilter';
import TableOrdersSearchFilter from '../components/TableOrders/TableOrdersSearchFilter';
import TableOrdersListFilter from '../components/TableOrders/TableOrdersListFilter';
import TableOrdersTimeFilter from '../components/TableOrders/TableOrdersTimeFilter';
import TableOrdersTripNumberFilter from '../components/TableOrders/TableOrdersTripNumberFilter';
import {
  TableOrdersSearchFilterPropsModel,
  TableOrdersListFilterPropsModel,
  TableOrdersTimeFilterPropsModel,
} from '../models';

function useTableOrdersFilteringProps() {
  const location = useLocation();

  const tableQueryParser = new TableQueryParser(location);

  const getFilterValueByField = (field: string) =>
    tableQueryParser.parseFieldFilter(field) || null;

  const getColumnSorterProps = (field: OrderKeysEnum) => ({
    sorter: true,
    sortOrder: tableQueryParser.parseFieldSort(field),
  });

  const getColumnPriceRangeProps = (
    field: string,
    inputProps?: SliderRangeProps,
  ) => ({
    filteredValue: getFilterValueByField(field),
    filterDropdown: (dropdownProps: FilterDropdownProps) => (
      <TableOrdersRangeFilter
        field={field}
        inputProps={inputProps}
        {...dropdownProps}
      />
    ),
  });

  const getColumnPaymentStatusProps = (
    dataIndex: string,
    inputProps?: RadioProps,
  ) => ({
    filteredValue: getFilterValueByField(dataIndex),
    filterDropdown: (dropdownProps: FilterDropdownProps) => (
      <TableOrdersStatusFilter inputProps={inputProps} {...dropdownProps} />
    ),
  });

  const getColumnDateRangeProps = (
    dataIndex: string,
    inputProps: RangePickerProps<Moment>,
  ) => ({
    filteredValue: getFilterValueByField(dataIndex),
    filterDropdown: (dropdownProps: FilterDropdownProps) => (
      <TableOrdersDateFilter {...dropdownProps} inputProps={inputProps} />
    ),
  });

  const getColumnTimeProps = (
    field: string,
    filterProps?: Partial<TableOrdersTimeFilterPropsModel>,
  ) => {
    const filteredValue = getFilterValueByField(field);

    return {
      filteredValue,
      filterDropdown: (dropdownProps: FilterDropdownProps) => (
        <TableOrdersTimeFilter
          field={field}
          filteredValue={filteredValue}
          {...filterProps}
          {...dropdownProps}
        />
      ),
    };
  };

  const getColumnSearchProps = (
    field: string,
    filterProps?: TableOrdersSearchFilterPropsModel,
  ) => ({
    filteredValue: getFilterValueByField(field),
    filterDropdown: (dropdownProps: FilterDropdownProps) => (
      <TableOrdersSearchFilter {...dropdownProps} {...filterProps} />
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  });

  const getColumnListProps = (
    field: string,
    filterProps?: Partial<TableOrdersListFilterPropsModel>,
  ) => {
    const filteredValue = getFilterValueByField(field);

    return {
      filteredValue,
      filterDropdown: (dropdownProps: FilterDropdownProps) => (
        <TableOrdersListFilter
          field={field}
          filteredValue={filteredValue}
          {...filterProps}
          {...dropdownProps}
        />
      ),
    };
  };

  const getTripIdColumnFilterProps = (field: string) => ({
    filteredValue: getFilterValueByField(field),
    filterDropdown: (dropdownProps: FilterDropdownProps) => (
      <TableOrdersTripNumberFilter {...dropdownProps} />
    ),
  });

  return {
    getColumnSorterProps,
    getColumnPriceRangeProps,
    getColumnPaymentStatusProps,
    getColumnDateRangeProps,
    getColumnTimeProps,
    getColumnSearchProps,
    getColumnListProps,
    getTripIdColumnFilterProps,
  };
}

export default useTableOrdersFilteringProps;
