import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'antd';

export default function AvailabilityNotSupportedAges(props: {
  text: string;
  onRemove: any;
  onReselect: any;
}) {
  const { text, onRemove, onReselect } = props;

  return (
    <div className="not-supported-age-body">
      <div className="header">
        <span>{text}</span>
      </div>
      <div className="body">
        <div>
          <Button onClick={onRemove}>{intl.get('buttons.remove')}</Button>
        </div>
        <div>
          <Button onClick={onReselect}>{intl.get('buttons.reselect')}</Button>
        </div>
      </div>
    </div>
  );
}
