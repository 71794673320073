import React from 'react';
import { Col, Row } from 'antd';
import intl from 'react-intl-universal';

function LandingAdvantages() {
  const advantages = [
    {
      key: 0,
      icon: 'experience',
      caption: intl.get('landingAdvantage.1.caption'),
      description: intl.get('landingAdvantage.1.description'),
    },
    {
      key: 1,
      icon: 'hotel',
      caption: intl.get('landingAdvantage.2.caption'),
      description: intl.get('landingAdvantage.2.description'),
    },
    {
      key: 0,
      icon: 'entertainment',
      caption: intl.get('landingAdvantage.3.caption'),
      description: intl.get('landingAdvantage.3.description'),
    },
    {
      key: 0,
      icon: 'transportation',
      caption: intl.get('landingAdvantage.4.caption'),
      description: intl.get('landingAdvantage.4.description'),
    },
  ];

  return (
    <section className="landing-advantages">
      <div className="container-huge landing-advantages__container">
        <Row gutter={[70, 30]}>
          {advantages.map((advantage) => (
            <Col key={advantage.key} xs={24} sm={12} lg={6}>
              <div className="landing-advantage">
                {advantage.icon && (
                  <div className="landing-advantage__icon">
                    <i className={`icon tc-${advantage.icon}`} />
                  </div>
                )}

                {advantage.caption && (
                  <h3 className="landing-advantage__caption">
                    {advantage.caption}
                  </h3>
                )}

                {advantage.description && (
                  <p className="landing-advantage__description">
                    {advantage.description}
                  </p>
                )}
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
}

export default LandingAdvantages;
