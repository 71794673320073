import React, { PureComponent } from 'react';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import { CopyTwoTone, ReconciliationTwoTone } from '@ant-design/icons';
import intl from 'react-intl-universal';
import { FormikProps } from 'formik';
import { isEqual, omit } from 'lodash';

import { InventoryStore } from '../../../../../../stores/inventory.store';
import { FormRateModel } from '../../../../../../models/inventory/formRate.model';
import { getInitialRate } from '../../../../../../utils/rateUtils';
import { addGlobalMessage } from '../../../../../SharedComponents/GlobalMessages';
import { withStore } from '../../../../../../hocs';
import { ProductFormValuesModel } from '../../../../../../models/inventory/productFormValues.model';

type Props = {
  rate: FormRateModel;
  formikProps: FormikProps<ProductFormValuesModel>;
  inventoryStore?: InventoryStore;
  className?: string;
};

@withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
}))
class RatePanelCopyPaste extends PureComponent<Props> {
  onCopy = () => {
    const { inventoryStore, rate } = this.props;

    inventoryStore.setCopiedRateValue({
      position: rate.position,
      CommissionType: rate.CommissionType,
      CommissionFlat: rate.CommissionFlat,
      CommissionPct: rate.CommissionPct,
      RegularRates: rate.RegularRates,
    });

    addGlobalMessage({
      message: intl.get('inventory.products.popup.ratesTable.copySuccess'),
    });
  };

  onPaste = () => {
    const { formikProps, rate, inventoryStore } = this.props;
    const { copiedRateValue } = inventoryStore;

    Object.entries(omit(copiedRateValue, 'position')).forEach(
      ([key, value]) => {
        formikProps.setFieldValue(`Rates.${rate.position}.${key}`, value);
      },
    );
  };

  isPasteDisabled = () => {
    const { inventoryStore, rate } = this.props;
    const { copiedRateValue } = inventoryStore;

    return !copiedRateValue || rate.position === copiedRateValue.position;
  };

  isPasteWarningDisabled = () => {
    const { formikProps, inventoryStore, rate } = this.props;
    const { copiedRateValue, productDetailsType } = inventoryStore;
    const { values } = formikProps;

    const areFieldsPristine = Object.keys(
      omit(copiedRateValue, 'position'),
    ).every((key) =>
      isEqual(
        getInitialRate(productDetailsType)[key],
        values.Rates[rate.position][key],
      ),
    );

    return this.isPasteDisabled() || areFieldsPristine;
  };

  render() {
    const { className } = this.props;

    return (
      <Space size={10} className={className}>
        <Tooltip overlay={intl.get('labels.copy')}>
          <Button icon={<CopyTwoTone />} onClick={this.onCopy} />
        </Tooltip>

        <Tooltip overlay={intl.get('labels.paste')}>
          <Popconfirm
            disabled={this.isPasteWarningDisabled()}
            title={intl.get('inventory.products.popup.ratesTable.pasteWarning')}
            okText={intl.get('buttons.yes')}
            cancelText={intl.get('buttons.no')}
            onConfirm={this.onPaste}
          >
            <Button
              icon={<ReconciliationTwoTone />}
              disabled={this.isPasteDisabled()}
              onClick={this.isPasteWarningDisabled() ? this.onPaste : undefined}
            />
          </Popconfirm>
        </Tooltip>
      </Space>
    );
  }
}

export default RatePanelCopyPaste;
