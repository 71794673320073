import { observable, action, computed, makeObservable } from 'mobx';
import intl from 'react-intl-universal';
import { router } from 'router';
import { CommonApi } from '../api/common.api';

import { RootStore } from './root.store';
import { AuthApi } from '../api/auth.api';

import { LoginFormModel } from '../models/form-models/login-form.model';

import {
  addGlobalMessage,
  addGlobalSpinner,
  removeGlobalSpinner,
} from '../components/SharedComponents/GlobalMessages';
import { MessageTypeEnum } from '../models/global-messages/message.model';
import { UserApi } from '../api/user.api';
import { USER_ROLE_GROUPS } from '../utils/constants';

const storageKey = 'token';

export class AuthStore {
  private rootStore: RootStore;
  private apiService: AuthApi;
  private userApiService: UserApi;

  @observable private _token: string = undefined;

  constructor(
    rootStore: RootStore,
    apiService: AuthApi,
    userApiService: UserApi,
  ) {
    makeObservable(this);

    this.updateTokenFromStorage();
    this.rootStore = rootStore;
    this.apiService = apiService;
    this.userApiService = userApiService;
  }

  @computed public get token() {
    if (!this._token) {
      this.logOut();
    }

    return this._token;
  }

  @action
  public async login(data: LoginFormModel) {
    const { suppliersStore } = this.rootStore;

    try {
      addGlobalSpinner();
      const { data: response }: any = await this.apiService.login(
        data.userName,
        data.password,
      );

      this.setToken(`${response.token_type} ${response.access_token}`);

      await Promise.all([
        this.rootStore.commonStore.loadSettings(),
        this.rootStore.commonStore.loadHelpInstructions(),
      ]);

      const res = await this.userApiService.getUserProperties();
      // there can be situation when data = null

      if (res.data) {
        this.rootStore.userStore.userProperties = observable(res.data);

        await suppliersStore.checkAndLoadSuppliers();

        const { Roles } = res.data;

        if (
          Roles.some((userRole) =>
            USER_ROLE_GROUPS.ALL.includes(userRole.RoleId),
          )
        ) {
          removeGlobalSpinner();
          return Promise.resolve();
        }

        this.logOut();
        throw new Error('invalid role');
      } else {
        this.logOut();
        throw new Error('no user data');
      }
    } catch (error) {
      removeGlobalSpinner();
      return Promise.reject(error);
    }
  }

  @action
  public logOut() {
    this.clearStore();
    this.rootStore.cleanEntireStorage();
    router.navigate('/login');
    localStorage.removeItem('orders-user-filter-id');
  }

  @action.bound
  public clearStore() {
    this._token = undefined;
    localStorage.clear();
  }

  @action
  private setToken(token: string) {
    this._token = token;
    localStorage.setItem(storageKey, token);
  }

  @action.bound
  private updateTokenFromStorage() {
    const token = localStorage.getItem(storageKey);
    this._token = token;
  }

  @action.bound public async forgotPassword(data: any) {
    try {
      await this.apiService.forgotPassword(data);
      return Promise.resolve();
    } catch (error) {
      addGlobalMessage({
        message: intl.get('globalErrors.smthWentWrong'),
        type: MessageTypeEnum.error,
      });
      return Promise.reject(error);
    }
  }

  @action.bound public async resetPassword(data: any) {
    try {
      addGlobalSpinner();
      await this.apiService.resetPassword(data);
      removeGlobalSpinner();
      return Promise.resolve();
    } catch (error) {
      removeGlobalSpinner();
      addGlobalMessage({
        message: intl.get('globalErrors.smthWentWrong'),
        type: MessageTypeEnum.error,
      });
      return Promise.reject(error);
    }
  }

  @computed public get isLoggedIn() {
    return !!this._token;
  }
}
