import React, { Component } from 'react';
import moment, { Moment } from 'moment';
import uniq from 'lodash/uniq';
import intl from 'react-intl-universal';
import { Formik, FormikHelpers, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Popover, Button, Rate, Modal } from 'antd';

import DatepickerContainer from '../../FormContainers/datepicker.container';
import { TourStore } from '../../../stores/tour.store';
import TravelersSelect from './TravelersSelect';
import { moneyFormat } from '../../../utils/formatter';
import { VoucherTypesEnum } from '../../../models/enums/voucherTypes.enum';
import AvailabilityNotSupportedAges from './AvailabilityNotSupportedAge';
import { getAgeNumberFromAvailabilityMessage } from './utils/availabilityUtils';
import { withStore } from '../../../hocs';

@withStore(({ rootStore }) => ({
  tourStore: rootStore.tourStore,
}))
export default class AvailabilityCheck extends Component<{
  tourStore?: TourStore;
  productId: string;
  availabilityChecked: Function;
  scrollToNeededElement: Function;
  modalContainer: HTMLElement;
}> {
  travelerSelectsRef = null;

  state = {
    isTravelersOpen: false,
    notSupportedAgeText: null,
    isAvailableNotSupportedVisible: false,
    availabilityFormRef: null,

    unavailableDatesMoment: [],
  };

  componentDidMount() {
    const { tourStore, productId } = this.props;
    const startDate = moment.utc();
    const endDate = startDate.clone().add(2, 'years');

    tourStore
      .getServiceInventory(
        productId,
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD'),
      )
      .then((dates) => {
        const unavailableDatesMoment = dates.map((item) => moment.utc(item));

        this.setState({
          unavailableDatesMoment,
        });
      });
  }

  getCalendarContainer = () =>
    this.state.availabilityFormRef || this.props.modalContainer;

  getPopupContainer = () =>
    this.state.availabilityFormRef || this.props.modalContainer;

  refCallbackForAvailability = (elem) => {
    !this.state.availabilityFormRef &&
      this.setState({ availabilityFormRef: elem });
  };

  handleTravelersVisibleChange = (visible: boolean) => {
    this.setState({ isTravelersOpen: visible });
  };

  handleTravelersCountChange = async (
    setFieldValue: Function,
    { adults, children },
    values: any,
  ) => {
    setFieldValue('travelers', {
      adults,
      minorAges: children.map((age: string) => Number(age)),
    });
    this.setState({ isTravelersOpen: false });

    await this.checkAvailability(values.departureDate, adults, children);
  };

  handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
    const totalTravelers: number =
      values.travelers.adults + values.travelers.minorAges.length;

    if (totalTravelers < 1) {
      actions.setFieldError('travelers', intl.get('required'));
      actions.setSubmitting(false);
      return;
    }

    await this.checkAvailability(
      values.departureDate,
      values.travelers.adults,
      values.travelers.minorAges,
    );

    actions.setSubmitting(false);
  };

  handleRemoveNotSupportedAgesAndSubmit =
    (setFieldValue: Function, values: any, notSupportedAges: string[]) =>
    () => {
      const { travelers, departureDate } = values;
      const newChildren = travelers.minorAges.filter(
        (item) => !notSupportedAges.find((age) => +age === +item),
      );
      const newTravelers = {
        ...travelers,
        minorAges: newChildren,
      };

      setFieldValue('travelers', newTravelers);
      this.checkAvailability(
        departureDate,
        newTravelers.adults,
        newTravelers.minorAges,
      );
      this.handleNotSupportedAgesAndSubmitPopupClose();
      // call reset method of Traveler Selects
      this.travelerSelectsRef &&
        this.travelerSelectsRef.resetTheFormChildren(newChildren);
    };

  handleReselectNotSupportedAges = () => {
    this.setState({
      isTravelersOpen: true,
      isAvailableNotSupportedVisible: false,
    });
  };

  handleNotSupportedAgesAndSubmitPopupClose = () => {
    this.setState({
      isAvailableNotSupportedVisible: false,
    });
  };

  checkAvailability = (
    departureDate: Moment,
    adults: number,
    minorAges: number[],
  ) => {
    const { tourStore, availabilityChecked, scrollToNeededElement } =
      this.props;
    const date = departureDate.format('YYYY-MM-DD');

    scrollToNeededElement();
    tourStore
      .getTourAvailability(date, adults, minorAges)
      .then((response) => {
        if (response && response[0]) {
          const res = response[0];

          if (res.AvailabilityMessage) {
            this.setState({
              notSupportedAgeText: res.AvailabilityMessage,
              isAvailableNotSupportedVisible: true,
            });
          }
        }
      })
      .finally(() => {
        availabilityChecked();
      });
  };

  datePickerUnavailableRule = (current: Moment) => {
    const { unavailableDatesMoment } = this.state;
    const dateUnavailable = unavailableDatesMoment.find((date) =>
      current.isSame(date, 'day'),
    );

    return !!dateUnavailable;
  };

  datePickerDisabledDate = (current: Moment) => {
    const firstRule =
      current && current < moment().subtract(1, 'days').endOf('day');
    const secondRule = this.datePickerUnavailableRule(current);
    if (firstRule || secondRule) {
      return true;
    }

    return false;
  };

  renderProductLanguages = () => {
    const {
      tourStore: {
        tourInfo: { ProductLanguages },
      },
    } = this.props;

    if (!ProductLanguages || !ProductLanguages.length) return null;
    const languageKeys = uniq(ProductLanguages.map((item) => item.LanguageId)); // ['ENG', 'CHI'] only 2 languages in this version

    return (
      <div className="tour-availability-languages">
        <span>{`${intl.get('tourDetails.tourLanguage')} ${languageKeys
          .map((languageKey) => intl.get(`languages.${languageKey}`))
          .join(', ')}`}</span>
      </div>
    );
  };

  renderTourParams = () => {
    const {
      tourStore: {
        tourInfo: { OnRequestPeriod, HasPickup, VoucherTypesAccepted },
      },
    } = this.props;

    let confirmationType: any;

    if (!OnRequestPeriod) {
      confirmationType = (
        <>
          <i className="tc-ic-freesale" />
          <span>{intl.get('tourDetails.confirmationTypes.instant')}</span>
        </>
      );
    } else {
      confirmationType = (
        <>
          <i className="tc-ic-freesale-onrequest" />
          <span>
            {intl.get('tourDetails.confirmationTypes.instantWithCutoff', {
              countOfDays: OnRequestPeriod / 24,
            })}
          </span>
        </>
      );
    }

    return (
      <div className="tour-params">
        {!!confirmationType && (
          <div className="params-line">{confirmationType}</div>
        )}
        {HasPickup && (
          <div className="params-line">
            <i className="tc-ic-hotelpickup" />
            <span>{intl.get('tourDetails.hotelPickup')}</span>
          </div>
        )}
        {(VoucherTypesAccepted === VoucherTypesEnum.electronic ||
          VoucherTypesAccepted === VoucherTypesEnum.electronicOrId ||
          VoucherTypesAccepted === VoucherTypesEnum.paperOrElectronic ||
          VoucherTypesAccepted === VoucherTypesEnum.paperOrElectronicOrId) && (
          <div className="params-line">
            <i className="tc-ic-mobile-ticket" />
            <span>{intl.get('tourDetails.mobileTicket')}</span>
          </div>
        )}
      </div>
    );
  };

  renderAvailabilityNotSupportedPopup = (
    setFieldValue: Function,
    values: any,
  ) => {
    const { notSupportedAgeText, isAvailableNotSupportedVisible } = this.state;

    if (!notSupportedAgeText) {
      return;
    }
    const notSupportedAges =
      getAgeNumberFromAvailabilityMessage(notSupportedAgeText);

    return (
      <Modal
        destroyOnClose
        visible={isAvailableNotSupportedVisible}
        footer={null}
        onCancel={this.handleNotSupportedAgesAndSubmitPopupClose}
        cancelButtonProps={{ size: 'large' }}
        className="available-not-supported-age"
      >
        <AvailabilityNotSupportedAges
          text={notSupportedAgeText}
          onRemove={this.handleRemoveNotSupportedAgesAndSubmit(
            setFieldValue,
            values,
            notSupportedAges,
          )}
          onReselect={this.handleReselectNotSupportedAges}
        />
      </Modal>
    );
  };

  render() {
    const {
      tourStore: {
        tourInfo: { StartingAgentNet, DurationText },
        departureDate,
      },
    } = this.props;
    const { isTravelersOpen } = this.state;

    return (
      <div className="tour-availability" ref={this.refCallbackForAvailability}>
        <div className="tour-availability-header">
          <div className="rate-and-duration">
            {!!DurationText && (
              <div className="duration">
                <i className="tc-clock duration-icon" />
                {/* maybe it should be implemented via intl for chinese */}
                <span>
                  {DurationText.replace(/hours/g, 'hrs').replace(
                    /minutes/g,
                    'mins',
                  )}
                </span>
              </div>
            )}
          </div>
          {this.renderProductLanguages()}
        </div>
        <Formik
          initialValues={{
            departureDate: departureDate || moment.utc(),
            travelers: {
              adults: 1,
              minorAges: [],
            },
          }}
          validationSchema={Yup.object().shape({
            departureDate: Yup.string().required(intl.get('required')),
          })}
          validateOnChange
          onSubmit={this.handleSubmit}
        >
          {({ values, setFieldValue, errors }) => (
            <Form className="availability-check-form">
              <div className="form-fields">
                <Field
                  name="departureDate"
                  component={DatepickerContainer}
                  label={intl.get('tourDetails.departureDate')}
                  disabledDate={this.datePickerDisabledDate}
                  getCalendarContainer={this.getCalendarContainer}
                  className="date-select"
                  unavailableDate={this.datePickerUnavailableRule}
                />
                <Popover
                  content={
                    <TravelersSelect
                      ref={(elem) => (this.travelerSelectsRef = elem)}
                      onSubmit={(travelersValues) =>
                        this.handleTravelersCountChange(
                          setFieldValue,
                          travelersValues,
                          values,
                        )
                      }
                    />
                  }
                  trigger="click"
                  placement="bottomLeft"
                  visible={isTravelersOpen}
                  onVisibleChange={this.handleTravelersVisibleChange}
                  getPopupContainer={this.getPopupContainer}
                >
                  <div className="field">
                    <h4 className="field-label">
                      {intl.get('tourDetails.travelers')}
                    </h4>
                    <div className="ant-input">
                      <span>
                        {values.travelers.adults +
                          values.travelers.minorAges.length ||
                          intl.get('select')}
                      </span>
                    </div>
                  </div>
                </Popover>
              </div>
              {!!StartingAgentNet && (
                <div className="tour-price">
                  {`${intl.get('from')} ${moneyFormat(StartingAgentNet)}`}
                </div>
              )}
              <div className="form-actions">
                <Button type="primary" htmlType="submit" size="large">
                  {intl.get('tourDetails.checkAvailability')}
                </Button>
              </div>
              {this.renderTourParams()}
              {this.renderAvailabilityNotSupportedPopup(setFieldValue, values)}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
