import moment, { Moment } from 'moment';

import { ServiceModel } from '../../../../models/service.model';
import { normalizeDate } from '../../../../utils/storeUtils';
import { getProductCancellationPenalty } from './orderUtils';

export const getCancellationInfoItem = (
  item: ServiceModel,
  paymentAmountDue: number,
) => {
  const sortedPolicies = [...item.CancelInfo.Policies].sort(
    (a, b) => b.NightsPrior - a.NightsPrior,
  );

  // I think there always will be 1 or 2 conditions
  const policiesRules = sortedPolicies.map((policy, index) => {
    if (policy.NonRefundable && policy.NightsPrior === 999) {
      // non-refundable = true and NightsPrior = 999 means non-refundable from the moment of purchase
      return { noRefund: true };
    }
    if (index === 0) {
      // free cancellation until the first record
      return {
        // after this days it 100% penalty == no refund
        freeCancellationUntilDays: policy.NightsPrior,
      };
    }
    if (index === sortedPolicies.length - 1) {
      // last record is in effect until the departure date
      return {
        noRefundPriorDays: policy.NightsPrior,
      };
    }
    if (!policy.NonRefundable) {
      // there should be penalty percentage but Dean said that penalty is 100%
    }

    return {};
  });

  const penalty = getProductCancellationPenalty(item);
  const refund = item.Price - penalty;

  // then we should find out if there is free cancellation period or no
  // if { noRefund: true } == always 100% penalty == not within free cancellation
  // if { freeCancellationUntilDays: number } we should use StartDate adn if now is before StartDate - untilDays
  // then there is free cancellation
  // else not within free cancellation
  const result = {
    // eslint-disable-next-line eqeqeq
    isPaid: paymentAmountDue == 0,
    isFreeCancellation: undefined,
    productId: item.ProductId,
    tripItemId: item.TripItemId,
    Price: item.Price,
    availabilityLevel: item.AvailabilityLevel,
    isPartlyRefunded: item.Price !== refund && refund > 0,
    refund,
    penalty,
  };

  // isFreeCancellation
  const isNoRefund = policiesRules.some((policiesRule) =>
    policiesRule.hasOwnProperty('noRefund'),
  );

  if (isNoRefund) {
    result.isFreeCancellation = false;
  } else {
    const cancellationCondition = policiesRules.find((policiesRule) =>
      policiesRule.hasOwnProperty('freeCancellationUntilDays'),
    );
    // it should be always
    if (cancellationCondition) {
      const countDays = cancellationCondition.freeCancellationUntilDays;
      const startDate: Moment = normalizeDate(moment(item.StartDate));
      const now: Moment = normalizeDate(moment());
      const freeCancellationDate: Moment = startDate.subtract(
        countDays,
        'days',
      );

      if (now < freeCancellationDate) {
        result.isFreeCancellation = true;
      } else {
        result.isFreeCancellation = false;
      }
    }
  }

  return result;
};
