import React, { Component } from 'react';
import intl from 'react-intl-universal';

import { Modal, Button } from 'antd';
import { UserStore } from '../../../../../stores/user.store';
import { AuthStore } from '../../../../../stores/auth.store';

import Signup from './Signup';
import AfterSignup from './AfterSignup';
import DeactivateUser from './DeactivateUser';
import RoleManagementTable from './RoleManagementTable';
import { withStore } from '../../../../../hocs';

const modalContents = {
  signup: 0,
  afterSignup: 1,
  changeUserStatus: 2,
};

@withStore(({ rootStore }) => ({
  userStore: rootStore.userStore,
  authStore: rootStore.authStore,
}))
export default class RoleManagement extends Component<{
  userStore?: UserStore;
  authStore?: AuthStore;
}> {
  state = {
    isModalOpened: false,
    modalContent: modalContents.signup,
    activateUser: {
      userName: null,
      inactive: false,
    },
  };

  componentDidMount() {
    const { userStore } = this.props;

    userStore.getUserList();
  }

  handleNewUserPopupVisible = (isModalOpened) =>
    this.setState({ isModalOpened });

  handleNewUserClick = () =>
    this.setState({ isModalOpened: true, modalContent: modalContents.signup });

  handleChangeNewUserContent = (modalContent) =>
    this.setState({ modalContent });

  handleAfterUserSignupClick = () =>
    this.setState({ isModalOpened: false, modalContent: modalContents.signup });

  handleActivateClick = (userName, inactive) => {
    this.setState({
      isModalOpened: true,
      modalContent: modalContents.changeUserStatus,
      activateUser: {
        userName,
        inactive,
      },
    });
  };

  handleChangeUserStatusClose = () => {
    this.setState({ isModalOpened: false }, () => {
      this.setState({
        activateUser: {
          userName: null,
          inactive: false,
        },
      });
    });
  };

  handleChangeUserStatusSuccess = () => {
    const { userStore } = this.props;

    userStore.getUserList();
    this.handleChangeUserStatusClose();
  };

  render() {
    const {
      isModalOpened,
      modalContent,
      activateUser: { userName, inactive },
    } = this.state;

    return (
      <div className="role-management">
        <div className="d-f jc-sb">
          <div className="f-s-22 f-w-7 role-name">
            <span>{intl.get('profile.roleManagement.header')}</span>
          </div>
          <div>
            <Button
              className="default-button p-h-30 p-v-5"
              type="primary"
              htmlType="button"
              size="large"
              onClick={this.handleNewUserClick}
            >
              {intl.get('profile.roleManagement.newUserBtn')}
            </Button>
          </div>
        </div>
        <div className="m-t-80">
          <RoleManagementTable onActivateClick={this.handleActivateClick} />
        </div>
        <Modal
          maskClosable={false}
          visible={isModalOpened}
          footer={null}
          onCancel={() => this.handleNewUserPopupVisible(false)}
          cancelButtonProps={{ size: 'large' }}
          className="notification"
        >
          {modalContent === modalContents.signup && (
            <Signup
              onSignupSuccess={() =>
                this.handleChangeNewUserContent(modalContents.afterSignup)
              }
            />
          )}
          {modalContent === modalContents.afterSignup && (
            <AfterSignup onButtonClick={this.handleAfterUserSignupClick} />
          )}
          {modalContent === modalContents.changeUserStatus && (
            <DeactivateUser
              userName={userName}
              inactive={inactive}
              onCancel={this.handleChangeUserStatusClose}
              onSuccess={this.handleChangeUserStatusSuccess}
            />
          )}
        </Modal>
      </div>
    );
  }
}
