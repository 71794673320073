import { TourDetailsModel } from '../models/tour-details/tourDetails.model';
import { AvailableCategoryModel } from '../models/tour-details/tourAvailableCategory.model';

const mapGuestsToRequest = (availability: AvailableCategoryModel) =>
  availability.Guests.map(({ Seat, GuestId, Age, Price, Type }) => ({
    Seat,
    GuestId,
    Age,
    Price,
    Type,
  }));

export const tourToPerformHoldMapper = (
  tourInfo: TourDetailsModel,
  availability: AvailableCategoryModel,
) => {
  const { BookingQuestions, ProductType, ProductId, StartDate } = tourInfo;
  const {
    Id,
    ServiceTime,
    SupplierData,
    RatePlan,
    AvailabilityLevel,
    Price,
    ConnectionId,
    ProductMappingId,
  } = availability;

  const guests = mapGuestsToRequest(availability);

  return {
    ServiceTime,
    Guests: {
      Guests: guests,
      PrimaryGuestId: guests[0].GuestId,
    },
    SupplierData,
    BookingQuestions,
    TripItemId: 0,
    ProductType,
    ProductId,
    CategoryId: Id,
    RatePlanId: RatePlan.Id,
    StartDate,
    Note: '',
    AvailabilityLevel,
    Price,
    ConnectionId,
    ProductMappingId,
    UnderReview: true,
    ConfirmationNumber: '',
    BundleId: 0,
    PassId: '',
  };
};
