import React from 'react';
import { Col, Radio, Row, Tooltip } from 'antd';
import { Field, useFormikContext } from 'formik';
import intl from 'react-intl-universal';

import { VisibilityEnum } from '../../../../../../models/enums/visibility.enum';
import {
  getVisibilityIcon,
  getVisibilityLabel,
} from '../../../../../../utils/rateUtils';
import RadioGroupField from '../../../../../FormContainers/radio-group.container';
import { SelectContainer } from '../../../../../FormContainers';
import { SelectOptionModel } from '../../../../../../models/selectOption.model';
import { ProductFormValuesModel } from '../../../../../../models/inventory/productFormValues.model';
import { withStore } from '../../../../../../hocs';
import { InventoryStore } from '../../../../../../stores/inventory.store';

type Props = {
  name: string;
  inventoryStore?: InventoryStore;
  filterAgencyOptions: (option: SelectOptionModel) => boolean;
};

function ProductRateAvailabilityFilters(props: Props) {
  const { name, inventoryStore, filterAgencyOptions } = props;

  const { values, setFieldValue } = useFormikContext<ProductFormValuesModel>();

  const derivedAgenciesOptions = (
    [
      {
        label: intl.get('inventory.products.popup.allAgencies'),
        value: '',
      },
    ] as SelectOptionModel[]
  ).concat(inventoryStore.agenciesOptions.filter(filterAgencyOptions));

  const onRadioClick = () => setFieldValue(`${name}.visibility`, undefined);

  return (
    <Row gutter={[30, 0]} align="bottom">
      <Col>
        <Field name={`${name}.visibility`} component={RadioGroupField}>
          {[
            VisibilityEnum.Public,
            VisibilityEnum.Private,
            VisibilityEnum.Negotiated,
          ].map((visibility) => (
            <Tooltip key={visibility} overlay={getVisibilityLabel(visibility)}>
              <Radio.Button value={visibility} onClick={onRadioClick}>
                {getVisibilityIcon(visibility)}
              </Radio.Button>
            </Tooltip>
          ))}
        </Field>
      </Col>

      {values[name]?.visibility === VisibilityEnum.Negotiated && (
        <Col span={12}>
          <Field
            name={`${name}.agencyId`}
            label={intl.get('inventory.products.popup.negotiatedWithAgency')}
            options={derivedAgenciesOptions}
            component={SelectContainer}
          />
        </Col>
      )}
    </Row>
  );
}

export default withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
}))(ProductRateAvailabilityFilters);
