import queryString, { ParseOptions, StringifyOptions } from 'query-string';
import { omit } from 'lodash';

export const trimUrl = (url: string) =>
  url ? url.trim().replace(/^\/|\/$/g, '') : '';

export const parseQueryString = (string: string, options: ParseOptions = {}) =>
  queryString.parse(string, { arrayFormat: 'bracket', ...options });

export const stringifyQueryString = (
  record: Record<string, unknown>,
  options: StringifyOptions = {},
) => queryString.stringify(record, { arrayFormat: 'bracket', ...options });

export const omitFromQueryString = (string: string, keys: string[]) =>
  stringifyQueryString(omit(parseQueryString(string), keys));

export const addToQueryString = (
  string: string,
  record: Record<string, unknown>,
) => stringifyQueryString({ ...parseQueryString(string), ...record });

export const getCountryFlagUrl = (countryCode: string) =>
  `https://flagcdn.com/${countryCode.toLowerCase()}.svg`;
