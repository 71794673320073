import React from 'react';
import { inject, observer } from 'mobx-react';
import { ReactComponent } from 'models/reactComponent.type';
import { RootStore } from '../stores/root.store';

type Store = { rootStore: RootStore };

type MapStoreToProps = (store: Store) => Record<string, unknown>;

export const withStore: <P>(
  mapStoreToProps: MapStoreToProps,
) => <T extends ReactComponent>(Component: T) => T & ReactComponent<P> =
  (mapStoreToProps) => (Component) =>
    inject(mapStoreToProps)(observer(Component));
