import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import Helmet from 'react-helmet';

import PrivateRoute from 'components/SharedComponents/PrivateRoute';
import PublicRoute from 'components/SharedComponents/PublicRoute';
import WithGeneralLayout from './components/Layouts/WithGeneralLayout';
import { CommonStore } from './stores/common.store';
import NotFound from './components/Pages/NotFound';
import {
  Login,
  ForgotPassword,
  ForgotPasswordEmailSent,
  ResetPassword,
} from './components/Pages/Auth';
import Orders from './components/Pages/Orders';
import Marketplace from './components/Pages/Marketplace';
import Cart from './components/Pages/Cart';
import Contacts from './components/Pages/Contacts';
import Profile from './components/Pages/Profile';
import Inventory from './components/Pages/Inventory';
import TopMenuCalendar from './components/Pages/TopMenuCalendar';
import Terms from './components/Pages/Terms';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import Landing from './components/Pages/Landing';
import { ROLES, USER_ROLE_GROUPS } from './utils/constants';
import MessageTemplates from './components/Pages/MessageTemplates';
import { withStore } from './hocs';

@withStore(({ rootStore }) => ({
  commonStore: rootStore.commonStore,
}))
export default class AppRoutes extends Component<{
  commonStore?: CommonStore;
}> {
  render() {
    const { commonStore } = this.props;
    const { portalTitle, portalFavIcon } =
      commonStore.siteSettings.brandingSettings;

    return (
      <>
        <Helmet>
          <title>{portalTitle}</title>
          <link rel="shortcut icon" href={portalFavIcon} />
        </Helmet>

        <Routes>
          <Route path="*" element={<NotFound />} />

          <Route
            index
            element={
              <PublicRoute redirectTo="/inventory">
                <Landing />
              </PublicRoute>
            }
          />

          <Route path="login" element={<Login />} />

          <Route path="forgot-password" element={<ForgotPassword />} />

          <Route
            path="forgot-password-email-sent"
            element={<ForgotPasswordEmailSent />}
          />

          <Route path="reset-password" element={<ResetPassword />} />

          <Route element={<WithGeneralLayout />}>
            <Route path="terms" element={<Terms />} />

            <Route path="privacy-policy" element={<PrivacyPolicy />} />

            <Route path="inventory">
              <Route
                path=":tab?"
                element={
                  <PrivateRoute
                    roles={[
                      ...USER_ROLE_GROUPS.SUPPLIER,
                      ...USER_ROLE_GROUPS.STAFF,
                    ]}
                  >
                    <Inventory />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route
              path="cart"
              element={
                <PrivateRoute roles={USER_ROLE_GROUPS.MARKETPLACE}>
                  <Cart />
                </PrivateRoute>
              }
            />

            <Route
              path="marketplace"
              element={
                <PrivateRoute roles={USER_ROLE_GROUPS.MARKETPLACE}>
                  <Marketplace />
                </PrivateRoute>
              }
            />

            <Route
              path="contacts"
              element={
                <PrivateRoute
                  roles={[ROLES.STAFF.value, ROLES.STAFF_ADMIN.value]}
                >
                  <Contacts />
                </PrivateRoute>
              }
            />

            <Route
              path="calendar"
              element={
                <PrivateRoute roles={USER_ROLE_GROUPS.SUPPLIER}>
                  <TopMenuCalendar />
                </PrivateRoute>
              }
            />

            <Route
              path="message-templates"
              element={
                <PrivateRoute
                  roles={[
                    ROLES.SUPPLIER_ADMIN.value,
                    ROLES.SUPPLIER_CLERK.value,
                    ROLES.STAFF.value,
                    ROLES.STAFF_ADMIN.value,
                    ROLES.MANAGER.value,
                  ]}
                >
                  <MessageTemplates />
                </PrivateRoute>
              }
            />
          </Route>

          <Route element={<WithGeneralLayout withPadding={false} />}>
            <Route
              path="orders"
              element={
                <PrivateRoute>
                  <Orders />
                </PrivateRoute>
              }
            />
          </Route>

          <Route
            element={
              <WithGeneralLayout withPadding={false} withBackground={false} />
            }
          >
            <Route
              path="profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </>
    );
  }
}
