import React, { useState } from 'react';
import { Button, DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { isEmpty } from 'lodash';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { FilterDropdownProps } from 'antd/es/table/interface';
import intl from 'react-intl-universal';

import { DateRangeTemplateEnum } from 'models/enums/dateRangeTemplate.enum';
import { MomentRangeModel } from 'models/momentRange.model';
import {
  normalizeDateRange,
  getDateRangeByTemplate,
  getIsDateRangeTemplateValid,
} from 'utils/datesUtils';
import RangePickerTemplates from 'components/SharedComponents/RangePickerTemplates';
import TableOrdersFilterActions from './TableOrdersFilterActions';

type Props = {
  inputProps?: RangePickerProps<Moment>;
} & FilterDropdownProps;

function TableOrdersDateFilter(props: Props) {
  const { inputProps, ...dropdownProps } = props;
  const { selectedKeys, setSelectedKeys } = dropdownProps;

  const [isOpen, setIsOpen] = useState(false);

  const parseSelectedTemplate = (
    value: string[],
  ): DateRangeTemplateEnum | null => {
    if (isEmpty(value)) return null;

    const [template] = value;

    return getIsDateRangeTemplateValid(template) ? template : null;
  };

  const parseDateRange = (value: string[]): MomentRangeModel | null => {
    if (isEmpty(value)) return null;

    const [from, to] = value;

    return [moment(from), moment(to)];
  };

  const parseSelectedDateRange = (value: string[]): MomentRangeModel => {
    if (isEmpty(value)) return null;

    return getDateRangeByTemplate(selectedTemplate) ?? parseDateRange(value);
  };

  const selectedTemplate = parseSelectedTemplate(selectedKeys as string[]);
  const selectedDateRange = parseSelectedDateRange(selectedKeys as string[]);

  const onTemplateChange = (fieldValue: DateRangeTemplateEnum) => {
    setSelectedKeys([fieldValue]);
  };

  const onDateChange = (fieldValue: MomentRangeModel) => {
    setSelectedKeys(normalizeDateRange(fieldValue));
  };

  return (
    <div className="ant-table-filter-dropdown">
      <div className="ant-table-filter-dropdown-search">
        <DatePicker.RangePicker
          {...inputProps}
          renderExtraFooter={() => (
            <div className="p-t-12">
              <RangePickerTemplates
                value={selectedTemplate}
                onChange={onTemplateChange}
              />

              <Button block type="link" onClick={() => setIsOpen(false)}>
                {intl.get('buttons.ok')}
              </Button>
            </div>
          )}
          separator="-"
          value={selectedDateRange}
          open={isOpen}
          onOpenChange={setIsOpen}
          onChange={onDateChange}
        />
      </div>

      <div className="ant-table-filter-dropdown-btns">
        <TableOrdersFilterActions {...dropdownProps} />
      </div>
    </div>
  );
}

export default TableOrdersDateFilter;
