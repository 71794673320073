import { observable, action, makeObservable } from 'mobx';

import { RootStore } from './root.store';

import { MessageModel } from '../models/global-messages/message.model';

export class GlobalMessagesStore {
  private rootStore: RootStore;

  private visibleTime = 5000;

  @observable public isSpinnerVisible = false;

  constructor(rootStore: RootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
  }

  @observable public messages: MessageModel[] = [];

  @action
  public addCloseListener(id: Date) {
    setTimeout(() => {
      this.messages = this.messages.filter(
        (message: MessageModel) => message.id !== id,
      );
    }, this.visibleTime);
  }

  @action
  public addMessage(message: MessageModel) {
    const id: Date = new Date();
    this.messages.push(
      observable({
        ...message,
        id,
      }),
    );
    this.addCloseListener(id);
  }

  @action
  public addGlobalSpinner() {
    this.isSpinnerVisible = true;
  }

  @action
  public removeGlobalSpinner() {
    this.isSpinnerVisible = false;
  }
}
