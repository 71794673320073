import React, { Component } from 'react';
import classnames from 'classnames';
import IMask, { FactoryReturnMasked } from 'imask';
import { getIn } from 'formik';
import { Input } from 'antd';

export default class InputContainer extends Component<{
  field: any;
  inputRef: any;
  form: any;
  label: string;
  mask?: string;
  maskOptions?: any;
  className?: string;
  tooltip?: any;
  onChange?: Function;
}> {
  mask: FactoryReturnMasked<unknown>;

  componentDidMount() {
    const { mask, maskOptions } = this.props;
    if (mask) {
      this.mask = IMask.createMask({
        mask,
        ...maskOptions,
      });
    }
  }

  handleChange = (e) => {
    const {
      form: { setFieldValue },
      field: { name },
      mask,
      onChange,
    } = this.props;
    let { value } = e.target;

    if (value[value.length - 2] === ' ') {
      value = value.trim();
    }

    if (mask) {
      this.mask.resolve(value);

      value = this.mask.value;
    }

    setFieldValue(name, value || '');

    if (onChange) {
      onChange(e, value || '');
    }
  };

  handleBlur = (e) => {
    const {
      form: { setFieldValue },
      field: { name, onBlur },
    } = this.props;
    const value = e.target.value.trim();

    setFieldValue(name, value);

    // call the built-in handleBur
    onBlur(e);
  };

  render() {
    const {
      field,
      form: { touched, errors },
      label,
      inputRef,
      className,
      tooltip,
      ...rest
    } = this.props;
    const fieldError: string = getIn(errors, field.name);
    const isTouched: boolean = getIn(touched, field.name);
    const hasError: boolean = fieldError && isTouched;

    return (
      <div
        className={classnames('field', { 'has-error': hasError }, className)}
      >
        {label && (
          <h4 className="field-label">
            {label} &nbsp;
            {tooltip && tooltip}
          </h4>
        )}
        <Input
          {...field}
          {...rest}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          ref={inputRef}
        />
        {hasError && <div className="field-error visible">{fieldError}</div>}
      </div>
    );
  }
}
