import React from 'react';
import intl from 'react-intl-universal';
import { ROLES, USER_ROLE_GROUPS } from '../../../utils/constants';

export const getRouteTree = () => [
  {
    to: '/inventory',
    children: <span>{intl.get('sidebarMenu.inventory')}</span>,
    icon: null,
    roles: [...USER_ROLE_GROUPS.SUPPLIER, ...USER_ROLE_GROUPS.STAFF],
  },
  {
    to: '/marketplace',
    children: <span>{intl.get('sidebarMenu.marketplace')}</span>,
    icon: null,
    roles: USER_ROLE_GROUPS.MARKETPLACE,
  },
  {
    to: '/orders',
    children: <span>{intl.get('sidebarMenu.orders')}</span>,
    icon: null,
    roles: [],
  },
  {
    to: '/contacts',
    children: <span>{intl.get('sidebarMenu.contacts')}</span>,
    icon: null,
    roles: [ROLES.STAFF.value, ROLES.STAFF_ADMIN.value],
  },
  {
    to: '/message-templates',
    children: <span>{intl.get('sidebarMenu.messageTemplates')}</span>,
    icon: null,
    roles: [
      ROLES.SUPPLIER_ADMIN.value,
      ROLES.SUPPLIER_CLERK.value,
      ROLES.STAFF.value,
      ROLES.STAFF_ADMIN.value,
      ROLES.MANAGER.value,
    ],
  },
];
