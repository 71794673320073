import React, { Component } from 'react';
import intl from 'react-intl-universal';
import * as Yup from 'yup';

import { Button, Select } from 'antd';
import { Formik, Form, Field } from 'formik';
import { UserStore } from '../../../../../stores/user.store';
import { AuthStore } from '../../../../../stores/auth.store';
import { AgenciesStore } from '../../../../../stores/agencies.store';
import { SuppliersStore } from '../../../../../stores/suppliers.store';

import { InputContainer, SelectContainer } from '../../../../FormContainers';
import { secretCode, ROLES } from '../../../../../utils/constants';
import { withStore } from '../../../../../hocs';

const initialFormValues = {
  userName: undefined,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  password: undefined,
  role: undefined,
  supplierId: undefined,
  agency: undefined,
};

const passwordPattern = /[!#$%&<=>_?]/; // /[!#$%&*+-<=>?@^_~]/;

const { Option } = Select;

@withStore(({ rootStore }) => ({
  userStore: rootStore.userStore,
  agenciesStore: rootStore.agenciesStore,
  suppliersStore: rootStore.suppliersStore,
}))
export default class Signup extends Component<{
  onSignupSuccess: Function;
  userStore?: UserStore;
  authStore?: AuthStore;
  agenciesStore?: AgenciesStore;
  suppliersStore?: SuppliersStore;
}> {
  state = {
    isRegisterApiLoading: false,
    isFormDataIncorrect: false,
    errors: [],
  };

  componentDidMount() {
    const { agenciesStore, suppliersStore } = this.props;
    agenciesStore.getAgencies();
    suppliersStore.getSuppliers();
  }

  handleAddNewUserSubmit = (values) => {
    const { userStore, onSignupSuccess } = this.props;
    const {
      userName,
      email,
      lastName,
      firstName,
      password,
      role,
      supplierId,
      agency,
    } = values;

    const data = {
      UserName: userName,
      Email: email,
      LastName: lastName,
      FirstName: firstName,
      Password: password,
      ConfirmPassword: password,
      SecretCode: secretCode,
      Role: role,
      SupplierId: supplierId,
      Agency: agency,
    };

    this.setState({
      isRegisterApiLoading: true,
      isFormDataIncorrect: false,
    });

    userStore
      .registerUser(data)
      .then(() => onSignupSuccess())
      .catch((e) => {
        if (e.response && e.response.data) {
          const { ModelState } = e.response.data;
          const errors = Object.values(ModelState).reduce(
            (result, currentArray) => [
              ...(result as string[]),
              ...(currentArray as string[]),
            ],
          );

          this.setState({ isFormDataIncorrect: true, errors });
        }
      })
      .finally(() => this.setState({ isRegisterApiLoading: false }));
  };

  render() {
    const {
      isRegisterApiLoading,
      isFormDataIncorrect,
      errors: serverErrors,
    } = this.state;
    const {
      userStore: { userProperties },
      agenciesStore: { isAgenciesLoading, agencies },
      suppliersStore: { suppliers, isSuppliersLoading },
    } = this.props;

    let availableRoles = [];

    if (
      userProperties &&
      userProperties.Roles.some((r) => r.RoleId === ROLES.SUPPLIER_ADMIN.value)
    ) {
      availableRoles = [ROLES.SUPPLIER_CLERK, ROLES.SUPPLIER_ADMIN];
    } else if (
      userProperties &&
      userProperties.Roles.some((r) => r.RoleId === ROLES.STAFF_ADMIN.value)
    ) {
      availableRoles = [
        ROLES.STAFF,
        ROLES.STAFF_ADMIN,
        ROLES.SUPPLIER_CLERK,
        ROLES.SUPPLIER_ADMIN,
        ROLES.MANAGER,
        ROLES.AGENT,
      ];
    }

    const roleChildren = availableRoles.map(({ name, value }) => (
      <Option key={value} value={value}>
        {name}
      </Option>
    ));

    const validation = Yup.object().shape({
      userName: Yup.string().required(intl.get('required')),
      email: Yup.string()
        .email(intl.get('emailValidator'))
        .required(intl.get('required')),
      firstName: Yup.string().required(intl.get('required')),
      lastName: Yup.string().required(intl.get('required')),
      password: Yup.string()
        .min(6, intl.get('resetPassword.minPassword'))
        .matches(/\d/, intl.get('resetPassword.containDigit'))
        .matches(passwordPattern, intl.get('resetPassword.passwordPattern'))
        .required(intl.get('resetPassword.passwordRequired')),
      role: Yup.string().required(intl.get('required')),
      supplierId: Yup.string().when('role', {
        is: (role) =>
          role === ROLES.SUPPLIER_ADMIN.value ||
          role === ROLES.SUPPLIER_CLERK.value,
        then: Yup.string().required('required'),
      }),
      agency: Yup.string().when('role', {
        is: (role) =>
          role === ROLES.AGENT.value || role === ROLES.MANAGER.value,
        then: Yup.string().required('required'),
      }),
    });

    const agenciesOptions = agencies.map((agency) => (
      <Option key={agency.Id} value={agency.Id}>
        {agency.Name}
      </Option>
    ));

    const suppliersOptions = suppliers.map((supplier) => (
      <Option key={supplier.Id} value={supplier.Id}>
        {supplier.Name}
      </Option>
    ));

    return (
      <div>
        <Formik
          initialValues={initialFormValues}
          validationSchema={validation}
          validateOnChange
          onSubmit={this.handleAddNewUserSubmit}
        >
          {({ values }) => (
            <Form>
              <div className="m-v-20 m-h-20">
                <div className="text-center m-b-30">
                  <span className="f-w-5 f-s-17">
                    {intl.get('profile.roleManagement.newUserRegistration')}
                  </span>
                </div>
                <div>
                  <Field
                    name="userName"
                    component={InputContainer}
                    placeholder={intl.get('labels.username')}
                    prefix={<i className="tc-user input-prefix" />}
                    disabled={isRegisterApiLoading}
                  />
                  <Field
                    name="email"
                    component={InputContainer}
                    placeholder={intl.get('labels.email')}
                    disabled={isRegisterApiLoading}
                  />
                  <Field
                    name="firstName"
                    component={InputContainer}
                    placeholder={intl.get('labels.firstName')}
                    disabled={isRegisterApiLoading}
                  />
                  <Field
                    name="lastName"
                    component={InputContainer}
                    placeholder={intl.get('labels.lastName')}
                    disabled={isRegisterApiLoading}
                  />
                  <Field
                    name="password"
                    component={InputContainer}
                    placeholder={intl.get('labels.password')}
                    type="password"
                    disabled={isRegisterApiLoading}
                  />
                  <Field
                    name="role"
                    component={SelectContainer}
                    placeholder={intl.get('profile.roleManagement.userRole')}
                    disabled={isRegisterApiLoading}
                  >
                    {roleChildren}
                  </Field>
                  {(values.role === ROLES.SUPPLIER_ADMIN.value ||
                    values.role === ROLES.SUPPLIER_CLERK.value) && (
                    <Field
                      loading={isSuppliersLoading}
                      disabled={isRegisterApiLoading}
                      name="supplierId"
                      placeholder={intl.get('labels.supplier')}
                      component={SelectContainer}
                    >
                      {suppliersOptions}
                    </Field>
                  )}
                  {(values.role === ROLES.AGENT.value ||
                    values.role === ROLES.MANAGER.value) && (
                    <Field
                      loading={isAgenciesLoading}
                      className="w-100"
                      name="agency"
                      placeholder={intl.get('labels.agency')}
                      component={SelectContainer}
                    >
                      {agenciesOptions}
                    </Field>
                  )}
                </div>
                {isFormDataIncorrect && (
                  <div>
                    <ul>
                      {serverErrors.map((error) => (
                        <li>{error}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="m-t-30 d-f jc-c">
                  <Button
                    size="large"
                    htmlType="submit"
                    className="default-button p-h-50"
                    disabled={isRegisterApiLoading}
                    loading={isRegisterApiLoading}
                  >
                    {intl.get('buttons.add')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
