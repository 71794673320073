import { observable, action, computed, makeObservable } from 'mobx';
import intl from 'react-intl-universal';

import { SuppliersApi } from '../api/suppliers.api';

import { RootStore } from './root.store';

import { addGlobalMessage } from '../components/SharedComponents/GlobalMessages';
import { MessageTypeEnum } from '../models/global-messages/message.model';
import { SupplierModel } from '../models/contacts/supplier.model';
import { checkUserGroup } from '../utils/userRolesUtils';
import { ROLES, USER_ROLE_GROUPS } from '../utils/constants';

export class SuppliersStore {
  private rootStore: RootStore;
  private apiService: SuppliersApi;

  @observable public suppliers: Array<SupplierModel> = [];
  @observable public isSuppliersLoading = false;
  @observable public isSupplierLoading = false;

  constructor(rootStore: RootStore, apiService: SuppliersApi) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.apiService = apiService;
  }

  @computed
  public get userSupplier() {
    const { userProperties } = this.rootStore.userStore;

    return this.getSupplierById(userProperties?.Operator_Code);
  }

  @computed
  public get isUserDefaultSupplier() {
    const { userProperties } = this.rootStore.userStore;
    const { settings } = this.rootStore.commonStore;

    return (
      userProperties.Operator_Code === null ||
      userProperties.Operator_Code === settings.OperatorId
    );
  }

  @computed
  public get isSupplierSelectable() {
    const { userProperties } = this.rootStore.userStore;

    return (
      this.isUserDefaultSupplier &&
      checkUserGroup(userProperties, [
        ROLES.STAFF.value,
        ROLES.STAFF_ADMIN.value,
      ])
    );
  }

  public getSupplierById(id: string) {
    return this.suppliers.find((supplier) => supplier.Id === id);
  }

  @action
  public async checkAndLoadSuppliers() {
    const { userStore } = this.rootStore;

    const shouldLoad = checkUserGroup(userStore.userProperties, [
      ...USER_ROLE_GROUPS.SUPPLIER,
      ...USER_ROLE_GROUPS.STAFF,
    ]);

    if (!shouldLoad) return;

    await this.getSuppliers();
  }

  @action
  public async getSuppliers() {
    try {
      this.isSuppliersLoading = true;
      const response = await this.apiService.getSuppliers();
      const { data } = response;
      const suppliers = data.value;

      this.suppliers = observable(suppliers);

      this.isSuppliersLoading = false;

      return Promise.resolve();
    } catch (error) {
      this.isSuppliersLoading = false;

      addGlobalMessage({
        message: intl.get('contacts.suppliers.failedToLoadSuppliers'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async createSupplier(supplier) {
    try {
      this.isSupplierLoading = true;

      await this.apiService.createSupplier(supplier);

      this.isSupplierLoading = false;

      addGlobalMessage({
        message: intl.get(
          'contacts.suppliers.supplierHasBeenSuccessfullyCreated',
        ),
        type: MessageTypeEnum.success,
      });

      this.getSuppliers();

      return Promise.resolve();
    } catch (error) {
      this.isSupplierLoading = false;

      addGlobalMessage({
        message: intl.get('contacts.suppliers.failedToCreateSupplier'),
        type: MessageTypeEnum.error,
      });

      return Promise.reject();
    }
  }

  @action
  public async editSupplier(
    supplierId: string,
    supplier: Partial<SupplierModel>,
  ) {
    try {
      this.isSupplierLoading = true;

      await this.apiService.editSupplier(supplierId, supplier);

      this.isSupplierLoading = false;

      addGlobalMessage({
        message: intl.get(
          'contacts.suppliers.supplierHasBeenSuccessfullyEdited',
        ),
        type: MessageTypeEnum.success,
      });

      this.getSuppliers();

      return Promise.resolve();
    } catch (error) {
      this.isSupplierLoading = false;

      addGlobalMessage({
        message: intl.get('contacts.suppliers.failedToEditSupplier'),
        type: MessageTypeEnum.error,
      });

      return Promise.reject();
    }
  }

  @action
  public clearStore() {
    this.suppliers = [];
    this.isSuppliersLoading = false;
  }
}
