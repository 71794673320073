import React, { Component } from 'react';
import intl from 'react-intl-universal';
import classNames from 'classnames';

import { OrderDocTypesModel } from '../../../../models/orders/orderDocTypes.model';
import { TypeOfDocumentEnum } from '../../../../models/enums/typeOfDocument.enum';
import { OrdersStore } from '../../../../stores/orders.store';
import { getDocumentName } from '../utils/documentsUtils';

export default class OrderDownloads extends Component<{
  docTypes: OrderDocTypesModel[];
  tripId: number;
  orderStore: OrdersStore;
  isDownloading: boolean;
  closePopup: any;
  startFileDownloading: Function;
  finishFileDownloading: Function;
}> {
  handleDownloadAllClick = () => {
    const { docTypes, tripId, finishFileDownloading, startFileDownloading } =
      this.props;
    const isMultiple = true;

    startFileDownloading();
    const downloads = docTypes.map((docType) => {
      const name = getDocumentName(docType.Name);

      return this.handleDownloadClick(docType, name, tripId, isMultiple);
    });

    Promise.all(downloads).finally(() => finishFileDownloading());
  };

  handleDownloadClick = (
    doc: OrderDocTypesModel,
    name: string,
    tripId: number,
    isMultiple?: boolean,
  ) => {
    const {
      orderStore,
      isDownloading,
      closePopup,
      startFileDownloading,
      finishFileDownloading,
    } = this.props;

    if (isDownloading) {
      return;
    }

    startFileDownloading();
    closePopup();

    if (doc.Name === TypeOfDocumentEnum.Voucher) {
      orderStore
        .downloadOrderDocumentByUrlVoucher('pdf', name, tripId)
        .finally(() => {
          finishFileDownloading();
        });
      return;
    }

    if (isMultiple) {
      return orderStore
        .downloadOrderDocumentByUrl(doc.RetrieveUri, 'pdf', name, tripId)
        .finally(() => {
          finishFileDownloading();
        });
    }

    orderStore
      .downloadOrderDocumentByUrl(doc.RetrieveUri, 'pdf', name, tripId)
      .finally(() => {
        finishFileDownloading();
      });
  };

  render() {
    const { docTypes, tripId, isDownloading } = this.props;

    // eslint-disable-next-line eqeqeq
    if (docTypes.length == 0) return null;

    const docs = docTypes.map((docType) => {
      const name = getDocumentName(docType.Name);

      return (
        <div key={docType.Name}>
          <span onClick={() => this.handleDownloadClick(docType, name, tripId)}>
            {`${intl.get('orders.popup.download')} ${name}`}
          </span>
        </div>
      );
    });

    return (
      <div
        className={classNames('order-download-content', {
          disabled: isDownloading,
        })}
      >
        <div>
          <span onClick={this.handleDownloadAllClick}>
            {intl.get('orders.popup.all')}
          </span>
        </div>
        {docs}
      </div>
    );
  }
}
