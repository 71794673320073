/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Field } from 'formik';

import { TableEditableCellProps } from 'models/table/tableEditableCell.props';
import { EditableCellInputEnum } from 'models/enums/editableCellInput.enum';
import {
  DatepickerContainer,
  InputContainer,
  InputNumberContainer,
  SelectContainer,
  TimePickerContainer,
  PhoneContainer,
} from '../FormContainers';

function TableEditableCell(props: TableEditableCellProps) {
  const {
    isEditing,
    dataIndex,
    isEditable,
    record,
    children,
    inputProps,
    ...restProps
  } = props;
  const { inputType, ...fieldProps } = inputProps ?? {};

  const getFieldComponent = () => {
    switch (inputType) {
      case EditableCellInputEnum.Select:
        return SelectContainer;

      case EditableCellInputEnum.Number:
        return InputNumberContainer;

      case EditableCellInputEnum.Date:
        return DatepickerContainer;

      case EditableCellInputEnum.Time:
        return TimePickerContainer;

      case EditableCellInputEnum.Phone:
        return PhoneContainer;

      case EditableCellInputEnum.Text:
      default:
        return InputContainer;
    }
  };

  const FieldComponent = getFieldComponent();

  return (
    <td {...restProps}>
      {isEditable && isEditing ? (
        <div className="editable-cell" onClick={(e) => e.stopPropagation()}>
          <Field name={dataIndex} component={FieldComponent} {...fieldProps} />
        </div>
      ) : (
        children
      )}
    </td>
  );
}

export default React.memo(TableEditableCell);
