import React, { Component } from 'react';
import { Button, Checkbox, Radio, Space } from 'antd';
import intl from 'react-intl-universal';
import { WarningOutlined } from '@ant-design/icons';

import InputNumberStepper from '../../../../SharedComponents/InputNumberStepper';
import { BulkInventoryStore } from '../../../../../stores/bulkInventory.store';
import { InventoryEditTypeEnum } from '../../../../../models/enums/inventoryEditType.enum';
import { withStore } from '../../../../../hocs';

type Props = {
  bulkInventoryStore?: BulkInventoryStore;
};

@withStore(({ rootStore }) => ({
  bulkInventoryStore: rootStore.bulkInventoryStore,
}))
export default class InventoryBulkViewEdit extends Component<Props> {
  state = {
    isApplyLoading: false,
  };

  handleApply = async () => {
    const { bulkInventoryStore } = this.props;

    try {
      this.setState({ isApplyLoading: true });

      await bulkInventoryStore.saveEditedInventories();
    } finally {
      this.setState({ isApplyLoading: false });
    }
  };

  render() {
    const { bulkInventoryStore } = this.props;

    const { isApplyLoading } = this.state;

    const {
      isAbleEdit,
      isDisplaySelectionWarning,
      selectedRowsCount,
      editType,
      availabilityQuantity,
      stopSell,
      changeEditType,
      changeAvailabilityQuantity,
      changeStopSell,
      resetSelectedRows,
    } = bulkInventoryStore;

    return (
      <Space direction="vertical" align="end">
        <Space align="center" className="inventory-bulk-view-edit">
          <Radio.Group
            value={editType}
            onChange={(ev) => changeEditType(ev.target.value)}
          >
            <Radio value={InventoryEditTypeEnum.Increase}>
              {`${intl.get(
                'inventory.bulkView.increaseBy',
              )} ${availabilityQuantity}`}
            </Radio>
            <Radio value={InventoryEditTypeEnum.Decrease}>
              {`${intl.get(
                'inventory.bulkView.decreaseBy',
              )} ${availabilityQuantity}`}
            </Radio>
            <Radio value={InventoryEditTypeEnum.SetTo}>
              {`${intl.get(
                'inventory.bulkView.setTo',
              )} ${availabilityQuantity}`}
            </Radio>
          </Radio.Group>

          <InputNumberStepper
            min={editType === InventoryEditTypeEnum.SetTo ? null : 1}
            value={availabilityQuantity}
            style={{ maxWidth: 140 }}
            onChange={(value) => changeAvailabilityQuantity(value)}
          />

          <Checkbox
            checked={stopSell}
            onChange={(ev) => changeStopSell(ev.target.checked)}
          >
            {intl.get('inventory.bulkView.stopSell')}
          </Checkbox>

          <Button
            type="primary"
            loading={isApplyLoading}
            disabled={!isAbleEdit}
            onClick={this.handleApply}
          >
            {intl.get('buttons.apply')}
          </Button>

          <Button onClick={() => resetSelectedRows()}>
            {intl.get('buttons.clearSelection')}
          </Button>
        </Space>

        {isDisplaySelectionWarning && (
          <div className="as-fe">
            <WarningOutlined style={{ color: 'orange' }} />{' '}
            {intl.get('inventory.bulkView.selectionWarning', {
              selectedRowsCount,
            })}
          </div>
        )}
      </Space>
    );
  }
}
