import { PaymentMethodProfileModel } from './paymentMethodProfile.model';
import { PaymentMethodModel } from './paymentMethod.model';
import { PaymentDueAgingModel } from './paymentDueAging.model';

export class FinanceInfoModel {
  AllowElectronicPayment: boolean;
  CreditBalance: number;
  FundsBalance: number;
  CreditPercentRemaining: number;
  CardCodeRequired: boolean;
  Profiles: PaymentMethodProfileModel[];
  PaymentMethods: PaymentMethodModel[];
  PaymentDueAging: PaymentDueAgingModel[];

  constructor() {
    this.AllowElectronicPayment = false;
    this.CreditBalance = undefined;
    this.FundsBalance = undefined;
    this.CreditPercentRemaining = undefined;
    this.CardCodeRequired = undefined;
    this.Profiles = [];
    this.PaymentMethods = [];
    this.PaymentDueAging = [];
  }
}
