import React from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { AvailabilityLevelEnum } from '../../../../models/enums/availabilityLevel.enum';
import { GuestTypesEnum } from '../../../../utils/constants';
import { ServiceModel } from '../../../../models/service.model';
import { PENALTY_PRODUCT_ID } from '../constants';
import { NotificationStatusEnum } from '../../../../models/enums/notificationStatusEnum';
import { NotificationHistoryMethodType } from '../../../../models/enums/notificationHistoryMethodType.enum';

export const getPaymentStatus = (services: ServiceModel[]) => {
  const activeServices = services.filter(
    (service) =>
      service.AvailabilityLevel === AvailabilityLevelEnum.Administrative ||
      service.AvailabilityLevel === AvailabilityLevelEnum.Confirmed,
  );

  const isOrderAdministrative = activeServices.every(
    (service) => service.ProductId === PENALTY_PRODUCT_ID,
  );

  if (isEmpty(activeServices)) {
    return <span>{intl.get('orders.popup.cancelled')}</span>;
  }

  if (isOrderAdministrative) {
    return <span>{intl.get('orders.popup.administrative')}</span>;
  }

  return <span>{intl.get('orders.popup.confirmed')}</span>;
};

export const getStatusIcon = (status: AvailabilityLevelEnum) => {
  switch (status) {
    case AvailabilityLevelEnum.Confirmed: {
      return '✔';
    }
    case AvailabilityLevelEnum.OnRequest:
    case AvailabilityLevelEnum.PartlyConfirmed: {
      return '⮃';
    }
    case AvailabilityLevelEnum.Cancelled:
    case AvailabilityLevelEnum.Administrative: {
      return '✖';
    }
  }
};

export const getAgebandLabel = (type: GuestTypesEnum) => {
  switch (type) {
    case GuestTypesEnum.ADULT: {
      return intl.get('adult');
    }
    case GuestTypesEnum.CHD: {
      return intl.get('child');
    }
    case GuestTypesEnum.JUNIOR: {
      return intl.get('junior');
    }
    case GuestTypesEnum.INFANT: {
      return intl.get('infant');
    }
  }
};

export const getPaymentStatusIcon = (status: AvailabilityLevelEnum) => {
  switch (status) {
    case AvailabilityLevelEnum.Administrative:
    case AvailabilityLevelEnum.Confirmed: {
      return (
        <div>
          <i className="tc-check-circle confirmed" />
          <span className="label f-s-15 m-l-5">
            {intl.get('orders.popup.confirmed')}
          </span>
        </div>
      );
    }
    case AvailabilityLevelEnum.OnRequest:
    case AvailabilityLevelEnum.PartlyConfirmed: {
      return (
        <div>
          <i className="on-request tc-ic-pending">
            <i className="tc-custom-dots" />
          </i>
          <span className="label f-s-15 m-l-5">
            {intl.get('orders.popup.pending')}
          </span>
        </div>
      );
    }
    case AvailabilityLevelEnum.Cancelled: {
      return (
        <div>
          <i className="tc-remove-circle cancelled" />
          <span className="label f-s-15 m-l-5">
            {intl.get('orders.popup.cancelled')}
          </span>
        </div>
      );
    }
  }
};

export const getProductCancellationPenalty = (product: ServiceModel) => {
  const now = moment();
  const policies = product?.CancelInfo?.Policies;
  const startDate = moment(product?.StartDate);

  const actualPolicy = policies.find((policy, index) => {
    const nextPolicy = index < policies.length - 1 ? policies[index + 1] : null;

    if (!nextPolicy) {
      return true;
    }

    const nightsPriorMoment = startDate
      .clone()
      .subtract(policy.NightsPrior, 'days');
    const nightsNextPriorMoment = startDate
      .clone()
      .subtract(nextPolicy.NightsPrior, 'days');

    if (now.isBetween(nightsPriorMoment, nightsNextPriorMoment)) {
      return true;
    }

    return false;
  });

  const percentage = actualPolicy?.Percentage || 0;

  return (product.Price * percentage) / 100;
};

export const getNotificationStatusLabel = (status: NotificationStatusEnum) => {
  const statusLabels = {
    [NotificationStatusEnum.Queued]: intl.get('notificationStatus.queued'),
    [NotificationStatusEnum.Sent]: intl.get('notificationStatus.sent'),
    [NotificationStatusEnum.Delivered]: intl.get(
      'notificationStatus.delivered',
    ),
    [NotificationStatusEnum.Received]: intl.get('notificationStatus.received'),
    [NotificationStatusEnum.Failed]: intl.get('notificationStatus.failed'),
    [NotificationStatusEnum.Undelivered]: intl.get(
      'notificationStatus.undelivered',
    ),
    [NotificationStatusEnum.Canceled]: intl.get('notificationStatus.canceled'),
    [NotificationStatusEnum.Read]: intl.get('notificationStatus.read'),
    [NotificationStatusEnum.Click]: intl.get('notificationStatus.click'),
    [NotificationStatusEnum.SpamReport]: intl.get(
      'notificationStatus.spamReport',
    ),
    [NotificationStatusEnum.Unsubscribe]: intl.get(
      'notificationStatus.unsubscribe',
    ),
  };

  return statusLabels[status] ?? '';
};

export const getNotificationMethodLabel = (
  method: NotificationHistoryMethodType,
) => {
  const methodLabels = {
    [NotificationHistoryMethodType.SMS]: intl.get('notificationMethod.sms'),
    [NotificationHistoryMethodType.Email]: intl.get('notificationMethod.email'),
  };

  return methodLabels[method] ?? '';
};
