import { AxiosRequestConfig, AxiosPromise, ResponseType } from 'axios';
import { OrderRouterRoleEnum } from 'models/enums/orderRouterRole.enum';
import { CancelOrderModel } from '../models/cancelOrder.model';
import { OrderModel } from '../models/order.model';
import { BaseApi } from './base.api';
import { OrderTabsEnum } from '../models/enums/orderTabs.enum';
import { OrdersFilterSuggestionModel } from '../models/orders/ordersFilterSuggestion.model';

export class OrdersApi extends BaseApi {
  public getOrders(
    routeRole: OrderRouterRoleEnum,
    tab: OrderTabsEnum,
    config?: AxiosRequestConfig,
  ): AxiosPromise<OrderModel[]> {
    return this.axios({
      method: 'get',
      url: `/trips/bookings/items/${routeRole}/${tab}`,
      ...config,
    });
  }

  public getOrderDetailsByTripId(tripId: number) {
    const params = {
      itineraryForm: true,
    };

    return this.axios.get(`trips/${tripId}`, {
      params,
    });
  }

  public sendOrderReConfirm(tripId: number, emails: string) {
    const body = `"${emails}"`;
    const config = {
      headers: {
        'Content-Type': 'text/json',
      },
    };

    return this.axios.post(
      `/documentation/trips/${tripId}/confirmation/send`,
      body,
      config,
    );
  }

  public getOrderFinance(tripId: number) {
    return this.axios.get(`/trips/${tripId}/finance`);
  }

  public cancelOrder(data: CancelOrderModel) {
    return this.axios.delete('trips/cancel', { data });
  }

  public async getVoucherEmailAddresses(tripId: string) {
    return this.axios.get(
      `/documentation/trips/${tripId}/voucher/emailaddresses`,
    );
  }

  public async getInvoiceEmailAddresses(tripId: string) {
    return this.axios.get(
      `/documentation/trips/${tripId}/invoice/emailaddresses`,
    );
  }

  public async getReceiptEmailAddresses(tripId: string) {
    return this.axios.get(
      `/documentation/trips/${tripId}/receipt/emailaddresses`,
    );
  }

  public async getOrderDocumentByUrl(
    url: string,
    responseType: ResponseType = 'blob',
  ) {
    return this.axios.get(url, { responseType });
  }

  public async getFilterOptions(
    routeRole: OrderRouterRoleEnum,
    tab: OrderTabsEnum,
    field: string,
    config?: AxiosRequestConfig,
  ) {
    return this.axios.get<OrdersFilterSuggestionModel[]>(
      `/filters/suggester-options/items/${routeRole}/${tab}/${field}`,
      config,
    );
  }
}
