import React from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';

import { Button } from 'antd';

import { supportEmail } from '../../../../utils/constants';

export default function ForgotPasswordEmailSent() {
  return (
    <div className="login-page">
      <div className="login-form">
        <div className="form-wrapper">
          <h1 className="login-header">{intl.get('emailSentTitle')}</h1>
          <div>
            <p>{intl.get('authPages.forgotPasswordEmailSent.0')}</p>
            <p>{intl.get('authPages.forgotPasswordEmailSent.1')}</p>
          </div>
          <div className="form-actions">
            <div className="login-page-bottom forgot-page-bottom">
              <Button
                className="login-button"
                type="primary"
                htmlType="submit"
                size="large"
              >
                <Link to="/login">{intl.get('buttons.backToLogin')}</Link>
              </Button>
            </div>
          </div>
          <div className="support-message">{intl.get('supportMessage')}</div>
          <div className="support-message">
            {intl.get('contactUs')}{' '}
            <a className="support-email" href={`mailto:${supportEmail}`}>
              {supportEmail}
            </a>
          </div>
        </div>
      </div>
      <aside className="right-container">
        <img className="logo" src="/logo.png" alt="logo" />
      </aside>
    </div>
  );
}
