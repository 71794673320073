import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input, Select, Skeleton, Space } from 'antd';
import { FieldProps, getIn } from 'formik';
import classnames from 'classnames';
import IMask, { FactoryOpts } from 'imask';
import intl from 'react-intl-universal';

import { useStore } from 'hooks';
import { phoneCodes, phoneCodesPreferable } from 'utils/phoneCodes';
import { getCountryFlagUrl } from 'utils/urlUtils';
import { delay } from 'lodash';

type OwnProps = {
  label?: string;
  className?: string;
};

type Props = OwnProps & FieldProps;

function PhoneContainer(props: Props) {
  const { field, form, label, className } = props;

  const searchRef = useRef(null);
  const [code, setCode] = useState(undefined);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(undefined);
  const initialFieldValue = useRef(field.value).current;
  const messagesStore = useStore((state) => state.messagesStore);

  const fieldError = getIn(form.errors, field.name);
  const isTouched = getIn(form.touched, field.name);
  const hasError = fieldError && isTouched;

  const formatPhoneCode = (phoneCode: string) => `+${phoneCode}`;

  const formatOptionLabel = (phoneCode: (typeof phoneCodes)[number]) =>
    `${phoneCode.countryName} (${formatPhoneCode(phoneCode.phoneCode)})`;

  const makeOptionValue = (phoneCode: string, countryCode: string) =>
    [phoneCode, countryCode].join(':');

  const parseOptionValue = (value = '') => value.split(':');

  const getMaskOptions = (phoneCode: string): FactoryOpts => {
    const MASK_LENGTH = 15;
    const [parsedCode] = parseOptionValue(phoneCode);
    const length = parsedCode ? MASK_LENGTH - parsedCode.length : MASK_LENGTH;
    const formattedCode = formatPhoneCode(parsedCode);
    const codeSubMask = parsedCode ? `{${formattedCode}}` : '';
    const numberSubMask = '0'.repeat(length);

    return {
      mask: `${codeSubMask}${numberSubMask}`,
      prepare: (chars) => {
        const isCodeValid = !chars || chars.startsWith(formattedCode);

        if (isCodeValid) return chars;

        const rightSide = chars.length < formattedCode.length ? '' : chars;

        return `${formattedCode}${rightSide}`;
      },
      overwrite: true,
    };
  };

  const getFlagImg = (countryCode: string) => (
    <img
      className="phone-container-flag-img"
      alt={countryCode}
      src={getCountryFlagUrl(countryCode.toLowerCase())}
    />
  );

  const getIsSearchMatch = (phoneCode: (typeof phoneCodes)[number]) =>
    phoneCode.countryName.toLowerCase().startsWith(search.toLowerCase());

  const getOption = (phoneCode: (typeof phoneCodes)[number]) => {
    const value = makeOptionValue(phoneCode.phoneCode, phoneCode.countryCode);
    const flagImg = getFlagImg(phoneCode.countryCode);
    const formattedLabel = formatOptionLabel(phoneCode);

    return !search || getIsSearchMatch(phoneCode)
      ? {
          key: value,
          value,
          formattedLabel,
          label: (
            <Space>
              {flagImg}

              <span>{formattedLabel}</span>
            </Space>
          ),
          flag: flagImg,
        }
      : null;
  };

  const getPhoneNumber = (phoneCode: string, fullPhone: string) => {
    const [parsedCode] = parseOptionValue(phoneCode);
    const formattedCode = formatPhoneCode(parsedCode);
    const startIndex = fullPhone.startsWith(formattedCode)
      ? formattedCode.length
      : 0;

    return fullPhone.slice(startIndex);
  };

  const mapOptions = (codes: typeof phoneCodes) =>
    codes.map(getOption).filter(Boolean);

  const options = [
    ...mapOptions(phoneCodesPreferable),
    ...mapOptions(phoneCodes).sort((a, b) => (a.label < b.label ? -1 : 1)),
  ];

  const getPhoneResource = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await messagesStore.getPhoneResource(initialFieldValue);

      setIsLoading(false);

      return response;
    } catch (e) {
      setIsLoading(false);

      return null;
    }
  }, []);

  const initPhoneCode = useCallback(async () => {
    if (initialFieldValue) {
      const response = await getPhoneResource();

      if (response?.calling_country_code) {
        onChangeCode(
          makeOptionValue(response.calling_country_code, response.country_code),
        );

        return;
      }
    }

    onChangeCode(options[0].value);
  }, [getPhoneResource]);

  useEffect(() => {
    initPhoneCode();
  }, [initPhoneCode]);

  const onChange = (value: string, phoneCode = code) => {
    const maskInstance = IMask.createMask(getMaskOptions(phoneCode));

    maskInstance.resolve(value);

    form.setFieldValue(field.name, maskInstance.value);
  };

  const onChangeCode = (phoneCode: string) => {
    setCode((oldPhoneCode: string) => {
      const [parsedPhoneCode] = parseOptionValue(phoneCode);
      const phoneNumber = getPhoneNumber(
        oldPhoneCode || phoneCode,
        field.value,
      );
      const newValue = `${formatPhoneCode(parsedPhoneCode)}${phoneNumber}`;

      onChange(newValue, phoneCode);

      return phoneCode;
    });
  };

  const onChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onChangeDropdown = (open: boolean) => {
    if (open) {
      delay(() => searchRef.current.focus(), 300);
    } else {
      setSearch('');
    }
  };

  if (isLoading) return <Skeleton.Input block active />;

  return (
    <div className={classnames('field', { 'has-error': hasError }, className)}>
      {label && <h4 className="field-label">{label}</h4>}
      <Input
        {...field}
        addonBefore={
          <Select
            className="phone-container-select"
            optionLabelProp="flag"
            dropdownMatchSelectWidth={false}
            value={code}
            options={options}
            style={{ width: 80 }}
            dropdownRender={(menu) => (
              <>
                <div className="ant-table-filter-dropdown-search">
                  <Input
                    ref={searchRef}
                    placeholder={intl.get('labels.searchInPhoneCodes')}
                    value={search}
                    onChange={onChangeSearch}
                  />
                </div>
                {menu}
              </>
            )}
            onDropdownVisibleChange={onChangeDropdown}
            onChange={onChangeCode}
          />
        }
        onChange={onChangeNumber}
      />
      {hasError && <div className="field-error visible">{fieldError}</div>}
    </div>
  );
}

export default PhoneContainer;
