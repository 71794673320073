import React from 'react';
import { Formik, FormikHelpers, FormikProps, yupToFormErrors } from 'formik';

import { ProductAvailabilityCreateFormModel } from 'models/inventory/productAvailabilityCreateForm.model';
import { ProductAvailabilityFiltersFormModel } from 'models/inventory/productAvailabilityFiltersForm.model';
import { InventoryEditTypeEnum } from 'models/enums/inventoryEditType.enum';
import { EventModel } from 'models/inventory/event.model';
import { ProductAvailabilityCreateFormSchema } from '../../../schemas';
import ProductAvailabilityCreateForm from './ProductAvailabilityCreateForm';

type Props = {
  innerRef?: React.Ref<FormikProps<ProductAvailabilityCreateFormModel>>;
  filtersFormValues?: ProductAvailabilityFiltersFormModel;
  selectedDaysEvents: { day: string; event: EventModel }[];
  getNumberOfEntriesToCreate?: (
    values: ProductAvailabilityCreateFormModel,
  ) => number;
  onSubmit: (
    values: ProductAvailabilityCreateFormModel,
    formikHelpers: FormikHelpers<ProductAvailabilityCreateFormModel>,
  ) => void;
};

function ProductAvailabilityCreate(props: Props) {
  const {
    innerRef,
    filtersFormValues,
    selectedDaysEvents,
    getNumberOfEntriesToCreate,
    onSubmit,
  } = props;

  const initialValues: ProductAvailabilityCreateFormModel = {
    notes: '',
    availabilityEditType: InventoryEditTypeEnum.SetTo,
    isCreateAvailability: false,
    isStopSell: false,
    availableQuantity: null,
    hold: undefined,
    bookingCutoffHours: undefined,
  };

  const validate = async (values: ProductAvailabilityCreateFormModel) => {
    try {
      await ProductAvailabilityCreateFormSchema.validate(values, {
        abortEarly: false,
        context: { filtersFormValues },
      });
    } catch (errors) {
      return yupToFormErrors(errors);
    }
  };

  return (
    <Formik
      innerRef={innerRef}
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      <ProductAvailabilityCreateForm
        getNumberOfEntriesToCreate={getNumberOfEntriesToCreate}
        selectedDaysEvents={selectedDaysEvents}
      />
    </Formik>
  );
}

export default ProductAvailabilityCreate;
