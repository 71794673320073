import React, { useEffect, useRef } from 'react';
import { Checkbox, Col, Input, InputRef, Row } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import intl from 'react-intl-universal';

import { TABLE_ORDERS_BLANK_FILTER_VALUE } from 'utils/constants';
import { TableOrdersSearchFilterPropsModel } from '../../models';
import TableOrdersFilterActions from './TableOrdersFilterActions';

type Props = FilterDropdownProps & TableOrdersSearchFilterPropsModel;

function TableOrdersSearchFilter(props: Props) {
  const { allowBlankEntry = false, inputProps, ...dropdownProps } = props;
  const { visible, selectedKeys, setSelectedKeys, confirm } = dropdownProps;
  const ref = useRef<InputRef>();

  const [selectedSearch, selectedBlank] = selectedKeys as string[];

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        ref.current?.focus();
      }, 100);
    }
  }, [visible]);

  const onChange = (keys: React.Key[]) => {
    setSelectedKeys(keys.every((key) => key === undefined) ? [] : keys);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange([event.target.value || undefined, selectedBlank]);

  const onBlankChange = (event: CheckboxChangeEvent) =>
    onChange([
      selectedSearch,
      event.target.checked ? TABLE_ORDERS_BLANK_FILTER_VALUE : undefined,
    ]);

  return (
    <div className="ant-table-filter-dropdown">
      <div className="ant-table-filter-dropdown-search">
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Input
              ref={ref}
              {...inputProps}
              value={selectedSearch}
              onChange={onSearchChange}
              onPressEnter={() => confirm()}
            />
          </Col>

          {allowBlankEntry && (
            <Col span={24}>
              <Checkbox
                checked={Boolean(selectedBlank)}
                onChange={onBlankChange}
              >
                {intl.get('blank')}
              </Checkbox>
            </Col>
          )}
        </Row>
      </div>

      <div className="ant-table-filter-dropdown-btns">
        <TableOrdersFilterActions {...dropdownProps} />
      </div>
    </div>
  );
}

export default TableOrdersSearchFilter;
