import { memo, useCallback, useRef } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { debounce } from 'lodash';

type Props = {
  delay?: number;
  onSubmit: (values: FormikValues) => void;
};

function FormikAutoSummit<P>(props: Props) {
  const { delay = 300, onSubmit } = props;
  const { values } = useFormikContext<P>();
  const isMounted = useRef(false);

  const debouncedOnSubmit = useCallback(debounce(onSubmit, delay), []);

  useDeepCompareEffect(() => {
    if (isMounted.current) {
      debouncedOnSubmit(values);
    } else {
      isMounted.current = true;
    }
  }, [values]);

  return null;
}

export default memo(FormikAutoSummit);
