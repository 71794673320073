import React, { Component } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';

import { MarketplaceStore } from '../../../stores/marketplace.store';
import ToursAndActivities from './ToursAndActivities';
import { withStore } from '../../../hocs';

@withStore(({ rootStore }) => ({
  marketplaceStore: rootStore.marketplaceStore,
}))
class Marketplace extends Component<{ marketplaceStore?: MarketplaceStore }> {
  state = {
    numberOfOpenedTab: 0,
  };

  handleSwitchTab = (numberOfTab: number) => {
    const { numberOfOpenedTab: currentTab } = this.state;

    if (numberOfTab !== currentTab) {
      this.setState({
        numberOfOpenedTab: numberOfTab,
      });
    }
  };

  render() {
    const { numberOfOpenedTab } = this.state;

    return (
      <div className="marketplace">
        <div className="head-tabs">
          <div
            onClick={() => this.handleSwitchTab(0)}
            className={classnames('tab-title', {
              active: numberOfOpenedTab === 0,
            })}
          >
            {intl.get('marketplacePage.toursAndActivities')}
          </div>
        </div>
        <div>
          {numberOfOpenedTab === 0 && <ToursAndActivities />}
          {numberOfOpenedTab === 1 && <span>The will be hotel component</span>}
        </div>
      </div>
    );
  }
}

export default Marketplace;
