import { action, makeObservable } from 'mobx';
import intl from 'react-intl-universal';

import { RootStore } from './root.store';
import { MessagesApi } from '../api/messages.api';
import { addGlobalMessage } from '../components/SharedComponents/GlobalMessages';
import { MessageTypeEnum } from '../models/global-messages/message.model';
import { PhoneResourceErrorEnum } from '../models/enums/phoneResourceError.enum';

export class MessagesStore {
  private rootStore: RootStore;
  private apiService: MessagesApi;

  constructor(rootStore: RootStore, apiService: MessagesApi) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.apiService = apiService;
  }

  @action
  public async sendSupportEmail(body) {
    try {
      await this.apiService.sendSupportEmail(body);
    } catch (error) {
      addGlobalMessage({
        message: intl.get('helpAndSupport.failedToSendEmail'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async getPhoneResource(phone: string) {
    const response = await this.apiService.getPhoneResource(phone);

    return response.data;
  }

  public mapPhoneErrorToLabel(error: PhoneResourceErrorEnum) {
    return (
      {
        [PhoneResourceErrorEnum.TooShort]: intl.get('phoneErrors.tooShort'),
        [PhoneResourceErrorEnum.TooLong]: intl.get('phoneErrors.tooLong'),
        [PhoneResourceErrorEnum.InvalidButPossible]: intl.get(
          'phoneErrors.invalidButPossible',
        ),
        [PhoneResourceErrorEnum.InvalidCountryCode]: intl.get(
          'phoneErrors.invalidCountryCode',
        ),
        [PhoneResourceErrorEnum.InvalidLength]: intl.get(
          'phoneErrors.invalidLength',
        ),
        [PhoneResourceErrorEnum.NotANumber]: intl.get('phoneErrors.notANumber'),
      }[error] ?? ''
    );
  }
}
