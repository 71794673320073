import React, { Component, Fragment } from 'react';
import pluralize from 'pluralize';
import intl from 'react-intl-universal';
import { Link, Location } from 'react-router-dom';
import { Button, Empty, Modal } from 'antd';

import { router } from 'router';
import { CheckoutStore } from '../../../stores/checkout.store';
import { CartStore } from '../../../stores/cart.store';

import { CardInCartModel } from '../../../models/cardInCart.model';
import CardInCart from './CardInCart';
import TourDetails from '../TourDetails/TourDetails';
import Checkout from '../Checkout/Checkout';
import { moneyFormat } from '../../../utils/formatter';
import { RouterProps, withRouter, withStore } from '../../../hocs';

type Props = {
  cartStore?: CartStore;
  checkoutStore?: CheckoutStore;
} & RouterProps;

@withRouter
@withStore(({ rootStore }) => ({
  cartStore: rootStore.cartStore,
  checkoutStore: rootStore.checkoutStore,
}))
export default class Cart extends Component<Props> {
  state = {
    isCheckoutPopupVisible: false,
    isProductDetailsPopupVisible: false,
    popupTourId: undefined,
    cardId: undefined,
  };

  componentDidMount() {
    const { location } = this.props;

    this.handleCartHistoryChange(location);

    router.subscribe((state) => {
      if (state.location.pathname === '/cart') {
        this.handleCartHistoryChange(state.location);
      }
    });
  }

  handleCartHistoryChange = (location: Location) => {
    const {
      cartStore: { cardsInCart },
      checkoutStore,
      navigate,
    } = this.props;

    const params = new URLSearchParams(location.search);

    if (params.has('isCheckoutOpened')) {
      if (cardsInCart.length > 0) {
        const isCheckoutPopupVisible = true;

        checkoutStore.initializeNewCheckout(cardsInCart);
        this.setState({ isCheckoutPopupVisible });
      } else {
        const searchParams = new URLSearchParams(location.search);

        params.delete('isCheckoutOpened');
        navigate(
          {
            search: searchParams.toString(),
          },
          { replace: true },
        );
      }
    } else {
      this.setState({ isCheckoutPopupVisible: false });
    }

    if (params.has('isProductPopupOpened') && params.has('popupTourId')) {
      const isProductDetailsPopupVisible = true;
      const popupTourId = params.get('popupTourId');
      // in case cardId is undefined it means that new product else - edit
      const cardId = params.get('cardId') || undefined;

      this.setState({
        isProductDetailsPopupVisible,
        popupTourId,
        cardId,
      });
    } else {
      this.setState({ isProductDetailsPopupVisible: false });
    }
  };

  handleNewProductOpen = (tourId: string) => {
    const { location, navigate } = this.props;

    const params = new URLSearchParams(location.search);

    params.set('isProductPopupOpened', 'true');
    params.set('popupTourId', `${tourId}`);
    params.delete('cardId');
    navigate(`/cart?${params.toString()}`);
  };

  handleEditCard = (tourId: string, cardId: string) => {
    const { location, navigate } = this.props;

    const params = new URLSearchParams(location.search);

    params.set('isProductPopupOpened', 'true');
    params.set('popupTourId', `${tourId}`);
    params.set('cardId', `${cardId}`);
    navigate({ search: params.toString() });
  };

  handleProductDetailsPopupClose = () => {
    const { location, navigate } = this.props;

    const params = new URLSearchParams(location.search);

    params.delete('isProductPopupOpened');
    params.delete('popupTourId');
    params.delete('cardId');
    navigate({ search: params.toString() });
  };

  handleCheckoutPopupClose = () => {
    this.props.navigate('/cart');
  };

  handleProceedToCheckoutClick = () => {
    const {
      cartStore: { cardsInCart },
      checkoutStore,
      location,
      navigate,
    } = this.props;
    const params = new URLSearchParams(location.search);

    checkoutStore.initializeNewCheckout(cardsInCart);
    params.set('isCheckoutOpened', 'true');
    navigate({ search: params.toString() });
  };

  renderPaymentSection = (cardsInCart: CardInCartModel[]) => {
    const total = cardsInCart.reduce(
      (sum, current) => sum + current.product.Price,
      0,
    );

    return (
      <Fragment>
        <div className="payment-total">
          <div className="fee-not-included" />
          <div className="cart-total-label">
            {intl.get('cartPage.cartTotal')}
          </div>
          <div className="total-price">{moneyFormat(total)} USD</div>
        </div>
        <Button
          onClick={this.handleProceedToCheckoutClick}
          type="primary"
          htmlType="button"
          size="large"
        >
          {intl.get('cartPage.proceedToCheckout')}
        </Button>
      </Fragment>
    );
  };

  renderCartItems = (cards: CardInCartModel[]) => {
    const { cartStore } = this.props;

    return (
      <Fragment>
        {cards.map((card) => (
          <CardInCart
            key={`${card.id}`}
            card={card}
            editCard={this.handleEditCard}
            removeCard={() => cartStore.removeCardFromCart(card.id)}
            onCardClick={this.handleNewProductOpen}
          />
        ))}
      </Fragment>
    );
  };

  renderProductDetailsPopup = () => {
    const { isProductDetailsPopupVisible, popupTourId, cardId } = this.state;

    return (
      <Modal
        destroyOnClose
        visible={isProductDetailsPopupVisible}
        footer={null}
        onCancel={this.handleProductDetailsPopupClose}
        wrapClassName="modal-tourschain product-details-modal"
        cancelButtonProps={{ size: 'large' }}
      >
        <TourDetails
          id={popupTourId}
          cardId={cardId}
          parentClassName="product-details-modal"
          closeDetails={this.handleProductDetailsPopupClose}
        />
      </Modal>
    );
  };

  renderCheckoutPopup = () => {
    const { isCheckoutPopupVisible } = this.state;

    return (
      <Modal
        destroyOnClose
        visible={isCheckoutPopupVisible}
        maskClosable={false}
        footer={null}
        onCancel={this.handleCheckoutPopupClose}
        wrapClassName="modal-tourschain checkout-modal"
        cancelButtonProps={{ size: 'large' }}
      >
        <Checkout closePopup={this.handleCheckoutPopupClose} />
      </Modal>
    );
  };

  render() {
    const {
      cartStore: { cardsInCart },
    } = this.props;

    return (
      <div className="cart-page">
        {cardsInCart.length > 0 ? (
          <div>
            <h2 className="header">
              <span>{intl.get('cartPage.yourCart')}</span>
              <span className="cart-count">
                (
                {pluralize(intl.get('cartPage.item'), cardsInCart.length, true)}
                )
              </span>
            </h2>
            <div className="cart-body">
              <div className="cards-section">
                {this.renderCartItems(cardsInCart)}
              </div>
              <aside className="cart-side">
                <div className="payment-section">
                  {this.renderPaymentSection(cardsInCart)}
                </div>
              </aside>
            </div>
            <div className="continue-shopping">
              <Link to="/marketplace?isContinueShopping=true">
                <i className="tc-arrow-left" />{' '}
                {intl.get('cartPage.continueShopping')}
              </Link>
            </div>
            {this.renderProductDetailsPopup()}
          </div>
        ) : (
          <Empty
            description={
              <span className="empty-cart-description">
                <span className="empty-cart-label">
                  {intl.get('cartPage.emptyCart')}
                </span>
                <span className="continue-shopping">
                  <Link to="/marketplace">
                    <i className="tc-arrow-left" />{' '}
                    {intl.get('cartPage.continueShopping')}
                  </Link>
                </span>
              </span>
            }
          />
        )}
        {this.renderCheckoutPopup()}
      </div>
    );
  }
}
