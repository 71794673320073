import { toNumber, toString } from 'lodash';
import moment from 'moment/moment';

import { CreateRateModel } from '../models/inventory/createRate.model';
import { FormRateModel } from '../models/inventory/formRate.model';
import { CommissionTypeEnum } from '../models/enums/commissionType.enum';
import { RegularRatesModel } from '../models/inventory/regularRates.model';
import { AgeTypeEnum } from '../models/enums/ageType.enum';
import RateAgeFormatter from './rateAgeFormatter';
import { RateGroup } from './rateGroup';
import { toServiceTime } from './datesUtils';
import { getDaysOfWeekInt } from './rateUtils';

export default class CreateRatesMapper {
  constructor(
    private readonly productId: string,
    private readonly rates: FormRateModel[],
  ) {}

  private getRateTypeProjection(): Record<
    AgeTypeEnum,
    (
      regularRate: RegularRatesModel,
      rate: Partial<CreateRateModel>,
    ) => Partial<CreateRateModel>
  > {
    const projectGroupableFields = this.createProjectGroupableFields();

    return {
      [AgeTypeEnum.Adult]: projectGroupableFields,
      [AgeTypeEnum.Group]: projectGroupableFields,
      [AgeTypeEnum.Child]: (regularRate: RegularRatesModel) => ({
        ChildAgeLimit: this.getRegularRateAge(regularRate),
        ChildRetail: toNumber(regularRate.Retail),
        ChildRate: toNumber(regularRate.Rate),
        ChildCost: toNumber(regularRate.Cost),
        ChildCostBeforeTax: toNumber(regularRate.CostBeforeTax),
      }),
      [AgeTypeEnum.Junior]: (regularRate: RegularRatesModel) => ({
        JuniorAgeLimit: this.getRegularRateAge(regularRate),
        JuniorRetail: toNumber(regularRate.Retail),
        JuniorRate: toNumber(regularRate.Rate),
        JuniorCost: toNumber(regularRate.Cost),
        JuniorCostBeforeTax: toNumber(regularRate.CostBeforeTax),
      }),
      [AgeTypeEnum.Senior]: (regularRate: RegularRatesModel) => ({
        SeniorAgeLimit: toString(regularRate.AgeMax),
        SeniorRetail: toNumber(regularRate.Retail),
        SeniorRate: toNumber(regularRate.Rate),
        SeniorCost: toNumber(regularRate.Cost),
        SeniorCostBeforeTax: toNumber(regularRate.CostBeforeTax),
      }),
      [AgeTypeEnum.Single]: (regularRate) => ({
        SingleRetail: toNumber(regularRate.Retail),
        SingleRate: toNumber(regularRate.Rate),
        SingleCost: toNumber(regularRate.Cost),
        SingleCostBeforeTax: toNumber(regularRate.CostBeforeTax),
      }),
    };
  }

  public map() {
    return this.rates.map((rate) => {
      const [startDate, endDate] = rate.InventoryDate;

      return Object.assign(
        new CreateRateModel(),
        {
          Id: rate.Id,
          AgencyId: rate.AgencyId,
          Visibility: rate.Visibility,
          CategoryId: rate.CategoryId,
          BookingStartDate: rate.BookingStartDate,
          BookingEndDate: rate.BookingEndDate,
          Description: rate.Description,
          IsAgencyRate: rate.IsAgencyRate,
          LastUpdate: rate.LastUpdate,
          DaysOfWeek: getDaysOfWeekInt(rate.DaysOfWeek as number[]),
          TransportationSegments: rate.TransportationSegments,
          TransportationDays: rate.TransportationDays,
          Inactive: rate.Inactive,
          ProductId: this.productId,
          CommissionPct:
            rate.CommissionType === CommissionTypeEnum.Percentage
              ? rate.CommissionPct
              : null,
          CommissionFlat:
            rate.CommissionType === CommissionTypeEnum.Flat
              ? rate.CommissionFlat
              : null,
          ServiceTime: rate.ServiceTime
            ? toServiceTime(rate.ServiceTime)
            : null,
          ServiceStartDate: moment(startDate).format('YYYY-MM-DD'),
          ServiceEndDate: moment(endDate).format('YYYY-MM-DD'),
        },
        this.mapRegularRatesToRequest(rate.RegularRates),
      );
    });
  }

  private mapRegularRatesToRequest = (regularRates: RegularRatesModel[]) => {
    const result: Partial<CreateRateModel> = {};
    const rateTypeProjection = this.getRateTypeProjection();

    regularRates.forEach((regularRate) => {
      Object.assign(
        result,
        rateTypeProjection[regularRate.Type]?.(regularRate, result),
      );
    });

    return result;
  };

  private createProjectGroupableFields() {
    let index = -1;

    return (regularRate: RegularRatesModel, rate: Partial<CreateRateModel>) => {
      index += 1;

      return {
        AdultRetail: new RateGroup(rate.AdultRetail)
          .add(toNumber(regularRate.Retail), index)
          .getValues(),
        AdultRate: new RateGroup(rate.AdultRate)
          .add(toNumber(regularRate.Rate), index)
          .getValues(),
        AdultCost: new RateGroup(rate.AdultCost)
          .add(toNumber(regularRate.Cost), index)
          .getValues(),
        AdultCostBeforeTax: new RateGroup(rate.AdultCostBeforeTax)
          .add(toNumber(regularRate.CostBeforeTax), index)
          .getValues(),
        UnitRate: new RateGroup(rate.UnitRate, false)
          .add(regularRate.Type === AgeTypeEnum.Group, index)
          .getValues(),
        MaxOccupancy: new RateGroup(rate.MaxOccupancy)
          .add(toNumber(regularRate.MaxOccupancy), index)
          .getValues(),
      };
    };
  }

  private getRegularRateAge(regularRate: RegularRatesModel) {
    return new RateAgeFormatter([
      regularRate.AgeMin,
      regularRate.AgeMax,
    ]).format();
  }
}
