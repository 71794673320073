import React, { PureComponent } from 'react';
import { Space, Radio, Button, Checkbox, Tooltip, Row, Col } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';
import { isEmpty } from 'lodash';
import { Field, FormikProps } from 'formik';

import { withStore } from 'hocs';
import { OrdersStore } from 'stores/orders.store';
import Accessor from 'components/SharedComponents/Accessor';
import { RadioGroupContainer } from 'components/FormContainers';
import { OrderCommunicationMethodEnum } from 'models/enums/orderCommunicationMethod.enum';
import { MANAGE_ORDERS_ROLES } from 'utils/constants';
import { BulkCommunicationFormValuesModel } from 'models/bulkCommunicationFormValues.model';
import OrdersCommunicationModal from '../OrdersCommunicationModal';
import TableOrdersSettings from './TableOrdersSettings';
import TableOrdersUserFilters from './TableOrdersUserFilters';

type Props = {
  ordersStore?: OrdersStore;
  isBulkCommunicationLoading: boolean;
  formikProps: FormikProps<BulkCommunicationFormValuesModel>;
  primaryMethod: string;
};

type State = {
  isCancelLoading: boolean;
  isCommunicationModalVisible: boolean;
};

@withStore(({ rootStore }) => ({
  ordersStore: rootStore.ordersStore,
}))
export default class TableOrdersActions extends PureComponent<Props, State> {
  state = {
    isCancelLoading: false,
    isCommunicationModalVisible: false,
  };

  get ordersToCancel() {
    const { formikProps } = this.props;

    return formikProps.values.selectedOrders.filter((order) => !order.Inactive);
  }

  handleCancelOrders = async () => {
    const { ordersStore } = this.props;

    try {
      this.setState({ isCancelLoading: true });

      await ordersStore.cancelSelectedOrders(this.ordersToCancel);
    } finally {
      this.setState({ isCancelLoading: false });
    }
  };

  handleCommunicationModalOpen = () => {
    this.setState({ isCommunicationModalVisible: true });
  };

  handleCommunicationModalClose = () => {
    this.setState({ isCommunicationModalVisible: false });
  };

  handleClearSelection = () => {
    const { formikProps } = this.props;

    formikProps.setFieldValue('selectedOrders', []);
  };

  render() {
    const {
      ordersStore,
      formikProps,
      isBulkCommunicationLoading,
      primaryMethod,
    } = this.props;

    const { isCancelLoading, isCommunicationModalVisible } = this.state;

    return (
      <Row align="middle" gutter={[0, 15]}>
        <Col span={24}>
          <Row align="middle" gutter={15}>
            <Accessor roles={MANAGE_ORDERS_ROLES}>
              <>
                <Col>
                  <span className="f-s-12 d-b">
                    {intl.getHTML('orders.table.communicationMethods.label')}
                  </span>
                </Col>

                <Col className="m-l-a">
                  <Space>
                    <span className="f-s-14">
                      {intl.get('orders.table.selectedOrders', {
                        ordersCount: formikProps.values.selectedOrders.length,
                      })}
                    </span>

                    <Button onClick={this.handleClearSelection}>
                      {intl.get('orders.table.clearSelection')}
                    </Button>
                  </Space>
                </Col>
              </>
            </Accessor>

            <Col>
              <TableOrdersUserFilters />
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row align="middle" gutter={15}>
            <Accessor roles={MANAGE_ORDERS_ROLES}>
              <>
                <Col>
                  <Checkbox
                    checked={ordersStore.isAllRowsExpanded}
                    indeterminate={ordersStore.isExpandedRowsIndeterminate}
                    onChange={(e) =>
                      ordersStore.toggleExpandAllRows(e.target.checked)
                    }
                  >
                    {intl.get('orders.table.showAdditionalGuests')}
                  </Checkbox>
                </Col>

                <Col>
                  <Field
                    name="communicationMethod"
                    className="m-b-0"
                    component={RadioGroupContainer}
                  >
                    <Radio value={OrderCommunicationMethodEnum.SMS}>
                      {intl.get('orders.table.communicationMethods.SMS')}
                    </Radio>

                    <Radio value={OrderCommunicationMethodEnum.Email}>
                      {intl.get('orders.table.communicationMethods.email')}
                    </Radio>

                    <Radio value={OrderCommunicationMethodEnum.FirstAvailable}>
                      {intl.get(
                        'orders.table.communicationMethods.firstAvailable',
                      )}{' '}
                      <Tooltip
                        overlay={intl.get(
                          'orders.table.communicationMethods.explanation',
                        )}
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Radio>
                  </Field>
                </Col>

                <Col>
                  <Button
                    type="primary"
                    disabled={isEmpty(formikProps.values.selectedOrders)}
                    onClick={this.handleCommunicationModalOpen}
                  >
                    {intl.get('orders.table.communicate')}
                  </Button>
                </Col>

                <Col>
                  <Button
                    danger
                    type="primary"
                    disabled={isEmpty(this.ordersToCancel)}
                    loading={isCancelLoading}
                    onClick={this.handleCancelOrders}
                  >
                    {intl.get('orders.table.cancelOrders', {
                      ordersCount: this.ordersToCancel.length,
                    })}
                  </Button>
                </Col>
              </>
            </Accessor>

            <Col className="m-l-a">
              <TableOrdersSettings />
            </Col>

            <OrdersCommunicationModal
              visible={isCommunicationModalVisible}
              formikProps={formikProps}
              isBulkCommunicationLoading={isBulkCommunicationLoading}
              primaryMethod={primaryMethod}
              onCancel={this.handleCommunicationModalClose}
            />
          </Row>
        </Col>
      </Row>
    );
  }
}
