export const phoneCodesPreferable = [
  {
    countryCode: 'US',
    countryName: 'United States',
    phoneCode: '1',
  },
  {
    countryCode: 'CN',
    countryName: 'China',
    phoneCode: '86',
  },
  {
    countryCode: 'TW',
    countryName: 'Taiwan',
    phoneCode: '886',
  },
  {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    phoneCode: '852',
  },
];

// array is sorted by countyName
export const phoneCodes = [
  {
    countryCode: 'AF',
    countryName: 'Afghanistan',
    phoneCode: '93',
  },
  {
    countryCode: 'AL',
    countryName: 'Albania',
    phoneCode: '355',
  },
  {
    countryCode: 'DZ',
    countryName: 'Algeria',
    phoneCode: '213',
  },
  {
    countryCode: 'AS',
    countryName: 'American Samoa',
    phoneCode: '1-684',
  },
  {
    countryCode: 'AD',
    countryName: 'Andorra',
    phoneCode: '376',
  },
  {
    countryCode: 'AO',
    countryName: 'Angola',
    phoneCode: '244',
  },
  {
    countryCode: 'AI',
    countryName: 'Anguilla',
    phoneCode: '1-264',
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    phoneCode: '672',
  },
  {
    countryCode: 'AG',
    countryName: 'Antigua and Barbuda',
    phoneCode: '1-268',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    phoneCode: '54',
  },
  {
    countryCode: 'AM',
    countryName: 'Armenia',
    phoneCode: '374',
  },
  {
    countryCode: 'AW',
    countryName: 'Aruba',
    phoneCode: '297',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    phoneCode: '61',
  },
  {
    countryCode: 'AT',
    countryName: 'Austria',
    phoneCode: '43',
  },
  {
    countryCode: 'AZ',
    countryName: 'Azerbaijan',
    phoneCode: '994',
  },
  {
    countryCode: 'BS',
    countryName: 'Bahamas',
    phoneCode: '1-242',
  },
  {
    countryCode: 'BH',
    countryName: 'Bahrain',
    phoneCode: '973',
  },
  {
    countryCode: 'BD',
    countryName: 'Bangladesh',
    phoneCode: '880',
  },
  {
    countryCode: 'BB',
    countryName: 'Barbados',
    phoneCode: '1-246',
  },
  {
    countryCode: 'BY',
    countryName: 'Belarus',
    phoneCode: '375',
  },
  {
    countryCode: 'BE',
    countryName: 'Belgium',
    phoneCode: '32',
  },
  {
    countryCode: 'BZ',
    countryName: 'Belize',
    phoneCode: '501',
  },
  {
    countryCode: 'BJ',
    countryName: 'Benin',
    phoneCode: '229',
  },
  {
    countryCode: 'BM',
    countryName: 'Bermuda',
    phoneCode: '1-441',
  },
  {
    countryCode: 'BT',
    countryName: 'Bhutan',
    phoneCode: '975',
  },
  {
    countryCode: 'BO',
    countryName: 'Bolivia',
    phoneCode: '591',
  },
  {
    countryCode: 'BA',
    countryName: 'Bosnia and Herzegovina',
    phoneCode: '387',
  },
  {
    countryCode: 'BW',
    countryName: 'Botswana',
    phoneCode: '267',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    phoneCode: '55',
  },
  {
    countryCode: 'IO',
    countryName: 'British Indian Ocean Territory',
    phoneCode: '246',
  },
  {
    countryCode: 'VG',
    countryName: 'British Virgin Islands',
    phoneCode: '1-284',
  },
  {
    countryCode: 'BN',
    countryName: 'Brunei',
    phoneCode: '673',
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    phoneCode: '359',
  },
  {
    countryCode: 'BF',
    countryName: 'Burkina Faso',
    phoneCode: '226',
  },
  {
    countryCode: 'BI',
    countryName: 'Burundi',
    phoneCode: '257',
  },
  {
    countryCode: 'KH',
    countryName: 'Cambodia',
    phoneCode: '855',
  },
  {
    countryCode: 'CM',
    countryName: 'Cameroon',
    phoneCode: '237',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    phoneCode: '1',
  },
  {
    countryCode: 'CV',
    countryName: 'Cape Verde',
    phoneCode: '238',
  },
  {
    countryCode: 'KY',
    countryName: 'Cayman Islands',
    phoneCode: '1-345',
  },
  {
    countryCode: 'CF',
    countryName: 'Central African Republic',
    phoneCode: '236',
  },
  {
    countryCode: 'TD',
    countryName: 'Chad',
    phoneCode: '235',
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    phoneCode: '56',
  },
  {
    countryCode: 'CX',
    countryName: 'Christmas Island',
    phoneCode: '61',
  },
  {
    countryCode: 'CC',
    countryName: 'Cocos Islands',
    phoneCode: '61',
  },
  {
    countryCode: 'CO',
    countryName: 'Colombia',
    phoneCode: '57',
  },
  {
    countryCode: 'KM',
    countryName: 'Comoros',
    phoneCode: '269',
  },
  {
    countryCode: 'CK',
    countryName: 'Cook Islands',
    phoneCode: '682',
  },
  {
    countryCode: 'CR',
    countryName: 'Costa Rica',
    phoneCode: '506',
  },
  {
    countryCode: 'HR',
    countryName: 'Croatia',
    phoneCode: '385',
  },
  {
    countryCode: 'CU',
    countryName: 'Cuba',
    phoneCode: '53',
  },
  {
    countryCode: 'CW',
    countryName: 'Curacao',
    phoneCode: '599',
  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
    phoneCode: '357',
  },
  {
    countryCode: 'CZ',
    countryName: 'Czech Republic',
    phoneCode: '420',
  },
  {
    countryCode: 'CD',
    countryName: 'Democratic Republic of the Congo',
    phoneCode: '243',
  },
  {
    countryCode: 'DK',
    countryName: 'Denmark',
    phoneCode: '45',
  },
  {
    countryCode: 'DJ',
    countryName: 'Djibouti',
    phoneCode: '253',
  },
  {
    countryCode: 'DM',
    countryName: 'Dominica',
    phoneCode: '1-767',
  },
  {
    countryCode: 'DO',
    countryName: 'Dominican Republic',
    phoneCode: '1-809 and 1-829',
  },
  {
    countryCode: 'TL',
    countryName: 'East Timor',
    phoneCode: '670',
  },
  {
    countryCode: 'EC',
    countryName: 'Ecuador',
    phoneCode: '593',
  },
  {
    countryCode: 'EG',
    countryName: 'Egypt',
    phoneCode: '20',
  },
  {
    countryCode: 'SV',
    countryName: 'El Salvador',
    phoneCode: '503',
  },
  {
    countryCode: 'GQ',
    countryName: 'Equatorial Guinea',
    phoneCode: '240',
  },
  {
    countryCode: 'ER',
    countryName: 'Eritrea',
    phoneCode: '291',
  },
  {
    countryCode: 'EE',
    countryName: 'Estonia',
    phoneCode: '372',
  },
  {
    countryCode: 'ET',
    countryName: 'Ethiopia',
    phoneCode: '251',
  },
  {
    countryCode: 'FK',
    countryName: 'Falkland Islands',
    phoneCode: '500',
  },
  {
    countryCode: 'FO',
    countryName: 'Faroe Islands',
    phoneCode: '298',
  },
  {
    countryCode: 'FJ',
    countryName: 'Fiji',
    phoneCode: '679',
  },
  {
    countryCode: 'FI',
    countryName: 'Finland',
    phoneCode: '358',
  },
  {
    countryCode: 'FR',
    countryName: 'France',
    phoneCode: '33',
  },
  {
    countryCode: 'GF',
    countryName: 'French Guiana',
    phoneCode: '594',
  },
  {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    phoneCode: '689',
  },
  {
    countryCode: 'GA',
    countryName: 'Gabon',
    phoneCode: '241',
  },
  {
    countryCode: 'GM',
    countryName: 'Gambia',
    phoneCode: '220',
  },
  {
    countryCode: 'GE',
    countryName: 'Georgia',
    phoneCode: '995',
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    phoneCode: '49',
  },
  {
    countryCode: 'GH',
    countryName: 'Ghana',
    phoneCode: '233',
  },
  {
    countryCode: 'GI',
    countryName: 'Gibraltar',
    phoneCode: '350',
  },
  {
    countryCode: 'GR',
    countryName: 'Greece',
    phoneCode: '30',
  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
    phoneCode: '299',
  },
  {
    countryCode: 'GD',
    countryName: 'Grenada',
    phoneCode: '1-473',
  },
  {
    countryCode: 'GP',
    countryName: 'Guadeloupe',
    phoneCode: '590',
  },
  {
    countryCode: 'GU',
    countryName: 'Guam',
    phoneCode: '1-671',
  },
  {
    countryCode: 'GT',
    countryName: 'Guatemala',
    phoneCode: '502',
  },
  {
    countryCode: 'GG',
    countryName: 'Guernsey',
    phoneCode: '44-1481',
  },
  {
    countryCode: 'GN',
    countryName: 'Guinea',
    phoneCode: '224',
  },
  {
    countryCode: 'GW',
    countryName: 'Guinea-Bissau',
    phoneCode: '245',
  },
  {
    countryCode: 'GY',
    countryName: 'Guyana',
    phoneCode: '592',
  },
  {
    countryCode: 'HT',
    countryName: 'Haiti',
    phoneCode: '509',
  },
  {
    countryCode: 'HM',
    countryName: 'Heard Island and McDonald Islands',
    phoneCode: ' ',
  },
  {
    countryCode: 'HN',
    countryName: 'Honduras',
    phoneCode: '504',
  },
  {
    countryCode: 'HU',
    countryName: 'Hungary',
    phoneCode: '36',
  },
  {
    countryCode: 'IS',
    countryName: 'Iceland',
    phoneCode: '354',
  },
  {
    countryCode: 'IN',
    countryName: 'India',
    phoneCode: '91',
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    phoneCode: '62',
  },
  {
    countryCode: 'IR',
    countryName: 'Iran',
    phoneCode: '98',
  },
  {
    countryCode: 'IQ',
    countryName: 'Iraq',
    phoneCode: '964',
  },
  {
    countryCode: 'IE',
    countryName: 'Ireland',
    phoneCode: '353',
  },
  {
    countryCode: 'IM',
    countryName: 'Isle of Man',
    phoneCode: '44-1624',
  },
  {
    countryCode: 'IL',
    countryName: 'Israel',
    phoneCode: '972',
  },
  {
    countryCode: 'IT',
    countryName: 'Italy',
    phoneCode: '39',
  },
  {
    countryCode: 'CI',
    countryName: 'Ivory Coast',
    phoneCode: '225',
  },
  {
    countryCode: 'JM',
    countryName: 'Jamaica',
    phoneCode: '1-876',
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    phoneCode: '81',
  },
  {
    countryCode: 'JE',
    countryName: 'Jersey',
    phoneCode: '44-1534',
  },
  {
    countryCode: 'JO',
    countryName: 'Jordan',
    phoneCode: '962',
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    phoneCode: '7',
  },
  {
    countryCode: 'KE',
    countryName: 'Kenya',
    phoneCode: '254',
  },
  {
    countryCode: 'KI',
    countryName: 'Kiribati',
    phoneCode: '686',
  },
  {
    countryCode: 'XK',
    countryName: 'Kosovo',
    phoneCode: '383',
  },
  {
    countryCode: 'KW',
    countryName: 'Kuwait',
    phoneCode: '965',
  },
  {
    countryCode: 'KG',
    countryName: 'Kyrgyzstan',
    phoneCode: '996',
  },
  {
    countryCode: 'LA',
    countryName: 'Laos',
    phoneCode: '856',
  },
  {
    countryCode: 'LV',
    countryName: 'Latvia',
    phoneCode: '371',
  },
  {
    countryCode: 'LB',
    countryName: 'Lebanon',
    phoneCode: '961',
  },
  {
    countryCode: 'LS',
    countryName: 'Lesotho',
    phoneCode: '266',
  },
  {
    countryCode: 'LR',
    countryName: 'Liberia',
    phoneCode: '231',
  },
  {
    countryCode: 'LY',
    countryName: 'Libya',
    phoneCode: '218',
  },
  {
    countryCode: 'LI',
    countryName: 'Liechtenstein',
    phoneCode: '423',
  },
  {
    countryCode: 'LT',
    countryName: 'Lithuania',
    phoneCode: '370',
  },
  {
    countryCode: 'LU',
    countryName: 'Luxembourg',
    phoneCode: '352',
  },
  {
    countryCode: 'MO',
    countryName: 'Macao',
    phoneCode: '853',
  },
  {
    countryCode: 'MK',
    countryName: 'Macedonia',
    phoneCode: '389',
  },
  {
    countryCode: 'MG',
    countryName: 'Madagascar',
    phoneCode: '261',
  },
  {
    countryCode: 'MW',
    countryName: 'Malawi',
    phoneCode: '265',
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    phoneCode: '60',
  },
  {
    countryCode: 'MV',
    countryName: 'Maldives',
    phoneCode: '960',
  },
  {
    countryCode: 'ML',
    countryName: 'Mali',
    phoneCode: '223',
  },
  {
    countryCode: 'MT',
    countryName: 'Malta',
    phoneCode: '356',
  },
  {
    countryCode: 'MH',
    countryName: 'Marshall Islands',
    phoneCode: '692',
  },
  {
    countryCode: 'MQ',
    countryName: 'Martinique',
    phoneCode: '596',
  },
  {
    countryCode: 'MR',
    countryName: 'Mauritania',
    phoneCode: '222',
  },
  {
    countryCode: 'MU',
    countryName: 'Mauritius',
    phoneCode: '230',
  },
  {
    countryCode: 'YT',
    countryName: 'Mayotte',
    phoneCode: '262',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    phoneCode: '52',
  },
  {
    countryCode: 'FM',
    countryName: 'Micronesia',
    phoneCode: '691',
  },
  {
    countryCode: 'MD',
    countryName: 'Moldova',
    phoneCode: '373',
  },
  {
    countryCode: 'MC',
    countryName: 'Monaco',
    phoneCode: '377',
  },
  {
    countryCode: 'MN',
    countryName: 'Mongolia',
    phoneCode: '976',
  },
  {
    countryCode: 'ME',
    countryName: 'Montenegro',
    phoneCode: '382',
  },
  {
    countryCode: 'MS',
    countryName: 'Montserrat',
    phoneCode: '1-664',
  },
  {
    countryCode: 'MA',
    countryName: 'Morocco',
    phoneCode: '212',
  },
  {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    phoneCode: '258',
  },
  {
    countryCode: 'MM',
    countryName: 'Myanmar',
    phoneCode: '95',
  },
  {
    countryCode: 'NA',
    countryName: 'Namibia',
    phoneCode: '264',
  },
  {
    countryCode: 'NR',
    countryName: 'Nauru',
    phoneCode: '674',
  },
  {
    countryCode: 'NP',
    countryName: 'Nepal',
    phoneCode: '977',
  },
  {
    countryCode: 'NL',
    countryName: 'Netherlands',
    phoneCode: '31',
  },
  {
    countryCode: 'NC',
    countryName: 'New Caledonia',
    phoneCode: '687',
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    phoneCode: '64',
  },
  {
    countryCode: 'NI',
    countryName: 'Nicaragua',
    phoneCode: '505',
  },
  {
    countryCode: 'NE',
    countryName: 'Niger',
    phoneCode: '227',
  },
  {
    countryCode: 'NG',
    countryName: 'Nigeria',
    phoneCode: '234',
  },
  {
    countryCode: 'NU',
    countryName: 'Niue',
    phoneCode: '683',
  },
  {
    countryCode: 'NF',
    countryName: 'Norfolk Island',
    phoneCode: '672',
  },
  {
    countryCode: 'KP',
    countryName: 'North Korea',
    phoneCode: '850',
  },
  {
    countryCode: 'MP',
    countryName: 'Northern Mariana Islands',
    phoneCode: '1-670',
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
    phoneCode: '47',
  },
  {
    countryCode: 'OM',
    countryName: 'Oman',
    phoneCode: '968',
  },
  {
    countryCode: 'PK',
    countryName: 'Pakistan',
    phoneCode: '92',
  },
  {
    countryCode: 'PW',
    countryName: 'Palau',
    phoneCode: '680',
  },
  {
    countryCode: 'PS',
    countryName: 'Palestinian Territory',
    phoneCode: '970',
  },
  {
    countryCode: 'PA',
    countryName: 'Panama',
    phoneCode: '507',
  },
  {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    phoneCode: '675',
  },
  {
    countryCode: 'PY',
    countryName: 'Paraguay',
    phoneCode: '595',
  },
  {
    countryCode: 'PE',
    countryName: 'Peru',
    phoneCode: '51',
  },
  {
    countryCode: 'PH',
    countryName: 'Philippines',
    phoneCode: '63',
  },
  {
    countryCode: 'PN',
    countryName: 'Pitcairn',
    phoneCode: '870',
  },
  {
    countryCode: 'PL',
    countryName: 'Poland',
    phoneCode: '48',
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    phoneCode: '351',
  },
  {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    phoneCode: '1-787 and 1-939',
  },
  {
    countryCode: 'QA',
    countryName: 'Qatar',
    phoneCode: '974',
  },
  {
    countryCode: 'CG',
    countryName: 'Republic of the Congo',
    phoneCode: '242',
  },
  {
    countryCode: 'RE',
    countryName: 'Reunion',
    phoneCode: '262',
  },
  {
    countryCode: 'RO',
    countryName: 'Romania',
    phoneCode: '40',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    phoneCode: '7',
  },
  {
    countryCode: 'RW',
    countryName: 'Rwanda',
    phoneCode: '250',
  },
  {
    countryCode: 'BL',
    countryName: 'Saint Barthelemy',
    phoneCode: '590',
  },
  {
    countryCode: 'SH',
    countryName: 'Saint Helena',
    phoneCode: '290',
  },
  {
    countryCode: 'KN',
    countryName: 'Saint Kitts and Nevis',
    phoneCode: '1-869',
  },
  {
    countryCode: 'LC',
    countryName: 'Saint Lucia',
    phoneCode: '1-758',
  },
  {
    countryCode: 'MF',
    countryName: 'Saint Martin',
    phoneCode: '590',
  },
  {
    countryCode: 'PM',
    countryName: 'Saint Pierre and Miquelon',
    phoneCode: '508',
  },
  {
    countryCode: 'VC',
    countryName: 'Saint Vincent and the Grenadines',
    phoneCode: '1-784',
  },
  {
    countryCode: 'WS',
    countryName: 'Samoa',
    phoneCode: '685',
  },
  {
    countryCode: 'SM',
    countryName: 'San Marino',
    phoneCode: '378',
  },
  {
    countryCode: 'ST',
    countryName: 'Sao Tome and Principe',
    phoneCode: '239',
  },
  {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    phoneCode: '966',
  },
  {
    countryCode: 'SN',
    countryName: 'Senegal',
    phoneCode: '221',
  },
  {
    countryCode: 'RS',
    countryName: 'Serbia',
    phoneCode: '381',
  },
  {
    countryCode: 'SC',
    countryName: 'Seychelles',
    phoneCode: '248',
  },
  {
    countryCode: 'SL',
    countryName: 'Sierra Leone',
    phoneCode: '232',
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    phoneCode: '65',
  },
  {
    countryCode: 'SX',
    countryName: 'Sint Maarten',
    phoneCode: '599',
  },
  {
    countryCode: 'SK',
    countryName: 'Slovakia',
    phoneCode: '421',
  },
  {
    countryCode: 'SI',
    countryName: 'Slovenia',
    phoneCode: '386',
  },
  {
    countryCode: 'SB',
    countryName: 'Solomon Islands',
    phoneCode: '677',
  },
  {
    countryCode: 'SO',
    countryName: 'Somalia',
    phoneCode: '252',
  },
  {
    countryCode: 'ZA',
    countryName: 'South Africa',
    phoneCode: '27',
  },
  {
    countryCode: 'KR',
    countryName: 'South Korea',
    phoneCode: '82',
  },
  {
    countryCode: 'SS',
    countryName: 'South Sudan',
    phoneCode: '211',
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    phoneCode: '34',
  },
  {
    countryCode: 'LK',
    countryName: 'Sri Lanka',
    phoneCode: '94',
  },
  {
    countryCode: 'SD',
    countryName: 'Sudan',
    phoneCode: '249',
  },
  {
    countryCode: 'SR',
    countryName: 'Suriname',
    phoneCode: '597',
  },
  {
    countryCode: 'SJ',
    countryName: 'Svalbard and Jan Mayen',
    phoneCode: '47',
  },
  {
    countryCode: 'SZ',
    countryName: 'Swaziland',
    phoneCode: '268',
  },
  {
    countryCode: 'SE',
    countryName: 'Sweden',
    phoneCode: '46',
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    phoneCode: '41',
  },
  {
    countryCode: 'SY',
    countryName: 'Syria',
    phoneCode: '963',
  },
  {
    countryCode: 'TJ',
    countryName: 'Tajikistan',
    phoneCode: '992',
  },
  {
    countryCode: 'TZ',
    countryName: 'Tanzania',
    phoneCode: '255',
  },
  {
    countryCode: 'TH',
    countryName: 'Thailand',
    phoneCode: '66',
  },
  {
    countryCode: 'TG',
    countryName: 'Togo',
    phoneCode: '228',
  },
  {
    countryCode: 'TK',
    countryName: 'Tokelau',
    phoneCode: '690',
  },
  {
    countryCode: 'TO',
    countryName: 'Tonga',
    phoneCode: '676',
  },
  {
    countryCode: 'TT',
    countryName: 'Trinidad and Tobago',
    phoneCode: '1-868',
  },
  {
    countryCode: 'TN',
    countryName: 'Tunisia',
    phoneCode: '216',
  },
  {
    countryCode: 'TR',
    countryName: 'Turkey',
    phoneCode: '90',
  },
  {
    countryCode: 'TM',
    countryName: 'Turkmenistan',
    phoneCode: '993',
  },
  {
    countryCode: 'TC',
    countryName: 'Turks and Caicos Islands',
    phoneCode: '1-649',
  },
  {
    countryCode: 'TV',
    countryName: 'Tuvalu',
    phoneCode: '688',
  },
  {
    countryCode: 'VI',
    countryName: 'U.S. Virgin Islands',
    phoneCode: '1-340',
  },
  {
    countryCode: 'UG',
    countryName: 'Uganda',
    phoneCode: '256',
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    phoneCode: '380',
  },
  {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    phoneCode: '971',
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom',
    phoneCode: '44',
  },
  {
    countryCode: 'UY',
    countryName: 'Uruguay',
    phoneCode: '598',
  },
  {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    phoneCode: '998',
  },
  {
    countryCode: 'VU',
    countryName: 'Vanuatu',
    phoneCode: '678',
  },
  {
    countryCode: 'VA',
    countryName: 'Vatican',
    phoneCode: '379',
  },
  {
    countryCode: 'VE',
    countryName: 'Venezuela',
    phoneCode: '58',
  },
  {
    countryCode: 'VN',
    countryName: 'Vietnam',
    phoneCode: '84',
  },
  {
    countryCode: 'WF',
    countryName: 'Wallis and Futuna',
    phoneCode: '681',
  },
  {
    countryCode: 'EH',
    countryName: 'Western Sahara',
    phoneCode: '212',
  },
  {
    countryCode: 'YE',
    countryName: 'Yemen',
    phoneCode: '967',
  },
  {
    countryCode: 'ZM',
    countryName: 'Zambia',
    phoneCode: '260',
  },
  {
    countryCode: 'ZW',
    countryName: 'Zimbabwe',
    phoneCode: '263',
  },
];
