import { BulkCommunicationFormValuesModel } from 'models/bulkCommunicationFormValues.model';
import { OrderCommunicationType } from 'models/enums/orderCommunicationType.enum';
import { OrderCommunicationMethodEnum } from 'models/enums/orderCommunicationMethod.enum';

export const bulkCommunicationInitialValues: BulkCommunicationFormValuesModel =
  {
    messageType: OrderCommunicationType.PredefinedTemplate,
    communicationMethod: OrderCommunicationMethodEnum.SMS,
    template: undefined,
    messageText: undefined,
    emailTemplate: {
      json: undefined,
      html: undefined,
    },
    selectedOrders: [],
    emailSubject: undefined,
  };
