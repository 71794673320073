/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import intl from 'react-intl-universal';
import { Moment } from 'moment';

import { parseServiceTime } from 'utils/datesUtils';
import { TimePickerContainer } from 'components/FormContainers';
import { TableOrdersTimeFilterSchema } from '../../schemas';
import { TableOrdersTimeFilterPropsModel } from '../../models';
import TableOrdersBaseListFilter from './TableOrdersBaseListFilter';

function TableOrdersTimeFilter(props: TableOrdersTimeFilterPropsModel) {
  const { inputProps, ...restProps } = props;

  const getIsSearchValueMatched = (
    value: string,
    label: string,
    searchValue: Moment,
  ) => !searchValue || parseServiceTime(value)?.isSame(searchValue, 'minutes');

  return (
    <TableOrdersBaseListFilter
      getIsSearchValueMatched={getIsSearchValueMatched}
      validationSchema={TableOrdersTimeFilterSchema}
      getSearchContainer={(fieldProps) => (
        <TimePickerContainer
          placeholder={intl.get('orders.table.searchInFilters')}
          {...inputProps}
          {...fieldProps}
        />
      )}
      {...restProps}
    />
  );
}

export default TableOrdersTimeFilter;
