import React, { Component } from 'react';
import * as d3 from 'd3';

import { find } from 'lodash';
import { AvailableCategoryModel } from '../../../../models/tour-details/tourAvailableCategory.model';
import { isObjectsEqualBy } from '../../../../utils/formatter';
import {
  createPricingOptions,
  getOptionObjectByKey,
  OPTION_FIND_FIELDS,
} from './seatMapUtils';
import SeatCategorySelect from './SeatCategorySelect';
import SeatMapArea from './SeatMapArea';
import { SeatModel } from './pricingOptions.model';
import Availability from '../Availability';

type Props = {
  url: string;
  availabilityCategories: AvailableCategoryModel[];
  onAddAvailabilityToCart: (availability: AvailableCategoryModel) => void;
  onScrollToAvailabilities: () => void;
};

export default class SeatMap extends Component<Props> {
  state = {
    pricingOptions: createPricingOptions(this.props.availabilityCategories),
    selectedPriceOptionKey: undefined,
    popoverInfo: undefined,
    isPopoverVisible: false,
  };

  mapNodeRef: React.RefObject<SVGSVGElement> = React.createRef();

  componentDidUpdate(_, prevState) {
    if (!isObjectsEqualBy(this.state, prevState, ['selectedPriceOptionKey'])) {
      this.highlightSelectedAvailableSeats();
    }
  }

  get selectedAvailability() {
    const { selectedPriceOptionKey, pricingOptions } = this.state;

    const selectedPriceOptionObject = getOptionObjectByKey(
      selectedPriceOptionKey,
    );

    const pricingOption = find(pricingOptions, ({ availabilityCategory }) =>
      isObjectsEqualBy(
        availabilityCategory,
        selectedPriceOptionObject,
        OPTION_FIND_FIELDS,
      ),
    );

    return pricingOption?.availabilityCategory;
  }

  get d3MapNode() {
    return d3.select(this.mapNodeRef.current);
  }

  handleInitMap = () => {
    const { onScrollToAvailabilities } = this.props;

    onScrollToAvailabilities();

    this.highlightAllAvailableSeats();
    this.highlightSelectedAvailableSeats();
  };

  highlightAllAvailableSeats = () => {
    const { pricingOptions } = this.state;
    const pricingOptionsSeats = pricingOptions.flatMap(({ seats }) =>
      seats.map((seat) => seat),
    );

    this.setSeats(pricingOptionsSeats);
  };

  highlightSelectedAvailableSeats = () => {
    const { selectedPriceOptionKey, pricingOptions } = this.state;
    const pricingOption = pricingOptions.find(
      ({ key }) => key === selectedPriceOptionKey,
    );

    if (!pricingOption) return;

    this.highlightSelectedSeats(pricingOption.seats);
  };

  highlightSelectedSeats = (seats: SeatModel[]) => {
    this.unselectSeats();
    this.selectSeats(seats);
  };

  handlePricingOptionChange = (selectedPriceOptionKey: string) => {
    this.setState({ selectedPriceOptionKey });
  };

  handleAddToCart = () => {
    const { onAddAvailabilityToCart } = this.props;

    onAddAvailabilityToCart(this.selectedAvailability);
  };

  selectSeats = (seats: SeatModel[]) => this.classedSeats(seats, 'selected');

  unselectSeats = () => {
    this.d3MapNode.selectAll('circle.selected').classed('selected', false);
  };

  classedSeats = (seats: SeatModel[], className: string, value = true) => {
    seats.forEach(({ id }) =>
      this.d3MapNode.select(`circle[id='${id}']`).classed(className, value),
    );
  };

  setSeats = (seats: SeatModel[]) => {
    seats.forEach((seat) =>
      this.d3MapNode
        .select(`circle[id='${seat.id}']`)
        .style('fill', seat.color)
        .datum(seat)
        .on('mouseover', this.onSeatMouseOver)
        .on('mouseout', this.onSeatMouseOut),
    );
  };

  onSeatMouseOver = ({ offsetX, offsetY }, metaData: SeatModel) => {
    this.setState({
      popoverInfo: {
        metaData,
        offsetX,
        offsetY,
      },
      isPopoverVisible: true,
    });
  };

  onSeatMouseOut = () => {
    this.setState({ isPopoverVisible: false }, () =>
      this.setState({ popoverInfo: undefined }),
    );
  };

  render() {
    const { url } = this.props;
    const {
      pricingOptions,
      isPopoverVisible,
      popoverInfo,
      selectedPriceOptionKey,
    } = this.state;

    return (
      <div className="seat-map-container">
        <SeatCategorySelect
          value={selectedPriceOptionKey}
          options={pricingOptions}
          onChange={this.handlePricingOptionChange}
        />

        <SeatMapArea
          url={url}
          isPopoverVisible={isPopoverVisible}
          popoverInfo={popoverInfo}
          innerRef={this.mapNodeRef}
          onInit={this.handleInitMap}
        />

        {this.selectedAvailability && (
          <Availability
            availability={this.selectedAvailability}
            addToCard={this.handleAddToCart}
          />
        )}
      </div>
    );
  }
}
