import React, { SyntheticEvent, useEffect, useRef } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Menu,
  MenuProps,
  Row,
  Spin,
  Tooltip,
} from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Field, Form, Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { delay, difference, identity, isEmpty } from 'lodash';

import { useStore } from 'hooks';
import { OrdersFilterSuggestionModel } from 'models/orders/ordersFilterSuggestion.model';
import { TABLE_ORDERS_BLANK_FILTER_VALUE } from 'utils/constants';
import useTableOrdersFilterLoader from '../../hooks/useTableOrdersFilterLoader';
import {
  TableOrdersBaseListFilterFormValuesModel,
  TableOrdersBaseListFilterPropsModel,
} from '../../models';
import TableOrdersFilterActions from './TableOrdersFilterActions';

function TableOrdersBaseListFilter<T>(
  props: TableOrdersBaseListFilterPropsModel<T>,
) {
  const {
    field,
    filteredValue,
    validationSchema,
    getSearchContainer,
    getIsSearchValueMatched,
    formatOption = identity,
    formatValue = identity,
    formatSearch = identity,
    allowCustomFilter = false,
    allowBlankEntry = false,
    ...dropdownProps
  } = props;
  const { visible, selectedKeys, setSelectedKeys, confirm } = dropdownProps;

  const formRef =
    useRef<FormikProps<TableOrdersBaseListFilterFormValuesModel<T>>>(null);
  const { isLoading, filterOptions: loadedFilterOptions } =
    useTableOrdersFilterLoader(field, visible);
  const userFiltersStore = useStore((state) => state.userFiltersStore);

  useEffect(() => {
    if (!visible) {
      delay(() => formRef.current?.resetForm(), 300);
    }
  }, [visible]);

  useEffect(() => {
    if (
      allowCustomFilter &&
      !userFiltersStore.selectedFilterId &&
      !isEmpty(loadedFilterOptions)
    ) {
      const formattedLoadedOptions = loadedFilterOptions.map((option) =>
        formatValue(option.Key),
      );

      userFiltersStore.addCustomFilterOptions(
        field,
        difference(filteredValue, formattedLoadedOptions),
      );
    }
  }, [loadedFilterOptions]);

  const customFilterOptions =
    userFiltersStore.getFieldCustomFilterOptions(field);
  const filterOptions = [...customFilterOptions, ...loadedFilterOptions];
  const filterOptionsKeys = filterOptions.map((option) => option.Key);

  const initialValues: TableOrdersBaseListFilterFormValuesModel<T> = {
    search: undefined,
    allowBlankEntry,
    filterOptions: filterOptionsKeys,
  };

  const getIsCustomFilter = (key: string) =>
    customFilterOptions.some((option) => option.Key === key);

  const mapFilterOption = (
    filterOption: OrdersFilterSuggestionModel,
    searchValue: T,
    getValue: (label: string, value: string) => MenuProps['items'][number],
  ) => {
    const label = formatOption(filterOption.Value);
    const value = formatValue(filterOption.Key);

    return getIsSearchValueMatched(value, label, searchValue)
      ? getValue(label, value)
      : null;
  };

  const getItems = (searchValue: T) =>
    filterOptions.map((filterOption) =>
      mapFilterOption(filterOption, searchValue, (label, value) => ({
        key: value,
        label: (
          <Row align="middle" gutter={8}>
            <Col>
              <Checkbox checked={selectedKeys.includes(value)} />
            </Col>

            <Col flex="auto">
              <span>{label}</span>
            </Col>

            {getIsCustomFilter(value) && (
              <Col>
                <Button
                  danger
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={onRemoveCustomFilter(value)}
                />
              </Col>
            )}
          </Row>
        ),
      })),
    );

  const onRemoveCustomFilter = (value: string) => (event: SyntheticEvent) => {
    event.stopPropagation();

    setSelectedKeys(selectedKeys.filter((key) => key !== value));

    userFiltersStore.removeCustomFilterOption(field, value);

    if (filteredValue?.includes(value)) {
      confirm({ closeDropdown: false });
    }
  };

  const onAddCustomFilter = (
    values: TableOrdersBaseListFilterFormValuesModel<T>,
  ) => {
    if (!allowCustomFilter) return;

    const value =
      formatSearch(values.search) || TABLE_ORDERS_BLANK_FILTER_VALUE;

    userFiltersStore.addCustomFilterOptions(field, [value]);

    setSelectedKeys([...selectedKeys, value]);

    formRef.current?.resetForm();
  };

  return (
    <div className="ant-table-filter-dropdown" style={{ minWidth: 220 }}>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={allowCustomFilter ? validationSchema : null}
        onSubmit={onAddCustomFilter}
      >
        {({ values }) => (
          <>
            <div className="ant-table-filter-dropdown-search">
              <Form>
                <Row wrap={false} gutter={5}>
                  <Col flex="auto">
                    <Field
                      name="search"
                      placeholder={intl.get('orders.table.searchInFilters')}
                      prefix={<SearchOutlined />}
                    >
                      {getSearchContainer}
                    </Field>
                  </Col>

                  {allowCustomFilter && (
                    <Col>
                      <Tooltip title={intl.get('orders.table.addCustomFilter')}>
                        <Button
                          shape="circle"
                          type="primary"
                          htmlType="submit"
                          icon={<PlusOutlined />}
                        />
                      </Tooltip>
                    </Col>
                  )}
                </Row>
              </Form>
            </div>

            {isLoading ? (
              <div className="text-center p-v-10">
                <Spin />
              </div>
            ) : (
              <Menu
                multiple
                items={getItems(values.search)}
                selectedKeys={selectedKeys as string[]}
                onSelect={(bag) => setSelectedKeys(bag.selectedKeys)}
                onDeselect={(bag) => setSelectedKeys(bag.selectedKeys)}
              />
            )}
          </>
        )}
      </Formik>

      <div className="ant-table-filter-dropdown-btns">
        <TableOrdersFilterActions {...dropdownProps} />
      </div>
    </div>
  );
}

export default observer(TableOrdersBaseListFilter);
