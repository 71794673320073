import React from 'react';
import { Col, Row, Tag } from 'antd';

import { DateRangeTemplateEnum } from 'models/enums/dateRangeTemplate.enum';
import { getDateRangeTemplateLabel } from 'utils/datesUtils';

type Props = {
  value: DateRangeTemplateEnum;
  onChange: (value: DateRangeTemplateEnum) => void;
};

function RangePickerTemplates(props: Props) {
  const { value, onChange } = props;

  const checkIsSelected = (dateRangeTemplate: DateRangeTemplateEnum) =>
    dateRangeTemplate === value;

  const prevTemplates = [
    DateRangeTemplateEnum.Yesterday,
    DateRangeTemplateEnum.Prev7Days,
    DateRangeTemplateEnum.Prev30Days,
    DateRangeTemplateEnum.Prev3Months,
    DateRangeTemplateEnum.PrevQuarter,
    DateRangeTemplateEnum.Prev12Months,
    DateRangeTemplateEnum.PrevYear,
  ];

  const thisTemplates = [
    DateRangeTemplateEnum.Today,
    DateRangeTemplateEnum.ThisWeek,
    DateRangeTemplateEnum.ThisMonth,
    DateRangeTemplateEnum.ThisQuarter,
    DateRangeTemplateEnum.ThisYear,
    DateRangeTemplateEnum.YearToDate,
  ];

  const nextTemplates = [
    DateRangeTemplateEnum.Tomorrow,
    DateRangeTemplateEnum.Next7Days,
    DateRangeTemplateEnum.Next30Days,
    DateRangeTemplateEnum.Next3Months,
    DateRangeTemplateEnum.NextQuatter,
    DateRangeTemplateEnum.Next12Months,
    DateRangeTemplateEnum.NextYear,
  ];

  const renderTemplates = (templates: DateRangeTemplateEnum[]) => (
    <Row gutter={[0, 5]}>
      {templates.map((dateRangeTemplate: DateRangeTemplateEnum) => (
        <Col key={dateRangeTemplate} span={24}>
          <Tag
            color={checkIsSelected(dateRangeTemplate) ? 'blue' : undefined}
            style={{ cursor: 'pointer', display: 'block' }}
            onClick={() => onChange(dateRangeTemplate)}
          >
            {getDateRangeTemplateLabel(dateRangeTemplate)}
          </Tag>
        </Col>
      ))}
    </Row>
  );

  return (
    <Row gutter={[30, 0]}>
      <Col span={8}>{renderTemplates(prevTemplates)}</Col>

      <Col span={8}>{renderTemplates(thisTemplates)}</Col>

      <Col span={8}>{renderTemplates(nextTemplates)}</Col>
    </Row>
  );
}

export default RangePickerTemplates;
