import React from 'react';
import { Tooltip, Popover } from 'antd';
import intl from 'react-intl-universal';

import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import { EventModel } from '../../../../../../models/inventory/event.model';
import EventPopoverContent from './EventPopoverContent';

type EventComponentProps = {
  event: EventModel;
};

const EventComponent: React.FC<EventComponentProps> = React.memo(
  ({ event }) => {
    const { options } = event;
    const optionsAmount = options.length;

    const stopSellOptions = options.filter(({ StopSell }) => StopSell);

    const renderSingleOptionContent = () => (
      <div className="d-f f-d-column ai-fe jc-fe h-100">
        {!!options[0]?.Hold && (
          <div className="flx-2 f-s-13 f-w-4 p-h-5 m-t-5 m-l-2 text-left event-label">
            {`${intl.get('productPage.hold').toLowerCase()} ${options[0].Hold}`}
          </div>
        )}

        <div className="p-r-10 w-100 d-f ai-c jc-sb">
          {options[0]?.StopSell && (
            <div className="w-100">
              <Tooltip placement="bottom" title="Stop Sell">
                <CloseOutlined className="stop-sell-icon stop-sell-icon_red" />
              </Tooltip>
            </div>
          )}

          {options[0]?.AvailableQuantity !== undefined && (
            <div className="flx-3 f-s-20 text-right">
              {options[0].AvailableQuantity}
            </div>
          )}
        </div>
      </div>
    );

    const renderMultipleOptionsContent = () => (
      <div className="d-f ai-fe jc-fe h-100">
        <div className="p-r-10 w-100">
          <Popover
            overlayClassName="event-popover"
            content={<EventPopoverContent event={event} />}
          >
            <div className="d-f">
              {!!stopSellOptions.length && (
                <div className="w-100 text-left">
                  <CloseOutlined
                    className={classNames(
                      'stop-sell-icon stop-sell-icon_grey',
                      {
                        'stop-sell-icon_red':
                          stopSellOptions.length === options.length,
                      },
                    )}
                  />
                </div>
              )}

              <div className="flx-3 f-s-15 text-right">
                {intl.get('productPage.options', { optionsAmount })}
              </div>
            </div>
          </Popover>
        </div>
      </div>
    );

    if (!optionsAmount) return null;

    if (optionsAmount === 1) return renderSingleOptionContent();

    return renderMultipleOptionsContent();
  },
);

export default EventComponent;
