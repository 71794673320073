import { isEmpty, uniq } from 'lodash';

export const findShortestArray = <T>(array: Array<T>[]) =>
  isEmpty(array)
    ? []
    : array.reduce((prevArray, currentArray) =>
        prevArray.length > currentArray.length
          ? [...currentArray]
          : [...prevArray],
      );

export const isSameBy = <T>(
  data: T[],
  selector: (value: T, index: number, array: T[]) => unknown,
) => uniq(data.flatMap(selector)).length === 1;

export function flattenBy<T>(rootArr: T[], key: string | number) {
  const result: T[] = [];

  const flatRecurse = (arr) => {
    arr.forEach((element) => {
      if (!element[key]) {
        result.push(element);
      } else {
        flatRecurse(element[key]);
      }
    });
  };

  flatRecurse(rootArr);

  return result;
}
