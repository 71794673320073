import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Field, Form, Formik } from 'formik';
import { Button, Col, Row } from 'antd';
import * as Yup from 'yup';

import { SuppliersStore } from '../../../../stores/suppliers.store';
import {
  CheckboxContainer,
  InputNumberContainer,
  SelectContainer,
} from '../../../FormContainers';
import { CombinationOperatorEnum } from '../../../../models/enums/combinationOperator.enum';
import { getFeeCombinationLabel } from '../../../../utils/supplierUtils';
import { PlatformFeesFormModel } from '../../../../models/contacts/platformFeesForm.model';
import { withStore } from '../../../../hocs';

type Props = {
  suppliersStore?: SuppliersStore;
};

type State = {
  isLoading: boolean;
};

@withStore(({ rootStore }) => ({
  suppliersStore: rootStore.suppliersStore,
}))
class ProfilePlatformFees extends Component<Props, State> {
  state = {
    isLoading: false,
  };

  getInitialData = (): PlatformFeesFormModel => {
    const { suppliersStore } = this.props;
    const { userSupplier } = suppliersStore;

    return {
      defaultCommissionFlat: userSupplier.DefaultCommissionFlat,
      defaultCommissionPercentage: userSupplier.DefaultCommissionPercentage,
      commissionCombinationOperator: userSupplier.CommissionCombinationOperator,
      commissionIncludesTax: userSupplier.CommissionIncludesTax,
    };
  };

  getCommissionCombinationOptions = () =>
    [
      CombinationOperatorEnum.Both,
      CombinationOperatorEnum.Greater,
      CombinationOperatorEnum.Less,
    ].map((combination) => ({
      label: getFeeCombinationLabel(combination),
      value: combination,
    }));

  onSubmit = async (values: PlatformFeesFormModel) => {
    const { suppliersStore } = this.props;
    const { userSupplier } = suppliersStore;

    try {
      this.setState({ isLoading: true });

      await suppliersStore.editSupplier(userSupplier.Id, {
        Id: userSupplier.Id,
        Name: userSupplier.Name,
        DefaultCommissionFlat: values.defaultCommissionFlat,
        DefaultCommissionPercentage: values.defaultCommissionPercentage,
        CommissionCombinationOperator: values.commissionCombinationOperator,
        CommissionIncludesTax: values.commissionIncludesTax,
      });

      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { suppliersStore } = this.props;
    const { isLoading } = this.state;

    const { userSupplier } = suppliersStore;

    return (
      <div className="my-profile-section">
        <Formik
          initialValues={this.getInitialData()}
          validationSchema={Yup.object().shape({
            defaultCommissionFlat: Yup.number()
              .nullable()
              .required(intl.get('required')),
            defaultCommissionPercentage: Yup.number()
              .nullable()
              .required(intl.get('required')),
            commissionCombinationOperator: Yup.string()
              .nullable()
              .required(intl.get('required')),
            commissionIncludesTax: Yup.boolean()
              .nullable()
              .required(intl.get('required')),
          })}
          onSubmit={this.onSubmit}
        >
          <Form>
            <Row gutter={[0, 30]}>
              <Col span={24}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <h2 className="my-profile-head m-0">
                      {intl.get('profile.platformFees.header')}
                    </h2>
                  </Col>

                  <Col>
                    <Button
                      htmlType="submit"
                      size="large"
                      loading={isLoading}
                      className="default-button"
                    >
                      {intl.get('buttons.save')}
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row gutter={[20, 20]}>
                  <Col span={5}>
                    <Field
                      label={intl.get('profile.platformFees.commissionFlat')}
                      name="defaultCommissionFlat"
                      type="number"
                      min={0}
                      step={1}
                      precision={0}
                      disabled={!userSupplier.CommissionFlatEditable}
                      component={InputNumberContainer}
                    />
                  </Col>

                  <Col span={5}>
                    <Field
                      label={intl.get(
                        'profile.platformFees.commissionPercentage',
                      )}
                      name="defaultCommissionPercentage"
                      type="number"
                      min={0}
                      step={1}
                      precision={0}
                      disabled={!userSupplier.CommissionPercentageEditable}
                      component={InputNumberContainer}
                    />
                  </Col>

                  <Col span={7}>
                    <Field
                      label={intl.get(
                        'profile.platformFees.combinationOperator',
                      )}
                      name="commissionCombinationOperator"
                      options={this.getCommissionCombinationOptions()}
                      component={SelectContainer}
                    />
                  </Col>

                  <Col span={7}>
                    <Field
                      label={intl.get(
                        'profile.platformFees.commissionIncludesTax',
                      )}
                      fieldLabel="&nbsp;"
                      name="commissionIncludesTax"
                      component={CheckboxContainer}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Formik>
      </div>
    );
  }
}

export default ProfilePlatformFees;
