import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'antd';
import { UserStore } from '../../../../../stores/user.store';
import { withStore } from '../../../../../hocs';

@withStore(({ rootStore }) => ({
  userStore: rootStore.userStore,
}))
export default class DeactivateUser extends Component<{
  userName: string;
  inactive: boolean;
  onCancel: Function;
  onSuccess: Function;
  userStore?: UserStore;
}> {
  state = {
    isDeactivateLoading: false,
  };

  handleChangeUserStatus = () => {
    const { userName, inactive, userStore, onSuccess } = this.props;

    this.setState({ isDeactivateLoading: true });

    userStore
      .changeUserStatus(userName, !inactive)
      .then(() => onSuccess())
      .finally(() => this.setState({ isDeactivateLoading: false }));
  };

  render() {
    const { userName, inactive, onCancel } = this.props;
    const { isDeactivateLoading } = this.state;
    const activate = intl.get('profile.roleManagement.activate');
    const deactivate = intl.get('profile.roleManagement.deactivate');

    return (
      <div className="notification-body">
        <div className="header">
          <span>{intl.get('profile.roleManagement.changeAccountStatus')}</span>
        </div>
        <div className="body">
          <span className="f-s-16">
            <span>
              {intl.getHTML('profile.roleManagement.areYouSureTo', {
                action: inactive
                  ? activate.toLocaleLowerCase()
                  : deactivate.toLocaleLowerCase(),
                user: userName,
              })}
            </span>
          </span>
        </div>
        <div className="footer d-f m-t-20">
          <div className="m-r-20">
            <Button
              className="secondary"
              onClick={() => onCancel()}
              disabled={isDeactivateLoading}
            >
              {intl.get('buttons.cancel')}
            </Button>
          </div>
          <div>
            <Button
              className="primary"
              onClick={this.handleChangeUserStatus}
              loading={isDeactivateLoading}
              disabled={isDeactivateLoading}
            >
              {inactive ? activate : deactivate}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
