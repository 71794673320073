import React, { useState, useRef } from 'react';
import { Popover, Tooltip, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { isNil } from 'lodash';
import intl from 'react-intl-universal';

import { EventModel } from '../../../../../../models/inventory/event.model';
import LessMoreList from '../../../../../SharedComponents/LessMoreList';
import EventPopoverContent from './EventPopoverContent';

type Props = {
  event: EventModel;
};

export default function InlineEventComponent(props: Props) {
  const { event } = props;

  const containerRef = useRef(null);

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const handleShowAllOptions = () => setIsPopoverVisible((visible) => !visible);

  const moreLabelFormatter = ({ restItemsCount }) =>
    `${restItemsCount} ${intl.get('more')}`;

  return (
    <Popover
      visible={isPopoverVisible}
      trigger="click"
      content={<EventPopoverContent event={event} />}
      overlayClassName="event-popover"
      onVisibleChange={setIsPopoverVisible}
    >
      <LessMoreList
        limit={4}
        moreLabel={moreLabelFormatter}
        onMore={handleShowAllOptions}
      >
        {event.options.map((option) => (
          <div
            key={option.Id}
            ref={containerRef}
            className="inline-event-option"
          >
            <span
              className="inline-event-option-title"
              title={option.CategoryName}
            >
              {option.CategoryName}
            </span>

            <Space size={4}>
              {option.StopSell && (
                <Tooltip placement="bottom" title="Stop Sell">
                  <CloseOutlined className="stop-sell-icon stop-sell-icon_red" />
                </Tooltip>
              )}

              {!isNil(option.AvailableQuantity) && (
                <div className="inline-event-option-quantity">
                  {option.AvailableQuantity}
                </div>
              )}
            </Space>
          </div>
        ))}
      </LessMoreList>
    </Popover>
  );
}
