import React, { Component } from 'react';
import { Modal, Button, Spin, Row, Col } from 'antd';
import intl from 'react-intl-universal';
import { Formik, Form, FormikProps, Field, FormikTouched } from 'formik';
import { toJS } from 'mobx';
import { isNil } from 'lodash';

import { MessageTemplatesStore } from 'stores/messageTemplates.store';
import { CommonStore } from 'stores/common.store';
import { UserStore } from 'stores/user.store';
import { NotificationTemplate } from 'models/notification-template/notificationTemplate.model';
import { NotificationRptTypeModelEnum } from 'models/enums/notificationRptTypeModelEnum';
import { checkUserGroup } from 'utils/userRolesUtils';
import { ROLES } from 'utils/constants';
import { safeJSONParse } from 'utils/jsonUtils';
import {
  getPreviewModelByType,
  getTemplateInitialValues,
} from 'utils/messageTemplateUtils';
import { withStore } from 'hocs';
import MessageTemplateModelField from './MessageTemplateModelField';
import MessageTemplateModelFindOrder from './MessageTemplateModelFindOrder';
import CodeEditorContainer from '../../../FormContainers/codeEditor.container';
import { MESSAGE_TEMPLATE_DEFAULT_REPLY_EMAIL } from '../constants/messageTemplate.constants';
import {
  EmailEditorContainer,
  InputContainer,
  TextareaContainer,
} from '../../../FormContainers';
import { messageTemplateSchema } from '../../../../utils/validation/schemas/messageTemplate.schema';
import { MessageTemplateFormModel } from '../../../../models/messageTemplateForm.model';

type Props = {
  visible: boolean;
  messageTemplateId?: number;
  messageTemplatesStore?: MessageTemplatesStore;
  commonStore?: CommonStore;
  userStore?: UserStore;
  onClose?: () => void;
};

type State = {
  isDetailsLoading: boolean;
  isSubmitLoading: boolean;
  templateDetails: NotificationTemplate;
};

@withStore(({ rootStore }) => ({
  messageTemplatesStore: rootStore.messageTemplatesStore,
  commonStore: rootStore.commonStore,
  userStore: rootStore.userStore,
}))
export default class MessageTemplateModal extends Component<Props, State> {
  formRef: React.RefObject<FormikProps<MessageTemplateFormModel>> =
    React.createRef();

  initialState = {
    isDetailsLoading: false,
    isSubmitLoading: false,
    templateDetails: null as NotificationTemplate,
  };

  state = this.initialState;

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { visible, messageTemplateId } = this.props;

    if (messageTemplateId && !prevProps.visible && visible) {
      this.handleLoadMessageTemplate();
    }
  }

  get isEditMode() {
    return !isNil(this.state.templateDetails);
  }

  get isTemplateModelVisible() {
    const { userStore } = this.props;

    return checkUserGroup(userStore.userProperties, [
      ROLES.AGENT.value,
      ROLES.MANAGER.value,
    ]);
  }

  handleLoadMessageTemplate = async () => {
    const { messageTemplatesStore, messageTemplateId } = this.props;

    try {
      this.setState({ isDetailsLoading: true });

      const templateDetails =
        await messageTemplatesStore.getNotificationTemplate(messageTemplateId);

      this.setState({ templateDetails });
    } finally {
      this.setState({ isDetailsLoading: false });
    }
  };

  getInitialTouched = (): FormikTouched<MessageTemplateFormModel> => ({
    smsTemplate: true,
    emailTemplate: { json: true, html: true },
  });

  getInitialValues = (): MessageTemplateFormModel => {
    const { templateDetails } = this.state;

    if (this.isEditMode) {
      return getTemplateInitialValues(templateDetails);
    }

    return {
      description: undefined,
      subject: undefined,
      smsTemplate: undefined,
      modelType: NotificationRptTypeModelEnum.OneProduct,
      previewModel: getPreviewModelByType(
        NotificationRptTypeModelEnum.OneProduct,
      ),
      orderId: undefined,
      emailTemplate: {
        json: undefined,
        html: undefined,
      },
    };
  };

  getMergeTags = (modelType: NotificationRptTypeModelEnum) => {
    const { commonStore } = this.props;
    const { agencyMergeTags, supplierMergeTags } = commonStore.siteSettings;

    const mergeTags = {
      [NotificationRptTypeModelEnum.AllProducts]: agencyMergeTags,
      [NotificationRptTypeModelEnum.OneProduct]: supplierMergeTags,
    }[modelType];

    return toJS(mergeTags);
  };

  handleSubmit = async (values: MessageTemplateFormModel) => {
    const { templateDetails } = this.state;

    const data: NotificationTemplate = {
      Id: templateDetails?.Id,
      Code: templateDetails?.Code ?? '',
      Description: values.description,
      ReportText: values.emailTemplate.html,
      Message: JSON.stringify(values.emailTemplate.json),
      ReportTextMobile: values.smsTemplate,
      ReplyToEmail:
        templateDetails?.ReplyToEmail ?? MESSAGE_TEMPLATE_DEFAULT_REPLY_EMAIL,
      ReplyToName: templateDetails?.ReplyToName ?? '',
      ReplyToPhone: templateDetails?.ReplyToPhone ?? '',
      Subject: values.subject ?? '',
      RptTypeModel: values.modelType,
    };

    try {
      this.setState({ isSubmitLoading: true });

      const action = this.isEditMode
        ? this.onUpdateNotificationTemplate
        : this.onCreateNotificationTemplate;

      await action(data);
    } finally {
      this.setState({ isSubmitLoading: false });
    }
  };

  onCreateNotificationTemplate = async (data: NotificationTemplate) => {
    const { messageTemplatesStore } = this.props;

    const newTemplateDetails =
      await messageTemplatesStore.createNotificationTemplate(data);

    this.setState({ templateDetails: newTemplateDetails });
  };

  onUpdateNotificationTemplate = async (data: NotificationTemplate) => {
    const { messageTemplatesStore } = this.props;

    await messageTemplatesStore.updateNotificationTemplate(data.Id, data);
  };

  onCancel = () => {
    const { onClose } = this.props;

    this.setState(this.initialState);

    onClose();
  };

  render() {
    const { commonStore, visible } = this.props;
    const { isDetailsLoading, isSubmitLoading } = this.state;

    return (
      <Modal
        destroyOnClose
        width="95%"
        style={{ maxWidth: 1400, minWidth: 1080 }}
        title={
          this.isEditMode
            ? intl.get('messageTemplates.updateMessageTemplate')
            : intl.get('messageTemplates.createMessageTemplate')
        }
        visible={visible}
        onCancel={this.onCancel}
        footer={false}
      >
        {isDetailsLoading && (
          <Row justify="center">
            <Spin />
          </Row>
        )}

        {!isDetailsLoading && (
          <Formik
            innerRef={this.formRef}
            validateOnMount={this.isEditMode}
            initialTouched={this.getInitialTouched()}
            initialValues={this.getInitialValues()}
            validationSchema={messageTemplateSchema}
            onSubmit={this.handleSubmit}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <Row gutter={30}>
                  <Col span={12}>
                    <Field
                      name="description"
                      label={intl.get('messageTemplates.description')}
                      component={InputContainer}
                    />
                  </Col>

                  <Col span={12}>
                    <MessageTemplateModelFindOrder />
                  </Col>

                  <Col span={12}>
                    <Field
                      name="smsTemplate"
                      style={{ height: 310 }}
                      label={intl.get('messageTemplates.smsTemplate')}
                      component={TextareaContainer}
                    />
                  </Col>

                  <Col span={12}>
                    <Field
                      name="previewModel"
                      label={intl.get('messageTemplates.previewModel')}
                      language="json"
                      height={310}
                      style={{
                        minHeight: '100%',
                        fontSize: 12,
                        backgroundColor: '#f5f5f5',
                        fontFamily:
                          'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                      }}
                      component={CodeEditorContainer}
                    />
                  </Col>

                  <Col span={12}>
                    <Field
                      name="subject"
                      label={intl.get('messageTemplates.subject')}
                      component={InputContainer}
                    />
                  </Col>

                  {this.isTemplateModelVisible && (
                    <Col span={12}>
                      <MessageTemplateModelField />
                    </Col>
                  )}

                  <Col span={24}>
                    <Field
                      name="emailTemplate"
                      label={intl.get('messageTemplates.emailTemplate')}
                      minHeight={700}
                      options={{
                        mergeTags: this.getMergeTags(values.modelType),
                        features: {
                          smartMergeTags: false,
                        },
                      }}
                      projectId={commonStore.siteSettings.unlayerProjectId}
                      previewModel={safeJSONParse(values.previewModel)}
                      component={EmailEditorContainer}
                    />
                  </Col>

                  <Col span={24}>
                    <Row justify="end">
                      <Col>
                        <Button
                          htmlType="submit"
                          type="primary"
                          loading={isSubmitLoading}
                          disabled={isSubmitting}
                        >
                          {intl.get('buttons.save')}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    );
  }
}
