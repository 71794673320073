// questionId by BookingQuestionEnum
export const BookingQuestionEnum = {
  dateOfBirth: '1',
  passengerHeights: '2',
  passportExpiryDate: '3',
  passportNationality: '4',
  passportNumber: '5',
  arrivalAirline: '7',
  arrivalFlightNumber: '8',
  departureAirline: '9',
  departureFlightNumber: '10',
  dropOffLocation: '11',
  arrivalTime: '12',
  departureDate: '13',
  pickUpLocation: '14',
  departureTime: '15',
  disembarkationTime: '16',
  cruiseShip: '17',
  boardingTime: '18',
  arrivalRailLine: '19',
  arrivalRailStation: '20',
  departureRailLine: '21',
  departureRailStation: '22',
  passengerWeights: '23',
};
