import { Moment } from 'moment';
import { RateModel } from './rate.model';
import { RegularRatesModel } from './regularRates.model';
import { CommissionTypeEnum } from '../enums/commissionType.enum';

export class FormRateModel extends RateModel {
  RegularRates?: RegularRatesModel[];
  CommissionType?: CommissionTypeEnum;
  InventoryDate?: [Moment?, Moment?];
  CategoryName?: string;
  IsExistsCategory?: boolean;
  isExistRate?: boolean;
  isCategoryChanged?: boolean;
  DefaultAvailability?: number;
  StopSell?: boolean;
  position?: number;
}
