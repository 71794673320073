import React, { Component } from 'react';
import intl from 'react-intl-universal';
import classNames from 'classnames';
import { Modal, Select, Button } from 'antd';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { OrderDocTypesModel } from '../../../../models/orders/orderDocTypes.model';
import { OrdersStore } from '../../../../stores/orders.store';
import { UserStore } from '../../../../stores/user.store';
import { SelectContainer, InputContainer } from '../../../FormContainers';
import { addGlobalMessage } from '../../../SharedComponents/GlobalMessages';
import { MessageTypeEnum } from '../../../../models/global-messages/message.model';
import { TypeOfDocumentEnum } from '../../../../models/enums/typeOfDocument.enum';
import { getDocumentName } from '../utils/documentsUtils';
import { withStore } from '../../../../hocs';

const { Option } = Select;

@withStore(({ rootStore }) => ({
  ordersStore: rootStore.ordersStore,
  userStore: rootStore.userStore,
}))
export default class SendDocuments extends Component<{
  docTypes: OrderDocTypesModel[];
  tripId: number;
  orderStore: OrdersStore;
  isDownloading: boolean;
  closePopup: any;
  startFileDownloading: Function;
  finishFileDownloading: Function;
  userStore?: UserStore;
}> {
  state = {
    voucherEmails: [],
    invoiceEmails: [],
    receiptEmails: [],
    isModalOpened: false,
    selectedDocType: null,
  };

  componentDidMount() {
    this.loadEmailAddresses();
  }

  loadEmailAddresses = async () => {
    const { orderStore, tripId } = this.props;

    const [voucherEmails, invoiceEmails, receiptEmails] = await Promise.all([
      orderStore.getVoucherEmailAddresses(tripId),
      orderStore.getInvoiceEmailAddresses(tripId),
      orderStore.getReceiptEmailAddresses(tripId),
    ]);

    this.setState({ voucherEmails, invoiceEmails, receiptEmails });
  };

  handleDocumentClick = (selectedDocType) => {
    this.setState({
      selectedDocType,
      isModalOpened: true,
    });
  };

  handleModalOpen = (isModalOpened) => {
    this.setState({
      isModalOpened,
    });
  };

  handleSubmit = (values) => {
    const {
      userStore,
      tripId,
      closePopup,
      startFileDownloading,
      finishFileDownloading,
    } = this.props;
    const { selectedDocType } = this.state;
    const { email, selectEmail } = values;
    const recipient = selectEmail || email;

    if (!recipient) return null;

    let promise;

    if (selectedDocType === TypeOfDocumentEnum.Invoice) {
      promise = userStore.sendEmailInvoice(tripId, [recipient]);
    }

    if (selectedDocType === TypeOfDocumentEnum.Voucher) {
      promise = userStore.sendEmailVoucher(tripId, [recipient]);
    }

    if (selectedDocType === TypeOfDocumentEnum.PaymentReceipt) {
      promise = userStore.sendEmailReceipt(tripId, [recipient]);
    }

    if (!promise) return null;

    const message = `Email was sent to ${recipient}`;

    startFileDownloading();

    promise
      .then(() => {
        addGlobalMessage({
          message,
          type: MessageTypeEnum.success,
        });
      })
      .finally(() => {
        finishFileDownloading();
      });

    this.setState({
      isModalOpened: false,
      selectedDocType: null,
    });

    closePopup();
  };

  getSendDocumentsSettings = () => {
    const { selectedDocType, voucherEmails, invoiceEmails, receiptEmails } =
      this.state;

    const selectOrEnterRecipientValidationSchema = Yup.object().shape({
      selectEmail: Yup.string().when('email', (email, schema) => {
        if (!email) {
          return schema
            .email(intl.get('emailValidator'))
            .required(intl.get('required'));
        }
      }),
      email: Yup.string().email(intl.get('emailValidator')),
    });

    const selectRecipientValidationSchema = Yup.object().shape({
      selectEmail: Yup.string()
        .email(intl.get('emailValidator'))
        .required(intl.get('required')),
    });

    switch (selectedDocType) {
      case TypeOfDocumentEnum.Voucher:
      case TypeOfDocumentEnum.ServiceVouchers:
        return {
          validationSchema: selectOrEnterRecipientValidationSchema,
          initialValues: {
            selectEmail: '',
            email: '',
          },
          selectOptions: voucherEmails,
        };

      case TypeOfDocumentEnum.Invoice:
        return {
          validationSchema: selectRecipientValidationSchema,
          initialValues: {
            selectEmail: '',
          },
          selectOptions: invoiceEmails,
        };

      case TypeOfDocumentEnum.PaymentReceipt:
        return {
          validationSchema: selectRecipientValidationSchema,
          initialValues: {
            selectEmail: '',
          },
          selectOptions: receiptEmails,
        };
    }
  };

  renderModalContent = () => {
    const { selectedDocType } = this.state;

    if (!selectedDocType) return null;

    const { initialValues, validationSchema, selectOptions } =
      this.getSendDocumentsSettings();

    const children = selectOptions.map(({ EmailAddress, Name }) => (
      <Option key={EmailAddress} value={EmailAddress}>
        <span>{`${EmailAddress} (${Name})`}</span>
      </Option>
    ));

    const HEADER = 'Select recipient email';
    const OR = 'OR';
    const OPTION = 'Enter recipient email';
    const SEND = 'SEND';

    return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          onSubmit={this.handleSubmit}
        >
          {(formik) => (
            <Form className="login-form">
              <div className="form-wrapper">
                <h2 className="login-header">{HEADER}</h2>
                <div className="fields-wrapper">
                  <Field
                    name="selectEmail"
                    component={SelectContainer}
                    placeholder={intl.get('labels.email')}
                    // eslint-disable-next-line react/no-children-prop
                    children={children}
                  />
                </div>

                {formik.values.hasOwnProperty('email') && (
                  <React.Fragment>
                    <div className="d-f jc-c m-v-10">
                      <span className="f-w-6">{OR}</span>
                    </div>
                    <div>
                      <h2 className="login-header">{OPTION}</h2>
                      <div className="fields-wrapper">
                        <Field
                          name="email"
                          component={InputContainer}
                          placeholder={intl.get('labels.email')}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}

                <div className="d-f jc-c m-t-20">
                  <Button
                    htmlType="submit"
                    type="default"
                    size="large"
                    className="primary-button p-h-30"
                  >
                    {SEND}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  render() {
    const { docTypes, isDownloading } = this.props;
    const { isModalOpened } = this.state;

    // eslint-disable-next-line eqeqeq
    if (docTypes.length == 0) return null;

    const docs = docTypes
      .map((docType) => (
        <div key={docType.Name}>
          <span onClick={() => this.handleDocumentClick(docType.Name)}>
            {`${intl.get('orders.popup.SEND_DOCUMENTS')} ${getDocumentName(
              docType.Name,
            )}`}
          </span>
        </div>
      ))
      .filter(Boolean);

    return (
      <div
        className={classNames('order-download-content m-v-20 m-h-20', {
          disabled: isDownloading,
        })}
      >
        <div>{docs}</div>
        <Modal
          destroyOnClose
          visible={isModalOpened}
          footer={null}
          onCancel={() => this.handleModalOpen(false)}
          cancelButtonProps={{ size: 'large' }}
        >
          <div className="m-v-30 m-h-30">{this.renderModalContent()}</div>
        </Modal>
      </div>
    );
  }
}
