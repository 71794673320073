import React from 'react';
import intl from 'react-intl-universal';
import { Button, Col, Row } from 'antd';

type Props = {
  onSignUpClick?: () => void;
};

function LandingBanner(props: Props) {
  const { onSignUpClick } = props;

  return (
    <section className="landing-banner">
      <div className="container-huge landing-banner__container">
        <div className="landing-banner__inner">
          <h1 className="landing-banner__title">
            {intl.get('landingBannerTitle')}
          </h1>

          <p className="landing-banner__text">
            {intl.get('landingBannerText')}
          </p>

          {onSignUpClick && (
            <Row justify="end" className="landing-banner__actions">
              <Col>
                <Button
                  size="large"
                  className="secondary-button"
                  onClick={onSignUpClick}
                >
                  {intl.get('signUpNow')}
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </section>
  );
}

export default LandingBanner;
