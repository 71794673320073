export default class PickupPointForm {
  LocationType: string;
  LocationId: string;
  Time: string;

  cardId: string;
  type: string; // "pickup", "dropOff"

  isCustom: boolean; // if true Address should be filled
  Address: string;

  constructor(values) {
    const {
      cardId,
      LocationType,
      LocationId,
      type,
      StartTime,
      isCustom,
      Address,
    } = values;

    this.cardId = cardId;
    this.LocationType = LocationType;
    this.LocationId = LocationId;
    this.type = type;
    this.Time = StartTime;

    this.isCustom = isCustom;
    this.Address = Address;
  }
}
