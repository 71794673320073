import React from 'react';
import { Button, Col, Row } from 'antd';
import { Field, FieldArray, FormikProps } from 'formik';
import intl from 'react-intl-universal';
import { PlusOutlined } from '@ant-design/icons';
import { compact } from 'lodash';
import { FormRateModel } from '../../../../../../models/inventory/formRate.model';
import { SupplierModel } from '../../../../../../models/contacts/supplier.model';
import { AgeTypeEnum } from '../../../../../../models/enums/ageType.enum';
import ProductRate from './ProductRate';
import { RegularRatesModel } from '../../../../../../models/inventory/regularRates.model';
import CommissionTypeContainer from '../../../../../FormContainers/commissionType.container';
import { CommissionTypeEnum } from '../../../../../../models/enums/commissionType.enum';
import CommissionContainer from '../../../../../FormContainers/commission.container';
import { RateGroup } from '../../../../../../utils/rateGroup';
import { VisibilityEnum } from '../../../../../../models/enums/visibility.enum';
import { ProductFormValuesModel } from '../../../../../../models/inventory/productFormValues.model';

type ProductRatesProps = {
  rates: FormRateModel[];
  ratePosition: number;
  formikProps: FormikProps<ProductFormValuesModel>;
  operatorData: SupplierModel;
};

const ProductRates: React.FC<ProductRatesProps> = React.memo((props) => {
  const { rates, ratePosition, formikProps, operatorData } = props;
  const { setFieldValue } = formikProps;

  const titles = compact([
    {
      span: 5,
      textKey: 'inventory.products.popup.ratesTable.GuestType',
    },
    {
      span: 2,
    },
    {
      span: 2,
      textKey: 'min',
    },
    {
      span: 2,
      textKey: 'max',
    },
    {
      span: 3,
      textKey: 'inventory.products.popup.ratesTable.Cost',
    },
    {
      span: 3,
      textKey: 'inventory.products.popup.ratesTable.CostBeforeTax',
    },
    operatorData?.NetRateVisible && {
      span: 3,
      textKey: 'inventory.products.popup.ratesTable.NetRate',
    },
    {
      span: 3,
      textKey: 'inventory.products.popup.ratesTable.Price',
    },
  ]);

  const addNewRate = () => {
    const newRates: RegularRatesModel[] = [
      ...rates[ratePosition].RegularRates,
      {
        Type: undefined,
        AgeMin: null,
        AgeMax: null,
        Cost: null,
        CostBeforeTax: null,
        Retail: null,
        Rate: null,
        MaxOccupancy: null,
      },
    ];

    setFieldValue(`Rates.${ratePosition}.RegularRates`, newRates);
  };

  const deleteRateRow = (index: number) => {
    const newRates = rates[ratePosition].RegularRates.filter(
      (_, regularRateIndex) => regularRateIndex !== index,
    );

    const juniorRateIndex = newRates.findIndex(
      (rate) => rate.Type === AgeTypeEnum.Junior,
    );

    if (juniorRateIndex !== -1) {
      newRates[juniorRateIndex].AgeMax = null;
    }

    setFieldValue(`Rates.${ratePosition}.RegularRates`, newRates);
  };

  const isAddButtonVisible = () => {
    const groupableRates = rates[ratePosition].RegularRates.filter(
      (regularRate) =>
        regularRate.Type === AgeTypeEnum.Group ||
        regularRate.Type === AgeTypeEnum.Adult,
    );

    return groupableRates.length < RateGroup.size;
  };

  return (
    <div className="d-f f-d-column">
      {rates[ratePosition].Visibility !== VisibilityEnum.Private && (
        <Row gutter={[15, 0]}>
          <Col>
            <span className="regular-rate-col-label regular-rate-field-row">
              {intl.get('inventory.products.popup.ratesTable.CommissionType')}
            </span>
          </Col>

          <Col span={5}>
            <Field
              name={`Rates.${ratePosition}.CommissionType`}
              component={CommissionTypeContainer}
            />
          </Col>

          <Col span={5}>
            <Field
              name={
                rates[ratePosition].CommissionType ===
                CommissionTypeEnum.Percentage
                  ? `Rates.${ratePosition}.CommissionPct`
                  : `Rates.${ratePosition}.CommissionFlat`
              }
              type={rates[ratePosition].CommissionType}
              component={CommissionContainer}
            />
          </Col>
        </Row>
      )}

      {rates[ratePosition].RegularRates.length > 0 && (
        <Row gutter={5}>
          {titles.map(({ span, textKey }) => (
            <Col key={textKey} span={span}>
              {textKey && (
                <span className="regular-rate-col-label">
                  {intl.get(textKey)}
                </span>
              )}
            </Col>
          ))}
        </Row>
      )}

      <FieldArray
        name="Rates"
        render={() => (
          <div>
            {rates[ratePosition].RegularRates.map(
              (currentRate, index: number) => (
                <ProductRate
                  key={index}
                  regularRate={currentRate}
                  ratePosition={ratePosition}
                  setFieldValue={setFieldValue}
                  operatorData={operatorData}
                  regularRates={rates[ratePosition].RegularRates}
                  regularRateIndex={index}
                  onDelete={deleteRateRow}
                />
              ),
            )}

            {isAddButtonVisible() && (
              <div className="new-product-add-button">
                <Button shape="circle" onClick={addNewRate}>
                  <PlusOutlined />
                </Button>
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
});

export default ProductRates;
