import { useCallback, useEffect } from 'react';

import { useQuery, useStore } from '../../../../hooks';

export default function useTableOrdersLoader() {
  const query = useQuery();
  const { orderTab, filters, sorts, page, pageSize } = query;

  const ordersStore = useStore((state) => state.ordersStore);

  const onLoadOrders = useCallback(async () => {
    if (!orderTab) return;

    await ordersStore.loadOrders();
  }, [orderTab, filters, sorts, page, pageSize]);

  useEffect(() => {
    onLoadOrders();
  }, [onLoadOrders]);
}
