export class PaymentMethodProfileModel {
  ProfileID: string;
  PaymentMethod: string;
  LastDigits: string;
  Description: string;

  constructor() {
    this.ProfileID = undefined;
    this.PaymentMethod = undefined;
    this.LastDigits = undefined;
    this.Description = undefined;
  }
}
