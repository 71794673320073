import React from 'react';
import intl from 'react-intl-universal';
import { Field } from 'formik';

import { CheckboxContainer } from '../../../../../FormContainers';

export default function AdditionGeneralInfo() {
  return (
    <div className="row">
      <div className="column title" />
      <div className="column content">
        <div>
          <Field
            invertValue
            name="Inactive"
            label={intl.get('inventory.products.popup.active')}
            component={CheckboxContainer}
          />
        </div>
        <div>
          <Field
            name="MultipleCategories"
            label={intl.get('inventory.products.popup.multipleCategories')}
            component={CheckboxContainer}
          />
        </div>
      </div>
    </div>
  );
}
