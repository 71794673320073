import React from 'react';
import { Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';

import { UploadOutlined } from '@ant-design/icons';
import { ImageSizeEnum } from '../../../../../../utils/constants';
import { PreviewFile } from './ProductUpload';

type OwnProps = {
  size?: ImageSizeEnum;
  fileList: PreviewFile[];
};

type Props = OwnProps & UploadProps;

export default function SingleUpload(props: Props) {
  const { fileList, size } = props;

  const fileBySize = fileList.find((file) => file.apiSize === size);
  const ownFileList = [fileBySize].filter(Boolean);

  return (
    <Upload
      className="single-upload"
      accept="image/*"
      listType="picture-card"
      {...props}
      customRequest={() => undefined}
      fileList={ownFileList}
    >
      {!ownFileList.length && <UploadOutlined />}
    </Upload>
  );
}
