import React, { useState } from 'react';
import { Button, Col, Row, Space, Tooltip } from 'antd';
import { Field, useFormikContext } from 'formik';
import intl from 'react-intl-universal';
import { InfoCircleOutlined } from '@ant-design/icons';

import { withStore } from 'hocs';
import { OrdersStore } from 'stores/orders.store';
import { safeJSONStringify } from 'utils/jsonUtils';
import { MessageTemplateFormModel } from 'models/messageTemplateForm.model';
import { InputContainer } from '../../../FormContainers';

type Props = {
  ordersStore?: OrdersStore;
};

function MessageTemplateModelFindOrder(props: Props) {
  const { ordersStore } = props;

  const [isLoading, setIsLoading] = useState(false);

  const { values, setFieldValue } =
    useFormikContext<MessageTemplateFormModel>();

  const onSearchOrder = async () => {
    try {
      setIsLoading(true);

      const result = await ordersStore.findOrderById(values.orderId);

      if (result) {
        setFieldValue('previewModel', safeJSONStringify(result, null, ' '));
      }

      setIsLoading(false);
    } catch (_) {
      setIsLoading(false);
    }
  };

  return (
    <Row align="bottom" gutter={[15, 0]}>
      <Col flex="auto">
        <Field
          name="orderId"
          label={
            <Space>
              {intl.get('messageTemplates.order')}

              <Tooltip title={intl.get('messageTemplates.orderDescription')}>
                <InfoCircleOutlined className="c-p" />
              </Tooltip>
            </Space>
          }
          component={InputContainer}
        />
      </Col>

      <Col>
        <div className="field">
          <div className="field-label">&nbsp;</div>

          <Button htmlType="button" loading={isLoading} onClick={onSearchOrder}>
            {intl.get('buttons.search')}
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default withStore(({ rootStore }) => ({
  ordersStore: rootStore.ordersStore,
}))(MessageTemplateModelFindOrder);
