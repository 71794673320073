import React from 'react';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';
import { FilterDropdownProps } from 'antd/es/table/interface';

type Props = FilterDropdownProps;

function TableOrdersFilterActions(props: Props) {
  const { confirm, clearFilters } = props;

  return (
    <>
      <Button
        type="primary"
        onClick={() => confirm()}
        icon={<SearchOutlined />}
        size="small"
      >
        {intl.get('buttons.search')}
      </Button>

      <Button onClick={() => clearFilters()} size="small">
        {intl.get('buttons.reset')}
      </Button>
    </>
  );
}

export default TableOrdersFilterActions;
