import { AxiosResponse } from 'axios';
import { BaseApi } from './base.api';
import { UserModel } from '../models/account/user.model';

export class UserApi extends BaseApi {
  public getFinanceInfo(agencyId?: string) {
    return this.axios.get('/account/FinanceInfo', {
      params: {
        agencyId,
      },
    });
  }

  public getUserProperties() {
    return this.axios.get('/account/UserProperties');
  }

  public updateUserProperties(data) {
    return this.axios.put('/account/users/properties', data);
  }

  public getUserList(): Promise<AxiosResponse<UserModel[]>> {
    return this.axios.get('/account/Users');
  }

  public registerUser(data) {
    return this.axios.post('/account/Register', data);
  }

  public changeUserStatus(userName, active) {
    return this.axios.put(`/account/users/${userName}/status?active=${active}`);
  }

  public sendEmailVoucher(tripId, body) {
    return this.axios.post(`/documentation/trips/${tripId}/voucher/send`, body);
  }

  public sendEmailInvoice(tripId, body) {
    return this.axios.post(`/documentation/trips/${tripId}/invoice/send`, body);
  }
  public sendEmailReceipt(tripId, body) {
    return this.axios.post(`/documentation/trips/${tripId}/receipt/send`, body);
  }

  public sendEmailCancellation(tripId: number, body: string[]) {
    return this.axios.post(
      `/documentation/trips/${tripId}/cancellation/send`,
      body,
    );
  }
}
