import React from 'react';

type Props = {
  icon?: string;
  caption?: string;
  description?: string;
};

function LandingHowItWorksCard(props: Props) {
  const { icon, caption, description } = props;

  return (
    <div className="landing-how-works-card">
      <div className="landing-how-works-card__header">
        {icon && (
          <div className="landing-how-works-card__icon">
            <i className={`icon tc-${icon}`} />
          </div>
        )}

        {caption && (
          <h4 className="landing-how-works-card__caption">{caption}</h4>
        )}
      </div>

      {description && (
        <p className="landing-how-works-card__description">{description}</p>
      )}
    </div>
  );
}

export default LandingHowItWorksCard;
