import React from 'react';
import { Button, Col, Divider, Row, Space } from 'antd';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';

type Props = {
  onSignUpClick?: () => void;
};

function LandingTopHeader(props: Props) {
  const { onSignUpClick } = props;

  return (
    <section className="landing-top-header">
      <div className="container-huge">
        <Row justify="end" align="middle" gutter={[20, 0]}>
          <Col>
            <Link to="/login">
              <Button type="link" className="top-header-btn">
                <Space size={18}>
                  <span className="top-header-btn__icon">
                    <i className="tc-user-default" />
                  </span>

                  {intl.get('login')}
                </Space>
              </Button>
            </Link>
          </Col>

          {onSignUpClick && (
            <>
              <Col>
                <Divider
                  type="vertical"
                  className="landing-top-header__divider"
                />
              </Col>

              <Col>
                <Button
                  type="link"
                  className="top-header-btn"
                  onClick={onSignUpClick}
                >
                  <Space size={18}>
                    <span className="top-header-btn__icon">
                      <i className="tc-tasks" />
                    </span>

                    {intl.get('signUp')}
                  </Space>
                </Button>
              </Col>
            </>
          )}
        </Row>
      </div>
    </section>
  );
}

export default LandingTopHeader;
