import { isNil } from 'lodash';

import { OrderCommunicationMethodEnum } from '../models/enums/orderCommunicationMethod.enum';
import { NotificationMethodEnum } from '../models/enums/notificationMethod.enum';
import { OrderTableDataModel } from '../models/orders/orderTableData.model';
import { BulkCommunicationFormValuesModel } from '../models/bulkCommunicationFormValues.model';
import { OrderColumn } from '../models/orders/orderColumn.model';
import { NotificationRptTypeModelEnum } from '../models/enums/notificationRptTypeModelEnum';
import {
  EMAIL_TEMPLATE_PREVIEW_ORDER,
  EMAIL_TEMPLATE_PREVIEW_ORDER_ITEM,
} from '../fixtures';
import { safeJSONParse, safeJSONStringify } from './jsonUtils';
import { NotificationTemplate } from '../models/notification-template/notificationTemplate.model';

export const getFirstAvailableCommunicationMethod = (
  columns: OrderColumn[],
) => {
  const communicationMethodColumns = columns.filter(
    (column) =>
      column.dataIndex === 'PrimaryEmail' ||
      column.dataIndex === 'PrimaryPhone',
  );

  return communicationMethodColumns[0]?.dataIndex === 'PrimaryEmail'
    ? NotificationMethodEnum.Email
    : NotificationMethodEnum.SMS;
};

export const getOrderCommunicationMethod = (
  communicationMethod: OrderCommunicationMethodEnum,
  order: OrderTableDataModel,
  columns: OrderColumn[],
) => {
  switch (communicationMethod) {
    case OrderCommunicationMethodEnum.Email: {
      return NotificationMethodEnum.Email;
    }

    case OrderCommunicationMethodEnum.SMS: {
      return NotificationMethodEnum.SMS;
    }

    case OrderCommunicationMethodEnum.FirstAvailable: {
      const firstAvailableCommunicationMethod =
        getFirstAvailableCommunicationMethod(columns);

      if (firstAvailableCommunicationMethod === NotificationMethodEnum.Email) {
        return order.PrimaryEmail
          ? NotificationMethodEnum.Email
          : NotificationMethodEnum.SMS;
      }

      if (firstAvailableCommunicationMethod === NotificationMethodEnum.SMS) {
        return order.PrimaryPhone
          ? NotificationMethodEnum.SMS
          : NotificationMethodEnum.Email;
      }
    }
  }
};

export const getOrderRecipient = (
  communicationMethod: OrderCommunicationMethodEnum,
  order: OrderTableDataModel,
  columns: OrderColumn[],
) => {
  switch (communicationMethod) {
    case OrderCommunicationMethodEnum.Email: {
      return order.PrimaryEmail;
    }

    case OrderCommunicationMethodEnum.SMS: {
      return order.PrimaryPhone;
    }

    case OrderCommunicationMethodEnum.FirstAvailable: {
      const firstAvailableCommunicationMethod =
        getFirstAvailableCommunicationMethod(columns);

      if (firstAvailableCommunicationMethod === NotificationMethodEnum.Email) {
        return order.PrimaryEmail ? order.PrimaryEmail : order.PrimaryPhone;
      }

      if (firstAvailableCommunicationMethod === NotificationMethodEnum.SMS) {
        return order.PrimaryPhone ? order.PrimaryPhone : order.PrimaryEmail;
      }
    }
  }
};

export const getOrderNotificationId = (
  values: BulkCommunicationFormValuesModel,
  order: OrderTableDataModel,
  columns: OrderColumn[],
) => {
  const safeTemplateId = isNil(values.template)
    ? undefined
    : Number(values.template);

  switch (values.communicationMethod) {
    case OrderCommunicationMethodEnum.Email:
    case OrderCommunicationMethodEnum.SMS: {
      return safeTemplateId;
    }

    case OrderCommunicationMethodEnum.FirstAvailable: {
      const firstAvailableCommunicationMethod =
        getFirstAvailableCommunicationMethod(columns);

      if (firstAvailableCommunicationMethod === NotificationMethodEnum.Email) {
        return order.PrimaryEmail ? safeTemplateId : undefined;
      }

      if (firstAvailableCommunicationMethod === NotificationMethodEnum.SMS) {
        return order.PrimaryPhone ? safeTemplateId : undefined;
      }
    }
  }
};

export const getMessage = (
  values: BulkCommunicationFormValuesModel,
  order: OrderTableDataModel,
  columns: OrderColumn[],
) => {
  switch (values.communicationMethod) {
    case OrderCommunicationMethodEnum.SMS: {
      return values.template ? undefined : values.messageText;
    }

    case OrderCommunicationMethodEnum.Email: {
      return values.template ? undefined : values.emailTemplate.html;
    }

    case OrderCommunicationMethodEnum.FirstAvailable: {
      if (values.template) return undefined;

      const firstAvailableCommunicationMethod =
        getFirstAvailableCommunicationMethod(columns);

      if (firstAvailableCommunicationMethod === NotificationMethodEnum.Email) {
        return order.PrimaryEmail
          ? values.emailTemplate.html
          : values.messageText;
      }

      if (firstAvailableCommunicationMethod === NotificationMethodEnum.SMS) {
        return order.PrimaryPhone
          ? values.messageText
          : values.emailTemplate.html;
      }
    }
  }
};

export const getPreviewModelByType = (type: NotificationRptTypeModelEnum) => {
  const previewModelString = {
    [NotificationRptTypeModelEnum.AllProducts]: EMAIL_TEMPLATE_PREVIEW_ORDER,
    [NotificationRptTypeModelEnum.OneProduct]:
      EMAIL_TEMPLATE_PREVIEW_ORDER_ITEM,
  }[type];

  return safeJSONStringify(previewModelString, null, ' ');
};

export const getTemplateInitialValues = (template: NotificationTemplate) => ({
  description: template?.Description,
  subject: template?.Subject,
  smsTemplate: template?.ReportTextMobile,
  modelType: template?.RptTypeModel,
  previewModel: getPreviewModelByType(template?.RptTypeModel),
  orderId: undefined,
  emailTemplate: {
    json: safeJSONParse(template?.Message),
    html: template?.ReportText,
  },
});
