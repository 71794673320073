import React from 'react';
import intl from 'react-intl-universal';
import { FormikProps } from 'formik';
import { Element } from 'react-scroll';

import CategoryAndRates from './CategoryAndRates';
import { ProductFormValuesModel } from '../../../../../../models/inventory/productFormValues.model';
import { ProductSectionKeyEnum } from '../../../../../../models/enums/productSectionKey.enum';

export default function ProductCategoriesAndRatesInfo(props: {
  extraData: any;
  formikProps: FormikProps<ProductFormValuesModel>;
  activeRateKey: string[];
  onActiveRateChange: (key: string | string[]) => void;
  onToggleAllRates: () => void;
}) {
  const {
    extraData,
    activeRateKey,
    formikProps,
    onToggleAllRates,
    onActiveRateChange,
  } = props;

  return (
    <Element name={ProductSectionKeyEnum.Rates} className="row">
      <div className="column title">
        {intl.get('inventory.products.popup.categoryAndRates')}
      </div>
      <div className="column content">
        <CategoryAndRates
          formikProps={formikProps}
          initialValues={extraData.initialValues}
          activeRateKey={activeRateKey}
          onToggleAll={onToggleAllRates}
          onActiveRateChange={onActiveRateChange}
        />
      </div>
    </Element>
  );
}
