import React, { useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { isNil, isEmpty, isFunction, get } from 'lodash';
import {
  Table,
  Space,
  TablePaginationConfig,
  notification,
  TableProps,
} from 'antd';
import {
  Formik,
  Form,
  FormikProps,
  FormikValues,
  yupToFormErrors,
} from 'formik';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import compose from 'compose-function';
import ReactDragListView from 'react-drag-listview';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import { SendNotificationModel } from 'models/notification/sendNotification.model';
import { OrderTableDataModel } from 'models/orders/orderTableData.model';
import { OrderCommunicationType } from 'models/enums/orderCommunicationType.enum';
import { OrderColumn } from 'models/orders/orderColumn.model';
import { OrderKeysEnum } from 'models/enums/orderKeys.enum';
import { BulkCommunicationFormValuesModel } from 'models/bulkCommunicationFormValues.model';
import { checkUserGroup } from 'utils/userRolesUtils';
import {
  getFirstAvailableCommunicationMethod,
  getMessage,
  getOrderCommunicationMethod,
  getOrderNotificationId,
  getOrderRecipient,
} from 'utils/messageTemplateUtils';
import { useQuery, useStore } from 'hooks';
import { TableQueryBuilder } from 'utils/tableQueryBuilder';
import { getOnCellEditableProps } from 'utils/tableUtils';
import { MANAGE_ORDERS_ROLES } from 'utils/constants';
import { BulkCommunicationSchema } from '../../schemas';
import {
  bulkCommunicationInitialValues,
  ORDER_UNSAVED_CHANGES_NOTIFICATION_KEY,
} from '../../constants';
import { useTableOrdersLoader, useTableOrdersColumns } from '../../hooks';
import { TableOrdersHeaderCell } from './TableOrdersHeaderCell';
import TableOrdersActions from './TableOrdersActions';
import TableOrdersCell from './TableOrdersCell';
import TableOrdersOperations from './TableOrdersOperations';

type Props = {
  onOrderClick: (tripId: number) => void;
};

function TableOrders(props: Props) {
  const { onOrderClick } = props;

  const ordersStore = useStore((state) => state.ordersStore);
  const userStore = useStore((state) => state.userStore);

  const navigate = useNavigate();
  const query = useQuery();

  const editOrderFormRef = useRef<FormikProps<FormikValues>>();
  const bulkCommunicationFormRef =
    useRef<FormikProps<BulkCommunicationFormValuesModel>>();

  const [editingOrderKey, setEditingOrderKey] = useState(null);
  const [isEditOrderLoading, setIsEditOrderLoading] = useState(false);
  const [isBulkCommunicationLoading, setIsBulkCommunicationLoading] =
    useState(false);
  const [validationSchema, setValidationSchema] = useState(null);

  useTableOrdersLoader();

  useEffect(() => {
    handleCancelEditingOrder();
  }, [query.orderTab]);

  const hasManageOrdersAccess = checkUserGroup(
    userStore.userProperties,
    MANAGE_ORDERS_ROLES,
  );

  const getIsEditRecordActive = (record: OrderTableDataModel) =>
    editingOrderKey === record.key;

  const ordersColumns = useTableOrdersColumns({
    hasManageOrdersAccess,
    getIsEditRecordActive,
    onOrderClick,
  });

  const components: TableProps<OrderTableDataModel>['components'] = {
    header: { cell: TableOrdersHeaderCell },
    body: { cell: TableOrdersCell },
  };

  const expandable: TableProps<OrderTableDataModel>['expandable'] = {
    expandedRowKeys: toJS(ordersStore.expandedRows),
    onExpandedRowsChange: (rows) =>
      ordersStore.setExpandedRows(rows as string[]),
  };

  const getRowSelection = (
    formikProps: FormikProps<BulkCommunicationFormValuesModel>,
  ): TableProps<OrderTableDataModel>['rowSelection'] => {
    if (!hasManageOrdersAccess) return undefined;

    const { values, setFieldValue } = formikProps;
    const { selectedOrders } = values;

    return {
      fixed: 'left',
      selectedRowKeys: selectedOrders.map(({ key }) => key),
      renderCell: (_$1, record, _$2, originNode) =>
        record.parent ? null : originNode,
      getCheckboxProps: (record) => ({
        disabled: Boolean(record.parent) || record.key === editingOrderKey,
      }),
      onChange: (_, orders) => setFieldValue('selectedOrders', orders),
    };
  };

  const mapOrderColumns = (columns: OrderColumn[]) =>
    columns.map((col) => ({
      ...col,
      onCell: (record: OrderTableDataModel) => ({
        ...getOnCellEditableProps(record, col),
        ...(col.resizable && {
          width: col.width,
          onResize: (_, { size }) => {
            ordersStore.setColumnWidth(col.key.toString(), size.width);
          },
        }),
      }),
      onHeaderCell: () => ({
        isDraggable: col.draggable,
      }),
    })) as unknown as OrderColumn[];

  const filterVisibleColumns = (columns: OrderColumn[]) =>
    columns.filter(
      (column) =>
        column && ordersStore.visibleColumnIds.includes(column.key.toString()),
    );

  const sortOrdersColumns = (columns: OrderColumn[]) =>
    [...columns].sort(
      (a, b) =>
        ordersStore.visibleColumnIds.indexOf(a.key.toString()) -
        ordersStore.visibleColumnIds.indexOf(b.key.toString()),
    );

  const addOperationColumn = (columns: OrderColumn[]) =>
    hasManageOrdersAccess
      ? [
          ...columns,
          {
            key: OrderKeysEnum.Operation,
            title: intl.get('orders.table.operation'),
            dataIndex: 'operation',
            draggable: false,
            resizable: false,
            width: 150,
            fixed: 'right',
            render: (_, record: OrderTableDataModel) => (
              <TableOrdersOperations
                isEditing={getIsEditRecordActive(record)}
                isLoading={isEditOrderLoading}
                isDisabled={record.Inactive || editingOrderKey !== null}
                onEdit={() => handleSetEditingOrder(record)}
                onCancel={handleCancelEditingOrder}
                onDetails={() => onOrderClick(record.TripId)}
                onSubmit={() => editOrderFormRef.current.submitForm()}
              />
            ),
          } as OrderColumn,
        ]
      : columns;

  const columns = compose(
    addOperationColumn,
    sortOrdersColumns,
    mapOrderColumns,
    filterVisibleColumns,
  )(ordersColumns);

  const handleCancelEditingOrder = () => {
    editOrderFormRef.current.setErrors({});
    editOrderFormRef.current.resetForm();

    setEditingOrderKey(null);
  };

  const indexEditableColumnsOnValue = (
    record: OrderTableDataModel,
    getValue: (col: OrderColumn) => unknown,
  ) =>
    columns.reduce(
      (acc, col) =>
        col.edit?.(col.key.toString(), record).isEnabled
          ? Object.assign(acc, {
              [col.dataIndex.toString()]: getValue(col),
            })
          : acc,
      {},
    );

  const handleSetEditingOrder = (record: OrderTableDataModel) => {
    setEditingOrderKey(record.key);

    setRecordValidationSchema(record);

    handleResetSelectedEditingOrder(record.key);

    editOrderFormRef.current.setValues(
      indexEditableColumnsOnValue(record, (col) =>
        col
          .edit?.(col.key.toString(), record)
          .getValue(get(record, col.dataIndex)),
      ),
    );
  };

  const handleResetSelectedEditingOrder = (key: string) => {
    const { values, setFieldValue } = bulkCommunicationFormRef.current;

    const selectedOrders = values.selectedOrders.filter(
      (selectedOrder) => selectedOrder.key !== key,
    );

    setFieldValue('selectedOrders', selectedOrders);
  };

  const handleEditOrderSubmit = async (values: FormikValues) => {
    try {
      setIsEditOrderLoading(true);

      await ordersStore.editOrder(
        editingOrderKey,
        values as OrderTableDataModel,
      );
    } finally {
      setIsEditOrderLoading(false);
      handleCancelEditingOrder();
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter:
      | SorterResult<OrderTableDataModel>
      | SorterResult<OrderTableDataModel>[],
  ) => {
    const initialQuery = { orderTab: ordersStore.currentTab };

    if (editingOrderKey) {
      notification.warn({
        key: ORDER_UNSAVED_CHANGES_NOTIFICATION_KEY,
        message: intl.get('orders.table.unsavedEditChanged'),
      });

      return;
    }

    if (!isEmpty(bulkCommunicationFormRef.current.values.selectedOrders)) {
      bulkCommunicationFormRef.current.validateForm();
    }

    navigate({
      pathname: '/orders',
      search: new TableQueryBuilder<OrderTableDataModel>(initialQuery)
        .setSorter(sorter)
        .setFilters(filters)
        .setPagination(pagination)
        .build(),
    });
  };

  const setRecordValidationSchema = (record: OrderTableDataModel) => {
    const columnValidations = indexEditableColumnsOnValue(record, (col) => {
      const { validation } = col.edit?.(col.key.toString(), record) ?? {};

      if (isNil(validation)) return null;

      return isFunction(validation) ? validation(record) : validation;
    });

    setValidationSchema(Yup.object(columnValidations));
  };

  const primaryMethod = getFirstAvailableCommunicationMethod(columns);

  const handleValidateBulkCommunication = (
    values: BulkCommunicationFormValuesModel,
  ) => {
    try {
      BulkCommunicationSchema.validateSync(values, {
        abortEarly: false,
        context: { values, orders: ordersStore.tableOrders, primaryMethod },
      });
    } catch (error) {
      return yupToFormErrors(error);
    }
  };

  const handleSendBulkCommunication = async (
    values: BulkCommunicationFormValuesModel,
  ) => {
    const { selectedOrders, template, messageType } = values;
    const isCustomMessage =
      messageType === OrderCommunicationType.CustomMessage;

    setIsBulkCommunicationLoading(true);

    try {
      const messages: SendNotificationModel[] = selectedOrders.map((order) => ({
        TripId: order.parent || order.TripId,
        Item: order.TripItemId,
        Method: getOrderCommunicationMethod(
          values.communicationMethod,
          order,
          columns,
        ),
        Message: getMessage(values, order, columns),
        Recipient: getOrderRecipient(
          values.communicationMethod,
          order,
          columns,
        ),
        Subject: values.emailSubject,
        NotificationTemplateId: getOrderNotificationId(values, order, columns),
      }));

      if (isCustomMessage) {
        await ordersStore.sendCustomBulkCommunication(messages);
      } else {
        await ordersStore.sendBulkCommunication(template, messages);
      }
    } finally {
      setIsBulkCommunicationLoading(false);
    }
  };

  const onHeaderCellDragEnd = (dragFromIndex: number, dragToIndex: number) => {
    const newColumns = ordersStore.visibleColumnIds.slice();
    const fromIndex = ordersStore.visibleColumnIds.findIndex(
      (column) => column === ordersStore.visibleColumnIds[dragFromIndex],
    );
    const toIndex = ordersStore.visibleColumnIds.findIndex(
      (column) => column === ordersStore.visibleColumnIds[dragToIndex],
    );

    newColumns.splice(toIndex, 0, newColumns.splice(fromIndex, 1)[0]);

    ordersStore.setVisibleColumnsIds(newColumns);
  };

  return (
    <Formik
      innerRef={bulkCommunicationFormRef}
      initialValues={bulkCommunicationInitialValues}
      validate={handleValidateBulkCommunication}
      onSubmit={handleSendBulkCommunication}
    >
      {(formikProps) => (
        <Space size={15} direction="vertical" className="w-100">
          <TableOrdersActions
            formikProps={formikProps}
            primaryMethod={primaryMethod}
            isBulkCommunicationLoading={isBulkCommunicationLoading}
          />

          <Formik
            enableReinitialize
            innerRef={editOrderFormRef}
            initialValues={{}}
            initialTouched={{
              PrimaryPhone: true,
            }}
            validationSchema={validationSchema}
            onSubmit={handleEditOrderSubmit}
          >
            <Form>
              <ReactDragListView.DragColumn
                nodeSelector="th.is-draggable"
                ignoreSelector="th:not(.is-draggable)"
                onDragEnd={onHeaderCellDragEnd}
              >
                <Table
                  sticky
                  columns={columns}
                  dataSource={ordersStore.tableOrders}
                  className="orders-table"
                  rowClassName={() => 'editable-row'}
                  pagination={{
                    ...ordersStore.tablePagination,
                    showSizeChanger: true,
                  }}
                  scroll={{ x: 1500 }}
                  components={components}
                  expandable={expandable}
                  rowSelection={getRowSelection(formikProps)}
                  loading={ordersStore.isLoading}
                  onChange={handleTableChange}
                />
              </ReactDragListView.DragColumn>
            </Form>
          </Formik>
        </Space>
      )}
    </Formik>
  );
}

export default observer(TableOrders);
