import React from 'react';
import intl from 'react-intl-universal';
import { Button, Popover, Checkbox, Space, Row, Col } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FilterOutlined, SettingOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { omit } from 'lodash';

import { withStore } from 'hocs';
import { CommonStore } from 'stores/common.store';
import { OrdersStore } from 'stores/orders.store';
import { parseQueryString } from 'utils/urlUtils';
import { TableQueryParser } from 'utils/tableQueryParser';
import { TableQueryBuilder } from 'utils/tableQueryBuilder';

type Props = {
  commonStore?: CommonStore;
  ordersStore?: OrdersStore;
};

function TableOrdersSettings(props: Props) {
  const { commonStore, ordersStore } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const onChange = (value: CheckboxValueType[]) =>
    ordersStore.setVisibleColumnsIds(value as string[]);

  const parsedFilters = new TableQueryParser(location).parseFilters();

  const isFilterActive = (filterId: string) =>
    Object.keys(parsedFilters).some((key) => key === filterId);

  const onClearFilter = (filterId: string) => {
    const search = new TableQueryBuilder(parseQueryString(location.search))
      .setFilters(omit(parsedFilters, [filterId]))
      .build();

    navigate({ search }, { replace: true });
  };

  return (
    <Popover
      content={
        <div className="field">
          <span className="field-label">
            {intl.get('orders.table.visibleColumns')}
          </span>

          <Checkbox.Group
            value={ordersStore.visibleColumnIds}
            onChange={onChange}
          >
            <Space direction="vertical">
              {commonStore.ordersListColumns.map((column) => (
                <Row justify="space-between" align="middle">
                  <Col>
                    <Checkbox
                      key={column.Id}
                      value={column.Id}
                      disabled={column.Required}
                    >
                      {column.Name}
                    </Checkbox>
                  </Col>

                  <Col>
                    {isFilterActive(column.Id) ? (
                      <Button
                        shape="circle"
                        size="small"
                        icon={<FilterOutlined style={{ fontSize: 12 }} />}
                        onClick={() => onClearFilter(column.Id)}
                      />
                    ) : null}
                  </Col>
                </Row>
              ))}
            </Space>
          </Checkbox.Group>
        </div>
      }
      trigger={['click']}
      placement="bottomRight"
      overlayClassName="table-orders-settings-popover"
      getPopupContainer={(triggerNode) => triggerNode}
    >
      <Button type="primary" shape="circle" icon={<SettingOutlined />} />
    </Popover>
  );
}

export default withStore(({ rootStore }) => ({
  commonStore: rootStore.commonStore,
  ordersStore: rootStore.ordersStore,
}))(TableOrdersSettings);
