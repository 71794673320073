import { BaseApi } from './base.api';

// for Matt new project
// const API_URL = 'https://api.chdestination.com';

export class TourApi extends BaseApi {
  public getTourDetails(tourId: string, language: string) {
    return this.axios.get(`/services/${tourId}/languages/${language}`);
  }

  public getTourAvailability(formData: any) {
    return this.axios.post(`/services/availability`, formData);
  }

  public getCancellationPolicy(productId: string) {
    return this.axios.get(`/services/cancellationpolicies/${productId}`);
  }

  public getServiceInventory(
    productId: string,
    startDate: string,
    endDate: string,
    stopsellOnly = true,
  ) {
    return this.axios.get(
      `/services/inventory/${productId}/${startDate}/${endDate}`,
      {
        params: {
          stopsellOnly,
        },
      },
    );
  }

  public performHoldProduct(formData) {
    return this.axios.post('/services/availability/hold', formData);
  }

  public performCancelAvailabilityHold(formData) {
    return this.axios.delete('/services/availability/hold', { data: formData });
  }
}
