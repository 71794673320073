import { SuggestedProductModel } from '../../../../models/marketplace/suggestedProduct.model';
import { availableLanguages } from '../../../../models/enums/language.enums';

const firstString = '<span class="bold">';
const secondString = '</span>';

const fromSuggestedSearchTextToText = (searchText: string): string =>
  searchText.replace(firstString, '').replace(secondString, '');

export const textToDisplayFromSuggestion = (
  language: string,
  suggestion: SuggestedProductModel,
) => {
  const shouldDisplayTranslatedName = language === availableLanguages.CHI; // requirement from Matt
  const city = shouldDisplayTranslatedName
    ? suggestion.TranslatedName
    : fromSuggestedSearchTextToText(suggestion['@search.text']);
  const { StateName, CountryName, RegionName } = suggestion;
  const locations = [city, StateName, CountryName, RegionName];

  return locations.filter(Boolean).join(', ');
};

export const textToDisplayInResults = (
  language: string,
  suggestion: SuggestedProductModel,
) => {
  const shouldDisplayTranslatedName = language === availableLanguages.CHI; // requirement from Matt
  const city = shouldDisplayTranslatedName
    ? suggestion.TranslatedName
    : fromSuggestedSearchTextToText(suggestion['@search.text']);

  return city;
};
