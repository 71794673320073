import React, { Component } from 'react';
import intl from 'react-intl-universal';
import * as Yup from 'yup';

import { Formik, Form, Field, FormikHelpers } from 'formik';
import { Button } from 'antd';
import { AuthStore } from '../../../../stores/auth.store';
import { InputContainer } from '../../../FormContainers';

import { supportEmail } from '../../../../utils/constants';
import { RouterProps, withRouter, withStore } from '../../../../hocs';

const passwordPattern = /[!#$%&<=>_?]/; // /[!#$%&*+-<=>?@^_~]/;

type Props = {
  authStore?: AuthStore;
} & RouterProps;

@withRouter
@withStore(({ rootStore }) => ({
  authStore: rootStore.authStore,
}))
export default class ResetPassword extends Component<Props> {
  handleSubmit = async (
    values: { password: string; confirmPassword: string },
    actions: FormikHelpers<{ password: string; confirmPassword: string }>,
  ) => {
    const { authStore, navigate, location } = this.props;
    const params = new URLSearchParams(location.search);
    const data = {
      UserName: params.get('userName'),
      Password: values.password,
      ConfirmPassword: values.confirmPassword,
      Code: params.get('code'),
    };

    try {
      await authStore.resetPassword(data);
      navigate('/login');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      // global error handler here
    }

    actions.setSubmitting(false);
  };

  render() {
    return (
      <div className="login-page">
        <Formik
          validateOnChange
          initialValues={{ password: '', confirmPassword: '' }}
          validationSchema={Yup.object({
            password: Yup.string()
              .min(6, intl.get('resetPassword.minPassword'))
              .matches(/\d/, intl.get('resetPassword.containDigit'))
              .matches(
                passwordPattern,
                intl.get('resetPassword.passwordPattern'),
              )
              .required(intl.get('resetPassword.passwordRequired')),
            confirmPassword: Yup.string()
              .oneOf(
                [Yup.ref('password')],
                intl.get('resetPassword.passwordsHaveToMatch'),
              )
              .required(intl.get('resetPassword.passwordConfirmRequired')),
          })}
          onSubmit={this.handleSubmit}
        >
          {() => (
            <Form className="login-form">
              <div className="form-wrapper">
                <h1 className="login-header">
                  {intl.get('resetPasswordTitle')}
                </h1>
                <Field
                  name="password"
                  component={InputContainer}
                  type="password"
                  label={intl.get('labels.newPassword')}
                />
                <Field
                  name="confirmPassword"
                  component={InputContainer}
                  type="password"
                  label={intl.get('labels.newPasswordConfirm')}
                />
                <div className="login-page-bottom forgot-page-bottom">
                  <Button
                    className="login-button"
                    type="primary"
                    htmlType="submit"
                    size="large"
                  >
                    {intl.get('submit')}
                  </Button>
                </div>
                <div className="support-message">
                  {intl.get('supportMessage')}
                </div>
                <div className="support-message">
                  {intl.get('contactUs')}{' '}
                  <a className="support-email" href={`mailto:${supportEmail}`}>
                    {supportEmail}
                  </a>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <aside className="right-container">
          <img className="logo" src="/logo.png" alt="logo" />
        </aside>
      </div>
    );
  }
}
