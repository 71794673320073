import React, { Component } from 'react';
import { Progress } from 'antd';
import moment from 'moment';

import { isObjectsEqualBy } from '../../utils/formatter';
import { CountDownTypeEnum } from '../../models/enums/countDownType.enum';

type Props = {
  type?: CountDownTypeEnum;
  width?: number;
  format?: string;
  startDate: string;
  endDate: string;
  onTimerEnd?: () => void;
};

const initialState = {
  countdown: '1970-01-01T00:00:00Z',
  percent: 0,
};

export default class CountDownTimer extends Component<Props> {
  state = initialState;

  timer = null;

  componentDidMount() {
    this.runCountDown();
  }

  componentDidUpdate(prevProps) {
    if (!isObjectsEqualBy(this.props, prevProps, ['startDate', 'endDate'])) {
      this.runCountDown();
    }
  }

  getFormattedTime = () => {
    const { format = 'mm:ss' } = this.props;
    const { countdown } = this.state;

    return moment(countdown).format(format);
  };

  calculateTimeLeft = () => {
    const { startDate, endDate, onTimerEnd } = this.props;

    const momentNow = moment();
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);
    const countdown = moment(
      (momentEndDate as any) - (momentNow as any),
    ).format();
    const percent =
      ((momentNow.unix() - momentStartDate.unix()) /
        (momentEndDate.unix() - momentStartDate.unix())) *
      100;

    if (momentNow.isAfter(momentEndDate)) {
      this.stopCountDown();

      if (onTimerEnd) onTimerEnd();

      return;
    }

    this.setState({ countdown, percent });
  };

  stopCountDown = () => {
    clearInterval(this.timer);

    this.timer = null;

    this.setState(initialState);
  };

  runCountDown = () => {
    this.calculateTimeLeft();

    this.timer = setInterval(() => this.calculateTimeLeft(), 1000);
  };

  renderTimer = () => {
    const { type = CountDownTypeEnum.Text, width = 80 } = this.props;
    const { percent } = this.state;

    switch (type) {
      case CountDownTypeEnum.Text: {
        return this.getFormattedTime();
      }

      case CountDownTypeEnum.Progress: {
        return (
          <Progress
            type="circle"
            width={width}
            className="count-down-timer"
            percent={percent}
            strokeWidth={10}
            format={this.getFormattedTime}
          />
        );
      }

      default:
        return null;
    }
  };

  render() {
    return this.renderTimer();
  }
}
