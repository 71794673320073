import React, { Component } from 'react';
import intl from 'react-intl-universal';
import pluralize from 'pluralize';
import { Button, Tabs } from 'antd';
import { Navigate } from 'react-router-dom';
import { has } from 'lodash';

import { withRouter, RouterProps } from 'hocs/withRouter';
import ProductTypeMenu from 'components/SharedComponents/ProductTypeMenu';
import { BulkInventoryStore } from 'stores/bulkInventory.store';
import { withStore } from 'hocs';
import { ProductCardModel } from 'models/inventory/productCard.model';
import {
  addToQueryString,
  omitFromQueryString,
  parseQueryString,
} from 'utils/urlUtils';
import { ProductTypeEnum } from 'models/enums/productType.enum';
import { InventoryTabEnum } from '../../../models/enums/inventoryTab.enum';
import { InventoryStore } from '../../../stores/inventory.store';
import ProductDetails from './components/ProductDetailsPopup/ProductDetails';
import InventoryTable from './components/InventoryTable';
import { InventoryBulkViewModal } from './components/InventoryBulkView';

type Props = {
  inventoryStore?: InventoryStore;
  bulkInventoryStore?: BulkInventoryStore;
} & RouterProps;

type State = {
  isProductPopupOpened: boolean;
  isInventoryBulkViewOpened: boolean;
};

@withRouter
@withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
  bulkInventoryStore: rootStore.bulkInventoryStore,
}))
export default class Inventory extends Component<Props, State> {
  state = {
    isProductPopupOpened: false,
    isInventoryBulkViewOpened: false,
  };

  componentDidMount() {
    this.handleLoadProducts();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.params.tab !== this.props.params.tab) {
      this.handleLoadProducts();
    }
  }

  componentWillUnmount() {
    const { inventoryStore } = this.props;
    const { preventCategoriesRequest, preventDestinationsRequest } =
      inventoryStore;

    if (preventCategoriesRequest) {
      inventoryStore.preventCategoriesRequest('cancel');
    }

    inventoryStore.preventProductsRequest();

    if (preventDestinationsRequest) {
      inventoryStore.preventDestinationsRequest('cancel');
    }

    inventoryStore.clearStore();
  }

  handleLoadProducts() {
    const { inventoryStore } = this.props;

    inventoryStore.getProducts();
  }

  handleProductSelect = (product: ProductCardModel) => {
    const { navigate, location } = this.props;

    navigate(
      {
        search: addToQueryString(location.search, {
          productId: product.Id,
          productType: product.ProductType,
        }),
      },
      { replace: true },
    );
  };

  handleNewProductClick = (productType: ProductTypeEnum) => {
    const { inventoryStore, navigate, location } = this.props;

    inventoryStore.stopGettingProduct();
    inventoryStore.clearDeletedRatesIds();
    inventoryStore.clearDeletedTimesIds();

    navigate(
      {
        search: addToQueryString(location.search, {
          productId: null,
          productType: productType.toString(),
        }),
      },
      { replace: true },
    );
  };

  handleProductPopupClose = () => {
    const { inventoryStore, navigate, location } = this.props;

    inventoryStore.clearProduct();
    inventoryStore.stopGettingProduct();
    inventoryStore.clearDeletedRatesIds();
    inventoryStore.clearDeletedTimesIds();

    navigate(
      {
        search: omitFromQueryString(location.search, [
          'productId',
          'productType',
        ]),
      },
      { replace: true },
    );
  };

  handleInventoryBulkViewOpen = (productType) => {
    const { bulkInventoryStore } = this.props;

    bulkInventoryStore.setProductType(productType);

    this.setState({ isInventoryBulkViewOpened: true });
  };

  handleInventoryBulkViewClose = () => {
    this.setState({ isInventoryBulkViewOpened: false });
  };

  handleTabsChange = (activeKey: string) => {
    const { navigate } = this.props;

    navigate(`/inventory/${activeKey}`, { replace: true });
  };

  get isProductDetailsOpen() {
    const { location } = this.props;
    const queryParams = parseQueryString(location.search);

    return has(queryParams, 'productId') && has(queryParams, 'productType');
  }

  get tabsItems() {
    return [
      {
        key: InventoryTabEnum.Active,
        value: InventoryTabEnum.Active,
        label: intl.get('inventory.tabs.active'),
        children: <InventoryTable onProductSelect={this.handleProductSelect} />,
      },

      {
        key: InventoryTabEnum.Inactive,
        value: InventoryTabEnum.Inactive,
        label: intl.get('inventory.tabs.inactive'),
        children: <InventoryTable onProductSelect={this.handleProductSelect} />,
      },

      {
        key: InventoryTabEnum.All,
        value: InventoryTabEnum.All,
        label: intl.get('inventory.tabs.all'),
        children: <InventoryTable onProductSelect={this.handleProductSelect} />,
      },
    ];
  }

  render() {
    const {
      inventoryStore: { total },
      params,
    } = this.props;
    const { isProductPopupOpened, isInventoryBulkViewOpened } = this.state;

    return (
      <div className="inventory">
        <div className="inventory-head">
          <div>
            <h2 className="page-title">{intl.get('inventoryPage.products')}</h2>
            <div>
              {`${pluralize(
                intl.get('inventoryPage.products'),
                total,
                true,
              ).toLowerCase()}`}
            </div>
          </div>

          <div>
            <ProductTypeMenu onClick={this.handleNewProductClick}>
              <Button className="header-tab-button m-r-15">
                {intl.get('buttons.newProduct')}
              </Button>
            </ProductTypeMenu>

            <ProductTypeMenu onClick={this.handleInventoryBulkViewOpen}>
              <Button className="header-tab-button">
                {intl.get('buttons.bulkView')}
              </Button>
            </ProductTypeMenu>
          </div>
        </div>

        {params.tab ? null : (
          <Navigate to={`/inventory/${InventoryTabEnum.Active}`} />
        )}

        <Tabs
          destroyInactiveTabPane
          size="large"
          items={this.tabsItems}
          activeKey={params.tab}
          onChange={this.handleTabsChange}
        />

        {this.isProductDetailsOpen && (
          <ProductDetails
            isProductPopupOpened
            handleProductPopupClose={this.handleProductPopupClose}
          />
        )}

        <InventoryBulkViewModal
          visible={isInventoryBulkViewOpened}
          onCancel={this.handleInventoryBulkViewClose}
        />
      </div>
    );
  }
}
