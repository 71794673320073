import React, { ReactElement } from 'react';
import { Dropdown, Menu } from 'antd';
import { ProductTypeEnum } from '../../models/enums/productType.enum';
import { getProductTypeLabel } from '../../utils/productUtils';

type Props = {
  children?: ReactElement;
  onClick: (type: ProductTypeEnum) => void;
};

function ProductTypeMenu(props: Props) {
  const { children, onClick } = props;

  const menu = (
    <Menu onClick={({ key }) => onClick(key as ProductTypeEnum)}>
      <Menu.Item key={ProductTypeEnum.Service}>
        {getProductTypeLabel(ProductTypeEnum.Service)}
      </Menu.Item>

      <Menu.Item key={ProductTypeEnum.Package}>
        {getProductTypeLabel(ProductTypeEnum.Package)}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      arrow
      trigger={['click']}
      placement="bottomCenter"
      overlay={menu}
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
    >
      {children}
    </Dropdown>
  );
}

export default ProductTypeMenu;
