import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { Link, Events } from 'react-scroll';
import intl from 'react-intl-universal';

import { ProductSectionKeyEnum } from 'models/enums/productSectionKey.enum';
import { PRODUCT_DETAILS_NAV_CONTAINER_ID } from '../../../constants';

type Props = {
  sectionsKeys: ProductSectionKeyEnum[];
};

function ProductDetailsNav(props: Props) {
  const { sectionsKeys } = props;

  const [selectedKey, setSelectedKey] = useState(ProductSectionKeyEnum.General);

  useEffect(() => {
    Events.scrollEvent.register('end', (key) => setSelectedKey(key));

    return () => Events.scrollEvent.remove('end');
  }, []);

  const getProductSectionLabel = (key: ProductSectionKeyEnum) => {
    switch (key) {
      case ProductSectionKeyEnum.General: {
        return intl.get('inventory.products.popup.general');
      }
      case ProductSectionKeyEnum.Rates: {
        return intl.get('inventory.products.popup.categoryAndRates');
      }
      case ProductSectionKeyEnum.Times: {
        return intl.get('inventory.products.popup.times');
      }
      case ProductSectionKeyEnum.Information: {
        return intl.get('inventory.products.popup.information');
      }
      case ProductSectionKeyEnum.Cancellation: {
        return intl.get('inventory.products.popup.cancellationPolicy');
      }
      case ProductSectionKeyEnum.Location: {
        return intl.get('inventory.products.popup.location.title');
      }
      default: {
        return '';
      }
    }
  };

  return (
    <Menu
      mode="horizontal"
      className="product-details-nav"
      selectedKeys={[selectedKey]}
      items={sectionsKeys.map((key) => ({
        key,
        label: (
          <Link
            to={key}
            spy
            smooth
            offset={-50}
            containerId={PRODUCT_DETAILS_NAV_CONTAINER_ID}
            onSetActive={(activeKey) => setSelectedKey(activeKey)}
          >
            {getProductSectionLabel(key)}
          </Link>
        ),
      }))}
    />
  );
}

export default ProductDetailsNav;
