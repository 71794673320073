import React, { useState } from 'react';
import { Button, Popover, Select, Space } from 'antd';
import intl from 'react-intl-universal';

import { withStore } from 'hocs';
import { UserFiltersStore } from 'stores/userFilters.store';
import { UserFiltersFormValuesModel } from 'models/user-filters/userFiltersFormValues.model';
import UserFiltersManageForm from 'components/SharedComponents/UserFiltersManageForm';

type Props = {
  userFiltersStore?: UserFiltersStore;
};

function TableOrdersUserFilters(props: Props) {
  const { userFiltersStore } = props;

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isSavePopoverVisible, setIsSavePopoverVisible] = useState(false);

  const selectedFilter = userFiltersStore.getSelectedFilter();
  const options = userFiltersStore.filters.map((filter) => ({
    label: userFiltersStore.parseFilterData(filter.FilterData).name,
    value: filter.Uuid,
  }));

  const onSave = async (values: UserFiltersFormValuesModel) => {
    setIsSaveLoading(true);

    const action = selectedFilter
      ? userFiltersStore.updateOrderFilter
      : userFiltersStore.createOrderFilter;

    const result = await action(values);

    setIsSaveLoading(false);

    if (result.error) {
      userFiltersStore.resetSelectedFilterId();
    } else {
      setIsSavePopoverVisible(false);
    }

    return result;
  };

  const onDelete = async () => {
    try {
      setIsDeleteLoading(true);

      await userFiltersStore.deleteOrderFilter();
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const onChange = (id: string) => userFiltersStore.setFilter(id);

  const onVisibleChange = (isVisible: boolean) => {
    if (isSaveLoading) return;

    setIsSavePopoverVisible(isVisible);
  };

  const getIsAccessSettingsVisible = () =>
    selectedFilter
      ? userFiltersStore.getIsUserOwnedFilter(selectedFilter.Uuid)
      : true;

  const getInitialValues = (): UserFiltersFormValuesModel => {
    const name = selectedFilter
      ? userFiltersStore.parseFilterData(selectedFilter.FilterData).name
      : '';
    const allNames = options
      .filter(
        (option) => !selectedFilter || option.value !== selectedFilter.Uuid,
      )
      .map((option) => option.label);

    return {
      name,
      allNames,
      isAvailableForAgents: selectedFilter?.IsSharedForAgency ?? false,
      isAvailableForSuppliers: selectedFilter?.IsSharedForSuppliers ?? false,
    };
  };

  return (
    <Space>
      <Select
        allowClear
        placeholder={intl.get('inventory.bulkView.selectViewCriteria')}
        style={{ width: 250 }}
        getPopupContainer={(triggerNode) => triggerNode}
        value={selectedFilter?.Uuid}
        options={options}
        dropdownRender={(menu) => (
          <>
            {menu}

            <Button block type="link" onClick={() => onChange(null)}>
              {intl.get('buttons.clear')}
            </Button>
          </>
        )}
        onChange={onChange}
      />

      <Popover
        destroyTooltipOnHide
        trigger={['click']}
        placement="bottomRight"
        visible={isSavePopoverVisible}
        content={() => (
          <UserFiltersManageForm
            isAccessSettingsVisible={getIsAccessSettingsVisible()}
            initialValues={getInitialValues()}
            isLoading={isSaveLoading}
            onSave={onSave}
          />
        )}
        getPopupContainer={(triggerNode) => triggerNode}
        onVisibleChange={onVisibleChange}
      >
        <div>
          <Button>
            {selectedFilter
              ? intl.get('buttons.update')
              : intl.get('buttons.save')}
          </Button>
        </div>
      </Popover>

      <Button
        loading={isDeleteLoading}
        disabled={!selectedFilter}
        onClick={onDelete}
      >
        {intl.get('buttons.delete')}
      </Button>
    </Space>
  );
}

export default withStore(({ rootStore }) => ({
  userFiltersStore: rootStore.userFiltersStore,
}))(TableOrdersUserFilters);
