import { action, computed, makeObservable, observable } from 'mobx';
import intl from 'react-intl-universal';
import moment from 'moment';
import { compact, groupBy, isEmpty, uniq } from 'lodash';
import axios from 'axios';
import { TablePaginationConfig } from 'antd';

import { router } from 'router';
import { OrdersApi } from 'api/orders.api';
import { OrderModel } from 'models/order.model';
import {
  addGlobalMessage,
  addGlobalSpinner,
  removeGlobalSpinner,
} from 'components/SharedComponents/GlobalMessages';
import { MessageTypeEnum } from 'models/global-messages/message.model';
import { OrderDetailsModel } from 'models/orderDetails.model';
import {
  getSelectedOrderCancelBody,
  mapRequestCancelBodyAccordingToOrderDetails,
} from 'utils/ordersHelper';
import { OrderFinanceModel } from 'models/orders/orderFinance.model';
import { isSupplierUser } from 'utils/userRolesUtils';
import {
  getEditedOrderRequest,
  mapOrderDetailsToRequest,
} from 'mappers/order.mapper';
import { CheckoutApi } from 'api/checkout.api';
import { OrderTabsEnum } from 'models/enums/orderTabs.enum';
import { NotificationTemplatesApi } from 'api/notificationTemplates.api';
import { NotificationTemplate } from 'models/notification-template/notificationTemplate.model';
import { OrderTableDataModel } from 'models/orders/orderTableData.model';
import { NotificationsApi } from 'api/notifications.api';
import { SendNotificationModel } from 'models/notification/sendNotification.model';
import { OrderCancellationFormModel } from 'models/orders/orderCancellationForm.model';
import { NotificationRptTypeModelEnum } from 'models/enums/notificationRptTypeModelEnum';
import { OrderKeysEnum } from 'models/enums/orderKeys.enum';
import { OrderFilterOperatorsEnum } from 'models/enums/orderFilterOperators.enum';
import { TableQueryParser } from 'utils/tableQueryParser';
import { OrderExcelReportGenerator } from 'utils/report-generator/excel-report-generator';
import { parseQueryString } from 'utils/urlUtils';
import { NotificationTableModel } from 'models/notification/notificationTableModel';
import { NotificationHistoryModel } from 'models/notification/notificationHistory.model';
import { NotificationStatusEnum } from 'models/enums/notificationStatusEnum';
import { UserFilterDataModel } from 'models/user-filters/userFilterData.model';
import { OrderRouterRoleEnum } from 'models/enums/orderRouterRole.enum';
import OrdersFilterMapper from '../mappers/ordersFilter.mapper';
import OrdersTableParamsMapper from '../mappers/ordersTableParams.mapper';
import { RootStore } from './root.store';
import { NotificationTypeModelEnum } from '../models/enums/notificationTypeModelEnum';
import { formatOrderPrimaryGuest } from '../utils/formatter';

export class OrdersStore {
  private rootStore: RootStore;
  private apiService: OrdersApi;
  private checkoutApiService: CheckoutApi;
  private notificationsApiService: NotificationsApi;
  private notificationTemplatesApiService: NotificationTemplatesApi;
  private cancelLoadOrders: AbortController = null;

  @observable public isInitialized = false;
  @observable public isOrderInProcessing = false;
  @observable public isLoading = false;
  @observable public visibleColumnIds: string[] = [];
  @observable public columnWidths: Record<string, number | null> = {};
  @observable private loadedOrders: OrderModel[] = [];
  @observable public tablePagination: TablePaginationConfig = {};
  @observable public expandedRows: string[] = [];
  @observable public isAllRowsExpanded = false;
  @observable public isExpandedRowsIndeterminate = false;
  @observable public notificationTemplates: NotificationTemplate[] = [];
  @observable public orderDetails: OrderDetailsModel = undefined;
  @observable public orderDetailsFinance: OrderFinanceModel[] = undefined;
  @observable
  public detailsTableNotifications: NotificationTableModel[] = null;
  @observable public isShowAdditionalGuests = false;

  constructor(
    rootStore: RootStore,
    apiService: OrdersApi,
    checkoutApiService: CheckoutApi,
    notificationsApiService: NotificationsApi,
    notificationTemplatesApiService: NotificationTemplatesApi,
  ) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.apiService = apiService;
    this.checkoutApiService = checkoutApiService;
    this.notificationsApiService = notificationsApiService;
    this.notificationTemplatesApiService = notificationTemplatesApiService;
  }

  private mapTableOrders(orders: OrderModel[]): OrderTableDataModel[] {
    return orders.map((order) => ({
      ...order,
      parent: null,
      guest: null,
      key: [order.TripId, order.TripItemId].join('-'),
      children: isEmpty(order.AdditionalGuestDetails)
        ? null
        : order.AdditionalGuestDetails.map((guest, index) => ({
            key: [order.TripId, order.TripItemId, index].join('-'),
            guest,
            parent: order.TripId,
            TripId: order.TripId,
            TripItemId: order.TripItemId,
            Inactive: order.Inactive,
            PrimaryGuestName: formatOrderPrimaryGuest(
              guest.FirstName,
              guest.LastName,
            ),
            PrimaryEmail: guest.Email,
            PrimaryPhone: guest.Phone,
            TestMode: order.TestMode,
          })),
    }));
  }

  @computed public get tableOrders(): OrderTableDataModel[] {
    return this.mapTableOrders(this.loadedOrders);
  }

  @computed public get allExpandableRows() {
    return this.tableOrders
      .filter((order) => order.children)
      .map((child) => child.key);
  }

  public get currentTab() {
    const query = parseQueryString(router.state.location.search);

    return query.orderTab as OrderTabsEnum;
  }

  private get routeRole() {
    return isSupplierUser(this.rootStore.userStore.userProperties)
      ? OrderRouterRoleEnum.Supplier
      : OrderRouterRoleEnum.Agency;
  }

  @action
  public async downloadReport() {
    try {
      this.isOrderInProcessing = true;

      const params = {
        includeCancelled: true,
        applyPagination: false,
        ...new OrdersTableParamsMapper(router.state.location)
          .buildSorts()
          .buildFilters()
          .map(),
      };
      const response = await this.apiService.getOrders(
        this.routeRole,
        this.currentTab,
        { params },
      );

      OrderExcelReportGenerator.downloadReport(
        this.mapTableOrders(response.data),
        this.visibleColumnIds,
        () => {
          this.isOrderInProcessing = false;
        },
      );
    } catch (e) {
      this.isOrderInProcessing = false;
    }
  }

  @action public toggleExpandAllRows(checked: boolean) {
    this.setExpandedRows(checked ? this.allExpandableRows : []);
  }

  @action public setExpandedRows(rows: string[]) {
    const isAllRowsExpanded = this.allExpandableRows.length === rows.length;

    this.isAllRowsExpanded = isAllRowsExpanded;
    this.isExpandedRowsIndeterminate = !isAllRowsExpanded && rows.length > 0;
    this.expandedRows = rows;
  }

  @action
  public async findOrderById(id: string) {
    try {
      const params = {
        filters: new OrdersFilterMapper(OrderKeysEnum.TripId).getSearch(
          OrderFilterOperatorsEnum.Equals,
          id,
        ),
      };
      const response = await this.apiService.getOrders(
        this.routeRole,
        OrderTabsEnum.All,
        { params },
      );

      if (response.status === 204) {
        addGlobalMessage({
          message: intl.get('orders.orderNotFound'),
          type: MessageTypeEnum.error,
        });
      }

      const orders = response.data ?? [];

      return orders[0];
    } catch (error) {
      addGlobalMessage({
        message: intl.get('orders.failedToFind'),
        type: MessageTypeEnum.error,
      });

      throw error;
    }
  }

  @action
  public async loadOrders() {
    try {
      this.isLoading = true;

      this.cancelLoadOrders?.abort();

      const abortController = new AbortController();

      this.cancelLoadOrders = abortController;

      const params = {
        includeCancelled: true,
        ...new OrdersTableParamsMapper(router.state.location)
          .buildSorts()
          .buildFilters()
          .buildPagination()
          .map(),
      };
      const response = await this.apiService.getOrders(
        this.routeRole,
        this.currentTab,
        {
          params,
          signal: abortController.signal,
        },
      );

      const orders = response.data || [];
      const paginationHeader = response.headers['x-pagination'];

      this.loadedOrders = orders;
      this.tablePagination = this.parsePaginationHeader(paginationHeader);
      this.isLoading = false;
      this.toggleExpandAllRows(this.isAllRowsExpanded);

      return orders;
    } catch (error) {
      if (axios.isCancel(error)) return;

      this.isLoading = false;
      this.loadedOrders = [];

      addGlobalMessage({
        message: intl.get('orders.failedToLoad'),
        type: MessageTypeEnum.error,
      });
    }
  }

  private parsePaginationHeader(header = ''): TablePaginationConfig {
    const [current, pageSize, total] = header
      .split(',')
      .map((value) => parseInt(value.split('=')[1], 10));

    return { current, pageSize, total };
  }

  @action
  async loadFilterOptions(field: string) {
    const params = {
      includeCancelled: true,
    };

    const response = await this.apiService.getFilterOptions(
      this.routeRole,
      this.currentTab,
      field,
      { params },
    );

    return response.data.filter((option) => option.Key);
  }

  @action
  public setVisibleColumnsIds(columnIds: string[]) {
    this.visibleColumnIds = columnIds;
  }

  @action
  public setColumnWidths(columnWidths: Record<string, number | null>) {
    this.columnWidths = columnWidths;
  }

  @action
  public setColumnWidth(id: string, width: number) {
    this.columnWidths[id] = width;
  }

  public getColumnWidth(id: string) {
    return this.columnWidths[id];
  }

  @action
  public async initialize() {
    const { userFiltersStore } = this.rootStore;
    const { getOrdersFilter } = userFiltersStore;

    await getOrdersFilter();

    this.initializeFilters();

    this.isInitialized = true;
  }

  @action
  public initializeFilters() {
    const { commonStore, userFiltersStore } = this.rootStore;

    const savedFilterId = localStorage.getItem('orders-user-filter-id');

    if (savedFilterId) {
      const selectedFilterId = userFiltersStore.setFilter(savedFilterId);

      if (!selectedFilterId) return;

      const selectedFilter = userFiltersStore.getSelectedFilter();

      const userFilters = userFiltersStore.parseFilterData(
        selectedFilter.FilterData,
      );

      this.setUserFilters(userFilters);
    } else {
      const filters = new TableQueryParser(
        router.state.location,
      ).parseFilters();

      const columns = uniq([
        ...commonStore.ordersListColumnsIds,
        ...Object.keys(filters),
      ]);

      this.setVisibleColumnsIds(columns);
    }
  }

  public setUserFilters(userFilters: UserFilterDataModel) {
    const { visibleColumnIds, columnWidths } = userFilters;

    if (visibleColumnIds) {
      this.setVisibleColumnsIds(visibleColumnIds);
    }

    if (columnWidths) {
      this.setColumnWidths(columnWidths);
    }
  }

  @action
  public async getOrderDetailsByTripId(tripId: number) {
    try {
      const response = await this.apiService.getOrderDetailsByTripId(tripId);
      const order = response.data;

      if (order.Hotels.length) {
        order.Hotels = order.Hotels.map((hotel) => ({
          ...hotel,
          Guests: hotel.Rooms.reduce(
            (AllRooms, room) => [...AllRooms, ...room.Guests],
            [],
          ),
        }));
      }

      this.orderDetails = observable(order);
      return Promise.resolve(order);
    } catch (error) {
      addGlobalMessage({
        message: `${intl.get('orders.popup.failedToLoadOrder')} ${tripId}`,
        type: MessageTypeEnum.error,
      });
      return Promise.reject(error);
    }
  }

  @action
  public async editOrder(orderKey: string, values: OrderTableDataModel) {
    const editingOrder = this.tableOrders
      .flatMap((order) => [order, ...(order.children ?? [])])
      .find((order) => order.key === orderKey);

    if (!editingOrder) return;

    const tripId = editingOrder.parent || editingOrder.TripId;

    try {
      const orderDetailsResponse =
        await this.apiService.getOrderDetailsByTripId(tripId);

      const testMode = editingOrder.TestMode;
      const orderDetails = orderDetailsResponse.data as OrderDetailsModel;
      const orderRequest = mapOrderDetailsToRequest(orderDetails, { testMode });
      const newOrder = { ...editingOrder, ...values };
      const editedOrderRequest = getEditedOrderRequest(newOrder, orderRequest);

      const order = await this.checkoutApiService.saveOrder(editedOrderRequest);

      await this.loadOrders();

      return Promise.resolve(order);
    } catch (error) {
      addGlobalMessage({
        message: `${intl.get('orders.popup.failedToEditOrder')} ${tripId}`,
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async getOrderFinance(tripId: number) {
    try {
      const response = await this.apiService.getOrderFinance(tripId);
      this.orderDetailsFinance = observable(response.data);
      return Promise.resolve(response.data);
    } catch (error) {
      addGlobalMessage({
        message: `${intl.get(
          'orders.popup.failedToLoadOrderFinance',
        )} ${tripId}`,
        type: MessageTypeEnum.error,
      });
      return Promise.reject(error);
    }
  }

  private getTemplateUrl(templateId: number) {
    return this.notificationTemplates.find(
      (notificationTemplate) => notificationTemplate.Id === templateId,
    );
  }

  private getBulkCommunicationUrl() {
    return isSupplierUser(this.rootStore.userStore.userProperties)
      ? NotificationRptTypeModelEnum.OneProduct
      : NotificationRptTypeModelEnum.AllProducts;
  }

  @action
  public async sendBulkCommunication(
    templateId: number,
    messages: SendNotificationModel[],
  ) {
    try {
      const templateUrl = this.getTemplateUrl(templateId);

      const url = templateUrl
        ? templateUrl.RptTypeModel
        : this.getBulkCommunicationUrl();

      await this.notificationsApiService.sendNotifications(url, messages);

      addGlobalMessage({
        message: intl.get('orders.popup.sendMessageSuccess'),
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      addGlobalMessage({
        message: intl.get('orders.popup.failedToSendMessage'),
        type: MessageTypeEnum.error,
      });
    }
  }

  public async sendCustomBulkCommunication(messages: SendNotificationModel[]) {
    try {
      const url = NotificationTypeModelEnum.Custom;

      await this.notificationsApiService.sendNotifications(url, messages);

      addGlobalMessage({
        message: intl.get('orders.popup.sendMessageSuccess'),
        type: MessageTypeEnum.success,
      });
    } catch (error) {
      addGlobalMessage({
        message: intl.get('orders.popup.failedToSendMessage'),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async loadOrderNotificationsHistory() {
    const tripId = this.orderDetails.TripId;

    try {
      const response =
        await this.notificationsApiService.getTripNotificationHistory(tripId);

      this.detailsTableNotifications = this.mapHistoryToTableNotifications(
        response.data,
      );
    } catch (e) {
      this.detailsTableNotifications = null;
    }
  }

  private mapHistoryToTableNotifications(
    notificationHistory: NotificationHistoryModel,
  ): NotificationTableModel[] {
    const filteredNotifications = notificationHistory.TripNotifications.filter(
      (tripNotification) => !isEmpty(tripNotification.DeliveryStatuses),
    );

    return filteredNotifications
      .map((notification) => ({
        id: notification.NotificationId,
        method: notification.MethodType,
        templateInfo: notification.TemplateInfo,
        ...notification.DeliveryStatuses.reduce(
          (acc, deliveryStatus) =>
            Object.assign(acc, {
              [deliveryStatus.StatusDescription]:
                deliveryStatus.StatusUpdatedAt,
            }),
          {},
        ),
      }))
      .sort((a, b) =>
        moment(a[NotificationStatusEnum.Sent]).diff(
          moment(b[NotificationStatusEnum.Sent]),
        ),
      );
  }

  @action
  public async downloadOrderDocumentByUrlVoucher(
    formatId: string,
    name: string,
    tripId: number,
  ) {
    try {
      const correctUrl = `/documentation/trips/${tripId}/voucherdocs/formats/${formatId}/retrieve`;
      const { data } = await this.apiService.getOrderDocumentByUrl(correctUrl);

      const blob = data.slice(0, data.size, 'application/pdf');
      const fileUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = fileUrl;
      link.download = `${name}-${tripId}.pdf`;
      link.click();

      return Promise.resolve();
    } catch (error) {
      addGlobalMessage({
        message: `${intl.get(
          'orders.popup.failedToDownloadDocument',
        )} ${tripId}`,
        type: MessageTypeEnum.error,
      });
      return Promise.reject(error);
    }
  }

  @action
  public async downloadOrderDocumentByUrl(
    url: string,
    formatId: string,
    docType: string,
    tripId: number,
  ) {
    try {
      // {formatId} => %7BformatId%7D by url encode
      const correctUrl = url.replace('%7BformatId%7D', formatId);
      const response = await this.apiService.getOrderDocumentByUrl(correctUrl);

      const fileUrl = URL.createObjectURL(response.data);
      const link = document.createElement('a');

      link.href = fileUrl;
      link.download = `${docType}-${tripId}`;
      link.click();

      return Promise.resolve();
    } catch (error) {
      addGlobalMessage({
        message: `${intl.get(
          'orders.popup.failedToDownloadDocument',
        )} ${tripId}`,
        type: MessageTypeEnum.error,
      });
      return Promise.reject(error);
    }
  }

  @action
  public async sendOrderReConfirm(tripId: number, reConfirmEmail: string) {
    try {
      await this.apiService.sendOrderReConfirm(tripId, reConfirmEmail);

      addGlobalMessage({
        message: `${intl.get('orders.popup.successToSendReConfirm')} ${tripId}`,
        type: MessageTypeEnum.success,
      });

      return Promise.resolve();
    } catch (error) {
      addGlobalMessage({
        message: `${intl.get('orders.popup.failedToSendReConfirm')} ${tripId}`,
        type: MessageTypeEnum.error,
      });
      return Promise.reject(error);
    }
  }

  @action
  public async getVoucherEmailAddresses(tripId) {
    try {
      const { data } = await this.apiService.getVoucherEmailAddresses(tripId);

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  @action
  public async getInvoiceEmailAddresses(tripId) {
    try {
      const { data } = await this.apiService.getInvoiceEmailAddresses(tripId);

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  @action
  public async getReceiptEmailAddresses(tripId) {
    try {
      const { data } = await this.apiService.getReceiptEmailAddresses(tripId);

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  @action
  public clearOrderDetails() {
    this.orderDetails = undefined;
  }

  @action
  public async cancelOrder(
    orderDetails: OrderDetailsModel,
    values: OrderCancellationFormModel,
    options?,
  ) {
    const {
      userStore: { financeInfo },
    } = this.rootStore;

    try {
      addGlobalSpinner();
      const requestBody = mapRequestCancelBodyAccordingToOrderDetails(
        orderDetails,
        values,
        financeInfo,
        options,
      );

      const response = await this.apiService.cancelOrder(requestBody);

      this.orderDetails = observable(response.data);
      removeGlobalSpinner();

      return Promise.resolve(response.data);
    } catch (error) {
      removeGlobalSpinner();
      addGlobalMessage({
        message: `${intl.get('orders.popup.failedToCancel')} ${
          orderDetails.TripId
        }`,
        type: MessageTypeEnum.error,
      });
      return Promise.reject(error);
    }
  }

  public async cancelSelectedOrders(ordersToCancel: OrderTableDataModel[]) {
    const groupedOrders = groupBy(ordersToCancel, (order) => order.TripId);

    await Promise.all(
      Object.entries(groupedOrders).map(([tripId, ordersGroup]) =>
        this.handleOrdersGroupCancellation(tripId, ordersGroup),
      ),
    );

    addGlobalMessage({
      message: intl.get('orders.table.cancelOrderSuccess', {
        ordersCount: ordersToCancel.length,
      }),
      type: MessageTypeEnum.success,
    });

    await this.loadOrders();
  }

  private async handleOrdersGroupCancellation(
    tripId: string,
    ordersGroup: OrderTableDataModel[],
  ) {
    const { userStore } = this.rootStore;

    await this.cancelOrdersGroup(tripId, ordersGroup);
    await userStore.sendUserEmailCancellation(parseInt(tripId, 10));
  }

  private async cancelOrdersGroup(
    tripId: string,
    ordersGroup: OrderTableDataModel[],
  ) {
    try {
      const [order] = ordersGroup;
      const tripItemIds = ordersGroup.map(({ TripItemId }) => TripItemId);
      const body = getSelectedOrderCancelBody(order, tripItemIds);

      await this.apiService.cancelOrder(body);
    } catch (error) {
      addGlobalMessage({
        message: intl.get('orders.table.failedToCancelOrder', { tripId }),
        type: MessageTypeEnum.error,
      });
    }
  }

  @action
  public async getNotificationTemplates() {
    const response =
      await this.notificationTemplatesApiService.getNotificationTemplates();

    this.notificationTemplates = response.data.value;
  }

  @action
  public clearStore() {
    this.isInitialized = false;
    this.isOrderInProcessing = false;
    this.isLoading = false;

    this.loadedOrders = [];
    this.orderDetails = undefined;
    this.orderDetailsFinance = undefined;
    this.notificationTemplates = [];
    this.isShowAdditionalGuests = false;
  }
}
