/* eslint-disable no-param-reassign */
import React, { useCallback, useMemo } from 'react';
import intl from 'react-intl-universal';
import { UploadFile } from 'antd/lib/upload/interface';

import { isNil, round } from 'lodash';
import { Popover, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ImageCropper from '../../../../../SharedComponents/ImageCropper';
import { ImageSizeEnum } from '../../../../../../utils/constants';
import { SiteSettingsModel } from '../../../../../../models/site-settings/siteSettings.model';
import { PreviewFile } from './ProductUpload';
import SingleUpload from './SingleUpload';
import { bytesToSize } from '../../../../../../utils/formatter';

type UploaderConfig = {
  size: ImageSizeEnum;
  label: string;
  aspect: number;
};

type Props = {
  fileList: PreviewFile[];
  siteSettings: SiteSettingsModel;
  onRemove: (file: UploadFile) => void;
  onPreview: (file: UploadFile) => void;
  beforeCrop: (file: File, fileList: File[]) => boolean;
  onApplyCrop: (file: File, fileList: File[]) => boolean;
};

const UPLOADER_WIDTH = 100;

function PrimaryImageUpload(props: Props) {
  const {
    fileList,
    beforeCrop,
    siteSettings,
    onRemove,
    onPreview,
    onApplyCrop,
  } = props;

  const uploaderList = useMemo(
    () =>
      (
        [
          {
            size: ImageSizeEnum.LARGE,
            label: intl.get(
              `SUPPLIER.PRODUCT_DETAILS.IMAGES_SECTION.FORM.SIZE_LABELS.${ImageSizeEnum.LARGE}`,
            ),
            aspect: siteSettings?.largeImageAspectRatio,
          },
          {
            size: ImageSizeEnum.MEDIUM,
            label: intl.get(
              `SUPPLIER.PRODUCT_DETAILS.IMAGES_SECTION.FORM.SIZE_LABELS.${ImageSizeEnum.MEDIUM}`,
            ),
            aspect: siteSettings?.mediumImageAspectRatio,
          },
          {
            size: ImageSizeEnum.SMALL,
            label: intl.get(
              `SUPPLIER.PRODUCT_DETAILS.IMAGES_SECTION.FORM.SIZE_LABELS.${ImageSizeEnum.SMALL}`,
            ),
            aspect: siteSettings?.smallImageAspectRatio,
          },
          {
            size: ImageSizeEnum.THUMBNAIL,
            label: intl.get(
              `SUPPLIER.PRODUCT_DETAILS.IMAGES_SECTION.FORM.SIZE_LABELS.${ImageSizeEnum.THUMBNAIL}`,
            ),
            aspect: siteSettings?.thumbnailImageAspectRatio,
          },
        ] as UploaderConfig[]
      ).filter((config) => config.aspect),
    [],
  );

  const handleApplyCrop = useCallback(
    (size: ImageSizeEnum, aspect: number, file: any, files: any[]) => {
      file.apiSize = size;
      file.isPrimary = true;
      file.aspect = aspect;

      return onApplyCrop?.(file, files);
    },
    [],
  );

  const getUploaderStyle = (config: UploaderConfig): React.CSSProperties => ({
    height: round(UPLOADER_WIDTH / config.aspect),
  });

  const getImagePreview = (imageSize: ImageSizeEnum) =>
    ({
      [ImageSizeEnum.LARGE]: '/images/preview-large.png',
      [ImageSizeEnum.SMALL]: '/images/preview-small.png',
      [ImageSizeEnum.THUMBNAIL]: '/images/preview-thumbnail.png',
    }[imageSize]);

  const getImageDetails = (config: UploaderConfig) => {
    const file = fileList.find((f) => f.apiSize === config.size);

    const imagePreview = getImagePreview(file?.apiSize as ImageSizeEnum);

    const isAllFieldsEmpty = [
      file?.size,
      file?.width,
      file?.height,
      file?.aspect,
      imagePreview,
    ].every(isNil);

    if (!file || isAllFieldsEmpty) return null;

    return (
      <>
        {Boolean(file?.size) && (
          <p>
            <strong>
              {intl.get('SUPPLIER.PRODUCT_DETAILS.IMAGES_SECTION.SIZE')}:
            </strong>
            <span> {bytesToSize(file.size)}</span>
          </p>
        )}

        {Boolean(file?.width && file?.height) && (
          <p>
            <strong>
              {intl.get('SUPPLIER.PRODUCT_DETAILS.IMAGES_SECTION.DIMENSIONS')}:
            </strong>
            <span> {`${file.width} x ${file.height}`}</span>
          </p>
        )}

        {Boolean(file?.aspect) && (
          <p>
            <strong>
              {intl.get('SUPPLIER.PRODUCT_DETAILS.IMAGES_SECTION.ASPECT_RATIO')}
              :
            </strong>
            <span> {file.aspect}</span>
          </p>
        )}

        {Boolean(imagePreview) && (
          <p>
            <strong className="d-b">
              {intl.get('SUPPLIER.PRODUCT_DETAILS.IMAGES_SECTION.PREVIEW')}:
            </strong>

            <img src={imagePreview} alt={file.name} width={300} />
          </p>
        )}
      </>
    );
  };

  const getImageDetailsPopover = (config: UploaderConfig) => {
    const imageDetails = getImageDetails(config);

    if (!imageDetails) return null;

    return (
      <Popover
        content={imageDetails}
        getPopupContainer={(triggerNode) => triggerNode}
      >
        <InfoCircleOutlined className="c-p" />
      </Popover>
    );
  };

  return (
    <div>
      <h3>
        {intl.get('SUPPLIER.PRODUCT_DETAILS.IMAGES_SECTION.PRIMARY_IMAGE')}
      </h3>

      <Space size={10} align="start">
        {uploaderList.map((config) => (
          <div key={config.size} style={{ width: UPLOADER_WIDTH }}>
            <span>{config.label} </span>

            <span>{getImageDetailsPopover(config)}</span>

            <div style={getUploaderStyle(config)}>
              <ImageCropper
                showGrid
                rotationSlider
                aspect={config.aspect}
                beforeCrop={beforeCrop}
              >
                <SingleUpload
                  size={config.size}
                  fileList={fileList}
                  onRemove={onRemove}
                  onPreview={onPreview}
                  beforeUpload={(...args) =>
                    handleApplyCrop(config.size, config.aspect, ...args)
                  }
                />
              </ImageCropper>
            </div>
          </div>
        ))}
      </Space>
    </div>
  );
}

export default React.memo(PrimaryImageUpload);
