export class MessageModel {
  id?: Date;
  message?: string;
  description?: string;
  type?: MessageTypeEnum;
  closable?: boolean;
  showIcon?: boolean;
}

export enum MessageTypeEnum {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}
