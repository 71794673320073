import React from 'react';
import intl from 'react-intl-universal';
import * as Yup from 'yup';
import { Field } from 'formik';
import {
  englishLetterRegex,
  emailRegex,
  englishLettesAndSymbolsRegex,
} from '../../../../utils/formatter';

import { InputContainer, TextareaContainer } from '../../../FormContainers';

function renderAgencyInfo(extraData) {
  const { currentContact } = extraData;

  return (
    <div className="d-f f-d-column flx-1">
      <div className="d-f jc-sb ai-fs">
        <Field
          className="m-r-5"
          name="Name"
          label={intl.get('contacts.popup.agencyName')}
          component={InputContainer}
        />
        <Field
          className="m-l-5"
          name="Id"
          disabled={currentContact}
          label={intl.get('contacts.popup.uniqueCode')}
          component={InputContainer}
        />
      </div>
      <div className="d-f jс-fs ai-fs">
        <Field
          className="p-r-5 w-50"
          name="ARNumber"
          label={`${intl.get('contacts.popup.accountReceivable')} #`}
          component={InputContainer}
        />
      </div>
    </div>
  );
}

function renderContactPerson() {
  return (
    <div className="d-f f-d-column flx-1">
      <div className="d-f jc-sb ai-fs">
        <Field
          className="m-r-5"
          name="PrimaryContact"
          label={intl.get('contacts.popup.name')}
          component={InputContainer}
        />
        <Field
          className="m-l-5"
          name="Email"
          label={intl.get('contacts.popup.email')}
          component={InputContainer}
        />
      </div>
      <div className="d-f jс-fs ai-fs">
        <Field
          className="p-r-5 w-50"
          name="Phone"
          label={intl.get('contacts.popup.phone')}
          component={InputContainer}
        />
      </div>
    </div>
  );
}

function renderNotes() {
  return <Field name="Notes" component={TextareaContainer} />;
}

export function renderAgencyPopupBody(setFieldValue, values, extraData) {
  return (
    <div className="main-info">
      <div className="row">
        <div className="column title">
          {intl.get('contacts.popup.agencyInfo')}
        </div>
        <div className="column content">{renderAgencyInfo(extraData)}</div>
      </div>

      <div className="row">
        <div className="column title">{intl.get('contacts.popup.contact')}</div>
        <div className="column content">{renderContactPerson()}</div>
      </div>

      <div className="row">
        <div className="column title">{intl.get('contacts.popup.notes')}</div>
        <div className="column content">{renderNotes()}</div>
      </div>
    </div>
  );
}

export function getAgencyInitialValues(currentContact) {
  return currentContact
    ? {
        Id: currentContact.Id,
        Name: currentContact.Name,
        Notes: currentContact.Notes,
        PrimaryContact: currentContact.PrimaryContact,
        Email: currentContact.Email,
        Phone: currentContact.Phone,
        ARNumber: currentContact.ARNumber,
      }
    : {
        Id: undefined,
        Name: undefined,
        Notes: undefined,
        PrimaryContact: undefined,
        Email: undefined,
        Phone: undefined,
        ARNumber: undefined,
      };
}

export function getAgencyValidation() {
  return Yup.object().shape({
    Id: Yup.string()
      .required('required')
      .test(
        'length',
        'Must be 10 characters or less',
        (value) => value && value.trim().length <= 10,
      )
      .test(
        'englishLetters',
        "Can enter only latin letters, numbers and '-'",
        (value) => englishLetterRegex.test(value),
      ),
    Name: Yup.string()
      .required('required')
      .test(
        'length',
        'Must be 60 characters or less',
        (value) => value && value.trim().length <= 60,
      )
      .test(
        'englishLetters',
        'Can enter only latin letters and numbers',
        (value) => !englishLettesAndSymbolsRegex.test(value),
      ),
    Email: Yup.string()
      .required('required')
      .matches(emailRegex, intl.get('emailValidator')),
    PrimaryContact: Yup.string()
      .matches(
        englishLettesAndSymbolsRegex,
        'Can enter only latin letters and numbers',
      )
      .test(
        'length',
        'Must be 50 characters or less',
        (value) => value && value.trim().length <= 50,
      )
      .required('required'),
    ARNumber: Yup.string()
      .matches(
        englishLetterRegex,
        "Can enter only latin letters, numbers and '-'",
      )
      .test(
        'length',
        'Must be 36 characters or less',
        (value) => value && value.trim().length <= 36,
      ),
    Phone: Yup.string()
      .required('required')
      .matches(/^\d+$/, intl.get('checkout.phoneNumberError')),
  });
}
