import React, { Component } from 'react';
import intl from 'react-intl-universal';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { InputContainer } from '../../../FormContainers';
import { CheckoutStore } from '../../../../stores/checkout.store';
import { withStore } from '../../../../hocs';

@withStore(({ rootStore }) => ({
  checkoutStore: rootStore.checkoutStore,
}))
export default class ReferenceNumber extends Component<{
  checkoutStore?: CheckoutStore;
}> {
  state = {
    initialValues: {
      referenceNumber: '',
    },
  };

  UNSAFE_componentWillMount() {
    const {
      checkoutStore: { referenceNumber },
    } = this.props;

    referenceNumber &&
      this.setState({
        initialValues: {
          referenceNumber,
        },
      });
  }

  handleReferenceNumberChange = ({ referenceNumber }) => {
    const { checkoutStore } = this.props;

    checkoutStore.changeReferenceNumber(referenceNumber);
  };

  render() {
    const { initialValues } = this.state;
    const questionTooltip = (
      <Tooltip
        placement="topLeft"
        title={intl.get('questionMarks.checkout.referenceNumber')}
        trigger="hover"
        overlayClassName="checkout-tooltip"
      >
        <QuestionCircleOutlined />
      </Tooltip>
    );

    return (
      <div className="reference-number-section">
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            referenceNumber: Yup.string(),
          })}
          validateOnChange
          onSubmit={() => undefined}
        >
          {({ values }) => (
            <Form>
              <>{this.handleReferenceNumberChange(values)}</>

              <Field
                name="referenceNumber"
                component={InputContainer}
                label={intl.get('labels.referenceNumber')}
                placeholder={`(${intl.get('labels.optional')})`}
                className="reference-number"
                tooltip={questionTooltip}
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
