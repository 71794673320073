import { MessageModel } from '../../../models/global-messages/message.model';

import { rootStore } from '../../../provider';

export function addGlobalMessage(message: MessageModel) {
  rootStore.globalMessagesStore.addMessage(message);
}

export function addGlobalSpinner() {
  rootStore.globalMessagesStore.addGlobalSpinner();
}

export function removeGlobalSpinner() {
  rootStore.globalMessagesStore.removeGlobalSpinner();
}
