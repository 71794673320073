import Axios, { AxiosRequestConfig } from 'axios';
import { searchApiKey } from '../utils/constants';
import { LocationTypesEnum } from '../models/enums/locationTypes.enum';
import { SiteSettingsModel } from '../models/site-settings/siteSettings.model';
import { TourCardModel } from '../models/marketplace/tourCard.model';

enum RequestTypeEnum {
  Products,
  Suggestions,
}

export class MarketplaceApi {
  defaultServiceTypes = "ServiceType eq 'SHOW' or ServiceType eq 'ATTAD'";

  public getSuggestions(text: string, siteSettings: SiteSettingsModel) {
    const requestConfig: AxiosRequestConfig = {
      headers: {
        'api-key': searchApiKey,
      },
      params: {
        'api-version': '2017-11-11',
      },
    };
    // chdtix only
    const filters = [`LocationType eq '${LocationTypesEnum.service}'`];
    const settingsFilters = this.getRequestFiltersBySettings(
      siteSettings,
      RequestTypeEnum.Suggestions,
    );

    filters.push(...settingsFilters);

    return Axios.post(
      'https://chd.search.windows.net/indexes/product-search-index-en/docs/suggest',
      {
        search: text,
        searchFields: 'Code,SearchCityName,TranslatedName,Name',
        suggesterName: 'product-search-suggester-en',
        top: 15,
        select:
          'LocationType,Code,TranslatedName,ThumbnailImage,StartingPrice,Language_Code,CountryName,StateName,RegionName',
        highlightPreTag: '<span class="bold">',
        highlightPostTag: '</span>',
        orderby: 'Featured desc, Ranking',
        filter: filters.join(' and '),
      },
      requestConfig,
    );
  }

  public async getProducts(options: {
    text?: string;
    topCount: number;
    skip?: number;
    count?: boolean;
    orderBy?: string[];
    filterConditions?: string[];
    siteSettings: SiteSettingsModel;
  }) {
    const {
      text,
      topCount,
      skip,
      count,
      orderBy,
      filterConditions,
      siteSettings,
    } = options;
    const params: any = {
      'api-version': '2017-11-11',
    };
    const { specificProducts } = siteSettings;
    // chdtix only
    const filters = [];
    const hasSpecificProducts = specificProducts.length;
    const settingsFilters = this.getRequestFiltersBySettings(
      siteSettings,
      RequestTypeEnum.Products,
    );

    if (filterConditions && filterConditions.length) {
      filters.push(filterConditions.join(' and '));
    }

    filters.push(...settingsFilters);

    !!text && (params.search = `${text}*`);
    !hasSpecificProducts && !!topCount && (params.$top = topCount);
    !hasSpecificProducts && !!skip && (params.$skip = skip);
    !!count && (params.$count = count);
    !hasSpecificProducts &&
      !!orderBy &&
      orderBy.length > 0 &&
      (params.$orderby = orderBy.join(', '));
    params.$filter = filters.join(' and ');

    const requestConfig: AxiosRequestConfig = {
      headers: {
        'api-key': searchApiKey,
      },
      params,
    };

    const response = await Axios.get<{ value: TourCardModel[] }>(
      'https://chd.search.windows.net/indexes/service-info-index/docs',
      requestConfig,
    );

    let products = response?.data?.value;
    const productsCount = response?.data['@odata.count'];

    if (hasSpecificProducts) {
      products = [...products]
        .sort((a, b) => {
          const specificProductIndexA = specificProducts.findIndex(
            ({ Id }) => Id === a.Product_Code,
          );
          const specificProductIndexB = specificProducts.findIndex(
            ({ Id }) => Id === b.Product_Code,
          );

          return specificProductIndexA - specificProductIndexB;
        })
        .slice(skip, skip + topCount);
    }

    return {
      products,
      productsCount,
    };
  }

  private getRequestFiltersBySettings(
    siteSettings: SiteSettingsModel,
    requestType: RequestTypeEnum,
  ): string[] {
    const {
      serviceTypes,
      specificProducts,
      productsIncluded,
      productsExcluded,
      destinations,
      defaultDestinations,
    } = siteSettings;

    const result = [];
    let filterServiceTypes = '';
    const productCode = this.getProductCode(requestType);
    const hasSpecificProducts = !!specificProducts.length;

    const destinationsString = destinations.join(', ');

    const defaultDestinationsString = defaultDestinations.join(', ');

    if (serviceTypes && !hasSpecificProducts) {
      const settingsServiceTypes = serviceTypes
        .split(',')
        .map((type) => `ServiceType eq '${type}'`)
        .join(' or ');

      filterServiceTypes = `(${settingsServiceTypes})`;
    }

    if (!filterServiceTypes && !hasSpecificProducts) {
      filterServiceTypes = `(${this.defaultServiceTypes})`;
    }

    if (productsIncluded.length) {
      const productsIncludedFilter = productsIncluded
        .map(({ Id }) => `${productCode} eq '${Id}'`)
        .join(' or ');

      filterServiceTypes = `(${filterServiceTypes} or ${productsIncludedFilter})`;
    }

    if (productsExcluded.length) {
      const productsExcludedFilter = productsExcluded
        .map(({ Id }) => `${productCode} ne '${Id}'`)
        .join(' and ');

      filterServiceTypes = `(${filterServiceTypes}) and ${productsExcludedFilter}`;
    }

    if (filterServiceTypes) {
      result.push(filterServiceTypes);
    }

    if (hasSpecificProducts) {
      const specificProductsIds = specificProducts.map(({ Id }) => Id);

      result.push(
        `search.in(${productCode}, '${specificProductsIds.join(', ')}')`,
      );
    }

    if (
      !hasSpecificProducts &&
      (destinationsString || defaultDestinationsString)
    ) {
      result.push(
        `search.in(City_Code, '${
          defaultDestinationsString || destinationsString
        }')`,
      );
    }

    return result;
  }

  private getProductCode(requestType: RequestTypeEnum) {
    switch (requestType) {
      case RequestTypeEnum.Products:
        return 'Product_Code';

      case RequestTypeEnum.Suggestions:
        return 'Code';

      default:
        return '';
    }
  }
}
