import { useLocation } from 'react-router-dom';
import { ParseOptions } from 'query-string';

import { parseQueryString } from 'utils/urlUtils';

export default function useQuery(options?: ParseOptions) {
  const { search } = useLocation();

  return parseQueryString(search, options);
}
