import React, { Component } from 'react';
import classnames from 'classnames';
import { Checkbox } from 'antd';
import { getIn } from 'formik';

const { Group: CheckboxGroup } = Checkbox;

export default class CheckboxGroupContainer extends Component<{
  field: any;
  inputRef?: any;
  form: any;
  label?: string;
  className?: string;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}> {
  handleChange = (values) => {
    const {
      field: { name },
      form: { setFieldValue },
    } = this.props;

    setFieldValue(name, values);
  };

  render() {
    const {
      field,
      form: { touched, errors },
      label,
      inputRef,
      className,
      ...rest
    } = this.props;
    const fieldError: string = getIn(errors, field.name);
    const isTouched: boolean = getIn(touched, field.name);
    const hasError: boolean = fieldError && isTouched;

    return (
      <div
        className={classnames('field', { 'has-error': hasError }, className)}
      >
        {label && <h4 className="field-label">{label}</h4>}

        <CheckboxGroup
          {...field}
          {...rest}
          onChange={this.handleChange}
          className="field-label"
          ref={inputRef}
        />
        {hasError && <div className="field-error visible">{fieldError}</div>}
      </div>
    );
  }
}
