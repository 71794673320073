import React from 'react';
import { Slider, Space, Spin } from 'antd';
import { SliderRangeProps } from 'antd/lib/slider';
import { FilterDropdownProps } from 'antd/es/table/interface';

import useTableOrdersFilterLoader from '../../hooks/useTableOrdersFilterLoader';
import TableOrdersFilterActions from './TableOrdersFilterActions';

type Props = {
  field: string;
  inputProps?: SliderRangeProps;
} & FilterDropdownProps;

function TableOrdersRangeFilter(props: Props) {
  const { field, inputProps, ...dropdownProps } = props;
  const { visible, selectedKeys, setSelectedKeys } = dropdownProps;
  const { isLoading, filterOptions } = useTableOrdersFilterLoader(
    field,
    visible,
  );

  const parseValues = (values: string[] = []) =>
    values.map((value) => parseFloat(value));

  const [min, max] = parseValues(filterOptions.map((option) => option.Key));
  const [minValue, maxValue] = parseValues(selectedKeys as string[]);

  return (
    <div className="ant-table-filter-dropdown" style={{ width: 300 }}>
      <div className="ant-table-filter-dropdown-search">
        {isLoading ? (
          <div className="text-center p-v-10">
            <Spin />
          </div>
        ) : (
          <Space>
            <span>{min}</span>
            <Slider
              {...inputProps}
              range
              min={min}
              max={max}
              value={[minValue ?? min, maxValue ?? max]}
              onChange={(fieldValue) => setSelectedKeys(fieldValue)}
              style={{ width: 200 }}
            />
            <span>{max}</span>
          </Space>
        )}
      </div>

      <div className="ant-table-filter-dropdown-btns">
        <TableOrdersFilterActions {...dropdownProps} />
      </div>
    </div>
  );
}

export default TableOrdersRangeFilter;
