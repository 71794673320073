import { cloneDeep, get, isNil } from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';

import { FormTimeModel } from 'models/inventory/formTime.model';
import {
  englishLetterRegex,
  englishLettesAndSymbolsRegex,
  getDurationHoursAndMins,
} from '../../../../../utils/formatter';

import { DayOfWeekValueEnum } from '../../../../../models/enums/dayOfWeek.enum';
import { EMPTY_VALUE } from '../../../../../utils/constants';
import { checkIsRateDatesExtended } from '../../../../../utils/inventoryHelper';
import { ProductTypeEnum } from '../../../../../models/enums/productType.enum';
import { AgeTypeEnum } from '../../../../../models/enums/ageType.enum';
import { VisibilityEnum } from '../../../../../models/enums/visibility.enum';
import { CommissionTypeEnum } from '../../../../../models/enums/commissionType.enum';
import { getDaysOfWeekList } from '../../../../../utils/rateUtils';
import {
  regularRateAgeMaxValidator,
  regularRateNetRateValidator,
} from '../../../../../utils/validation/validators';
import { RateFiltersModel } from '../../../../../models/inventory/rateFilters.model';
import { ProductFormValuesModel } from '../../../../../models/inventory/productFormValues.model';
import { TimeModel } from '../../../../../models/inventory/time.model';
import { FormMediaModel } from '../../../../../models/inventory/formMedia.model';
import { parseServiceTime } from '../../../../../utils/datesUtils';

const DEFAULT_DAYS = 0;
const DEFAULT_TYPE = 'percentage';
const DEFAULT_TIME_UNIT = 'flxTimeUnitsDays';

const initialRateFilters: RateFiltersModel = {
  agencyId: '',
  visibility: undefined,
};

export function getProductInitialValues(
  currentProduct,
  settings,
): ProductFormValuesModel {
  const defaultPolicy = {
    Type: DEFAULT_TYPE,
    Days: DEFAULT_DAYS,
    Penalty: 0,
    TimeUnits: DEFAULT_TIME_UNIT,
    NonRefundable: false,
  };

  const defaultMedia: FormMediaModel[] = [
    {
      CategoryId: '',
      LanguageId: null,
      FormattedText: undefined,
      Section: settings.MainMediaSection,
      Visibility: VisibilityEnum.Public,
      key: uuid(),
    },
    {
      CategoryId: '',
      LanguageId: null,
      FormattedText: undefined,
      Section: settings.FullDescriptionMediaSection,
      Visibility: VisibilityEnum.Public,
      key: uuid(),
    },
    {
      CategoryId: '',
      LanguageId: null,
      FormattedText: undefined,
      Section: settings.TermsMediaSection,
      Visibility: VisibilityEnum.Public,
      key: uuid(),
    },
  ];

  if (currentProduct) {
    const [hours] = getDurationHoursAndMins(currentProduct.DefaultCutoffMins);
    return {
      Id: currentProduct.Id,
      Name: currentProduct.Name,
      RestrictionCode: currentProduct.RestrictionCode,
      OperatorId: currentProduct.OperatorId,
      ServiceTypeId: currentProduct.ServiceTypeId,
      PackageTypeId: currentProduct.PackageTypeId,
      DestinationId: currentProduct.DestinationId,
      CutoffHours: hours,
      MinDuration: currentProduct.MinDuration,
      MaxDuration: currentProduct.MaxDuration,
      DefaultTime: currentProduct.DefaultTime
        ? parseServiceTime(currentProduct.DefaultTime)
        : undefined,
      Inactive: currentProduct.Inactive,
      StateId: currentProduct.StateId,
      CountryId: currentProduct.CountryId,
      City: currentProduct.City,
      Street: currentProduct.Street,
      Zip: currentProduct.Zip,
      Latitude: currentProduct.Latitude,
      Longitude: currentProduct.Longitude,
      MultipleCategories:
        get(currentProduct, 'Rates[0].CategoryId') !== currentProduct.Id,
      Media: currentProduct.Media,
      Policies:
        currentProduct.Policies && currentProduct.Policies.length
          ? currentProduct.Policies
          : [defaultPolicy],
      Rates:
        currentProduct.Rates && currentProduct.Rates.length
          ? cloneDeep(currentProduct.Rates)
          : [],
      Times:
        currentProduct.Times && currentProduct.Times.length
          ? currentProduct.Times.map((time) => ({
              ...time,
              CategoryId: time.CategoryId || EMPTY_VALUE,
            }))
          : [],
      isExistsProductId: false,
      rateFilters: initialRateFilters,
      mediaFilters: initialRateFilters,
    };
  }

  return {
    Id: undefined,
    Name: undefined,
    RestrictionCode: undefined,
    OperatorId: undefined,
    ServiceTypeId: undefined,
    PackageTypeId: undefined,
    DestinationId: undefined,
    CutoffHours: 0,
    MinDuration: undefined,
    MaxDuration: undefined,
    DefaultTime: undefined,
    StateId: undefined,
    CountryId: undefined,
    City: undefined,
    Street: undefined,
    Zip: undefined,
    Latitude: undefined,
    Longitude: undefined,
    Inactive: false,
    MultipleCategories: true,
    Rates: [],
    Times: [],
    Media: defaultMedia,
    Policies: [defaultPolicy],
    isExistsProductId: false,
    rateFilters: initialRateFilters,
    mediaFilters: initialRateFilters,
  };
}

export function getProductValidation(
  initialValues,
  productDetailsType: ProductTypeEnum,
) {
  return Yup.object().shape({
    Id: Yup.string()
      .required('required')
      .test(
        'englishLetters',
        "Can enter only latin letters, numbers and '-'",
        (value) => englishLetterRegex.test(value),
      )
      .test(
        'length',
        'Must be 32 characters or less',
        (value) => value && value.trim().length <= 32,
      )
      .when('isExistsProductId', {
        is: (isExistsProductId) => isExistsProductId,
        then: Yup.string().test(
          'isExistsProductId',
          'Code already exists',
          () => false,
        ),
      }),
    Name: Yup.string()
      .required('required')
      .test(
        'length',
        'Must be 60 characters or less',
        (value) => value && value.trim().length <= 60,
      )
      .test(
        'englishLetters',
        'Can enter only latin letters and numbers',
        (value) => !englishLettesAndSymbolsRegex.test(value),
      ),
    ServiceTypeId:
      productDetailsType === ProductTypeEnum.Service
        ? Yup.string().required('required')
        : Yup.string(),
    PackageTypeId:
      productDetailsType === ProductTypeEnum.Package
        ? Yup.string().required('required')
        : Yup.string(),
    DestinationId: Yup.string().required('required'),
    MinDuration: Yup.number().nullable(),
    MaxDuration: Yup.number().nullable(),
    DefaultTime: Yup.string().nullable(),
    StateId: Yup.string(),
    CountryId: Yup.string(),
    City: Yup.string(),
    Street: Yup.string(),
    Zip: Yup.number(),
    Latitude: Yup.number().nullable(),
    Longitude: Yup.number().nullable(),
    CutoffHours: Yup.number(),
    Times: Yup.array().of(
      Yup.object().shape({
        Id: Yup.string(),
        RecursEvery: Yup.number(),
        DaysOfWeek: Yup.array().of(Yup.string()),
        CategoryId: Yup.string(),
        Time: Yup.string(),
        EndTime: Yup.string(),
      }),
    ),
    Rates: Yup.array().when('MultipleCategories', (MultipleCategories) => {
      if (!MultipleCategories) {
        return Yup.array().of(
          Yup.object().shape({
            CategoryId: Yup.string().required('required'),
            InventoryDate: Yup.string().required('required'),
            ServiceTime: Yup.string(),
            Visibility: Yup.number().required('required'),
            AgencyId: Yup.string()
              .nullable()
              .when('Visibility', {
                is: (visibility) => visibility === VisibilityEnum.Negotiated,
                then: Yup.string().required('required'),
              }),
            CommissionType: Yup.string(),
            CommissionPct: Yup.number().when(
              ['CommissionType', 'position', '$values'],
              {
                is: (commissionType, position, values) =>
                  values.Rates[position].Visibility !==
                    VisibilityEnum.Private &&
                  commissionType === CommissionTypeEnum.Percentage,
                then: Yup.number().required('required'),
              },
            ),
            CommissionFlat: Yup.number().when(
              ['CommissionType', 'position', '$values'],
              {
                is: (commissionType, position, values) =>
                  values.Rates[position].Visibility !==
                    VisibilityEnum.Private &&
                  commissionType === CommissionTypeEnum.Flat,
                then: Yup.number().required('required'),
              },
            ),
            DaysOfWeek: Yup.array().of(Yup.string()),
            IsExistsCategory: Yup.boolean().required('required'),
            isExistRate: Yup.boolean().required('required'),
            isCategoryChanged: Yup.boolean().required('required'),
            DefaultAvailability: Yup.number()
              .test('defaultAvailability', 'required', function (value) {
                const datesFieldName = `Rates.${this.parent.position}.InventoryDate`;

                const [, prevEndDate] =
                  get(initialValues, datesFieldName) || [];

                const [, currentEndDate] = get(this.parent, 'InventoryDate', [])
                  .split(',')
                  .map((date) => moment(date));

                return (
                  !checkIsRateDatesExtended(prevEndDate, currentEndDate) ||
                  !isNil(value)
                );
              })
              .when(['isExistRate', 'isCategoryChanged'], {
                is: (isExistRate, isCategoryChanged) =>
                  !isExistRate || isCategoryChanged,
                then: Yup.number()
                  .integer('must be an integer')
                  .required('required'),
                otherwise: Yup.number().integer('must be an integer'),
              }),
            StopSell: Yup.boolean(),
            RegularRates: Yup.array().of(
              Yup.object().shape({
                Type: Yup.string().required('required'),
                AgeMin: Yup.number()
                  .nullable()
                  .when('Type', {
                    is: (Type) => Type === AgeTypeEnum.Child,
                    then: Yup.number()

                      .min(0)
                      .integer('must be an integer')
                      .required('required'),
                  }),
                AgeMax: Yup.number()
                  .nullable()
                  .test(regularRateAgeMaxValidator('required'))
                  .positive('must be a positive number')
                  .integer('must be an integer'),
                MaxOccupancy: Yup.number()
                  .nullable()
                  .when('Type', {
                    is: (type) =>
                      type === AgeTypeEnum.Group || type === AgeTypeEnum.Adult,
                    then: Yup.number()
                      .min(0)
                      .integer('must be an integer')
                      .required('required'),
                  }),
                Cost: Yup.string().nullable().required('required'),
                CostBeforeTax: Yup.string().nullable(),
                Rate: Yup.string()
                  .nullable()
                  .test(regularRateNetRateValidator('required')),
                Retail: Yup.string().nullable().required('required'),
              }),
            ),
          }),
        );
      }
      return Yup.array().of(
        Yup.object().shape({
          CategoryId: Yup.string().required('required'),
          CategoryName: Yup.string().required('required'),
          Visibility: Yup.number().required('required'),
          CommissionType: Yup.string(),
          CommissionPct: Yup.number().when(
            ['CommissionType', 'position', '$values'],
            {
              is: (commissionType, position, values) =>
                values.Rates[position].Visibility !== VisibilityEnum.Private &&
                commissionType === CommissionTypeEnum.Percentage,
              then: Yup.number().required('required'),
            },
          ),
          CommissionFlat: Yup.number().when(
            ['CommissionType', 'position', '$values'],
            {
              is: (commissionType, position, values) =>
                values.Rates[position].Visibility !== VisibilityEnum.Private &&
                commissionType === CommissionTypeEnum.Flat,
              then: Yup.number().required('required'),
            },
          ),
          DaysOfWeek: Yup.array().of(Yup.string()),
          AgencyId: Yup.string()
            .nullable()
            .when('Visibility', {
              is: (visibility) => visibility === VisibilityEnum.Negotiated,
              then: Yup.string().required('required'),
            }),
          InventoryDate: Yup.string().required('required'),
          ServiceTime: Yup.string(),
          IsExistsCategory: Yup.boolean().required('required'),
          isExistRate: Yup.boolean().required('required'),
          isCategoryChanged: Yup.boolean().required('required'),
          DefaultAvailability: Yup.number()
            .test('defaultAvailability', 'required', function (value) {
              const datesFieldName = `Rates.${this.parent.position}.InventoryDate`;

              const [, prevEndDate] = get(initialValues, datesFieldName) || [];

              const [, currentEndDate] = (this.parent?.InventoryDate || '')
                .split(',')
                .map((date) => moment(date));

              return (
                !checkIsRateDatesExtended(prevEndDate, currentEndDate) ||
                !isNil(value)
              );
            })
            .when(['isExistRate', 'isCategoryChanged'], {
              is: (isExistRate, isCategoryChanged) =>
                !isExistRate || isCategoryChanged,
              then: Yup.number()
                .integer('must be an integer')
                .required('required'),
              otherwise: Yup.number().integer('must be an integer'),
            }),
          StopSell: Yup.boolean(),
          RegularRates: Yup.array().of(
            Yup.object().shape({
              Type: Yup.string().required('required'),
              AgeMin: Yup.number()
                .nullable()
                .when('Type', {
                  is: (Type) => Type === AgeTypeEnum.Child,
                  then: Yup.number()
                    .min(0)
                    .integer('must be an integer')
                    .required('required'),
                }),
              AgeMax: Yup.number()
                .nullable()
                .test(regularRateAgeMaxValidator('required'))
                .positive('must be a positive number')
                .integer('must be an integer'),
              MaxOccupancy: Yup.number()
                .nullable()
                .when('Type', {
                  is: (type) =>
                    type === AgeTypeEnum.Group || type === AgeTypeEnum.Adult,
                  then: Yup.number()
                    .min(0)
                    .integer('must be an integer')
                    .required('required'),
                }),
              Cost: Yup.string().nullable().required('required'),
              CostBeforeTax: Yup.string().nullable(),
              Rate: Yup.string()
                .nullable()
                .test(regularRateNetRateValidator('required')),
              Retail: Yup.string().nullable().required('required'),
            }),
          ),
        }),
      );
    }),
    Media: Yup.array().of(
      Yup.object()
        .shape({
          CategoryId: Yup.string(),
          Section: Yup.string().required('required'),
          FormattedText: Yup.string(),
          Visibility: Yup.number().nullable().required('required'),
          LanguageId: Yup.string().nullable().required('required'),
          AgencyId: Yup.string()
            .nullable()
            .when('Visibility', {
              is: (visibility) => visibility === VisibilityEnum.Negotiated,
              then: Yup.string().required('required'),
            }),
        })
        .uniqueInArray(
          'Section',
          ['CategoryId', 'Visibility', 'LanguageId'],
          'You have entered information more than once for the same option, type, visibility, and language.  Please combine the duplicate information into one information block',
        ),
    ),
  });
}

export const getInitialTimes = (times: TimeModel[]): FormTimeModel[] =>
  times.map((time, index) => ({
    isExistsTime: true,
    position: index,
    Id: time.Id,
    Time: time.Time ?? undefined,
    EndTime: time.EndTime ?? undefined,
    RecursEvery: time.RecursEvery || undefined,
    DaysOfWeek: getDaysOfWeekList(time.DaysOfWeek as string),
    CategoryId: time.CategoryId || EMPTY_VALUE,
    CreateAvailability: false,
    ServiceStartEnd:
      time.ServiceStart && time.ServiceEnd
        ? [
            moment(time.ServiceStart, 'YYYY-MM-DD'),
            moment(time.ServiceEnd, 'YYYY-MM-DD'),
          ]
        : undefined,
    timeData: time,
  }));

export const mapDaysOfWeekValuesToKeys = (daysOfWeek: number[]) =>
  daysOfWeek.map((dayOfWeek) => {
    const [key] = Object.entries(DayOfWeekValueEnum).find(
      ([, value]) => dayOfWeek === value,
    );

    return key;
  });
