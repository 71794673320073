import React, { PureComponent } from 'react';
import { Popover } from 'antd';
import intl from 'react-intl-universal';

import { moneyFormat } from '../../../../utils/formatter';
import { SeatModel } from './pricingOptions.model';

type Props = {
  popoverInfo: {
    metaData: SeatModel;
    offsetX: number;
    offsetY: number;
  };
  isPopoverVisible: boolean;
  getPopupContainer: (triggerNode: HTMLElement) => HTMLElement;
};

export default class SeatMapPopover extends PureComponent<Props> {
  renderPopoverContent = () => {
    const {
      popoverInfo: { metaData },
    } = this.props;

    if (!metaData) return null;

    const { section, row, seat, categoryName, price } = metaData;

    return (
      <table className="seat-popover-table">
        <thead>
          <tr>
            <td>{intl.get('tourDetails.seatMap.section')}</td>
            <td>{intl.get('tourDetails.seatMap.row')}</td>
            <td>{intl.get('tourDetails.seatMap.seat')}</td>
            <td>{intl.get('tourDetails.seatMap.admit')}</td>
            <td>{intl.get('tourDetails.seatMap.price')}</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{section}</td>
            <td>{row}</td>
            <td>{seat}</td>
            <td>{categoryName}</td>
            <td>{moneyFormat(price)}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  render() {
    const { popoverInfo, isPopoverVisible, getPopupContainer } = this.props;

    if (!popoverInfo) return null;

    const { offsetX, offsetY } = popoverInfo;

    return (
      <Popover
        mouseEnterDelay={0}
        visible={isPopoverVisible}
        content={this.renderPopoverContent()}
        getPopupContainer={getPopupContainer}
      >
        <div
          className="seat-popover-trigger"
          style={{ top: `${offsetY}px`, left: `${offsetX}px` }}
        />
      </Popover>
    );
  }
}
