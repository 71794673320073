import React from 'react';
import { Select } from 'antd';
import intl from 'react-intl-universal';

import SelectContainer, { SelectContainerProps } from './select.container';
import { CommissionTypeEnum } from '../../models/enums/commissionType.enum';

type Props = SelectContainerProps;

function CommissionTypeContainer(props: Props) {
  const policyTypes = [
    { Id: CommissionTypeEnum.Percentage, Name: '%' },
    {
      Id: CommissionTypeEnum.Flat,
      Name: intl.get('inventory.products.popup.flatRate'),
    },
  ];

  return (
    <SelectContainer {...props}>
      {policyTypes.map((policy) => (
        <Select.Option key={policy.Id} value={policy.Id}>
          {policy.Name}
        </Select.Option>
      ))}
    </SelectContainer>
  );
}

export default CommissionTypeContainer;
