import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { HelpInstructionsSettingModel } from 'models/site-settings/helpInstructionsSetting.model';
import { BaseApi } from './base.api';
import { MediaSectionModel } from '../models/media/mediaSection.model';
import { LanguageModel } from '../models/language.model';
import { SettingsModel } from '../models/site-settings/settings.model';

export class CommonApi extends BaseApi {
  public getLanguages(): AxiosPromise<LanguageModel[]> {
    return this.axios.get('/languages');
  }

  public getSettings(): AxiosPromise<SettingsModel> {
    return this.axios.get('/settings');
  }

  public getHelpInstructions(): AxiosPromise<HelpInstructionsSettingModel[]> {
    return this.axios.get('/settings/instructions');
  }

  public getSiteSettings(token?: string, baseURL?: string) {
    const config: AxiosRequestConfig = {
      headers: {
        ...(token && { Authorization: token }),
      },
      ...(baseURL && { baseURL }),
    };

    return this.axios.get('/settings/sites', config);
  }

  public getMediaSections(): AxiosPromise<{ value: MediaSectionModel[] }> {
    return this.axios.get('/odata/media/sections()');
  }

  public getLocationsStates() {
    return this.axios.get('/locations/states');
  }

  public getLocationsCountries() {
    return this.axios.get('/locations/countries');
  }

  public getLocationLatLng(query: string, params?: Record<string, string>) {
    return this.axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json`,
      {
        params,
      },
    );
  }
}
