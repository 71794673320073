import React, { ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
  isDraggable: boolean;
  className?: string;
  children?: ReactNode;
};

export function TableOrdersHeaderCell(props: Props) {
  const { isDraggable, className, children, ...restProps } = props;

  return (
    <th
      className={classNames(className, { 'is-draggable': isDraggable })}
      {...restProps}
    >
      {children}
    </th>
  );
}
