import React, { PureComponent } from 'react';
import intl from 'react-intl-universal';
import { Spin, List, Button, Row, Col, Pagination } from 'antd';
import { isEmpty } from 'lodash';
import MessageTemplateCard from './components/MessageTemplateCard';

import { MessageTemplatesStore } from '../../../stores/messageTemplates.store';
import MessageTemplateModal from './components/MessageTemplateModal';
import MessageTemplatesSearch from './components/MessageTemplatesSearch';
import { withStore } from '../../../hocs';

type Props = {
  messageTemplatesStore?: MessageTemplatesStore;
};

type State = {
  isLoading: boolean;
  isNewTemplateVisible: boolean;
};

@withStore(({ rootStore }) => ({
  messageTemplatesStore: rootStore.messageTemplatesStore,
}))
class MessageTemplates extends PureComponent<Props, State> {
  state = {
    isLoading: false,
    isNewTemplateVisible: false,
  };

  componentDidMount() {
    this.handleLoadMessageTemplates();
  }

  handleLoadMessageTemplates = async () => {
    const { messageTemplatesStore } = this.props;

    try {
      this.setState({ isLoading: true });

      await messageTemplatesStore.getNotificationTemplates();
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleNewTemplateOpen = () => {
    this.setState({ isNewTemplateVisible: true });
  };

  handleNewTemplateClose = () => {
    this.setState({ isNewTemplateVisible: false });
  };

  handleChangePagination = (page: number, pageSize: number) => {
    const { messageTemplatesStore } = this.props;

    messageTemplatesStore.changePagination(page, pageSize);

    this.handleLoadMessageTemplates();
  };

  render() {
    const { messageTemplatesStore } = this.props;

    const { isLoading, isNewTemplateVisible } = this.state;

    return (
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col>
              <h2 className="page-title">
                {intl.get('messageTemplates.pageTitle')}
              </h2>
            </Col>

            <Col>
              <Button
                className="header-tab-button"
                onClick={this.handleNewTemplateOpen}
              >
                {intl.get('buttons.newMessageTemplate')}
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <MessageTemplatesSearch onChange={this.handleLoadMessageTemplates} />
        </Col>

        {isLoading && (
          <Col span={24}>
            <Row justify="center">
              <Spin />
            </Row>
          </Col>
        )}

        {!isLoading && (
          <>
            <Col span={24}>
              <List
                dataSource={messageTemplatesStore.notificationTemplates}
                renderItem={(messageTemplate) => (
                  <MessageTemplateCard messageTemplate={messageTemplate} />
                )}
              />
            </Col>

            {!isEmpty(messageTemplatesStore.notificationTemplates) && (
              <Col span={24}>
                <Pagination
                  showSizeChanger
                  current={messageTemplatesStore.pagination.page}
                  pageSize={messageTemplatesStore.pagination.pageSize}
                  total={messageTemplatesStore.pagination.total}
                  onChange={this.handleChangePagination}
                />
              </Col>
            )}
          </>
        )}

        <MessageTemplateModal
          visible={isNewTemplateVisible}
          onClose={this.handleNewTemplateClose}
        />
      </Row>
    );
  }
}

export default MessageTemplates;
