import React, { Component } from 'react';
import { get } from 'lodash';
import intl from 'react-intl-universal';
import { Field, FormikProps } from 'formik';

import CategoryAutoCompletedContainer from './CategoryAutoCompleted.container';

import { InputContainer } from '../../../../../FormContainers';
import { ProductFormValuesModel } from '../../../../../../models/inventory/productFormValues.model';

export default class CategoriesSelect extends Component<{
  categories: any;
  ratePosition: number;
  formikProps: FormikProps<ProductFormValuesModel>;
  productCode: string;
  MultipleCategories: boolean;
  initialValues: any;
}> {
  state = {
    categoriesToRender: this.props.categories
      .map((item) => ({ label: item.Name, value: item.Id }))
      .filter((item) => item.value),
  };

  container: any = React.createRef();

  componentDidMount() {
    this.handleChangeMultipleCategories();
  }

  componentDidUpdate(prevProps) {
    const { productCode, MultipleCategories } = this.props;
    if (
      MultipleCategories !== prevProps.MultipleCategories ||
      productCode !== prevProps.productCode
    ) {
      this.handleChangeMultipleCategories();
    }
  }

  handleChangeMultipleCategories = () => {
    const {
      ratePosition,
      productCode,
      MultipleCategories,
      initialValues,
      formikProps,
    } = this.props;
    const { setFieldValue } = formikProps;
    const name = `Rates.${ratePosition}`;

    if (!MultipleCategories) {
      setFieldValue(`${name}.CategoryId`, productCode);
      setFieldValue(`${name}.IsExistsCategory`, true);
    } else {
      setFieldValue(
        `${name}.CategoryName`,
        get(initialValues, `${name}.CategoryName`),
      );
      setFieldValue(
        `${name}.CategoryId`,
        get(initialValues, `${name}.CategoryId`),
      );
      setFieldValue(
        `${name}.IsExistsCategory`,
        get(initialValues, `${name}.IsExistsCategory`),
      );
    }
  };

  render() {
    const { ratePosition, MultipleCategories } = this.props;
    const { categoriesToRender } = this.state;

    if (!MultipleCategories) return null;

    const name = `Rates.${ratePosition}`;

    return (
      <div className="d-f w-100">
        <div ref={this.container} className="w-80 p-r-10">
          <Field
            name={`Rates.${ratePosition}.CategoryName`}
            categoryName={name}
            label={intl.get('inventory.products.popup.category')}
            placeholder={intl.get('inventory.products.popup.selectCategory')}
            dataSource={categoriesToRender}
            component={CategoryAutoCompletedContainer}
          />
        </div>
        <Field
          disabled
          label={intl.get('inventory.products.popup.categoryCode')}
          name={`${name}.CategoryId`}
          className="w-20"
          component={InputContainer}
        />
      </div>
    );
  }
}
