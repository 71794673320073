import { observable, action, makeObservable } from 'mobx';
import intl from 'react-intl-universal';

import { RootStore } from './root.store';
import { UserPropertiesModel } from '../models/userProperties.model';
import { UserApi } from '../api/user.api';
import { addGlobalMessage } from '../components/SharedComponents/GlobalMessages';
import { MessageTypeEnum } from '../models/global-messages/message.model';
import {
  availableLocales,
  availableLanguages,
} from '../models/enums/language.enums';
import { FinanceInfoModel } from '../models/financeInfo.model';
import { UserModel } from '../models/account/user.model';

const locales = {
  'en-US': require('../locales/en-US.json'),
  'zh-CN': require('../locales/zh-CN.json'),
};

const languageStorageKey = 'language';

export class UserStore {
  private rootStore: RootStore;
  private apiService: UserApi;

  @observable public locale: availableLocales = availableLocales.ENG;
  @observable public language: availableLanguages = availableLanguages.ENG;
  @observable public financeInfo: FinanceInfoModel = new FinanceInfoModel();
  @observable public userProperties: UserPropertiesModel = undefined;

  // for manager
  @observable public userList: UserModel[] = [];
  @observable public isUserListLoading = false;
  // for manager

  constructor(rootStore: RootStore, apiService: UserApi) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.apiService = apiService;
  }

  @action
  public async loadLocales() {
    this.getLanguagePropertiesFromStore();

    try {
      intl.init({
        currentLocale: this.locale,
        locales,
      });

      return this.locale;
    } catch (error) {
      return null;
    }
  }

  @action
  public async getFinanceInfo(agencyId: string) {
    try {
      this.financeInfo = new FinanceInfoModel();
      const { data: response } = await this.apiService.getFinanceInfo(agencyId);

      this.financeInfo = observable(response);
      return Promise.resolve();
    } catch (error) {
      this.financeInfo = new FinanceInfoModel();

      return Promise.reject(error);
    }
  }

  @action
  public getLanguagePropertiesFromStore() {
    const storageObject: string = localStorage.getItem(languageStorageKey);

    if (!storageObject) {
      return;
    }

    const { locale, language } = JSON.parse(storageObject);

    this.locale = locale;
    this.language = language;
  }

  @action
  public setLanguagePropertiesToStore(locale: string, language: string) {
    localStorage.setItem(
      languageStorageKey,
      JSON.stringify({
        locale,
        language,
      }),
    );
  }

  @action
  public changeLanguage(language: string) {
    const locale: string = availableLocales[language];

    this.setLanguagePropertiesToStore(locale, language);
    document.location.reload();
  }

  @action
  public async getUserProperties() {
    try {
      const { data: response } = await this.apiService.getUserProperties();

      if (!response) {
        this.rootStore.authStore.logOut();

        return;
      }

      this.userProperties = response;
    } catch (error) {
      this.rootStore.authStore.logOut();
    }
  }

  @action
  public async updateUserProperties(data) {
    try {
      await this.apiService.updateUserProperties(data);
      const { data: response } = await this.apiService.getUserList();

      if (response) {
        this.userList = observable(response);
      }

      addGlobalMessage({
        message: intl.get('profile.roleManagement.updateSuccess'),
        type: MessageTypeEnum.success,
      });

      return Promise.resolve(response);
    } catch (error) {
      addGlobalMessage({
        message: intl.get('profile.roleManagement.updateFail'),
        type: MessageTypeEnum.error,
      });

      return Promise.reject(error);
    }
  }

  @action
  public async getUserList() {
    try {
      this.isUserListLoading = true;
      const { data } = await this.apiService.getUserList();

      this.userList = data;

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    } finally {
      this.isUserListLoading = false;
    }
  }

  @action
  public async registerUser(data) {
    try {
      await this.apiService.registerUser(data);

      this.getUserList();

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  @action
  public async changeUserStatus(username, active) {
    try {
      await this.apiService.changeUserStatus(username, active);

      this.getUserList();

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  @action
  public async sendEmailVoucher(tripId, body) {
    try {
      await this.apiService.sendEmailVoucher(tripId, body);

      return Promise.resolve();
    } catch (error) {
      addGlobalMessage({
        message: intl.get('helpAndSupport.failedToSendEmail'),
        type: MessageTypeEnum.error,
      });

      return Promise.reject();
    }
  }

  @action
  public async sendEmailInvoice(tripId, body) {
    try {
      await this.apiService.sendEmailInvoice(tripId, body);

      return Promise.resolve();
    } catch (error) {
      addGlobalMessage({
        message: intl.get('helpAndSupport.failedToSendEmail'),
        type: MessageTypeEnum.error,
      });

      return Promise.reject();
    }
  }

  public async sendEmailReceipt(tripId, body) {
    try {
      await this.apiService.sendEmailReceipt(tripId, body);

      return Promise.resolve();
    } catch (error) {
      addGlobalMessage({
        message: intl.get('helpAndSupport.failedToSendEmail'),
        type: MessageTypeEnum.error,
      });

      return Promise.reject();
    }
  }

  @action
  public async sendEmailCancellation(tripId: number, body: string[]) {
    try {
      await this.apiService.sendEmailCancellation(tripId, body);
    } catch (error) {
      addGlobalMessage({
        message: intl.get('helpAndSupport.failedToSendEmail'),
        type: MessageTypeEnum.error,
      });
    }
  }

  public async sendUserEmailCancellation(tripId: number) {
    const userEmail = this.userProperties?.Email;

    if (!userEmail) return;

    await this.sendEmailCancellation(tripId, [userEmail]);
  }

  @action
  public clearStore() {
    this.financeInfo = new FinanceInfoModel();
    this.userProperties = undefined;
  }
}
