import { BaseApi } from './base.api';

export class AuthApi extends BaseApi {
  public login(userName, password) {
    return this.axios.post('/account/Login', {
      userName,
      password,
    });
  }

  public forgotPassword(data) {
    return this.axios.post('/account/ForgotPassword', data);
  }

  public resetPassword(data) {
    return this.axios.post('/account/ResetPassword', data);
  }
}
