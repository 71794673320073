import { OrderCommunicationType } from '../../../../models/enums/orderCommunicationType.enum';
import { OrderCommunicationMethodEnum } from '../../../../models/enums/orderCommunicationMethod.enum';
import { BulkCommunicationFormValuesModel } from '../../../../models/bulkCommunicationFormValues.model';
import { NotificationMethodEnum } from '../../../../models/enums/notificationMethod.enum';

export const isEmailFieldVisible = (
  values: BulkCommunicationFormValuesModel,
  primaryMethod: string,
) => {
  const { selectedOrders, messageType, communicationMethod } = values;

  const isCustomMessage = messageType === OrderCommunicationType.CustomMessage;
  const isEmailFirst = primaryMethod === NotificationMethodEnum.Email;

  switch (communicationMethod) {
    case OrderCommunicationMethodEnum.Email: {
      return isCustomMessage;
    }

    case OrderCommunicationMethodEnum.SMS: {
      return false;
    }

    case OrderCommunicationMethodEnum.FirstAvailable: {
      return (
        isEmailFirst &&
        isCustomMessage &&
        selectedOrders.some((selectedOrder) => selectedOrder.PrimaryEmail)
      );
    }
  }
};

export const isMessageTextVisible = (
  values: BulkCommunicationFormValuesModel,
  primaryMethod: string,
) => {
  const { selectedOrders, messageType, communicationMethod } = values;

  const isCustomMessage = messageType === OrderCommunicationType.CustomMessage;
  const isSmsFirst = primaryMethod === NotificationMethodEnum.SMS;

  switch (communicationMethod) {
    case OrderCommunicationMethodEnum.Email: {
      return false;
    }

    case OrderCommunicationMethodEnum.SMS: {
      return true;
    }

    case OrderCommunicationMethodEnum.FirstAvailable: {
      if (!isCustomMessage && isSmsFirst) return true;

      return (
        isSmsFirst &&
        selectedOrders.some((selectedOrder) => selectedOrder.PrimaryPhone)
      );
    }
  }
};
