import React from 'react';
import intl from 'react-intl-universal';
import { Field, useFormikContext } from 'formik';
import { RadioChangeEvent } from 'antd';

import { RadioGroupContainer } from '../../../FormContainers';
import { NotificationRptTypeModelEnum } from '../../../../models/enums/notificationRptTypeModelEnum';
import { MessageTemplateFormModel } from '../../../../models/messageTemplateForm.model';
import {
  EMAIL_TEMPLATE_PREVIEW_ORDER,
  EMAIL_TEMPLATE_PREVIEW_ORDER_ITEM,
} from '../../../../fixtures';
import { safeJSONStringify } from '../../../../utils/jsonUtils';

function MessageTemplateModelField(props) {
  const { setFieldValue } = useFormikContext<MessageTemplateFormModel>();

  const getOptionLabel = (type: NotificationRptTypeModelEnum) =>
    ({
      [NotificationRptTypeModelEnum.AllProducts]: intl.get(
        'messageTemplates.modelType.allProducts',
      ),
      [NotificationRptTypeModelEnum.OneProduct]: intl.get(
        'messageTemplates.modelType.oneProduct',
      ),
    }[type] ?? '');

  const options = [
    NotificationRptTypeModelEnum.AllProducts,
    NotificationRptTypeModelEnum.OneProduct,
  ].map((value) => ({
    value,
    label: getOptionLabel(value),
  }));

  const getPreviewModel = (type: NotificationRptTypeModelEnum) => {
    const previewModelString =
      {
        [NotificationRptTypeModelEnum.AllProducts]:
          EMAIL_TEMPLATE_PREVIEW_ORDER,
        [NotificationRptTypeModelEnum.OneProduct]:
          EMAIL_TEMPLATE_PREVIEW_ORDER_ITEM,
      }[type] ?? '';

    return safeJSONStringify(previewModelString, null, ' ');
  };

  const onChange = (e: RadioChangeEvent) => {
    setFieldValue('previewModel', getPreviewModel(e.target.value));
  };

  return (
    <Field
      name="modelType"
      label={intl.get('messageTemplates.modelTypeLabel')}
      options={options}
      component={RadioGroupContainer}
      onChange={onChange}
      {...props}
    />
  );
}

export default MessageTemplateModelField;
