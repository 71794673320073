import React, { Component } from 'react';
import intl from 'react-intl-universal';

import { ServiceModel } from '../../../../models/service.model';
import { OrdersStore } from '../../../../stores/orders.store';
import { UserStore } from '../../../../stores/user.store';
import { getCancellationInfoItem } from '../utils/cancellationUtils';
import { formatCardLastDigits, moneyFormat } from '../../../../utils/formatter';
import OrderCancellationForm from './OrderCancellationForm';
import { OrderCancellationFormModel } from '../../../../models/orders/orderCancellationForm.model';
import { withStore } from '../../../../hocs';
import { AvailabilityLevelEnum } from '../../../../models/enums/availabilityLevel.enum';

@withStore(({ rootStore }) => ({
  userStore: rootStore.userStore,
  ordersStore: rootStore.ordersStore,
}))
export default class OrderEntireCancellation extends Component<{
  orders: ServiceModel[];
  closePopup: any;
  afterCancelTrip: Function;
  tripId: number;
  ordersStore?: OrdersStore;
  userStore?: UserStore;
}> {
  handleCancellationSubmit = (values: OrderCancellationFormModel) => {
    const {
      ordersStore,
      ordersStore: { orderDetails },
      closePopup,
      afterCancelTrip,
    } = this.props;

    closePopup();
    return ordersStore.cancelOrder(orderDetails, values).then(() => {
      afterCancelTrip();

      return Promise.resolve();
    });
  };

  handleCancellationAndSendEmail = async (
    values: OrderCancellationFormModel,
  ) => {
    const { userStore, tripId } = this.props;

    await this.handleCancellationSubmit(values);
    await userStore.sendUserEmailCancellation(tripId);
  };

  get cancellationInfo() {
    const { orders, ordersStore } = this.props;

    return orders.map((order) =>
      getCancellationInfoItem(order, ordersStore.orderDetails.PaymentAmountDue),
    );
  }

  get refund() {
    return this.cancellationInfo.reduce((acc, { refund }) => acc + refund, 0);
  }

  get isPartlyRefunded() {
    return this.cancellationInfo.every(
      ({ isPartlyRefunded }) => isPartlyRefunded,
    );
  }

  get isFreeCancellation() {
    return this.cancellationInfo.every(
      ({ isFreeCancellation }) => isFreeCancellation,
    );
  }

  renderCancellationMessage = () => {
    const {
      ordersStore: { orderDetails, orderDetailsFinance },
    } = this.props;
    const { LastDigits } = orderDetailsFinance[0] || {};

    if (this.isPartlyRefunded) {
      const descriptionTranslationKey =
        orderDetails.PaymentInfoRequiredForRefund
          ? 'orders.popup.orderPartlyRefundedDescription'
          : 'orders.popup.orderPartlyRefundedCardDescription';

      return (
        <>
          <p>
            {intl.get('orders.popup.orderPartlyRefundedTitle', {
              tripId: orderDetails.TripId,
            })}
          </p>

          {this.renderProductsCancellationMessage()}

          <div>
            {intl.get(descriptionTranslationKey, {
              refund: moneyFormat(this.refund),
              cardDigits: formatCardLastDigits(LastDigits),
            })}
          </div>
        </>
      );
    }

    if (this.isFreeCancellation) {
      const translationKey = orderDetails.PaymentInfoRequiredForRefund
        ? 'orders.popup.cancelRefundableOrder'
        : 'orders.popup.cancelRefundableCardOrder';

      return (
        <span>
          {intl.get(translationKey, {
            tripId: orderDetails.TripId,
            refund: moneyFormat(this.refund),
            cardDigits: formatCardLastDigits(LastDigits),
          })}
        </span>
      );
    }

    return (
      <span>
        {intl.get('orders.popup.cancelNonRefundableOrder', {
          tripId: orderDetails.TripId,
        })}
      </span>
    );
  };

  renderProductsCancellationMessage = () => {
    const { orders, ordersStore } = this.props;

    return orders
      .filter(
        (order) => order.AvailabilityLevel !== AvailabilityLevelEnum.Cancelled,
      )
      .map((order) => {
        const cancellationInfoItem = getCancellationInfoItem(
          order,
          ordersStore.orderDetails.PaymentAmountDue,
        );

        return (
          <p>
            <strong>{order.Name}: </strong>

            {cancellationInfoItem.isFreeCancellation ? (
              <span>
                {`${intl.get(
                  'orders.popup.nonRefundable.cancellationPenalty',
                )}: ${moneyFormat(cancellationInfoItem.penalty)}`}
              </span>
            ) : (
              <span>{intl.get('orders.popup.nonRefundable')}</span>
            )}
          </p>
        );
      });
  };

  render() {
    const {
      closePopup,
      ordersStore: { orderDetails },
      userStore: { financeInfo },
    } = this.props;

    return (
      <div className="cancel-order-tooltip">
        <div className="cancel-header">
          <span>{intl.get('orders.popup.confirmCancellation')}</span>
        </div>
        <div>
          <div className="cancellation-order-text">
            {this.renderCancellationMessage()}
          </div>

          <div className="p-h-30">
            <OrderCancellationForm
              isPenaltyVisible={!this.isFreeCancellation}
              isCardVisible={orderDetails.PaymentInfoRequiredForRefund}
              financeInfo={financeInfo}
              onCancel={closePopup}
              onSubmit={this.handleCancellationAndSendEmail}
            />
          </div>
        </div>
      </div>
    );
  }
}
