import React, { Component } from 'react';
import intl from 'react-intl-universal';

import { moneyFormat } from '../../../../utils/formatter';
import { CheckoutStore } from '../../../../stores/checkout.store';
import { UserStore } from '../../../../stores/user.store';
import { withStore } from '../../../../hocs';

@withStore(({ rootStore }) => ({
  checkoutStore: rootStore.checkoutStore,
  userStore: rootStore.userStore,
}))
export default class TotalPayment extends Component<{
  checkoutStore?: CheckoutStore;
  userStore?: UserStore;
}> {
  getPaymentFee = (total) => {
    const {
      checkoutStore: {
        paymentMethod: { paymentMethodType },
      },
      userStore: {
        financeInfo: { PaymentMethods },
      },
    } = this.props;

    if (!PaymentMethods) {
      return;
    }

    const paymentMethod = PaymentMethods.find(
      (method) => method.Method === paymentMethodType,
    );

    if (!paymentMethod) {
      return;
    }

    return {
      title: intl.get(`checkout.paymentMethodsEnum.${paymentMethod.Method}`),
      fee: (paymentMethod.MarkupPct * total) / 100,
    };
  };

  render() {
    const {
      checkoutStore: { cardsToCheckout },
    } = this.props;
    const total = cardsToCheckout.reduce(
      (sum, current) => sum + current.product.Price,
      0,
    );
    const paymentFee = this.getPaymentFee(total);
    const totalAndFee = !paymentFee ? total : total + paymentFee.fee;

    return (
      <div className="payment-total-and-fee">
        {/* {paymentFee && (
                    <div className="payment-fee">
                        <div>
                            <span className="fee-title">{paymentFee.title}</span>
                        </div>
                        <div>
                            <span className="fee">{moneyFormat(paymentFee.fee)}</span>
                        </div>
                    </div>
                )} */}
        <div className="footer">
          <div className="title">{intl.get('checkout.total')}</div>
          <div className="count">{moneyFormat(totalAndFee)}</div>
          <div className="currency">USD</div>
        </div>
      </div>
    );
  }
}
