import React, { Component } from 'react';
import { Button, Collapse, Modal, Space } from 'antd';
import intl from 'react-intl-universal';
import { get, isNil, set } from 'lodash';
import { ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { FormikProps, setNestedObjectValues } from 'formik';

import { ErrorSectionMessageContainer } from 'components/FormContainers';
import { InventoryStore } from '../../../../../../stores/inventory.store';
import { getCorrectDate } from '../../../../../../utils/formatter';
import { FormRateModel } from '../../../../../../models/inventory/formRate.model';
import { getInitialRate } from '../../../../../../utils/rateUtils';
import { VisibilityEnum } from '../../../../../../models/enums/visibility.enum';
import { withStore } from '../../../../../../hocs';
import { ProductFormValuesModel } from '../../../../../../models/inventory/productFormValues.model';
import ProductRateAvailabilityFilters from './ProductRateAvailabilityFilters';
import RatePanelActions from './RatePanelActions';
import RatePanel from './RatePanel';

const { Panel } = Collapse;

type Props = {
  formikProps: FormikProps<ProductFormValuesModel>;
  inventoryStore?: InventoryStore;
  initialValues?: ProductFormValuesModel;
  activeRateKey: string[];
  onToggleAll: () => void;
  onActiveRateChange: (key: string | string[]) => void;
};

@withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
}))
export default class CategoryAndRates extends Component<Props> {
  componentDidMount() {
    this.handleLoadAgencies();
  }

  handleLoadAgencies = async () => {
    const { inventoryStore } = this.props;

    await inventoryStore.getAgenciesList();
  };

  handleNewRate = () => {
    const {
      onActiveRateChange,
      formikProps: { values, setFieldValue },
      inventoryStore: { productDetailsType },
    } = this.props;

    const rates: FormRateModel[] = values.Rates.concat(
      getInitialRate(
        productDetailsType,
        values.rateFilters,
        values.Rates.length,
      ),
    );

    setFieldValue('Rates', rates);

    if (rates.length >= 1) {
      onActiveRateChange([values.Rates.length.toString()]);
    }
  };

  handleDeleteRate = (currentRate) => {
    const { inventoryStore, formikProps } = this.props;
    const { values, setFieldValue } = formikProps;
    const newRates = values.Rates.filter(
      (rate) => rate.position !== currentRate.position,
    ).map((rate, position) => ({ ...rate, position }));

    if (currentRate.isExistRate && currentRate.Id) {
      const hasAvailabilities = inventoryStore.inventories.filter(
        (inventory) => {
          const rate = this.rates.find(({ Id }) => Id === currentRate.Id);

          return rate?.CategoryId === inventory.CategoryId;
        },
      );

      if (hasAvailabilities) {
        return Modal.confirm({
          title: intl.get('inventory.products.removeRateConfirm'),
          icon: <ExclamationCircleOutlined />,
          onOk: () => {
            this.props.inventoryStore.setIdOfDeletedRate(currentRate.Id);

            setFieldValue('Rates', newRates);
          },
          okText: intl.get('buttons.yes'),
          cancelText: intl.get('buttons.no'),
        });
      }
    }

    setFieldValue('Rates', newRates);
  };

  handleRateOkClick = async (position: number) => {
    const { onActiveRateChange, formikProps, activeRateKey } = this.props;
    const { validateForm, setTouched, values, touched } = formikProps;

    const errors = await validateForm();

    if (errors.Rates?.[position]) {
      const newTouched = { ...touched };

      set(
        newTouched,
        `Rates[${position}]`,
        setNestedObjectValues(values.Rates[position], true),
      );

      setTouched(newTouched);

      return;
    }

    onActiveRateChange(
      activeRateKey.filter((key) => key !== position.toString()),
    );
  };

  getRateHeader = (rate: FormRateModel) => {
    const { CategoryId, CategoryName, ServiceTime, position } = rate;

    const serviceTime = getCorrectDate(ServiceTime);
    const time = serviceTime ? serviceTime.format('h:mm a') : '';
    const labelFields = CategoryId
      ? [CategoryId, CategoryName, time]
      : [intl.get('inventory.products.popup.rate'), position + 1];

    return (
      <Space>
        <ErrorSectionMessageContainer
          name={`Rates.${rate.position}`}
          render={() => <WarningOutlined style={{ color: 'red' }} />}
        />

        <span>{labelFields.join(' ')}</span>
      </Space>
    );
  };

  get rates() {
    const { formikProps } = this.props;

    const { agencyId, visibility } = formikProps.values.rateFilters;

    return formikProps.values.Rates.filter(
      (rate) =>
        (isNil(visibility) || visibility === rate.Visibility) &&
        (!agencyId ||
          visibility !== VisibilityEnum.Negotiated ||
          agencyId === rate.AgencyId),
    );
  }

  render() {
    const {
      initialValues,
      activeRateKey,
      formikProps,
      onToggleAll,
      onActiveRateChange,
    } = this.props;
    const { values } = formikProps;
    const isAllExpanded =
      get(activeRateKey, 'length') === get(values, 'Rates.length');
    const TOGGLE_BUTTON_NAME = isAllExpanded
      ? intl.get('inventory.products.popup.collapseAll')
      : intl.get('inventory.products.popup.expandAll');

    return (
      <div className="d-f f-d-column flx-1">
        <ProductRateAvailabilityFilters
          name="rateFilters"
          filterAgencyOptions={(option) =>
            values.Rates.some(({ AgencyId }) => AgencyId === option.value)
          }
        />

        <Button
          type="link"
          size="small"
          className="m-b-10 as-fe"
          onClick={onToggleAll}
        >
          {TOGGLE_BUTTON_NAME}
        </Button>

        <div className="d-f f-d-column flx-1">
          <Collapse
            activeKey={activeRateKey}
            defaultActiveKey={['0']}
            onChange={onActiveRateChange}
          >
            {this.rates.map((rate) => (
              <Panel
                header={this.getRateHeader(rate)}
                key={String(rate.position)}
                className="new-product-panel"
                extra={
                  <RatePanelActions
                    rate={rate}
                    onDelete={this.handleDeleteRate}
                  />
                }
              >
                <div>
                  <RatePanel
                    rate={rate}
                    initialValues={initialValues}
                    onCancel={this.handleRateOkClick}
                  />
                </div>
              </Panel>
            ))}
          </Collapse>
        </div>
        <div className="m-20">
          <div className="d-f jc-c">
            <Button type="primary" onClick={this.handleNewRate}>
              Add Rate
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
