import React from 'react';
import intl from 'react-intl-universal';
import { Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import moment from 'moment';
import { EventModel } from '../../../../../../models/inventory/event.model';

type Props = {
  event: EventModel;
};

export default function EventPopoverContent(props: Props) {
  return (
    <div className="event-popover-content">
      {props.event.options.map((option) => (
        <div key={option.CategoryId} className="popover-option">
          <div className="d-f m-b-5">
            <span className="f-w-6 f-z-16 m-r-10">{option.CategoryName}</span>

            <div className="m-l-a d-f ai-c">
              {!!option.Hold && (
                <div className="f-s-13 f-w-4 p-h-5 text-left event-label">
                  {`${intl.get('productPage.hold').toLowerCase()} ${
                    option.Hold
                  }`}
                </div>
              )}

              {option.StopSell && (
                <div className="text-left">
                  <Tooltip placement="bottom" title="Stop Sell">
                    <CloseOutlined className="stop-sell-icon stop-sell-icon_red" />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>

          {!!option.RatePlanName && (
            <div>
              <span className="f-w-5">
                {intl.get('productPage.ratePlan')}:{' '}
              </span>
              <span>{option.RatePlanName}</span>
            </div>
          )}

          {!!option.Time && (
            <div>
              <span className="f-w-5">{intl.get('productPage.time')}: </span>
              <span>{moment(option.Time).local().format('h:mm A')}</span>
            </div>
          )}

          {option.AvailableQuantity !== undefined && (
            <div>
              <span className="f-w-5">
                {intl.get('productPage.quantity')}:{' '}
              </span>
              <span>{option.AvailableQuantity}</span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
