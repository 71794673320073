import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { BaseApi } from './base.api';
import { CategoryModel } from '../models/inventory/category.model';
import { ProductTypeEnum } from '../models/enums/productType.enum';
import { ProductDetailsModel } from '../models/inventory/productDetails.model';
import { ViewCriteriaModel } from '../models/inventory/viewCriteria.model';

const { CancelToken } = axios;

export class InventoryApi extends BaseApi {
  // PRODUCTS
  public getProducts(params: AxiosRequestConfig['params']) {
    return ['/odata/servicesdata', '/odata/packagedata'].map((url) => {
      const controller = new AbortController();

      const request = this.axios.get(url, {
        params,
        signal: controller.signal,
      });

      return { request, controller };
    });
  }

  public getProduct(productId: string, productType: ProductTypeEnum) {
    let cancel;

    const baseUrl = this.getProductDataBaseUrlByType(productType);

    const request = this.axios.get(`${baseUrl}/${productId}`, {
      params: {
        $expand: 'Policies,Rates,Media($expand=Images),Times',
      },
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    });

    return { cancel, request };
  }

  public isExistsProductId(productId: string, productType: ProductTypeEnum) {
    const baseUrl = this.getProductDataBaseUrlByType(productType);

    return this.axios.get(`${baseUrl}/${productId}/exists`);
  }

  public isExistsCategoryId(categoryId: string) {
    return this.axios.get(`/odata/categories/exists?id=${categoryId}`);
  }

  public createProduct(
    product: ProductDetailsModel,
    productType: ProductTypeEnum,
  ) {
    const baseUrl = this.getProductDataBaseUrlByType(productType);

    return this.axios.post(baseUrl, product);
  }

  public editProduct(product, productType: ProductTypeEnum) {
    const baseUrl = this.getProductDataBaseUrlByType(productType);

    return this.axios.put(`${baseUrl}/${product.Id}`, product);
  }

  public getProductTypes(productType: ProductTypeEnum) {
    return this.axios.get(this.getProductTypesByType(productType));
  }

  // POLICIES
  public getPolicies(policyId) {
    return this.axios.get(`/odata/policies/${policyId}`);
  }

  public createPolicies(policies) {
    return this.axios.post('/odata/policies', policies);
  }

  public editPolicies(policies) {
    return this.axios.put(`/odata/policies/${policies.Id}`, policies);
  }

  // MEDIA
  public getMedia(mediaId) {
    return this.axios.get(`/odata/media/${mediaId}`);
  }

  public createMedia(media) {
    return this.axios.post('/odata/media/multiple', media);
  }

  public deleteMedia(ids: number[]) {
    const requests = ids.map((id) => ({
      method: 'DELETE',
      url: `/odata/Media(${id})`,
      id: String(id),
    }));

    return this.axios.delete('/odata/$batch', {
      data: {
        requests,
      },
    });
  }

  // CATEGORIES
  public getCategories(config?: AxiosRequestConfig) {
    let cancel;

    const request = this.axios.get('/odata/categories', {
      ...config,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    });

    return { cancel, request };
  }

  public createCategory(category: CategoryModel) {
    return this.axios.post('/odata/categories', {
      Id: category.Id,
      Name: category.Name,
    });
  }

  public uploadImages(images: any[]) {
    return this.axios.post('/images/upload', images);
  }

  public deleteImage(image: any) {
    return this.axios.delete('/images/delete', { data: image });
  }

  // REGULAR RATES AND RATE RULES
  public createRates(rates, productType: ProductTypeEnum) {
    const path = this.getRatesPathByType(productType);

    return this.axios.post(`/odata/${path}/multiple`, {
      entities: rates,
    });
  }

  public deleteRates(rateIds: number[], productType: ProductTypeEnum) {
    const path = this.getRatesPathByType(productType);

    const requests = rateIds.map((id) => ({
      method: 'DELETE',
      url: `${path}(${id})`,
      id: String(id),
    }));

    return this.axios.delete('/odata/$batch', {
      data: {
        requests,
      },
    });
  }

  // INVENTORIES
  public getInventories = (params: AxiosRequestConfig['params']) => {
    let cancel;

    const request = this.axios.get('/odata/inventory', {
      params,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    });

    return { cancel, request };
  };

  public deleteInventories(inventoryIds: number[]) {
    const requests = inventoryIds.map((id) => ({
      method: 'DELETE',
      url: `inventory(${id})`,
      id: String(id),
    }));

    return this.axios.delete('/odata/$batch', {
      data: {
        requests,
      },
    });
  }

  public createInventoryRange(inventoryRange) {
    return this.axios.post('/odata/inventory/build', {
      range: inventoryRange,
    });
  }

  public createOrUpdateMultipleInventories(inventories) {
    return this.axios.post('/odata/inventory/multiple', {
      entities: inventories,
    });
  }

  public updateTimes(times) {
    return this.axios.post('/odata/producttimes/multiple', {
      entities: times,
    });
  }

  public deleteTimes(timeIds: number[]) {
    const requests = timeIds.map((id) => ({
      method: 'DELETE',
      url: `productTimes(${id})`,
      id: String(id),
    }));

    return this.axios.delete('/odata/$batch', {
      data: {
        requests,
      },
    });
  }

  public getDestinations() {
    let cancel;

    const request = this.axios.get('/destinations/locations', {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    });

    return { cancel, request };
  }

  private getProductTypesByType(productType: ProductTypeEnum) {
    switch (productType) {
      case ProductTypeEnum.Package:
        return '/odata/packagetypes';

      case ProductTypeEnum.Service:
      default:
        return '/odata/servicetypes';
    }
  }

  private getProductDataBaseUrlByType(productType: ProductTypeEnum) {
    switch (productType) {
      case ProductTypeEnum.Package:
        return '/odata/packagedata';

      case ProductTypeEnum.Service:
      default:
        return '/odata/servicesdata';
    }
  }

  private getRatesPathByType(productType: ProductTypeEnum) {
    switch (productType) {
      case ProductTypeEnum.Package:
        return 'packagerates';

      case ProductTypeEnum.Service:
      default:
        return 'servicerates';
    }
  }

  public getBulkViewCriteria(): AxiosPromise<ViewCriteriaModel> {
    return this.axios.get('/inventoryfilters/getfilters');
  }

  public createOrUpdateBulkViewCriteria(viewCriteria: ViewCriteriaModel) {
    const data = JSON.stringify(JSON.stringify(viewCriteria));

    return this.axios.put('/inventoryfilters/updatefilters', data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
