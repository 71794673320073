import React from 'react';
import intl from 'react-intl-universal';
import { Field } from 'formik';
import { Select } from 'antd';
import { Element } from 'react-scroll';

import {
  CheckboxContainer,
  InputNumberContainer,
  SelectContainer,
} from '../../../../../FormContainers';
import CommissionTypeContainer from '../../../../../FormContainers/commissionType.container';
import CommissionContainer from '../../../../../FormContainers/commission.container';
import { ProductSectionKeyEnum } from '../../../../../../models/enums/productSectionKey.enum';

const { Option } = Select;

export default function ProductCancellationPoliciesInfo(props: {
  values: any;
}) {
  const { values } = props;
  const timeUnits = [
    { Id: 'flxTimeUnitsDays', Name: 'days' },
    { Id: 'flxTimeUnitsHours', Name: 'hours' },
  ];

  const timeUnitsOptions = timeUnits.map((policy) => (
    <Option key={policy.Id} value={policy.Id}>
      {policy.Name}
    </Option>
  ));

  return (
    <Element name={ProductSectionKeyEnum.Cancellation} className="row">
      <div className="column title">
        {intl.get('inventory.products.popup.cancellationPolicy')}
      </div>
      <div className="column content">
        <div className="d-f f-d-column">
          <div className="d-f f-d-column flx-1">
            <Field
              label={intl.get('inventory.products.popup.nonRefundable')}
              name="Policies[0].NonRefundable"
              component={CheckboxContainer}
            />

            {!values.Policies[0].NonRefundable && (
              <>
                <div className="d-f jc-fs ai-c">
                  <div className="m-b-10">Penalty for cancelling a booking</div>
                  <div className="w-15 m-h-10">
                    <Field
                      className="m-b-0"
                      name="Policies[0].Days"
                      component={InputNumberContainer}
                      min={0}
                    />
                  </div>
                  <div className="m-b-10">or fewer</div>
                  <div className="w-15 m-h-10 center-select-value">
                    <Field
                      className="m-b-0"
                      name="Policies[0].TimeUnits"
                      component={SelectContainer}
                    >
                      {timeUnitsOptions}
                    </Field>
                  </div>
                  <div className="m-b-10">prior to travel date</div>
                </div>
                <div className="d-f jc-fs ai-c m-t-10">
                  <div className="m-b-10">Penalty fee</div>
                  <div className="w-15 m-h-10 center-select-value">
                    <Field
                      className="m-b-0"
                      name="Policies[0].Type"
                      component={CommissionTypeContainer}
                    />
                  </div>
                  <div className="w-15">
                    <Field
                      className="m-b-0"
                      name="Policies[0].Penalty"
                      type={values.Policies[0].Type}
                      component={CommissionContainer}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Element>
  );
}
