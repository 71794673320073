import React from 'react';
import intl from 'react-intl-universal';
import { Space } from 'antd';
import {
  CalculatorTwoTone,
  LockTwoTone,
  UnlockTwoTone,
} from '@ant-design/icons';
import { isEmpty, isNil, uniqBy } from 'lodash';
import moment from 'moment';

import { VisibilityEnum } from '../models/enums/visibility.enum';
import { VisibilityCopyEnum } from '../models/enums/visibilityCopy.enum';
import { ProductTypeEnum } from '../models/enums/productType.enum';
import { AgeTypeEnum } from '../models/enums/ageType.enum';
import { CommissionTypeEnum } from '../models/enums/commissionType.enum';
import { FormRateModel } from '../models/inventory/formRate.model';
import {
  DayOfWeekEnum,
  DayOfWeekValueEnum,
} from '../models/enums/dayOfWeek.enum';
import { RateFiltersModel } from '../models/inventory/rateFilters.model';
import { RegularRatesModel } from '../models/inventory/regularRates.model';

export const getVisibilityIcon = (visibility: VisibilityEnum) =>
  ({
    [VisibilityEnum.Private]: <LockTwoTone />,
    [VisibilityEnum.Public]: <UnlockTwoTone />,
    [VisibilityEnum.Negotiated]: <CalculatorTwoTone />,
  }[visibility] ?? null);

export const getVisibilityLabel = (visibility: VisibilityEnum) =>
  ({
    [VisibilityEnum.Private]: intl.get('rateAvailability.private'),
    [VisibilityEnum.Public]: intl.get('rateAvailability.public'),
    [VisibilityEnum.Negotiated]: intl.get('rateAvailability.negotiated'),
  }[visibility] ?? '');

export const getVisibilityOptionLabel = (visibility: VisibilityEnum) =>
  ({
    [VisibilityEnum.Private]: (
      <Space>
        {getVisibilityIcon(VisibilityEnum.Private)}
        {intl.get('rateAvailability.private')}
      </Space>
    ),
    [VisibilityEnum.Public]: (
      <Space>
        {getVisibilityIcon(VisibilityEnum.Public)}
        {intl.get('rateAvailability.public')}
      </Space>
    ),
    [VisibilityEnum.Negotiated]: (
      <Space>
        {getVisibilityIcon(VisibilityEnum.Negotiated)}
        {intl.get('rateAvailability.negotiated')}
      </Space>
    ),
  }[visibility] ?? null);

export const getVisibilityValue = (visibility: VisibilityCopyEnum) =>
  ({
    [VisibilityCopyEnum.Private]: VisibilityEnum.Private,
    [VisibilityCopyEnum.Public]: VisibilityEnum.Public,
    [VisibilityCopyEnum.Negotiated]: VisibilityEnum.Negotiated,
  }[visibility]);

export const getInitialRate = (
  productDetailsType: ProductTypeEnum,
  rateFilters?: RateFiltersModel,
  position?: number,
): FormRateModel => ({
  position,
  isExistRate: false,
  isCategoryChanged: false,
  Inactive: false,
  ServiceTime: undefined,
  Visibility: rateFilters?.visibility,
  CommissionPct: undefined,
  CommissionFlat: undefined,
  DaysOfWeek: [],
  AgencyId: rateFilters?.agencyId,
  CategoryName: undefined,
  CategoryId: undefined,
  IsExistsCategory: false,
  RegularRates: [
    {
      Type:
        productDetailsType === ProductTypeEnum.Package
          ? AgeTypeEnum.Single
          : AgeTypeEnum.Adult,
      Cost: null,
      CostBeforeTax: null,
      Retail: null,
      Rate: null,
      MaxOccupancy: null,
    },
  ],
  DefaultAvailability: undefined,
  StopSell: false,
  InventoryDate: [],
  CommissionType: CommissionTypeEnum.Percentage,
});

export const getDaysOptions = () =>
  Object.keys(DayOfWeekEnum).map((key) => ({
    label: key,
    value: DayOfWeekValueEnum[key],
  }));

export const getDaysOfWeekList = (daysOfWeek: string): number[] =>
  isNil(daysOfWeek)
    ? []
    : daysOfWeek
        .split(',')
        .map((day) => DayOfWeekValueEnum[day.trim()])
        .filter(Boolean);

export const getDaysOfWeekInt = (daysOfWeek: number[]) =>
  isEmpty(daysOfWeek)
    ? null
    : `${daysOfWeek.reduce((acc, val) => acc + val, 0)}`;

export const getAvailableAges = (
  ageTypes: AgeTypeEnum[],
  currentRate: RegularRatesModel,
  rates: RegularRatesModel[],
) =>
  ageTypes.filter(
    (age) =>
      age === AgeTypeEnum.Group ||
      age === AgeTypeEnum.Adult ||
      age === currentRate.Type ||
      !rates.some((rate) => rate.Type === age),
  );

const getRateGuestTypeLabel = (age: AgeTypeEnum) =>
  ({
    [AgeTypeEnum.Adult]: intl.get('adultPerPerson'),
    [AgeTypeEnum.Child]: intl.get('childPerPerson'),
    [AgeTypeEnum.Junior]: intl.get('juniorPerPerson'),
    [AgeTypeEnum.Senior]: intl.get('seniorPerPerson'),
    [AgeTypeEnum.Group]: intl.get('groupRate'),
  }[age]);

export const getRateGuestTypeOptions = (
  ageTypes: AgeTypeEnum[],
  currentRate: RegularRatesModel,
  rates: RegularRatesModel[],
) =>
  getAvailableAges(ageTypes, currentRate, rates).map((age) => ({
    value: age,
    label: getRateGuestTypeLabel(age),
  }));

export const getUniqRates = (rates: FormRateModel[]) =>
  uniqBy(rates, (rate) => rate.CategoryId);

export const getRateCategoryTimes = (
  categoryId: string,
  rates: FormRateModel[],
) =>
  rates
    .filter((rate) => rate.CategoryId === categoryId && rate.ServiceTime)
    .map((rate) => moment(rate.ServiceTime));
