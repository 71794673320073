export enum availableLocales {
  CHI = 'zh-CN',
  ENG = 'en-US',
}

export enum availableLanguages {
  CHI = 'CHI',
  ENG = 'ENG',
}

export enum languageNames {
  CHI = '简体中文',
  ENG = 'English',
}
