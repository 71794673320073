import React, { Component, Fragment } from 'react';
import moment from 'moment';
import pluralize from 'pluralize';
import intl from 'react-intl-universal';
import { Tooltip } from 'antd';

import {
  ClockCircleOutlined,
  CloseOutlined,
  EditOutlined,
  QuestionCircleFilled,
} from '@ant-design/icons';
import { CardInCartModel } from '../../../models/cardInCart.model';
import { GuestTypesEnum } from '../../../utils/constants';
import { moneyFormat } from '../../../utils/formatter';
import ImageComponent from '../../SharedComponents/Image';
import { AvailabilityLevelEnum } from '../../../models/enums/availabilityLevel.enum';
import ProductHoldInfoPopover from '../../SharedComponents/ProductHoldInfoPopover';

const dateFormat = 'MMM D (ddd)';

export default class CardInCart extends Component<{
  card: CardInCartModel;
  editCard: Function;
  removeCard: Function;
  onCardClick?: Function;
  isProductIdHidden?: boolean;
}> {
  handleCardNameClick = () => {
    const { card, onCardClick } = this.props;

    !!onCardClick && onCardClick(card.productId);
  };

  handleEditCard = () => {
    const { card, editCard } = this.props;

    editCard(card.productId, card.id);
  };

  handleRemoveCard = () => {
    this.props.removeCard();
  };

  renderGuestFragment = (
    productName: string,
    price: number,
    countOfGuests: number,
    guestLabel: string,
  ) => (
    <div className="ticket-item">
      <div className="ticket-name">{`${productName} (${guestLabel})`}</div>
      <div className="ticket-total">
        <div className="price">{moneyFormat(price)}</div>
        <div className="guest-count-and-type">
          {pluralize(guestLabel, countOfGuests, true)}
        </div>
      </div>
    </div>
  );

  renderProductByGuests = (product: any) => {
    const adults = product.Guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.ADULT,
    );
    const adultsPrice = adults.reduce((sum, current) => sum + current.Price, 0);

    const children = product.Guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.CHD,
    );
    const childrenPrice = children.reduce(
      (sum, current) => sum + current.Price,
      0,
    );

    const juniors = product.Guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.JUNIOR,
    );
    const juniorsPrice = juniors.reduce(
      (sum, current) => sum + current.Price,
      0,
    );

    const infants = product.Guests.filter(
      (guest: any) => guest.Type === GuestTypesEnum.INFANT,
    );
    const infantsPrice = infants.reduce(
      (sum, current) => sum + current.Price,
      0,
    );

    return (
      <Fragment>
        {adults.length > 0 &&
          this.renderGuestFragment(
            product.Name,
            adultsPrice,
            adults.length,
            intl.get('adult'),
          )}
        {juniors.length > 0 &&
          this.renderGuestFragment(
            product.Name,
            juniorsPrice,
            juniors.length,
            intl.get('junior'),
          )}
        {children.length > 0 &&
          this.renderGuestFragment(
            product.Name,
            childrenPrice,
            children.length,
            intl.get('child'),
          )}
        {infants.length > 0 &&
          this.renderGuestFragment(
            product.Name,
            infantsPrice,
            infants.length,
            intl.get('infant'),
          )}
      </Fragment>
    );
  };

  render() {
    const {
      card,
      card: { tourName, productId, imageUrl, date, product },
      isProductIdHidden,
    } = this.props;
    const REQUESTABLE_TOOLTIP = intl.get('REQUESTABLE_TOOLTIP');

    return (
      <div className="card-in-cart">
        <div className="card-top">
          <ProductHoldInfoPopover
            cardsInCart={[card]}
            text={intl.get('holdInfo.singleTimerText')}
            getPopupContainer={() => document.body}
          >
            <div className="card-hold-icon">
              <ClockCircleOutlined />
            </div>
          </ProductHoldInfoPopover>

          <ImageComponent className="card-image" src={imageUrl} />

          <div className="card-info">
            <div className="d-f ai-c">
              <h3
                className="tour-name m-r-10"
                onClick={this.handleCardNameClick}
              >
                {tourName}
              </h3>
              {product.AvailabilityLevel ===
                AvailabilityLevelEnum.Requestable && (
                <span className="f-s-18 m-b-10">
                  <Tooltip title={REQUESTABLE_TOOLTIP}>
                    <QuestionCircleFilled className="color-yellow c-p" />
                  </Tooltip>
                </span>
              )}
            </div>
            {!isProductIdHidden && (
              <div className="product-number">#{productId}</div>
            )}
            <div className="cart-actions">
              <span className="edit-button" onClick={this.handleEditCard}>
                <EditOutlined /> {intl.get('cartPage.edit')}
              </span>
              <span className="remove-button" onClick={this.handleRemoveCard}>
                <CloseOutlined /> {intl.get('cartPage.remove')}
              </span>
            </div>
          </div>
        </div>
        <div className="card-bottom">
          <div className="tour-date">{moment.utc(date).format(dateFormat)}</div>
          <div className="products-by-guests">
            {this.renderProductByGuests(product)}
          </div>
        </div>
      </div>
    );
  }
}
