import React from 'react';
import Dotdotdot from 'react-dotdotdot';
import intl from 'react-intl-universal';

import { TourCardModel } from '../../../../models/marketplace/tourCard.model';
import { moneyFormat } from '../../../../utils/formatter';
import ImageComponent from '../../../SharedComponents/Image';

export default function TourCard(props: {
  card: TourCardModel;
  imagesRoot: string;
}) {
  const { card, imagesRoot } = props;

  return (
    <div className="tour-card">
      <div className="img-container">
        <ImageComponent
          isBackground
          className="img-div"
          src={
            card.LargeImage
              ? `${imagesRoot}/${encodeURI(
                  card.LargeImage.replace(/\\/g, '/'),
                )}`
              : null
          }
        >
          <span className="city-name">{card.CityName}</span>
          {!!card.Featured && (
            <div className="featured-label">
              <span>{intl.get('marketplacePage.tourCard.featured')}</span>
            </div>
          )}
        </ImageComponent>
      </div>
      <div className="tour-card-info">
        <h4 className="tour-name">{card.Name}</h4>
        <div className="rating-and-trip-advisor" />
        <div className="description">
          <Dotdotdot clamp={4}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: card.Text }} />
          </Dotdotdot>
        </div>
        <div className="card-footer">
          {!!card.StartingAgentNet && (
            <div className="price">
              {`${intl.get('from')} ${moneyFormat(card.StartingAgentNet)}`}
            </div>
          )}
          {!!card.DurationText && (
            <div className="duration">
              <i className="tc-clock duration-icon" />
              <span>{card.DurationText}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
