import { useContext } from 'react';
import { MobXProviderContext, useObserver } from 'mobx-react';

import { RootStore } from 'stores/root.store';

function useStore<TState = RootStore, Selected = unknown>(
  selector: (state: TState) => Selected,
): Selected {
  const { rootStore } = useContext(MobXProviderContext);

  return useObserver(() => selector(rootStore));
}

export default useStore;
