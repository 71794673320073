import React from 'react';

import { supportEmail } from '../../../utils/constants';

export default function PrivacyPolicy() {
  return (
    <div className="privacy-policy-page">
      <h1 className="privacy-policy-page-title">
        <p className="m-0">Privacy Policy</p>
      </h1>

      <p className="privacy-policy-small-title">
        <b>Introduction</b>
      </p>
      <p>
        CHD, Inc, a Nevada Company d/b/a “Travel USA Express”{' '}
        <b>(“TUE,” “Company, “we,” “us,” “our”)</b> respect your privacy and are
        committed to protecting it through our compliance with this policy.
      </p>

      <p>
        This policy describes the types of information we may collect from you
        or that you may provide when you visit our online platform that provides
        travel products and services (the <b>“Services“</b>
        ), which online platform is accessible at www.travelusaexpress.com
        (collectively with any other websites through which the Company makes
        the Services available, the <b>“Site”</b>) and our practices for
        collecting, using, maintaining, protecting and disclosing that
        information.
      </p>

      <p>This policy applies to information we collect:</p>
      <ul>
        <li>On the Services.</li>
        <li>
          In e-mail, text and other electronic messages between you and the
          Services.
        </li>
        <li>
          Through mobile and desktop applications you download from the
          Services, which provide dedicated non-browser-based interaction
          between you and the Services.
        </li>
        <li>
          When you interact with our advertising and applications on third-party
          websites and services, if those applications or advertising include
          links to this policy.
        </li>
      </ul>

      <p>It does not apply to information collected by:</p>
      <ul>
        <li>
          us offline or through any other means, including on any other website
          operated by Company or any third party (including our affiliates and
          subsidiaries); or
        </li>
        <li>
          any third party (including our affiliates and subsidiaries), including
          through any application or content (including advertising) that may
          link to or be accessible from or on the Services.
        </li>
      </ul>

      <p>
        Please read this policy carefully to understand our policies and
        practices regarding your information and how we will treat it. If you do
        not agree with our policies and practices, your choice is not to use our
        Website. By accessing or using the Services, you agree to this privacy
        policy. This policy may change from time to time. Your continued use of
        the Services after we make changes is deemed to be acceptance of those
        changes, so please check the policy periodically for updates.
      </p>

      <div>
        <p className="privacy-policy-small-title">
          <b>Children Under the Age of 13</b>
        </p>
        <p>
          Our Services are not intended for children under 18 years of age. We
          do not knowingly collect personal information from children under 13.
          If you are under 13, do not use or provide any information on the
          Services or on or through any of its features/register on the
          Services, make any purchases through the Services, use any of the
          interactive or public comment features of the Services or provide any
          information about yourself to us, including your name, address,
          telephone number, e-mail address or any screen name or user name you
          may use. If we learn we have collected or received personal
          information from a child under 13 without verification of parental
          consent, we will delete that information. If you believe we might have
          any information from or about a child under 13, please contact us at{' '}
          {supportEmail}
        </p>
      </div>

      <div>
        <p className="privacy-policy-small-title">
          <b>Information We Collect About You and How We Collect It</b>
        </p>
        <span>
          We collect several types of information from and about users of our
          Services, including information:
        </span>
        <ul>
          <li>
            by which you may be personally identified, such as name, postal
            address, e-mail address, telephone number, or any other identifier
            by which you may be contacted online or offline (
            <b>“personal information“</b>
            );
          </li>
          <li>
            that is about you but individually does not identify you, such as
            Place of departure, destination, itinerary; and/or
          </li>
          <li>
            about your internet connection, the equipment you use to access our
            Services and usage details.
          </li>
        </ul>

        <p>We collect this information:</p>
        <ul>
          <li>Directly from you when you provide it to us.</li>
          <li>
            Automatically as you navigate through the Site. Information
            collected automatically may include usage details, IP addresses and
            information collected through cookies, web beacons, and other
            tracking technologies.
          </li>
          <li>From third parties, for example, our business partners.</li>
        </ul>

        <p>
          <b>Information You Provide to Us.</b> The information we collect on or
          through our Services may include:
        </p>
        <ul>
          <li>
            Information that you provide by filling in forms on the Site. This
            includes information provided at the time of registering to use our
            Services, posting material, or requesting further services. We may
            also ask you for information when you enter a contest or promotion
            sponsored by us, and when you report a problem with our Services.
          </li>
          <li>
            Records and copies of your correspondence (including e-mail
            addresses), if you contact us.
          </li>
          <li>
            Your responses to surveys that we might ask you to complete for
            research purposes.
          </li>
          <li>
            Details of transactions you carry out through our Services. You may
            be required to provide financial information before listing or
            booking adventure travel destinations, activities, products or
            services through our Site.
          </li>
          <li>Your search queries on the Site.</li>
        </ul>

        <p>
          You also may provide information to be published or displayed
          (hereinafter, <b>“posted“</b>) on public areas of the Site, or
          transmitted to other users of the Site or third parties (collectively,{' '}
          <b>“User Contributions“</b>
          ). Your User Contributions are posted on and transmitted to others at
          your own risk. Although [we limit access to certain pages/you may set
          certain privacy settings for such information by logging into your
          account profile], please be aware that no security measures are
          perfect or impenetrable. Additionally, we cannot control the actions
          of other users of the Site with whom you may choose to share your User
          Contributions. Therefore, we cannot and do not guarantee that your
          User Contributions will not be viewed by unauthorized persons.
        </p>
      </div>
      <div>
        <p className="privacy-policy-small-title">
          <b>
            Information We Collect Through Automatic Data Collection
            Technologies.
          </b>
        </p>
        <p>
          As you navigate through and interact with our Services, we may use
          automatic data collection technologies to collect certain information
          about your equipment, browsing actions and patterns, including:
        </p>
        <ul>
          <li>
            Details of your visits to our Site, including traffic data, location
            data, logs and other communication data and the resources that you
            access and use on the Site.
          </li>
          <li>
            Information about your computer and internet connection, including
            your IP address, operating system and browser type.
          </li>
        </ul>

        <p>
          The information we collect automatically may include personal
          information. It helps us to improve our Services and to deliver a
          better and more personalized service, including by enabling us to:
        </p>

        <ul>
          <li>Estimate our audience size and usage patterns.</li>
          <li>
            Store information about your preferences, allowing us to customize
            our Website according to your individual interests.
          </li>
          <li>Speed up your searches.</li>
          <li>Recognize you when you return to our Site.</li>
        </ul>

        <p>
          The technologies we use for this automatic data collection may
          include:
        </p>

        <ul>
          <li>
            <b>Cookies (or browser cookies).</b> A cookie is a small file placed
            on the hard drive of your computer. You may refuse to accept browser
            cookies by activating the appropriate setting on your browser.
            However, if you select this setting you may be unable to access
            certain parts of our Services. Unless you have adjusted your browser
            setting so that it will refuse cookies, our system will issue
            cookies when you direct your browser to our Services.
          </li>
          <li>
            <b>Flash Cookies.</b> Certain features of our Services may use local
            stored objects or Flash cookies to collect and store information
            about your preferences and navigation to, from and on our Services.
            Flash cookies are not managed by the same browser settings as are
            used for browser cookies.
          </li>
          <li>
            <b>Web Beacons.</b> Pages of our Site and our e-mails may contain
            small electronic files known as web beacons (also referred to as
            clear gifs. pixel tags and single-pixel gifs) that permit the
            Company, for example, to count users who have visited those pages or
            opened an e-mail and for other related website statistics (for
            example, recording the popularity of certain website content and
            verifying system and server integrity).
          </li>
        </ul>
      </div>

      <div>
        <p className="privacy-policy-small-title">
          <b>Third-party Use of Cookies and Other Tracking Technologies.</b>
        </p>
        <p>
          Some content or applications, including advertisements, on the
          Services are served by third-parties, including advertisers, ad
          networks and servers, content providers and application providers.
          These third parties may use cookies, alone or in conjunction with web
          beacons or other tracking technologies, to collect information about
          you when you use our Services. The information they collect may be
          associated with your personal information or they may collect
          information, including personal information, about your online
          activities over time and across different websites and other online
          services. They may use this information to provide you with
          interest-based (behavioral) advertising or other targeted content.
        </p>

        <p>
          We do not control these third parties’ tracking technologies or how
          they may be used. If you have any questions about an advertisement or
          other targeted content, you should contact the responsible provider
          directly.
        </p>
      </div>
      <div>
        <p className="privacy-policy-small-title">
          <b>How We Use Your Information</b>
        </p>
        <p>
          We use information that we collect about you or that you provide to
          us, including any personal information:
        </p>
        <ul>
          <li>To present our Services and its contents to you.</li>
          <li>
            To provide you with information, products or services that you
            request from us.
          </li>
          <li>To fulfill any other purpose for which you provide it.</li>
          <li>To provide you with notices about your account.</li>
          <li>
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection.
          </li>
          <li>
            To notify you about changes to our Site or any products or services
            we offer or provide though our Site.
          </li>
          <li>
            To allow you to participate in interactive features on our Site.
          </li>
          <li>
            In any other way we may describe when you provide the information.
          </li>
          <li>For any other purpose with your consent.</li>
        </ul>
        <p>
          We may also use your information to contact you about our own and
          third-parties’ goods and services that may be of interest to you. If
          you do not want us to use your information in this way, please [adjust
          your user preferences in your account profile.
        </p>
        <p>
          We may use the information we have collected from you to enable us to
          display advertisements to our advertisers’ target audiences. Even
          though we do not disclose your personal information for these purposes
          without your consent, if you click on or otherwise interact with an
          advertisement, the advertiser may assume that you meet its target
          criteria.
        </p>
      </div>

      <div>
        <p className="privacy-policy-small-title">
          <b>Disclosure of Your Information</b>
        </p>
        <p>
          We may disclose aggregated information about our users, and
          information that does not identify any individual, without
          restriction.
        </p>
        <p>
          We may disclose personal information that we collect or you provide as
          described in this privacy policy:
        </p>
        <ul>
          <li>To our subsidiaries and affiliates.</li>
          <li>
            To contractors, service providers and other third parties we use to
            support our business, and who are bound by contractual obligations
            to keep personal information confidential and use it only for the
            purposes for which we disclose it to them.
          </li>
          <li>
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution or other sale or transfer
            of some or all of the Company’s assets, whether as a going concern
            or as part of bankruptcy, liquidation or similar proceeding, in
            which personal information held by the Company about the users of
            our Services is among the assets transferred.
          </li>
          <li>
            To third parties to market their products or services to you if you
            have not opted out of these disclosures.
          </li>
          <li>To fulfill the purpose for which you provide it.</li>
          <li>
            For any other purpose disclosed by us when you provide the
            information.
          </li>
          <li>With your consent.</li>
        </ul>

        <p>We may also disclose your personal information:</p>

        <ul>
          <li>
            To comply with any court order, law or legal process, including to
            respond to any government or regulatory request.
          </li>
          <li>
            To enforce or apply our Terms of Use and other agreements, including
            for billing and collection purposes.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of the Company, our customers or others.
            This includes exchanging information with other companies and
            organizations for the purposes of fraud protection and credit risk
            reduction.
          </li>
        </ul>
      </div>

      <div>
        <p className="privacy-policy-small-title">
          <b>Choices About How We Use and Disclose Your Information</b>
        </p>
        <p>
          We strive to provide you with choices regarding the personal
          information you provide to us. We have created mechanisms to provide
          you with the following control over your information:
        </p>

        <ul>
          <li>
            {' '}
            <b>Tracking Technologies and Advertising.</b> You can set your
            browser to refuse all or some browser cookies, or to alert you when
            cookies are being sent. To learn how you can manage your Flash
            cookie settings, visit the Flash player settings page on Adobe’s
            website. If you disable or refuse cookies, please note that some
            parts of this site may then be inaccessible or not function
            properly.
          </li>
          <li>
            <b>Disclosure of Your Information for Third-Party Advertising.</b>
          </li>
        </ul>

        <p>
          If you do not want us to share your personal information with
          unaffiliated or non-agent third parties for promotional purposes, you
          can opt-out by adjusting your user preferences in your account profile
          by checking or unchecking the relevant boxes or by sending us an
          e-mail stating your request to {supportEmail}.
        </p>

        <ul>
          <li>
            <b>Promotional Offers from the Company.</b> If you do not wish to
            have your [e-mail address/contact information] used by the Company
            to promote our own or third parties’ products or services, you can
            opt-out by adjusting your user preferences in your account profile
            by checking or unchecking the relevant boxes or by sending us an
            e-mail stating your request to {supportEmail} If we have sent you a
            promotional e-mail, you may send us a return e-mail asking to be
            omitted from future e-mail distributions.
          </li>
          <li>
            <b>Targeted Advertising.</b> If you do not want us to use
            information that we collect or that you provide to us to deliver
            advertisements according to our advertisers’ target-audience
            preferences, you can opt-out by adjusting your user advertising
            preferences in your account profile by checking or un-checking the
            relevant boxes or by sending us an e-mail stating your request to{' '}
            {supportEmail}. For this opt-out to function, you must have your
            browser set to accept browser cookies.
          </li>
        </ul>

        <p>
          We do not control third parties’ collection or use of your information
          to serve interest-based advertising. However these third parties may
          provide you with ways to choose not to have your information collected
          or used in this way.
        </p>
      </div>

      <div>
        <p className="privacy-policy-small-title">
          <b>Accessing and Correcting Your Information</b>
        </p>
        <p>
          You can review and change your personal information by logging into
          the Site and visiting your account profile page.
        </p>
        <p>
          You may also send us an e-mail at {supportEmail} to request access to,
          correct or delete any personal information that you have provided to
          us. We cannot delete your personal information except by also deleting
          your user account. We may not accommodate a request to change
          information if we believe the change would violate any law or legal
          requirement or cause the information to be incorrect.
        </p>
        <p>
          If you delete your User Contributions from the Site, copies of your
          User Contributions may remain viewable in cached and archived pages,
          or might have been copied or stored by other Site users. Proper access
          and use of information provided on the Site, including User
          Contributions, is governed by our Terms of Use.
        </p>
      </div>

      <div>
        <p className="privacy-policy-small-title">
          <b>Your California Privacy Rights</b>
        </p>
        <p>
          California Civil Code Section § 1798.83 permits users of our Services
          that are California residents to request certain information regarding
          our disclosure of personal information to third parties for their
          direct marketing purposes. To make such a request, please send an
          e-mail to {supportEmail}
        </p>
      </div>

      <div>
        <p className="privacy-policy-small-title">
          <b>Data Security</b>
        </p>
        <p>
          The safety and security of your information also depends on you. Where
          we have given you (or where you have chosen) a password for access to
          certain parts of our Website, you are responsible for keeping this
          password confidential. We ask you not to share your password with
          anyone. We urge you to be careful about giving out information in
          public areas of the Site like message boards. The information you
          share in public areas may be viewed by any user of the Site.
        </p>
        <p>
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we do our best to protect your personal
          information, we cannot guarantee the security of your personal
          information transmitted to our Site. Any transmission of personal
          information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained
          on the Site.
        </p>
      </div>

      <div>
        <p className="privacy-policy-small-title">
          <b>Changes to Our Privacy Policy</b>
        </p>
        <p>
          It is our policy to post any changes we make to our privacy policy on
          this page. If we make material changes to how we treat our users’
          personal information, we will notify you by e-mail to the e-mail
          address specified in your account or through a notice on the Site home
          page. The date the privacy policy was last revised is identified at
          the top of the page. You are responsible for ensuring we have an
          up-to-date active and deliverable e-mail address for you, and for
          periodically visiting our Site and this privacy policy to check for
          any changes.
        </p>
      </div>

      <div>
        <p className="privacy-policy-small-title">
          <b>Contact Information</b>
        </p>
        <p>
          To ask questions or comment about this privacy policy and our privacy
          practices, contact us at: <a href="">{supportEmail}</a>
        </p>
      </div>

      <div>
        <b>Last Updated: </b>
        <span>4/2/2019</span>
      </div>
    </div>
  );
}
