import React, { Component } from 'react';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import type { GroupBase } from 'react-select';
import AsyncSelect, {
  type AsyncCreatableProps,
} from 'react-select/async-creatable';
import { FieldProps, getIn } from 'formik';
import { debounce, get } from 'lodash';

import { generateCategoryId } from '../../../../../../utils/inventoryHelper';
import { InventoryStore } from '../../../../../../stores/inventory.store';
import { withStore } from '../../../../../../hocs';

type OptionType = { label: string; value: string };
type SelectProps<T = OptionType> = AsyncCreatableProps<T, false, GroupBase<T>>;

type Props = {
  label: string;
  className?: string;
  categoryName: string;
  dataSource: OptionType[];
  inventoryStore: InventoryStore;
} & FieldProps;

type State = {
  value: OptionType;
};

@withStore(({ rootStore }) => ({
  inventoryStore: rootStore.inventoryStore,
}))
export default class CategoryAutoCompletedContainer extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    const { form, field, categoryName } = props;

    this.state = {
      value: {
        label: field.value,
        value: get(form.values, `${categoryName}.CategoryId`),
      },
    };
  }

  handleInputValueChange: SelectProps['onInputChange'] = (
    inputValueChanged,
    option,
  ) => {
    const { action } = option;
    if (action === 'input-change') {
      const {
        form: { setFieldValue },
        categoryName,
      } = this.props;

      this.setState({ value: null });
      setFieldValue(`${categoryName}.CategoryName`, inputValueChanged);
      setFieldValue(`${categoryName}.CategoryId`, '');
      setFieldValue(`${categoryName}.IsExistsCategory`, false);
      setFieldValue(`${categoryName}.isCategoryChanged`, true);
    }
  };

  handleSelect: SelectProps['onChange'] = (value) => {
    const {
      form: { setFieldValue, initialValues },
      categoryName,
    } = this.props;

    this.setState({
      value,
    });

    const isCategoryChanged =
      get(initialValues, `${categoryName}.CategoryName`) !== value?.label;

    setFieldValue(`${categoryName}.CategoryName`, value?.label);
    setFieldValue(`${categoryName}.CategoryId`, value?.value);
    setFieldValue(`${categoryName}.IsExistsCategory`, Boolean(value));
    setFieldValue(`${categoryName}.isCategoryChanged`, isCategoryChanged);
  };

  handleGenerateNewCategory: SelectProps['onCreateOption'] = async (value) => {
    const {
      categoryName,
      form: { setFieldValue },
    } = this.props;
    const { code, IsExistsCategory } = await generateCategoryId(value || '');

    setFieldValue(`${categoryName}.CategoryId`, code);
    setFieldValue(`${categoryName}.IsExistsCategory`, IsExistsCategory);
    setFieldValue(`${categoryName}.isCategoryChanged`, true);
  };

  loadOptions: SelectProps['loadOptions'] = (inputValue, callback) => {
    const { inventoryStore } = this.props;

    inventoryStore.searchCategories(inputValue).then(callback);
  };

  // eslint-disable-next-line react/sort-comp
  loadOptionsDebounced = debounce(this.loadOptions, 300);

  render() {
    const {
      field,
      form: { touched, errors },
      className,
      dataSource,
      ...rest
    } = this.props;
    const { value } = this.state;
    const fieldError: string = getIn(errors, field.name);
    const isTouched: boolean = getIn(touched, field.name);
    const hasError: boolean = fieldError && isTouched;

    return (
      <div
        className={classnames(
          'field',
          {
            'has-error': hasError,
          },
          className,
        )}
      >
        <h4 className="field-label">
          {intl.get('inventory.products.popup.category')}
        </h4>

        <AsyncSelect
          {...rest}
          isClearable
          createOptionPosition="first"
          tabSelectsValue={false}
          className="testOp"
          value={value}
          inputValue={field.value}
          defaultOptions={dataSource}
          loadOptions={this.loadOptionsDebounced}
          onChange={this.handleSelect}
          onCreateOption={this.handleGenerateNewCategory}
          onInputChange={this.handleInputValueChange}
        />
        {hasError && <div className="field-error visible">{fieldError}</div>}
      </div>
    );
  }
}
