import React, { Component } from 'react';
import classnames from 'classnames';
import { getIn } from 'formik';

import { Input } from 'antd';

export default class TextareaContainer extends Component<{
  field: any;
  form: any;
  label: string;
  className?: string;
  rowCount?: number;
  maxWords?: number;
}> {
  handleChange = (e) => {
    const {
      form: { setFieldValue },
      field: { name },
      maxWords,
    } = this.props;
    const { value } = e.target;

    if (maxWords && value.split(' ').length > maxWords) {
      return;
    }

    setFieldValue(name, value || '');
  };

  render() {
    const {
      field,
      form: { touched, errors },
      label,
      className,
      rowCount,
      ...rest
    } = this.props;
    const fieldError: string = getIn(errors, field.name);
    const isTouched: boolean = getIn(touched, field.name);
    const hasError: boolean = fieldError && isTouched;

    return (
      <div
        className={classnames('field', { 'has-error': hasError }, className)}
      >
        {label && <h4 className="field-label">{label}</h4>}
        <Input.TextArea
          rows={rowCount || 6}
          {...field}
          {...rest}
          onChange={this.handleChange}
        />
        {hasError && <div className="field-error visible">{fieldError}</div>}
      </div>
    );
  }
}
