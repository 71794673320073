import { AxiosPromise } from 'axios';

import { BaseApi } from './base.api';
import { SupplierModel } from '../models/contacts/supplier.model';

export class SuppliersApi extends BaseApi {
  public getSuppliers(): AxiosPromise<{ value: SupplierModel[] }> {
    return this.axios.get('/odata/operators');
  }

  public createSupplier(supplier) {
    return this.axios.post('/odata/operators', supplier);
  }

  public editSupplier(supplierId: string, supplier: Partial<SupplierModel>) {
    return this.axios.patch(`/odata/operators/${supplierId}`, supplier);
  }
}
