import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Button, Col, Row } from 'antd';
import { Field, FormikProps } from 'formik';
import { compact, lowerCase, sortBy, upperFirst } from 'lodash';
import { Marker, Map, LngLatLike } from 'mapbox-gl';
import { Element } from 'react-scroll';

import { CommonStore } from '../../../../../../stores/common.store';
import {
  InputContainer,
  InputNumberContainer,
  SelectContainer,
} from '../../../../../FormContainers';
import MapBox, { MapBoxMarker } from '../../../../../SharedComponents/MapBox';
import { withStore } from '../../../../../../hocs';
import { ProductFormValuesModel } from '../../../../../../models/inventory/productFormValues.model';
import { ProductSectionKeyEnum } from '../../../../../../models/enums/productSectionKey.enum';

type Props = {
  commonStore?: CommonStore;
  formikProps: FormikProps<ProductFormValuesModel>;
};

@withStore(({ rootStore }) => ({
  commonStore: rootStore.commonStore,
}))
class ProductLocation extends Component<Props> {
  map: Map = null;

  marker: Marker = null;

  componentDidMount() {
    this.handlePlotAddress();
  }

  componentWillUnmount() {
    this.marker.off('dragend', this.handleDragEnd);
  }

  handleLatChange = (latitude) => {
    const { formikProps } = this.props;
    const { Longitude: longitude } = formikProps.values;

    if (!longitude) return;

    const center = [longitude, latitude] as LngLatLike;

    this.marker.setLngLat(center).addTo(this.map);

    this.map.flyTo({ center, zoom: 8 });
  };

  handleLonChange = (longitude) => {
    const { formikProps } = this.props;
    const { Latitude: latitude } = formikProps.values;

    if (!latitude) return;

    const center = [longitude, latitude] as LngLatLike;

    this.marker.setLngLat(center).addTo(this.map);

    this.map.flyTo({ center, zoom: 8 });
  };

  handleMarkerInit = (marker, map) => {
    this.map = map;
    this.marker = marker;

    this.marker.on('dragend', this.handleDragEnd);
  };

  handleDragEnd = (ev) => {
    const { formikProps } = this.props;

    formikProps.setFieldValue('Latitude', ev.target._lngLat.lat);

    formikProps.setFieldValue('Longitude', ev.target._lngLat.lng);
  };

  handlePlotAddress = async () => {
    const { commonStore, formikProps } = this.props;
    const { getLocationLatLng } = commonStore;
    const { values } = formikProps;

    const result = await getLocationLatLng(
      compact([values.StateId, values.City, values.Street, values.Zip]).join(
        ', ',
      ),
      values.CountryId,
    );

    const center = result.features.at(0)?.center;

    if (!center) return;

    this.marker.setLngLat(center).addTo(this.map);

    this.map.flyTo({ center, zoom: 8 });

    formikProps.setFieldValue('Longitude', center.at(0));

    formikProps.setFieldValue('Latitude', center.at(1));
  };

  get stateOptions() {
    const {
      commonStore,
      formikProps: { values },
    } = this.props;

    return commonStore.locationStates
      .filter((state) => state.CountryCode === values.CountryId)
      .map((state) => ({
        label: this.formatOption(state.Name),
        value: state.Id,
      }));
  }

  get countryOptions() {
    const { commonStore } = this.props;

    const options = commonStore.locationCountries.map((county) => ({
      label: this.formatOption(county.Name),
      value: county.Id,
    }));

    return sortBy(options, (option) => option.label);
  }

  get markers() {
    return [
      {
        options: { draggable: true },
        onInit: this.handleMarkerInit,
      },
    ] as MapBoxMarker[];
  }

  get isPlotAddressDisabled() {
    const { formikProps } = this.props;
    const { values } = formikProps;

    return ![values.CountryId, values.City, values.Street].every(Boolean);
  }

  formatOption = (value: string) => upperFirst(lowerCase(value));

  onCountryChange = (value: string) => {
    const { formikProps } = this.props;

    formikProps.setFieldValue('CountryId', value);
    formikProps.setFieldValue('StateId', undefined);

    this.onResetStateFields();
  };

  onStateChange = (value: string) => {
    const { formikProps } = this.props;

    formikProps.setFieldValue('StateId', value);

    this.onResetStateFields();
  };

  onResetStateFields = () => {
    const { formikProps } = this.props;

    formikProps.setFieldValue('City', undefined);
    formikProps.setFieldValue('Street', undefined);
    formikProps.setFieldValue('Zip', undefined);
  };

  render() {
    const { formikProps } = this.props;

    return (
      <Element name={ProductSectionKeyEnum.Location} className="row">
        <div className="column title">
          {intl.get('inventory.products.popup.location.title')}
        </div>

        <div className="column content">
          <Row gutter={[15, 10]} align="bottom">
            <Col span={12}>
              <Field
                showSearch
                filterOption
                name="CountryId"
                optionFilterProp="label"
                label={intl.get('inventory.products.popup.location.country')}
                options={this.countryOptions}
                component={SelectContainer}
                onChange={this.onCountryChange}
              />
            </Col>

            <Col span={12}>
              <Field
                showSearch
                filterOption
                name="StateId"
                optionFilterProp="label"
                disabled={!formikProps.values.CountryId}
                label={intl.get('inventory.products.popup.location.state')}
                options={this.stateOptions}
                component={SelectContainer}
                onChange={this.onStateChange}
              />
            </Col>

            <Col span={6}>
              <Field
                name="City"
                disabled={!formikProps.values.StateId}
                label={intl.get('inventory.products.popup.location.city')}
                component={InputContainer}
              />
            </Col>

            <Col span={6}>
              <Field
                name="Street"
                disabled={!formikProps.values.StateId}
                label={intl.get('inventory.products.popup.location.street')}
                component={InputContainer}
              />
            </Col>

            <Col span={6}>
              <Field
                name="Zip"
                disabled={!formikProps.values.StateId}
                label={intl.get('inventory.products.popup.location.zip')}
                component={InputContainer}
              />
            </Col>

            <Col span={6}>
              <Button
                block
                type="primary"
                className="m-b-10"
                disabled={this.isPlotAddressDisabled}
                onClick={this.handlePlotAddress}
              >
                {intl.get('inventory.products.popup.location.plotAddress')}
              </Button>
            </Col>

            <Col span={12}>
              <Field
                name="Latitude"
                parser={(value) => value}
                label={intl.get('inventory.products.popup.location.latitude')}
                component={InputNumberContainer}
                onChange={this.handleLatChange}
              />
            </Col>

            <Col span={12}>
              <Field
                name="Longitude"
                parser={(value) => value}
                label={intl.get('inventory.products.popup.location.longitude')}
                component={InputNumberContainer}
                onChange={this.handleLonChange}
              />
            </Col>

            <Col span={24}>
              <MapBox markers={this.markers} style={{ height: 400 }} />
            </Col>
          </Row>
        </div>
      </Element>
    );
  }
}

export default ProductLocation;
