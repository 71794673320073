import React, { Component } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';

import { Formik, Form } from 'formik';
import { Button, Modal } from 'antd';

export default class MainPopup extends Component<{
  closePopup: any;
  visible: boolean;
  title: string;
  submitTitle: string;
  initialValues: Record<string, any>;
  validation: Record<string, any>;
  renderBody: any;
  onCancel: Function;
  onSubmit: Function;

  enableReinitialize?: boolean;
  mask?: boolean;
  extraData?: any;
  submitLoading?: boolean;
  disabledCancelBtn?: boolean;
  disabledSubmitBtn?: boolean;
  renderHeader?: any;
  renderFooter?: any;
  contentClassName?: string;
}> {
  handleSubmit = (values) => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  renderHeader(setFieldValue, values) {
    const {
      closePopup,
      title,
      submitTitle,
      renderHeader,
      submitLoading,
      disabledCancelBtn,
      disabledSubmitBtn,
      extraData,
    } = this.props;

    return (
      <div className="header">
        {renderHeader ? (
          renderHeader(
            title,
            submitTitle,
            closePopup,
            submitLoading,
            setFieldValue,
            values,
            disabledCancelBtn,
            disabledSubmitBtn,
            extraData,
          )
        ) : (
          <div className="d-f flx-1">
            <div className="d-f jc-fs ai-c flx-1">
              <Button
                className="back-button"
                onClick={closePopup}
                disabled={disabledCancelBtn}
              >
                <i className="icon tc-arrow-left" />
                {intl.get('buttons.cancel')}
              </Button>
            </div>
            <div className="title d-f jc-c ai-c flx-1">
              <div>{title}</div>
            </div>
            <div className="title d-f jc-fe ai-c flx-1">
              <Button
                loading={submitLoading}
                htmlType="submit"
                className="action-button"
                disabled={disabledSubmitBtn}
              >
                {submitTitle}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderBody(setFieldValue, values) {
    const { renderBody, extraData } = this.props;

    if (!renderBody) {
      return null;
    }

    return (
      <div className="body">{renderBody(setFieldValue, values, extraData)}</div>
    );
  }

  renderFooter() {
    const { renderFooter, extraData } = this.props;

    if (!renderFooter) {
      return null;
    }

    return <div className="footer">{renderFooter(extraData)}</div>;
  }

  renderPopupContent() {
    const { initialValues, validation, contentClassName, enableReinitialize } =
      this.props;

    return (
      <div className={classnames('content', contentClassName)}>
        <Formik
          enableReinitialize={enableReinitialize}
          initialValues={initialValues}
          validationSchema={validation}
          validateOnChange
          onSubmit={this.handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              {this.renderHeader(setFieldValue, values)}
              {this.renderBody(setFieldValue, values)}
              {this.renderFooter()}
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  render() {
    const { visible, onCancel, mask = true } = this.props;

    return (
      <Modal
        wrapClassName="popup full-modal"
        destroyOnClose
        visible={visible}
        mask={mask}
        maskClosable={false}
        footer={null}
        closable={false}
        onCancel={() => onCancel}
      >
        {this.renderPopupContent()}
      </Modal>
    );
  }
}
