export enum OrderKeysEnum {
  TripNumber = 'TripNumber',
  TripId = 'TripId',
  TripItemId = 'TripItemId',
  Reference = 'Reference',
  ProductType = 'ProductType',
  ProductId = 'ProductId',
  Name = 'Name',
  StartDate = 'StartDate',
  Price = 'Price',
  Cost = 'Cost',
  CreatedOn = 'CreatedOn',
  PassengerCount = 'PassengerCount',
  CategoryId = 'CategoryId',
  CategoryName = 'CategoryName',
  PrimaryGuestName = 'PrimaryGuestName',
  Inactive = 'Inactive',
  PrimaryEmail = 'PrimaryEmail',
  PrimaryPhone = 'PrimaryPhone',
  Nights = 'Nights',
  AgencyId = 'AgencyId',
  AgencyName = 'AgencyName',
  ServiceTime = 'ServiceTime',
  OperatorId = 'OperatorId',
  OperatorName = 'OperatorName',
  Confirmation = 'Confirmation',
  PickupPointLocationType = 'PickupPointLocationType',
  PickupPointLocationId = 'PickupPointLocationId',
  PickupPointName = 'PickupPointName',
  PickupPointStartTime = 'PickupPointStartTime',
  DropoffPointLocationType = 'DropoffPointLocationType',
  DropoffPointLocationId = 'DropoffPointLocationId',
  DropoffPointName = 'DropoffPointName',
  CommunicationStatus = 'CommunicationStatus',
  TestMode = 'TestMode',
  Operation = 'Operation',
}
