import { BookingQuestionEnum } from './bookingQuestion.enum';

export class BookingQuestionProductForm {
  // arrivalAirline: string; // British Airways
  // arrivalFlightNumber: string; // QA 233
  // departureAirline: string; // British Airways
  // departureFlightNumber: string; // UA 864
  // dropOffLocation: string; // 1234 Cedar Way, Brooklyn, NY 00123
  // arrivalTime: string; // 20:30
  // departureDate: string; // 15 September 2015
  // pickUpLocation: string; // 1234 Cedar Way
  // departureTime: string; // 20:30
  // disembarkationTime: string; // 20:30
  // cruiseShip: string; // Brilliance of the Seas
  // boardingTime: string; // 20:30
  // arrivalRailLine: string; // Amtrak
  // arrivalRailStation: string; // Central Station
  // departureRailLine: string; // Amtrak
  // departureRailStation: string; // Central Station
  cardId: string;

  constructor(values, cardId) {
    this.cardId = cardId;

    // object values contain keys listed above
    Object.entries(values).forEach(([questionId, value]) => {
      if (questionId === BookingQuestionEnum.passengerHeights) {
        // eslint-disable-next-line no-param-reassign
        value = `${value}cm`;
      }

      this[questionId] = value;
    });
  }
}
