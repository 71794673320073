import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Pagination } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export default class TourPagination extends Component<{
  current: number;
  onChange: any; // Function;
  pageSize: number;
  total: number;
}> {
  renderItem = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <span className="bold">
          <LeftOutlined /> {intl.get('pagination.previous')}
        </span>
      );
    }

    if (type === 'next') {
      return (
        <span className="bold">
          {intl.get('pagination.next')} <RightOutlined />
        </span>
      );
    }

    return originalElement;
  };

  render() {
    const { current, onChange, pageSize, total } = this.props;

    return (
      <div className="tour-pagination">
        <Pagination
          current={current}
          onChange={onChange}
          pageSize={pageSize}
          total={total}
          itemRender={this.renderItem}
          showSizeChanger={false}
          hideOnSinglePage
        />
      </div>
    );
  }
}
