/* eslint-disable react/prop-types */
import React, { ReactElement, Children } from 'react';
import { getIsSelected } from '../../../../../../utils/formatter';

type CalendarCellWrapperProps = {
  children?: ReactElement;
  value?: string;
  selectedDays: string[];
};

const CalendarCellWrapper: React.FC<CalendarCellWrapperProps> = React.memo(
  (props) => {
    const { children, value, selectedDays } = props;
    let className = children.props.className
      ? `${children.props.className}`
      : '';
    let style: any = {
      outlineOffset: '-3px',
    };
    const isSelected = getIsSelected(selectedDays, value);

    if (className.includes('rbc-selected-cell')) {
      className = className.replace(/rbc-selected-cell/g, '');

      style = {
        ...style,
        outline: '1.7px dashed #4a90e2',
      };
    } else if (isSelected) {
      style = {
        ...style,
        outline: '2px solid #4a90e2',
      };
    }

    return React.cloneElement(Children.only(children), {
      ...children.props,
      style: {
        ...children.props.style,
        ...style,
      },
      className,
    });
  },
);

export default CalendarCellWrapper;
